define("crm/routes/organizations/organization/internments/internment/financial-report", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      this.get('store').query('internment-financial', {
        internment: this.modelFor('organizations.organization.internments.internment').get('id')
      }).then(function (financials) {
        Ember.set(model, 'financials', financials.sortBy('created').reverse());
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var now = (0, _moment.default)(); // set start

        var start = (0, _moment.default)().startOf('day').hours(8);
        controller.set('start', start);
        controller.set('startDate', start.format("YYYY-MM-DD"));
        controller.set('startHours', (start.hours() < 10 ? '0' : '') + start.hours());
        controller.set('startMinutes', '00'); // set end

        controller.set('end', (0, _moment.default)());
        controller.set('endDate', now.format("YYYY-MM-DD"));
        controller.set('endHours', (now.hours() < 10 ? '0' : '') + now.hours());
        controller.set('endMinutes', (now.minutes() < 10 ? '0' : '') + now.minutes());
      }
    }
  });

  _exports.default = _default;
});