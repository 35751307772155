define("crm/components/lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    restservices: Ember.inject.service(),
    linesPriceTotal: Ember.computed("lines.@each.linePrice", "lines.[]", function () {
      var self = this;
      var result = 0;

      if (!Ember.isEmpty(this.get("sortedLines"))) {
        this.get("sortedLines").forEach(function (line) {
          if (!Ember.isEmpty(Ember.get(line, "linePrice"))) {
            result += self.money(Ember.get(line, "linePrice"));
          }
        });
      }

      result = this.money(result);

      if (this.get("sortedLines")) {
        this.set("lines.totalAmount", result);
      }

      return result;
    }),
    linesTotal: Ember.computed("lines.@each.unitPrice", "lines.@each.quantity", "linesPriceTotal", function () {
      var total = 0;

      if (!Ember.isEmpty(this.get("sortedLines"))) {
        this.get("sortedLines").forEach(function (line) {
          if (!Ember.isEmpty(Ember.get(line, "unitPrice"))) {
            total += Ember.get(line, "unitPrice") * Ember.get(line, "quantity");
          }
        });
      }

      return total;
    }),
    linesDiscountTotal: Ember.computed("linesTotal", "linesPriceTotal", function () {
      return this.get("linesTotal") - this.get("linesPriceTotal") > 0.01 ? this.get("linesTotal") - this.get("linesPriceTotal") : 0;
    }),
    sortedLines0: Ember.computed("lines.@each.lineGroupTree", function () {
      if (!this.get("lines")) {
        return null;
      }

      return this.get("lines").filter(function (x) {
        return x;
      }).sortBy("lineGroupTree");
    }),
    sortedLines: Ember.computed("sortedLines0.@each.treeLevel", function () {
      var odd = false;
      var lastGroup = 0;

      if (!this.get("sortedLines0")) {
        return null;
      }

      this.get("sortedLines0").forEach(function (l) {
        if (Ember.get(l, "item")) {
          if (Ember.get(l, "lineGroup") && Ember.get(l, "lineGroup") !== lastGroup) {
            odd = !odd;
            lastGroup = Ember.get(l, "lineGroup");
          }

          Ember.set(l, "odd", odd);
        }
      });
      return this.get("sortedLines0");
    }),
    linesIndex: Ember.computed("sortedLines", function () {
      /*
       * We need this for the case when because of some bug, a parent is lost, but chindren are still in the lines
       */
      var result = {};
      var index = 0;
      this.get("sortedLines").forEach(function (e) {
        return result[index++] = e;
      });
      return result;
    }),
    actions: {
      clearCart: function clearCart() {
        if (confirm(this.get("intl").t("intl.remove"))) {
          this.sendAction("disableCustomerShoppingCarts");

          if (this.get("clearLines")) {
            this.sendAction("clearLines");
          } else {
            this.set("lines", []);
          }
        }
      },
      deleteLine: function deleteLine(line) {
        var self = this;
        var id = Ember.get(line, "id");
        var tree = Ember.get(line, "lineGroupTree");

        if (id) {
          if (tree) {
            this.get("lines").filter(function (l) {
              return Ember.get(l, "lineGroupTree") && Ember.get(l, "lineGroupTree").startsWith(tree);
            }).forEach(function (l) {
              l.transitionTo('deleted.saved');
              l.unloadRecord();
            });
            this.get("restservices").delete("shoppingCartLines/" + id);
          } else {
            line.destroyRecord();
          }
        } else if (tree) {
          this.get("lines").filter(function (l) {
            return Ember.get(l, "lineGroupTree") && Ember.get(l, "lineGroupTree").startsWith(tree);
          }).forEach(function (l) {
            self.get("lines").removeObject(l);
          });
        } else {
          this.get("lines").removeObject(line);
        }
      },
      openDetails: function openDetails(line) {
        var _this = this;

        this.get("store").query("item-item", {
          parent: Ember.get(line, "item.id")
        }).then(function (r) {
          if (Ember.isEmpty(r) || Ember.get(line, "item.isFixedPriceBundle")) {
            _this.sendAction("openDetails", line);
          } else {
            _this.sendAction("openDetailsModal", "order-line-compound-product", line);
          }
        });
      }
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});