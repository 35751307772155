define("crm/models/contact-authorization-type", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    contact: _emberData.default.belongsTo("contact", {
      inverse: 'authorizations'
    }),
    authorizationType: _emberData.default.belongsTo('authorizationType')
  });

  _exports.default = _default;
});