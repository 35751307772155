define("crm/abilities/debug", ["exports", "crm/abilities/denied"], function (_exports, _denied) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _denied.default.extend({
    canDo: Ember.computed("g", function () {
      return this.get("g") && window.location.href.includes("localhost") && this.get("currentUser.id") === "1";
    })
  });

  _exports.default = _default;
});