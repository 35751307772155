define("crm/components/custom-form-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pdf: Ember.inject.service(),
    actions: {
      reset: function reset() {// empty
      },
      someAction: function someAction(formData) {
        var formResult = formData.map(function (e) {
          return {
            key: e.type.name,
            value: e.value
          };
        });
        this.toggleProperty("closed");
        Ember.set(this.get("model"), "values", formResult);
        this.sendAction("openModal", {
          entity: "pdf2",
          action: "details",
          model: this.get("model")
        });
      }
    }
  });

  _exports.default = _default;
});