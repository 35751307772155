define("crm/models/internment-status", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    chat: _emberData.default.belongsTo("chat"),
    diagnosis: _emberData.default.attr("string"),
    internment: _emberData.default.belongsTo("internment", {
      inverse: "internmentStatus"
    })
  });

  _exports.default = _default;
});