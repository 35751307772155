define("crm/abilities/veterinary", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canDo: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canCreate: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canRead: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canUpdate: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canDelete: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    })
  });

  _exports.default = _default;
});