define("crm/components/documents-totals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cleanData: Ember.computed("model.[]", function () {
      return Ember.isEmpty(this.get("model")) ? null : this.get("model").filter(function (d) {
        return d.get("payableAmount") && (Ember.isEmpty(d.get("status")) || d.get("status") !== 8);
      });
    }),
    inTotal: Ember.computed("cashTotal", "debTotalf", "transferTotal", "cardTotal", "checkTotal", function () {
      return this.get("cashTotal") + this.get("debTotal") + this.get("transferTotal") + this.get("cardTotal") + this.get("checkTotal");
    }),
    outTotal: Ember.computed("cashTotal", "debTotalP", "transferTotalP", "cardTotalP", "checkTotalP", function () {
      return this.get("cashTotalP") + this.get("debTotalP") + this.get("transferTotalP") + this.get("cardTotalP") + this.get("checkTotalP");
    }),
    currentVal: Ember.computed("financialAccountOpen.payableAmount", "inTotal", "outTotal", function () {
      return this.get("financialAccountOpen.payableAmount") + this.get("inTotal") + this.get("outTotal");
    }),
    total: Ember.computed("inTotal", "outTotal", function () {
      return this.get("inTotal") - Math.abs(this.get("outTotal"));
    }),

    /*
     * received
     */
    inDocs: Ember.computed("cleanData.[]", function () {
      return Ember.isEmpty(this.get("cleanData")) ? null : this.get("cleanData").filter(function (document) {
        return (document.get("constructor.modelName") === "invoice" || document.get("constructor.modelName") === "receipt") && (Ember.isEmpty(document.get("documentType")) || document.get("documentType") !== "FA") && !Ember.isEmpty(document.get("billingID"));
      });
    }),
    cashTotal: Ember.computed("inDocs.[]", function () {
      return Ember.isEmpty(this.get("inDocs")) ? null : this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "NUM" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    debTotal: Ember.computed("inDocs.[]", function () {
      return Ember.isEmpty(this.get("inDocs")) ? null : this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "DEB" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    transferTotal: Ember.computed("inDocs.[]", function () {
      return Ember.isEmpty(this.get("inDocs")) ? null : this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "TRA" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    checkTotal: Ember.computed("inDocs.[]", function () {
      return Ember.isEmpty(this.get("inDocs")) ? null : this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "CHQ" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    cardTotal: Ember.computed("inDocs.[]", function () {
      return Ember.isEmpty(this.get("inDocs")) ? null : this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "CARD" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),

    /*
     * payments
     */
    outDocs: Ember.computed("cleanData.[]", function () {
      return Ember.isEmpty(this.get("cleanData")) ? null : this.get("cleanData").filter(function (document) {
        return document.get("_internalModel.modelName") === "payment" || document.get("_internalModel.modelName") === "purchase-invoice";
      });
    }),
    cashTotalP: Ember.computed("outDocs.[]", function () {
      return Ember.isEmpty(this.get("outDocs")) ? null : this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "NUM" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    debTotalP: Ember.computed("outDocs.[]", function () {
      return Ember.isEmpty(this.get("outDocs")) ? null : this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "DEB" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    transferTotalP: Ember.computed("outDocs.[]", function () {
      return Ember.isEmpty(this.get("outDocs")) ? null : this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "TRA" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    checkTotalP: Ember.computed("outDocs.[]", function () {
      return Ember.isEmpty(this.get("outDocs")) ? null : this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "CHQ" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    cardTotalP: Ember.computed("outDocs.[]", function () {
      return Ember.isEmpty(this.get("outDocs")) ? null : this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "CARD" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    })
  });

  _exports.default = _default;
});