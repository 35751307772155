define("crm/models/questionnaire-answer", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    reply: _emberData.default.belongsTo("questionnaireReply"),
    type: _emberData.default.belongsTo("questionnaireQuestion")
  });

  _exports.default = _default;
});