define("crm/controllers/organizations/organization/listings/sales/vat-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    filteredModels: Ember.computed('model.invoiceLines.[]', 'model.memoLines.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoiceLines"))) {
        result.addObjects(this.get("model.invoiceLines"));
      }

      if (!Ember.isEmpty(this.get("model.memoLines"))) {
        result.addObjects(this.get("model.memoLines"));
      }

      return result;
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "title": "vatAmount",
        "propertyName": "vatAmount",
        "className": "column-price-large"
      }, {
        "title": "linePriceSum",
        "propertyName": "linePriceSum",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "linePriceWoVatSum",
        "propertyName": "linePriceWoVatSum",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "vatLinePriceSum",
        "propertyName": "vatLinePriceSum",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "totalSum",
        "propertyName": "totalSum",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});