define("crm/components/customer-new", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    dataValidator: Ember.inject.service(),
    contactValidator: Ember.inject.service(),
    customer: {},
    contact: {},
    address: {},
    catalogs: Ember.inject.service(),
    inboundTypes: Ember.computed.alias("catalogs.inboundTypes"),
    titles: Ember.computed.alias("catalogs.titles"),
    session: Ember.inject.service("session"),
    newPatientAfter: false,
    closed: false,
    today: (0, _moment.default)().format("YYYY-MM-DD"),
    init: function init() {
      this._super();

      this.set("countries", this.get("store").peekAll("country"));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      var self = this;

      if (this.get("countries.length") && this.get("organization.country")) {
        this.set("selectedCountry", this.get("countries").find(function (country) {
          return Ember.get(country, "code") === self.get("organization.country");
        }));

        if (this.get("address")) {
          this.set("address.country", this.get("countries").find(function (country) {
            return Ember.get(country, "code") === self.get("organization.country");
          }));
        }

        if (this.get("customer")) {
          this.set("customer.language", this.get("countries").find(function (country) {
            return Ember.get(country, "code") === self.get("organization.country");
          }));
        }
      }
    },
    customFields: Ember.computed("fieldTypes", function () {
      var result = [];

      if (this.get("fieldTypes")) {
        this.get("fieldTypes").forEach(function (type) {
          result.push({
            type: type
          });
        });
      }

      return result;
    }),
    nifAllreadyExists: Ember.computed("customer.nif", function () {
      if (this.get("customer.nif.length") >= 9) {
        return this.get("store").query("customer", {
          company: Number(this.get("organization.company.id")),
          vat: this.get("customer.nif").toString()
        });
      }
    }),
    usageNifClass: Ember.computed("nifAllreadyExists.[]", "customer.nif", function () {
      if (!Ember.isEmpty(this.get("customer.nif")) && !Ember.isEmpty(this.get("nifAllreadyExists"))) {
        this.set("disabledSubmission", true);
        this.set("hideIcon", true);
        return true;
      }

      this.set("disabledSubmission", false);
      this.set("hideIcon", false);
      return false;
    }),
    usageNifMessage: Ember.computed("nifAllreadyExists.[]", function () {
      if (!Ember.isEmpty(this.get("customer.nif"))) {
        if (!Ember.isEmpty(this.get("nifAllreadyExists"))) {
          return this.get("intl").t("customers.form.allreadyExistsVat");
        }
      }
    }),
    phoneMandatory: Ember.computed("organization.id", function () {
      return this.get("organization.id") == 234;
    }),
    disableBtnAction: Ember.computed("usageNifClass", "disabledSubmission", "validTaxNumber", function () {
      return this.get("usageNifClass") || this.get("disabledSubmission") || !this.get("validTaxNumber");
    }),
    patternZipFirstPart: Ember.computed("zipFirstBox", "selectedCountry.code", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{4}";
      }
    }),
    patternZipSecondPart: Ember.computed("zipSecondPart", "selectedCountry.code", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{3}";
      }
    }),
    referencedByObserver: Ember.observer("referencedBy.id", "customer", function () {
      if (this.get("referencedBy.id") && this.get("customer")) {
        this.set("customer.referencedBy", this.get("referencedBy"));
      }
    }),
    validTaxNumber: Ember.computed("selectedCountry.code", "customer.nif", function () {
      if (Ember.isEmpty(this.get("customer.nif"))) {
        return true;
      }

      if (this.get("customer.nif") === "999999990") {
        return false;
      }

      var self = this;
      var aux = this.get("dataValidator").taxNumber(this.get("selectedCountry.code"), this.get("customer.nif"));

      if (aux) {
        self.set("validatingTaxNumber", true);
        Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/checkvat?country=" + this.get("selectedCountry.code") + "&vat=" + this.get("customer.nif"),
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale "));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(result) {
            if (result.valid) {// TODO if data comes back fill automatically

              /*if(isEmpty(self.get("customer.name"))) {
                  self.set("customer.name", result.name);
              }
              if(isEmpty(self.get("customer.zip"))) {
                  var aux = result.address.zipcode.split("-");
                  if(aux) {
                      self.set("zipFirstPart", aux[0]);
                      self.set("zipSecondPart", aux[1]);
                      self.set("customer.zip", self.get("zipFirstPart") +"-" + self.get("zipSecondPart") );
                  }
              }
              if(isEmpty(self.get("customer.town"))) {
                  self.set("customer.town", result.address.city);
              }
              if(isEmpty(self.get("customer.street"))) {
                  self.set("customer.street", result.address.street);
              }*/
            }

            self.set("validatingTaxNumber", false);
          },
          error: function error() {
            self.set("validatingTaxNumber", false);
          }
        });
      } else {
        /*this.set("validatingTaxNumber", null);
        this.set("customer.name", null);
        this.set("zipFirstPart", null);
        this.set("zipSecondPart", null);
        this.set("customer.town", null);
        this.set("customer.street", null);
        this.set("customer.phone", null);
        this.set("customer.email", null);
        this.set("customer.facebook", null);*/
      }

      return aux;
    }),
    validContactNumber: Ember.computed("selectedCountry.code", "mobilePhone", function () {
      if (Ember.isEmpty(this.get("mobilePhone"))) {
        return true;
      }

      var contact;
      var contactType;

      if (this.get("mobilePhone")) {
        contactType = 1;
        contact = this.get("mobilePhone");
      }

      var aux = this.get("contactValidator").contactNumber(this.get("selectedCountry.code"), contact, contactType);
      return aux;
    }),
    close: function close() {
      Ember.$("#modal-customer-create").modal("hide");
      this.reset();
    },
    reset: function reset() {
      this.set("customer", {});
      this.set("contact", {});
      this.set("address", {});
      this.set("newPatientAfter", false);
      this.set("mobilePhone", null);
      this.set("email", null);
      this.set("selectedTitle", null);
      this.set("zipFirstPart", null);
      this.set("selectedInboundType", null);
      this.set("selectedTitle", null);
      this.set("zipSecondPart", null);
      this.set("disabledSubmission", false);
      this.set("showMoreFields", false);
      this.set("referencedBy", null);
    },
    actions: {
      selectCountry: function selectCountry(country) {
        if (country) {
          this.set("address.country", Ember.get(country, "code"));
          this.set("selectedCountry", country);
          this.set("customer.language", this.get("selectedCountry"));
        } else {
          this.set("address.country", null);
          this.set("customer.language", null);
          this.set("selectedCountry", null);
        }
      },
      selectReason: function selectReason(value) {
        this.set("adherenceReason", value);
      },
      selectOrigin: function selectOrigin(value) {
        this.set("origin", value);
      },
      associatePatient: function associatePatient() {
        this.set("disabledSubmission", true);
        var self = this;

        if (this.get("selectedCountry")) {
          this.set("address.country", this.get("selectedCountry.code"));
        }

        if (this.get("selectedTitle")) {
          this.set("customer.title", this.get("selectedTitle"));
        }

        var newCustomer = this.get("store").createRecord("customer", this.get("customer"));
        newCustomer.save().then(function (response) {
          /* create custom fields */
          for (var i = 0; i < self.get("customFields").length; i++) {
            var customField = self.get("customFields")[i];

            if (customField.value) {
              var newObj = {
                type: customField.type,
                customer: response,
                value: customField.value
              };

              switch (customField.type.get("type")) {
                case 0:
                  self.get("store").createRecord("customerFieldString", newObj).save();
                  break;

                case 1:
                  self.get("store").createRecord("customerFieldInt", newObj).save();
                  break;

                case 2:
                  self.get("store").createRecord("customerFieldDate", newObj).save();
                  break;

                case 3:
                  self.get("store").createRecord("customerFieldBoolean", newObj).save();
                  break;

                case 4:
                  self.get("store").createRecord("customerFieldDouble", newObj).save();
                  break;

                default: //console.log("Unknown custom field data type."); TODO

              }
            }
          }

          if (self.get("mobilePhone")) {
            self.set("contact.contactType", 1);
            self.set("contact.contact", self.get("mobilePhone"));
            self.set("contact.customer", response);
            self.set("contact.name", self.get("customer.name")); // TODO build the name on serverside | person name + some sufix

            self.set("contact.preferential", true);
            self.get("store").createRecord("contact", self.get("contact")).save();
          }

          if (self.get("email")) {
            self.set("contact.contactType", 3);
            self.set("contact.contact", self.get("email"));
            self.set("contact.customer", response);
            self.set("contact.name", self.get("customer.name"));
            self.set("contact.preferential", true);
            self.get("store").createRecord("contact", self.get("contact")).save();
          }

          if (self.get("address")) {
            if (self.get("address.country") && (self.get("address.street") || self.get("address.town") || self.get("zipFirstPart"))) {
              if (!Ember.isEmpty(self.get("zipFirstPart"))) {
                if (!Ember.isEmpty(self.get("zipSecondPart"))) {
                  self.set("address.zip", self.get("zipFirstPart") + "-" + self.get("zipSecondPart"));
                } else {
                  self.set("address.zip", self.get("zipFirstPart"));
                }
              }

              if (!Ember.isEmpty(self.get("address.country")) && !Ember.isEmpty(self.get("address.country.code"))) {
                self.set("address.country", self.get("address.country.code"));
              }

              self.set("address.customer", response);
              self.get("store").createRecord("address", self.get("address")).save();
            } else if (self.get("address.country") !== self.get("organization.country")) {
              self.set("address.customer", response);
              self.get("store").createRecord("address", self.get("address")).save();
            }
          }

          self.set("createdCustomer", response);
          Ember.$("#modal-customer-create").modal("hide");
          self.sendAction("openModal", {
            entity: "patient",
            action: "create",
            model: {
              owner: response
            }
          });
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.get("store").unloadRecord(newCustomer);
          self.set("disabledSubmission", false);
          self.get("es").handle(result);
        });
      },
      create: function create() {
        this.set("disabledSubmission", true);
        var self = this;

        if (this.get("selectedCountry")) {
          this.set("address.country", this.get("selectedCountry.code"));
        }

        if (this.get("selectedTitle")) {
          this.set("customer.title", this.get("selectedTitle"));
        }

        var newCustomer = this.get("store").createRecord("customer", this.get("customer"));
        newCustomer.save().then(function (response) {
          /* create custom fields */
          for (var i = 0; i < self.get("customFields").length; i++) {
            var customField = self.get("customFields")[i];

            if (customField.value) {
              var newObj = {
                type: customField.type,
                customer: response,
                value: customField.value
              };

              switch (customField.type.get("type")) {
                case 0:
                  self.get("store").createRecord("customerFieldString", newObj).save();
                  break;

                case 1:
                  self.get("store").createRecord("customerFieldInt", newObj).save();
                  break;

                case 2:
                  self.get("store").createRecord("customerFieldDate", newObj).save();
                  break;

                case 3:
                  self.get("store").createRecord("customerFieldBoolean", newObj).save();
                  break;

                case 4:
                  self.get("store").createRecord("customerFieldDouble", newObj).save();
                  break;

                default: //console.log("Unknown custom field data type."); TODO

              }
            }
          }

          if (self.get("mobilePhone")) {
            self.set("contact.contactType", 1);
            self.set("contact.contact", self.get("mobilePhone"));
            self.set("contact.customer", response);
            self.set("contact.name", self.get("customer.name")); // TODO build the name on serverside | person name + some sufix

            self.set("contact.preferential", true);
            self.get("store").createRecord("contact", self.get("contact")).save();
          }

          if (self.get("email")) {
            self.set("contact.contactType", 3);
            self.set("contact.contact", self.get("email"));
            self.set("contact.customer", response);
            self.set("contact.name", self.get("customer.name"));
            self.set("contact.preferential", true);
            self.get("store").createRecord("contact", self.get("contact")).save();
          }

          if (self.get("address")) {
            if (self.get("address.country") && (self.get("address.street") || self.get("address.town") || self.get("zipFirstPart"))) {
              if (!Ember.isEmpty(self.get("zipFirstPart"))) {
                if (!Ember.isEmpty(self.get("zipSecondPart"))) {
                  self.set("address.zip", self.get("zipFirstPart") + "-" + self.get("zipSecondPart"));
                } else {
                  self.set("address.zip", self.get("zipFirstPart"));
                }
              }

              self.set("address.customer", response);
              self.get("store").createRecord("address", self.get("address")).save();
            } else if (self.get("address.country") !== self.get("organization.country")) {
              self.set("address.customer", response);
              self.get("store").createRecord("address", self.get("address")).save();
            }
          }

          if (self.get("customer.shouldntOpen") == true) {
            self.sendAction("openModal", {
              entity: "patient",
              action: "create",
              model: {
                owner: response
              }
            });
          }

          self.get("flashMessages").success(self.get("intl").t("customers.messages.createdSuccess"));
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.get("store").unloadRecord(newCustomer);
          self.set("disabledSubmission", false);
          self.get("es").handle(result);
        });
      },
      canceled: function canceled() {
        this.toggleProperty("closed");
      },
      setInboundType: function setInboundType(inboundType) {
        this.set("selectedInboundType", inboundType);
        this.set("customer.inboundType", inboundType.id);
      },
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        this.set("customer.paymentMethod", paymentMethod);
      },
      setSelectedCustomer: function setSelectedCustomer(customer) {
        this.set("customer.referencedBy", customer);
        this.sendAction("openModal", {
          entity: "customer",
          action: "select"
        });
      },
      removeSelectedCustomer: function removeSelectedCustomer() {
        this.set("customer.referencedBy", null);
        this.set("referencedBy", null);
      },
      reset: function reset() {
        this.reset();
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});