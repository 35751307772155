define("crm/components/debt-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    disabledAction: false,
    selectedDebtStatus: null,
    notes: null,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (!Ember.isEmpty(this.get("model.id"))) {
        var self = this;
        this.get("store").findRecord("debt", this.get("model.id"), {
          reload: true
        }).then(function (debt) {
          self.set("selectedDebtStatus", debt.get("currentDebtDebtStatus.debtStatus"));

          if (debt.get("dueDate")) {
            self.set("dueDate", (0, _moment.default)(debt.get("dueDate")).format("YYYY-MM-DD"));
          }

          if (debt.get("notes")) {
            self.set("notes", debt.get("notes"));
          }
        });
      }
    },
    close: function close() {
      Ember.$("#modal-debt-edit").modal("hide");
    },
    actions: {
      setDebtStatus: function setDebtStatus(selectedDebtStatus) {
        this.set("selectedDebtStatus", selectedDebtStatus);
      },
      modalUpdateDebt: function modalUpdateDebt() {
        this.set("disabledAction", true);
        var self = this;

        if (!Ember.isEmpty(this.get("selectedDebtStatus.name"))) {
          var newDebtDebtStatus = this.get("store").createRecord("debt-debt-status", {
            debt: this.get("model"),
            debtStatus: this.get("selectedDebtStatus")
          });
          newDebtDebtStatus.save().then(function () {
            self.set("model.dueDate", self.get("dueDate"));
            self.set("model.notes", self.get("notes"));
            self.get("model").save();
            self.sendAction("reloadModel");
            self.close();
          }).catch(function (reason) {
            self.get("flashMessages").danger(reason.errors ? reason.errors[0].debtStatus ? self.get("intl").t("debtStatuses.debtStatus.errors." + reason.errors[0].debtStatus) : reason.errors[0] : "Error");
            self.set("disabledAction", false);
          });
        }
      },
      cancelUpdateDebtModal: function cancelUpdateDebtModal() {
        this.close();
      },
      reset: function reset() {
        this.set("disabledAction", false);
        this.set("selectedDebtStatus", null);
        this.set("dueDate", null);
        this.set("notes", null);
      }
    }
  });

  _exports.default = _default;
});