define("crm/components/weight-details-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['module-crm'],
    sorted: Ember.computed("model.[]", function () {
      return this.get("model.length") ? this.get("model").sortBy("weighted") : [];
    }),
    inversed: Ember.computed("model.[]", function () {
      return this.get("model.length") ? this.get("model").sortBy("weighted").reverse() : [];
    }),
    // Line weights graphic data
    data: Ember.computed("sorted.[]", function () {
      if (this.get("sorted")) {
        var dates = this.get('sorted').map(function (w) {
          return (0, _moment.default)(w.get('weighted')).format('DD/MM/YYYY');
        });
        var weights = this.get('sorted').mapBy('weight');
        return {
          labels: dates,
          datasets: [{
            label: this.get('intl').t("patients.patient.weights.weight.title"),
            data: weights,
            fillColor: "#2f4050",
            backgroundColor: "rgba(220,220,220,0.2)",
            pointBackgroundColor: "rgba(220,220,220,1)",
            pointRadius: 2,
            pointHoverRadius: 2,
            pointHoverColor: "rgba(220,220,220,1)",
            pointStyle: 'circle',
            lineTension: 0
          }]
        };
      }
    }),
    // Line weights graphic options
    options: {
      legend: {
        display: false
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    actions: {
      deleteWeight: function deleteWeight(weight) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          weight.destroyRecord();
          Ember.$('#modal-weight-details').modal("hide");
        }
      }
    }
  });

  _exports.default = _default;
});