define("crm/helpers/pu-str-lower-case", ["exports", "universe-gui/helpers/pu-str-lower-case"], function (_exports, _puStrLowerCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _puStrLowerCase.default;
    }
  });
});