define("crm/controllers/organizations/organization/items/item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    intl: Ember.inject.service(),
    actions: {
      edit: function edit() {
        this.send("openModal", {
          entity: "item",
          action: "edit",
          model: {
            item: this.get("model")
          }
        });
      },
      editLine: function editLine(line) {
        this.get("store").query("item-unit", {
          item: this.get("model.id")
        }).then(function (itemUnits) {
          line.set("allItemUnits", itemUnits);
        });
        this.send("openModal", {
          entity: "purchase-invoice-line",
          action: "edit",
          model: {
            item: line
          }
        });
      },
      openPurchaseDetails: function openPurchaseDetails(purchaseInvoiceID) {
        this.transitionToRoute("organizations.organization.purchase-invoices.purchase-invoice", purchaseInvoiceID);
      },
      editItemModal: function editItemModal() {
        this.send("transitionToRoute", "organizations.organization.items.item.edit", this.get("model.id"));
      },
      openMoveStockModal: function openMoveStockModal() {
        this.send("openModal", {
          entity: "item",
          action: "moveStock",
          model: this.get("model")
        });
      },
      openDuplicateItem: function openDuplicateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create",
          model: this.get("model")
        });
      },
      openTransferStockModal: function openTransferStockModal() {
        this.send("openModal", {
          entity: "item",
          action: "transferStock",
          model: this.get("model")
        });
      },
      openPurchaseInvoiceLineNewModal: function openPurchaseInvoiceLineNewModal() {
        this.send("openModal", {
          entity: "purchase-invoice-line",
          action: "create",
          model: {
            item: this.get("model")
          }
        });
      },
      openItemUnitEditModal: function openItemUnitEditModal() {
        this.send("openModal", {
          entity: "item",
          action: "unit-edit",
          model: {
            item: this.get("model")
          }
        });
      },
      newSupplierItems: function newSupplierItems() {
        this.send("openModal", {
          entity: "supplier-item",
          action: "new",
          model: {
            item: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});