define("crm/routes/organizations/organization/group/documents", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      queryQuotations: {
        refreshModel: true
      },
      queryOrders: {
        refreshModel: true
      },
      queryInvoices: {
        refreshModel: true
      },
      queryMemos: {
        refreshModel: true
      },
      queryReceipts: {
        refreshModel: true
      },
      queryPayments: {
        refreshModel: true
      },
      queryDeliveries: {
        refreshModel: true
      },
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      var companyID = organization.get("company.id");

      if (params.createdAfter && (0, _moment.default)(params.createdAfter).isValid() && params.createdBefore && (0, _moment.default)(params.createdBefore).isValid()) {
        var createdAfter = (0, _moment.default)(params.createdAfter).startOf("day").format();
        var createdBefore = (0, _moment.default)(params.createdBefore).endOf("day").format();
        return Ember.RSVP.hash({
          // GET all needed customers
          c1: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Quotation"
          }),
          c2: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "OrderEntity"
          }),
          c3: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Invoice"
          }),
          c4: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Memo"
          }),
          c5: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Receipt"
          }),
          c6: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Payment"
          }),
          c7: this.get("store").query("customer", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Delivery"
          }),
          // GET all needed patients
          p1: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Quotation"
          }),
          p2: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "OrderEntity"
          }),
          p3: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Invoice"
          }),
          p4: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Memo"
          }),
          p5: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Receipt"
          }),
          p6: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Payment"
          }),
          p7: this.get("store").query("patient", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            entityName: "Delivery"
          }),
          quotations: params.queryQuotations ? this.get("store").query("quotation", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          orders: params.queryOrders ? this.get("store").query("order", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          invoices: params.queryInvoices ? this.get("store").query("invoice", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          memos: params.queryMemos ? this.get("store").query("memo", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          receipts: params.queryReceipts ? this.get("store").query("receipt", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          payments: params.queryPayments ? this.get("store").query("payment", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          deliverys: params.queryDeliveries ? this.get("store").query("delivery", {
            company: companyID,
            createdAfter: createdAfter,
            createdBefore: createdBefore,
            status: null
          }) : null,
          financialAccounts: this.get("store").query("financial-account", {
            organization: organizationID
          })
        }).then(function (r) {
          return {
            organization: organization,
            company: organization.get("company"),
            quotations: r.quotations,
            orders: r.orders,
            invoices: r.invoices,
            memos: r.memos,
            receipts: r.receipts,
            payments: r.payments,
            deliverys: r.deliverys
          };
        });
      } else {
        return {
          organization: this.modelFor("organizations.organization")
        };
      }
    }
  });

  _exports.default = _default;
});