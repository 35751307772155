define("crm/models/address", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    building: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    customer: _emberData.default.belongsTo('customer'),
    supplier: _emberData.default.belongsTo('supplier'),
    shortVersion: Ember.computed("town", "zip", function () {
      var result = Ember.isEmpty(this.get("zip")) ? "" : this.get("zip") + " ";
      result = result.concat(Ember.isEmpty(this.get("town")) ? "" : this.get("town"));
      return result;
    }),
    longVersion: Ember.computed("town", "zip", "street", "building", "country", function () {
      var result = Ember.isEmpty(this.get("street")) ? "" : this.get("street") + ", ";
      result = result.concat(Ember.isEmpty(this.get("building")) ? "" : this.get("building") + ", ");
      result = result.concat(Ember.isEmpty(this.get("zip")) ? "" : this.get("zip") + ", ");
      result = result.concat(Ember.isEmpty(this.get("town")) ? "" : this.get("town") + ", ");
      result = result.concat(Ember.isEmpty(this.get("country")) ? "" : this.get("country"));
      return result;
    })
  });

  _exports.default = _default;
});