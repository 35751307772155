define("crm/models/sms", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    text: _emberData.default.attr("string"),
    senderName: _emberData.default.attr("string"),
    phoneNumber: _emberData.default.attr("string"),
    customer: _emberData.default.belongsTo("customer"),
    animal: _emberData.default.belongsTo("patient"),
    organization: _emberData.default.belongsTo("organization"),
    startDate: _emberData.default.attr("isodate"),
    XcustomerName: _emberData.default.attr("string"),
    patientName: _emberData.default.attr("string")
  });

  _exports.default = _default;
});