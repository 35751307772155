define("crm/components/purchase-memo-line-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    changeUnitPrice: false,
    changeLinePrice: true,
    tagName: "tr",
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("line.unitPrice")) {
        this.set("unitPriceWithVatLock", true);

        if (this.get("line.vat.vat.amount")) {
          this.set("line.unitPriceWithVat", this.get("line.unitPrice") * (100 + this.get("line.vat.vat.amount")) / 100);
        }
      }

      if (this.get("line.discount")) {
        this.set("discount2Lock", false);
        this.set("discountMoneyLock", true);
      } else {
        this.set("discount2Lock", true);
        this.set("discountMoneyLock", false);
      }

      if (!this.get("line.quantity")) {
        this.set("quantityPromotionLock", true);
      }

      if (this.get("line.supplierItem") && this.get("line.supplierItem.supplierId") && Ember.isEmpty(this.get("line.supplierId"))) {
        this.set("line.supplierId", this.get("line.supplierItem.supplierId"));
      }

      this.discountMoneyUpdate();
      this.lineTotalUpdate();
    },
    cost: Ember.computed("line.unitPrice", "line.discount", "line.discount2", "line.quantity", "line.quantityPromotion", "generalDiscount", function () {
      var result = null;

      if (this.get("line.unitPrice")) {
        result = this.get("line.unitPrice");

        if (this.get("line.discount")) {
          result = result * (100 - this.get("line.discount")) / 100;
        }

        if (this.get("line.discount2")) {
          result = result * (100 - this.get("line.discount2")) / 100;
        }

        if (this.get("line.quantity") && this.get("line.quantityPromotion")) {
          result = result * this.get("line.quantity") / (this.get("line.quantity") + this.get("line.quantityPromotion"));
        }

        if (this.get("generalDiscount")) {
          result = result * (100 - this.get("generalDiscount")) / 100;
        }

        if (this.get("line.item.stockItemUnit.quantity")) {
          result = result / this.get("line.item.stockItemUnit.quantity");
        }
      }

      this.set("line.purchasePrice", result);
      return result;
    }),
    discountAmount: Ember.computed("line.unitPrice", "line.discount", function () {
      return this.get("line.unitPrice") && this.get("line.discount") ? this.get("line.unitPrice") * this.get("line.discount") / 100 : undefined;
    }),
    discountAmount2: Ember.computed("line.unitPrice", "line.discount", "line.discount2", function () {
      var unitPrice = this.get("line.unitPrice");
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");
      return unitPrice && discount1 && discount2 ? unitPrice * (discount1 * (100 - discount2) / 10000) : undefined;
    }),
    discountTotalPercentage: Ember.computed("line.discount", "line.discount2", function () {
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");
      return discount1 / 100 + discount1 * (100 - discount2) / 10000;
    }),

    /*
        unitPriceParsed: computed("line.unitPrice", function() {
            // return Math.trunc(this.get("line.unitPrice")) + "." + this.get("line.unitPrice").toString().split(".")[1];
            return this.get("line.unitPrice")
                ? this.get("line.unitPrice")
                : null;
        }),
    */
    unitPriceDiff: Ember.computed("line.unitPrice", "line.lastPurchaseMemoLine.unitPrice", function () {
      return this.get("line.unitPrice") && this.get("line.lastPurchaseMemoLine.unitPrice") ? this.get("line.unitPrice") - this.get("line.lastPurchaseMemoLine.unitPrice") : null;
    }),
    unitPricePricier: Ember.computed("unitPriceDiff", function () {
      return this.get("unitPriceDiff") > 0;
    }),

    /******************************************************************************************************************
     * Update fields
     *****************************************************************************************************************/
    discountMoneyUpdate: function discountMoneyUpdate() {
      var unitPrice = this.get("line.unitPrice");
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");

      if (discount2 && discount2 > 0) {
        var aux = unitPrice * discount1 / 100;
        aux += unitPrice * (discount1 * (100 - discount2) / 10000);
        this.set("line.discountMoney", aux);
      } else {
        this.set("line.discountMoney", unitPrice * discount1 / 100);
      }
    },
    lineTotalUpdate: function lineTotalUpdate() {
      if ((this.get("unitPriceLock") || this.get("unitPriceWithVatLock")) && this.get("line.quantity")) {
        if (this.get("line.discount")) {
          this.set("lineTotalLock", true);
          this.set("lineTotalWithVatLock", true);
        } else {
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }

        var lineUnitPrice = this.get("line.unitPrice");

        if (this.get("line.discount")) {
          lineUnitPrice *= (100 - this.get("line.discount")) / 100;
        }

        if (this.get("line.discount2")) {
          lineUnitPrice *= (100 - this.get("line.discount2")) / 100;
        }
        /*
                    if(this.get("generalDiscount")) {
                        lineUnitPrice *= (100 - this.get("generalDiscount")) / 100;
                    }
        */


        this.set("line.linePrice", lineUnitPrice * this.get("line.quantity"));

        if (this.get("line.vat.vat.amount") === undefined || this.get("line.vat.vat.amount") === null) {
          this.set("line.linePriceWithVat", undefined);
        } else if (this.get("line.vat.vat.amount") == 0) {
          this.set("line.linePriceWithVat", lineUnitPrice * this.get("line.quantity"));
        } else {
          this.set("line.linePriceWithVat", lineUnitPrice * this.get("line.quantity") * (100 + this.get("line.vat.vat.amount")) / 100);
        }
      } else {
        this.set("lineTotalLock", false);
        this.set("lineTotalWithVatLock", false);
        this.set("line.linePrice", undefined);
        this.set("line.linePriceWithVat", undefined);
      }
    },
    calcAfterLine: function calcAfterLine() {
      if (this.get("unitPriceLock") || this.get("unitPriceWithVatLock")) {
        if (this.get("discount2Lock")) {
          // No discount
          if (this.get("line.quantity")) {
            this.set("line.discount", 100 - this.get("line.linePrice") * 100 / this.get("line.quantity") / this.get("line.unitPrice")); // this.set("discountLock", true);
          } else {
            this.set("line.quantity", this.get("line.linePrice") / this.get("line.unitPrice")); // this.set("quantityLock", true);
          }
        } else {
          // has discount
          if (this.get("line.discount")) {
            this.set("line.quantity", this.get("line.linePrice") / (this.get("line.unitPrice") - this.get("line.discountMoney")));
          } else {
            this.set("line.quantity", this.get("line.linePrice") / this.get("line.unitPrice"));
          } // this.set("quantityLock", true);

        }
      } else {
        // -> Unit Price
        if (this.get("line.discount")) {
          if (this.get("line.quantity")) {
            if (this.get("line.discount2")) {
              this.set("line.unitPrice", this.get("line.linePrice") * 10000 / this.get("line.quantity") / (100 - this.get("line.discount")) / (100 - this.get("line.discount2")));
            } else {
              this.set("line.unitPrice", this.get("line.linePrice") * 100 / this.get("line.quantity") / this.get("line.discount"));
            }
          } else {
            if (this.get("line.discount2")) {
              this.set("line.unitPrice", this.get("line.linePrice") * 10000 / (100 - this.get("line.discount")) / (100 - this.get("line.discount2")));
            } else {
              this.set("line.unitPrice", this.get("line.linePrice") * 100 / (100 - this.get("line.discount")));
            }
          }
        } else {
          if (this.get("line.quantity")) {
            this.set("line.unitPrice", this.get("line.linePrice") / this.get("line.quantity"));
          } else {
            this.set("line.unitPrice", this.get("line.linePrice"));
          }
        } // this.set("unitPriceLock", true);
        // this.set("unitPriceLockWithVat", true);

      }
    },
    afterUnitPrice: function afterUnitPrice() {
      if (this.get("discountMoneyLock")) {
        this.discountMoneyUpdate();
      } else if (this.get("discountLock")) {
        this.set("line.discount", this.get("line.discountMoney") / this.get("line.unitPrice"));
      }

      this.lineTotalUpdate();
    },

    /******************************************************************************************************************
     * Actions
     *****************************************************************************************************************/
    actions: {
      deleteLine: function deleteLine() {
        this.deleteLine(this.get("line"));
      },
      setUnitPrice: function setUnitPrice(unitPrice) {
        if (unitPrice && unitPrice > 0) {
          this.set("line.unitPrice", Number(unitPrice));
          this.set("unitPriceWithVatLock", true);
          this.set("line.unitPriceWithVat", unitPrice * (100 + this.get("line.vat.vat.amount")) / 100);
          this.afterUnitPrice();
        } else {
          this.set("line.unitPrice", null);
          this.set("line.unitPriceWithVat", null);
          this.set("unitPriceWithVatLock", false);
        }
      },
      setUnitPriceWithVat: function setUnitPriceWithVat(unitPriceWithVat) {
        if (unitPriceWithVat && unitPriceWithVat > 0) {
          this.set("unitPriceLock", true);
          this.set("line.unitPriceWithVat", Number(unitPriceWithVat));
          this.set("line.unitPrice", unitPriceWithVat * 100 / (100 + this.get("line.vat.vat.amount")));
          this.afterUnitPrice();
        } else {
          this.set("line.unitPrice", null);
          this.set("line.unitPriceWithVat", null);
          this.set("unitPriceLock", false);
        }
      },
      setVat: function setVat(vatObj) {
        this.set("line.vat", vatObj);

        if (vatObj === undefined || vatObj === null) {
          if (this.get("unitPriceLock")) {
            this.set("line.unitPrice", undefined);
          } else if (this.get("unitPriceWithVatLock")) {
            this.set("line.unitPriceWithVat", undefined);
          }
        } else {
          var vat = vatObj.get("vat.amount");

          if (this.get("unitPriceLock")) {
            this.set("line.unitPrice", this.get("line.unitPriceWithVat") * 100 / (100 + vat));
          } else if (this.get("unitPriceWithVatLock")) {
            this.set("line.unitPriceWithVat", this.get("line.unitPrice") * (100 + vat) / 100);
          }
        }

        this.lineTotalUpdate();
      },
      setDiscount: function setDiscount(discount) {
        this.set("line.discount2", undefined);
        this.set("discountMoneyLock", true);

        if (discount && discount > 0) {
          this.set("line.discount", Number(discount));
          var unitPrice = this.get("line.unitPrice");
          var discount1 = this.get("line.discount");
          this.set("discount2Lock", false);
          this.set("line.discountMoney", unitPrice * discount1 / 100);
        } else {
          this.set("line.discount", undefined);
          this.set("discount2Lock", true);
          this.set("line.discountMoney", undefined);
          this.set("discountMoneyLock", false);
        }

        this.lineTotalUpdate();
      },
      setDiscount2: function setDiscount2(discount2) {
        if (discount2 && discount2 > 0) {
          this.set("line.discount2", discount2);
        } else {
          this.set("line.discount2", undefined);
        }

        this.discountMoneyUpdate();
        this.lineTotalUpdate();
      },
      setDiscountMoney: function setDiscountMoney(discountMoney) {
        if (discountMoney && discountMoney > 0) {
          this.set("line.discountMoney", discountMoney);
          this.set("discount2Lock", true);
          this.set("line.discount2", undefined);
          this.set("discountLock", true);
          this.set("line.discount", discountMoney / this.get("line.unitPrice") * 100);
        } else {
          this.set("line.discountMoney", undefined);
          this.set("line.discount2", undefined);
          this.set("line.discount", undefined);
          this.set("discountLock", false);
          this.set("discount2Lock", true);
        }

        this.lineTotalUpdate();
      },
      setQuantity: function setQuantity(quantity) {
        if (quantity && quantity > 0) {
          this.set("line.quantity", Number(quantity));
          this.set("quantityPromotionLock", false);
        } else {
          this.set("line.quantity", undefined);
          this.set("line.quantityPromotion", undefined);
          this.set("quantityPromotionLock", true);
        }

        this.lineTotalUpdate();
      },
      setQuantityPromotion: function setQuantityPromotion(quantityPromotion) {
        if (quantityPromotion && quantityPromotion > 0) {
          this.set("line.quantityPromotion", Number(quantityPromotion));
        } else {
          this.set("line.quantityPromotion", undefined);
        }
      },
      setLinePrice: function setLinePrice(linePrice) {
        if (linePrice && linePrice > 0) {
          this.set("line.linePrice", Number(linePrice));
          this.set("lineTotalWithVatLock", true);
          this.set("line.linePriceWithVat", this.get("line.linePrice") * (100 + this.get("line.vat.vat.amount")) / 100);
          this.calcAfterLine();
        } else {
          this.set("line.linePrice", undefined);
          this.set("line.linePriceWithVat", undefined);
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }
      },
      setLinePriceWithVat: function setLinePriceWithVat(linePriceWithVat) {
        if (linePriceWithVat && linePriceWithVat > 0) {
          this.set("line.linePriceWithVat", Number(linePriceWithVat));
          this.set("lineTotalLock", true);
          this.set("line.linePrice", this.get("line.linePriceWithVat") * 100 / (100 + this.get("line.vat.vat.amount")));
          this.calcAfterLine();
        } else {
          this.set("line.linePrice", undefined);
          this.set("line.linePriceWithVat", undefined);
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }
      }
    }
  });

  _exports.default = _default;
});