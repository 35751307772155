define("crm/components/jasmin-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    close: function close() {
      Ember.$(".modal").modal('hide');
    },
    actions: {
      login: function login() {
        var self = this;
        window.open(this.get("url", '_blank')).focus();
        Ember.run.later(function () {
          self.close();
        }, 3000);
      }
    }
  });

  _exports.default = _default;
});