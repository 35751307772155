define("crm/routes/organizations/organization/campaigns/campaign/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      //TODO
      this.store.query("sms-sent", {
        campaign: model.get("id")
      });
      this.store.query("communication-error", {
        campaign: model.get("id")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});