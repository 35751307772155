define("crm/components/patient-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    disabledAction: false,
    environmentTypes: Ember.computed.alias("catalogs.environmentTypes"),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("patient", this.get("model.id"), {
          reload: true
        });
      }

      if (this.get("model.deathdate")) {
        this.set("deathdate", (0, _moment.default)(this.get("model.deathdate")).format("YYYY-MM-DD"));
      } else {
        this.set("deathdate", undefined);
      }
    },
    speciesList: Ember.computed(function () {
      return this.get("store").peekAll("species");
    }),
    environments: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get('intl').t("patients.form.environmentType.indoor")
      }, {
        id: 2,
        name: this.get('intl').t("patients.form.environmentType.outdoor")
      }, {
        id: 3,
        name: this.get('intl').t("patients.form.environmentType.both")
      }];
    }),
    choices: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get('intl').t("patients.patient.yes")
      }, {
        id: 0,
        name: this.get('intl').t("patients.patient.no")
      }];
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    reset: function reset() {
      this.set("disabledAction", false);
      this.set("showMoreFields", false);
    },
    actions: {
      toggleEnvironmentType: function toggleEnvironmentType(environmentTypeID) {
        if (this.get("model.environmentType") === environmentTypeID) {
          this.set("model.environmentType", null);
        } else {
          this.set("model.environmentType", environmentTypeID);
        }
      },
      imageUpdated: function imageUpdated(image) {
        var self = this;
        this.get("store").findRecord("patient", this.get("model.id"), {
          reload: true
        }).then(function (savedPatient) {
          savedPatient.set("avatar", image);
          savedPatient.save().catch(function (result) {
            self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
          });
        });
      },
      unselectCustomer: function unselectCustomer() {
        this.set("model.owner", null);
      },
      selectSex: function selectSex(sex) {
        if (this.get("model.sex")) {
          if (this.get("model.sex") === sex) {
            this.set("model.sex", null);
          } else {
            this.set("model.sex", sex);
          }
        } else {
          this.set("model.sex", sex);
        }
      },
      setOwner: function setOwner(value) {
        this.set("customer", value);
      },
      setSterialized: function setSterialized(value) {
        this.set("model.sterilized", value);
      },
      setMixedBreed: function setMixedBreed(value) {
        this.set("model.mixedbreed", value);
      },
      setTattoo: function setTattoo(value) {
        this.set("model.tattoo", value);
      },
      setInsurance: function setInsurance(value) {
        this.set("model.insurance", value);
      },
      setFavoriteOwner: function setFavoriteOwner(favoriteOwner) {
        this.set("model.ownerPrefered", favoriteOwner);
      },
      setTail: function setTail(tail) {
        this.set("tail", tail);
      },
      setEnvironment: function setEnvironment(value) {
        this.set("model.environmentType", value);
      },
      setPrice: function setPrice(value) {
        this.set("pvp", value);
      },
      setDiet: function setDiet(diet) {
        this.set("diet", diet);
      },
      setSpeciesID: function setSpeciesID(speciesID) {
        if (speciesID) {
          this.send("setSpecies", this.get("store").peekRecord("species", speciesID));
        } else {
          this.send("setSpecies", null);
        }
      },
      setSpecies: function setSpecies(species) {
        this.set("model.species", species);
        this.set("model.breed", null);
      },
      setDeathdateNull: function setDeathdateNull() {
        this.set("model.deathdate", null);
        this.set("deathdate", null);
      },
      modalEditPatient: function modalEditPatient() {
        this.set("disabledAction", true);
        var self = this;

        if (this.get("breedID")) {
          var breed = this.get("store").peekRecord("breed", this.get("breedID"));
          this.set("model.breed", breed);
        }

        if (this.get("customer")) {
          var owner = this.get("store").peekRecord("customer", this.get("customer"));
          this.set("model.owner", owner);
        }

        if (this.get("tail")) {
          var tail = this.get("store").peekRecord("tail", this.get("tail"));
          this.set("model.tailType", tail);
        }

        if (this.get("diet")) {
          var diet = this.get("store").peekRecord("diet", this.get("diet"));
          this.set("model.diet", diet);
        }

        if (this.get("deathdate")) {
          this.set("model.deathdate", this.get("deathdate"));
          this.set("model.enabled", false);
        } else {
          this.set("model.enabled", true);
        }

        this.get("model.completed", true);
        this.get("model").save().then(function () {
          self.close();
          self.sendAction("reloadModel");
        }).catch(function (result) {
          self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
          self.set("disabledAction", false);
        });
      },
      cancelEditPatientModal: function cancelEditPatientModal() {
        this.get("model").rollbackAttributes();
        this.sendAction("reloadModel");
        this.close();
      },
      reset: function reset() {
        this.reset();
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});