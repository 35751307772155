define("crm/components/owners-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*actions: {
        toCustomer(customerID) {
            this.sendAction("toCustomer", customerID);
        }
    }*/
  });

  _exports.default = _default;
});