define("crm/abilities/undermanager", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canDo: Ember.computed.alias("undermanager"),
    canCreate: Ember.computed.alias("undermanager"),
    canRead: Ember.computed.alias("undermanager"),
    canUpdate: Ember.computed.alias("undermanager"),
    canDelete: Ember.computed.alias("undermanager")
  });

  _exports.default = _default;
});