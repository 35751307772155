define("crm/components/show-appointment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "i",
    classNames: "fa fa-fw",
    icon: Ember.computed(function () {
      var icon;

      switch (this.get("record.appointment.status")) {
        case 0:
          icon = this.get('intl').t("icons.appointments");
          break;

        case 1:
          icon = this.get('intl').t("icons.appointments");
          break;

        case 2:
          icon = this.get('intl').t("icons.appointments");
          break;

        case 3:
          icon = this.get('intl').t("icons.waitingRoom");
          break;

        case 4:
          icon = this.get('intl').t("icons.visit");
          break;

        case 5:
          icon = this.get('intl').t("icons.checkout");
          break;

        case 6:
          icon = this.get('intl').t("icons.appointmentDone");
          break;

        default: // code block

      }

      return "fa-" + icon + " " + this.get("record.appointment.status");
    }),
    title: Ember.computed(function () {
      var text;

      switch (this.get("record.appointment.status")) {
        case 0:
          text = this.get('intl').t("appointments.appointment.title");
          break;

        case 1:
          text = this.get('intl').t("appointments.status.scheduled");
          break;

        case 2:
          text = this.get('intl').t("appointments.status.checkinWithoutscheduling");
          break;

        case 3:
          text = this.get('intl').t("appointments.status.checkinWithScheduling");
          break;

        case 4:
          text = this.get('intl').t("appointments.status.inConsultation");
          break;

        case 5:
          text = this.get('intl').t("appointments.status.inCheckout");
          break;

        case 6:
          text = this.get('intl').t("appointments.status.finished");
          break;

        default: // code block

      }

      return text;
    })
  });

  _exports.default = _default;
});