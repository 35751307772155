define("crm/routes/organizations/organization/listings/crm/customers/active", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      before: {
        refreshModel: true
      },
      after: {
        refreshModel: true
      }
    },
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var begin = this.modelFor("organizations.organization.listings").createdAfter;
      var end = this.modelFor("organizations.organization.listings").createdBefore;
      var self = this;
      return Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/customers/active" + "?after=" + new Date(_moment.default.utc(begin).startOf("d")).toISOString() + "&before=" + new Date(_moment.default.utc(end).endOf("d")).toISOString() + "&organization=" + organizationID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        }
      }).then(function (model) {
        return model ? model.map(function (x) {
          return {
            id: x[0],
            name: x[1],
            invoicesNumber: x[2],
            recentDate: x[3],
            invoicesTotal: x[4]
          };
        }).sortBy("name") : null;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("columns", [{
        "propertyName": "id",
        "title": this.get('intl').t("patients.patient.id"),
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.name"),
        "routeName": "organizations.organization.customers.customer",
        "routeProperty": "id"
      }, {
        "propertyName": "invoicesNumber",
        "title": this.get('intl').t("stats.invoicesNumber"),
        "className": "column-price-large text-right"
      }, {
        "propertyName": "recentDate",
        "title": this.get('intl').t("stats.mostRecentDate"),
        "component": "umt-datetime",
        "className": "column-date-time text-right"
      }, {
        "propertyName": "invoicesTotal",
        "title": this.get('intl').t("stats.totalInInvoices"),
        "component": "umt-price-display",
        "className": "column-price-large text-right"
      }]);
    }
  });

  _exports.default = _default;
});