define("crm/models/product", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    internalDescription: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    productType: _emberData.default.belongsTo('productType'),
    associatedService: _emberData.default.attr('string'),
    family: _emberData.default.belongsTo('family'),
    subFamily: _emberData.default.belongsTo('family'),
    subSubFamily: _emberData.default.belongsTo('family'),
    local: _emberData.default.attr('string'),
    vatRate: _emberData.default.belongsTo('vatRate'),
    packingQuantity: _emberData.default.attr('string'),
    //quantidade da embalagem
    unitMeasure: _emberData.default.attr('number'),
    stockControl: _emberData.default.attr('boolean'),
    enable: _emberData.default.attr('boolean'),
    observations: _emberData.default.attr('string'),
    stockMinimum: _emberData.default.attr('number'),
    avaragePrice: _emberData.default.attr('number'),
    costPrice: _emberData.default.attr('number'),
    optionCalculatePrice: _emberData.default.attr('number'),
    productCategory: _emberData.default.attr('number'),
    laboratory: _emberData.default.belongsTo('laboratory'),
    supplier: _emberData.default.belongsTo('supplier'),
    //fabricante
    viaAdministration: _emberData.default.belongsTo('viaAdministration'),
    activePrinciple: _emberData.default.attr('string'),
    therapeuticClass: _emberData.default.attr('string'),
    medicalNote: _emberData.default.attr('string'),
    posology: _emberData.default.attr('string'),
    changeQuantity: _emberData.default.attr('boolean') //se a quantidade pode ser alterada ou não quando inserida no carrinho

  });

  _exports.default = _default;
});