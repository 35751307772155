define("crm/controllers/organizations/organization/management-stats", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    labels: [],
    // Line chart graphic options
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    filter: {
      invoices: false,
      memos: false
    },
    filterOptions: Ember.computed("intl", function () {
      var result = [];

      for (var key in this.get("filter")) {
        result.push({
          name: this.get("intl").t("filters." + key),
          code: "filter." + key,
          active: this.get("filter")[key]
        });
      }

      return result;
    }),
    // Line chart graphic data
    chartData: Ember.computed('billingByDay.[]', function () {
      return {
        labels: this.get("labels"),
        datasets: [{
          label: "Label",
          data: this.get("billingByDay"),
          fillColor: "#2f4050",
          backgroundColor: "rgba(220,220,220,0.2)",
          pointBackgroundColor: "rgba(220,220,220,1)",
          pointRadius: 2,
          pointHoverRadius: 2,
          pointHoverColor: "rgba(220,220,220,1)",
          pointStyle: 'circle',
          lineTension: 0
        }]
      };
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "sortPrecedence": 0,
        "title": "Mes"
      }];
    }),
    billingByMonth: Ember.computed("data.[]", function () {
      var result = [];

      for (var yearName in this.get("data")) {
        var yearData = this.get("data")[yearName];

        for (var monthName in yearData) {
          if (monthName !== "amount") {
            var monthData = yearData[monthName];
            var aux = {
              name: monthName
            };

            for (var dayName in monthData) {
              var dayData = monthData[dayName];
              aux[dayName] = dayData.amount;
            }

            result.push(aux);
          }
        }
      }

      return result;
    }),
    billingByDay: Ember.computed("invoices.[]", "memos.[]", function () {
      if (Ember.isEmpty(this.get("invoices"))) {
        return {};
      }

      var result = {
        amount: 0
      };
      this.get("invoices").forEach(function (document) {
        var date = (0, _moment.default)(document[0]);
        var year = date.year();
        var month = date.format("MMM");
        var day = date.date();

        if (!result[year]) {
          result[year] = {
            amount: 0,
            amountI: 0,
            amountNC: 0
          };

          for (var mI = 1; mI < 13; mI++) {
            var m = (0, _moment.default)("2022-" + mI + "-01").format("MMM");
            result[year][m] = {
              amount: 0,
              amountI: 0,
              amountNC: 0
            };

            if ((0, _moment.default)().isSame(year + "-" + mI + "-01", "M")) {
              result[year][m].isMonth = true;
            }
            /*
             * For each day in month
             */


            var totalDays = (0, _moment.default)("" + year + "-" + mI, "YYYY-M").daysInMonth();

            for (var d = 1; d < totalDays + 1; d++) {
              result[year][m][d] = {
                amount: 0,
                amountI: 0,
                amountNC: 0
              };

              if (1 + (0, _moment.default)().month() === mI) {
                result[year][m][d].isMonth = true;

                if ((0, _moment.default)().isSame(year + "-" + mI + "-" + d, "d")) {
                  result[year][m][d].isToday = true;
                }
              } else {
                if ((0, _moment.default)().date() == d) {
                  result[year][m][d].isDay = true;
                }
              }
            }
          }
        }

        result[year][month][day].amountI += document[2];
        result[year][month].amountI += document[2];
        result[year].amountI += document[2];
        result.amountI += document[2];
        result[year][month][day].amount += document[2];
        result[year][month].amount += document[2];
        result[year].amount += document[2];
        result.amount += document[2];
      });

      if (!Ember.isEmpty(this.get("memos"))) {
        this.get("memos").forEach(function (document) {
          var date = (0, _moment.default)(document[0]);
          var year = date.year();
          var month = date.format("MMM");
          var day = date.date();
          result[year][month][day].amount -= document[2];
          result[year][month].amount -= document[2];
          result[year].amount -= document[2];
          result.amount -= document[2];
          result[year][month][day].amountNC += document[2];
          result[year][month].amountNC += document[2];
          result[year].amountNC += document[2];
          result.amountNC += document[2];
        });
      }

      return result;
    }),
    actions: {
      setFilter: function setFilter(filter) {
        this.set(filter, this.get(filter) ? false : true);
      }
    }
  });

  _exports.default = _default;
});