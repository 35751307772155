define("crm/controllers/organizations/organization/item-adjustments/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    queryParams: ["startedAfter", "startedBefore"],
    startedAfter: (0, _moment.default)().startOf("day").format("YYYY-MM-DD"),
    startedBefore: (0, _moment.default)().endOf("day").format("YYYY-MM-DD"),
    sessionAccount: Ember.inject.service(),
    isGod: Ember.computed.readOnly("sessionAccount.currentUser.isGod"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    reset: function reset() {
      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minStartedAfter: Ember.computed("startedBefore", function () {
      if (!Ember.isEmpty(this.get("startedBefore"))) {
        return (0, _moment.default)(this.get("startedBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxStartedBefore: Ember.computed("startedAfter", function () {
      if (!Ember.isEmpty(this.get("startedAfter"))) {
        return (0, _moment.default)(this.get("startedAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    columns: Ember.computed(function () {
      var columns = [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("documents.document.title"),
        "className": "document-name"
      }, {
        "propertyName": "payableAmount",
        "title": this.get("intl").t("documents.payableAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "component": "item-adjustment-actions",
        "className": "column-actions-3"
      }];

      if (this.get("model.firstObject.organization.warehouses.length") > 1) {
        columns.splice(2, 0, {
          "propertyName": "warehouse.internalDescription",
          "title": this.get("intl").t("warehouses.warehouse.title"),
          "className": "document-name"
        });
      }

      return columns;
    }),
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      }
    }
  });

  _exports.default = _default;
});