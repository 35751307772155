define("crm/components/purchase-invoice-new-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    creating: false,
    model: {},
    reset: function reset() {
      this.set("creating", false);
      this.set("model", {});
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model") && Ember.isEmpty(this.get("model.date"))) {
        this.set("model.date", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    },
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    disableBtn: Ember.computed("model.name", "model.payableAmount", "model.date", "model.paymentMethod", "model.supplier", "creating", function () {
      return Ember.isEmpty(this.get("model.supplier")) || Ember.isEmpty(this.get("model.name")) || Ember.isEmpty(this.get("model.payableAmount")) || Ember.isEmpty(this.get("model.date")) || Ember.isEmpty(this.get("model.paymentMethod"));
    }),
    hasTypeBankAccount: Ember.computed("financialAccounts.[]", function () {
      return this.get("financialAccounts") && this.get("financialAccounts").filterBy("financialAccountType", 2).get("length");
    }),
    hasTypeRegisterAccount: Ember.computed("financialAccounts.[]", function () {
      return this.get("financialAccounts") && this.get("financialAccounts").filterBy("financialAccountType", 1).get("length");
    }),
    actions: {
      newSupplier: function newSupplier() {
        this.set("model.supplier", null);
        this.sendAction("openModal", {
          entity: "supplier",
          action: "new"
        });
      },
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        if (paymentMethod) {
          this.set("model.paymentMethod", paymentMethod);
        } else {
          this.set("model.paymentMethod", null);
        }
      },
      create: function create() {
        var self = this;
        this.set("creating", true);
        this.set("model.status", 1);
        this.set("model.paidAmount", this.get("model.payableAmount"));
        this.set("model.truePayableAmount", this.get("model.payableAmount"));
        this.set("model.date", (0, _moment.default)(this.get("model.date")).format());
        this.get("store").createRecord("purchase-invoice", this.get("model")).save().then(function () {
          self.toggleProperty("closed");
          self.sendAction("created");
          self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfully"));
        }).catch(function (result) {
          self.set("creating", false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});