define("crm/components/internment/edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    internmentTypes: Ember.computed.alias("catalogs.internmentTypes"),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    saving: false,
    end: null,
    responsibleVet: null,
    close: function close() {
      this.toggleProperty("closed");
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        if (this.get("model.responsibleVet")) {
          this.set("responsibleVet", this.get("model.responsibleVet"));
        }
      }
    },
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    filteredItems: Ember.computed("selectedCategory.id", function () {
      if (this.get('selectedCategory.id')) {
        return this.get("store").query("item", {
          company: this.get("model.organization.company.id"),
          type: this.get("selectedCategory.id"),
          sellable: true
        });
      } else {
        return [];
      }
    }),
    disableBtnEditInternment: Ember.computed("model.patient.id", "saving", function () {
      return !this.get("model.patient.id") || this.get("saving");
    }),
    actions: {
      editInternment: function editInternment() {
        var self = this;
        this.set("saving", true);

        if (this.get("end")) {
          this.set("model.end", this.get("end"));
        }

        if (this.get("responsibleVet")) {
          this.set("model.responsibleVet", this.get("responsibleVet"));
        }

        if (this.get("selectedItem")) {
          this.set("model.item", this.get("selectedItem"));
        }

        this.get("model").save().then(function () {
          self.close();
        }).catch(function (result) {
          self.set("saving", false);
          self.get("es").errorReturned(result);
        }).finally(function () {
          self.set("saving", false);
          self.set("end", null);
          self.set("responsibleVet", null);
          self.set("selectedInternmentType", null);
        });
      },
      canceled: function canceled() {
        this.close();
      },
      setSelectedCategory: function setSelectedCategory(value) {
        this.set("selectedCategory", value);
        this.set("model.category", value);
        this.set("selectedItem", null);
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("selectedCategory", null);

        if (this.get("model.type")) {
          var self = this;
          this.get("internmentTypes").forEach(function (internmentType) {
            if (internmentType.id == self.get("model.type")) {
              self.set("selectedInternmentType", internmentType);
            }
          });
        }

        if (this.get("model.responsibleVet")) {
          this.set("responsibleVet", this.get("model.responsibleVet"));
        }

        this.set("end", null);
      },
      setResponsibleVet: function setResponsibleVet(value) {
        this.set("responsibleVet", value);
      },
      setSelectedInternmentType: function setSelectedInternmentType(value) {
        this.set("selectedInternmentType", value);

        if (value) {
          this.set("model.type", value.id);
        } else {
          this.set("model.type", null);
        }
      }
    }
  });

  _exports.default = _default;
});