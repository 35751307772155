define("crm/routes/organizations/organization/stats/reception/map", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model() {
      return this.modelFor("organizations.organization");
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var organizationID = model.get("id");
      var startOfYear = (0, _moment.default)().startOf("year").format("YYYY-MM-DD");
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/appointments/hour" + "?organization=" + organizationID + "&createdAfter=" + startOfYear,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        },
        success: function success(result) {
          controller.set("appointments", result);
        }
      });
      controller.set("organization", model);
    }
  });

  _exports.default = _default;
});