define("crm/components/sex-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "i",
    classNameBindings: ['icon'],
    attributeBindings: ['title'],
    title: Ember.computed('model', function () {
      if (this.get("model") === 1) {
        this.set("icon", "fa fa-mars" + (this.get("noColour") ? "" : " text-primary"));
        return this.get('intl').t('enums.sex.male.name');
      } else if (this.get("model") === 2) {
        this.set("icon", "fa fa-venus" + (this.get("noColour") ? "" : " text-danger")); // must have this space space "_text-

        return this.get('intl').t('enums.sex.female.name');
      } else if (this.get("model") === 3) {
        this.set("icon", "fa fa-genderless" + (this.get("noColour") ? "" : " text-info")); // must have this space space "_text-

        return this.get('intl').t("patients.patient.genderless");
      } else if (this.get("model") === 4) {
        this.set("icon", "fa fa-intersex" + (this.get("noColour") ? "" : " text-warning")); // must have this space space "_text-

        return this.get('intl').t('patients.patient.hermaphrodite');
      } else if (this.get("model") === 5) {
        this.set("icon", "fa fa-neuter" + (this.get("noColour") ? "" : " text-black")); // must have this space space "_text-

        return this.get('intl').t('patients.patient.neuter');
      } else if (this.get("model") === 0) {
        this.set("icon", "fa fa-question" + (this.get("noColour") ? "" : " text-success")); // must have this space space "_text-

        return this.get('intl').t("patients.patient.unknown");
      }
    })
  });

  _exports.default = _default;
});