define("crm/components/open-shopping-carts-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    can: Ember.inject.service(),
    canDoCustomer: Ember.computed("can", function () {
      //return this.get("can").can("do crm"); TODOJD
      return true;
    }),
    organization: Ember.computed("column.pu-organization", function () {
      return this.get("column.pu-organization");
    }),
    isPayable: Ember.computed("record.status", function () {
      return this.get("record.status") != 1 && this.get("record.status") != 3;
    }),
    cannotClose: Ember.computed("record.status", function () {
      return this.get("record.status") === 1 || this.get("record.status") === 3;
    }),
    customerisEnabled: Ember.computed("record.customer.enabled", function () {
      return this.get("record.customer.enabled");
    }),
    queryParams: Ember.computed("record.id", function () {
      return {
        isQueryParams: true,
        values: {
          shoppingCartId: this.get("record.id")
        }
      };
    }),
    actions: {
      openDetails: function openDetails(record) {
        var self = this;
        this.get("store").findRecord("shopping-cart", record.get("id")).then(function (shoppingCart) {
          self.sendAction("openDetails", shoppingCart);
        });
      },
      openCloseShoppingCart: function openCloseShoppingCart(record) {
        this.sendAction("openCloseShoppingCart", record);
      }
    }
  });

  _exports.default = _default;
});