define("crm/models/box", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    organization: _emberData.default.belongsTo("organization"),
    deleted: _emberData.default.attr(),
    stays: _emberData.default.hasMany("stay", {
      inverse: "box"
    })
  });

  _exports.default = _default;
});