define("crm/components/consultation-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    model: {},
    startDate: Ember.observer('model.start', 'model.category', 'model.type', 'selectedItemType', 'model.category', function () {
      if (!this.get("saving")) {
        var start;

        if (!this.get("startDay")) {
          start = (0, _moment.default)(this.get('model.start'));
          this.set('startDay', start.format('YYYY-MM-DD'));
          this.set('startTime', start.format('HH:mm'));
        } else {
          start = (0, _moment.default)(this.get('startDay')).hour(Number(this.get("startTime").slice(0, -3))).minutes(Number(this.get("startTime").slice(3)));
        }

        if (this.get("model.type.defaultDurationItem") || this.get("model.category.defaultDurationItemType")) {
          if (this.get("model.category.defaultDurationItemType")) {
            if (this.get("model.type.defaultDurationItem")) {
              this.set('endTime', start.add(this.get("model.type.defaultDurationItem"), 'minutes').format('HH:mm'));
            } else {
              this.set('endTime', start.add(this.get("model.category.defaultDurationItemType"), 'minutes').format('HH:mm'));
            }
          }
        } else {
          this.set('endTime', start.add(30, 'minutes').format('HH:mm'));
        }
      }
    }),
    duration: Ember.computed('startDay', 'startTime', 'endTime', 'startDate', function () {
      if (this.get("startTime"), this.get("endTime")) {
        var startDate = (0, _moment.default)(this.get('startDay') + "T" + this.get('startTime'), "YYYY-MM-DDTHH:mm");
        var endDate = (0, _moment.default)(this.get('startDay') + "T" + this.get('endTime'), "YYYY-MM-DDTHH:mm");
        return endDate.diff(startDate, "minutes");
      }
    }),
    visitTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return type.get('isVisit') && Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    filteredItems: Ember.computed("model.category.id", "model.organization.company.id", function () {
      var self = this;

      if (this.get('model.category.id') && this.get("model.organization.company.id")) {
        return this.get("store").query("item", {
          company: this.get("model.organization.company.id"),
          type: this.get('model.category.id'),
          sellable: true
        }).then(function (items) {
          if (items && items.get('length') === 1) {
            self.set('model.type', items.get('firstObject'));
          }

          return items;
        });
      }
    }),
    hideIcon: Ember.computed("model.category.id", "model.worker", "model.patient", function () {
      return !(this.get("model.category.id") && this.get("model.worker") && this.get("model.patient"));
    }),
    disabledAction: Ember.computed("model.category", "model.worker", "model.patient", "saving", function () {
      return !(this.get("model.category") && this.get("model.worker") && this.get("model.patient")) || this.get("saving");
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        if (this.get('model.patient.id')) {
          this.set("patientWeights", this.get("store").query("weight", {
            patient: this.get("model.patient.id")
          }));
        }

        if (!this.get("model.worker") && this.get("currentWorker.isVet")) {
          this.set("model.worker", this.get("currentWorker"));
        }

        var start = (0, _moment.default)(this.get('model.start'));
        this.set('startDay', start.format('YYYY-MM-DD'));
        this.set('startTime', start.format('HH:mm'));
        this.set('endTime', start.add(30, 'minutes').format('HH:mm'));
      }
    },
    reset: function reset() {
      this.set('saving', false);
      Ember.$('select').prop('selectedIndex', 0);
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        this.reset();
      },
      selectVisitType: function selectVisitType(value) {
        this.set("model.category", value);
        this.set('model.type', undefined);
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", undefined);
      },
      create: function create() {
        this.set('saving', true);
        var self = this;

        if (this.get("startDay") && this.get('startTime')) {
          this.set('model.start', (0, _moment.default)(this.get('startDay') + "T" + this.get('startTime'), "YYYY-MM-DDTHH:mm").format());
          this.set('model.timeConsultation', (0, _moment.default)(this.get('startDay') + "T" + this.get('startTime'), "YYYY-MM-DDTHH:mm").format());
        } // END


        if (this.get('endTime')) {
          this.set('model.end', (0, _moment.default)(this.get('startDay') + "T" + this.get('endTime'), "YYYY-MM-DDTHH:mm").format());
        }

        this.set('model.status', 4);
        var newAppointment = this.get('store').createRecord('appointment', this.get('model'));
        newAppointment.save().then(function (savedAppointment) {
          self.sendAction('transitionToRouteID', 'organizations.organization.consultations.consultation', savedAppointment.get('consultation.id'));
          self.close();
        }).catch(function (result) {
          self.get("es").handle(result);
          self.get('store').unloadRecord(newAppointment);
          self.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});