define("crm/routes/organizations/organization/group/overview-sales", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    session: Ember.inject.service('session'),
    queryParams: {
      startedAfter: {
        refreshModel: true
      },
      startedAfter2: {
        refreshModel: true
      },
      startedBefore: {
        refreshModel: true
      },
      startedBefore2: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var self = this;
      var startedAfter = params.startedAfter || (0, _moment.default)().startOf("day").format("YYYY-MM-DD");
      var startedAfter2 = params.startedAfter2 || (0, _moment.default)().subtract(1, 'days').startOf("day").format("YYYY-MM-DD");
      var startedBefore = params.startedBefore || (0, _moment.default)().endOf("day").format("YYYY-MM-DD");
      var startedBefore2 = params.startedBefore2 || (0, _moment.default)().subtract(1, 'days').endOf("day").format("YYYY-MM-DD");
      var company = this.modelFor("organizations.organization").get("company");
      var companyID = company.get('id');
      return Ember.RSVP.hash({
        model1: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/day?company=" + companyID + "&startedAfter=" + startedAfter + "&startedBefore" + startedBefore,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        model2: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/day?company=" + companyID + "&startedAfter" + startedAfter2 + "&startedBefore" + startedBefore2,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        })
      }).then(function (hash) {
        return hash;
      });
    }
  });

  _exports.default = _default;
});