define("crm/components/stays-coming-today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": 'startDate',
        "component": "umt-date",
        "className": "column-id",
        "title": this.get('intl').t("enums.chronos.dateUpper")
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("stays.patient")
      }, {
        "propertyName": "patient.owner.name",
        "title": this.get('intl').t("customers.customer.title")
      }, {
        "propertyName": "box.name",
        "className": "column-small",
        "title": this.get('intl').t("stays.box")
      }];
    })
  });

  _exports.default = _default;
});