define("crm/components/shift-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoGroupShifts: Ember.computed("can", function () {
      //return this.get("can").can("do group"); TODOJD
      return true;
    }),
    actions: {
      openDetails: function openDetails() {
        this.sendAction('openDetails', this.get("record"));
      },
      openEdit: function openEdit() {
        this.sendAction('openEdit', this.get("record"));
      },
      delete: function _delete(item) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          item.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});