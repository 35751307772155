define("crm/components/item-adjustment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.set("documentLines", this.get("store").query("item-adjustment-line", {
          itemAdjustment: this.get("model.id")
        }));
      }
    },
    documentAnnul: Ember.computed("model.status", function () {
      var result = "";

      switch (this.get("model.status")) {
        case 3:
          result = "";
          break;

        case 4:
          result = this.get("intl").t("invoices.invoice.error");
          break;

        case 8:
          result = this.get("intl").t("invoices.invoice.annuled");
          break;

        default:
          break;
      }

      return result;
    }),
    documentAnulable: Ember.computed("model.status", function () {
      return this.get("model.status") ? this.get("model.status") != 8 : true;
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      annulDocument: function annulDocument(document) {
        this.close();
        this.sendAction("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      }
    }
  });

  _exports.default = _default;
});