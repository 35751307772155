define("crm/components/item-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["appointment-card-component"],
    eventIO: null,
    clock: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.refresh();
    },
    refresh: function refresh() {
      var self = this;
      this.set("clock", (0, _moment.default)());
      Ember.run.later(function () {
        if (self && !self.isDestroyed) self.refresh();
      }, 1000);
    },
    click: function click() {
      var eventIO = this.get("eventIO"); //if this is the first click , schedule it for later

      if (eventIO === null) {
        eventIO = Ember.run.later(this, function () {
          //do single click stuff
          var eventIO = this.get("eventIO");
          this.sendAction("clickAction", this.get("model")); ///clear additional events

          if (eventIO != null) {
            Ember.run.cancel(eventIO);
            this.set("eventIO", null);
          }
        }, 600); //register event to the component

        this.set("eventIO", eventIO);
      }
    },
    doubleClick: function doubleClick() {
      var eventIO = this.get("eventIO"); //check if there is any event for single click, disable it

      if (eventIO != null) {
        Ember.run.cancel(eventIO);
        this.set("eventIO", null);
      } // do the stuff with double click


      this.sendAction("doubleClickAction", this.get("model"));
    }
  });

  _exports.default = _default;
});