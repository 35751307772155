define("crm/models/template-section", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    orderNumber: _emberData.default.attr("number"),
    template: _emberData.default.belongsTo("template", {
      inverse: "sections"
    }),
    parent: _emberData.default.belongsTo("template-section", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("template-section", {
      inverse: "parent"
    }),
    fields: _emberData.default.hasMany("template-section-field", {
      inverse: "templateSection"
    }),
    consultationTemplateSectionFields: Ember.computed("fields.[]", "fields.@each.consultationTemplateSectionFields", function () {
      if (!Ember.isEmpty(this.get("fields"))) {
        var consultationTemplateSectionFields = Ember.A();
        this.get("fields").forEach(function (field) {
          consultationTemplateSectionFields.addObjects(field.get("consultationTemplateSectionFields"));
        });
        return consultationTemplateSectionFields;
      }
    })
  });

  _exports.default = _default;
});