define("crm/models/open-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    amount: _emberData.default.attr("number"),
    dueDate: _emberData.default.attr("isodate"),
    enterprise: _emberData.default.attr("string"),
    open: _emberData.default.attr("number"),
    sourceDoc: _emberData.default.attr("string"),
    sourceDocId: _emberData.default.attr("string"),
    settled: _emberData.default.attr("number"),
    discount: _emberData.default.attr("number")
  });

  _exports.default = _default;
});