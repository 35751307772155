define("crm/routes/organizations/organization/appointments/group", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    queryParams: {
      day: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      if (params.day) {
        var self = this;
        var day = transition.queryParams.day ? transition.queryParams.day : (0, _moment.default)().format("YYYY-MM-DD");
        var startOfday = (0, _moment.default)(day).startOf("day");
        var endOfday = (0, _moment.default)(startOfday).add(1, "days");
        var organization = this.modelFor("organizations.organization");
        var me = this.get("worker");
        return Ember.RSVP.hash({
          comp: organization.get("company"),
          organizations: organization.get("siblings"),
          filteredAppointments: self.store.query("appointment", {
            company: organization.get("company.id"),
            startedAfter: startOfday.format(),
            startedBefore: endOfday.format()
          }),
          patients: self.store.query("patient", {
            company: organization.get("company.id"),
            appointmentStartedAfter: startOfday.format(),
            appointmentStartedBefore: endOfday.format()
          }),
          customers: self.store.query("customer", {
            company: organization.get("company.id"),
            appointmentStartedAfter: startOfday.format(),
            appointmentStartedBefore: endOfday.format()
          })
        }).then(function (hashResult) {
          var orgs = [];
          hashResult.organizations.forEach(function (org) {
            return orgs.push(org);
          });
          orgs.push(organization);
          orgs.forEach(function (o) {
            return Ember.set(o, "company", hashResult.comp);
          });
          var appointments = hashResult.filteredAppointments;
          Ember.set(appointments, "organization", organization);
          Ember.set(appointments, "organizations", orgs.uniqBy('id'));
          Ember.set(appointments, "startOfday", startOfday);
          Ember.set(appointments, "endOfday", endOfday);
          Ember.set(appointments, "me", me);
          return appointments;
        });
      }
    },
    setupController: function setupController(controller, model) {
      var self = this;

      this._super(controller, model);

      var organizationID = model.get("organization.id");
      var startedBeforeOld = (0, _moment.default)().startOf("day").subtract(1, "d").format();
      var startedAfter = (0, _moment.default)().startOf("day").format();
      var startedBefore = (0, _moment.default)().endOf("day").format();
      Ember.RSVP.hash({
        old: self.store.query("task", {
          organization: organizationID,
          startedBefore: startedBeforeOld,
          status: 1
        }),
        today: self.store.query("task", {
          organization: organizationID,
          startedAfter: startedAfter,
          startedBefore: startedBefore
        })
      }).then(function (hashResult) {
        var dayTasks = [];
        var hourTasks = [];

        if (hashResult.old) {
          hashResult.old.forEach(function (task) {
            dayTasks.push(task);
          });
        }

        if (hashResult.today) {
          hashResult.today.forEach(function (task) {
            if (task.get("taskTime") && task.get("taskTime") !== "00:00:00") {
              hourTasks.push(task);
            } else {
              dayTasks.push(task);
            }
          });
        }

        controller.set("tasks", dayTasks);
        controller.set("filteredTasks", hourTasks);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("day", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    },
    actions: {
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send('openModalAppointmentDetails', appointment);
      }
    }
  });

  _exports.default = _default;
});