define("crm/controllers/organizations/organization/items/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["search", "filterDisable"],
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    exporting: false,
    columns: Ember.computed(function () {
      return [{
        "propertyName": "code",
        "title": "",
        "component": "title-models-table-display",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "sellable",
        "title": "",
        "component": "item/umt-icons",
        "className": "column-icons-3"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("items.item.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.items.item",
        "sortPrecedence": 0,
        "className": "text-strong"
      }, {
        "propertyName": "complementaryDescription",
        "title": this.get('intl').t("items.item.complementaryDescription"),
        "component": "title-models-table-display",
        "className": "hidden-xs hidden-sm hidden-md"
      }, {
        "propertyName": "pvp",
        "title": this.get('intl').t("itemSelectList.pvp"),
        "component": "umt-price-display",
        "className": "column-price-medium text-right text-xs-small"
      }, {
        "propertyName": "pvp2",
        "title": this.get('intl').t("itemSelectList.pvp2"),
        "component": "umt-price-display",
        "className": "column-price-medium text-right hidden-xs hidden-sm"
      }, {
        "propertyName": "pvp3",
        "title": this.get('intl').t("itemSelectList.pvp3"),
        "component": "umt-price-display",
        "className": "column-price-medium text-right hidden-xs hidden-sm"
      }, {
        "propertyName": "vat.vat.amount",
        "title": this.get('intl').t("taxes.vat.title"),
        "className": "column-icon text-right hidden-xs"
      }, {
        "propertyName": "displayPrice",
        "title": this.get('intl').t("itemSelectList.compoundTotal"),
        "component": "umt-price-display",
        "className": "column-price-medium text-right hidden-xs"
      }];
    }),
    actions: {
      export: function _export() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/items/xlsx?company=" + self.get("organization.company.id") + "&enable=" + (this.get("filterDisable") ? false : true),
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "items-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      },
      removeItem: function removeItem(item) {
        this.get('model').removeObject(item);
      },
      setItemsTab: function setItemsTab() {
        this.set('filterDisable', undefined);
      },
      setInactiveItemsTab: function setInactiveItemsTab() {
        this.set('filterDisable', true);
      },
      openModalCreateItem: function openModalCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      },
      openModalEntryItem: function openModalEntryItem() {
        this.send('openEntryItem');
      },
      openDetails: function openDetails(item, warehouseItems) {
        this.send("openModal", {
          entity: "item",
          action: "details",
          model: {
            item: item,
            warehouseItems: warehouseItems
          }
        });
      },
      openEditItemModal: function openEditItemModal(itemToEdit) {
        this.send('openEditItem', itemToEdit);
      },
      openMoveStockModal: function openMoveStockModal(item) {
        this.send("openModal", {
          entity: "item",
          action: "moveStock",
          model: item
        });
      },
      openCreate: function openCreate(entity) {
        this.send("openModal", {
          entity: entity,
          action: "create"
        });
      },
      openEntry: function openEntry(entity) {
        this.send("openModal", {
          entity: entity,
          action: "entry"
        });
      },
      openCompoundProductCreate: function openCompoundProductCreate() {
        this.send("openModal", {
          entity: "compound-product",
          action: "new"
        });
      },
      searchItems: function searchItems(query) {
        this.set("search", query);
      },
      searchInactiveItems: function searchInactiveItems(query) {
        this.set("search", query);
      }
    }
  });

  _exports.default = _default;
});