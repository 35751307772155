define("crm/models/todo-serum", ["exports", "ember-data", "crm/models/todo"], function (_exports, _emberData, _todo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todo.default.extend({
    kind: "serum",
    adjustment: _emberData.default.attr("number"),
    // PMS: Valor de ajuste (ex: 1/2)     Hospi: TaxaFinal
    amount: _emberData.default.attr("number"),
    // PMS: Garrafa             Hospi: Garrafa
    fluidRate: _emberData.default.attr("number"),
    internment: _emberData.default.belongsTo("internment", {
      inverse: "serums"
    }),
    nextTodo: _emberData.default.belongsTo("todo-serum", {
      inverse: "prevTodo"
    }),
    prevTodo: _emberData.default.belongsTo("todo-serum", {
      inverse: "nextTodo"
    }),
    supplementations: _emberData.default.hasMany("todo-serum-supplementation"),
    rate: Ember.computed("adjustment", "internment.patient.maintenanceFluidRate", function () {
      return this.get("adjustment") * this.get("internment.patient.maintenanceFluidRate");
    }),

    /*
     * Changed properties
     */
    amountHasChanged: Ember.computed("amount", "prevTodo.amount", function () {
      return this.get("prevTodo.id") && this.get("amount") !== this.get("prevTodo.amount");
    }),
    adjustmentHasChanged: Ember.computed("adjustment", "prevTodo.adjustment", function () {
      return this.get("prevTodo.id") && this.get("adjustment") !== this.get("prevTodo.adjustment");
    }),
    supplementationsHaveChanged: Ember.computed("prevTodo", "supplementations", "prevTodo.supplementations", function () {
      return this.get("prevTodo.id") && this.get("supplementations") !== this.get("prevTodo.supplementations");
    })
  });

  _exports.default = _default;
});