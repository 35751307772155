define("crm/components/choice-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    intl: Ember.inject.service(),
    preferential: Ember.computed("record.preferential", function () {
      switch (this.get("record.preferential")) {
        case true:
          return this.get('intl').t("icons.check");

        default:
          return "";
      }
    })
  });

  _exports.default = _default;
});