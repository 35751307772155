define("crm/helpers/role-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roleDisplay = roleDisplay;
  _exports.default = void 0;

  function roleDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    roles: Ember.computed.readOnly("catalogs.workerRoles"),
    compute: function compute(params) {
      var role = this.get("roles").find(function (o) {
        return o.id == params[0];
      });
      return role ? role.name : "";
    }
  });

  _exports.default = _default;
});