define("crm/models/invoice", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    billingID: _emberData.default.attr("string"),
    currency: _emberData.default.attr("string"),
    paymentMethod: _emberData.default.attr("string"),
    documentType: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    accountingTaxId: _emberData.default.attr("string"),
    accountingName: _emberData.default.attr("string"),
    accountingAddress: _emberData.default.attr("string"),
    checkNumber: _emberData.default.attr("number"),
    checkDate: _emberData.default.attr("localdate"),
    deleted: _emberData.default.attr("isodate"),
    deletedReason: _emberData.default.attr("string"),
    slip: _emberData.default.attr("boolean"),
    series: _emberData.default.attr("string"),
    reward: _emberData.default.attr("number"),
    order: _emberData.default.belongsTo("order"),
    financialAccount: _emberData.default.belongsTo("financial-account"),
    lines: _emberData.default.hasMany("invoice-line", {
      inverse: "invoice"
    }),
    invoiceShoppingCarts: _emberData.default.hasMany("invoice-shopping-cart"),
    receiptInvoices: _emberData.default.hasMany("receipt-invoice"),
    memos: _emberData.default.hasMany("memo"),
    receipts: Ember.computed("receiptInvoices.@each.receipt", function () {
      return this.get("receiptInvoices").map(function (x) {
        return x.get("receipt");
      });
    })
  });

  _exports.default = _default;
});