define("crm/models/clinical-data", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    date: _emberData.default.attr('isodate'),
    dataType: _emberData.default.attr('number'),
    text: _emberData.default.attr('string'),
    textFile: _emberData.default.attr('string'),
    vet: _emberData.default.belongsTo('worker'),
    consultation: _emberData.default.belongsTo('consultation')
  });

  _exports.default = _default;
});