define("crm/components/financial-account-type-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    financialAccount: Ember.computed("record.financialAccountType", function () {
      if (this.get("record.financialAccountType") == 1) {
        return this.get('intl').t("financialAccounts.register");
      } else {
        return this.get('intl').t("financialAccounts.bankAccount");
      }
    })
  });

  _exports.default = _default;
});