define("crm/routes/organizations/organization/appointments/day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      day: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var day = (0, _moment.default)();

      if (transition.queryParams.day) {
        //get day query parameter
        day = transition.queryParams.day;
      }

      var startOfday = (0, _moment.default)(day).startOf("day");
      var endOfday = (0, _moment.default)(startOfday).add(1, "days");
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");

      if (startOfday.isValid() && endOfday.isValid()) {
        return Ember.RSVP.hash({
          organization: organization,
          workers: this.store.query("worker", {
            organization: organizationID
          }),
          filteredAppointments: this.store.query("appointment", {
            organization: organizationID,
            startedAfter: startOfday.format(),
            startedBefore: endOfday.format()
          })
        }).then(function (hashResult) {
          var appointments = hashResult.filteredAppointments;
          var patientid = transition.queryParams.patientid ? transition.queryParams.patientid : hashResult.patientid;
          Ember.set(appointments, "organization", hashResult.organization);
          Ember.set(appointments, "workers", hashResult.workers);
          Ember.set(appointments, "startOfday", hashResult.startOfday);
          Ember.set(appointments, "patientid", patientid);
          return appointments;
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("oldAppointment", null);
        controller.set("customer", null);
        controller.set("patient", null);
        controller.set("worker", null);
        controller.set("intakeDate", null);
        controller.set("day", (0, _moment.default)().format());
      }
    },
    actions: {
      createdConsultation: function createdConsultation(id) {
        this.transitionTo("organizations.organization.consultations.consultation", id);
      }
    }
  });

  _exports.default = _default;
});