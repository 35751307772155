define("crm/controllers/organizations/organization/group/customers", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    customerTypes: Ember.computed.alias("catalogs.customerType"),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    exporting: false,
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    init: function init() {
      this._super();

      this.set("after", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
      this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minAfter: Ember.computed("before", function () {
      if (!Ember.isEmpty(this.get("before"))) {
        return (0, _moment.default)(this.get("before")).subtract(this.get("dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxBefore: Ember.computed("after", function () {
      if (!Ember.isEmpty(this.get("after"))) {
        return (0, _moment.default)(this.get("after")).add(this.get("dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    customersOverviewObserver: Ember.observer("after", "before", "organization.company.id", function () {
      if (!Ember.isEmpty(this.get("organization.company.id")) && !Ember.isEmpty(this.get("before")) && !Ember.isEmpty(this.get("after"))) {
        var self = this;
        Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/customers/overview?company=" + this.get("organization.company.id") + "&before=" + this.get("before") + "&after=" + this.get("after"),
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          success: function success(result) {
            self.set("customersOverview", result);
          }
        });
      }
    }),
    customersOverviewStatus: Ember.computed("customersOverview.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("customersOverview.totalByStatus") ? [] : this.get("customersOverview.totalByStatus").map(function (s) {
        return {
          type: _this.get("customerTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("customersOverview.total") + "%")
        };
      });
    }),
    waits: Ember.computed("customersOverview", function () {
      if (!this.get("customersOverview")) {
        return [];
      }

      return {
        labels: ["checkin", "visit", "checkout"],
        datasets: [{
          data: [this.get("customersOverview.checkinWait"), this.get("customersOverview.visitWait"), this.get("customersOverview.checkoutWait")],
          backgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"],
          hoverBackgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"]
        }]
      };
    }),
    scheduledWaitByType: Ember.computed("customersOverview.scheduledWaitByType.[]", function () {
      return this.waitByType(this.get("customersOverview.scheduledWaitByType"));
    }),
    checkinWaitByType: Ember.computed("customersOverview.checkinWaitByType.[]", function () {
      return this.waitByType(this.get("customersOverview.checkinWaitByType"));
    }),
    visitWaitByType: Ember.computed("customersOverview.visitWaitByType.[]", function () {
      return this.waitByType(this.get("customersOverview.visitWaitByType"));
    }),
    checkoutWaitByType: Ember.computed("customersOverview.checkoutWaitByType.[]", function () {
      return this.waitByType(this.get("customersOverview.checkoutWaitByType"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[1],
          quantity: s[2],
          duration: s[3]
        };
      });
    },
    todayMore: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") > this.get("todayStats.yesterday");
    }),
    todaySame: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") === this.get("todayStats.yesterday");
    }),
    todayMoreIncreased: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return Math.abs(this.get("todayStats.today") - this.get("todayStats.yesterday"));
    }),
    sexChartData: Ember.computed("sexStats.females", "sexStats.males", function () {
      return {
        labels: [this.get('intl').t('stats.female'), this.get('intl').t('stats.male')],
        datasets: [{
          data: [this.get("sexStats.females"), this.get("sexStats.males")],
          backgroundColor: ["#ED5565", "#6BCABA"],
          hoverBackgroundColor: ["#ED5565", "#6BCABA"]
        }]
      };
    }),
    actions: {
      sendOpenExport: function sendOpenExport() {
        var self = this;
        this.send("openModal", {
          entity: "export",
          action: "export",
          model: {
            func: function func(before, after) {
              self.export(before, after);
            }
          }
        });
      }
    },
    export: function _export(before, after) {
      this.set("exporting", true);
      var self = this;
      var startedAfter = (0, _moment.default)(after).startOf("day").add(1, "h").toISOString();
      var startedBefore = (0, _moment.default)(before).endOf("day").add(1, "h").toISOString();
      Ember.$.ajax({
        method: 'GET',
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/customers/xlsx?company=" + self.get("organization.company.id") + "&createdBefore=" + startedBefore + "&createdAfter=" + startedAfter,
        xhrFields: {
          responseType: 'blob'
        },
        success: function success(blob) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var url = url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "customers-" + new Date().toISOString().slice(0, 10) + ".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          self.set("exporting", false);
        },
        error: function error() {
          self.set("exporting", false);
        }
      });
    }
  });

  _exports.default = _default;
});