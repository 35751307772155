define("crm/controllers/organizations/organization/inventory/excess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [{
        "propertyName": "Item",
        "title": " ",
        "className": "column-id"
      }, {
        "propertyName": "Description",
        "title": this.get('intl').t("items.item.name"),
        "className": "column-contact"
      }, {
        "propertyName": "Unit",
        "title": this.get('intl').t("units.unit.title"),
        "className": "column-sex"
      }, {
        "propertyName": "Warehouse",
        "title": this.get('intl').t("warehouses.warehouse.title"),
        "className": "column-contact"
      }, {
        "propertyName": "Stock",
        "title": this.get('intl').t("items.item.stock"),
        "className": "column-id text-right",
        "component": "umt-number"
      }, {
        "propertyName": "MaxStock",
        "title": this.get('intl').t("items.item.maxStock"),
        "className": "column-id text-right"
      }];
      return listCollumns;
    })
  });

  _exports.default = _default;
});