define("crm/routes/organizations/organization/group/waiting-room", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model() {
      return this.modelFor("organizations.organization");
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set("organizaiton", model);
      controller.set("company", model.get("company"));
      var companyID = model.get("company.id");
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/appointments/overview?company=" + companyID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("appointmentsOverview", result);
        }
      });
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sex?company=" + companyID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("sexStats", result);
        }
      });
      /*Ember.$.get({url: ENV.universe.api.host + "/" + ENV.universe.api.namespace + "/stats/type?company=" + companyID,
                  beforeSend: function(request) {request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'))},
                  success (result) { controller.set("typeStats", result) }
                 });*/

      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/appointments/today?company=" + companyID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("todayStats", result);
        }
      });
      /*Ember.$.get({url: ENV.universe.api.host + "/" + ENV.universe.api.namespace + "/stats/averageTime?company=" + companyID,
                  beforeSend: function(request) {request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'))},
                  success (result) {
                       result.forEach(function(x) {
                          x.clinicperson = self.store.peekRecord("clinic-person", x.worker);
                          x.avg = x.avg.toFixed(0);
                      });
                       controller.set("totalActions", result.sort((a,b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0))) }
                 });*/
    }
  });

  _exports.default = _default;
});