define("crm/components/note-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        var self = this;
        this.get("store").findRecord("note", this.get("model.id")).then(function (savedNote) {
          self.set("model.started", (0, _moment.default)(savedNote.get("started")).format("YYYY-MM-DD"));
        });
      }
    },
    maxDate: Ember.computed("model.started", function () {
      if (this.get("model.started")) {
        return (0, _moment.default)().endOf("day").format("YYYY-MM-DD");
      }
    }),
    contacts: Ember.computed("model.customer.contacts.[]", "model.patient.owner.contacts.[]", function () {
      if (this.get("model.customer.contacts")) {
        return this.get("model.customer.contacts");
      }

      if (this.get("model.patient.owner.contacts")) {
        return this.get("model.patient.owner.contacts");
      }
    }),
    patients: Ember.computed("model.customer.animals.[]", function () {
      if (this.get("model.customer.animals")) {
        return this.get("model.customer.animals");
      }
    }),
    close: function close() {
      Ember.$("#modal-note-edit").modal("hide");
    },
    actions: {
      createNote: function createNote() {
        this.set('disabledSubmission', true);
        var self = this;
        this.set("model.noteType", this.get("noteType"));
        this.set("model.outcomeType", this.get("outcomeType"));
        this.get("model").save().then(function () {
          self.close();
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set('disabledSubmission', false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});