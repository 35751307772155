define("crm/routes/organizations/organization/quotations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      return this.store.query("quotation", {
        organization: organization.get("id"),
        customerNull: true
      });
    }
  });

  _exports.default = _default;
});