define("crm/controllers/organizations/organization/stats/customers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    canDoStatsCustomers: Ember.computed("can", function () {
      return this.get("can").can("do stat");
    }),
    columns: Ember.computed('intl', function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "created",
        "title": this.get('intl').t("customers.customer.customerSince"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-datetime",
        "className": "column-date-time"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer"
      }, {
        "propertyName": "nif",
        "searchable": true,
        "className": "column-vat text-right"
      }, {
        "propertyName": "stickyNote",
        "title": this.get('intl').t("customers.customer.stickyNote"),
        "searchable": true,
        "className": "column-type hidden-xs"
      }];
    })
  });

  _exports.default = _default;
});