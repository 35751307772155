define("crm/models/module-type", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    parent: _emberData.default.belongsTo("module-type"),
    children: _emberData.default.hasMany("module-type"),
    enable: _emberData.default.attr("boolean"),
    code: _emberData.default.attr("string"),
    description: _emberData.default.attr("string")
  });

  _exports.default = _default;
});