define("crm/components/sheltered-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      editShelteredModal: function editShelteredModal() {
        this.sendAction('openEditShelteredModal', this.get('model'));
      },
      delete: function _delete(id) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.sendAction("delete", id);
        }
      },
      enable: function enable(object) {
        this.sendAction("enable", object);
      },
      disable: function disable(object) {
        this.sendAction("disable", object);
      }
    }
  });

  _exports.default = _default;
});