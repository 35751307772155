define("crm/components/purchase-order-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disabledAction: false,
    newPurchaseOrder: {},
    purchaseOrderLines: [],
    close: function close() {
      Ember.$("#modal-purchase-order-new").modal("hide");
    },
    disabledSubmissionPONew: Ember.computed("purchaseOrderLines.@each.line", function () {
      return this.get("purchaseOrderLines.length") ? false : true;
    }),
    actions: {
      setSelectedItem: function setSelectedItem(item) {
        var self = this;
        var exists = false;

        if (this.get("purchaseOrderLines.length") > 0) {
          this.get("purchaseOrderLines").forEach(function (purchaseOrderLine) {
            for (var i = 0; i < self.get("purchaseOrderLines.length"); i++) {
              var line = self.get("purchaseOrderLines")[i];

              if (line.get("item") && item.get("id") === purchaseOrderLine.get("item.id")) {
                exists = true;
                break;
              }
            }

            if (exists) {
              purchaseOrderLine.set("quantity", Number(purchaseOrderLine.get("quantity")) + 1);
            } else {
              self.get("purchaseOrderLines").addObject(self.get("store").createRecord("purchase-order-line", {
                item: item,
                quantity: 1
              }));
            }
          });
        } else {
          this.get("purchaseOrderLines").addObject(this.get("store").createRecord("purchase-order-line", {
            item: item,
            quantity: 1
          }));
        }
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        this.set("newPurchaseOrder", {
          organization: this.get("organization")
        });
        this.set("purchaseOrderLines", []);
        this.set("disabledAction", false);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.set("newPurchaseOrder.date", (0, _moment.default)());
        this.set("newPurchaseOrder.organization", this.get("organization"));
        var newPurchaseOrder = this.get("store").createRecord("purchase-order", this.get("newPurchaseOrder"));
        newPurchaseOrder.save().then(function (purchaseOrder) {
          self.get("purchaseOrderLines").forEach(function (purchaseOrderLine) {
            purchaseOrderLine.set("purchaseOrder", purchaseOrder);
            purchaseOrderLine.save().catch(function (error) {
              self.get("flashMessages").danger(error);
              self.get("store").unloadRecord(purchaseOrderLine);
              self.set("disabledAction", false);
            });
          });
          self.sendAction("reloadModel");
          self.close();
          self.get("flashMessages").success(self.get("intl").t("purchaseOrder.messages.createdSuccess"));
        }).catch(function () {
          self.get("store").unloadRecord(newPurchaseOrder);
          self.set("disabledAction", false);
        });
      },
      deletePurchaseOrderLine: function deletePurchaseOrderLine(line) {
        this.get("purchaseOrderLines").removeObject(line);
      }
    }
  });

  _exports.default = _default;
});