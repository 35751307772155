define("crm/components/item-crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    baseUnits: Ember.computed("units.@each.isBaseUnit", function () {
      return this.get("units").filterBy("isBaseUnit", true);
    })
  });

  _exports.default = _default;
});