define("crm/controllers/organizations/organization/warehouses/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    canDoWarehouses: Ember.computed("can", function () {
      return this.get("can").can("do procurement");
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "internalDescription",
        "title": this.get("intl").t("warehouses.warehouse.title"),
        "routeName": "organizations.organization.warehouses.warehouse",
        "routeProperty": "id",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "description",
        "title": this.get("intl").t("warehouses.warehouse.description")
      }, {
        "component": "warehouse-actions",
        "className": "column-actions-3"
      }];
    }),
    actions: {
      openModalCreateWarehouse: function openModalCreateWarehouse() {
        this.send("openModal", {
          entity: "warehouse",
          action: "create"
        });
      },
      openEditWarehouseModal: function openEditWarehouseModal(warehouseToEdit) {
        this.send("openEditWarehouse", warehouseToEdit);
      },
      openDetails: function openDetails(warehouse) {
        this.send("openModal", {
          entity: "warehouse",
          action: "details",
          model: warehouse
        });
      },
      export: function _export() {
        var dateMoment = (0, _moment.default)();
        var self = this;
        return Ember.$.ajax({
          method: "GET",
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/today/xlsx?organization=" + self.get("model.firstObject.organization.id") + "&date=" + dateMoment.format("YYYY-MM-DD"),
          xhrFields: {
            responseType: "blob"
          },
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "Stock Atual - " + dateMoment.format("YYYY-MM-DD") + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          },
          error: function error() {
            return {};
          }
        });
      }
    }
  });

  _exports.default = _default;
});