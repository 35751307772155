define("crm/controllers/organizations/organization/templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    section: [],
    fields: [{}],
    control: 0,
    actions: {
      createTemplate: function createTemplate() {
        this.set("disabledSubmission", true);
        var self = this;
        this.set("model.code", this.get("model.name"));
        this.set("model.description", this.get("model.name"));
        this.set("model.enable", true);
        this.get("store").createRecord("template", this.get("model")).save().then(function (template) {
          self.set("section.template", template);
          self.set("section.organization", self.get("model.organization"));
          self.set("section.enable", true);
          self.set("section.code", self.get("section.name"));
          self.get("store").createRecord("template-section", self.get("section")).save().then(function (templateSection) {
            self.get("fields").forEach(function (field) {
              field.templateSection = templateSection;
              field.organization = self.get("model.organization");
              field.enable = true;
              field.code = field.name;
              field.orderNumber = self.get("control") + 1;
              self.set("control", self.get("control") + 1);
              self.get("store").createRecord("template-section-field", field).save().then(function () {
                self.set("section", []);
                self.set("fields", [{}]);
                self.set("control", 0);
                self.set("disabledSubmission", false);
                self.transitionToRoute('organizations.organization.templates');
              });
            });
          });
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledSubmission", false);
        });
      },
      addTemplateField: function addTemplateField() {
        this.get('fields').addObject({});
      },
      removeTemplateField: function removeTemplateField(templateField) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get('fields').removeObject(templateField);
        }
      },
      canceled: function canceled() {
        this.transitionToRoute('organizations.organization.templates');
      }
    }
  });

  _exports.default = _default;
});