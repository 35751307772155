define("crm/controllers/organizations/organization/group/customers/new", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: ["before", "after"],
    canDoGroupCustomersNew: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    columns: Ember.computed('intl', function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "created",
        "title": this.get('intl').t("customers.customer.customerSince"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-datetime",
        "className": "column-date-time"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer"
      }, {
        "propertyName": "nif",
        "searchable": true,
        "className": "column-vat text-right"
      }, {
        "propertyName": "stickyNote",
        "title": this.get('intl').t("customers.customer.stickyNote"),
        "searchable": true,
        "className": "column-type hidden-xs"
      }];
    }),
    export: function _export(before, after) {
      this.set("exporting", true);
      var self = this;
      var startedAfter = (0, _moment.default)(after).startOf("day").add(1, "h").toISOString();
      var startedBefore = (0, _moment.default)(before).endOf("day").add(1, "h").toISOString();
      Ember.$.ajax({
        method: 'GET',
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/customers/xlsx?company=" + self.get("company.id") + "&createdBefore=" + startedBefore + "&createdAfter=" + startedAfter,
        xhrFields: {
          responseType: 'blob'
        },
        success: function success(blob) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var url = url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "customers-" + new Date().toISOString().slice(0, 10) + ".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          self.set("exporting", false);
        },
        error: function error() {
          self.set("exporting", false);
        }
      });
    },
    actions: {
      sendOpenExport: function sendOpenExport() {
        var self = this;
        this.send("openModal", {
          entity: "export",
          action: "export",
          model: {
            func: function func(before, after) {
              self.export(before, after);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});