define("crm/components/item-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      editItemModal: function editItemModal(item) {
        var self = this;
        this.get('store').findRecord('item', item.get('id')).then(function (itemFound) {
          self.sendAction('openEditItemModal', itemFound);
        });
      },
      openMoveStockModal: function openMoveStockModal() {
        this.sendAction('openMoveStockModal', this.get("record"));
      },
      openTransferStockModal: function openTransferStockModal() {
        this.sendAction('openTransferStockModal', this.get("record"));
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
      }
    }
  });

  _exports.default = _default;
});