define("crm/models/consultation-template-section-field", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    notes: _emberData.default.attr("string"),
    consultation: _emberData.default.belongsTo("consultation"),
    templateSectionField: _emberData.default.belongsTo("template-section-field", {
      inverse: "consultationTemplateSectionFields"
    }),
    templateSection: Ember.computed("templateSectionField.templateSection.id", function () {
      if (!Ember.isEmpty(this.get("templateSectionField")) && !Ember.isEmpty(this.get("templateSectionField.templateSection.id"))) {
        return this.get("templateSectionField.templateSection");
      }
    }),
    template: Ember.computed("templateSection.template.id", function () {
      if (!Ember.isEmpty(this.get("templateSection")) && !Ember.isEmpty(this.get("templateSection.template.id"))) {
        return this.get("templateSection.template");
      }
    }),
    type: Ember.computed("templateSectionField", function () {
      return this.get("templateSectionField");
    }),
    value: Ember.computed("notes", function () {
      return this.get("notes");
    })
  });

  _exports.default = _default;
});