define("crm/components/customer-merge", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    flashMessages: Ember.inject.service(),
    es: Ember.inject.service(),
    disabledAction: Ember.computed("invalidMerge", "oldCustomer", "newCustomer", "newCustomer.currentAccount", function () {
      return this.get("invalidMerge") || Ember.isEmpty(this.get("oldCustomer")) || Ember.isEmpty(this.get("newCustomer")) || this.get("newCustomer.currentAccount");
    }),
    invalidMerge: Ember.computed("oldCustomer", "newCustomer", function () {
      return this.get("oldCustomer.id") && this.get("newCustomer.id") && this.get("oldCustomer.id") === this.get("newCustomer.id");
    }),
    actions: {
      merge: function merge() {
        var self = this;

        if (confirm(this.get('intl').t('customers.merge.messages.confirm'))) {
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/customers/merge" + "?oldCustomerId=" + self.get("oldCustomer.id") + "&newCustomerId=" + self.get("newCustomer.id"),
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            success: function success(mergedCustomer) {
              self.get("flashMessages").success(self.get("intl").t("customers.merge.messages.success"), {
                sticky: true
              });
              self.sendAction("transitionToRouteID", "organizations.organization.customers.customer", mergedCustomer.id);
            },
            error: function error(e) {
              self.get("flashMessages").danger(self.get("intl").t("customers.merge.messages.error"), {
                sticky: true
              });
              self.get("es").handle(e);
            },
            finally: function _finally() {
              self.close();
            }
          });
        }

        this.close();
      },
      reset: function reset() {
        this.set("oldCustomer", null);
        this.set("newCustomer", null);
      },
      canceled: function canceled() {
        this.send("reset");
        this.close();
      }
    }
  });

  _exports.default = _default;
});