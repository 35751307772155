define("crm/routes/organizations/organization/laboratories/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      created: function created(id) {
        this.transitionTo('organizations.organization.laboratories.laboratory', id);
      },
      canceled: function canceled() {
        this.transitionTo('organizations.organization.laboratories');
      }
    }
  });

  _exports.default = _default;
});