define("crm/controllers/organizations/organization/customers/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["search"],
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    disabled: false,
    oldString: null,
    columns: Ember.computed("model", function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer",
        "className": "text-strong",
        "sortPrecedence": 0
      }, {
        "propertyName": "alert",
        "title": this.get('intl').t("patients.patient.alert"),
        "className": "hidden-xs"
      }, {
        "propertyName": "stickyNote",
        "title": this.get('intl').t("customers.customer.stickyNote"),
        "className": "hidden-xs hidden-sm"
      }, {
        "propertyName": "nif",
        "className": "hidden"
      }, {
        "component": "customer-actions",
        "className": "column-actions-3"
      }];
    }),
    actions: {
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      mergeCustomer: function mergeCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "merge"
        });
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.send("updateCustomerModal", record);
      },
      search: function search(query) {
        this.set("search", query);
      },
      searchInactiveCustomers: function searchInactiveCustomers(query) {
        this.set("search", query);
      },
      setCustomersTab: function setCustomersTab() {
        this.set('filterDisable', undefined);
      },
      setInactiveCustomersTab: function setInactiveCustomersTab() {
        this.set('filterDisable', true);
      },
      export: function _export() {
        var self = this;
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/customers/xlsx?organization=" + self.get("model.id"),
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "customers-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});