define("crm/routes/organizations/organization/listings/sales/users/user", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    model: function model(params) {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      var since = (0, _moment.default)(dateFrom).startOf("day").format();
      var until = (0, _moment.default)(dateTo).endOf("day").format();
      var self = this;

      if ((0, _moment.default)(until).diff(since, "days") <= this.get("dateLimit")) {
        return Ember.$.get({
          url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/sales/lines/users/" + params.user_id + "?organization=" + organizationID + "&dateFrom=" + (0, _moment.default)(since).toISOString() + "&dateTo=" + (0, _moment.default)(until).toISOString()),
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        });
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});