define("crm/routes/organizations/organization/internments/overview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do internment')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var openAfter = (0, _moment.default)().startOf('hour').subtract(2, 'd').format();
      return Ember.RSVP.hash({
        internments: this.get("store").query("internment", {
          organization: this.modelFor("organizations.organization").get("id"),
          open: true
        }),
        chores: this.store.query("todo-chore", {
          organization: organizationID,
          openAfter: openAfter
        }),
        drugs: this.store.query("todo-drug", {
          organization: organizationID,
          openAfter: openAfter
        }),
        exames: this.store.query("todo-exam", {
          organization: organizationID,
          openAfter: openAfter
        }),
        monitorings: this.store.query("todo-monitoring", {
          organization: organizationID,
          openAfter: openAfter
        }),
        serums: this.store.query("todo-serum", {
          organization: organizationID,
          openAfter: openAfter
        }),
        actions: this.store.query("action", {
          organization: organizationID,
          startedAfter: (0, _moment.default)().startOf('hour').subtract(3, 'd').subtract(3, 'h').subtract(1, 'ms').format()
        })
      }).then(function (r) {
        return r.internments;
      });
    }
  });

  _exports.default = _default;
});