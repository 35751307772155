define("crm/models/debt-debt-status", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    debt: _emberData.default.belongsTo("debt", {
      inverse: "debtDebtStatuses"
    }),
    debtStatus: _emberData.default.belongsTo("debt-status", {
      inverse: "debtDebtStatuses"
    })
  });

  _exports.default = _default;
});