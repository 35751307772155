define("crm/models/financial-account", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    financialAccountType: _emberData.default.attr("number"),
    description: _emberData.default.attr("string"),
    accountNumber: _emberData.default.attr("string"),
    iban: _emberData.default.attr("string"),
    swift: _emberData.default.attr("string"),
    isManual: _emberData.default.attr("boolean"),
    isOpen: _emberData.default.attr("boolean"),
    externalID: _emberData.default.attr("string"),
    internalDescription: _emberData.default.attr("string"),
    organization: _emberData.default.belongsTo("organization"),
    invoices: _emberData.default.hasMany("invoice"),
    receipts: _emberData.default.hasMany("receipt"),
    purchaseInvoices: _emberData.default.hasMany("purchase-invoice"),
    payments: _emberData.default.hasMany("payment"),
    financialAccountOpens: _emberData.default.hasMany("financial-account-open"),
    financialAccountCloses: _emberData.default.hasMany("financial-account-close"),
    isEmptyOrClosed: Ember.computed("isOpen", function () {
      return Ember.isEmpty(this.get("isOpen")) || this.get("isOpen") == false;
    })
  });

  _exports.default = _default;
});