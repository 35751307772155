define("crm/models/laboratory", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    address: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    mobilePhone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization')
  });

  _exports.default = _default;
});