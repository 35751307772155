define("crm/helpers/numbers-subtraction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NumbersSubtraction = NumbersSubtraction;
  _exports.default = void 0;

  function NumbersSubtraction() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var var1 = params[0] || 0;
      var var2 = params[1] || 0;
      return var1 - var2;
    }
  });

  _exports.default = _default;
});