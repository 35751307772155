define("crm/routes/organizations/organization/purchase-invoices/purchase-invoice/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      if (model.get("paidAmount")) {
        model.set("paid", true);
      }

      if (!model.get("date")) {
        model.set("date", new Date());
      }

      return Ember.RSVP.hash({
        items: this.store.query("item", {
          purchaseInvoice: model.get("id")
        }),
        itemUnits: this.store.query("item-unit", {
          purchaseInvoice: model.get("id")
        }),
        purchaseInvoiceLines: this.store.query("purchase-invoice-line", {
          purchaseInvoice: model.get("id")
        }),
        purchaseOrders: this.store.query("purchase-order", {
          purchaseInvoice: model.get("id")
        }),
        purchaseOrderLines: this.store.query("purchase-order-line", {
          purchaseInvoice: model.get("id")
        }),
        supplierItems: this.store.query("supplier-item", {
          purchaseInvoice: model.get("id")
        })
      }).then(function (hash) {
        if (hash.purchaseInvoiceLines && hash.purchaseInvoiceLines.get("length")) {
          var aux = {};
          var placement = 1;
          /* supplierItems */

          if (hash.supplierItems) {
            hash.supplierItems.forEach(function (si) {
              return aux[si.get("item.id")] = si;
            });
          }

          hash.purchaseInvoiceLines.sortBy("name").sortBy("id").sortBy("placement").forEach(function (line) {
            if (line.get("placement") && line.get("placement") > placement) {
              placement = line.get("placement");
            }
          });
          hash.purchaseInvoiceLines.sortBy("placement").forEach(function (line) {
            /* linePriceWithVat */
            if (line.get("linePrice") && line.get("tax.vat.amount")) {
              line.set("linePriceWithVat", line.get("linePrice") * (100 + line.get("tax.vat.amount")) / 100);
            }
            /* supplierItem */


            if (aux[line.get("item.id")]) {
              line.set("supplierItem", aux[line.get("item.id")]);
              line.set("supplierId", aux[line.get("item.id")].get("supplierId"));
            }
            /* Warehouse */


            if (model.get("warehouse.id")) {
              line.set("warelhouse", model.get("warehouse"));
            }

            if (line.get("item.stockItemUnit.quantity")) {
              line.set("itemUnit", line.get("item.stockItemUnit"));
            }
            /* placement */


            if (!line.get("placement")) {
              line.set("placement", placement++);
            }
          });
        }
      });
    }
  });

  _exports.default = _default;
});