define("crm/controllers/organizations/organization/stats/sales/documents/invoices", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    shift: Ember.computed("model.createdBefore", "model.createdBefore2", function () {
      return this.get("model.createdBefore") && this.get("model.createdBefore2") ? (0, _moment.default)(this.get("model.createdBefore2")).diff(this.get("model.createdBefore"), this.get("windowsUnit")) : 0;
    }),
    chartDateInit: Ember.computed("model.createdAfter", function () {
      return this.get("model.createdAfter");
    }),
    window: Ember.computed("model.createdAfter", "model.createdBefore", function () {
      if (this.get("model.createdAfter") && this.get("model.createdBefore")) {
        return (0, _moment.default)(this.get("model.createdBefore")).diff(this.get("model.createdAfter"), this.get("windowsUnit"));
      } else {
        return 0;
      }
    }),
    windowsUnit: Ember.computed(function () {
      return "d";
    }),
    windowStep: Ember.computed(function () {
      return 1;
    }),
    chartOptions: {
      //legend: { display: false },
      //responsive: true,
      height: "200px",
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    days: Ember.computed("chartDateInit", "window", "windowStep", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i <= this.get("window"); i++) {
        result.addObject((0, _moment.default)(result.objectAt(i - 1)).add(this.get("windowStep"), this.get("windowsUnit")).format("YYYY-MM-DD"));
      }

      return result;
    }),
    chartData2: Ember.computed("model.model1.[]", "model.model2.[]", function () {
      if (!this.get("model.model1") && !this.get("model.model2")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("model.model1")).map(function (d) {
          return d.name;
        }),
        datasets: [{
          data: this.get("model.model1").map(function (d) {
            return d.value;
          })
        }, {
          data: this.get("model.model2").map(function (d) {
            return d.value;
          })
        }]
      };
    }),
    values1: Ember.computed("days.[]", "model.model1.[]", function () {
      var _this = this;

      var result = [];
      this.get("days").forEach(function (d) {
        var flag = false;

        _this.get("model.model1").forEach(function (x) {
          if (d === x[0]) {
            flag = true;
            result.addObject({
              name: x[0],
              value: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: d,
            value: 0
          });
        }
      });
      return result;
    }),
    values2: Ember.computed("days.[]", "model.model2.[]", function () {
      var _this2 = this;

      var result = [];
      this.get("days").forEach(function (d) {
        var flag = false;
        var dayShift = (0, _moment.default)(d).add(_this2.get("shift"), _this2.get("windowsUnit")).format("YYYY-MM-DD");

        _this2.get("model.model2").forEach(function (x) {
          if (dayShift === x[0]) {
            flag = true;
            result.addObject({
              name: dayShift,
              value: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: dayShift,
            value: 0
          });
        }
      });
      return result;
    }),
    chartData: Ember.computed("values1.[]", "values2.[]", function () {
      if (!this.get("values1") && !this.get("values2")) {
        return [];
      }

      return {
        labels: this.get("days"),
        datasets: [{
          label: "Periodo actual",
          data: this.get("values1").map(function (x) {
            return x.value;
          }),
          backgroundColor: 'rgba(26,179,148,0.5)',
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff"
        }, {
          label: "Periodo homologo",
          data: this.get("values2").map(function (x) {
            return x.value;
          }),
          backgroundColor: 'rgba(220, 220, 220, 0.5)',
          pointBorderColor: "#fff"
        }]
      };
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "Dia",
        "component": "umt-date",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "value",
        "title": "Faturas",
        "component": "umt-price-display",
        "className": "column-price-medium text-right text-xs-small"
      }];
    })
  });

  _exports.default = _default;
});