define("crm/controllers/organizations/organization/purchase-memos/new", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    showLoading: false,
    savingPurchase: false,
    movesStock: false,
    disabledSubmission: Ember.computed("model.purchaseInvoice.id", "model.name", "model.payableAmount", "model.date", "model.supplier.id", "savingPurchase", "model.checkNumber", "model.checkDate", "model.linesAux.@each.quantity", function () {
      var disabled = false;

      if (Ember.isEmpty(this.get("model.name")) || Ember.isEmpty(this.get("model.payableAmount")) || Ember.isEmpty(this.get("model.date")) || Ember.isEmpty(this.get("model.supplier.id")) || Ember.isEmpty(this.get("model.purchaseInvoice.id"))) {
        disabled = true;
      }

      if (!Ember.isEmpty(this.get("model.linesAux"))) {
        this.get("model.linesAux").forEach(function (line) {
          if (Ember.get(line, "quantity") == 0 || Ember.isEmpty(Ember.get(line, "quantity"))) {
            disabled = true;
          }
        });
      }

      if (this.get("savingPurchase")) {
        disabled = true;
      }

      return disabled;
    }),
    pricesMatch: Ember.computed("itemsTotalPriceDiscounted", "model.payableAmount", "model.linesAux.[]", function () {
      return this.get("itemsTotalPriceDiscounted") == this.get("model.payableAmount");
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 2).get("length") > 0;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 1).get("length") > 0 || this.get("paymentMethod") === "DEB";
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    itemsTotalPrice: Ember.computed("model.linesAux.@each.linePriceWithVat", function () {
      var self = this;
      var finalPrice = 0;

      if (this.get("model.linesAux.length")) {
        this.get("model.linesAux").forEach(function (item) {
          if (Ember.get(item, "linePriceWithVat")) {
            finalPrice += self.money(Ember.get(item, "linePriceWithVat"));
          }
        });
        return this.money(finalPrice);
      } else {
        return null;
      }
    }),
    itemsTotalPriceDiscounted: Ember.computed("itemsTotalPrice", "model.discount", function () {
      return this.get("model.discount") ? (this.get("itemsTotalPrice") * (100 - this.get("model.discount")) / 100).toFixed(2) : this.get("itemsTotalPrice");
    }),
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    actions: {
      addItem: function addItem(selectedItem, itemQuantity) {
        var self = this;
        this.set("showLoading", true);
        var newLine = {
          item: selectedItem,
          index: this.get("model.linesAux.length")
        };
        this.get("store").query("purchase-memo-line", {
          item: selectedItem.get("id"),
          top: 1
        }).then(function (savedLines) {
          self.get("store").query("item-unit", {
            item: selectedItem.get("id")
          }).then(function () {
            self.get("store").query("supplier-item", {
              item: selectedItem.get("id"),
              supplier: self.get("model.supplier.id")
            }).then(function (supplierItems) {
              if (!Ember.isEmpty(savedLines)) {
                var lastLine = savedLines.objectAt(0);
                Ember.set(newLine, "lastPurchaseMemoLine", lastLine);
                Ember.set(newLine, "discount", lastLine.get("discount"));
                Ember.set(newLine, "linePrice", lastLine.get("linePrice"));
                Ember.set(newLine, "quantity", lastLine.get("quantity"));
                Ember.set(newLine, "unitPrice", lastLine.get("unitPrice"));
                Ember.set(newLine, "warehouse", self.get("model.warehouse"));
                Ember.set(newLine, "discount2", lastLine.get("discount2"));
                Ember.set(newLine, "discountMoney", lastLine.get("discountMoney"));
                Ember.set(newLine, "lot", lastLine.get("lot"));
                Ember.set(newLine, "quantityPromotion", lastLine.get("quantityPromotion"));
                Ember.set(newLine, "shelfLife", lastLine.get("shelfLife") ? (0, _moment.default)(lastLine.get("shelfLife")).format("YYYY-MM-DD") : null);
                Ember.set(newLine, "vat", lastLine.get("vat"));
              }

              if (self.get("model.supplier.id")) {
                Ember.set(newLine, "supplier", self.get("model.supplier"));

                if (Ember.isEmpty(supplierItems)) {
                  Ember.set(newLine, "supplierItem", null);
                } else {
                  Ember.set(newLine, "supplierItem", supplierItems.objectAt(0));
                  Ember.set(newLine, "supplierId", supplierItems.objectAt(0).get("supplierId"));
                }
              }

              if (itemQuantity) {
                Ember.set(newLine, "quantity2", itemQuantity);
              }

              self.get("model.linesAux").addObject(newLine);
            }).catch(function (e) {
              self.get("es").handle(e);
            });
          }).catch(function (e) {
            self.get("es").handle(e);
          });
        });
        this.set("showLoading", false);
      },
      supplierChanged: function supplierChanged(supplier) {
        var self = this;

        if (!Ember.isEmpty(this.get("model.linesAux")) && !Ember.isEmpty(supplier)) {
          this.get("model.linesAux").forEach(function (line) {
            self.get("store").query("supplier-item", {
              item: line.item.get("id"),
              supplier: supplier.get("id")
            }).then(function (supplierItems) {
              if (Ember.isEmpty(supplierItems)) {
                Ember.set(line, "supplierItem", null);
                Ember.set(line, "supplierId", null);
              } else {
                Ember.set(line, "supplierItem", supplierItems.objectAt(0));
                Ember.set(line, "supplierId", supplierItems.objectAt(0).get("supplierId"));
              }
            }).catch(function (e) {
              self.get("es").handle(e);
            });
          });
        }
      },
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        if (paymentMethod) {
          this.set("model.paymentMethod", paymentMethod);
        } else {
          this.set("model.paymentMethod", null);
        }
      },
      saveStock: function saveStock() {
        this.set("savingPurchase", true);
        var self = this;
        this.set("model.status", 0);
        this.set("model.movesStock", this.get("movesStock"));
        this.get("model").save().then(function (savedPurchaseMemo) {
          if (Ember.isEmpty(self.get("model.linesAux"))) {
            /*
             * No lines
             */
            self.transitionToRoute("organizations.organization.purchase-memos");
            self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfullySaved") + ": " + savedPurchaseMemo.get("name"), {
              sticky: true
            });
          } else {
            /*
             * With lines
             */
            var purchaseMemoLines = [];
            self.get("model.linesAux").forEach(function (line) {
              self.store.query("item-unit", {
                item: line.item.get("id")
              });

              if (line.unitPrice) {
                if (line.quantity > 0) {
                  if (!Ember.isEmpty(line.supplierItem)) {
                    line.supplierItem.set("supplierId", line.supplierId);
                    line.supplierItem.save();
                  } else if (!Ember.isEmpty(line.supplier) && !Ember.isEmpty(line.supplierId)) {
                    self.store.createRecord("supplier-item", {
                      supplier: line.supplier,
                      item: line.item,
                      supplierId: line.supplierId
                    }).save();
                  }

                  var purchaseMemoLine = {
                    name: line.item.get("name"),
                    discount: line.discount,
                    linePrice: line.linePrice,
                    quantity: line.quantity,
                    unitPrice: line.unitPrice,
                    purchasePrice: line.purchasePrice,
                    item: line.item.get("id"),
                    warehouse: self.get("model.warehouse.id"),
                    itemUnit: line.item.get("stockItemUnit.id"),
                    discount2: line.discount2,
                    discountMoney: line.discountMoney,
                    lot: line.lot,
                    quantityPromotion: line.quantityPromotion,
                    unitPriceWithVat: line.unitPriceWithVat,
                    shelfLife: line.shelfLife,
                    purchaseMemo: savedPurchaseMemo.get("id"),
                    vat: line.vat.get("id")
                  };
                  purchaseMemoLines.addObject(purchaseMemoLine);
                } else {
                  self.set("savingPurchase", false);
                  self.get("flashMessages").danger(self.get("intl").t("alerts.negativeStock"));
                }
              } else {
                self.set("savingPurchase", false);
                self.get("flashMessages").danger(self.get("intl").t("alerts.mustExistPrice"));
              }
            });
            Ember.$.ajax({
              method: "POST",
              beforeSend: function beforeSend(request) {
                return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              accept: "application/json",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(purchaseMemoLines),
              url: _environment.default.universe.api.wildfly + "/purchaseMemoLines/list"
            }).then(function () {
              self.set("savingPurchase", false);
              self.transitionToRoute("organizations.organization.procurement.documents");
              self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfullySaved") + ": " + savedPurchaseMemo.get("name"), {
                sticky: true
              });
            }, function (result) {
              self.set("savingPurchase", false);
              self.get("es").handle(result);
            });
          }
        }).catch(function (result) {
          self.set("savingPurchase", false);
          self.get("es").errorReturned(result);
        });
      },
      emitStock: function emitStock() {
        this.set("savingPurchase", true);
        var self = this;
        this.set("model.status", 1);
        this.get("model").save().then(function (savedPurchaseMemo) {
          if (Ember.isEmpty(self.get("model.linesAux"))) {
            /*
             * No lines
             */
            self.transitionToRoute("organizations.organization.purchase-memos");
            self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfully") + ": " + savedPurchaseMemo.get("name"), {
              sticky: true
            });
          } else {
            /*
             * With lines
             */
            var purchaseMemoLines = [];
            self.get("model.linesAux").forEach(function (line) {
              self.store.query("item-unit", {
                item: line.item.get("id")
              });

              if (line.unitPrice) {
                if (line.quantity > 0) {
                  if (!Ember.isEmpty(line.supplierItem)) {
                    line.supplierItem.set("supplierId", line.supplierId);
                    line.supplierItem.save();
                  } else if (!Ember.isEmpty(line.supplier) && !Ember.isEmpty(line.supplierId)) {
                    self.store.createRecord("supplier-item", {
                      supplier: line.supplier,
                      item: line.item,
                      supplierId: line.supplierId
                    }).save();
                  }

                  var purchaseMemoLine = {
                    name: line.item.get("name"),
                    discount: line.discount,
                    linePrice: line.linePrice,
                    quantity: line.quantity,
                    unitPrice: line.unitPrice,
                    purchasePrice: line.purchasePrice,
                    item: line.item.get("id"),
                    warehouse: self.get("model.warehouse.id"),
                    itemUnit: line.item.get("stockItemUnit.id"),
                    discount2: line.discount2,
                    discountMoney: line.discountMoney,
                    lot: line.lot,
                    quantityPromotion: line.quantityPromotion,
                    unitPriceWithVat: line.unitPriceWithVat,
                    shelfLife: line.shelfLife,
                    purchaseMemo: savedPurchaseMemo.get("id"),
                    vat: line.vat.get("id")
                  };
                  purchaseMemoLines.addObject(purchaseMemoLine);
                } else {
                  self.set("savingPurchase", false);
                  self.get("flashMessages").danger(self.get("intl").t("alerts.negativeStock"));
                }
              } else {
                self.set("savingPurchase", false);
                self.get("flashMessages").danger(self.get("intl").t("alerts.mustExistPrice"));
              }
            });
            Ember.$.ajax({
              method: "POST",
              beforeSend: function beforeSend(request) {
                return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              accept: "application/json",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(purchaseMemoLines),
              url: _environment.default.universe.api.wildfly + "/purchaseMemoLines/list"
            }).then(function () {
              self.set("savingPurchase", false);
              self.transitionToRoute("organizations.organization.procurement.documents");
              self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfully") + ": " + savedPurchaseMemo.get("name"), {
                sticky: true
              });
            }, function (result) {
              self.set("savingPurchase", false);
              self.get("es").handle(result);
            });
          }
        }).catch(function (result) {
          self.set("savingPurchase", false);
          self.get("es").errorReturned(result);
        });
      },
      deleteLine: function deleteLine(line) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          this.get("model.linesAux").removeObject(line);
        }
      },
      openModalCreateItem: function openModalCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      }
    },
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});