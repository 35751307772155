define("crm/routes/organizations/organization/procurement/documents/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      queryPurchaseRequests: {
        refreshModel: true
      },
      queryPurchaseOrders: {
        refreshModel: true
      },
      queryPurchaseInvoices: {
        refreshModel: true
      },
      queryPurchaseReceipts: {
        refreshModel: true
      },
      queryPurchaseMemos: {
        refreshModel: true
      },
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");

      if (params.createdAfter && (0, _moment.default)(params.createdAfter).isValid() && params.createdBefore && (0, _moment.default)(params.createdBefore).isValid()) {
        var createdAfter = (0, _moment.default)(params.createdAfter).startOf("day").format();
        var createdBefore = (0, _moment.default)(params.createdBefore).endOf("day").format();
        return Ember.RSVP.hash({
          purchaseRequests: params.queryPurchaseRequests ? this.get("store").query("purchase-request", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }) : null,
          purchaseOrders: params.queryPurchaseOrders ? this.get("store").query("purchase-order", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }) : null,
          purchaseInvoices: params.queryPurchaseInvoices ? this.get("store").query("purchase-invoice", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }) : null,
          purchaseReceipts: params.queryPurchaseReceipts ? this.get("store").query("purchase-receipt", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }) : null,
          purchaseMemos: params.queryPurchaseMemos ? this.get("store").query("purchase-memo", {
            organization: organizationID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }) : null,
          financialAccounts: this.get("store").query("financial-account", {
            organization: organizationID
          })
        }).then(function (r) {
          return {
            organization: organization,
            purchaseRequests: r.purchaseRequests,
            purchaseOrders: r.purchaseOrders,
            purchaseInvoices: r.purchaseInvoices,
            purchaseReceipts: r.purchaseReceipts,
            purchaseMemos: r.purchaseMemos
          };
        });
      } else {
        return {
          organization: this.modelFor("organizations.organization")
        };
      }
    }
  });

  _exports.default = _default;
});