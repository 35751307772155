define("crm/components/show-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    * Component variables
    */
    showMoreFields: false,

    /*
    * Actions
    */
    actions: {
      toggleShowMoreFields: function toggleShowMoreFields() {
        this.set('showMoreFields', !this.get('showMoreFields'));
      }
    }
  });

  _exports.default = _default;
});