define("crm/components/shopping-cart-line-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    discount: Ember.computed("model.discount", "model.unitPrice", "model.quantity", function () {
      var total = 0;

      if (this.get("model.discount") !== "0" && this.get("model.unitPrice") && this.get("model.quantity")) {
        total += Number(this.get("model.discount") * this.get("model.unitPrice") * this.get("model.quantity") / 100);
        return total.toFixed(2);
      }
    }),
    actions: {
      openShoppingCartLineDetails: function openShoppingCartLineDetails(model) {
        if (Ember.isEmpty(this.get("hideLinkDetails"))) {
          this.sendAction("openDetails", model);
        }
      },
      deleteLine: function deleteLine() {
        this.sendAction("deleteShoppingCartLine", this.get("model"));
      }
    }
  });

  _exports.default = _default;
});