define("crm/components/document-details", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    allowDelete: Ember.computed("can", function () {
      return this.get("can").can("delete invoice");
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      switch (this.get("model.constructor.modelName")) {
        case "invoice":
          if (!this.get("model.lines.length")) {
            Ember.RSVP.hash({
              receipts: this.get("store").query("receipt", {
                invoice: this.get("model.id")
              }),
              receiptInvoices: this.get("store").query("receipt-invoice", {
                invoice: this.get("model.id")
              }),
              items: this.get("store").query("item", {
                invoice: this.get("model.id")
              }),
              memos: this.get("store").query("memo", {
                invoice: this.get("model.id")
              })
            }).then(function () {
              return _this.get("store").query("invoice-line", {
                invoice: _this.get("model.id")
              });
            });
          }

          break;

        case "receipt":
          if (!this.get("model.lines.length")) {
            Ember.RSVP.hash({
              invoices: this.get("store").query("invoice", {
                receipt: this.get("model.id")
              }),
              receiptInvoices: this.get("store").query("receipt-invoice", {
                receipt: this.get("model.id")
              }),
              memos: this.get("store").query("memo", {
                receipt: this.get("model.id")
              }),
              receiptMemos: this.get("store").query("receipt-memo", {
                receipt: this.get("model.id")
              })
            });
          }

          break;

        case "memo":
          if (!this.get("model.lines.length")) {
            Ember.RSVP.hash({
              receipts: this.get("store").query("receipt", {
                memo: this.get("model.id")
              }),
              receiptMemos: this.get("store").query("receipt-memo", {
                memo: this.get("model.id")
              }),
              items: this.get("store").query("item", {
                memo: this.get("model.id")
              }),
              invoices: this.get("store").query("invoice", {
                memo: this.get("model.id")
              })
            }).then(function () {
              return _this.get("store").query("memo-line", {
                memo: _this.get("model.id")
              });
            });
          }

          break;

        case "order":
          if (!this.get("model.lines.length")) {
            Ember.RSVP.hash({
              lines: this.get("store").query("order-line", {
                order: this.get("model.id")
              }),
              items: this.get("store").query("item", {
                order: this.get("model.id")
              })
            });
          }

          break;

        case "quotation":
          if (!this.get("model.lines.length")) {
            Ember.RSVP.hash({
              lines: this.get("store").query("quotation-line", {
                quotation: this.get("model.id")
              }),
              items: this.get("store").query("item", {
                quotation: this.get("model.id")
              })
            });
          }

          break;

        default:
          this.set("documentLines", {});
      }
    },
    hideEmitPdf: Ember.computed("model.name", "model.constructor.modelName", function () {
      return this.get("model.name") === "Invoice" || this.get("model.name") === "Order" || this.get("model.name") === "Quotation" || this.get("model.name") === "Memo" || this.get("model.name") === "Receipt" || this.get("model.name") === "Payment" || this.get("model.constructor.modelName") === "purchase-invoice" || this.get("model.constructor.modelName") === "purchase-memo" || this.get("model.constructor.modelName") === "purchase-receipt" || !Ember.isEmpty(this.get("model.status")) && [4, 9, 3].includes(this.get("model.status"));
    }),
    saftByOrg: Ember.computed("model.[]", "model.organization.id", function () {
      return this.get("store").query("legal-declaration", {
        organization: this.get("model.organization.id")
      });
    }),
    mostRecentSaft: Ember.computed("saftByOrg.@each.endDate", function () {
      return this.get("saftByOrg").sortBy("endDate").get("lastObject");
    }),
    documentAnulable: Ember.computed("model.constructor.modelName", "model.status", "allowDelete", "model.created", "mostRecentSaft.endDate", "model.organization.settings.sales_invoices_freeDelete", function () {
      if (this.get("model")) {
        var model = this.get("model");

        if (this.get("can").can("do god")) {
          return true;
        }

        return (model.constructor.modelName == "invoice" && Ember.isEmpty(model.get("receipts")) || model.constructor.modelName == "memo" || model.constructor.modelName == "receipt" || model.constructor.modelName == "payment") && ![3, 4, 8, 9].includes(model.get("status")) && (model.constructor.modelName == "receipt" || Ember.isEmpty(this.get("mostRecentSaft.endDate")) || model.get("created") > this.get("mostRecentSaft.endDate")) && (this.get("allowDelete") || this.get("model.organization.settings.sales_invoices_freeDelete"));
      } else {
        return false;
      }
    }),
    shouldDisplayDetailsButton: Ember.computed("model.constructor.modelName", function () {
      if (this.get("model.constructor.modelName")) {
        return this.get("model.constructor.modelName") === "quotation" || this.get("model.constructor.modelName") === "invoice" || this.get("model.constructor.modelName") === "purchase-invoice";
      }
    }),
    motive: Ember.computed("model.motive", "model.organization.id", function () {
      if (this.get("model.motive")) {
        if (this.get("catalogs.memosMotives")) {
          var motives = this.get("catalogs.memosMotives");

          if (this.get("organization.id") == 70) {
            motives.push({
              id: 5,
              name: this.get("intl").t("memos.advance")
            });
          }

          var self = this;
          var motiveReason;
          motives.forEach(function (motive) {
            if (motive.id === Number(self.get("model.motive")) - 1) {
              motiveReason = motive.name;
            }
          });
        }

        return motiveReason;
      }
    }),
    close: function close() {
      Ember.$("#modal-document-details").modal("hide");
      this.sendAction("reset");
    },
    actions: {
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      emitSecond: function emitSecond(record) {
        var self = this;
        var modelName = record.constructor.modelName;
        var id = record.get("id");
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + modelName + "s" + "/" + self.get("model.id") + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id).then(function (savedDocument) {
              self.set("exporting", false);
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("name") + ".pdf";
              link.click();
            });
          }
        });
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction('openUpdateCustomerModal', record);
      },
      annulDocument: function annulDocument(document) {
        this.close();
        this.sendAction("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      },
      reset: function reset() {
        this.set("model", null);
      },
      transitionToDocument: function transitionToDocument() {
        this.sendAction("transitionToRouteID", "organizations.organization." + this.get("model.constructor.modelName") + "s." + this.get("model.constructor.modelName"), this.get("model.id"));
        this.close();
      }
    }
  });

  _exports.default = _default;
});