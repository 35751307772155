define("crm/components/patient-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["card", "contact-box", "patient-card"],
    store: Ember.inject.service(),
    showGraphic: false,
    showActions: false,

    /* INJECTS */
    intl: Ember.inject.service(),

    /* PROPERTIES */
    displayedWeightsNumber: 2,
    displayedWeights: Ember.computed('model.weights.@each.weighted', 'displayedWeightsNumber', function () {
      if (this.get('model.weights.length') > 0) {
        return this.get('model.weights').sortBy('weighted').reverse().slice(0, this.get('displayedWeightsNumber'));
      }
    }),
    // Line weights graphic data
    data: Ember.computed('model.weights.[]', function () {
      if (this.get('model.weights.length') > 0) {
        var dates = this.get('model.weights').sortBy('weighted').map(function (animalWeight) {
          return (0, _moment.default)(animalWeight.get('weighted')).format('DD/MM/YYYY');
        });
        var weights = this.get('model.weights').sortBy('weighted').mapBy('weight');
        return {
          labels: dates,
          datasets: [{
            label: this.get('intl').t("patients.patient.weights.weight.title"),
            data: weights,
            fillColor: "#2f4050",
            backgroundColor: "rgba(220,220,220,0.2)",
            pointBackgroundColor: "rgba(220,220,220,1)",
            pointRadius: 2,
            pointHoverRadius: 2,
            pointHoverColor: "rgba(220,220,220,1)",
            pointStyle: 'circle',
            lineTension: 0
          }]
        };
      }
    }),
    // Line weights graphic options
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    actions: {
      showGraphic: function showGraphic() {
        this.set("showGraphic", !this.get("showGraphic"));
      },
      editPatientModal: function editPatientModal(model) {
        this.sendAction('openEditPatientModal', model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      enable: function enable(model) {
        this.sendAction("setEnabled", model.content ? model.content : model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      disable: function disable(model) {
        this.sendAction("setEnabled", model.content ? model.content : model);
      },
      createAppointmentModal: function createAppointmentModal(model) {
        this.sendAction('openCreateAppointmentModal', model);
      },
      toggleActions: function toggleActions() {
        this.set("showActions", !this.get("showActions"));
      }
    }
  });

  _exports.default = _default;
});