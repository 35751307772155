define("crm/components/stock-transfer-order-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    canAnnulStockTransfer: Ember.computed("model.status", "model.loadingDateTime", function () {
      return Ember.isEmpty(this.get("model.status")) && (0, _moment.default)(this.get("model.loadingDateTime")).isAfter((0, _moment.default)());
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.set("stockTransferOrderLines", this.get("store").query("stock-transfer-order-line", {
          stockTransferOrder: this.get("model.id")
        }));
      }
    },
    actions: {
      annulStockTransfer: function annulStockTransfer(document) {
        this.close();
        this.sendAction("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      }
    }
  });

  _exports.default = _default;
});