define("crm/components/loop-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    numOfTimes: Ember.computed('times', function () {
      var result = [];

      for (var i = 0; i < this.get("times"); i++) {
        result.push(i);
      }

      return result;
    })
  });

  _exports.default = _default;
});