define("crm/models/organization-communication", ["exports", "ember-data", "crm/models/organization"], function (_exports, _emberData, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organization.default.extend({
    sendHour: _emberData.default.attr("number"),
    sendMinute: _emberData.default.attr("number"),
    daysBefore: _emberData.default.attr("number"),
    vaccineText: _emberData.default.attr("string"),
    internalDewormingText: _emberData.default.attr("string"),
    externalDewormingText: _emberData.default.attr("string"),
    othersText: _emberData.default.attr("string"),
    consultationText: _emberData.default.attr("string"),
    vaccineAppointmentText: _emberData.default.attr("string"),
    wellBeingAppointmentText: _emberData.default.attr("string"),
    surgeryAppointmentText: _emberData.default.attr("string"),
    proceduresAppointmentText: _emberData.default.attr("string"),
    patientBirthdayText: _emberData.default.attr("string"),
    customerBirthdayText: _emberData.default.attr("string"),
    hour: Ember.computed("sendHour", "sendMinute", function () {
      var h = (Ember.isEmpty(this.get("sendHour")) || this.get("sendHour").toString().length === 1 ? "0" : "") + this.get("sendHour");
      var m = (Ember.isEmpty(this.get("sendMinute")) || this.get("sendMinute").toString().length === 1 ? "0" : "") + this.get("sendMinute");
      return h + ":" + m;
    })
  });

  _exports.default = _default;
});