define("crm/components/report-new", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pdf: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    es: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.template.id")) {
        this.set("fields", this.get("store").query("template-section-field", {
          template: this.get("model.template.id")
        }));
      }
    },
    customFormFields: Ember.computed("fields.[]", "fields.each.type", "fields.each.name", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("fields"))) {
        this.get("fields").forEach(function (field) {
          result.addObject({
            value: field.get("defaultValue"),
            type: {
              name: field.get("name"),
              options: field.get("optionsArray") ? field.get("optionsArray") : field.get("options"),
              type: field.get("type"),
              orderNumber: field.get("orderNumber")
            },
            templateSectionField: field
          });
        });
      }

      return result.sort(function (a, b) {
        return a.type.orderNumber - b.type.orderNumber;
      });
    }),
    reset: function reset() {
      this.close();
    },
    close: function close() {
      Ember.$("#modal-report-new").modal("hide");
    },
    actions: {
      reset: function reset() {//this.close();
      },
      save: function save(formData) {
        var self = this;
        var fields = [];
        formData.forEach(function (field) {
          fields.addObject({
            notes: field.value,
            templateSectionField: field.templateSectionField.id
          });
        });
        var report = {
          consultation: self.get("model.consultation.id"),
          patient: self.get("model.patient.id"),
          template: self.get("model.template.id"),
          reportFields: fields
        };
        Ember.$.ajax({
          type: "POST",
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/reports/fields",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          accept: "application/json",
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(report),
          success: function success(report) {
            self.get("store").findRecord("report", report.id, {
              reload: true
            }).then(function (savedReport) {
              report = savedReport;
            });

            if (self.get("model.consultation.id")) {
              self.get("store").query("report-section", {
                consultation: self.get("model.consultation.id")
              }, {
                reload: true
              });
              self.get("store").query("report-field", {
                consultation: self.get("model.consultation.id")
              }, {
                reload: true
              });
            } else if (self.get("model.patient.id")) {
              self.get("store").query("report-section", {
                patient: self.get("model.patient.id")
              }, {
                reload: true
              });
              self.get("store").query("report-field", {
                patient: self.get("model.patient.id")
              }, {
                reload: true
              });
            }

            self.toggleProperty("closed");
          },
          error: function error(e) {
            self.get("es").handle(e);
          },
          finally: function _finally() {
            self.sendAction("openModal", {
              entity: "pdf2",
              action: "details",
              model: {
                template: report.get("template.report") ? report.get("template.report") : "defaultReport",
                nameToCall: report,
                consultation: self.get("model.consultation.id"),
                patient: self.get("model.patient.id")
              }
            });
          }
        });
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});