define("crm/controllers/organizations/organization/tasks/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: ["createdBefore"],
    displayDatePicker: false,
    canDoTasks: Ember.computed("can", function () {
      return this.get("can").can("do task");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("startedBefore", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    isToday: Ember.computed("startedBefore", function () {
      return (0, _moment.default)(this.get("startedBefore")).isSame((0, _moment.default)(), "day");
    }),
    isAfterToday: Ember.computed("startedBefore", function () {
      return (0, _moment.default)(this.get("startedBefore")).isAfter((0, _moment.default)(), "day");
    }),
    isBeforeToday: Ember.computed("startedBefore", function () {
      return (0, _moment.default)(this.get("startedBefore")).isBefore((0, _moment.default)(), "day");
    }),
    columns: Ember.computed("tasksByOrganization", function () {
      return [{
        "propertyName": "status",
        "title": "",
        "component": "show-task-status",
        "className": "column-icon-responsive"
      }, {
        "propertyName": "priority",
        "title": "",
        "component": "show-task-priority",
        "className": "column-icon-responsive"
      }, {
        "propertyName": "dateTime",
        "title": this.get('intl').t("tasks.task.taskDate"),
        "component": "umt-datetime3",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "className": "column-date-time hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("tasks.task.name"),
        "component": "title-models-table-display",
        "className": "text-strong"
      }, {
        "propertyName": "worker.name",
        "title": this.get('intl').t("workers.worker.title"),
        "className": "hidden-xs"
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("patients.patient.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.patients.patient",
        "routeProperty": "patient.id",
        "className": "hidden-xs"
      }, {
        "component": "show-task-actions",
        "className": "column-actions-5"
      }];
    }),
    myTasks: Ember.computed('model.@each.isDue', 'model.@each.priority', function () {
      if (Ember.isEmpty(this.get("model"))) {
        return null;
      }

      return this.get("model").filterBy("worker.id", this.get("currentWorker.id")).sort(function (a, b) {
        return a.get("isDue") && !b.get("isDue") ? -1 : !a.get("isDue") && b.get("isDue") ? 1 : b.get("priority") - a.get("priority");
      });
    }),
    noOneTasks: Ember.computed('model.[]', function () {
      if (Ember.isEmpty(this.get("model"))) {
        return null;
      }

      return this.get("model").filter(function (t) {
        return !t.get("worker.id");
      }).sort(function (a, b) {
        return a.get("isDue") && !b.get("isDue") ? -1 : !a.get("isDue") && b.get("isDue") ? 1 : b.get("priority") - a.get("priority");
      });
    }),
    othersTasks: Ember.computed('model.[]', function () {
      var _this = this;

      if (Ember.isEmpty(this.get("model"))) {
        return null;
      }

      return this.get("model").filter(function (t) {
        return t.get("worker.id") && t.get("worker.id") !== _this.get("currentWorker.id");
      }).sort(function (a, b) {
        return a.get("isDue") && !b.get("isDue") ? -1 : !a.get("isDue") && b.get("isDue") ? 1 : b.get("priority") - a.get("priority");
      });
    }),
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      openCreate: function openCreate() {
        this.send("openModal", {
          entity: "task",
          action: "create"
        });
      },
      openDone: function openDone(task) {
        this.send("openModal", {
          entity: "task",
          action: "done",
          model: task
        });
      },
      openDelete: function openDelete(task) {
        this.send("openModal", {
          entity: "task",
          action: "delete",
          model: task
        });
      },
      openCommentCreate: function openCommentCreate(task) {
        this.send("openModal", {
          entity: "task",
          action: "commentCreate",
          model: {
            task: task
          }
        });
      },
      updateModel: function updateModel(startedBefore) {
        var self = this;
        self.set("startedBefore", (0, _moment.default)(startedBefore).format('YYYY-MM-DD'));
        this.send("reloadModel");
      },
      previous: function previous() {
        var self = this;
        self.set("startedBefore", (0, _moment.default)(self.get("startedBefore")).subtract(1, "d").format("YYYY-MM-DD"));
      },
      next: function next() {
        var self = this;
        self.set("startedBefore", (0, _moment.default)(self.get("startedBefore")).add(1, "d").format("YYYY-MM-DD"));
      },
      today: function today() {
        var self = this;
        self.set("startedBefore", (0, _moment.default)().format("YYYY-MM-DD"));
      },
      setHideDatePicker: function setHideDatePicker() {
        this.set("displayDatePicker", false);
      },
      setAllowDatePicker: function setAllowDatePicker() {
        this.set("displayDatePicker", true);
      }
    }
  });

  _exports.default = _default;
});