define("crm/models/warehouse-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    quantity: _emberData.default.attr(),
    minStock: _emberData.default.attr("number"),
    maxStock: _emberData.default.attr("number"),
    preferential: _emberData.default.attr("boolean"),
    calculatedUnitCost: _emberData.default.attr("number"),
    inventoryBalance: _emberData.default.attr("number"),
    lastUnitCost: _emberData.default.attr("number"),
    item: _emberData.default.belongsTo("item"),
    warehouse: _emberData.default.belongsTo("warehouse")
  });

  _exports.default = _default;
});