define("crm/components/procurement/purchase-memo-header-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * components/procurement/purchase-memo-header-new
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    financialAccountsUsable: Ember.computed("financialAccounts.@each.financialAccountType", "paymentMethod", function () {
      var self = this;
      var financialAccounts = [];

      if (this.get("model") && this.get("financialAccounts")) {
        if (this.get("financialAccounts.length")) {
          this.get("financialAccounts").filter(function (financialAccount) {
            if (financialAccount.get("financialAccountType") == 1) {
              self.set("selectedFinancialAccount", financialAccount);

              if (financialAccount.get("isManual")) {
                if (financialAccount.get("isOpen")) {
                  financialAccounts.addObject(financialAccount);
                }
              } else {
                financialAccounts.addObject(financialAccount);
              }
            }
          }).objectAt(0);
        }

        if (financialAccounts.get("length") === 1) {
          this.set("model.financialAccount", financialAccounts[0]);
        } else {
          this.set("model.financialAccount", null);
        }
      }

      return financialAccounts;
    }),
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 2).get("length") > 0 ? true : false;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 1).get("length") > 0 || this.get("paymentMethod") === "DEB" ? true : false;
    }),
    selectedSupplierInvoices: Ember.computed("model.supplier.id", function () {
      if (this.get("model.supplier.id")) {
        this.get("store").findRecord("supplier", this.get("model.supplier.id")); // needed to make sure debt is calculated

        this.set("model.purchaseInvoice", null);
        return this.get("store").query("purchase-invoice", {
          supplier: this.get("model.supplier.id"),
          issued: true
        });
      } else {
        this.set("model.purchaseInvoice", null);
      }
    }),
    actions: {
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        if (paymentMethod) {
          this.set("model.paymentMethod", paymentMethod);
        } else {
          this.set("model.paymentMethod", null);
        }
      },
      setSupplier: function setSupplier(supplier) {
        this.set("model.supplier", supplier);
        this.sendAction("supplierChanged", this.get("model.supplier"));
      },
      unselectInvoice: function unselectInvoice(invoice) {
        this.set("model.purchaseInvoice", invoice);
      },
      setSelectedInvoice: function setSelectedInvoice(invoice) {
        this.set("model.purchaseInvoice", invoice);
      }
    }
  });

  _exports.default = _default;
});