define("crm/routes/organizations/organization/listings/sales/taxes", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("taxes.vat.title")
      });
    },
    model: function model() {
      var self = this;
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/invoices/taxes" + "?organization=" + self.modelFor("organizations.organization").get("id") + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        },
        success: function success(result) {
          result.data = JSON.parse(result.data);
          result.data.sort(function (a, b) {
            if (a.Document < b.Document) {
              return -1;
            }

            if (a.Document > b.Document) {
              return 1;
            }

            return b.TaxPercentage - a.TaxPercentage;
          });
          return result;
        },
        error: function error() {
          return {};
        }
      });
    }
  });

  _exports.default = _default;
});