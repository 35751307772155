define("crm/controllers/organizations/organization/appointments/day", ["exports", "moment", "crm/controllers/organizations/organization/appointments"], function (_exports, _moment, _appointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointments.default.extend({
    queryParams: ["oldAppointment", "patient", "worker", "customer", "intakeDate", "day"],
    startHour: 9,
    endingHour: 22,
    step: 30,
    displayChartDateInitInput: false,
    appointableWorkers: [],
    init: function init() {
      this._super();

      this.set('now', (0, _moment.default)());
      this.set('currentHour', (0, _moment.default)().startOf("hour"));
    },
    isToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isSame(this.get("chartDateInit"), "days");
    }),
    hourWindow: Ember.computed("step", "startHour", "endingHour", function () {
      return (1 + this.get("endingHour") - this.get("startHour")) * 60 / this.get("step");
    }),
    chartDateInit: Ember.computed('day', function () {
      return (this.get("day") ? (0, _moment.default)(this.get("day")) : (0, _moment.default)()).startOf("day").hours(this.get("startHour"));
    }),
    hours: Ember.computed('chartDateInit', "hourWindow", "step", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        var newHour = (0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), 'minutes');
        result.addObject(newHour);
      }

      return result;
    }),
    aa: Ember.observer("model.workers.@each.appointable", "chartDateInit", function () {
      var self = this;

      if (this.get("model.organization.hrFeature")) {
        this.set("appointableWorkers", []);
        this.get('model.workers').filterBy('appointable', true).forEach(function (worker) {
          self.get("store").query("shift", {
            worker: worker.get("id"),
            date: (0, _moment.default)(self.get("chartDateInit")).startOf("day").format()
          }).then(function (savedShift) {
            if (!Ember.isEmpty(savedShift)) {
              self.send("addWorker", worker);
            }
          });
        });
      } else {
        this.set("appointableWorkers", this.get('model.workers').filterBy('appointable', true));
      }
    }),
    midnight: Ember.computed('model.[]', function () {
      var self = this;
      var result = {};
      var flag = false;
      result[0] = [];
      self.get('appointableWorkers').forEach(function (worker) {
        result[worker.get('id')] = [];
      });
      this.get('model').filter(function (appointment) {
        return (0, _moment.default)(self.get("chartDateInit")).startOf("day").isSame(appointment.get("start"), "minutes");
      }).forEach(function (a) {
        if (a.get("patient") && Ember.isEmpty(a.get("patient.deathdate"))) {
          var workerID = a.get('worker.id');

          if (workerID === undefined) {
            workerID = 0;
          }

          result[workerID].addObject(a);
          flag = true;
        }
      });
      return flag ? result : [];
    }),
    columns: Ember.computed('model.[]', 'hours.[]', 'appointableWorkers.@each.id', function () {
      var result = {};
      var self = this;
      this.get('hours').forEach(function (hour) {
        result[hour] = {};
        result[hour][0] = [];
        self.get('appointableWorkers').forEach(function (worker) {
          result[hour][worker.get('id')] = [];
        });
      });
      this.get('model').forEach(function (appointment) {
        if (appointment.get("patient") && Ember.isEmpty(appointment.get("patient.deathdate"))) {
          var workerID = appointment.get('worker.id');

          if (workerID === undefined) {
            workerID = 0;
          }

          var appointmentStart = (0, _moment.default)(appointment.get('start'));
          var minutes = appointmentStart.minutes();
          var adjustedHour;

          if (minutes < self.get("step")) {
            adjustedHour = appointmentStart.startOf("hour");
          } else {
            adjustedHour = appointmentStart.startOf("hour").add(self.get("step"), 'minutes');
          }

          if (result[adjustedHour] && result[adjustedHour][workerID]) {
            if (result[adjustedHour][workerID] == "skip") {
              result[adjustedHour][workerID] = [];
            }

            result[adjustedHour][workerID] = result[adjustedHour][workerID].addObject(appointment);

            if (appointment.get("calendarSteps") > 1) {
              var lastStep = adjustedHour;

              for (var i = 1; i < appointment.get("calendarSteps"); i++) {
                lastStep = lastStep.add(self.get("step"), "minutes");

                if (result[adjustedHour] && result[adjustedHour][workerID]) {
                  result[adjustedHour][workerID] = result[adjustedHour][workerID].addObject({});
                }
              }
            }
          }
        }
      });
      return result;
    }),
    actions: {
      addWorker: function addWorker(worker) {
        var aux = [];
        this.get("appointableWorkers").forEach(function (workerElem) {
          if (workerElem.get("id") != worker.get("id")) {
            aux.push(workerElem);
          }
        });
        aux.push(worker);
        this.set("appointableWorkers", aux);
      },
      previous: function previous() {
        var day = this.get('chartDateInit').subtract(1, 'd');
        this.transitionToRoute('organizations.organization.appointments.day', this.get('model.organization.id'), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      next: function next() {
        var day = this.get('chartDateInit').add(1, 'd');
        this.transitionToRoute('organizations.organization.appointments.day', this.get('model.organization.id'), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      setChartDateInit: function setChartDateInit() {
        this.set('displayChartDateInitInput', false);
      },
      setDisplayChartDateInitInput: function setDisplayChartDateInitInput() {
        this.set('displayChartDateInitInput', true);
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send('openModalAppointmentDetails', appointment);
      },
      openAppointmentCreate: function openAppointmentCreate(hour, workerID) {
        var start = hour ? hour : (0, _moment.default)().format();
        var newAppointment = {
          "organization": this.get('model.organization'),
          "start": start
        };

        if (this.get('patient')) {
          newAppointment.patient = this.get('store').peekRecord('patient', this.get('patient'));
        }

        if (this.get('worker')) {
          newAppointment.worker = this.get('store').peekRecord('worker', this.get('worker'));
        }

        if (this.get('oldAppointment')) {
          newAppointment.oldAppointment = this.get('store').peekRecord('appointment', this.get('oldAppointment'));
        }

        if (this.get('customer')) {
          newAppointment.customer = this.get('store').peekRecord('customer', this.get('customer'));
        }

        if (workerID) {
          newAppointment.worker = this.get('store').peekRecord('worker', workerID);
        }

        if (this.get('intakeDate')) {
          newAppointment.intakeDate = this.get('intakeDate');
        }

        this.send('openModal', {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set('appointment', appointment);
          Ember.$('#openCreateAppointment').modal();
        }
      },
      openDoTaskModal: function openDoTaskModal(record) {
        this.send('openDoTask', record);
      },
      checkIn: function checkIn(appointment) {
        appointment.set('arrivalTime', (0, _moment.default)().format());
        appointment.set('status', 3);
        appointment.save();
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      deleteAppointment: function deleteAppointment(appointment) {
        if (false == Ember.isEmpty(appointment)) {
          appointment.destroyRecord();
        }
      },
      createdConsultation: function createdConsultation(consultationID) {
        this.transitionToRoute("organizations.organization.consultations.consultation", consultationID);
      },
      transitionToWeek: function transitionToWeek() {
        var week = (0, _moment.default)().isoWeek();
        var year = (0, _moment.default)().year();
        var day = this.get("day");
        this.transitionToRoute('organizations.organization.appointments.week', this.get('model.organization.id'), {
          queryParams: {
            week: week,
            year: year,
            day: day
          }
        });
      },
      today: function today() {
        var day = (0, _moment.default)().startOf('day');
        this.transitionToRoute('organizations.organization.appointments.day', this.get('model.organization.id'), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      transitionToDay: function transitionToDay() {
        var day = this.get("day");
        this.transitionToRoute('organizations.organization.appointments.day', this.get('model.organization.id'), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToDay2: function transitionToDay2() {
        var day = this.get("day");
        this.transitionToRoute('organizations.organization.appointments.day2', this.get('model.organization.id'), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToCustom: function transitionToCustom() {
        var day = this.get("day");
        this.transitionToRoute('organizations.organization.appointments.custom', this.get('model.organization.id'), {
          queryParams: {
            day: day
          }
        });
      }
    }
  });

  _exports.default = _default;
});