define("crm/models/tax", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // AKA company-vat
  var _default = _abstractModel.default.extend({
    purchase: _emberData.default.attr("boolean"),
    company: _emberData.default.belongsTo("company"),
    vat: _emberData.default.belongsTo("vat"),
    amount: Ember.computed("vat.amount", function () {
      return this.get("vat.amount");
    })
  });

  _exports.default = _default;
});