define("crm/routes/organizations/organization/listings/sales/lines", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    store: Ember.inject.service("session"),
    breadCrumb: {
      finalTitle: "Recebimentos"
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var createdAfter = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdAfter).startOf("day").utc().toISOString();
      var createdBefore = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdBefore).endOf("day").utc().toISOString();
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/sales/lines" + "?organization=" + organization.get("id") + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      }).then(function (lines) {
        return lines.map(function (i) {
          return {
            code: i[0],
            name: i[1],
            quantity: i[2],
            unitPriceWoVat: i[3],
            unitPrice: i[4],
            linePriceWoVat: i[5],
            linePrice: i[6],
            vatAmount: i[7],
            vatLinePrice: i[8],
            customer: i[9],
            created: i[10],
            invoice: i[11],
            creator: i[12]
          };
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("columns", [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "name" //"component": "umt-date",
        //"sortDirection": "desc",
        //"sortPrecedence": 0,
        //"title": this.get("intl").t("shifts.startDate"),
        //"className": "column-date"

      }, {
        "propertyName": "quantity",
        "className": "column-quantity"
      }, {
        "propertyName": "unitPriceWoVat",
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "unitPrice",
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "linePriceWoVat",
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "linePrice",
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "vatAmount",
        "className": "column-vat"
      }, {
        "propertyName": "vatLinePrice",
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "customer",
        "className": "column-large"
      }, {
        "propertyName": "created",
        "className": "column-date-time",
        "component": "umt-datetime2"
      }, {
        "propertyName": "invoice",
        "className": "column-large"
      }, {
        "propertyName": "creator",
        "className": "column-large"
      }]);
    }
  });

  _exports.default = _default;
});