define("crm/controllers/organizations/organization/configurations/item-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    data: Ember.computed("model.organization.company.companyItemTypes.[]", "model.organization.company.itemTypes.[]", function () {
      if (!this.get("model.organization.company.companyItemTypes")) {
        return null;
      }

      var types = this.store.peekAll("ItemType");

      if (!types) {
        return null;
      }

      var result = [];
      var companyItemTypes = this.get("model.organization.company.companyItemTypes");
      var companyTypes_map = {};
      companyItemTypes.forEach(function (cit) {
        return companyTypes_map[cit.get("itemType.id")] = cit;
      });
      types.sortBy("codes").forEach(function (type) {
        return result.push({
          itemType: type,
          companyItemType: companyTypes_map[type.get("id")],
          enable: undefined !== companyTypes_map[type.get("id")]
        });
      });
      return result;
    }),
    actions: {
      canceled: function canceled() {
        this.set("disabledAction", true);
        this.get("data").forEach(function (d) {
          if (d.companyItemType && Object.keys(d.companyItemType.changedAttributes()).length > 0) {
            d.companyItemType.rollbackAttributes();
          }
        });
        this.set("disabledAction", false);
      },
      delete: function _delete(line) {
        line.companyItemType.destroyRecord().catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      create: function create(line) {
        var self = this;
        this.set("disabledAction", true);
        this.get("store").createRecord("company-item-type", {
          company: this.get("model.organization.company"),
          itemType: line.itemType
        }).save(function () {
          return self.set("disabledAction", false);
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledAction", false);
        });
      },
      update: function update() {
        var self = this;
        this.set("disabledAction", true);
        this.get("data").forEach(function (d) {
          if (d.companyItemType && Object.keys(d.companyItemType.changedAttributes()).length > 0) {
            d.companyItemType.save().then(function () {
              self.set("disabledAction", false);
              self.get("flashMessages").success(self.get("intl").t("alerts.changesSaved"));
            }, function (error) {
              d.companyItemType.rollbackAttributes();
              self.set("disabledAction", false);
              self.get("es").errorReturned(error);
            });
          } else {
            self.set("disabledAction", false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});