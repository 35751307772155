define("crm/components/absences-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoGroupAbsences: Ember.computed("can", function () {
      //return this.get("can").can("do group"); TODOJD
      return true;
    }),
    actions: {
      openDetails: function openDetails() {
        this.sendAction('openDetails', this.get("record"));
      },
      openEdit: function openEdit() {
        this.sendAction('openEdit', this.get("record"));
      },
      delete: function _delete() {
        var self = this;

        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord().catch(function (e) {
            return self.get("es").handle(e);
          });
        }
      }
    }
  });

  _exports.default = _default;
});