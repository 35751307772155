define("crm/controllers/organizations/organization/customers/customer/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      var result = [{
        "propertyName": "created",
        "title": this.get("intl").t("invoices.invoice.date"),
        "component": "umt-date",
        "className": "column-date"
      }, {
        "propertyName": "invoice.name",
        "title": this.get("intl").t("invoices.invoice.title"),
        "className": "column-date-time hidden-xs"
      }, {
        "propertyName": "item.name",
        "title": this.get("intl").t("items.item.name")
      }, {
        "propertyName": "quantity",
        "title": this.get("intl").t("items.item.quantity"),
        "className": "column-price-medium"
      }, {
        "propertyName": "linePrice",
        "title": this.get("intl").t("prices.price.title"),
        "component": "umt-price-display",
        "className": "column-price-large hidden-xs"
      }];
      return result;
    })
  });

  _exports.default = _default;
});