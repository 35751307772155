define("crm/components/supplier-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldString: null,
    disabled: false,
    es: Ember.inject.service(),
    reset: function reset() {
      Ember.$("#modal-supplier-select").modal("hide");
      this.set("searchText", "");
      this.set("suppliers", []);
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("customers.customer.name"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }];
    }),
    searching: Ember.computed("searchText", "disabled", function () {
      return this.get("searchText") && this.get("disabled");
    }),
    actions: {
      searchSuppliers: function searchSuppliers(query) {
        var self = this;
        this.set("lockButton", true);
        Ember.RSVP.hash({
          suppliers: this.get("store").query("supplier", {
            company: self.get("organization.company.id"),
            query: query
          })
        }).then(function (hashResult) {
          self.set("suppliers", hashResult.suppliers);
          self.set("lockButton", false);
        }).catch(function (e) {
          self.set("lockButton", false);
          self.get("es").handle(e);
        });
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable.get("selectedItems.length")) {
          this.set("selectedSupplier", dataTable.get("selectedItems")[0]);
          this.set("oldString", null);

          if (this.get("onChange")) {
            this.get("onChange")(dataTable.get("selectedItems")[0]);
          }

          this.reset();
        }
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});