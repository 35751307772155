define("crm/components/organization-pet-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("organization-pet", this.get("model.id"), {
          reload: true
        });
      }
    },
    close: function close() {
      Ember.$("#modal-organization-pet-edit").modal("hide");
    },
    warehousesList: Ember.computed("warehouses.@each.warehouse", function () {
      return this.get("warehouses.length") > 1;
    }),
    actions: {
      editSenderName: function editSenderName() {
        this.set("disabledSubmission", true);
        var self = this;

        if (!Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") == 1) {
          this.set("model.defaultWarehouse", this.get("warehouses").objectAt(0));
        }

        this.get("model").save().then(function () {
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});