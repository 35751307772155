define("crm/controllers/organizations/organization/campaigns/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    can: Ember.inject.service(),
    queryParams: ["createdAfter", "createdBefore"],
    canDoPeriodic: Ember.computed("can", function () {
      return this.get("can").can("do crm");
    }),
    actions: {
      openCreateCampaign: function openCreateCampaign() {
        this.send("openModal", {
          entity: "campaign",
          action: "new"
        });
      },
      openDetails: function openDetails(campaign) {
        this.send("openModal", {
          entity: "campaign",
          action: "details",
          model: campaign
        });
      },
      openEdit: function openEdit(campaign) {
        this.send("openModal", {
          entity: "campaign",
          action: "edit",
          model: campaign
        });
      },
      updateModel: function updateModel(createdAfter, createdBefore) {
        var self = this;
        self.set("createdAfter", (0, _moment.default)(createdAfter).startOf("month").format());
        self.set("createdBefore", (0, _moment.default)(createdBefore).endOf("month").format());
      }
    }
  });

  _exports.default = _default;
});