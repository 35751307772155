define("crm/controllers/organizations/organization/listings/sales/shopping-carts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("intl", "organization", function () {
      return [{
        "propertyName": "customer.code",
        "title": "",
        "className": "column-id module-crm",
        "routeName": "organizations.organization.customers.customer",
        "routeProperty": "customer.id"
      }, {
        "propertyName": "customer.name",
        "title": this.get('intl').t("customers.customer.title"),
        "className": "module-crm",
        "component": "title-models-table-display",
        "routeName": "organizations.organization.customers.customer",
        "routeProperty": "customer.id"
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": this.get('intl').t("enums.chronos.dateUpper"),
        "className": "column-date"
      }, {
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "title": this.get('intl').t("invoices.invoice.total")
      }, {
        "component": "open-shopping-carts-actions",
        "className": "column-actions-3",
        "disableSorting": true,
        "pu-organization": this.get("organization")
      }];
    }),
    actions: {
      openDetails: function openDetails(shoppingCart) {
        this.send("openModal", {
          entity: "open-shopping-carts",
          action: "details",
          model: shoppingCart
        });
      },
      openCloseShoppingCart: function openCloseShoppingCart(shoppingCart) {
        this.send("openModal", {
          entity: "open-shopping-carts",
          action: "close",
          model: shoppingCart
        });
      }
    }
  });

  _exports.default = _default;
});