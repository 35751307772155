define("crm/controllers/organizations/organization/purchase-invoices/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    canDoPurchaseInvoices: Ember.computed("can", function () {
      return this.get("can").can("do procurement");
    }),
    filteredPurchaseInvoices: Ember.computed("model.@each.paidAmount", "showAll", "filteredSupplier.id", function () {
      var result;

      if (this.get("showAll")) {
        if (this.get("filteredSupplier")) {
          result = this.get("model").filter(function (pi) {
            return pi.get("supplier.id") == self.get("filteredSupplier.id");
          });
        } else {
          result = this.get("model");
        }
      } else {
        if (this.get("filteredSupplier")) {
          result = this.get("model").filter(function (pi) {
            return pi.get("supplier.id") == self.get("filteredSupplier.id");
          });
        } else {
          result = this.get("model");
        }
      }

      return result.sortBy("date").reverse();
    }),
    columns: Ember.computed(function () {
      return [{
        "component": "purchase-invoice-status",
        "className": "column-icon"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get("intl").t("purchaseInvoices.new.date"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("purchaseInvoices.new.name"),
        "component": "title-models-table-display",
        "className": "column-name text-strong"
      }, {
        "title": this.get("intl").t("suppliers.supplier.title"),
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "className": "column-name"
      }, {
        "title": this.get("intl").t("purchaseInvoices.new.paymentMethod"),
        "propertyName": "paymentMethod",
        "className": "column-price-medium"
      }, {
        "propertyName": "payableAmount",
        "title": this.get("intl").t("documents.payableAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "propertyName": "paidAmount",
        "title": this.get("intl").t("purchaseInvoices.new.paidAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "component": "purchase-invoice-documents-actions",
        "className": "column-price column-actions-4"
      }];
    }),
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      filterSuppliers: function filterSuppliers(supplier) {
        if (supplier) {
          this.set("filteredSupplier", supplier);
        } else {
          this.set("filteredSupplier", null);
        }
      },
      openPurchaseReceiptNew: function openPurchaseReceiptNew() {
        this.send("openModal", {
          entity: "purchase-receipt",
          action: "new"
        });
      },
      export: function _export() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseInvoices/xlsx?organization=" + self.get("model.organization.id") + "&open=true",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "purchaseInvoices-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});