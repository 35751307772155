define("crm/helpers/option-composite-product-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.optionCompositeProductDisplay = optionCompositeProductDisplay;
  _exports.default = void 0;

  function optionCompositeProductDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 0:
          return this.get('intl').t('models.sales.options.billStock');

        case 1:
          return this.get('intl').t('models.sales.options.stock');

        case 2:
          return this.get('intl').t('models.sales.options.billDiscountStock');

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});