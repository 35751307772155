define("crm/controllers/organizations/organization/purchase-orders/purchase-order/edit", ["exports", "crm/controllers/organizations/organization/purchase-orders/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    actions: {
      deleteLine: function deleteLine(line) {
        if (this._super(line)) {
          line.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});