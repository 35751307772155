define("crm/routes/organizations/organization/items/item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    afterModel: function afterModel(model) {
      var organization = this.modelFor("organizations.organization");

      if (organization.get("salesFeature")) {
        var itemID = model.get("id");
        Ember.RSVP.hash({
          itemItems: this.store.query("item-item", {
            parent: itemID,
            organization: organization.get("id")
          }),
          itemUnits: this.store.query("item-unit", {
            item: itemID
          }),
          itemCompanions: this.store.query("item-companion", {
            item: itemID
          }),
          invoices: !organization.get("salesFeature") ? [] : this.store.query("invoice", {
            item: itemID
          }),
          warehouseItems: !organization.get("salesFeature") ? [] : this.store.query("warehouse-item", {
            item: itemID,
            organization: organization.get("id")
          }),
          supplierItems: !organization.get("procurementFeature") ? [] : this.store.query("supplier-item", {
            item: itemID
          }),
          purchaseInvoices: !organization.get("procurementFeature") ? [] : this.store.query("purchase-invoice", {
            item: itemID
          }),
          purchaseInvoiceLines: !organization.get("procurementFeature") ? [] : this.store.query("purchase-invoice-line", {
            item: itemID
          })
        });
        /*
           .then(() => {
              $.ajax({
                 url: ENV.universe.api.host + "/" + ENV.universe.api.namespace + "/items/" + model.get("id") + "/sync",
                 accept: "application/json",
                 beforeSend: function(request) {
                    request.setRequestHeader("Accept-Language", self.get("intl.locale"));
                    request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                 }
              });
           });
           */

        this.store.query("item", {
          sync: true,
          item: model.get("id")
        });
      }
    }
  });

  _exports.default = _default;
});