define("crm/routes/organizations/organization/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('read customer')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    actions: {
      delete: function _delete(id) {
        var self = this;
        var customerToDelete = this.get('store').peekRecord('customer', id);
        customerToDelete.destroyRecord().then(function () {
          self.transitionTo('organizations.organization.customers');
        });
      },
      enable: function enable(customer) {
        var self = this;
        this.get("store").findRecord("customer", customer.get("id"), {
          reload: true
        }).then(function (savedCustomer) {
          savedCustomer.set("enabled", true);
          savedCustomer.save().catch(function (result) {
            self.get("store").findRecord("customer", customer.get("id"), {
              reload: true
            });
            self.get("es").handle(result);
          });
        });
      },
      disable: function disable(customer) {
        if (customer.get("currentAccount") === 0 || customer.get("currentAccount") < 0) {
          this.get("store").findRecord("customer", customer.get("id"), {
            reload: true
          }).then(function (savedCustomer) {
            savedCustomer.set('enabled', false);
            savedCustomer.save();
          });
        } else {
          this.send("openModal", {
            entity: "disable",
            action: "customer",
            model: customer
          });
        }
      }
    }
  });

  _exports.default = _default;
});