define("crm/components/boxes-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoBoxes: Ember.computed("can", function () {
      //return this.get("can").can("do hotel"); TODOJD
      return true;
    }),
    ocuppied: Ember.computed("record.status", function () {
      return this.get("record.status") === 2;
    }),
    actions: {
      openDetails: function openDetails() {
        this.sendAction('openDetails', this.get("record.box"));
      },
      editBoxModal: function editBoxModal() {
        this.sendAction('editBoxModal', this.get("record.box"));
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          this.get("store").findRecord("box", this.get("record.box.id"), {
            reload: true
          }).then(function (box) {
            box.destroyRecord();
          }).catch(function (error) {
            self.get('flashMessages').danger(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});