define("crm/components/disable-customer-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    close: function close() {
      Ember.$("#modal-disable-customer").modal("hide");
    },
    hasShoppingCarts: Ember.computed("model.shoppingCartsByCustomer", function () {
      if (this.get("model.shoppingCartsByCustomer")) {
        return this.get("model.shoppingCartsByCustomer").filterBy("status", 0);
      }
    }),
    actions: {
      save: function save() {
        var self = this;
        this.get("store").findRecord("customer", this.get("model.id"), {
          reload: true
        }).then(function (savedCustomer) {
          savedCustomer.set('enabled', false);
          savedCustomer.save();
          self.close();
        });
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});