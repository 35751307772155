define("crm/routes/organizations/organization/listings/sales/financial-accounts/financial-account/financial-account-opens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("financialAccounts.actions.open")
      });
    }
  });

  _exports.default = _default;
});