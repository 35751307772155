define("crm/routes/organizations/organization/appointments/week", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    queryParams: {
      day: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var startOfWeek = transition.queryParams.day ? (0, _moment.default)(transition.queryParams.day).startOf("isoWeek").startOf("day") : (0, _moment.default)().startOf("isoWeek").startOf("day");
      var endOfWeek = (0, _moment.default)(startOfWeek).add(6, "days").endOf("day");
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");

      if (startOfWeek.isValid() && endOfWeek.isValid()) {
        var self = this;
        var me = this.get("worker");
        return Ember.RSVP.hash({
          filteredAppointments: self.store.query("appointment", {
            organization: organizationID,
            startedAfter: (0, _moment.default)(startOfWeek).format(),
            startedBefore: endOfWeek.format()
          }),
          itemTypes: self.store.findAll("itemType"),
          workers: self.store.query("worker", {
            organization: organizationID
          }),
          workerFilters: self.store.query("calendar-filter-worker", {
            owner: me.get("id")
          }),
          itemTypeFilters: self.store.query("calendar-filter-item-type", {
            owner: me.get("id")
          }),
          patients: self.store.query("patient", {
            organization: organizationID,
            appointmentStartedAfter: startOfWeek.format(),
            appointmentStartedBefore: endOfWeek.format()
          }),
          customers: self.store.query("customer", {
            organization: organizationID,
            appointmentStartedAfter: startOfWeek.format(),
            appointmentStartedBefore: endOfWeek.format()
          }),
          addresses: self.store.query("address", {
            organization: organizationID,
            appointmentStartedAfter: startOfWeek.format(),
            appointmentStartedBefore: endOfWeek.format()
          })
        }).then(function (hashResult) {
          var appointments = hashResult.filteredAppointments;
          Ember.set(appointments, "organization", organization);
          Ember.set(appointments, "startOfWeek", startOfWeek);
          Ember.set(appointments, "endOfWeek", endOfWeek);
          Ember.set(appointments, "itemTypes", hashResult.itemTypes);
          Ember.set(appointments, "workers", hashResult.workers);
          Ember.set(appointments, "workerFilters", hashResult.workerFilters);
          Ember.set(appointments, "itemTypeFilters", hashResult.itemTypeFilters);
          Ember.set(appointments, "me", me);
          return appointments;
        });
      }
    },
    setupController: function setupController(controller, model) {
      var self = this;

      this._super(controller, model);

      var organizationID = model.get("organization.id");
      var startOfWeek = model.get("startOfWeek").format();
      var endOfWeek = model.get("endOfWeek").format();
      Ember.RSVP.hash({
        old: self.store.query("task", {
          organization: organizationID,
          startedBefore: startOfWeek,
          status: 1,
          me: true
        }),
        week: self.store.query("task", {
          organization: organizationID,
          startedAfter: startOfWeek,
          startedBefore: endOfWeek,
          me: true
        })
      }).then(function (hashResult) {
        var allDayTasks = [];
        var timedTasks = [];

        if (hashResult.old) {
          hashResult.old.forEach(function (task) {
            allDayTasks.push(task);
          });
        }

        if (hashResult.week) {
          hashResult.week.forEach(function (task) {
            if (task.get("taskTime") && task.get("taskTime") !== "00:00:00") {
              timedTasks.push(task);
            } else if ((0, _moment.default)().startOf("day").isAfter(task.get("taskDate")) && task.get("status") === 1 || (0, _moment.default)().isSame(task.get("taskDate"), "d")) {
              allDayTasks.push(task);
            }
          });
        }

        controller.set("tasks", allDayTasks);
        controller.set("filteredTasks", timedTasks);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("day", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});