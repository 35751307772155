define("crm/components/visits-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("dateStart", (0, _moment.default)().format('YYYY-MM-DD'));
      this.set("dateEnd", (0, _moment.default)().format('YYYY-MM-DD'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.set("dateStart", (0, _moment.default)().format('YYYY-MM-DD'));
      this.set("dateEnd", (0, _moment.default)().format('YYYY-MM-DD'));
    },
    filteredModels: Ember.computed('dateStart', 'dateEnd', function () {
      if (!Ember.isEmpty(this.get("dateStart")) && !Ember.isEmpty(this.get("dateEnd"))) {
        this.get("store").query("appointment", {
          organization: this.get("organization.id"),
          startedAfter: (0, _moment.default)(this.get("dateStart")).startOf("day").format(),
          startedBefore: (0, _moment.default)(this.get("dateEnd")).endOf("day").format()
        });
        return this.get("store").query("consultation", {
          organization: this.get("organization.id"),
          endedAfter: (0, _moment.default)(this.get("dateStart")).startOf("day").format(),
          endedBefore: (0, _moment.default)(this.get("dateEnd")).endOf("day").format()
        });
      }
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "appointment.display",
        "title": this.get('intl').t("appointments.appointment.appointmentType")
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("patients.patient.title"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "owner.name",
        "title": this.get('intl').t("customers.customer.title"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "date",
        "component": "umt-datetime",
        "title": this.get('intl').t("consultations.consultation.searchOptions.date"),
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "worker1.name",
        "title": this.get('intl').t("workers.worker.title")
      }, {
        "propertyName": "appointment.endConsultation",
        "component": "umt-boolean",
        "title": this.get('intl').t("visits.status.title")
      }, {
        "component": "visit-actions"
      }];
    }),
    actions: {
      openDetails: function openDetails(model) {
        this.sendAction('openDetails', model);
      },
      delete: function _delete(model) {
        this.sendAction("delete", model);
      },
      openModalCreateVisit: function openModalCreateVisit() {
        this.sendAction('openCreateVisit');
      },
      openEditVisitModal: function openEditVisitModal(visitToEdit) {
        this.sendAction('openEditVisit', visitToEdit);
      }
    }
  });

  _exports.default = _default;
});