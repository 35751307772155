define("crm/models/equipment-type", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    parent: _emberData.default.belongsTo("equipment-type", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("equipment-type", {
      inverse: "parent"
    }),
    enable: _emberData.default.attr("boolean", {
      allowNull: true
    })
  });

  _exports.default = _default;
});