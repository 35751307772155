define("crm/components/models-table/columns-hidden", ["exports", "ember-models-table/components/models-table/columns-hidden"], function (_exports, _columnsHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _columnsHidden.default;
    }
  });
});