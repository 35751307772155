define("crm/models/item-adjustment", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    billingID: _emberData.default.attr("string"),
    date: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    deletedReason: _emberData.default.attr("string"),
    adjustmentReasonCode: _emberData.default.attr("string"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    adjustmentReason: _emberData.default.belongsTo("adjustment-reason"),
    lines: _emberData.default.hasMany("item-adjustment-line", {
      inverse: "itemAdjustment"
    })
  });

  _exports.default = _default;
});