define("crm/controllers/organizations/organization/shifts/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["startDate", "endDate"],
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    showAside: true,
    startDate: (0, _moment.default)().startOf("month").format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().endOf("month").format("YYYY-MM-DD"),
    canDoGroupShifts: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    isToday: Ember.computed("startDate", "endDate", function () {
      return (0, _moment.default)().isSame(this.get("startDate"), "day") || (0, _moment.default)().isBetween(this.get("startDate"), this.get("endDate"));
    }),
    actions: {
      openShiftNew: function openShiftNew() {
        this.send("openModal", {
          entity: "shift",
          action: "new"
        });
      },
      previous: function previous() {
        this.set("startDate", (0, _moment.default)(this.get("startDate")).add(-1, "days").format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)(this.get("endDate")).add(-1, "days").format("YYYY-MM-DD"));
      },
      next: function next() {
        this.set("startDate", (0, _moment.default)(this.get("startDate")).add(1, "days").format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)(this.get("endDate")).add(1, "days").format("YYYY-MM-DD"));
      },
      today: function today() {
        this.set("startDate", (0, _moment.default)().format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});