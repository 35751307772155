define("crm/controllers/organizations/organization/purchase-invoices/purchase-invoice/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    can: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    allwReceipt: Ember.computed("model.payableAmount", "model.paidAmount", function () {
      return this.get("model.payableAmount") && (!this.get("model.paidAmount") || this.get("model.paidAmount") < this.get("model.payableAmount"));
    }),
    allowAnnul: Ember.computed("model.status", function () {
      return this.get("can").can("delete purchaseInvoice") && 1 === this.get("model.status");
    }),
    allowEdit: Ember.computed("model.status", function () {
      return this.get("can").can("update purchaseInvoice") && !this.get("model.status");
    }),
    documentAnnul: Ember.computed("model.status", function () {
      var result = "";

      switch (this.get("model.status")) {
        case 3:
          result = "";
          break;

        case 4:
          result = this.get("intl").t("invoices.invoice.error");
          break;

        case 8:
          result = this.get("intl").t("invoices.invoice.annuled");
          break;

        default:
          break;
      }

      return result;
    }),
    filteredModels: Ember.computed("model.purchaseReceipts.[]", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.purchaseReceipts"))) {
        result.addObjects(this.get("model.purchaseReceipts"));
      }

      return result;
    }),
    ordersColumns: Ember.computed("intl", "purchaseOrders.@each.status", function () {
      return [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("purchaseOrders.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.purchase-orders.purchase-order",
        "routeProperty": "id"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get('intl').t("purchaseOrders.date")
      }, {
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("purchaseOrders.supplier"),
        "path": "organizations.organization.suppliers.supplier",
        "routeProperty": "supplier.id"
      }];
    }),
    actions: {
      annulDocument: function annulDocument(document) {
        this.send("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      },
      openPurchaseReceiptNew: function openPurchaseReceiptNew(document) {
        this.send("openModal", {
          entity: "purchase-receipt",
          action: "new",
          model: document
        });
      }
    }
  });

  _exports.default = _default;
});