define("crm/models/exam-line", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    item: _emberData.default.belongsTo("item"),
    notes: _emberData.default.attr("string"),
    orderNumber: _emberData.default.attr("number"),
    exam: _emberData.default.belongsTo("exam", {
      inverse: "lines"
    })
  });

  _exports.default = _default;
});