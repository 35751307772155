define("crm/components/item/item-unit-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("baseUnitPriceSeed") !== this.get("baseUnitPrice")) ;
      this.send("setPrice", this.get("baseUnitPriceSeed"));
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    selectedUnit: Ember.computed("unit", "baseUnit", function () {
      return this.get("unit") ? this.get("unit") : this.get("baseUnit");
    }),
    quantityHide: Ember.computed("unit.id", "baseUnit.id", function () {
      return !this.get("unit.id") || this.get("unit.id") === this.get("baseUnit.id");
    }),
    actions: {
      /*
       * Purcahse
       */
      setQuantity: function setQuantity(quantity) {
        if (isNaN(quantity) || !quantity || quantity === 1) {
          this.set("unit", this.get("baseUnit"));
          this.set("quantity", undefined);
          this.sendAction("setBaseUnitPrice", undefined);
          this.set("unitPrice", undefined);
        } else {
          this.set("quantity", quantity);

          if (this.get("unitPrice")) {
            this.sendAction("setBaseUnitPrice", this.money(this.get("unitPrice") / quantity));
          } else {
            if (this.get("baseUnitPrice")) {
              this.set("unitPrice", this.money(this.get("baseUnitPrice") * quantity));
            }
          }
        }
      },
      setUnit: function setUnit(unit) {
        if (Ember.isEmpty(unit)) {
          this.set("unit", undefined);
          this.set("quantity", undefined);
          this.sendAction("setBaseUnitPrice", undefined);
          this.set("unitPrice", undefined);
          /*
                      if(this.get("model.stockItemUnit")) {
                         this.get("model.stockItemUnit").destroyRecord();
                      }
          */
        } else if (unit.get("id") === this.get("baseUnit.id")) {
          this.set("quantity", undefined);
          this.sendAction("setBaseUnitPrice", undefined);
          this.set("unitPrice", undefined);
        } else {
          this.set("unit", unit);
        }
      },
      setPrice: function setPrice(price) {
        if (isNaN(price) || !price) {
          this.set("baseUnitPrice", undefined);
          this.sendAction("setBaseUnitPrice", undefined);
          this.set("unitPrice", undefined);
        } else {
          this.set("baseUnitPrice", price);

          if (!this.get("baseUnitPriceSeed") || this.get("baseUnitPriceSeed") !== price) {
            this.sendAction("setBaseUnitPrice", price);
          }

          if (this.get("quantity")) {
            this.set("unitPrice", this.money(this.get("quantity") * price));
          } else if (this.get("unitPrice")) {
            this.set("quantity", this.money(this.get("unitPrice") / price));
          }
        }
      },
      setUnitPrice: function setUnitPrice(UnitPrice) {
        if (isNaN(UnitPrice) || !UnitPrice) {
          this.sendAction("setBaseUnitPrice", undefined);
          this.set("unitPrice", undefined);
        } else {
          this.set("unitPrice", UnitPrice);

          if (this.get("quantity")) {
            this.sendAction("setBaseUnitPrice", this.money(UnitPrice / this.get("quantity")));
          } else if (this.get("baseUnitPrice")) {
            this.set("quantity", this.money(UnitPrice / this.get("baseUnitPrice")));
          }
        }
      }
    }
  });

  _exports.default = _default;
});