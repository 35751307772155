define("crm/components/input-pills-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      select: function select(code) {
        this.get("options").forEach(function (o) {
          if (Ember.get(o, "code") === code) {
            Ember.set(o, "active", Ember.get(o, "active") ? false : true);
          }
        });

        if (this.get("onchange")) {
          this.get("onchange")(code);
        }
      }
    }
  });

  _exports.default = _default;
});