define("crm/controllers/organizations/organization/listings", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    queryParams: ["createdAfter", "createdBefore"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("createdAfter", (0, _moment.default)().format("YYYY-MM-DD"));
      this.set("createdBefore", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    actions: {
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      },
      setFilter1: function setFilter1(filterID) {
        this.set("filter1", this.get("filter1") === filterID ? null : filterID);

        if (this.get("filter1")) {
          switch (filterID) {
            case "d":
              this.set("createdAfter", (0, _moment.default)().format("YYYY-MM-DD"));
              this.set("createdBefore", (0, _moment.default)().format("YYYY-MM-DD"));
              break;

            case "w":
              this.set("createdAfter", (0, _moment.default)().startOf("week").format("YYYY-MM-DD"));
              this.set("createdBefore", (0, _moment.default)().format("YYYY-MM-DD"));
              break;

            case "m":
              this.set("createdAfter", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
              this.set("createdBefore", (0, _moment.default)().format("YYYY-MM-DD"));
              break;

            case "y":
              this.set("createdAfter", (0, _moment.default)().startOf("year").format("YYYY-MM-DD"));
              this.set("createdBefore", (0, _moment.default)().format("YYYY-MM-DD"));
              break;
          }
        }
      }
    }
  });

  _exports.default = _default;
});