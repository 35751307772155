define("crm/routes/organizations/organization/listings/sales/all", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      var since = (0, _moment.default)(dateFrom).startOf("day").format();
      var until = (0, _moment.default)(dateTo).endOf("day").format();
      return Ember.RSVP.hash({
        invoices: this.get("store").query("invoice", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        memos: this.get("store").query("memo", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        receipts: this.get("store").query("receipt", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        payments: this.get("store").query("payment", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        orders: this.get("store").query("order", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        quotation: this.get("store").query("quotation", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        }),
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          organization: organizationID,
          createdAfter: since,
          createdBefore: until,
          status: null
        })
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("documents.title")
      });
    }
  });

  _exports.default = _default;
});