define("crm/components/brand-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("brands.brand.name")
      }];
    })
  });

  _exports.default = _default;
});