define("crm/templates/organizations/organization/appointments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ehdH4Eo",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"\\n    \"],[4,\"link-to\",[\"organizations.organization.appointments.custom\"],null,{\"statements\":[[1,[25,\"t\",[\"pages.day\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"\\n    \"],[4,\"link-to\",[\"organizations.organization.appointments.week\"],null,{\"statements\":[[1,[25,\"t\",[\"pages.week\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/appointments/index.hbs"
    }
  });

  _exports.default = _default;
});