define("crm/controllers/organizations/organization/quotations/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    queryParams: ["startedAfter", "startedBefore"],
    startedAfter: (0, _moment.default)().startOf("day").format("YYYY-MM-DD"),
    startedBefore: (0, _moment.default)().endOf("day").format("YYYY-MM-DD"),
    reset: function reset() {
      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minStartedAfter: Ember.computed("startedBefore", function () {
      if (!Ember.isEmpty(this.get("startedBefore"))) {
        return (0, _moment.default)(this.get("startedBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxStartedBefore: Ember.computed("startedAfter", function () {
      if (!Ember.isEmpty(this.get("startedAfter"))) {
        return (0, _moment.default)(this.get("startedAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    columns: Ember.computed(function () {
      var result = [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": " ",
        "component": "title-models-table-display"
      }, {
        "title": " ",
        "propertyName": "paymentMethod",
        "className": "column-method"
      }, {
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "className": "column-price"
      }, {
        "component": "quotations-list-actions",
        "className": "column-actions-1"
      }];
      return result;
    })
  });

  _exports.default = _default;
});