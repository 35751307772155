define("crm/controllers/organizations/organization/warehouses/warehouse/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      editWarehouse: function editWarehouse() {
        var self = this;
        this.set('disabledAction', true);
        this.get("model").save().then(function (warehouse) {
          self.set('disabledAction', false);
          self.transitionToRoute("organizations.organization.warehouses.warehouse", warehouse.get("id"));
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set('disabledAction', false);
        });
      }
    }
  });

  _exports.default = _default;
});