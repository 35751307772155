define("crm/components/internment/financial-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    shoppingCartsTotal: Ember.computed("shoppingCarts.@each.total", function () {
      if (!Ember.isEmpty(this.get("shoppingCarts"))) {
        return this.get("shoppingCarts").reduce(function (acc, obj) {
          return (acc ? acc : 0) + (obj.get("total") ? obj.get("total") : 0);
        }, 0);
      }
    }),
    openShoppingCartsTotal: Ember.computed("shoppingCarts.@each.total", "shoppingCarts.@each.isOpen", function () {
      if (!Ember.isEmpty(this.get("shoppingCarts"))) {
        return this.get("shoppingCarts").reduce(function (acc, obj) {
          return (acc ? acc : 0) + (obj.get("isOpen") ? obj.get("total") ? obj.get("total") : 0 : 0);
        }, 0);
      }
    }),
    shoppingCartsFilled: Ember.computed("openShoppingCartsTotal", "shoppingCartsTotal", function () {
      return Math.round(((this.get("shoppingCartsTotal") - this.get("openShoppingCartsTotal")) / this.get("shoppingCartsTotal") * 100 + Number.EPSILON) * 100) / 100;
    }),
    quotationFilled: Ember.computed("shoppingCartsTotal", "quotation.payableAmount", function () {
      return Math.round((this.get("shoppingCartsTotal") / this.get("quotation.payableAmount") * 100 + Number.EPSILON) * 100) / 100;
    })
  });

  _exports.default = _default;
});