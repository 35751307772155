define("crm/models/lot", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    product: _emberData.default.belongsTo('product'),
    lotNumber: _emberData.default.attr('string'),
    validity: _emberData.default.attr('isodate'),
    existences: _emberData.default.attr('number')
  });

  _exports.default = _default;
});