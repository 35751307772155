define("crm/models/chat-message", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    session: Ember.inject.service("session"),
    currentUser: Ember.computed.alias("session.currentUser"),
    message: _emberData.default.attr("string"),
    chat: _emberData.default.belongsTo("chat"),
    canDelete: Ember.computed("currentUser.person.id", "creator.id", function () {
      return this.get("creator.id") === this.get("currentUser.person.id");
    })
  });

  _exports.default = _default;
});