define("crm/routes/email-validated", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel(transition) {
      if (this.get("session.isAuthenticated")) {
        this.get("session").invalidate();
        this.set('session.attemptedTransition', transition);
      }
    },
    model: function model(params) {
      return this.get('store').query('email-validation', {
        "code": params.email_validation_code
      }).then(function (emailValidations) {
        var emailValidation = emailValidations.objectAt(0);

        if (Ember.isEmpty(emailValidation.get("doneDate"))) {
          emailValidation.save();
        }

        return {
          name: emailValidation.get("name"),
          email: emailValidation.get("email")
        };
      });
    }
  });

  _exports.default = _default;
});