define("crm/components/tasks-list-simple", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    filteredModel: Ember.computed("model.@each.patient", function () {
      return Ember.isEmpty(this.get("model")) ? [] : this.get("model").filter(function (t) {
        return !t.get("patient.id") || t.get("patient.enabled");
      });
    }),
    actions: {
      done: function done(task) {
        if (confirm(this.get('intl').t('alerts.done'))) {
          if (Ember.isEmpty(task.get('doneTaskDate'))) {
            task.set('status', 2);
            task.set('doneTaskDate', (0, _moment.default)());
          } else {
            task.set('status', 1);
            task.set('doneTaskDate', null);
          }

          task.save();
        }
      },
      seeMore: function seeMore() {
        this.sendAction('transitToTasks');
      },
      details: function details(model) {
        this.sendAction("openModal", {
          entity: "task",
          action: "details",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});