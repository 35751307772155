define("crm/components/shopping-cart-line-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    chosenPVP: 1,
    item: {},
    canDoShoppingCartLineDetails: Ember.computed("can", function () {
      return this.get("can").cannot("do sale");
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this.set("item", this.get('store').findRecord('item', this.get('model.item.id')));

      if (this.get("model.item.pvp") == this.get("model.unitPrice")) {
        this.set("chosenPVP", 1);
      }

      if (this.get("model.item.pvp2") == this.get("model.unitPrice")) {
        this.set("chosenPVP", 2);
      }

      if (this.get("model.item.pvp3") == this.get("model.unitPrice")) {
        this.set("chosenPVP", 3);
      }
    },
    reset: function reset() {
      this.close();
      this.set("chosenPVP", 1);
    },
    close: function close() {
      Ember.$("#modal-shopping-cart-line-details").modal('hide');
    },
    itemTotalPrice: Ember.computed('model.quantity', 'model.discount', "model.unitPrice", function () {
      var quantity = this.get('model.quantity');
      var price = this.get("model.unitPrice");
      var discount = this.get('model.discount');
      var finalPrice;

      if (discount) {
        finalPrice = (this.money(price) - this.money(price) * (discount / 100)) * quantity;
      } else {
        finalPrice = quantity * this.money(price);
      }

      return this.money(finalPrice);
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    actions: {
      save: function save() {
        this.get('flashMessages').success(this.get('intl').t('alerts.changesSaved'), {
          timeout: 3000
        });
        this.set('model.linePrice', this.get('itemTotalPrice'));
        this.get("model").save();
        this.close();
      },
      canceled: function canceled() {
        if (this.get('canceled')) {
          this.sendAction("canceled");
        }

        this.get("model").rollbackAttributes();
        this.close();
      },
      delete: function _delete() {
        this.sendAction('deleteLine', this.get('model'));
        this.close();
      },
      setChosenPVP: function setChosenPVP(chosenPVP) {
        this.set("chosenPVP", chosenPVP);

        switch (chosenPVP) {
          case 1:
            this.set("model.unitPrice", this.get("model.item.pvp"));
            break;

          case 2:
            this.set("model.unitPrice", this.get("model.item.pvp2"));
            break;

          case 3:
            this.set("model.unitPrice", this.get("model.item.pvp3"));
            break;

          default:
            this.set("model.unitPrice", this.get("model.item.pvp"));
            break;
        }
      }
    }
  });

  _exports.default = _default;
});