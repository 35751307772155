define("crm/routes/organizations/organization/items/item/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    companionItems: [],
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    model: function model() {
      var item = this.modelFor("organizations.organization.items.item");
      return this.store.findRecord("item", item.get("id"), {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var companyID = this.modelFor("organizations/organization").get("company.id");
      var organizationID = this.modelFor("organizations/organization").get("id");
      controller.set("organization", this.modelFor("organizations/organization"));
      controller.set("organizationPet", this.store.findRecord("organization-pet", organizationID));
      this.store.query("item-type", {
        company: companyID
      }).then(function (itemTypes) {
        if (!Ember.isEmpty(itemTypes)) {
          itemTypes.forEach(function (itemType) {
            Ember.set(itemType, "name", self.get('intl').t("itemTypes.translation." + itemType.get("code")));
          });
        }

        controller.set("itemTypes", itemTypes);
      });
      controller.set("models", this.store.query('model', {
        organization: organizationID
      }));
      controller.set("companyItemSubTypes", this.store.query("company-item-sub-type", {
        company: companyID
      }));
      controller.set("ep", this.store.query("equipment-parameter", {
        organization: organizationID
      }));
      controller.set("parameters", this.store.query("equipment-parameter-type", {
        organization: organizationID
      }));
      controller.set("itemItems", this.store.query("item-item", {
        parent: model.get("id")
      }, {
        reload: true
      }));

      if (model.get("period")) {
        var time = model.get("period");

        if (time >= 8760) {
          model.set("periodInput", time / 8760);
        } else if (time >= 720) {
          model.set("periodInput", time / 720);
        } else if (time >= 168) {
          model.set("periodInput", time / 168);
        } else {
          model.set("periodInput", time / 24);
        }
      }

      controller.set("savedItemStockable", model.get("stockable"));
      controller.set("oldString", null);
      var list = [];

      if (!Ember.isEmpty(model.get("children"))) {
        model.get("children").forEach(function (itemItem) {
          self.get("store").findRecord("item-item", itemItem.get("id"), {
            reload: true
          }).then(function (foundItemItem) {
            self.get("store").findRecord("item", itemItem.get("child.id"), {
              reload: true
            });

            if (!Ember.isEmpty(foundItemItem.get("salePrice"))) {
              foundItemItem.set("priceLocked", true);
            }

            if (!Ember.isEmpty(foundItemItem.get("salePrice2"))) {
              foundItemItem.set("priceLocked2", true);
            }

            list.addObject(foundItemItem);
          });
        });
      }

      controller.set("itemItems", list);

      if (!Ember.isEmpty(model.get("companions"))) {
        model.get("companions").forEach(function (companion) {
          self.get("store").findRecord("item-companion", companion.get("id"), {
            reload: true
          }).then(function (foundCompanionItem) {
            model.set("companionItems", self.get("companionItems").addObject(foundCompanionItem));
          });
        });
      } else {
        model.set("companionItems", []);
      }

      if (model.get("baseUnit")) {
        this.store.query("unit", {
          company: companyID,
          isBaseUnit: true,
          dimension: model.get("baseUnit.unitDimension.id")
        }).then(function (dimensionBaseUnits) {
          controller.set("dimensionBaseUnits", dimensionBaseUnits);
        });
      }

      if (model.get("marginType") === 0) {
        controller.set("selectedMarginType", "Markup");
      } else if (model.get("marginType") === 1) {
        controller.set("selectedMarginType", "Margem");
      } else {
        controller.set("selectedMarginType", null);
      }

      if (model.get("marginType2") === 0) {
        controller.set("selectedMarginType2", "Markup");
      } else if (model.get("marginType2") === 1) {
        controller.set("selectedMarginType2", "Margem");
      } else {
        controller.set("selectedMarginType2", null);
      }

      if (model.get("marginType3") === 0) {
        controller.set("selectedMarginType3", "Markup");
      } else if (model.get("marginType3") === 1) {
        controller.set("selectedMarginType3", "Margem");
      } else {
        controller.set("selectedMarginType3", null);
      }

      if (model.get("marginDimension") === 0) {
        controller.set("selectedMarginDimension", "%");
      } else if (model.get("marginDimension") === 1) {
        controller.set("selectedMarginDimension", self.get("intl").t("icons.currency"));
      } else {
        controller.set("selectedMarginDimension", null);
      }

      if (model.get("marginDimension2") === 0) {
        controller.set("selectedMarginDimension2", "%");
      } else if (model.get("marginDimension2") === 1) {
        controller.set("selectedMarginDimension2", self.get("intl").t("icons.currency"));
      } else {
        controller.set("selectedMarginDimension2", null);
      }

      if (model.get("marginDimension3") === 0) {
        controller.set("selectedMarginDimension3", "%");
      } else if (model.get("marginDimension3") === 1) {
        controller.set("selectedMarginDimension3", self.get("intl").t("icons.currency"));
      } else {
        controller.set("selectedMarginDimension3", null);
      }

      if (model.get("itemUnits.length")) {
        self.get("store").query("item-unit", {
          item: model.get("id")
        }).then(function () {
          if (model.get("stockItemUnit")) {
            controller.set("selectedPurchaseUnit", model.get("stockItemUnit.unit"));
            controller.set("selectedPurchaseUnitQuantity", model.get("stockItemUnit.quantity"));
          } else {
            controller.set("selectedPurchaseUnit", null);
            controller.set("selectedPurchaseUnitQuantity", null);
          }

          if (model.get("saleItemUnit")) {
            controller.set("selectedSalesUnit", model.get("saleItemUnit.unit"));
            controller.set("selectedSalesUnitQuantity", model.get("saleItemUnit.quantity"));
          } else {
            controller.set("selectedSalesUnit", null);
            controller.set("selectedSalesUnitQuantity", null);
          }
        });
      } else {
        controller.set("selectedPurchaseUnit", null);
        controller.set("selectedPurchaseUnitQuantity", null);
        controller.set("selectedSalesUnit", null);
        controller.set("selectedSalesUnitQuantity", null);
      }

      this.store.query("tax", {
        company: companyID
      }).then(function (taxes) {
        controller.set("taxes", taxes);

        if (taxes) {
          if (!model.get("vat.content")) {
            model.set("vat", taxes.find(function (item) {
              return item.get("vat.description") === "TN" || item.get("vat.description") === "GEN";
            }));
          }
        }
      });
      controller.set("selectedVariationType", this.get("catalogs.variationTypes").find(function (vt) {
        return vt.id == model.get("variationType");
      }));
    }
  });

  _exports.default = _default;
});