define("crm/components/address-new", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    countries: _uConstants.default.countries,
    address: {},
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this.set("selectedCountry", this.get("countries").find(function (item) {
        return item.code === _this.get("organization.country");
      }));
      this.set("address.country", this.get("countries").find(function (item) {
        return item.code === _this.get("organization.country");
      }));
    },
    close: function close() {
      Ember.$("#modal-address-new").modal("hide");
    },
    patternZipFirstPart: Ember.computed("zipFirstBox", "selectedCountry", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{4}";
      }
    }),
    patternZipSecondPart: Ember.computed("zipSecondPart", "selectedCountry", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{3}";
      }
    }),
    disabledActionAddressNew: Ember.computed("saving", "address.street", "zipFirstPart", "zipSecondPart", "address.town", function () {
      return Ember.isEmpty(this.get("address.street")) && Ember.isEmpty(this.get("zipFirstPart")) && Ember.isEmpty(this.get("zipSecondPart")) && Ember.isEmpty(this.get("address.town")) || this.get("saving");
    }),
    actions: {
      selectCountry: function selectCountry(value) {
        this.set("selectedCountry", value);
        this.set("address.country", value.code);
      },
      createAddress: function createAddress() {
        this.set("saving", true);
        var self = this;
        this.set("address.customer", this.get("model"));

        if (!Ember.isEmpty(this.get("zipFirstPart"))) {
          if (!Ember.isEmpty(self.get("zipSecondPart"))) {
            self.set("address.zip", self.get("zipFirstPart") + "-" + self.get("zipSecondPart"));
          } else {
            self.set("address.zip", self.get("zipFirstPart"));
          }
        }

        this.set("address.name", this.get("model.name") + "-" + this.get("address.town"));

        if (!Ember.isEmpty(this.get("address.country")) && !Ember.isEmpty(this.get("address.country").code)) {
          this.set("address.country", this.get("address.country").code);
        }

        var address = this.get("store").createRecord("address", this.get("address"));
        address.save().then(function () {
          self.get("store").query("address", {
            customer: self.get("model.id")
          });
          self.close();
        }).catch(function () {
          self.set("saving", false);
          self.get("store").unloadRecord(address);
        });
      },
      reset: function reset() {
        this.set("address", {});
        Ember.$("select").prop("selectedIndex", 0);
        this.set("saving", false);
        this.set("zipFirstPart", null);
        this.set("zipSecondPart", null);
      }
    }
  });

  _exports.default = _default;
});