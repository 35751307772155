define("crm/components/open-purchase-invoices-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    displayPayButton: Ember.computed("record.paidAmount", "record.payableAmount", function () {
      return this.get("record.paidAmount") !== this.get("record.payableAmount");
    }),
    actions: {
      setPaid: function setPaid() {
        if (confirm(this.get("intl").t("shoppingCarts.markAsPaid"))) {
          this.set("record.paidAmount", this.get("record.payableAmount"));
          this.get("record").save();
        }
      }
    }
  });

  _exports.default = _default;
});