define("crm/abilities/customer", ["exports", "crm/abilities/crm"], function (_exports, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _crm.default.extend({
    canMerge: Ember.computed.alias("manager"),
    canDisable: Ember.computed('worker.role', 'settings.customer_disable', function () {
      if (this.get("settings.customer_disable")) {
        return true;
      } else {
        this.get("manager");
      }
    }),
    canSchedule: Ember.computed("features.[]", function () {
      return !this.hasFeature("veterinary");
    })
  });

  _exports.default = _default;
});