define("crm/routes/organizations/organization/listings/sales/vat-stats", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    store: Ember.inject.service("session"),
    breadCrumb: {
      finalTitle: "Recebimentos"
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var createdAfter = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdAfter).startOf("day").utc().toISOString();
      var createdBefore = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdBefore).endOf("day").utc().toISOString();
      return Ember.RSVP.hash({
        invoiceLines: Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/invoiceLines/taxes" + "?organization=" + organization.get("id") + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        }),
        memoLines: Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/memoLines/taxes" + "?organization=" + organization.get("id") + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return {
          invoiceLines: r.invoiceLines.map(function (i) {
            return {
              vatAmount: i[0],
              linePriceSum: i[1],
              linePriceWoVatSum: i[2],
              vatLinePriceSum: i[3],
              totalSum: i[4]
            };
          }),
          memoLines: r.memoLines.map(function (i) {
            return {
              vatAmount: i[0],
              linePriceSum: i[1],
              linePriceWoVatSum: i[2],
              vatLinePriceSum: i[3],
              totalSum: i[4]
            };
          })
        };
      });
    }
  });

  _exports.default = _default;
});