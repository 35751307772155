define("crm/components/u-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    steps: Ember.computed('pdfContent', function () {
      return this.get('pdfContent');
    }),
    steps2: []
  });

  _exports.default = _default;
});