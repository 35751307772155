define("crm/routes/application", ["exports", "crm/config/environment", "universe-gui/routes/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    intl: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel() {
      this._super();

      this.get("intl").setLocale(['pt']);
      document.title = _environment.default.universe.appName;
    },
    checkVersion: function checkVersion() {
      var timeStamp = new Date().getTime(); // Number of milliseconds since 1970/01/01 used to enforce get to server instead of getting from cache

      Ember.$.ajax({
        type: "GET",
        url: "system/info.json?q=" + timeStamp,
        accept: "application/json; charset=utf-8",
        contentType: "application/json; charset=utf-8",
        success: function success(result) {
          if (result.system.deploy !== _environment.default.deploy) {
            window.location.reload(true);
          }
        }
      });
    },
    actions: {
      willTransition: function willTransition() {
        if (Ember.$("body").width() < 768) {
          Ember.$("body").removeClass("mini-navbar");
        } else {
          Ember.$("body").addClass("mini-navbar");
        }

        this.checkVersion();
      }
    }
  });

  _exports.default = _default;
});