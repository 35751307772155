define("crm/components/financial-account-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "component": "financial-account-type-list",
        "title": this.get('intl').t("financialAccounts.financialAccount.type")
      }, {
        "propertyName": "internalDescription",
        "title": this.get('intl').t("financialAccounts.financialAccount.name")
      }, {
        "propertyName": "accountNumber",
        "title": this.get('intl').t("financialAccounts.financialAccount.accountNumber")
      }, {
        "propertyName": "iban",
        "title": this.get('intl').t("financialAccounts.financialAccount.iban")
      }, {
        "propertyName": "swift",
        "title": this.get('intl').t("financialAccounts.financialAccount.swift")
      }, {
        "component": "financial-account-is-manual",
        "title": this.get('intl').t("financialAccounts.financialAccount.isManual")
      }, {
        "component": "financial-account-actions"
      }];
    }),
    actions: {
      editFinancialAccount: function editFinancialAccount(model) {
        Ember.$("#modal-financialAccount-list").modal('hide');
        this.sendAction("openModal", {
          entity: "financialAccount",
          action: "edit",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});