define("crm/components/internment/todo-edit", ["exports", "moment", "crm/components/pu-crud-modal"], function (_exports, _moment, _puCrudModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _puCrudModal.default.extend({
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    periods: Ember.computed.readOnly("catalogs.todoPeriods"),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly('sessionAccount.currentUser'),

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        if (!this.get("model.started")) {
          this.set("model.started", (0, _moment.default)().startOf("h"));
        }
      }
    },

    /*
     * Computed
     */
    selectedPeriodObject: Ember.computed("model.period", function () {
      var _this = this;

      return !this.get("model.period") ? null : this.get("periods").find(function (p) {
        return p.id === _this.get("model.period");
      });
    }),
    allowsDecimals: Ember.computed("organizationPet.usesDecimals", "model.internment.organization.id", function () {
      return this.get("organizationPet.usesDecimals") || ["70"].includes(this.get("model.internment.organization.id"));
    }),
    updateStarted: Ember.computed("model.volumeTotal", "model.via", "model.unit", "model.adjustment", "model.amount", 'model.period', "model.prevStarted", "model.started", 'model.lastAction.started', function () {
      if (this.get('model')) {
        if (this.get('model.id')) {
          if (this.get('model.started')) {
            if (false === Ember.isEmpty(this.get('model.allActions'))) {
              if (this.get('model.lastAction.started') && (0, _moment.default)(this.get('model.started')).isAfter(this.get('model.lastAction.started'))) {
                // Started date was changed
                return this.get('model.started'); // Started date chosen by the user
              } else {
                return this.get('model.lastAction.started'); // Last action done started date

                /*
                 if(this.get('model.lastAction.started') && (this.get('model').changedAttributes()["volumeTotal"] || this.get('model').changedAttributes()["via"] || this.get('model').changedAttributes()["unit"] || this.get('model').changedAttributes()["adjustment"] || this.get('model').changedAttributes()["amount"] || this.get('model').changedAttributes()["period"])) {
                     return this.get('model.lastAction.started'); // Last action done started date
                 }
                 */
              }
            } else {
              return this.get('model.started'); // Started date chosen by the user
            }
          } else {
            return this.get('model.prevStarted'); // True started date
          }
        } else {
          return this.get('model.started'); // Started date chosen by the user
        }
      }
    }),

    /*
     * Functions
     */
    close: function close() {
      this.toggleProperty("closed");
    },
    reset: function reset() {
      this.set("saving", false);
    },
    clone: function clone() {
      return {};
    },
    save: function save() {
      var self = this;
      this.set("saving", true);
      /* exists & changed description or ended */

      if (this.get('model').changedAttributes()) {
        var changedAttributes = this.get('model').changedAttributes();

        if (Object.keys(changedAttributes).length === 1 && changedAttributes.description || Object.keys(changedAttributes).length === 1 && changedAttributes.ended || Object.keys(changedAttributes).length === 2 && changedAttributes.description && changedAttributes.ended // Only changed description and/or ended
        ) {
            this.get("model").save(); // this.send('update');

            self.close();
            return;
          }
      }

      if (Ember.isEmpty(this.get('model.allActions'))) {
        this.get("model").save(); // this.send('update');

        self.close();
        return;
      } else {
        var newTodo = this.clone();
        newTodo.started = this.get("updateStarted");
        newTodo.name = this.get("model.name");
        newTodo.creator = this.get("currentUser");
        newTodo.description = this.get("model.description");
        newTodo.ended = this.get("model.ended");
        newTodo.period = this.get("model.period");
        newTodo.prevTodo = this.get("model");
        newTodo.type = this.get("model.type");
        newTodo.internment = this.get("model.internment");
        var entity = this.get("store").createRecord(this.get("modelName"), newTodo);
        entity.save().then(function () {
          self.set("model.internment", null); // self.get("store").findRecord("internment", newTodo.internment.get("id"));
          // self.get("store").findRecord(self.get("modelName"), self.get("model.id"));

          self.close();
        }).catch(function (result) {
          return self.error(entity, result);
        });
      }
    },

    /*
     * Actions
     */
    actions: {
      setType: function setType(type) {
        if (type) {
          this.set("model.type", type);
          this.set("model.name", type.get("name"));
        } else {
          this.set("model.type", null);
          this.set("model.name", null);
        }
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});