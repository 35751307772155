define("crm/abilities/appointment", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canCreate: Ember.computed('worker.role', 'settings.calendar_receptionist', function () {
      if (this.get("settings.calendar_receptionist")) {
        return this.is(this.get("receptionist"));
      } else {
        return true;
      }
    }),
    canUpdate: Ember.computed('worker.role', 'settings.calendar_receptionist', function () {
      if (this.get("settings.calendar_receptionist")) {
        return this.is(this.get("receptionist"));
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});