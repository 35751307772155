define("crm/abilities/item", ["exports", "crm/abilities/undermanager"], function (_exports, _undermanager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _undermanager.default.extend({
    canRead: true,
    canChangeCartPrice: Ember.computed(function () {
      return this.get("manager") || ["192", "198", "337"].includes(this.get("worker.organization.id"));
    }),
    canSale: Ember.computed('featureNames.[]', function () {
      return this.hasFeature("sales");
    })
  });

  _exports.default = _default;
});