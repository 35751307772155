define("crm/routes/organizations/organization/listings/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    queryParams: {
      dateFrom: {
        refreshModel: true
      },
      dateTo: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do manager')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      return {
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        organization: this.modelFor("organizations.organization")
      };
    }
  });

  _exports.default = _default;
});