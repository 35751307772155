define("crm/routes/organizations/organization/shopping-carts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }
    },
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      return Ember.RSVP.hash({
        sc: this.store.query("shopping-cart", {
          organization: organizationID,
          status: 0
        }),
        pp: this.store.query("customer", {
          organization: organizationID,
          shoppingCarts: 0
        })
      }).then(function (h) {
        return h.sc.sortBy("customer.name");
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('organization', this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});