define("crm/components/internment/finish-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    deceased: false,
    close: function close() {
      this.toggleProperty("closed");
    },
    disableButtons: Ember.computed("saving", function () {
      return this.get("saving");
    }),
    actions: {
      reset: function reset() {
        if (this.get("saving")) {
          // after save button
          this.set("saving", false);
        } else {
          // cancel or close
          this.set('model.endReason', undefined);
          this.set('deceased', false);
          this.set("deceasedDate", undefined);
        }
      },
      cancel: function cancel() {
        this.set('model.endReason', undefined);
        this.close();
      },
      finish: function finish() {
        var self = this;
        this.set("saving", true);
        this.set("model.end", (0, _moment.default)());
        this.get('store').findRecord('internment', this.get("model.id")).then(function (internment) {
          self.set('showEndReason', false);
          internment.set('ended', new Date());
          internment.save().then(function () {
            if (self.get('deceased')) {
              self.get('store').findRecord('patient', self.get('model.patient.id')).then(function (patient) {
                patient.set('deathdate', self.get('deceasedDate'));
                patient.set('deathReason', self.get('deathReason'));
                patient.save().catch(function (error) {
                  self.get("es").errorReturned(error);
                  self.set("saving", true);
                });
              });
            }

            self.close();
            self.sendAction("transitionToRoute", "organizations.organization.internments");
          }).catch(function (error) {
            self.get("es").errorReturned(error);
            self.set("saving", true);
          });
        });
      }
    }
  });

  _exports.default = _default;
});