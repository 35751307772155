define("crm/routes/organizations/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var self = this;
      return this.get("store").findAll("company").then(function (companies) {
        if (Ember.isEmpty(companies)) {
          self.transitionTo('companies.new');
        }

        var model = {
          country: companies.get("firstObject.country"),
          companies: companies.sortBy("name")
        };

        if (companies.get("length") === 1 && companies.get("firstObject")) {
          model["company"] = companies.get("firstObject");
        }

        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('companies', model.companies);
    },
    actions: {
      created: function created(id) {
        this.transitionTo('organizations.organization', id);
      },
      canceled: function canceled() {
        this.transitionTo('organizations');
      }
    }
  });

  _exports.default = _default;
});