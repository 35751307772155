define("crm/controllers/organizations/organization/clinical-waiting-rooms/index", ["exports", "moment", "ember-models-table/themes/bootstrap3"], function (_exports, _moment, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    isDirty: true,
    disableBtn: false,
    appointment: null,
    newAppointment: {},
    filteredWorker: null,
    newFastAppointment: {},
    createdOnAppointment: false,
    disableBtnWithoutResource: false,
    canDoClinicalWaitingRoom: Ember.computed("can", function () {
      return this.get("can").can("do clinical");
    }),
    AppointmentsInCheckinUnscheduled: Ember.computed("model.appointments.@each.status", "filteredWorker.id", function () {
      if (this.get("filteredWorker.id")) {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("worker.id", this.get("filteredWorker.id")).filterBy("status", 2).sortBy("start");
      } else {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("status", 2).sortBy("start");
      }
    }),
    AppointmentsInCheckinScheduled: Ember.computed("model.appointments.@each.status", "filteredWorker.id", function () {
      if (this.get("filteredWorker.id")) {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("worker.id", this.get("filteredWorker.id")).filterBy("status", 3).sortBy("start");
      } else {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("status", 3).sortBy("start");
      }
    }),
    AppointmentsCheckin: Ember.computed("model.appointments.@each.status", "filteredWorker.id", function () {
      if (this.get("filteredWorker.id")) {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("worker.id", this.get("filteredWorker.id")).filter(function (appointment) {
          return appointment.get("status") == 2 || appointment.get("status") == 3;
        }).sortBy("start");
      } else {
        return this.get("model.appointments") && this.get("model.appointments").filter(function (appointment) {
          return appointment.get("status") == 2 || appointment.get("status") == 3;
        }).sortBy("start");
      }
    }),
    appointmentsInVisit: Ember.computed("model.appointments.@each.status", "filteredWorker", function () {
      if (this.get("filteredWorker.id")) {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("worker.id", this.get("filteredWorker.id")).filterBy("status", 4).sortBy("start");
      } else {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("status", 4).sortBy("start");
      }
    }),
    appointmentsInVisitPaused: Ember.computed("model.appointments.@each.status", "filteredWorker.id", function () {
      if (this.get("filteredWorker.id")) {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("worker.id", this.get("filteredWorker.id")).filterBy("status", 9).sortBy("start");
      } else {
        return this.get("model.appointments") && this.get("model.appointments").filterBy("status", 9).sortBy("start");
      }
    }),
    tasksFiltered: Ember.computed("tasks.@each.name", function () {
      return Ember.isEmpty(this.get("tasks")) ? undefined : this.get("tasks").filter(function (t) {
        return !t.get("name").match("Contactar:.+");
      });
    }),
    actions: {
      moveToInCheckin: function moveToInCheckin(appointment) {
        this.store.findRecord("appointment", appointment.get("id")).then(function () {
          if (!Ember.isEmpty(appointment.get("endConsultation"))) {
            // was at checkout
            appointment.set("endConsultation", null);
          }

          if (!Ember.isEmpty(appointment.get("timeConsultation"))) {
            // was in consultation
            appointment.set("timeConsultation", null);
          }

          appointment.set("arrivalTime", (0, _moment.default)().format());
          appointment.set("status", 3);
          appointment.save();
        });
      },
      moveToInVisit: function moveToInVisit(appointment) {
        var self = this;

        if (!appointment.get("customer.id")) {
          return null;
        }

        appointment.set("status", 4);
        appointment.save().then(function () {
          self.get("store").query("consultation", {
            organization: self.get("model.id"),
            appointment: appointment.get("id")
          }).then(function (consultations) {
            self.transitionToRoute("organizations.organization.consultations.consultation", consultations.get("firstObject.id"));
          });
        });
      },
      moveToPOS: function moveToPOS(appointment) {
        var _this = this;

        if (!appointment.get("customer.id")) {
          return null;
        }

        this.store.findRecord("appointment", appointment.get("id")).then(function () {
          var queryParams = {
            customerId: appointment.get("customer.id"),
            orderId: appointment.get("consultation.order.id"),
            appointmentId: appointment.get("id")
          };

          if (appointment.get("consultation.order.id")) {
            _this.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
          }
        });
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      },
      openModalAppointmentDetails: function openModalAppointmentDetails(appointment) {
        this.send("openAppointmentDetails", appointment);
      },
      moveToAppointments: function moveToAppointments() {
        this.send("transitionToRouteAppointmentsDay");
      },
      createConsultation: function createConsultation() {
        this.send("openModal", {
          entity: "consultation",
          action: "new"
        });
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newAppointmentCheckin: function newAppointmentCheckin() {
        var shouldCheckin = {
          "shouldCheckin": "shouldCheckin"
        };
        this.send("openModal", {
          entity: "appointment",
          action: "create",
          model: shouldCheckin
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      openCreateCheckin: function openCreateCheckin() {
        Ember.$("#createCheckIn").modal();
      },
      openModalCreateFastAppointment: function openModalCreateFastAppointment() {
        Ember.$("#openCreateFastAppointment").modal();
      },
      modalCreateFastAppointment: function modalCreateFastAppointment(patient) {
        var self = this;
        this.set("newFastAppointment.organization", this.get("model"));

        if (this.get("workerID")) {
          var worker = this.get("store").peekRecord("worker", this.get("workerID"));
          this.set("newFastAppointment.worker", worker);
        }

        if (this.get("typeID")) {
          var type = this.get("store").peekRecord("type", this.get("typeID"));
          this.set("newFastAppointment.appointment.type", type);
        }

        this.set("newFastAppointment.patient", patient);
        this.set("newFastAppointment.customer", patient.get("owner"));
        this.set("newFastAppointment.arrivalTime", (0, _moment.default)()); //agendado s/ marcação

        this.set("newFastAppointment.status", 2); //agendado s/ marcação

        this.set("newFastAppointment.calls", 0); //ainda não foi chamado

        this.set("newFastAppointment.waitingRoom", true); //vai sempre para a sala de espera

        this.set("newFastAppointment.start", (0, _moment.default)());
        var newFastAppointment = this.get("store").createRecord("appointment", this.get("newFastAppointment"));
        newFastAppointment.save().then(function (result) {
          self.resetFastAppointment();
          self.get("appointments").addObject(result._internalModel); //TODO
        }).catch(function () {
          self.get("store").unloadRecord(newFastAppointment);
        });
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set("appointment", appointment);
          Ember.$("#openCreateAppointment").modal();
        }
      },
      filterWorkers: function filterWorkers(worker) {
        if (Ember.isEmpty(worker)) {
          this.set("filteredWorker", null);
        } else {
          this.set("filteredWorker", worker);
        }
      }
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.title"),
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "owner.name",
        "title": this.get('intl').t("customers.customer.title")
      }, {
        "propertyName": "owner.lastContact.contact",
        "title": this.get('intl').t("customers.customer.contacts.contact")
      }, {
        "component": "show-appointment-actions"
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t("modelsTable.show") + " %@ - %@ " + this.get('intl').t("modelsTable.of") + " %@",
          "noDataToShow": this.get('intl').t("modelsTable.noDataToShow")
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "",
        "clearAllFiltersIcon": ""
      });
    }),
    resetFastAppointment: function resetFastAppointment() {
      this.set("newFastAppointment", {});
      Ember.$("#openCreateFastAppointment").modal("hide");
    }
  });

  _exports.default = _default;
});