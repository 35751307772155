define("crm/components/internment/details-chart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    actionModalModel: {},
    taskModalModel: {},
    displayChartDateInitInput: false,
    hourWindow: 24,
    step: 1,
    clock: (0, _moment.default)(),

    /*
     * Settings
     */
    start: Ember.computed("model.patient.organization.id", function () {
      return ["70", "119"].includes(this.get("model.patient.organization.id")) ? 7 : 8;
    }),
    chartDateInit: Ember.computed('clock', function () {
      if (this.get('model.hasEnded')) {
        var end = (0, _moment.default)(this.get('model.end'));

        if (end.hours() >= this.get('start')) {
          return end.hours(this.get('start')).startOf('hour');
        } else {
          return end.hours(this.get('start')).startOf('hour').subtract(this.get("hourWindow"), 'h');
        }
      }

      if (this.get('clock').hours() >= this.get('start')) {
        return (0, _moment.default)().hours(this.get('start')).startOf('hour');
      } else {
        return (0, _moment.default)().hours(this.get('start')).startOf('hour').subtract(this.get("hourWindow"), 'h');
      }
    }),
    chartDateEnd: Ember.computed('chartDateInit', "hourWindow", function () {
      return (0, _moment.default)(this.get("chartDateInit")).endOf("hour").add(this.get("hourWindow") - 1, "hours");
    }),

    /*
     * Navigation
     */
    isFirstDay: Ember.computed('chartDateInit', 'model.start', function () {
      if (this.get('model.start')) {
        if ((0, _moment.default)(this.get('model.start')).hours() >= this.get('start')) {
          return this.get('chartDateInit').isSame(this.get('model.start'), 'd');
        } else {
          return this.get('chartDateInit').isSame((0, _moment.default)(this.get('model.start')).subtract(this.get("hourWindow"), 'h'), 'd');
        }
      } else {
        return false;
      }
    }),
    isCurrentDay: Ember.computed('chartDateInit', function () {
      var now = (0, _moment.default)();

      if (now.hours() >= this.get('start')) {
        return this.get('chartDateInit').isSame(now, 'd');
      } else {
        return this.get('chartDateInit').isSame(now.subtract(this.get("hourWindow"), 'h'), 'd');
      }
    }),
    isLastDay: Ember.computed('chartDateInit', 'model.end', function () {
      if (this.get('model.end')) {
        if ((0, _moment.default)(this.get('model.end')).hours() >= this.get('start')) {
          return this.get('chartDateInit').isSame(this.get('model.end'), 'd');
        } else {
          return this.get('chartDateInit').isSame((0, _moment.default)(this.get('model.end')).subtract(this.get("hourWindow"), 'h'), 'd');
        }
      } else {
        return false;
      }
    }),

    /*
     * Chart
     */
    thisDates: Ember.computed('chartDateInit', "chartDateEnd", "step", function () {
      var result = [];
      var date = (0, _moment.default)(this.get("chartDateInit"));

      do {
        result.addObject((0, _moment.default)(date));
        date.add(this.get("step"), 'hours');
      } while (date.hours() != (0, _moment.default)(this.get("chartDateInit")).hours());

      return result;
    }),

    /*
     * OTHES
     */
    chartDateInitAux: Ember.computed('chartDateInit', function () {
      return (0, _moment.default)(this.get('chartDateInit')).format("YYYY-MM-DD");
    }),
    chores: Ember.computed("model.chores.@each.prevStarted", "model.chores.@each.ended", "chartDateInit", "chartDateEnd", function () {
      var self = this;
      return this.get('model.chores').filter(function (todo) {
        return !todo.get("nextTodo.id") && !todo.get("prevStarted") && (!todo.get("ended") || self.get("chartDateEnd").isSameOrBefore(todo.get("ended"))) || self.get("chartDateEnd").isAfter(todo.get("prevStarted")) && (!todo.get("ended") || self.get("chartDateInit").isSameOrBefore(todo.get("ended")));
      }).sortBy('name');
    }),
    drugs: Ember.computed("model.drugs.@each.nextTodo", "model.drugs.@each.prevStarted", "model.drugs.@each.ended", "chartDateInit", "chartDateEnd", function () {
      var self = this;
      return this.get('model.drugs').filter(function (todo) {
        return !todo.get("nextTodo.id") && !todo.get("prevStarted") && (!todo.get("ended") || self.get("chartDateEnd").isSameOrBefore(todo.get("ended"))) || self.get("chartDateEnd").isAfter(todo.get("prevStarted")) && (!todo.get("ended") || self.get("chartDateInit").isSameOrBefore(todo.get("ended")));
      }).sortBy('name');
    }),
    monitorings: Ember.computed("model.monitorings.@each.prevStarted", "model.monitorings.@each.ended", "chartDateInit", "chartDateEnd", function () {
      var self = this;
      return this.get('model.monitorings').filter(function (todo) {
        return !todo.get("nextTodo.id") && !todo.get("prevStarted") && (!todo.get("ended") || self.get("chartDateEnd").isSameOrBefore(todo.get("ended"))) || self.get("chartDateEnd").isAfter(todo.get("prevStarted")) && (!todo.get("ended") || self.get("chartDateInit").isSameOrBefore(todo.get("ended")));
      }).sortBy('name');
    }),
    exams: Ember.computed("model.exams.@each.prevStarted", "model.exams.@each.ended", "chartDateInit", "chartDateEnd", function () {
      var self = this;
      return this.get('model.exams').filter(function (todo) {
        return !todo.get("nextTodo.id") && !todo.get("prevStarted") && (!todo.get("ended") || self.get("chartDateEnd").isSameOrBefore(todo.get("ended"))) || self.get("chartDateEnd").isAfter(todo.get("prevStarted")) && (!todo.get("ended") || self.get("chartDateInit").isSameOrBefore(todo.get("ended")));
      }).sortBy('name');
    }),
    serums: Ember.computed("model.serums.@each.prevStarted", "model.serums.@each.ended", "chartDateInit", "chartDateEnd", function () {
      var self = this;
      return this.get('model.serums').filter(function (todo) {
        return !todo.get("nextTodo.id") && !todo.get("prevStarted") && (!todo.get("ended") || self.get("chartDateEnd").isSameOrBefore(todo.get("ended"))) || self.get("chartDateEnd").isAfter(todo.get("prevStarted")) && (!todo.get("ended") || self.get("chartDateInit").isSameOrBefore(todo.get("ended")));
      }).sortBy('name');
    }),
    actions: {
      /*
       * Navigation
       */
      currentDay: function currentDay() {
        if (this.get('clock').hours() >= this.get('start')) {
          this.set("chartDateInit", (0, _moment.default)().startOf('day').hours(this.get("start")));
        } else {
          this.set("chartDateInit", (0, _moment.default)().startOf('day').hours(this.get("start")).subtract(this.get("hourWindow"), 'h'));
        }
      },
      previousDay: function previousDay() {
        var aux = (0, _moment.default)(this.get("chartDateInit"));
        aux.subtract(this.get("hourWindow") * this.get("step"), 'hours');
        this.set("chartDateInit", aux);
      },
      nextDay: function nextDay() {
        var aux = (0, _moment.default)(this.get("chartDateInit")); //aux.add(this.get("hourWindow") * this.get("step"), 'hours');

        aux.add(1, "day");
        this.set("chartDateInit", aux);
      },
      setChartDateInit: function setChartDateInit(datetimeStr) {
        this.set('chartDateInit', datetimeStr);
        this.set('displayChartDateInitInput', false);
      },
      setDisplayChartDateInitInput: function setDisplayChartDateInitInput() {
        this.set('displayChartDateInitInput', true);
      },

      /*
       * Todo Modals
       */
      callModal: function callModal(entity, action, model) {
        this.get("callModal")({
          entity: entity,
          action: action,
          model: model || {
            internment: this.get("model")
          }
        });
      },
      openModalDrugNew: function openModalDrugNew() {
        Ember.$('#modal-todo-drug-new').modal();
      },
      openModalDrugEdit: function openModalDrugEdit() {
        Ember.$('#modal-todo-drug-edit').modal();
      },
      openModalExamNew: function openModalExamNew() {
        Ember.$('#modal-todo-exam-new').modal();
      },
      openModalExamEdit: function openModalExamEdit() {
        Ember.$('#modal-todo-exam-edit').modal();
      },
      openModalSerumNew: function openModalSerumNew() {
        Ember.$('#modal-todo-serum-new').modal();
      },
      openModalSerumEdit: function openModalSerumEdit() {
        Ember.$('#modal-todo-serum-edit').modal();
      },
      openModalMonitoringEdit: function openModalMonitoringEdit() {
        Ember.$('#modal-todo-monitoring-edit').modal();
      },
      openModalChoreNew: function openModalChoreNew() {
        Ember.$('#modal-todo-chore-new').modal();
      },
      openModalChoreEdit: function openModalChoreEdit() {
        Ember.$('#modal-todo-chore-edit').modal();
      },
      openAction: function openAction(task, thisDate, action, statusColor, prevAction) {
        if (this.get('model.disabled')) {
          return;
        }

        this.set('modalthisDate', thisDate);
        this.set('modalAction', action);
        this.set('modalPrevAction', prevAction);
        this.set('modalShouldDisplayRemoveButton', statusColor !== "add-action");
        this.openWithTrueTask(thisDate, task);
      },
      back: function back() {
        this.sendAction('back');
      },
      setStatus: function setStatus(task) {
        var self = this;

        if (Ember.isEmpty(task.get('status'))) {
          task.set('status', 1);
        } else if (task.get('status') === 1) {
          task.set('status', undefined);
        }

        task.save().catch(function (result) {
          self.get("es").errorReturned(result);
        });
      },
      transitionToActionDetails: function transitionToActionDetails(clinicID, internmentID, taskID, actionID, taskType) {
        this.sendAction("transitionToActionDetails", clinicID, internmentID, taskID, actionID, taskType);
      }
    },
    openWithTrueTask: function openWithTrueTask(thisDate, task) {
      if (thisDate.isBefore(task.get('started'), "h") && task.get('prevTodo.started')) {
        this.openWithTrueTask(thisDate, this.get('store').peekRecord(task.get('constructor.modelName'), task.get('prevTodo.id')));
      } else {
        this.set('actionModalModel', task);
        Ember.$('#modal-action-edit').modal();
      }
    }
  });

  _exports.default = _default;
});