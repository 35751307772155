define("crm/models/item-type", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    intl: Ember.inject.service(),
    stockable: _emberData.default.attr("boolean"),
    isVisit: _emberData.default.attr("boolean"),
    isPeriodic: _emberData.default.attr("boolean"),
    isDrug: _emberData.default.attr("boolean"),
    isExam: _emberData.default.attr("boolean"),
    code: _emberData.default.attr("string"),
    period: _emberData.default.attr("number"),
    parent: _emberData.default.belongsTo("item-type", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("item-type", {
      inverse: "parent"
    }),
    companyItemTypes: _emberData.default.hasMany("company-item-type"),
    name: Ember.computed("intl", function () {
      return this.get("intl").t("itemTypes.translation." + this.get("code"));
    }),
    codes: Ember.computed("code", "parent.codes", function () {
      if (Ember.isEmpty(this.get("parent.codes"))) {
        return [this.get("code")];
      } else {
        var result = [];
        this.get("parent.codes").forEach(function (e) {
          result.push(e);
        });
        result.push(this.get("code"));
        return result;
      }
    }),
    codesStr: Ember.computed("codes", function () {
      var names = this.get("codes");

      if (Ember.isEmpty(names)) {
        return "";
      }

      var result = names[0];

      for (var i = 1; i < names.length; i++) {
        result += " " + names[i];
      }

      return result;
    }),
    level: Ember.computed("codes.[]", function () {
      return this.get("codes.length") ? this.get("codes.length") - 1 : 0;
    }),
    names: Ember.computed("parent.names", function () {
      if (Ember.isEmpty(this.get("parent.names"))) {
        return this.get("name");
      } else {
        return this.get("parent.names") + ", " + this.get("name");
      }
    }),
    root: Ember.computed("id", "parent.id", "parent.root", function () {
      return this.get("parent.id") ? this.get("parent.root") : this;
    }),
    typePath: Ember.computed("parent.id", "id", "parent.typePath", function () {
      return this.get("parent.id") ? this.get("parent.typePath").concat(this) : [this];
    }),
    color: Ember.computed("root.code", function () {
      switch (this.get("root.code")) {
        case "food":
          return "#FFB300";

        case "wellness":
          return "#64B5F6";

        case "surgery":
          return "#90B4CE";

        case "consultations":
          return "#4CAF50";

        case "consumables":
          return "#FF9800";

        case "cmdt":
          return "#81C784";

        case "hospitalization":
          return "#6bcaba";

        case "hotel":
          return "#9C27B0";

        case "drugs":
          return "#81C784";

        case "pecuaria":
          return "#795548";

        case "petshop":
          return "#FFD54F";

        case "health_plans":
          return "#4CAF50";

        case "procedures":
          return "#00bcd4";

        case "profilaxia":
          return "#81C784";

        case "rehabilitation":
          return "#0288D1";

        case "referral":
          return "#00796b";
        //case "health_plans":
        //   return "#FFB300";

        case "complementary_services":
          return "#FFC107";

        case "vaccines":
          return "#EF7350";
      }
    })
  });

  _exports.default = _default;
});