define("crm/routes/organizations/organization/financial-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("sales")) {
        return null;
      }

      if (this.get("can").cannot('read financial-account')) {
        transition.abort();
        this.send("noPermissions");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});