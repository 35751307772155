define("crm/routes/organizations/organization/listings/crm/patients/all", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      csv: function csv() {
        var organization = this.modelFor("organizations.organization");
        this.set("exporting", true);
        var self = this;
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/patients/csv?company=" + organization.get("company.id"),
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = self.get("intl").t("patients.title") + "_" + new Date().toISOString().slice(0, 10) + ".csv";
            a.click();
            window.URL.revokeObjectURL(url);
            self.set("exporting", false);
          },
          error: function error() {
            self.set("exporting", false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});