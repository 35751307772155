define("crm/components/pet-new-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["modal", "fade"],
    actions: {
      sendAction1: function sendAction1() {
        this.sendAction("action1");
      },
      sendAction2: function sendAction2() {
        this.sendAction("action2");
      }
    }
  });

  _exports.default = _default;
});