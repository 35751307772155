define("crm/components/internment/todo-serum-new", ["exports", "crm/components/internment/todo-new"], function (_exports, _todoNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoNew.default.extend({
    catalogs: Ember.inject.service(),
    todoPeriods: Ember.computed.alias("catalogs.todoPeriods"),
    modelName: "todo-serum",
    supplementations: [{}],
    adjustments: Ember.computed.alias("catalogs.adjustments"),
    units: Ember.computed.alias("catalogs.units"),

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("model")) {
        this.set("model.adjustment", 1);
        this.set("model.period", 0);

        if (this.get("model.internment.patient.maintenanceFluidRate")) {
          this.set("model.fluidRate", this.get("model.internment.patient.maintenanceFluidRate"));
        } else {
          this.set("model.fluidRate", null);
        }
      }
    },

    /*
     * Computed
     */
    fluids: Ember.computed("model.internment.organization.company.id", function () {
      // TODO query story for these items units
      return this.get("model.internment.organization.company.id") ? this.get("store").query("item", {
        sellable: true,
        type: 92,
        company: this.get("model.internment.organization.company.id")
      }) : [];
    }),

    /*
     * Actions
     */
    actions: {
      setAdjustmentSelectAnswer: function setAdjustmentSelectAnswer(value) {
        if (this.get("model.adjustment") === value) {
          this.set("model.adjustment", null);
          this.set("model.fluidRate", null);
        } else {
          this.set("model.adjustment", value);
          this.set("model.fluidRate", (this.get("model.internment.patient.maintenanceFluidRate") * this.get("model.adjustment")).toFixed(3));
        }
      }
    }
  });

  _exports.default = _default;
});