define("crm/components/shift-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    visitTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return type.get('isVisit') && Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      editShift: function editShift() {
        var self = this;
        this.set("disabledAction", true);
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.get("es").handle(result);
          self.set("disabledAction", false);
        }).finally(function () {
          self.set("disabledAction", false);
        });
      },
      selectVisitType: function selectVisitType(value) {
        this.set("model.category", value);
      }
    }
  });

  _exports.default = _default;
});