define("crm/controllers/organizations/organization/suppliers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableBtn: false,
    isDirty: true,
    newSupplier: {},
    actions: {
      create: function create() {
        var self = this;
        var supplier = this.store.createRecord('supplier', this.get('newSupplier'));
        supplier.save().then(function (result) {
          self.send("created", result.get('id'));
        }).catch(function () {
          self.set('disableBtn', false);
          self.get('store').unloadRecord(supplier);
        });
      },
      cancel: function cancel() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.get('model').rollbackAttributes();
          this.send("canceled");
        }
      }
    }
  });

  _exports.default = _default;
});