define("crm/controllers/organizations/organization/purchase-invoices/purchase-invoice/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /* for used order highlight */
    usedOrders: Ember.computed("model.lines.@each.purchaseOrderLine", function () {
      return Ember.isEmpty(this.get("model.lines")) ? [] : _toConsumableArray(new Set(this.get("model.lines").filter(function (l) {
        return l.purchaseOrderLine && l.purchaseOrderLine.get;
      }).map(function (l) {
        return Ember.get(l, "purchaseOrderLine.purchaseOrder.id");
      })));
    }),

    /* open orders */
    orders: Ember.computed("model.supplier.id", function () {
      return this.get("model.supplier.id") ? this.get("store").query("purchase-order", {
        open: "true",
        supplier: this.get("model.supplier.id"),
        organization: this.get("model.organization.id")
      }, {
        reload: true
      }) : [];
    }),

    /* supplier has orders from other organizations */
    multiOrganization: Ember.computed("orders.[]", function () {
      var _this = this;

      if (!this.get("orders.length")) {
        return null;
      }

      return this.get("orders").filter(function (o) {
        return o.get("organization.id") !== _this.get("model.organization.id");
      }).length;
    }),
    nextPlacement: Ember.computed("model.lines.@each.placement", function () {
      var maxPlacement = 0;
      this.get("model.lines").forEach(function (l) {
        if (maxPlacement < l.get("placement")) {
          maxPlacement = l.get("placement");
        }
      });
      return maxPlacement + 1;
    }),
    usedItemsIDs: Ember.computed("model.lines.[]", function () {
      return this.get("model.lines") ? this.get("model.lines").mapBy("item.id") : null;
    }),
    itemsTotalPrice: Ember.computed("model.lines.@each.linePriceWithVat", function () {
      var finalPrice = 0;

      if (this.get("model.lines.length")) {
        this.get("model.lines").forEach(function (line) {
          if (line.get("linePriceWithVat")) {
            finalPrice += line.get("linePriceWithVat");
          }
        });
        return this.money(finalPrice);
      } else {
        return null;
      }
    }),
    pricesMatch: Ember.computed("itemsTotalPrice", "model.payableAmount", function () {
      var itemsTotalPrice = this.get("itemsTotalPrice") || 0;
      var payableAmount = this.get("model.payableAmount") || 0;
      var todal = payableAmount - itemsTotalPrice;
      return Math.abs(todal) < 0.05;
    }),
    appendlastPurchaseData: function appendlastPurchaseData(line, lastPurchaseInvoiceLine) {
      if (lastPurchaseInvoiceLine) {
        line.set("lastPurchaseInvoiceLine", lastPurchaseInvoiceLine);
        line.set("quantity", line.get("quantity") || lastPurchaseInvoiceLine.get("quantity"));
        line.set("discount", line.get("discount") || lastPurchaseInvoiceLine.get("discount"));
        line.set("vat", lastPurchaseInvoiceLine.get("vat"));
        line.set("unitPrice", lastPurchaseInvoiceLine.get("unitPrice"));
        line.set("discount2", lastPurchaseInvoiceLine.get("discount2"));
        line.set("quantityPromotion", lastPurchaseInvoiceLine.get("quantityPromotion"));
      }
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    save: function save() {
      var _this2 = this;

      var self = this;
      this.set("model.saving", true);
      this.set("model.truePayableAmount", this.get("model.payableAmount"));
      this.get("model").save().then(function (saved) {
        if (saved.get("status") === 1) {
          _this2.transitionToRoute("organizations.organization.purchase-invoices");
        }
      }, function (error) {
        self.get("es").handle(error);
      }).finally(function () {
        _this2.set("model.saving", false);

        _this2.set("locked", false);
      });
    },
    setName2: function setName2() {
      var _this3 = this;

      this.get("store").query("purchase-invoice", {
        company: this.get("model.organization.company.id"),
        supplier: this.get("model.supplier.id"),
        name: this.get("model.name")
      }).then(function (savedHomonymous) {
        if (savedHomonymous && savedHomonymous.get("firstObject.id") && savedHomonymous.get("firstObject.id") !== _this3.get("model.id")) {
          _this3.set("model.savedHomonym", savedHomonymous.get("firstObject"));

          _this3.set("model.saving", false);

          return;
        } else {
          _this3.set("model.savedHomonym", null);

          _this3.set("model.saving", false);

          _this3.save();
        }
      });
    },

    /*
     * Allow save or issue
     */
    cannotIssue: Ember.computed("locked", "linesSaving", "cannotSave", "badAdjustment", "badLines", function () {
      if (this.get("locked")) {
        return this.get("intl").t("purchaseInvoices.edit.saving");
      } else if (this.get("linesSaving")) {
        return this.get("intl").t("purchaseInvoices.edit.savingLines");
      } else if (this.get("cannotSave")) {
        return this.get("cannotSave");
      } else if (this.get("badAdjustment")) {
        return this.get("intl").t("purchaseInvoices.invalid.badAdjustment");
      } else if (this.get("badLines")) {
        return this.get("badLines");
      } else {
        return false;
      }
    }),
    cannotSave: Ember.computed("badHeader", "savingPurchase", "model.supplier.id", function () {
      if (!this.get("model.supplier.id")) {
        return this.get("intl").t("purchaseInvoices.invalid.noSupplier");
      }

      if (this.get("badHeader")) {
        return this.get("badHeader"); // this.get("intl").t("purchaseInvoices.invalid.badHeader");
      } else if (this.get("savingPurchase")) {
        "...";
      } else {
        return false;
      }
    }),
    badHeader: Ember.computed("model.name", "model.payableAmount", "model.date", "model.supplier", "model.checkNumber", "model.checkDate", function () {
      if (!this.get("model.name")) {
        return "Falta o nr de Fatura";
      } else if (!this.get("model.payableAmount")) {
        return "Falta o preço total";
      } else if (!this.get("model.date")) {
        return "Falta a data de emissão";
      } else if (!this.get("model.supplier")) {
        return "Falta o Fornecedor";
      } else if (this.get("model.paymentMethod") === "CHQ" && (!this.get("model.checkNumber") || !this.get("model.checkDate"))) {
        return "Dados do cheque";
      } else {
        return null;
      }
    }),
    badAdjustment: Ember.computed("model.warehouse.id", "model.stockDate", function () {
      return !this.get("model.warehouse.id") || !this.get("model.stockDate");
    }),
    badLines: Ember.computed("model.lines.@each.quantity", "model.lines.@each.item", "model.lines.@each.purchasePrice", "model.lines.@each.discount", "model.lines.@each.vat", "model.lines.@each.stockable", "model.lines.@each.warehouse", function () {
      var _this4 = this;

      var result = null;

      if (!Ember.isEmpty(this.get("model.lines"))) {
        this.get("model.lines").forEach(function (line) {
          if (!line.get("quantity")) {
            result = line.get("name") + ": " + _this4.get("intl").t("purchaseInvoices.invalid.amount");
          } else if (!line.get("item.id")) {
            result = line.get("name") + ": " + _this4.get("intl").t("actions.selectItem");
          } else if (!line.get("purchasePrice") && line.get("discount") !== 100) {
            result = line.get("name") + ": " + _this4.get("intl").t("purchaseInvoices.invalid.cost");
          } else if (!line.get("vat")) {
            result = line.get("name") + ": " + _this4.get("intl").t("purchaseInvoices.invalid.vat");
            /*
                        } else if(line.get("item.stockable") && !line.get("warehouse.id")) {
                           result = line.get("name") + ": " + this.get("intl").t("warehouses.warehouse.title");*/
          }
        });
      }

      return result;
    }),
    linesSaving: Ember.computed("model.lines.@each.saving", function () {
      return this.get("model.lines").any(function (l) {
        return l.get("saving");
      });
    }),
    actions: {
      openModalCreateItem: function openModalCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      },
      setName: function setName(name) {
        this.set("locked", true);
        this.set("model.name", name);

        if (this.get("model.name")) {
          Ember.run.debounce(this, this.setName2, 4000);
        }
      },
      setField: function setField(field, value) {
        this.set("locked", true);

        if ("name" === field) {
          this.send("setName", value);
          return;
        }

        if ("paid" === field) {
          if (value && !this.get("model.paidAmount")) {
            this.set("model.financialAccount", null);
            this.set("model.paidAmount", this.money(this.get("model.payableAmount")));
          } else {
            this.set("model.paymentMethod", null);
            this.set("model.paidAmount", null);
            this.set("model.financialAccount", null);
          }
        }
        /*
                 if("warehouse" === field && this.get("model.lines.length")) {
                    this.get("model.lines").forEach(l => l.set("warehouse", l.get("item.stockable") ? value : null));
                 }
        */


        if (typeof value === "string") {
          value = value.trim();
        }

        this.set("model." + field, value);
        Ember.run.debounce(this, this.save, 4000);
      },
      addPurchaseOrder: function addPurchaseOrder(purchaseOrder) {
        var self = this;
        this.set("model.saving", true);
        this.get("store").query("purchase-order-line", {
          purchaseOrder: purchaseOrder.get("id"),
          open: "true"
        }, {
          reload: true
        }).then(function (lines) {
          if (!Ember.isEmpty(lines) && lines.get("length") > 0) {
            lines.sortBy("lineGroupTree").forEach(function (purchaseOrderLine) {
              self.get("store").findRecord("item", purchaseOrderLine.get("item").get("id"), {
                reload: true
              }).then(function (item) {
                self.send("addLine", item, purchaseOrderLine);
              });
            });
          }
        });
      },
      addLine: function addLine(item, purchaseOrderLine) {
        var _this5 = this;

        if (!item) {
          return;
        }

        this.set("model.saving", true);

        if (this.get("usedItemsIDs").includes(item.get("id"))) {
          this.get("flashMessages").warning(this.get("intl").t("items.item.cantSelectSame"));
          return;
        }

        Ember.RSVP.hash({
          lastPurchaseInvoiceLines: this.get("store").query("purchase-invoice-line", {
            item: item.get("id"),
            top: 1
          }),
          itemUnits: this.get("store").query("item-unit", {
            item: item.get("id"),
            inbound: true
          }),
          supplierItems: this.get("store").query("supplier-item", {
            item: item.get("id"),
            supplier: this.get("model.supplier.id")
          })
        }).then(function (hash) {
          var line = _this5.get("store").createRecord("purchaseInvoiceLine", {
            saving: true,
            name: item.get("name"),
            placement: _this5.get("nextPlacement"),
            item: item,
            purchaseInvoice: _this5.get("model"),
            discount: _this5.get("model.discount"),
            // unitPrice: 0,
            // purchasePrice: 0,
            warehouse: item.get("stockable") ? _this5.get("model.warehouse") : null,
            itemUnit: item.get("purchaseItemUnit"),
            supplierItem: Ember.get(hash, "supplierItems.firstObject"),
            supplierId: Ember.get(hash, "supplierItems.firstObject.supplierId"),
            purchaseOrderLine: purchaseOrderLine,
            quantity: purchaseOrderLine ? purchaseOrderLine.get("quantity") : null
          });

          if (hash.lastPurchaseInvoiceLines) {
            _this5.appendlastPurchaseData(line, hash.lastPurchaseInvoiceLines.get("firstObject"));
          }
          /*
             The line is not saved here because is then saved in the line component init()
          */


          _this5.set("model.saving", false);
        });
      },
      deleteLine: function deleteLine(line) {
        line.destroy();
      },
      issue: function issue() {
        this.set("model.saving", true);
        this.set("model.status", 1);
        Ember.run.debounce(this, this.save, 1000);
      }
    }
  });

  _exports.default = _default;
});