define("crm/components/patient-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldString: null,
    disabled: false,
    reset: function reset() {
      Ember.$('#modal-patient-select').modal('hide');
      this.set("searchText", "");
      this.set("patients", []);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isEmpty(this.get("patientes")) && !Ember.isEmpty(this.get("selectedCustomer"))) {
        this.set("patients", this.get("selectedCustomer.patients"));
      }
      /*
      if(isEmpty(this.get("selectedPatient") && !isEmpty(this.get("selectedCustomer")))) {
          this.send("searchPatients", this.get("selectedCustomer.name"));
      }
      */

    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.name"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }, {
        "component": "show-sex-symbol",
        "title": this.get('intl').t("patients.patient.sex"),
        "filterWithSelect": true,
        "className": "column-sex"
      }, {
        "propertyName": "owner.name",
        "component": "show-owners-by-animal",
        "title": this.get('intl').t("customers.customer.title"),
        "sortedBy": "owner.name",
        "className": "column-big",
        "searchable": true
      }];
    }),
    searching: Ember.computed("searchText", "disabled", function () {
      return this.get("searchText") && this.get("disabled");
    }),
    actions: {
      searchPatients: function searchPatients(query) {
        var self = this;
        this.set("lockButton", true);
        Ember.RSVP.hash({
          customers: this.get("store").query("customer", {
            patient: true,
            company: self.get("organization.company.id"),
            query: query
          }),
          patients: this.get("store").query("patient", {
            customer: true,
            company: self.get("organization.company.id"),
            query: query
          })
        }).then(function (hashResult) {
          self.set("patients", hashResult.patients);
          self.set("lockButton", false);
        });
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable.get('selectedItems.length')) {
          this.set("selectedPatient", dataTable.get('selectedItems')[0]);
          this.set("selectedCustomer", dataTable.get('selectedItems')[0].get("owner"));

          if (this.get("selectedCustomer.id")) {
            this.get("store").findRecord("customer", this.get("selectedCustomer.id"));
          }

          this.set("oldString", null);

          if (this.get("onChange")) {
            this.get("onChange")(dataTable.get("selectedItems")[0]);
          }

          this.reset();
        }
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});