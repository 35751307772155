define("crm/routes/organizations/organization/charges/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.store.query("charge", {
        organization: this.modelFor("organizations.organization").get("id")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('columns', [{
        "propertyName": "status",
        "component": "show-document-status",
        "className": "column-icon",
        "title": ""
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date hidden-xs",
        "title": ""
      }, {
        "propertyName": "code",
        "className": "column-id text-right",
        "title": ""
      }, {
        "propertyName": "customerName",
        "component": "title-models-table-display",
        "className": "column-name text-strong",
        "title": "",
        "path": "organizations.organization.customers.customer",
        "routeProperty": "customer.id"
      }, {
        "propertyName": "name",
        "component": "title-models-table-display",
        "className": "column-name",
        "title": ""
      }, {
        "propertyName": "amount",
        "component": "umt-price-display",
        "className": "column-price",
        "title": ""
      }]);
    }
  });

  _exports.default = _default;
});