define("crm/routes/organizations/organization/group/purchase-orders/new", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/shortage?organization=" + organization.get("id"),
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      }).then(function (result) {
        return {
          organization: organization,
          lines: [],
          shortageItems: JSON.parse(result.data)
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var suppliers = model.organization.get("company.suppliers");
      controller.set("suppliers", suppliers);

      if (!Ember.isEmpty(suppliers) && suppliers.get("length") == 1) {
        Ember.set(model, "supplier", suppliers.objectAt(0));
      }

      this.store.query("warehouse", {
        organization: model.organization.get("id")
      }).then(function (warehouses) {
        controller.set("warehouses", warehouses);

        if (!Ember.isEmpty(warehouses) && warehouses.get("length") == 1) {
          Ember.set(model, "warehouse", warehouses.objectAt(0));
        }
      });
    }
  });

  _exports.default = _default;
});