define("crm/models/field-model", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    fieldType: _emberData.default.attr('number'),
    patients: _emberData.default.hasMany('patient'),
    customers: _emberData.default.hasMany('customer')
  });

  _exports.default = _default;
});