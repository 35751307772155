define("crm/controllers/organizations/organization/purchases/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    itemsList: [],
    showLoading: false,
    purchaseInvoice: {},
    reset: function reset() {
      this.set("selectedItem", null);
      this.set("disabledSubmission", false);
      this.set("itemsList", []);
      this.set("purchaseInvoice", {});
    },
    actions: {
      selectedItem: function selectedItem(_selectedItem) {
        var self = this;
        this.set("showLoading", true);
        this.get("store").query("warehouse-item", {
          item: _selectedItem.get("id"),
          organization: this.get("organization")
        }).then(function (warehouseItems) {
          var warehouseItemsCopy = warehouseItems.map(function (warehouseItem) {
            return {
              quantity: warehouseItem.get("quantity"),
              item: warehouseItem.get("item"),
              warehouse: warehouseItem.get("warehouse")
            };
          });

          _selectedItem.set("newWarehouseItems", warehouseItemsCopy);

          self.get("itemsList").push(_selectedItem);
          self.set("showLoading", false);
        });
      },
      deleteWarehouseItem: function deleteWarehouseItem(warehouseItem) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("warehouseItems").removeObject(warehouseItem);
        }
      },
      saveStock: function saveStock() {
        this.set("disabledSubmission", true);
        var self = this;
        this.store.createRecord("purchase-invoice", this.get("purchaseInvoice")).save().then(function () {
          self.get("itemsList").forEach(function (item) {
            item.get("newWarehouseItems").forEach(function (warehouseItem) {
              var name = "i" + warehouseItem.item.get("id") + "-" + (0, _moment.default)().format();

              if (warehouseItem.quantity2 > 0) {
                var purchase = self.get("store").createRecord("purchase", {
                  price: warehouseItem.price,
                  quantity: Number(warehouseItem.quantity) + Number(warehouseItem.quantity2),
                  lot: warehouseItem.lot,
                  discount: warehouseItem.discount,
                  expireDate: warehouseItem.expireDate,
                  item: warehouseItem.item,
                  warehouse: warehouseItem.warehouse,
                  name: name
                });
                purchase.save().then(function () {
                  self.reset();
                  self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfully"));
                }).catch(function () {
                  this.set("disabledSubmission", false);
                });
              }
            });
          });
          self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfully"));
        }).catch(function () {
          this.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});