define("crm/components/report-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pdf: Ember.inject.service(),
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.set("fields", this.get("store").query("report-field", {
          report: this.get("model.id")
        }));
      }

      if (this.get("model.exam.id")) {
        this.get("store").query("file-exam", {
          exam: this.get("model.exam.id")
        });
      }
    },
    files: Ember.computed("model.exam.id", function () {
      return this.get("model.exam.id") ? this.get("store").query("file-exam", {
        exam: this.get("model.exam.id")
      }).sortBy("created").reverse() : [];
    }),
    customFormFields: Ember.computed("fields.[]", "fields.@each.type", "fields.@each.name", "fields.@each.templateSectionField", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("fields"))) {
        this.get("fields").forEach(function (field) {
          result.addObject({
            value: field.get("notes"),
            type: {
              name: field.get("name"),
              options: field.get("templateSectionField.optionsArray") ? field.get("templateSectionField.optionsArray") : field.get("templateSectionField.options"),
              type: field.get("templateSectionField.type"),
              orderNumber: field.get("templateSectionField.orderNumber")
            },
            templateSectionField: field,
            field: field
          });
        });
      }

      return result.sort(function (a, b) {
        return a.type.orderNumber - b.type.orderNumber;
      });
    }),
    reset: function reset() {
      this.close();
    },
    close: function close() {
      Ember.$("#modal-report-edit").modal("hide");
    },
    actions: {
      reset: function reset() {//this.close();
      },
      save: function save() {
        this.set("disabledSubmission", true);
        this.get("model").save();

        if (!Ember.isEmpty(this.get("customFormFields"))) {
          var self = this;
          this.get("customFormFields").forEach(function (customFormField) {
            var field = Ember.get(customFormField, "field");
            Ember.set(field, "notes", Ember.get(customFormField, "value"));
            field.save().then(function () {
              return self.close();
            }).catch(function (e) {
              return self.get("es").handle(e);
            }).finally(function () {
              return self.set("disabledSubmission", false);
            });
          });
        }
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});