define("crm/routes/organizations/organization/group/sales-table-days", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    model: function model() {
      return {};
    },
    setupController: function setupController(controller) {
      var self = this;
      this.set("breadCrumb", {
        finalTitle: self.get("intl").t("sales.title")
      });
      var organization = this.modelFor("organizations.organization");
      var companyID = organization.get("company.id");
      var startOfYear = (0, _moment.default)().startOf("year").format("YYYY-MM-DD");
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/hour?company=" + companyID + "&createdAfter=" + startOfYear,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("invoices", result);
        }
      });
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/memos/hour?company=" + companyID + "&createdAfter=" + startOfYear,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("memos", result);
        }
      });
    }
  });

  _exports.default = _default;
});