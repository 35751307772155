define("crm/helpers/is-current-hour", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBeforeToday = isBeforeToday;
  _exports.default = void 0;

  function isBeforeToday() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      if (!params || !params[0]) {
        return false;
      }

      return (0, _moment.default)().isSame(params[0], 'h');
    }
  });

  _exports.default = _default;
});