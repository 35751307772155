define("crm/components/enterprise-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    newEnterprise: {},
    disabledActionEnterpriseNew: false,
    close: function close() {
      Ember.$("#modal-enterprise-create").modal("hide");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        this.set("disabledActionEnterpriseNew", true);
        var self = this;

        if (this.get("model")) {
          this.set("newEnterprise.organization", this.get("model"));
        }

        this.get("store").createRecord("enterprise", this.get("newEnterprise")).save().then(function () {
          self.close();
          self.get("flashMessages").success(self.get("intl").t("enterprises.messages.title"));
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set("disabledActionEnterpriseNew", false);
        });
      },
      reset: function reset() {
        this.set("newEnterprise", {});
        this.set("disabledActionEnterpriseNew", false);
      }
    }
  });

  _exports.default = _default;
});