define("crm/models/compound-product", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    barcode: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    longDescription: _emberData.default.attr("string"),
    originalRetailPrice: _emberData.default.attr(),
    retailPrice1: _emberData.default.attr(),
    retailPrice2: _emberData.default.attr(),
    retailPrice3: _emberData.default.attr(),
    type: _emberData.default.belongsTo("item-type"),
    baseUnit: _emberData.default.belongsTo("unit"),
    organization: _emberData.default.belongsTo("organization"),
    compoundProductItems: _emberData.default.hasMany("compound-product-item", {
      inverse: "compoundProduct"
    })
  });

  _exports.default = _default;
});