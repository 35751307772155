define("crm/controllers/organizations/organization/consultations/consultation/index", ["exports", "moment", "crm/config/environment", "ember-inflector"], function (_exports, _moment, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    pdf: Ember.inject.service(),
    intl: Ember.inject.service(),
    documents: Ember.inject.service(),
    terms: Ember.inject.service(),
    session: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    symptomAddLocked: false,
    diagnosticAddLocked: false,
    treatmentPlanAddLocked: false,
    viewStatus: true,
    today: (0, _moment.default)().format("YYYY-MM-DD"),
    isNotEnglish: Ember.computed(function () {
      return this.get("intl.locale").get("firstObject") !== "en";
    }),
    canDoCalendar: Ember.computed(function () {
      return this.get("can").can("do clinical");
    }),
    dateUpdatable: Ember.computed(function () {
      return (0, _moment.default)(this.get("model.date")).isSame((0, _moment.default)(this.get("appointment.start")), "h");
    }),
    filteredTemplates: Ember.computed(function () {
      if (this.get("model.templates") && this.get("templates")) {
        var used = this.get("model.templates").map(function (mT) {
          return mT.get("id");
        });
        return this.get("templates").filter(function (t) {
          return !used.includes(t.get("id"));
        });
      }

      return this.get("templates");
    }),
    isShoppingCartOpen: Ember.computed(function () {
      return this.get("model.shoppingCart.status") !== 1;
    }),
    disableAction3Button: Ember.computed(function () {
      return !this.get("model.organization.salesFeature");
    }),
    hvmeDocuments: Ember.computed(function () {
      return this.get("model.organization.id") == 185;
    }),
    hvbjDocuments: Ember.computed(function () {
      return this.get("model.organization.id") == 70;
    }),
    madeiraDocuments: Ember.computed(function () {
      return this.get("model.organization.id") == 119;
    }),
    filteredConsultations: Ember.computed(function () {
      if (this.get("selectedSymptom")) {
        var self = this;
        return this.get("model.patient.consultations").filter(function (consultation) {
          var flag = false;
          consultation.get("symptoms").forEach(function (symptom) {
            if (symptom === self.get("selectedSymptom")) {
              flag = true;
            }
          });
          return flag;
        }).sortBy("date").reverse();
      } else {
        return this.get("model.patient.consultations") ? this.get("model.patient.consultations").sortBy("date").reverse() : [];
      }
    }),
    filteredSymptoms: Ember.computed(function () {
      var result = [];
      this.get("model.patient.consultations").forEach(function (consultation) {
        if (!Ember.isEmpty(consultation.get("symptoms"))) {
          consultation.get("symptoms").forEach(function (symptom) {
            result.addObject(symptom);
          });
        }
      });
      return result;
    }),
    quotationQueryParams: Ember.computed(function () {
      return {
        isQueryParams: true,
        values: {
          customerId: this.get("model.owner.id"),
          patientId: this.get("model.patient.id"),
          tabQuotations: true
        }
      };
    }),
    saveAppointment: function saveAppointment() {
      var self = this;
      this.store.peekRecord("appointment", this.get("model.appointment.id")).save().then(function () {
        return self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationSaved"));
      }).catch(function (result) {
        return self.get("es").errorReturned(result);
      });
    },
    saveConsultation: function saveConsultation() {
      var self = this;
      this.get("model").save().then(function () {
        return self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationSaved"));
      }).catch(function (result) {
        return self.get("es").errorReturned(result);
      });
    },
    examion: function examion(request) {
      var _this = this;

      this.store.query("address", {
        customer: this.get("model.patient.owner.id")
      }).then(function () {
        var content = request + // Tipo de pedido:6302 para fazer rx, 6311 para ver rx, 6310 para o feedback do exame
        // "\n0008402XRAY01" +	    //	Dispositivo, XRAY01, SONO02, DICO03 ...
        "\n0003000" + _this.get("model.patient.code") + "\n0003100" + (_this.get("model.patient.species.name") ? _this.get("model.patient.species.name") : "") + "\n0003101" + _this.get("model.patient.name") + "\n0003102" + (_this.get("model.patient.breed.name") ? _this.get("model.patient.breed.name") : "") + "\n0003103" + (_this.get("model.patient.birthdate") ? (0, _moment.default)(_this.get("model.patient.birthdate")).format("DDMMYYYY") : "") + "\n0003104" + (_this.get("model.patient.furColor") ? _this.get("model.patient.furColor") : "") + "\n0003110" + (_this.get("model.patient.sex") ? _this.get("model.patient.sex") : "") + "\n0003120" + // Nome oficial
        "\n0003121" + (_this.get("model.patient.sterilized") ? 1 : 0) + "\n0003122" + "\n0003123" + "\n0003124" + "\n0003125" + (_this.get("model.patient.chip") ? _this.get("model.patient.chip") : "") + "\n0003126" + "\n0003201" + _this.get("model.patient.owner.name").split(' ').slice(-1).join(' ') + "\n0003202" + _this.get("model.patient.owner.name").split(' ').slice(0, -1).join(' ') + "\n0003205" + (_this.get("model.patient.owner.address.zipCode") ? _this.get("model.patient.owner.address.zipCode") : "") + "\n0003206" + (_this.get("model.patient.owner.address.town") ? _this.get("model.patient.owner.address.town") : "") + "\n0003207" + (_this.get("model.patient.owner.address.street") ? _this.get("model.patient.owner.address.street") : "") + "\n0003626" + (_this.get("model.patient.owner.phoneContact.contact") ? _this.get("model.patient.owner.phoneContact.contact") : "") + "\n0003619" + (_this.get("model.patient.owner.emailContact.contact") ? _this.get("model.patient.owner.emailContact.contact") : "");
        var a = document.createElement('a');
        a.setAttribute('href', 'data:application/json;charset=ISO-8859-1,' + escape(content));
        a.setAttribute('download', 'mgpcs.gdt');
        a.click();
      });
    },

    /***************************************************************************
     * Actions
     **************************************************************************/
    actions: {
      delete: function _delete() {
        var self = this;
        var sc = this.get("model.shoppingCart.id");
        this.get("model").destroyRecord().then(function () {
          if (sc) {
            self.store.peekRecord("shopping-cart", sc).unloadRecord();
          }

          self.transitionToRoute("organizations.organization");
        }).catch(function (error) {
          return self.get("es").handle(error);
        });
      },
      makePDF: function makePDF(model) {
        var self = this;
        var modelName = model.constructor.modelName;
        var id = model.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(savedDocument.get("date")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale"));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = model.get("patient.code") + "_" + model.get("patient.name") + "_" + model.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(model.get("date")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      },
      deletereceptionNotes: function deletereceptionNotes() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          this.get("store").findRecord("appointment", this.get("model.appointment.id"), {
            reload: true
          }).then(function (appointment) {
            appointment.set("receptionNotes", null);
            appointment.save();
          }).catch(function (error) {
            self.get('flashMessages').danger(error);
          });
        }
      },
      newExamion: function newExamion() {
        this.examion("00080006302");
      },
      viewExamion: function viewExamion() {
        this.examion("00080006311");
      },
      setFormat: function setFormat(format) {
        if (this.get("currentUser")) {
          if (this.get("currentUser.preferences")) {
            if (this.get("currentUser.preferences.clinical")) {
              if (this.get("currentUser.preferences.clinical.consultation")) {
                this.set("currentUser.preferences.clinical.consultation.format", format);
              } else {
                this.set("currentUser.preferences.clinical.consultation", {
                  format: format
                });
              }
            } else {
              this.set("currentUser.preferences.clinical", {
                consultation: {
                  format: format
                }
              });
            }
          } else {
            this.set("currentUser.preferences", {
              clinical: {
                consultation: {
                  format: format
                }
              }
            });
          }
        }

        this.get("currentUser.content").save();
      },
      imageUploaded: function imageUploaded(savedFile) {
        var fileEmberObj = this.get("store").createRecord("fileConsultation", savedFile);
        this.get("model.files").pushObject(fileEmberObj);
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        Ember.set(periodic, "consultation", this.get("model"));
        this.get("store").createRecord("periodic", periodic).save();
      },
      drugDelete: function drugDelete(drug) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          drug.destroyRecord();
        }
      },
      examDelete: function examDelete(exam) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          exam.destroyRecord();
        }
      },
      updatePhysicalExamNotes: function updatePhysicalExamNotes(notes) {
        this.set("model.physicalExam.notes", notes);
        this.get("model.physicalExam.content").save();
      },
      physicalExamCreated: function physicalExamCreated() {
        this.get("model").save();
      },

      /*
       * SOAP
       */
      symptomAdd: function symptomAdd(symptomName) {
        if (!symptomName) {
          symptomName = Ember.$("#symptomSelect").val();
        }

        if (Ember.isEmpty(symptomName) || this.get("symptomAddLocked")) {
          return;
        }

        this.set("symptomAddLocked", true);
        var consultation = this.get("model");
        var self = this;

        if (this.get("model.organization.symptoms")) {
          var symptom = this.get("model.organization.symptoms").filter(function (e) {
            return e.get("name") && e.get("name").toLowerCase() === symptomName.toLowerCase();
          }).objectAt(0);

          if (symptom) {
            var symptomConsultation = this.store.createRecord("symptom-consultation", {
              symptom: symptom,
              consultation: consultation
            });
            Ember.$.ajax({
              type: "POST",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/symptomConsultations",
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              accept: "application/json",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(symptomConsultation),
              success: function success() {
                self.get("model.symptoms").addObject(symptom);
                self.store.query("symptom", {
                  consultation: consultation.get("id")
                });
                Ember.$("#symptomSelect").val("");
                self.set("symptomAddLocked", false);
              },
              error: function error(e) {
                self.get("es").handle(e);
                self.set("symptomAddLocked", false);
              }
            });
            return;
          }
        }

        var symptomCreated = this.store.createRecord("symptom", {
          organization: this.get("model.organization"),
          name: symptomName
        });
        symptomCreated.save().then(function (savedSymptom) {
          var symptomConsultation = self.store.createRecord("symptom-consultation", {
            symptom: savedSymptom,
            consultation: consultation
          });
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/symptomConsultations",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(symptomConsultation),
            success: function success() {
              self.get("model.symptoms").addObject(symptomCreated);
              self.store.query("symptom", {
                consultation: consultation.get("id")
              });
              Ember.$("#symptomSelect").val("");
              self.set("symptomAddLocked", false);
            },
            error: function error(e) {
              self.get("es").handle(e);
              self.set("symptomAddLocked", false);
            }
          });
        }).catch(function (e) {
          self.get("es").handle(e);
          self.set("symptomAddLocked", false);
        });
      },
      symptomDelete: function symptomDelete(symptom) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          if (symptom.get("id") && this.get("model.id")) {
            var self = this;
            Ember.$.ajax({
              type: "DELETE",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/symptomConsultations/" + symptom.get("id") + "-" + self.get("model.id"),
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              success: function success() {
                self.get("model.symptoms").removeObject(symptom);
                symptom.get("consultations").removeObject(self.get("model"));
                self.store.unloadRecord(symptom);
                self.store.findRecord("symptom", symptom.get("id"), {
                  reload: true
                });
                self.store.findRecord("consultation", self.get("model.id"), {
                  reload: true
                });
              }
            });
          }
        }
      },
      diagnosticAdd: function diagnosticAdd(diagnosticName) {
        if (!diagnosticName) {
          diagnosticName = Ember.$("#diagnosticSelect").val();
        }

        if (Ember.isEmpty(diagnosticName) || this.get("diagnosticAddLocked")) {
          return;
        }

        this.set("diagnosticAddLocked", true);
        var consultation = this.get("model");
        var self = this;

        if (this.get("model.organization.diagnostics")) {
          var diagnostic = this.get("model.organization.diagnostics").filter(function (e) {
            return e.get("name") && e.get("name").toLowerCase() === diagnosticName.toLowerCase();
          }).objectAt(0);

          if (diagnostic) {
            var diagnosticConsultation = this.store.createRecord("diagnostic-consultation", {
              diagnostic: diagnostic,
              consultation: consultation
            });
            Ember.$.ajax({
              type: "POST",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/diagnosticConsultations",
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              accept: "application/json",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(diagnosticConsultation),
              success: function success() {
                self.get("model.diagnostics").addObject(diagnostic);
                self.store.query("diagnostic", {
                  consultation: consultation.get("id")
                });
                Ember.$("#diagnosticSelect").val("");
                self.set("diagnosticAddLocked", false);
              },
              error: function error(e) {
                self.get("es").handle(e);
                self.set("diagnosticAddLocked", false);
              }
            });
            return;
          }
        }

        var diagnosticCreated = this.store.createRecord("diagnostic", {
          organization: this.get("model.organization"),
          name: diagnosticName
        });
        diagnosticCreated.save().then(function (savedDiagnostic) {
          var diagnosticConsultation = self.store.createRecord("diagnostic-consultation", {
            diagnostic: savedDiagnostic,
            consultation: consultation
          });
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/diagnosticConsultations",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(diagnosticConsultation),
            success: function success() {
              self.get("model.diagnostics").addObject(diagnosticCreated);
              self.store.query("diagnostic", {
                consultation: consultation.get("id")
              });
              Ember.$("#diagnosticSelect").val("");
              self.set("diagnosticAddLocked", false);
            },
            error: function error(e) {
              self.get("es").handle(e);
              self.set("diagnosticAddLocked", false);
            }
          });
        }).catch(function (e) {
          self.get("es").handle(e);
          self.set("diagnosticAddLocked", false);
        });
      },
      diagnosticDelete: function diagnosticDelete(diagnostic) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          if (diagnostic.get("id") && this.get("model.id")) {
            var self = this;
            Ember.$.ajax({
              type: "DELETE",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/diagnosticConsultations/" + diagnostic.get("id") + "-" + self.get("model.id"),
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              success: function success() {
                self.get("model.diagnostics").removeObject(diagnostic);
                diagnostic.get("consultations").removeObject(self.get("model"));
                self.store.unloadRecord(diagnostic);
                self.store.findRecord("diagnostic", diagnostic.get("id"), {
                  reload: true
                });
                self.store.findRecord("consultation", self.get("model.id"), {
                  reload: true
                });
              }
            });
          }
        }
      },
      treatmentPlanAdd: function treatmentPlanAdd(treatmentPlanName) {
        if (!treatmentPlanName) {
          treatmentPlanName = Ember.$("#treatmentPlanSelect").val();
        }

        if (Ember.isEmpty(treatmentPlanName) || this.get("treatmentPlanAddLocked")) {
          return;
        }

        this.set("treatmentPlanAddLocked", true);
        var consultation = this.get("model");
        var self = this;

        if (this.get("model.organization.treatmentPlans")) {
          var treatmentPlan = this.get("model.organization.treatmentPlans").filter(function (e) {
            return e.get("name") && e.get("name").toLowerCase() === treatmentPlanName.toLowerCase();
          }).objectAt(0);

          if (treatmentPlan) {
            var treatmentPlanConsultation = this.store.createRecord("treatmentPlan-consultation", {
              treatmentPlan: treatmentPlan,
              consultation: consultation
            });
            Ember.$.ajax({
              type: "POST",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/treatmentPlanConsultations",
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              accept: "application/json",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(treatmentPlanConsultation),
              success: function success() {
                self.get("model.treatmentPlans").addObject(treatmentPlan);
                self.store.query("treatmentPlan", {
                  consultation: consultation.get("id")
                });
                Ember.$("#treatmentPlanSelect").val("");
                self.set("treatmentPlanAddLocked", false);
              },
              error: function error(e) {
                self.get("es").handle(e);
                self.set("treatmentPlanAddLocked", false);
              }
            });
            return;
          }
        }

        var treatmentPlanCreated = this.store.createRecord("treatmentPlan", {
          organization: this.get("model.organization"),
          name: treatmentPlanName
        });
        treatmentPlanCreated.save().then(function (savedTreatmentPlan) {
          var treatmentPlanConsultation = self.store.createRecord("treatmentPlan-consultation", {
            treatmentPlan: savedTreatmentPlan,
            consultation: consultation
          });
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/treatmentPlanConsultations",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(treatmentPlanConsultation),
            success: function success() {
              self.get("model.treatmentPlans").addObject(treatmentPlanCreated);
              self.store.query("treatmentPlan", {
                consultation: consultation.get("id")
              });
              Ember.$("#treatmentPlanSelect").val("");
              self.set("treatmentPlanAddLocked", false);
            },
            error: function error(e) {
              self.get("es").handle(e);
              self.set("treatmentPlanAddLocked", false);
            }
          });
        }).catch(function (e) {
          self.get("es").handle(e);
          self.set("treatmentPlanAddLocked", false);
        });
      },
      treatmentPlanDelete: function treatmentPlanDelete(treatmentPlan) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          if (treatmentPlan.get("id") && this.get("model.id")) {
            var self = this;
            Ember.$.ajax({
              type: "DELETE",
              url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/treatmentPlanConsultations/" + treatmentPlan.get("id") + "-" + self.get("model.id"),
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              },
              success: function success() {
                self.get("model.treatmentPlans").removeObject(treatmentPlan);
                treatmentPlan.get("consultations").removeObject(self.get("model"));
                self.store.unloadRecord(treatmentPlan);
                self.store.findRecord("treatmentPlan", treatmentPlan.get("id"), {
                  reload: true
                });
                self.store.findRecord("consultation", self.get("model.id"), {
                  reload: true
                });
              }
            });
          }
        }
      },

      /*
       * CRUD
       */
      save: function save() {
        var self = this;
        this.get("model").save().then(function () {
          return self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationSaved"));
        }).catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      finishWithoutPay: function finishWithoutPay() {
        var self = this;
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));
        appointment.set("status", 6);
        appointment.set("endConsultation", (0, _moment.default)().format());
        this.get("model").save();
        appointment.save().then(function () {
          if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
            self.get("store").peekRecord("shopping-cart", appointment.get("shoppingCart.id")).unloadRecord();
          }

          self.transitionToRoute("organizations.organization.waitingRooms");
          Ember.$("#openFinishedConsultationModal").modal("hide");
          self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationFinished"));
        }).catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      end: function end() {
        var self = this;
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));
        appointment.set("status", 5);
        appointment.set("endConsultation", (0, _moment.default)().format());
        appointment.set("open", false);
        this.get("model").save();
        appointment.save().then(function () {
          self.transitionToRoute("organizations.organization.waitingRooms");
          Ember.$("#openFinishedConsultationModal").modal("hide");
          self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationFinished"));
        }).catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      checkout: function checkout() {
        var self = this;
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));
        appointment.set("status", 5);
        appointment.set("endConsultation", (0, _moment.default)().format());
        appointment.set("open", false);
        this.get("model").save();
        appointment.save().then(function () {
          Ember.$("#openFinishedConsultationModal").modal("hide");
          self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationFinished"));
        }).catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      finishAndPay: function finishAndPay() {
        var self = this;
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));
        this.set("model.open", false);

        if (self.get("model.organization.salesFeature")) {
          appointment.set("status", 5);
        } else {
          if (this.get("model.shoppingCart.id") != null) {
            this.get("store").findRecord("shopping-cart", this.get("model.shoppingCart.id")).then(function (shoppingCart) {
              shoppingCart.set("status", 1);
              shoppingCart.save();
            });
          }

          appointment.set("status", 6);
        }

        appointment.set("endConsultation", (0, _moment.default)().format());
        this.set("notes", null);
        this.get("model").save();
        appointment.save().then(function () {
          if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
            self.get("store").peekRecord("shopping-cart", appointment.get("shoppingCart.id")).unloadRecord();
          }

          Ember.$("#openFinishedConsultationModal").modal("hide");

          if (self.get("model.organization.salesFeature")) {
            var queryParams = {
              customerId: self.get("model.owner.id"),
              shoppingCartId: self.get("model.shoppingCart.id"),
              appointmentId: self.get("model.appointment.id"),
              patientId: self.get("model.patient.id")
            };
            self.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
          } else {
            self.send("transitionToRoute", "organizations.organization.waitingRooms");
          }
        });
      },

      /*
       * Modals
       */
      callModal: function callModal(conteiner) {
        this.send("openModal", conteiner);
      },
      callConsultationDetails: function callConsultationDetails(consultation) {
        this.send("openModal", {
          entity: "consultation",
          action: "details",
          model: consultation
        });
      },
      callConsultationEnd: function callConsultationEnd() {
        this.get("model").save();
        Ember.$("#openFinishedConsultationModal").modal();
      },
      openInternmentCreate: function openInternmentCreate() {
        this.send("openModal", {
          entity: "internment",
          action: "create",
          model: {
            patient: this.get("model.patient"),
            consultation: this.get("model")
          }
        });
      },
      callDischargeLetter: function callDischargeLetter() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.dischargeLetter"),
            template: "dischargeLetter",
            patient: this.get("model.patient"),
            consultation: this.get("model")
          }
        });
      },
      callDrugDetails: function callDrugDetails(drug) {
        this.send("openModal", {
          entity: "drug",
          action: "details",
          model: drug
        });
      },
      callDrugNew: function callDrugNew() {
        this.send("openModal", {
          entity: "drug",
          action: "create",
          model: {
            consultation: this.get("model"),
            shoppingCart: this.get("model.shoppingCart")
          }
        });
      },
      callExamNew: function callExamNew() {
        this.send("openModal", {
          entity: "exam",
          action: "create",
          model: this.get("model")
        });
      },
      callExamDetails: function callExamDetails(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "details",
          model: exam
        });
      },
      callExamEdit: function callExamEdit(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "edit",
          model: exam
        });
      },
      callPeriodicNew: function callPeriodicNew() {
        this.send("openModal", {
          entity: "periodics",
          action: "create",
          model: {
            consultation: this.get("model"),
            patient: this.get("model.patient"),
            shoppingCart: this.get("model.shoppingCart")
          }
        });
      },
      callPrescription: function callPrescription() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "prescription",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callResponsabilityDeclaration: function callResponsabilityDeclaration() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "responsabilityDeclaration",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callResponsabilityTerm: function callResponsabilityTerm() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.responsabilityTerm"),
            template: "responsabilityTerm",
            patient: this.get("model.patient"),
            company: this.get("model.organization.company")
          }
        });
      },
      callResponsabilityTermInternment: function callResponsabilityTermInternment() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "responsabilityTermInternment",
            patient: this.get("model.patient")
          }
        });
      },
      callEuthanasia: function callEuthanasia() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "euthanasia",
            patient: this.get("model.patient")
          }
        });
      },
      callGeneralAnesthesia: function callGeneralAnesthesia() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.generalAnesthesia"),
            template: "generalAnesthesia",
            patient: this.get("model.patient")
          }
        });
      },
      callConditionedDischarge: function callConditionedDischarge() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "conditionedDischarge",
            patient: this.get("model.patient")
          }
        });
      },
      callSanityCertificate: function callSanityCertificate() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "sanityCertificate",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callBathCutRequest: function callBathCutRequest() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.bathCutRequest"),
            template: "bathCutRequest",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callBathCutReport: function callBathCutReport() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.bathCutReport"),
            template: "bathCutReport",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callEcoReport: function callEcoReport() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.ecoReport"),
            template: "ecoReport",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callCheckUpSeniorDog: function callCheckUpSeniorDog() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.checkUpSeniorDog"),
            template: "checkUpSeniorDog",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callCheckUpSeniorCat: function callCheckUpSeniorCat() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.checkUpSeniorCat"),
            template: "checkUpSeniorCat",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callElectrocardiogramDog: function callElectrocardiogramDog() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.electrocardiogramDog"),
            name: "electrocardiogramDog",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callElectrocardiogramCat: function callElectrocardiogramCat() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.electrocardiogramCat"),
            name: "electrocardiogramCat",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callSurgeryReport: function callSurgeryReport() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.surgeryReport"),
            template: "surgeryReport",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callSurgeryResponsabilityTermBraga: function callSurgeryResponsabilityTermBraga() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "surgeryResponsabilityTermBraga",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callEuthanasiaResponsabilityTermMadeira: function callEuthanasiaResponsabilityTermMadeira() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "euthanasiaResponsabilityTermMadeira",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callHospitalizationConsentBraga: function callHospitalizationConsentBraga() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "hospitalizationConsentBraga",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callLabAnalysisReport: function callLabAnalysisReport() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.labAnalysisReport"),
            name: "labAnalysisReport",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callLabAnalysisExamReport: function callLabAnalysisExamReport(exam) {
        var self = this;
        var modelName = exam.constructor.modelName;
        var id = exam.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("name") + "_" + (0, _moment.default)(savedDocument.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale"));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = exam.get("patient.code") + "_" + exam.get("patient.name") + "_" + exam.get("name") + "_" + (0, _moment.default)(exam.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      },
      callSurgeryDischarge: function callSurgeryDischarge() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.surgeryDischarge"),
            template: "surgeryDischarge",
            consultation: this.get("model")
          }
        });
      },
      callInternmentDischarge: function callInternmentDischarge() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.internmentDischarge"),
            template: "internmentDischarge",
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      callShoppingCartEdit: function callShoppingCartEdit() {
        var _this2 = this;

        if (this.get("model.shoppingCart.id")) {
          this.send("openModal", {
            entity: "shopping-cart",
            action: "edit",
            model: {
              shoppingCart: this.get("model.shoppingCart"),
              consultation: this.get("model"),
              hidePayButton: true
            }
          });
        } else {
          this.store.query("shopping-cart", {
            consultation: this.get("model.id")
          }).then(function (carts) {
            if (carts.get("length") && carts.get("length") === 1) {
              _this2.send("openModal", {
                entity: "shopping-cart",
                action: "edit",
                model: {
                  shoppingCart: carts.get("firstObject"),
                  consultation: _this2.get("model"),
                  hidePayButton: true
                }
              });
            } else {
              _this2.send("openModal", {
                entity: "shopping-cart",
                action: "edit",
                model: {
                  consultation: _this2.get("model"),
                  hidePayButton: true
                }
              });
            }
          });
        }
      },
      callWeightNew: function callWeightNew() {
        this.send("openModal", {
          entity: "weight",
          action: "new",
          model: this.get("model.patient")
        });
      },
      setTemplate: function setTemplate(template) {
        this.send("openModal", {
          entity: "report",
          action: "new",
          model: {
            template: template,
            consultation: this.get("model")
          }
        });
      },
      openPdf2Detals: function openPdf2Detals(template) {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            fields: this.get("model.consultationTemplateSectionFields").filterBy("templateSection.template.id", template.get("id")),
            template: template.get("report") ? template.get("report") : "defaultReport",
            nameToCall: template,
            consultation: this.get("model")
          }
        });
      },
      openReportViewer: function openReportViewer(report) {
        this.send("openModal", {
          entity: "report",
          action: "viewer",
          model: {
            template: Ember.get(report, "template.report") || "defaultReport",
            nameToCall: report,
            consultation: this.get("model")
          }
        });
      },
      openReportEdit: function openReportEdit(report) {
        this.send("openModal", {
          entity: "report",
          action: "edit",
          model: report
        });
      },
      selectView: function selectView(status) {
        this.set("viewStatus", status);
      },
      workerSelect: function workerSelect(worker) {
        var self = this;

        if (worker) {
          this.set("model.worker1", worker);
        } else {
          this.set("model.worker1", null);
        }

        this.get("model").save().then(function () {
          return self.get("flashMessages").success(self.get("intl").t("consultations.messages.consultationSaved"));
        }).catch(function (result) {
          return self.get("es").errorReturned(result);
        });
      },
      appointmentTypeSelect: function appointmentTypeSelect(itemType) {
        this.set("editCategory", false);
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));

        if (itemType) {
          appointment.set("category", itemType);
        } else {
          appointment.set("category", null);
        }

        Ember.run.debounce(this, this.saveAppointment, 4000);
      },
      roomSelect: function roomSelect(room) {
        var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));

        if (room) {
          appointment.set("room", room);
        } else {
          appointment.set("room", null);
        }

        Ember.run.debounce(this, this.saveAppointment, 4000);
      },
      selectSymptom: function selectSymptom(model) {
        if (model) {
          this.set("selectedSymptom", model);
        } else {
          this.set("selectedSymptom", null);
        }
      },
      gotoAppointmentNew: function gotoAppointmentNew(queryParams) {
        this.get("model").save();
        queryParams.goTo = "organizations.organization.consultations.consultation";
        queryParams.goToId = this.get("model.id");

        if (queryParams) {
          queryParams.consultation = this.get("model.id");
          this.send("transitionToRoute", "organizations.organization.appointments.custom", queryParams);
        } else {
          this.send("transitionToRoute", "organizations.organization.appointments.custom", {
            consultation: this.get("model.id")
          });
        }
      },
      callInternmentDischargeLetter: function callInternmentDischargeLetter() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "dischargeLetter",
            consultation: this.get("model"),
            patient: this.get("model.patient"),
            values: [{
              value: this.get("model.appointment.internment.dischargeText")
            }]
          }
        });
      },
      openTermForm: function openTermForm(template) {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("terms." + template),
            template: template,
            consultation: this.get("model"),
            patient: this.get("model.patient"),
            company: this.get("model.organization.company")
          }
        });
      },
      openTerm: function openTerm(template) {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: template,
            consultation: this.get("model"),
            patient: this.get("model.patient")
          }
        });
      },
      setDates: function setDates(newDate) {
        this.set("editDate", false);

        if (!Ember.isEmpty(newDate)) {
          var appointment = this.store.peekRecord("appointment", this.get("model.appointment.id"));
          this.set("model.date", newDate);
          var timeDifference = (0, _moment.default)(appointment.get("start")).diff((0, _moment.default)(appointment.get("end")), "minutes");
          appointment.set("start", newDate);
          appointment.set("end", (0, _moment.default)(newDate).add(timeDifference, "minutes"));
          appointment.set("timeConsultation", newDate);
          Ember.run.debounce(this, this.saveAppointment, 4000);
          Ember.run.debounce(this, this.saveConsultation, 4000);
        }
      }
    },
    sendOpenModalLabAnalysisExamReport: function sendOpenModalLabAnalysisExamReport(exam) {
      this.send("openModal", {
        entity: "pdf2",
        action: "details",
        model: {
          template: "labAnalysisExamReport",
          consultation: this.get("model"),
          patient: this.get("model.patient"),
          exam: exam
        }
      });
    }
  });

  _exports.default = _default;
});