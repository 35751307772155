define("crm/components/purchase-entity-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    isPurchaseOrder: Ember.computed("record.id", function () {
      return this.get("record._internalModel.modelName") === "purchase-order";
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: this.get("record._internalModel.modelName"),
          action: action,
          model: this.get("record")
        });
      },
      duplicate: function duplicate() {
        if (this.get("record._internalModel.modelName") === "purchase-order") {
          this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s.new", {
            queryParams: {
              purchaseOrder: this.get("record.id")
            }
          });
        } else {
          this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s.new", {
            queryParams: {
              purchaseRequest: this.get("record.id")
            }
          });
        }
      },
      edit: function edit() {
        this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s." + this.get("record._internalModel.modelName") + ".edit", this.get("record.id"));
      },
      send: function send() {
        if (confirm(this.get('intl').t("purchaseOrders.send.title"))) {
          this.set("record.status", 1);
          this.get("record").save();
        }
      },
      receive: function receive() {
        this.sendAction("receivePurchaseOrder", this.get("record"));
      }
    }
  });

  _exports.default = _default;
});