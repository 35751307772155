define("crm/helpers/initials-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialsDisplay = initialsDisplay;
  _exports.default = void 0;

  function initialsDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return this.get("intl").t("initials.urineNormal.name");

        case 2:
          return this.get("intl").t("initials.urineConcentrated.name");

        case 3:
          return this.get("intl").t("initials.urineNormalByExpression.name");

        case 4:
          return this.get("intl").t("initials.urineConcentratedByExpression.name");

        case 5:
          return this.get("intl").t("initials.urineJaundiced.name");

        case 6:
          return this.get("intl").t("initials.hematuria.name");

        case 7:
          return this.get("intl").t("initials.faecesNormal.name");

        case 8:
          return this.get("intl").t("initials.faecesLiquid.name");

        case 9:
          return this.get("intl").t("initials.faecesLiquidWithBlood.name");

        case 10:
          return this.get("intl").t("initials.faecesHard.name");

        case 11:
          return this.get("intl").t("initials.faecesHardWithBlood.name");

        case 12:
          return this.get("intl").t("initials.faecesSoft.name");

        case 13:
          return this.get("intl").t("initials.feedingNormal.name");

        case 14:
          return this.get("intl").t("initials.feedingForced.name");

        case 15:
          return this.get("intl").t("initials.vomitFood.name");

        case 16:
          return this.get("intl").t("initials.vomitFoamy.name");

        case 17:
          return this.get("intl").t("initials.vomitBile.name");

        case 18:
          return this.get("intl").t("initials.foodNormal.name");

        case 19:
          return this.get("intl").t("initials.foodForced.name");

        case 20:
          return this.get("intl").t("initials.didntEat.name");

        case 21:
          return this.get("intl").t("initials.fasting.name");

        case 22:
          return this.get("intl").t("initials.faecesSoftMoulded.name");

        case 23:
          return this.get("intl").t("initials.catheterRightForelimb.name");

        case 24:
          return this.get("intl").t("initials.catheterLeftForelimb.name");

        case 25:
          return this.get("intl").t("initials.catheterRightHindlimb.name");

        case 26:
          return this.get("intl").t("initials.catheterLeftHindlimb.name");

        case 27:
          return this.get("intl").t("initials.noFaeces.name");

        case 28:
          return this.get("intl").t("initials.noUrine.name");

        case 29:
          return this.get("intl").t("initials.faecesDigestedBlood.name");

        case 30:
          return this.get("intl").t("initials.faecesLivingBlood.name");

        case 31:
          return this.get("intl").t("initials.vomitWithBlood.name");

        case 32:
          return this.get("intl").t("initials.vomitRegurgitation.name");

        case 33:
          return this.get("intl").t("initials.foodTube.name");

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});