define("crm/components/specie-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    newSpecie: {},
    disabledAction: false,
    init: function init() {
      this._super();

      this.reset();
    },
    reset: function reset() {
      this.set('newSpecie', {});
      this.set('disabledAction', false);
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$(".modal").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.reset();
      },
      create: function create() {
        this.set('disabledAction', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newSpecie.organization', this.get('organization'));
        }

        var newSpecie = this.get('store').createRecord('species', this.get('newSpecie'));
        newSpecie.save().then(function () {
          self.reset();
        }).catch(function () {
          self.set('disableCreateBtn', false);
        });
      }
    }
  });

  _exports.default = _default;
});