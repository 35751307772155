define("crm/components/supplier-new", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    countries: _uConstants.default.countries,
    newSupplier: {},
    contact: {},
    address: {},
    rpContacts: [{}],
    close: function close() {
      Ember.$("#modal-supplier-new").modal("hide");
    },
    countryIsPortugal: Ember.computed("address.country", function () {
      return !Ember.isEmpty(this.get("address.country")) && this.get("address.country.code") === "PT";
    }),
    isRequired: Ember.computed("address.country", "address.street", "address.building", "address.zip", "address.town", function () {
      if (Ember.isEmpty(this.get("address.country")) && Ember.isEmpty(this.get("address.street")) && Ember.isEmpty(this.get("address.building")) && Ember.isEmpty(this.get("address.zip")) && Ember.isEmpty(this.get("address.town"))) {
        return false;
      } else {
        return true;
      }
    }),
    hasMoreThanOne: Ember.computed("rpContacts.[]", function () {
      if (this.get("rpContacts.length") > 1) {
        return true;
      } else {
        return false;
      }
    }),
    nifAlreadyExists: Ember.computed("countryIsPortugal", "newSupplier.vat", function () {
      if (!this.get("countryIsPortugal") || this.get("newSupplier.vat.length") >= 9) {
        return this.get("store").query("supplier", {
          company: this.get("organization.company.id"),
          vat: this.get("newSupplier.vat")
        });
      }
    }),
    usageNifMessage: Ember.computed("nifAlreadyExists.[]", "newSupplier.vat", function () {
      if (!Ember.isEmpty(this.get("newSupplier.vat"))) {
        return !Ember.isEmpty(this.get("nifAlreadyExists"));
      }
    }),
    disabledAction: Ember.computed("newSupplier.name", "newSupplier.vat", "usageNifMessage", function () {
      return Ember.isEmpty(this.get("newSupplier.name")) || Ember.isEmpty(this.get("address.country")) || Ember.isEmpty(this.get("newSupplier.vat")) || this.get("usageNifMessage");
    }),
    actions: {
      selectCountry: function selectCountry(country) {
        if (country) {
          this.set("address.country", country);
        }
      },
      canceled: function canceled() {
        this.close();
      },
      addRpContact: function addRpContact() {
        this.get("rpContacts").addObject({});
      },
      removeRpContact: function removeRpContact(contact) {
        this.get("rpContacts").removeObject(contact);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;

        if (this.get("organization")) {
          this.set("newSupplier.organization", this.get("organization"));
          this.set("newSupplier.company", this.get("organization.company"));
        }

        if (this.get("address.country")) {
          this.set("newSupplier.country", this.get("address.country.code"));
        }

        var newSupplier = this.get("store").createRecord("supplier", this.get("newSupplier"));
        newSupplier.save().then(function (result) {
          /*if(self.get("mobilePhone")) {
          self.set("contact.contactType", 1);
          self.set("contact.contact", self.get("mobilePhone"));
          self.set("contact.name", savedSupplier.get("name")); // TODO build the name on serverside | person name + some sufix
          self.set("contact.preferential", true);
          self.set("contact.supplier", savedSupplier);
          self.get("store").createRecord("contact", self.get("contact")).save();
          }
           if(self.get("email")) {
          self.set("contact.contactType", 3);
          self.set("contact.contact", self.get("email"));
          self.set("contact.name", savedSupplier.get("name"));
          self.set("contact.preferential", true);
          self.set("contact.supplier", savedSupplier);
          self.get("store").createRecord("contact", self.get("contact")).save();
          }
           if(self.get("address")) {
          self.get("store").createRecord("address", self.get("address")).save();
          }*/

          /*if(self.get("rpContacts")) {
          self.get("rpContacts").forEach(function(contact) {
          var newRpContact = self.get("store").createRecord("contact", {
          name: contact.name,
          contact: contact.contact,
          });
          newRpContact.save();
          })
          }*/
          self.get("created")("supplier", result);
          self.close();
          self.get("flashMessages").success(self.get("intl").t("suppliers.messages.createdSuccess"));
        }).catch(function () {
          self.get("store").unloadRecord(newSupplier);
          self.set("disabledAction", false);
        });
      },
      reset: function reset() {
        this.set("newSupplier", {});
        this.set("contact", {});
        this.set("address", {});
        this.set("disabledAction", false);
        this.set("mobilePhone", null);
        this.set("email", null);
        this.set("rpMobilePhone", null);
        this.set("rpEmail", null);
        this.set("rpName", null);
        this.set("rpContacts", [{}]);
        this.set("selectedCountry", null);
      }
    }
  });

  _exports.default = _default;
});