define("crm/controllers/organizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    isGod: Ember.computed.readOnly("sessionAccount.currentUser.isGod"),
    showSweetAlertError: false,
    showSweetAlertSuccess: false
  });

  _exports.default = _default;
});