define("crm/routes/organizations/organization/inventory/excess", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    worker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    model: function model() {
      var self = this;
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/excess?organization=" + self.get("worker.organization.id"),
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        },
        success: function success(result) {
          result.data = JSON.parse(result.data);
          return result;
        },
        error: function error() {
          return {};
        }
      });
    }
  });

  _exports.default = _default;
});