define("crm/components/procurement/purchase-invoice-header-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * components/procurement/purchase-invoice-header-new
   */
  var _default = Ember.Component.extend({
    tagName: "form",
    classNames: ["form form-horizontal"],
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    hasFinancialAccountTypeBankAccount: Ember.computed("model.organization.financialAccounts.@each.financialAccountType", function () {
      return this.get("model.organization.financialAccounts") && this.get("model.organization.financialAccounts").filterBy("financialAccountType", 2).get("length");
    }),
    hasFinancialAccountTypeRegister: Ember.computed("model.organization.financialAccounts.@each.financialAccountType", "paymentMethod", function () {
      return this.get("model.organization.financialAccounts") && (this.get("model.organization.financialAccounts").filterBy("financialAccountType", 1).get("length") || this.get("paymentMethod") === "DEB");
    }),
    actions: {
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        this.send("setField", "paymentMethod", paymentMethod && paymentMethod !== this.get("model.paymentMethod") ? paymentMethod : null);
      },
      setField: function setField(field, value) {
        if ("paidAmount" === field && !value) {
          this.set("model.paid", false);
        }

        this.setField(field, value);
      }
    }
  });

  _exports.default = _default;
});