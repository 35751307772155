define("crm/models/purchase-receipt", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    billingID: _emberData.default.attr("string"),
    paymentMethod: _emberData.default.attr("string"),
    deletedReason: _emberData.default.attr("string"),
    checkNumber: _emberData.default.attr("number"),
    checkDate: _emberData.default.attr("localdate"),
    settled: _emberData.default.attr("number"),
    date: _emberData.default.attr("localdate"),
    financialAccount: _emberData.default.belongsTo("financial-account"),
    supplier: _emberData.default.belongsTo("supplier"),
    purchaseReceiptPurchaseInvoice: _emberData.default.hasMany("purchase-receipt-purchase-invoice")
  });

  _exports.default = _default;
});