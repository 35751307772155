define("crm/routes/organizations/organization/item-adjustments/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      startedAfter: {
        refreshModel: true,
        as: "from"
      },
      startedBefore: {
        refreshModel: true,
        as: "until"
      }
    },
    model: function model(params, transition) {
      if (params.startedAfter && params.startedBefore) {
        var after = (0, _moment.default)(params.startedAfter);
        var before = (0, _moment.default)(params.startedBefore);

        if (after.isValid() && before.isValid()) {
          after = (0, _moment.default)(params.startedAfter).startOf("day").format();
          before = (0, _moment.default)(params.startedBefore).endOf("day").format();
          var organization = this.modelFor("organizations.organization");

          if ((0, _moment.default)(before).diff(after, "days") <= this.get("dateLimit")) {
            return this.get("store").query("item-adjustment", {
              organization: organization.get("id"),
              dateAfter: after,
              dateBefore: before
            });
          } else {
            return null;
          }
        } else {
          transition.queryParams = {};
        }
      }
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });

  _exports.default = _default;
});