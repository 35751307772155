define("crm/models/charge", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    code: _emberData.default.attr('number'),
    provider: _emberData.default.attr('number'),
    format: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number'),
    customerName: _emberData.default.attr('string'),
    customerEmail: _emberData.default.attr('string'),
    customerPhoneCountry: _emberData.default.attr('string'),
    customerPhone: _emberData.default.attr('string'),
    expiration: _emberData.default.attr('localdate'),
    status: _emberData.default.attr('number'),
    paidAmount: _emberData.default.attr('number'),
    customer: _emberData.default.belongsTo('customer'),
    invoice: _emberData.default.belongsTo('invoice'),
    organization: _emberData.default.belongsTo('organization')
  });

  _exports.default = _default;
});