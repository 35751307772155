define("crm/models/template", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    report: _emberData.default.attr("string"),
    parent: _emberData.default.belongsTo("template", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("template", {
      inverse: "parent"
    }),
    sections: _emberData.default.hasMany("template-section", {
      inverse: "template"
    }),
    enable: _emberData.default.attr("boolean"),
    fields: Ember.computed("sections.[]", "sections.@each.fields", function () {
      if (!Ember.isEmpty(this.get("sections"))) {
        var fields = Ember.A();
        this.get("sections").forEach(function (section) {
          fields.addObjects(section.get("fields"));
        });
        return fields;
      }
    })
  });

  _exports.default = _default;
});