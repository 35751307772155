define("crm/controllers/organizations/organization/sheltereds/sheltered/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    disableBtnWeight: false,
    disableBtnPathology: false,
    temperatureData: Ember.observer('model.id', function () {
      var _this = this;

      if (this.get('model.id') == 38) {
        Ember.$.ajax({
          method: 'GET',
          accept: "application/json",
          contentType: "application/json; charset=utf-8",
          url: "http://23.97.163.170:8080/PetSenseStudDETI-1.0-SNAPSHOT/rest/cass/c"
        }).then(function (result) {
          _this.set("temperatureData", JSON.parse(result));
        });
      }
    }),
    temperatureGraphData: Ember.computed('temperatureData.[]', function () {
      if (this.get('temperatureData.length') > 0) {
        var dates = this.get('temperatureData').map(function (temperature) {
          return (0, _moment.default)(temperature.local_timestamp).format('DD/MM/YYYY');
        }).reverse();
        var temperatures = this.get('temperatureData').mapBy('temperature').reverse();
        return {
          labels: dates,
          datasets: [{
            label: this.get('intl').t("sheltereds.sheltered.weights.weight.title"),
            data: temperatures,
            fillColor: "#2f4050",
            backgroundColor: "rgba(220,220,220,0.2)",
            pointBackgroundColor: "rgba(220,220,220,1)",
            pointRadius: 2,
            pointHoverRadius: 2,
            pointHoverColor: "rgba(220,220,220,1)",
            pointStyle: 'circle',
            lineTension: 0
          }]
        };
      }
    }),
    newShelteredPathology: Ember.computed('model.id', function () {
      return {
        date: (0, _moment.default)().format('YYYY-MM-DD')
      };
    }),
    actions: {
      selectPathology: function selectPathology(pathology) {
        this.set('pathology', pathology);
      },
      openEditShelteredModal: function openEditShelteredModal(shelteredToEdit) {
        this.send('openEditSheltered', shelteredToEdit);
      },
      openModalCreateAppointment: function openModalCreateAppointment() {
        this.transitionToRoute('organizations.organization.appointments.custom', {
          queryParams: {
            shelteredid: this.get('model.id')
          }
        });
      }
      /* createPathology() {
          var self = this;
          this.set('disableBtn', true);
           this.set("newShelteredPathology.sheltered", this.get("model"));
           var pathology = this.store.peekRecord('pathology', this.get("pathology"));
          this.set("newShelteredPathology.pathology", pathology);
           var shelteredPathology = this.get('store').createRecord('shelteredPathology', this.get("newShelteredPathology"));
           shelteredPathology.save()
              .then(function () {
                  self.send('getPathologies');
                  self.set('newShelteredPathology', {date: moment().format('YYYY-MM-DD')});
                  self.set('disableBtnPathology', false);
              }).catch(function() {
                  self.set('disableBtnPathology', false);
                  self.get('store').unloadRecord(shelteredPathology);
          });
      },
       deletePathology(pathologyID) {
          if (confirm(this.get('intl').t('intl.remove'))) {
              var self = this;
              var pathologyDelete = this.get('store').peekRecord('shelteredPathology', pathologyID);
               pathologyDelete.destroyRecord()
                  .then(function() {
                      self.send('getPathologies');
                  });
          }
      } */
      //TODO

    }
  });

  _exports.default = _default;
});