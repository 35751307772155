define("crm/routes/organizations/organization/patients/patient/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      if (model.get("owner.id")) {
        this.store.findRecord("customer", model.get("owner.id"));
        this.store.query("contact", {
          customer: model.get("owner.id")
        });
      }

      this.store.query("item", {
        patient: model.get("id"),
        periodic: true
      });
      this.store.query("periodic", {
        patient: model.get("id")
      });
      this.store.query("weight", {
        patient: model.get("id")
      });
      this.store.query("appointment", {
        patient: model.get("id")
      });
      this.store.query("consultation", {
        patient: model.get("id")
      });
      this.store.query("task", {
        patient: model.get("id")
      });
      this.store.query("note", {
        patient: model.get("id")
      });
      this.store.query("exam", {
        patient: model.get("id")
      });
      this.store.query("fileExam", {
        patient: model.get("id")
      });
      this.store.query("internment", {
        patient: model.get("id")
      });
      this.store.query("shopping-cart", {
        patient: model.get("id")
      });
      this.store.query("invoice", {
        patient: model.get("id")
      });
      this.store.query("quotation", {
        patient: model.get("id")
      });
      this.store.query("memo", {
        patient: model.get("id")
      });
      this.store.query("order", {
        patient: model.get("id")
      });
      this.store.query("receipt", {
        patient: model.get("id")
      });
      this.store.query("payment", {
        patient: model.get("id")
      });
      this.store.query("file-patient", {
        patient: model.get("id")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
      model.get("notes").sortBy("started");
    },
    actions: {
      openEditPatient: function openEditPatient(patientToEdit) {
        this.send("openEditPatientModal", patientToEdit);
      },
      openCreateAppointment: function openCreateAppointment(options) {
        this.send("openModalCreateAppointment", options);
      },
      openEdit: function openEdit(entity, model) {
        this.send("openModal", {
          entity: entity,
          action: "edit",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});