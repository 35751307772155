define("crm/abilities/denied", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canDo: false,
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false
  });

  _exports.default = _default;
});