define("crm/controllers/organizations/organization/invoices/invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    openShoppingCarts: Ember.computed("model.shoppingCartsByInvoice.@each.created", "model.shoppingCartsByInvoice.@each.status", function () {
      if (!Ember.isEmpty(this.get("model.shoppingCartsByInvoice"))) {
        return this.get("model.shoppingCartsByInvoice").sortBy("created").reverse();
      }
    })
  });

  _exports.default = _default;
});