define("crm/controllers/organizations/organization/patients/patient/index", ["exports", "moment", "crm/config/environment", "ember-inflector"], function (_exports, _moment, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pdf: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    documents: Ember.inject.service(),
    terms: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    newAppointment: {},
    disableBtnWeight: false,
    selectedSymptom: null,
    exporting: false,
    isPT: Ember.computed("intl.locale", function () {
      return this.get("intl.locale").includes("pt");
    }),
    filteredModels: Ember.computed('model.invoices.[]', 'model.orders.[]', 'model.quotations.[]', 'model.memos.[]', 'model.receipts.[]', 'model.payments.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices"));
      }

      if (!Ember.isEmpty(this.get("model.orders"))) {
        result.addObjects(this.get("model.orders"));
      }

      if (!Ember.isEmpty(this.get("model.quotations"))) {
        result.addObjects(this.get("model.quotations"));
      }

      if (!Ember.isEmpty(this.get("model.memos"))) {
        result.addObjects(this.get("model.memos"));
      }

      if (!Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts"));
      }

      if (!Ember.isEmpty(this.get("model.payments"))) {
        result.addObjects(this.get("model.payments"));
      }

      return result;
    }),
    activities: Ember.computed("model.weights.[]", "model.tasks.[]", "model.periodics.[]", "model.appointments.[]", "model.exams.[]", "model.consultations.[]", "model.notes.[]", function () {
      var self = this;
      var result = [];
      this.get("model.weights") && this.get("model.weights").forEach(function (weight) {
        if (weight.get("weighted")) {
          result.push({
            model: weight.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.weight"),
            className: "bg-crm",
            date: weight.get("weighted"),
            content: weight
          });
        }
      });
      this.get("model.tasks") && this.get("model.tasks").forEach(function (task) {
        if (task.get("taskDate")) result.push({
          model: task.get("_internalModel.modelName"),
          icon: self.get("intl").t("icons.task"),
          className: "bg-calendar",
          date: task.get("taskDate"),
          content: task
        });
      });
      this.get("model.periodics") && this.get("model.periodics").forEach(function (periodic) {
        if (periodic.get("intakeDate")) result.push({
          model: periodic.get("_internalModel.modelName"),
          icon: self.get("intl").t("icons.periodic"),
          className: "bg-clinical",
          date: periodic.get("intakeDate"),
          content: periodic
        });
      });
      this.get("model.appointments") && this.get("model.appointments").filter(function (a) {
        return a && a.get("status") < 4;
      }).forEach(function (appointment) {
        if (appointment.get("start")) {
          result.push({
            model: appointment.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.appointment"),
            className: "bg-calendar",
            date: appointment.get("start"),
            content: appointment,
            record: {
              appointment: appointment
            }
          });
        }
      });
      this.get("model.exams") && this.get("model.exams").forEach(function (exam) {
        if (exam.get("doneDate")) {
          result.push({
            model: exam.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.exam"),
            className: "bg-clinical",
            date: exam.get("doneDate"),
            content: exam
          });
        }
      });
      this.get("model.consultations") && this.get("model.consultations").forEach(function (consultation) {
        if (consultation.get("date")) result.push({
          model: consultation.get("_internalModel.modelName"),
          icon: self.get("intl").t("icons.consultation"),
          className: "bg-clinical",
          date: consultation.get("date"),
          content: consultation
        });
      });
      this.get("model.notes") && this.get("model.notes").forEach(function (note) {
        if (note.get("started")) {
          result.push({
            model: note.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.notes"),
            className: "bg-crm",
            date: note.get("started"),
            content: note
          });
        }
      });
      this.get("model.internments") && this.get("model.internments").forEach(function (internment) {
        if (internment.get("start")) {
          result.push({
            model: internment.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.internment"),
            className: "bg-internments",
            date: internment.get("start"),
            content: internment
          });
        }
      });
      this.get("model.files") && this.get("model.files").forEach(function (file) {
        if (file.get("created")) {
          result.push({
            model: file.get("_internalModel.modelName"),
            icon: self.get("intl").t("icons.file"),
            className: "bg-clinical",
            date: file.get("created"),
            content: file
          });
        }
      });
      return result.filter(function (a) {
        return (0, _moment.default)().isSameOrAfter(a.date, "d");
      }).sort(function (a, b) {
        return (0, _moment.default)(a.date).isBefore(b.date) ? 1 : -1;
      });
    }),
    filteredWeights: Ember.computed("model.weights.@each.weighted", function () {
      return this.get("model.weights") ? this.get("model.weights").sortBy("weighted") : [];
    }),
    showDisable: Ember.computed("model.enabled", "model.deathdate", function () {
      return this.get("model.deathdate") || !this.get("model.enabled");
    }),
    showWeights: Ember.computed("filteredWeights.[]", function () {
      return !Ember.isEmpty(this.get("filteredWeights")) && this.get("filteredWeights.length") > 1 ? true : false;
    }),
    filteredConsultations: Ember.computed("model.consultations.[]", "selectedSymptom", function () {
      if (this.get("selectedSymptom")) {
        var self = this;
        return this.get("model.consultations").filter(function (consultation) {
          var flag = false;
          consultation.get("symptoms").forEach(function (symptom) {
            if (symptom === self.get("selectedSymptom")) {
              flag = true;
            }
          });
          return flag;
        }).sortBy("date").reverse();
      } else {
        return this.get("model.consultations") ? this.get("model.consultations").sortBy("date").reverse() : [];
      }
    }),
    filteredSymptoms: Ember.computed("model.consultations.@each.symptoms", function () {
      var result = [];
      this.get("model.consultations").forEach(function (consultation) {
        if (!Ember.isEmpty(consultation.get("symptoms"))) {
          consultation.get("symptoms").forEach(function (symptom) {
            result.addObject(symptom);
          });
        }
      });
      return result;
    }),
    shouldHideFutureVisits: Ember.computed("model.deathdate", "model.owner.id", function () {
      return this.get("model.deathdate") || !this.get("model.owner.id");
    }),
    customerHasPhone: Ember.computed("model.owner.contacts.[]", "model.owner.contacts.@each.contactType", function () {
      if (!Ember.isEmpty(this.get("model.owner.contacts"))) {
        return this.get("model.owner.contacts").filterBy("contactType", 1);
      }
    }),
    examion: function examion(request) {
      var _this = this;

      this.store.query("address", {
        customer: this.get("model.owner.id")
      }).then(function () {
        var content = request + // Tipo de pedido:6302 para fazer rx, 6311 para ver rx, 6310 para o feedback do exame
        // "\n0008402XRAY01" +	    //	Dispositivo, XRAY01, SONO02, DICO03 ...
        "\n0003000" + _this.get("model.code") + "\n0003100" + (_this.get("model.species.name") ? _this.get("model.species.name") : "") + "\n0003101" + _this.get("model.name") + "\n0003102" + (_this.get("model.breed.name") ? _this.get("model.breed.name") : "") + "\n0003103" + (_this.get("model.birthdate") ? (0, _moment.default)(_this.get("model.birthdate")).format("DDMMYYYY") : "") + "\n0003104" + (_this.get("model.furColor") ? _this.get("model.furColor") : "") + "\n0003110" + (_this.get("model.sex") ? _this.get("model.sex") : "") + "\n0003120" + // Nome oficial
        "\n0003121" + (_this.get("model.sterilized") ? 1 : 0) + "\n0003122" + "\n0003123" + "\n0003124" + "\n0003125" + (_this.get("model.chip") ? _this.get("model.chip") : "") + "\n0003126" + "\n0003201" + _this.get("model.owner.name").split(' ').slice(-1).join(' ') + "\n0003202" + _this.get("model.owner.name").split(' ').slice(0, -1).join(' ') + "\n0003205" + (_this.get("model.owner.address.zipCode") ? _this.get("model.owner.address.zipCode") : "") + "\n0003206" + (_this.get("model.owner.address.town") ? _this.get("model.owner.address.town") : "") + "\n0003207" + (_this.get("model.owner.address.street") ? _this.get("model.owner.address.street") : "") + "\n0003626" + (_this.get("model.owner.phoneContact.contact") ? _this.get("model.owner.phoneContact.contact") : "") + "\n0003619" + (_this.get("model.owner.emailContact.contact") ? _this.get("model.owner.emailContact.contact") : "");
        var a = document.createElement('a');
        a.setAttribute('href', 'data:application/json;charset=ISO-8859-1,' + escape(content));
        a.setAttribute('download', 'mgpcs.gdt');
        a.click();
      });
    },
    actions: {
      newExamion: function newExamion() {
        this.examion("00080006302");
      },
      viewExamion: function viewExamion() {
        this.examion("00080006311");
      },
      imageUploaded: function imageUploaded(savedFile) {
        var fileEmberObj = this.get("store").createRecord("file-patient", savedFile);
        this.get("model.files").pushObject(fileEmberObj);
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        Ember.set(periodic, "patient", this.get("model"));
        this.get("store").createRecord("periodic", periodic).save();
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      selectSymptom: function selectSymptom(model) {
        if (model) {
          this.set("selectedSymptom", model);
        } else {
          this.set("selectedSymptom", null);
        }
      },
      createShoppingCart: function createShoppingCart() {
        var self = this;
        self.get("store").createRecord("shopping-cart", {
          organization: this.get("organization"),
          patient: this.get("model"),
          customer: this.get("model.owner")
        }).save().then(function (savedShoppingCart) {
          self.send("reloadModel");
          self.send("openModal", {
            entity: "shopping-cart",
            action: "edit",
            model: {
              shoppingCart: savedShoppingCart,
              hidePayButton: true,
              isAllowedToChange: true
            }
          });
        });
      },
      openEditPatientModal: function openEditPatientModal() {
        this.send("openEditPatient", this.get("model"));
      },

      /* Drugs */
      openDrugDetailsModal: function openDrugDetailsModal(drug) {
        this.send("openModal", {
          entity: "drug",
          action: "details",
          model: drug
        });
      },

      /* Exams */
      openModalMDCDetails: function openModalMDCDetails(exam) {
        this.send('openModal', {
          entity: "exam",
          action: "details",
          model: exam
        });
      },

      /* Weights */
      newWeight: function newWeight() {
        this.send("openModal", {
          entity: "weight",
          action: "new",
          model: this.get("model")
        });
      },
      openWeightsModal: function openWeightsModal() {
        this.send("openModal", {
          entity: "weight",
          action: "details",
          model: this.get("model.weights")
        });
      },

      /* tasks */
      newTask: function newTask() {
        this.send("openModal", {
          entity: "task",
          action: "create",
          model: {
            patient: this.get("model")
          }
        });
      },

      /*
       * Other to be ewviewd
       */
      makeSale: function makeSale() {
        var queryParams = {
          patientId: this.get("model.id"),
          customerId: this.get("model.owner.id")
        };
        this.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      },
      openConsultation: function openConsultation(consultation) {
        this.send("openModal", {
          entity: "consultation",
          action: "details",
          model: consultation
        });
      },
      gotoAppointmentNew: function gotoAppointmentNew() {
        if (!Ember.isEmpty(this.get("model.owner.content"))) {
          this.send("transitionToRoute", "organizations.organization.appointments.custom", {
            patient: this.get("model.id")
          });
        } else {
          this.get('flashMessages').info(this.get('intl').t('patients.patient.noOwner'));
        }
      },
      openDetails: function openDetails(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "details",
          model: exam
        });
      },
      openEdit: function openEdit(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "edit",
          model: exam
        });
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      },
      sendSMS: function sendSMS() {
        this.send("openModal", {
          entity: "sms",
          action: "create",
          model: {
            patient: this.get('model'),
            customer: this.get('model.owner')
          }
        });
      },
      newNote: function newNote() {
        this.send("openModal", {
          entity: "note",
          action: "create",
          model: {
            patient: this.get("model")
          }
        });
      },
      openNoteDetails: function openNoteDetails(model) {
        this.send("openModal", {
          entity: "note",
          action: "details",
          model: model
        });
      },
      openNoteEdit: function openNoteEdit(model) {
        this.send("openModal", {
          entity: "note",
          action: "edit",
          model: model
        });
      },
      openModalCreatePeriodic: function openModalCreatePeriodic() {
        this.send("openModal", {
          entity: "periodics",
          action: "create",
          model: {
            patient: this.get("model")
          }
        });
      },
      openModalCreateExam: function openModalCreateExam() {
        this.send("openModal", {
          entity: "exam",
          action: "create",
          model: {
            patient: this.get("model")
          }
        });
      },
      openModalCreateInternment: function openModalCreateInternment() {
        this.send("openModal", {
          entity: "internment",
          action: "create",
          model: {
            patient: this.get("model")
          }
        });
      },
      createConsultation: function createConsultation() {
        if (!Ember.isEmpty(this.get("model.owner.content"))) {
          this.send("openModal", {
            entity: "consultation",
            action: "new",
            model: {
              patient: this.get("model")
            }
          });
        } else {
          this.get('flashMessages').info(this.get('intl').t('patients.patient.noOwnerConsultation'));
        }
      },
      openTaskEdit: function openTaskEdit(model) {
        this.send("openModal", {
          entity: "task",
          action: "edit",
          model: model
        });
      },
      openCommentCreate: function openCommentCreate(task) {
        this.send("openModal", {
          entity: "task",
          action: "commentCreate",
          model: {
            task: task
          }
        });
      },
      updateModel: function updateModel() {
        this.send("reloadModel");
      },
      setf: function setf(f) {
        this.set("selectedFilter", f);
      },

      /*
       * Terms
       */
      openTermForm: function openTermForm(template) {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("terms." + template),
            template: template,
            patient: this.get("model"),
            company: this.get("model.organization.company")
          }
        });
      },
      openTerm: function openTerm(template) {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: template,
            patient: this.get("model")
          }
        });
      },
      callResponsabilityTerm: function callResponsabilityTerm() {
        this.send("openModal", {
          entity: "custom-form",
          action: "example",
          model: {
            fields: this.get("documents.responsabilityTerm"),
            template: "responsabilityTerm",
            patient: this.get("model"),
            company: this.get("organization.company")
          }
        });
      },
      callLabAnalysisExamReport: function callLabAnalysisExamReport(exam) {
        var self = this;
        var modelName = exam.constructor.modelName;
        var id = exam.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("name") + "_" + (0, _moment.default)(savedDocument.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale "));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = exam.get("patient.code") + "_" + exam.get("patient.name") + "_" + exam.get("name") + "_" + (0, _moment.default)(exam.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      },
      document: function (_document) {
        function document(_x) {
          return _document.apply(this, arguments);
        }

        document.toString = function () {
          return _document.toString();
        };

        return document;
      }(function (model) {
        var self = this;
        var modelName = model.constructor.modelName;
        var id = model.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(savedDocument.get("date")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale"));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = model.get("patient.code") + "_" + model.get("patient.name") + "_" + model.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(model.get("date")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      })
    }
  });

  _exports.default = _default;
});