define("crm/components/documents-list-actions", ["exports", "crm/config/environment", "ember-inflector"], function (_exports, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service("session"),
    exporting: false,
    canDoDocuments: Ember.computed("can", function () {
      //return this.get("can").can("do sale"); TODOJD
      return true;
    }),
    isPurchaseInvoice: Ember.computed("record._internalModel.modelName", function () {
      return this.get("record._internalModel.modelName") === "purchase-invoice";
    }),
    hideEmitPdf: Ember.computed("record.name", function () {
      return this.get("record.name") === "Invoice" || this.get("record.name") === "Order" || this.get("record.name") === "Quotation" || this.get("record.name") === "Memo" || this.get("record.name") === "Receipt" || this.get("record.name") === "Payment" || this.get("record.name") === "Delivery" || !Ember.isEmpty(this.get("record.status")) && [4, 9, 3].includes(this.get("record.status"));
    }),
    canSendEmail: Ember.computed(function () {
      return (this.get("record").constructor.modelName == "invoice" || this.get("record").constructor.modelName == "receipt") && this.get("record.status") !== 4;
    }),
    canEmitMemos: Ember.computed("record.status", function () {
      return this.get("record").constructor.modelName == "invoice" && ![3, 4, 8, 9].includes(this.get("record.status"));
    }),
    failled: Ember.computed("record.status", function () {
      return this.get("record").constructor.modelName == "invoice" && this.get("record.status") === 4;
    }),
    editDocument: Ember.computed("record.paymentMethod", function () {
      return !Ember.isEmpty(this.get("record.paymentMethod")) && ![3, 4, 8, 9].includes(this.get("record.status"));
    }),
    order: Ember.computed("record.order", function () {
      var model = this.get("record");

      if (model.constructor.modelName == "order") {
        if (this.get("record.status") == 1 || this.get("record.status") == 2) {
          this.set("hasInvoice", true);
        } else {
          this.set("hasInvoice", false);
        }

        return true;
      } else {
        return false;
      }
    }),
    quotation: Ember.computed("record.quotation", function () {
      var model = this.get("record");

      if (model.constructor.modelName == "quotation") {
        if (this.get("record.status") == 1 || this.get("record.status") == 2) {
          this.set("acceptedQuotation", false);
        } else {
          this.set("acceptedQuotation", true);
        }

        return true;
      } else {
        return false;
      }
    }),
    actions: {
      openDetails: function openDetails(document) {
        var self = this;
        this.get("store").findRecord(document.constructor.modelName, document.get("id")).then(function (savedDocument) {
          self.sendAction("openModal", savedDocument);
        });
      },
      showRecordPDF: function showRecordPDF(record) {
        var self = this;
        var modelName = (0, _emberInflector.pluralize)(record.constructor.modelName);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + modelName + "/" + self.get("record.id") + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.sendAction("showPdf", window.URL.createObjectURL(blob));
          }
        });
      },
      goToPos: function goToPos(record) {
        var queryParams = {};

        if (record.constructor.modelName === "invoice") {
          queryParams = {
            invoiceId: this.get("record.id")
          };
        } else if (record.constructor.modelName === "quotation") {
          queryParams = {
            quotationId: this.get("record.id")
          };
        } else {
          queryParams = {
            orderId: this.get("record.id")
          };
        }

        this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      },
      emitNote: function emitNote() {
        var queryParams = {};
        queryParams = {
          invoiceId: this.get("record.id")
        };
        this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      },
      acceptQuotation: function acceptQuotation(quotation) {
        var self = this;
        this.get("store").findRecord("quotation", quotation.get("id"), {
          reload: true
        }).then(function (quotationFound) {
          quotationFound.set("status", 1);
          quotationFound.save().then(function () {
            self.sendAction("reloadModel");
          });
          self.get("flashMessages").success(self.get("intl").t("configurations.configItems.acceptedQuotation"));
        }).catch(function (error) {
          self.get("flashMessages").danger(error);
        });
      },
      rejectQuotation: function rejectQuotation(quotation) {
        var self = this;
        this.get("store").findRecord("quotation", quotation.get("id"), {
          reload: true
        }).then(function (quotationFound) {
          quotationFound.set("status", 2);
          quotationFound.save().then(function () {
            self.sendAction("reloadModel");
          });
          self.get("flashMessages").success(self.get("intl").t("configurations.configItems.rejectedQuotation"));
        }).catch(function (error) {
          self.get("flashMessages").danger(error);
        });
      },
      delete: function _delete(document) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          document.destroyRecord();
        }
      },
      editDocumentModal: function editDocumentModal(document) {
        this.sendAction("editDocumentModal", document);
      },
      sendEmail: function sendEmail(document) {
        this.sendAction("sendEmail", document);
      }
    }
  });

  _exports.default = _default;
});