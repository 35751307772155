define("crm/helpers/array-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayLength = arrayLength;
  _exports.default = void 0;

  function arrayLength() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      if (params[0] && params[0].length > 0) {
        return params[0].includes(params[1]);
      }
    }
  });

  _exports.default = _default;
});