define("crm/components/payment-method-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    values: Ember.computed("intl", function () {
      return [{
        code: "NUM",
        name: this.get("intl").t("pointOfSales.paymentMethods.cash")
      }, {
        code: "CARD",
        name: this.get("intl").t("pointOfSales.paymentMethods.card")
      }, {
        code: "CHQ",
        name: this.get("intl").t("pointOfSales.paymentMethods.check")
      }, {
        code: "TRA",
        name: this.get("intl").t("pointOfSales.paymentMethods.transfer")
      }];
    })
  });

  _exports.default = _default;
});