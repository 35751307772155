define("crm/models/shopping-cart-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    shoppingCart: _emberData.default.belongsTo("shopping-cart"),
    linePriceOld: _emberData.default.attr("number"),
    linePrice2: Ember.computed("linePrice", "discount", "unitPrice", "quantity", function () {
      if (this.get("linePrice")) {
        return this.get("linePrice");
      } else {
        if (this.get("discount")) {
          return Number(this.get("unitPrice")) * Number(this.get("quantity")) - Number(this.get("unitPrice")) * Number(this.get("quantity")) * (this.get("discount") / 100);
        } else {
          return Number(this.get("unitPrice")) * Number(this.get("quantity"));
        }
      }
    })
  });

  _exports.default = _default;
});