define("crm/models/equipment-parameter-type", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    unit: _emberData.default.attr("string"),
    enable: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    equipmentType: _emberData.default.belongsTo("equipment-type"),
    parent: _emberData.default.belongsTo("equipment-parameter-type", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("equipment-parameter-type", {
      inverse: "parent"
    }),
    equipmentParameters: _emberData.default.hasMany("equipment-parameter", {
      inverse: "parameter"
    }),
    items: _emberData.default.hasMany("item", {
      inverse: "parameter"
    }),
    minVal: Ember.computed("equipmentParameters.[]", "equipmentParameters.@each.minVal", "equipmentParameters.@each.species", function () {
      if (!Ember.isEmpty(this.get("equipmentParameters"))) {
        return this.get("equipmentParameters.firstObject.minVal");
      }
    }),
    maxVal: Ember.computed("equipmentParameters.[]", "equipmentParameters.@each.maxVal", "equipmentParameters.@each.species", function () {
      if (!Ember.isEmpty(this.get("equipmentParameters"))) {
        return this.get("equipmentParameters.firstObject.maxVal");
      }
    })
  });

  _exports.default = _default;
});