define("crm/controllers/organizations/organization/configurations/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      save: function save(param) {
        var _this = this;

        var self = this;

        if (param) {
          param.forEach(function (s) {
            switch (Ember.get(s, "type.type")) {
              case 0:
                // self.get("store").createRecord("customerFieldString", newObj).save();
                break;

              case 1:
                // self.get("store").createRecord("customerFieldInt", newObj).save();
                break;

              case 2:
                // self.get("store").createRecord("customerFieldDate", newObj).save();
                break;

              case 3:
                if (Ember.get(s, "id")) {
                  if (s.get("hasDirtyAttributes")) {
                    if (s.get("value")) {
                      s.save().catch(function (e) {
                        return self.get("es").handle(e);
                      });
                    } else {
                      s.destroyRecord().catch(function (e) {
                        return self.get("es").handle(e);
                      });
                    }
                  }
                } else {
                  if (s.value) {
                    _this.store.createRecord("organizationSettingBoolean", s).save().catch(function (e) {
                      return self.get("es").handle(e);
                    });
                  }
                } // self.get("store").createRecord("customerFieldBoolean", newObj).save();


                break;

              case 4:
                // self.get("store").createRecord("customerFieldDouble", newObj).save();
                break;

              default: //console.log("Unknown custom field data type."); TODO

            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});