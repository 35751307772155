define("crm/controllers/organizations/organization/stats/consultations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    consultationTypes: Ember.computed.alias("catalogs.consultationType"),
    exporting: false,
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    newBySpecies: Ember.computed("model.newBySpecies.[]", function () {
      var self = this;
      return !this.get("model.newBySpecies") ? [] : this.get("model.newBySpecies").map(function (s) {
        return {
          speciesID: s[0],
          name: s[1],
          amount: s[2],
          percentage: Math.round((s[2] / self.get("model.created") * 100 + Number.EPSILON) * 100) / 100
        };
      });
    }),
    newByType: Ember.computed("model.newByType.[]", function () {
      var self = this;
      return !this.get("model.newByType") ? [] : this.get("model.newByType").map(function (s) {
        return {
          typeID: s[0],
          name: s[1],
          amount: s[2],
          percentage: Math.round((s[2] / self.get("model.created") * 100 + Number.EPSILON) * 100) / 100
        };
      });
    })
  });

  _exports.default = _default;
});