define("crm/components/customer-field-type-new", ["exports", "crm/components/u-modal-new"], function (_exports, _uModalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uModalNew.default.extend({
    modelName: "customerFieldType",
    organizationSetter: Ember.observer("organization.id", function () {
      this.set("model.organization", this.get("organization"));
    }),
    reset: function reset() {
      this.set("model.name", null);
      this.set("model.mandatory", null);
      this.set("model.unique", null);
      this.set("model.editable", null);
      this.set("model.type", null);
      Ember.$('select').prop('selectedIndex', 0);
      Ember.$("#modal-customerFieldType-create").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});