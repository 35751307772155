define("crm/components/task-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    model: {},
    creating: false,
    close: function close() {
      this.set("closed", this.get("closed") ? false : true);
    },
    reset: function reset() {
      this.set("model", {});
      this.set("creating", false);
    },
    types: Ember.computed(function () {
      return this.get("store").peekAll("taskType");
    }),
    taskPriorities: Ember.computed("intl", function () {
      //TODO move to catalogs
      return [{
        id: 1,
        name: this.get('intl').t("tasks.priorities.low"),
        color: "text-primary"
      }, {
        id: 2,
        name: this.get('intl').t("tasks.priorities.high"),
        color: "text-warning"
      }, {
        id: 3,
        name: this.get('intl').t("tasks.priorities.urgency"),
        color: "text-danger"
      }];
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isEmpty(this.get("model.customer"))) {
        if (!Ember.isEmpty(this.get("model.patient"))) {
          this.set("model.customer", this.get("model.patient.owner"));
        }
      } else {
        if (Ember.isEmpty(this.get("model.patient")) && this.get("model.customer.patients.length") === 1) {
          this.set("model.patient", this.get("model.customer.patients.firstObject"));
        }
      }

      if (this.get("model.exam")) {
        if (!Ember.isEmpty(this.get("model.exam.patient"))) {
          this.set("model.customer", this.get("model.exam.patient.owner"));
          this.set("model.patient", this.get("model.exam.patient"));
        }

        this.set("model.name", this.get("model.exam.name"));
      }
    },
    btnDisable: Ember.computed("creating", "model.customer", "model.patient", "model.name", function () {
      return this.get("creating") || !this.get("model.name") || Ember.isEmpty(this.get("model.patient")) && !Ember.isEmpty(this.get("model.customer"));
    }),
    actions: {
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
      },
      create: function create() {
        this.set("creating", true);
        var self = this;
        this.set("model.status", 1); // Pending

        var createTask = this.get("store").createRecord("task", this.get("model"));
        createTask.save().then(function (saved) {
          self.get("flashMessages").success(self.get("intl").t("tasks.messages.createdSuccess"));
          self.close();
          self.sendAction("created", saved);
        }).catch(function () {
          self.set("creating", false);
        });
      },
      reset: function reset() {
        this.reset();
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});