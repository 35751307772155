define("crm/routes/organizations/organization/financial-accounts/financial-account/archive", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var financialAccountID = this.modelFor("organizations.organization.financialAccounts.financialAccount").get("id");
      var createdAfter = (0, _moment.default)(params.createdAfter).startOf("d").format();
      var createdBefore = (0, _moment.default)(params.createdBefore).endOf("d").format();
      return Ember.RSVP.hash({
        invoices: this.get("store").query("invoice", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        receipts: this.get("store").query("receipt", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        payments: this.get("store").query("payment", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        })
      }).then(function (r) {
        var documents = Ember.A();
        r.invoices && r.invoices.filter(function (i) {
          return i.get("documentType") !== "FA" && !i.get("status");
        }).forEach(function (d) {
          return documents.addObject(d);
        });
        r.purchaseInvoices && r.purchaseInvoices.forEach(function (d) {
          return documents.addObject(d);
        });
        r.receipts && r.receipts.forEach(function (d) {
          return documents.addObject(d);
        });
        r.payment && r.payment.forEach(function (d) {
          return documents.addObject(d);
        });
        return {
          documents: documents
        };
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: "AA"
      });
    },
    actions: {
      search: function search(dates) {
        this.set("controller.createdAfter", dates.nextDateStart);
        this.set("controller.createdBefore", dates.nextDateEnd);
      }
    }
  });

  _exports.default = _default;
});