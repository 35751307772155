define("crm/models/purchase", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    price: _emberData.default.attr("number"),
    lot: _emberData.default.attr("string"),
    quantity: _emberData.default.attr("number"),
    expireDate: _emberData.default.attr("isodate"),
    warehouseItem: _emberData.default.belongsTo("warehouse-item"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    item: _emberData.default.belongsTo("item"),
    discount: _emberData.default.attr('string')
  });

  _exports.default = _default;
});