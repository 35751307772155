define("crm/translations/pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contactos"
    },
    "absences": {
      "approve": {
        "title": "Aprovar Pedidos"
      },
      "edit": {
        "title": "Editar Ausência"
      },
      "new": {
        "title": "Nova Ausência"
      },
      "title": "Ausências",
      "type": {
        "funeral": "Funeral",
        "holidays": "Férias",
        "marriage": "Casamento",
        "parental": "Parental",
        "sick": "Baixa Médica",
        "title": "Tipo de Ausência"
      }
    },
    "account": {
      "emailUs": "email",
      "freeVersion": "Versão gratuita!",
      "getStarted": {
        "addData": "Adicione os dados do seu hospital ou clínica e clique em “Criar”.",
        "clickCreate": "Clique em <Criar>.",
        "clickPlus": "Clique no botão “+ Registar Hospital” para efetuar o registo.",
        "guide": "Ver o Guia Inicial",
        "seeDetails": "Registado o animal poderá depois internar.",
        "step1": "Passo 1",
        "step1Done": "CAMV registado com sucesso.",
        "step2": "Passo 2",
        "step2Done": "Já tem animais registados.",
        "step3": "Passo 3",
        "title1": "Registar CAMV",
        "title2": "Registar o seu primeiro paciente",
        "title3": "Fazer um internamento"
      },
      "limitedUse": "Algumas funcionalidades podem estar limitadas. Para desbloquear envie-nos um",
      "personEditSubtitle": "Edite os seus dados pessoais.",
      "securityEditSubtitle": "Altere os seus dados de login.",
      "settingsSubtitle": "Personalize o sistema a seu gosto.",
      "softwareUpdated": "Está disponível uma atualização - {module} versão: {version}.<br><b>Clique aqui para actualizar!</b>",
      "title": "Conta"
    },
    "actions": {
      "addLine": "Adicionar Linha",
      "advanceOneMonth": "Avançar um mês",
      "approve": "Aprovar",
      "bill": "Faturar",
      "checkIn": "Checkin",
      "checkOut": "Checkout",
      "checkOutTitle": "Marcar conta fechada sem sair desta ficha",
      "checkedOut": "Saiu",
      "checkoutWithoutPaying": "Sair sem pagar",
      "clear": "Limpar Filtros",
      "clearCart": "Limpar",
      "close": "Fechar",
      "continue": "Continuar",
      "create": {
        "lastAction": "Última ação"
      },
      "currentHour": "Data e hora atuais",
      "delete": "Eliminar",
      "details": "Detalhes",
      "disable": "Inativar",
      "doTask": "Realizar Tarefa",
      "download": "Download",
      "edit": "Editar",
      "editProfile": "Editar Perfil",
      "enable": "Ativar",
      "filters": "Filtros",
      "finalConsumer": "Consumidor Final",
      "giveNext": "Dar o seguinte",
      "giveUp": "Desistiu",
      "goBackOneMonth": "Recuar um mês",
      "goToConsultation": "Ir para Visita",
      "makeSale": "Venda",
      "markAsPaid": "Marcar como pago",
      "memos": "Emitir Notas de Crédito",
      "missed": "Faltou",
      "moveStock": "Mover Stock",
      "newPurchaseInvoiceLine": "Nova linha de compra",
      "newScheduling": "Agendar",
      "openConsultation": "Ver visita",
      "openShoppingCartDetails": "Ver carrinho",
      "openShoppingCartEdit": "Editar carrinho",
      "paid": "Pagou",
      "pay": "Pagar",
      "print": "imprimir",
      "receive": "Receber",
      "recordResults": "Registar Resultados",
      "reject": "Rejeitar",
      "removeAppointmentConsultation": "Tem a certeza que pretende apagar este agendamento?",
      "reschedule": "Reagendar",
      "rescheduled": "Reagendada",
      "roundPrices": "Arredondar",
      "schedule": "Agendar",
      "scheduled": "Agendada",
      "search": "Cliente ou Paciente",
      "searchItems": "Artigo",
      "selectCustomer": "Selecionar cliente",
      "selectItem": "Selecionar artigo",
      "selectPatient": "Selecionar paciente",
      "send": "Enviar",
      "showInvoice": "Gerar Fatura",
      "title": "Ações",
      "transferStock": "Transferir Stock",
      "undoTask": "Reabrir Tarefa",
      "view": "Vista",
      "viewItem": "Ver Artigo",
      "viewProfile": "Ver Perfil",
      "viewTask": "Ver Tarefa"
    },
    "adjustmentReasons": {
      "adjustmentReason": {
        "title": "Motivo"
      }
    },
    "alerts": {
      "adjustmentWithItemStock": "Este motivo é usado apenas para artigos que nunca tiveram um stock inicial",
      "appointmentCheckout": "Visita terminada",
      "appointmentGivenUp": "Cliente desistiu",
      "appointmentMissed": "Cliente faltou",
      "appointmentRescheduled": "Visita reagendada",
      "changesSaved": "Alterações guardadas",
      "creatingItem": "Aqui está a criar novos artigos",
      "customerWithoutNif": "Não pode pagar com os dados de faturação Cliente se o cliente selecionado não possui um NIF associado",
      "done": "Tem a certeza que deseja marcar esta tarefa como realizada?",
      "inProgress": "Em desenvolvimento...",
      "inactiveCustomer": "Este cliente está inactivo",
      "inactivePatient": "Este paciente está inactivo",
      "invalidItems": "Existem artigos inválidos",
      "leave": "Se sair todas as alterações serão perdidas",
      "missingOwner": "Não existe nenhum dono associado",
      "missingQuotation": "Este cliente não possui nenhum orçamento",
      "missingWeight": "Não existe nenhum peso registado",
      "mustExistPrice": "Uma linha tem de possuir um preço",
      "negativeStock": "A quantidade não pode ser menos de 0",
      "noDiagnostic": "Ainda não adicionou nenhum diagnóstico",
      "noDrugs": "Ainda não adicionou nenhum medicamento",
      "noMcd": "Ainda não adicionou nenhum exame",
      "noSpaces": "Este campo não aceita espaços ou acentos",
      "noSymptoms": "Ainda não adicionou nenhum sintoma",
      "noTreatmentPlan": "Ainda não adicionou nenhum plano de tratamento",
      "noWarehouseItems": "Este armazém não possui nenhum item",
      "referralCustomer": "Este cliente é referenciado",
      "referralPatient": "Este paciente é referenciado",
      "roomAllreadyWithAppointment": "Esta sala já possui uma marcação associada neste período",
      "sendEmail": "Tem a certeza que deseja enviar este email?",
      "simplifiedInvoiceWarning": "Uma fatura simplificada não pode ter um total superior a 100",
      "transferDatesMessage": "A data de carregamento tem de ser anterior à data de descarregamento",
      "weightUpdated": "O peso não se encontra atualizado, por favor atualize para avançar",
      "workerAllreadyWithAppointment": "Este trabalhador já tem uma marcação agendada para este período"
    },
    "application": {
      "loading": "A carregar"
    },
    "appointments": {
      "appointment": {
        "appointmentDate": "Data de agendamento",
        "appointmentType": "Tipo de Visita",
        "arrivalTime": "Chegada",
        "calls": "Chamada",
        "dateHasPassed": "Não pode agendar uma visita para uma data passada",
        "end": "Data de Fim",
        "endConsultation": "Fim da Visita",
        "exitTime": "Saída",
        "giveUpDate": "Data da Desistência",
        "giveUpReason": "Motivo da Desistência",
        "googleAppointmentId": "Calendário Google ID",
        "madeBy": "Realizada por:",
        "missedDate": "Data da Falta",
        "missedReason": "Motivo da Falta",
        "note": "Nota",
        "notice": "Lembrete",
        "noticeDate": "Data de Aviso",
        "observation": "Observações",
        "periodicInfo": "Informação do Lembrete",
        "receptionNotes": "Notas da Receção",
        "recurrenceInfo": "Recorrência",
        "resource": "Recurso",
        "scheduledBy": "Agendada por:",
        "scheduledFor": "Agendada para:",
        "start": "Data de Início",
        "status": "Estado",
        "subject": "Assunto",
        "timeConsultation": "Inicio da visita",
        "title": "Agendamento",
        "type": "Tipo",
        "weight": "Peso"
      },
      "checkin": "Checkin",
      "edit": {
        "title": "Editar Agendamento"
      },
      "filters": {
        "noFilters": "Não está a ver nenhuma coluna porque os filtros estão todos inativos, selecione o que deseja ver nos filtros",
        "title": "Filtros"
      },
      "messages": {
        "checkOut": "Check out efectuado com sucesso.",
        "deleted": "O agendamento foi apagado",
        "deletedConsultation": "Consulta apagada",
        "reschedule": "Por favor escolha uma nova data para a visita do(a): "
      },
      "new": {
        "associatePatient": "Associar Paciente",
        "changeCustomer": "Mudar cliente",
        "changePatientAndCustomer": "Mudar paciente e cliente",
        "changeSupplier": "Mudar fornecedor",
        "completeRecord": "Completar Ficha",
        "expectedDuration": "Tempo de Duração",
        "fast": {
          "title": "Agendamento Rápido"
        },
        "noDataToShow": "Sem dados para mostrar",
        "reason": "Motivo",
        "repetition": "Repetição",
        "title": "Novo Agendamento",
        "to": "até",
        "worker": "Selecionar Funcionário"
      },
      "noWorkerAssigned": "Sem funcionário associado",
      "status": {
        "checkinWithScheduling": "Checkin com marcação",
        "checkinWithoutscheduling": "Checkin sem marcação",
        "checkout": "Checkout",
        "finished": "Terminado",
        "getOut": "A sair",
        "givenUp": "Desistiu",
        "inCheckout": "Em Checkout",
        "inConsultation": "Em visita",
        "inProgress": "Em curso",
        "inVisit": "Em Visita",
        "missed": "Faltou",
        "paused": "Em pausa",
        "rescheduledPhrase": "Reagendada",
        "scheduled": "Agendado"
      },
      "title": "Agendamentos"
    },
    "approvals": {
      "approve": {
        "title": "Aprovar"
      },
      "messages": {
        "approve": "Tem a certeza que pretende aceitar esta ausência?",
        "reject": "Tem a certeza que pretende rejeitar esta ausência?"
      },
      "reject": {
        "title": "Rejeitar"
      },
      "status": {
        "approved": "Aprovado",
        "pending": "Pendente",
        "rejected": "Rejeitado",
        "title": "Estado"
      },
      "title": "Aprovar de Ausências"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescrição 1",
        "prescription2": "Prescrição 2",
        "round": "Volta",
        "sunglasses": "Oculos de Sol",
        "title": "Acessórios",
        "wayfarers": "Wayfarers"
      },
      "clothes": {
        "blazerShirt": "Blazer Shirt",
        "blazerSweater": "Blazer Sweater",
        "collarSweater": "Camisola de Colarinho",
        "graphicShirt": "Camisa Gráfica",
        "hoodie": "Hoodie",
        "overall": "Jardineiras",
        "shirtCrewNeck": "Camisa Gola",
        "shirtScoopNeck": "Camisa de Pescoço",
        "shirtVNeck": "Camisa em V",
        "title": "Roupas"
      },
      "colorFabric": {
        "black": "Preto",
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Cinzento 1",
        "gray2": "Cinzento 2",
        "heather": "Urze",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Laranja Pastel",
        "pastelRed": "Vermelho Pastel",
        "pastelYellow": "Amarelo Pastel",
        "pink": "Rosa",
        "red": "Vermelho",
        "title": "Cores de Tecido",
        "white": "Branco"
      },
      "eyebrow": {
        "angry": "Irritado",
        "angryNatural": "Irritado Natural",
        "default": "Default",
        "defaultNatural": "Default Natural",
        "flatNatural": "Liso Natural",
        "raisedExcited": "Animado Levantado",
        "raisedExcitedNatural": "Animado Levantado Natural",
        "sadConcerned": "Triste",
        "sadConcernedNatural": "Trite Natural",
        "title": "Sobrancelha",
        "unibrowNatural": "Monosobrancelha",
        "upDown": "Cima Baixo",
        "upDownNatural": "Cima Baixo Natural"
      },
      "eyes": {
        "close": "Fechados",
        "cry": "Chorar",
        "default": "Default",
        "dizzy": "Tonto",
        "eyeRoll": "Rolo de Olhos",
        "happy": "Feliz",
        "hearts": "Corações",
        "side": "Lado",
        "squint": "Estrabismo",
        "surprised": "Surpreso",
        "title": "Olhos",
        "wink": "Piscadela",
        "winkWacky": "Piscadela Maluca"
      },
      "hatColor": {
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Cinzento 1",
        "gray2": "Cinzento 2",
        "heather": "Urze",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Laranja Pastel",
        "pastelRed": "Vermelho Pastel",
        "pastelYellow": "Amarelo Pastel",
        "pink": "Rosa",
        "red": "Vermelho",
        "title": "Cor do Chapéu",
        "white": "Branco"
      },
      "mouth": {
        "concerned": "Preocupado",
        "default": "Default",
        "disbelief": "Descrença",
        "eating": "A comer",
        "grimace": "Careta",
        "sad": "Triste",
        "screamOpen": "Medo",
        "serious": "Sisudo",
        "smile": "Sorriso",
        "title": "Boca",
        "tongue": "Língua",
        "twinkle": "Cintilação",
        "vomit": "Vomito"
      },
      "skin": {
        "black": "Preto",
        "brown": "Castanho",
        "darkBrown": "Castanho Escuro",
        "light": "Mabro",
        "pale": "Pálido",
        "tanned": "Bronzeada",
        "title": "Pele",
        "yellow": "Amarela"
      },
      "top": {
        "ShortHairShortFlat": "Cabelo Curto Liso",
        "eyepatch": "Tapa-Olho",
        "hat": "Chapéu",
        "hijab": "Hijab",
        "longHairBigHair": "Cabelo Comprido",
        "longHairBob": "Cabelo Comprido Bob",
        "longHairBun": "Bolo De Cabelo Longo",
        "longHairCurly": "Cabelo Comprido Encaracolado",
        "longHairCurvy": "Cabelo Comprido Curvilínea",
        "longHairDreads": "Cabelo Comprido Dread",
        "longHairFrida": "Cabelo Comprido Frida",
        "longHairFro": "Cabelo Comprido Afro",
        "longHairFroBand": "Cabelo Comprido para Banda",
        "longHairMiaWallace": "Cabelo Comprido Mia Wallace",
        "longHairNotTooLong": "Cabelo Comprido Não Demais",
        "longHairShavedSides": "Cabelo Comprido e Lados Raspados",
        "longHairStraight1": "Cabelo Comprido em Linha Reta 1",
        "longHairStraight2": "Cabelo Comprido em Linha Reta 2",
        "longHairStraightStrand": "Cabelo Comprido em Linha Reta Strand",
        "shortHairDreads1": "Cabelo Curto Dreads 1",
        "shortHairDreads2": "Cabelo Curto Dreads 2",
        "shortHairFrizzle": "Cabelo Curto Frizzle",
        "shortHairShaggyMullet": "Cabelo Curto Desgrenhado",
        "shortHairShortCurly": "Cabelo Curto Curto Encaracolado",
        "shortHairShortRound": "Cabelo Curto",
        "shortHairShortWaved": "Cabelo Curto Ondulado",
        "shortHairSides": "Cabelo Curto dos Lados",
        "shortHairTheCaesar": "Cabelo Curto Caesar",
        "shortHairTheCaesarSidePart": "Cabelo Curto Caesar Parte Lateral",
        "title": "Cabeça",
        "turban": "Turbante",
        "winterHat1": "Chapéu de Inverno 1",
        "winterHat2": "Chapéu de Inverno 2",
        "winterHat3": "Chapéu de Inverno 3",
        "winterHat4": "Chapéu de Inverno 4"
      }
    },
    "boxes": {
      "create": "Criar Box",
      "edit": "Editar Box",
      "name": "Nome",
      "status": {
        "checkin": "Checkin",
        "checkout": "Checkout",
        "free": "Livre",
        "occupied": "Ocupada",
        "title": "Estado"
      },
      "title": "Boxes"
    },
    "brands": {
      "brand": {
        "name": "Nome",
        "title": "Marca"
      },
      "description": "Ver todas as marcas",
      "form": {
        "placeholder": {
          "name": "Nome"
        }
      },
      "messages": {
        "createdSuccess": "Marca criada com sucesso"
      },
      "new": {
        "title": "Nova Marca"
      },
      "title": "Marcas"
    },
    "breeds": {
      "0": "Indeterminada",
      "1": "Pointer",
      "10": "Border Terrier",
      "100": "Wire-haired Pointing Griffon Korthals",
      "101": "Picardy Spaniel",
      "102": "Clumber Spaniel",
      "103": "Curly Coated Retriever",
      "104": "Golden Retriever",
      "105": "Pastor de Brie",
      "106": "Pont-audemer Spaniel",
      "107": "Saint Germain Pointer",
      "108": "Dogue de Bordéus",
      "109": "Deutsch Langhaar",
      "11": "Bull Terrier",
      "110": "Large Munsterlander",
      "111": "German Short- Haired Pointing Dog",
      "112": "Setter irlandês",
      "113": "Flat Coated Retriever",
      "114": "Labrador retriever",
      "115": "Field Spaniel",
      "116": "Irish Water Spaniel",
      "117": "English Springer Spaniel",
      "118": "Welsh Springer Spaniel",
      "119": "Sussex Spaniel",
      "12": "Fox Terrier de Pêlo Liso",
      "120": "King Charles Spaniel",
      "121": "Smålandsstövare",
      "122": "Drever",
      "123": "Schillerstövare",
      "124": "Hamiltonstövare",
      "125": "French Pointing Dog - Gascogne Type",
      "126": "French Pointing Dog - Pyrenean Type",
      "127": "Swedish Lapphund",
      "128": "Cavalier King Charles",
      "129": "Cão de Montanha dos Pirenéus",
      "13": "English Toy Terrier (black &tan",
      "130": "Pyrenean Sheepdog - Smooth Faced",
      "131": "Irish Terrier",
      "132": "Boston Terrier",
      "133": "Long-haired Pyrenean Sheepdog",
      "134": "Slovakian Chuvach",
      "135": "Dobermann",
      "136": "Boxer",
      "137": "Leonberger",
      "138": "Leão da Rodésia",
      "139": "Rottweiler",
      "14": "Swedish Vallhund",
      "140": "Bulldog Inglês",
      "141": "Serbian Hound",
      "142": "Istrian Short-haired Hound",
      "143": "Istrian Wire-haired Hound",
      "144": "Dálmata",
      "145": "Posavatz Hound",
      "146": "Bosnian Broken-haired Hound - Called Barak",
      "147": "Pastor Escocês de pelo longo",
      "148": "Bullmastiff",
      "149": "Greyhound",
      "15": "Pastor Belga",
      "150": "English Foxhound",
      "151": "Irish Wolfhound",
      "152": "Beagle",
      "153": "Whippet",
      "154": "Basset Hound",
      "155": "Deerhound",
      "156": "Italian Spinone",
      "157": "German Shepherd Dog",
      "158": "Cocker Spaniel Americano",
      "159": "Dandie Dinmont Terrier",
      "16": "Antigo Cão de Pastor Inglês",
      "160": "Fox Terrier de Pêlo Cerdoso",
      "161": "Cão de Castro Laboreiro",
      "162": "Bouvier Des Ardennes",
      "163": "Caniche",
      "164": "Cão da Serra da Estrela",
      "165": "French Spaniel",
      "166": "Picardy Sheepdog",
      "167": "Ariege Pointing Dog",
      "168": "Bourbonnais Pointing Dog",
      "169": "Auvergne Pointer",
      "17": "Griffon Nivernais",
      "170": "Giant Schnauzer",
      "171": "Schnauzer",
      "172": "Miniature Schnauzer",
      "173": "German Pinscher",
      "174": "Pinscher",
      "175": "Affenpinscher",
      "176": "Perdigueiro Português",
      "177": "Sloughi",
      "178": "Finnish Lapphund",
      "179": "Hovawart",
      "18": "Briquet Griffon Vendeen",
      "180": "Bouvier da Flandres",
      "181": "Kromfohrländer",
      "182": "Borzoi",
      "183": "Bergamasco Shepherd Dog",
      "184": "Italian Volpino",
      "185": "Bolognese",
      "186": "Mastim Napolitano",
      "187": "Italian Rough-haired Segugio",
      "188": "Cirneco Dell'etna",
      "189": "Galguinho italiano",
      "19": "Ariegeois",
      "190": "Maremma And The Abruzzes Sheepdog",
      "191": "Braco Italiano",
      "192": "Norwegian Hound",
      "193": "Sabueso Espanhol",
      "194": "Chow Chow",
      "195": "Chin Japonês",
      "196": "Pekingese",
      "197": "Shih Tzu",
      "198": "Tibetan Terrier",
      "199": "Canadian Eskimo Dog",
      "2": "Setter Inglês",
      "20": "Gascon Saintongeois",
      "200": "Samoiedo",
      "201": "Hanoverian Scent Hound",
      "202": "Hellenic Hound",
      "203": "Bichon Friséé",
      "204": "Pudelpointer",
      "205": "Bavarian Mountain Scent Hound",
      "206": "Chihuahua",
      "207": "French Tricolour Hound",
      "208": "French White & Black Hound",
      "209": "Frisian Water Dog",
      "21": "Great Gascony Blue",
      "210": "Stabijhoun",
      "211": "Pastor Holândes",
      "212": "Drentsche Partridge Dog",
      "213": "Fila Brasileiro",
      "214": "Landseer (European Continental Type)",
      "215": "Lhasa Apso",
      "216": "Galgo Afegão",
      "217": "Serbian Tricolour Hound",
      "218": "Tibetan Mastiff",
      "219": "Tibetan Spaniel",
      "22": "Poitevin",
      "220": "Braco Alemão",
      "221": "Little Lion Dog",
      "222": "Xoloitzcuintle",
      "223": "Dogue Alemão",
      "224": "Australian Silky Terrier",
      "225": "Norwegian Buhund",
      "226": "Mudi",
      "227": "Braco Húngaro de Pêlo Cerdoso",
      "228": "Hungarian Greyhound",
      "229": "Hungarian Hound - Transylvanian Scent Hound",
      "23": "Billy",
      "230": "Norwegian Elkhound Grey",
      "231": "Malamute do Alasca",
      "232": "Slovakian Hound",
      "233": "Bohemian Wire-haired Pointing Griffon",
      "234": "Cesky Terrier",
      "235": "Atlas Mountain Dog (Aidi)",
      "236": "Pharaoh Hound",
      "237": "Majorca Mastiff",
      "238": "Bichon Havanês",
      "239": "Polish Lowland Sheepdog",
      "24": "Artois Hound",
      "240": "Tatra Shepherd Dog",
      "241": "Pug",
      "242": "Alpine Dachsbracke",
      "243": "Akita Inu",
      "244": "Shiba",
      "245": "Japanese Terrier",
      "246": "Tosa",
      "247": "Hokkaido",
      "248": "Japanese Spitz",
      "249": "Chesapeake Bay Retriever",
      "25": "Porcelaine",
      "250": "Mastim Inglês",
      "251": "Norwegian Lundehund",
      "252": "Hygen Hound",
      "253": "Halden Hound",
      "254": "Norwegian Elkhound Black",
      "255": "Saluki",
      "256": "Husky Siberiano",
      "257": "Bearded Collie",
      "258": "Norfolk Terrier",
      "259": "Canaan Dog",
      "26": "Small Blue Gascony",
      "260": "Greenland Dog",
      "261": "Norrbottenspitz",
      "262": "Croatian Shepherd Dog",
      "263": "Karst Shepherd Dog",
      "264": "Montenegrin Mountain Hound",
      "265": "Old Danish Pointing Dog",
      "266": "Grand Griffon Vendeen",
      "267": "Coton de Tuléar",
      "268": "Lapponian Herder",
      "269": "Galgo Espanhol",
      "27": "Blue Gascony Griffon",
      "270": "American Staffordshire Terrier",
      "271": "Australian Cattle Dog",
      "272": "Chinese Crested Dog",
      "273": "Icelandic Sheepdog",
      "274": "Beagle Harrier",
      "275": "Eurasier",
      "276": "Dogue Argentino",
      "277": "Australian Kelpie",
      "278": "Otterhound",
      "279": "Harrier",
      "28": "Grand Basset Griffon Vendeen",
      "280": "Pastor Escocês de pelo curto",
      "281": "Border Collie",
      "282": "Romagna Water Dog",
      "283": "Pastor Alemão",
      "284": "Black And Tan Coonhound",
      "285": "American Water Spaniel",
      "286": "Irish Glen Of Imaal Terrier",
      "287": "American Foxhound",
      "288": "Russian-european Laika",
      "289": "East Siberian Laika",
      "29": "Norman Artesien Basset",
      "290": "West Siberian Laika",
      "291": "Azawakh",
      "292": "Dutch Smoushond",
      "293": "Shar Pei",
      "294": "Peruvian Hairless Dog",
      "295": "Saarloos Wolfhond",
      "296": "Nova Scotia Duck Tolling Retriever",
      "297": "Dutch Schapendoes",
      "298": "Nederlandse Kooikerhondje",
      "299": "Broholmer",
      "3": "Kerry Blue Terrier",
      "30": "Blue Gascony Basset",
      "300": "French White And Orange Hound",
      "301": "Kai",
      "302": "Kishu",
      "303": "Shikoku",
      "304": "Wirehaired Slovakian Pointer",
      "305": "Majorca Shepherd Dog",
      "306": "Great Anglo-french Tricolour Hound",
      "307": "Great Anglo-french White And Black Hound",
      "308": "Great Anglo-french White & Orange Hound",
      "309": "Medium-sized Anglo-french Hound",
      "31": "Basset Fauve De Bretagne",
      "310": "South Russian Shepherd Dog",
      "311": "Russian Black Terrier",
      "312": "Caucasian Shepherd Dog",
      "313": "Canarian Warren Hound",
      "314": "Irish Red And White Setter",
      "315": "Kangal Shepherd Dog",
      "316": "Cão-lobo Checo",
      "317": "Polish Greyhound",
      "318": "Korea Jindo Dog",
      "319": "Central Asia Shepherd Dog",
      "32": "Cão De Agua Português",
      "320": "Cão de Água Espanhol",
      "321": "Italian Short-haired Segugio",
      "322": "Thai Ridgeback Dog",
      "323": "Parson Russell Terrier",
      "324": "Cão de Fila de São Miguel",
      "325": "Brazilian Terrier",
      "326": "Australian Shepherd",
      "327": "Italian Cane Corso",
      "328": "American Akita",
      "329": "Jack Russell Terrier",
      "33": "Welsh Corgi (Cardigan)",
      "330": "Dogo Canario",
      "331": "White Swiss Shepherd Dog",
      "332": "Taiwan Dog",
      "333": "Romanian Mioritic Shepherd Dog",
      "334": "Romanian Carpathian Shepherd Dog",
      "335": "Russian Toy",
      "336": "Cimarrón Uruguayo",
      "337": "Polish Hunting Dog",
      "338": "Bosnian And Herzegovinian - Croatian Shepherd Dog",
      "339": "Miniature Bull Terrier",
      "34": "Welsh Corgi (Pembroke)",
      "340": "Abissínio",
      "341": "Bobtail Americano",
      "342": "Bobtail Americano Pêlo Curto",
      "343": "Curl Americano",
      "344": "Curl Americano Pêlo Longo",
      "345": "Curl Americano Pêlo Curto",
      "346": "Americano Pêlo Curto",
      "347": "Wirehair Americano",
      "348": "Mist Australiano",
      "349": "Balinês",
      "35": "Irish Soft Coated Wheaten Terrier",
      "350": "Bengal",
      "351": "Bengal Pêlo Longo",
      "352": "Sagrado da Birmânia",
      "353": "Bombaim",
      "354": "Inglês",
      "355": "Inglês Pêlo Longo",
      "356": "Inglês Pêlo Curto",
      "357": "Burmês",
      "358": "Burmilla",
      "359": "Burmilla Pêlo Longo",
      "36": "Yugoslavian Shepherd Dog - Sharplanina",
      "360": "Chartreux",
      "361": "Chausie",
      "362": "Colorpoint Pêlo Curto",
      "363": "Cornish Rex",
      "364": "Cymric",
      "365": "Devon Rex",
      "366": "Donskoy",
      "367": "Mau Egípcio",
      "368": "Europeu",
      "369": "Burmese Europeu",
      "37": "Jämthund",
      "370": "Exótico",
      "371": "Exótico Pêlo Curto",
      "372": "Havana",
      "373": "German Rex",
      "374": "Himalaio",
      "375": "Javanese",
      "376": "Bobtail Japonês",
      "377": "Bobtail Japonês Pêlo Longo",
      "378": "Khao Manee",
      "379": "Korat",
      "38": "Basenji",
      "380": "Kurilian Bobtail",
      "381": "Kurilian Bobtail Pêlo Curto",
      "382": "Kurilian Bobtail Pêlo Longo",
      "383": "LaPerm",
      "384": "LaPerm Pêlo Curto",
      "385": "Lykoi",
      "386": "Maine Coon",
      "387": "Manx",
      "388": "Minuet",
      "389": "Minuet Pêlo Longo",
      "39": "Pastor de Beauce",
      "390": "Munchkin",
      "391": "Munchkin Pêlo Longo",
      "392": "Nebelung",
      "393": "Bosques da Noruega",
      "394": "Ocicat",
      "395": "Oriental",
      "396": "Oriental Pêlo Curto",
      "397": "Oriental Pêlo Longo",
      "398": "Persa",
      "399": "Peterbald",
      "4": "Cairn Terrier",
      "40": "Bouvier Bernois",
      "400": "Pixiebob",
      "401": "Pixiebob Pêlo Longo",
      "402": "Ragamuffin",
      "403": "Ragdoll",
      "404": "Azul Russo",
      "405": "Savannah",
      "406": "Scottish Fold",
      "407": "Scottish Fold Pêlo Longo",
      "408": "Scottish Fold Pêlo Liso",
      "409": "Scottish Fold Pêlo Liso Longo",
      "41": "Appenzell Cattle Dog",
      "410": "Selkirk Rex",
      "411": "Selkirk Rex Pêlo Longo",
      "412": "Seychellois",
      "413": "Seychellois Pêlo Curto",
      "414": "Seychellois Pêlo Longo",
      "415": "Siamês",
      "416": "Siberiano",
      "417": "Singapura",
      "418": "Snowshoe",
      "419": "Sokoke",
      "42": "Entlebuch Cattle Dog",
      "420": "Somali",
      "421": "Sphynx",
      "422": "Thai",
      "423": "Tonkinese",
      "424": "Toyger",
      "425": "Angora Turco",
      "426": "Van Turco",
      "428": "Teckel",
      "429": "Cão de Gado Transmontano",
      "43": "Karelian Bear Dog",
      "44": "Finnish Spitz",
      "45": "Terra Nova",
      "46": "Finnish Hound",
      "47": "Polish Hound",
      "48": "Komondor",
      "49": "Kuvasz",
      "5": "Cocker Spaniel Inglês",
      "50": "Puli",
      "51": "Pumi",
      "52": "Braco Húngaro de Pêlo Curto",
      "53": "Grand Bouvier Suíço",
      "54": "Swiss Hound",
      "55": "Small Swiss Hound",
      "56": "São Bernardo",
      "57": "Coarse-haired Styrian Hound",
      "58": "Austrian Black And Tan Hound",
      "59": "Austrian Pinscher",
      "6": "Setter Gordon",
      "60": "Bichon Maltês",
      "61": "Fawn Brittany Griffon",
      "62": "Petit Basset Griffon Vendeen",
      "63": "Tyrolean Hound",
      "64": "Lakeland Terrier",
      "65": "Manchester Terrier",
      "66": "Norwich Terrier",
      "67": "Scottish Terrier",
      "68": "Sealyham Terrier",
      "69": "Skye Terrier",
      "7": "Airedale Terrier",
      "70": "Staffordshire Bull Terrier",
      "71": "Continental Toy Spaniel",
      "72": "Welsh Terrier",
      "73": "Griffon Bruxellois",
      "74": "Griffon Belge",
      "75": "Petit Brabançon",
      "76": "Schipperke",
      "77": "Bloodhound",
      "78": "West Highland White Terrier",
      "79": "Yorkshire Terrier",
      "8": "Australian Terrier",
      "80": "Pastor Catalão",
      "81": "Pastor de Shetland",
      "82": "Podengo Ibicenco",
      "83": "Perdigueiro de Burgos",
      "84": "Mastim Espanhol",
      "85": "Mastim dos Pirenéus",
      "86": "Cão da Serra de Aires",
      "87": "Podengo Português",
      "88": "Épagneul Bretão",
      "89": "Rafeiro do Alentejo",
      "9": "Bedlington Terrier",
      "90": "German Spitz",
      "91": "Deutsch Drahthaar",
      "92": "Braco de Weimar",
      "93": "Westphalian Dachsbracke",
      "94": "Bulldog Francês",
      "95": "Kleiner Münsterländer",
      "96": "German Hunting Terrier",
      "97": "German Spaniel",
      "98": "French Water Dog",
      "99": "Blue Picardy Spaniel"
    },
    "bugReports": {
      "bugReport": {
        "message": "Mensagem",
        "subject": "Assunto",
        "title": "Reportar erro"
      },
      "title": "Reportar erros"
    },
    "bundle": {
      "newBundle": "Novo Bundle"
    },
    "bundles": {
      "bundle": {
        "title": "Bundle"
      },
      "messages": {
        "createdSuccess": "Bundle criado com sucesso"
      },
      "title": "Bundles"
    },
    "calendar": {
      "title": "Calendário"
    },
    "campaigns": {
      "campaign": {
        "audience": "Audiência",
        "audienceDetermined": "A audiência são todos os pacientes com periódicos agendados entre",
        "audienceEveryone": "A audiência são todos os clientes",
        "description": "Notas",
        "ended": "Fim",
        "errors": {
          "periodicsAlreadyCommunicated": "Os periódicos selecionados já foram comunicados"
        },
        "message": "Mensagem",
        "name": "Nome",
        "sendDate": "Data de envio",
        "species": "Espécies",
        "started": "Começo",
        "title": "Campanha"
      },
      "new": {
        "createdSuccess": "Campanha criada com sucesso",
        "title": "Nova campanha"
      },
      "sendConfirmation": "Tem a certeza que quer enviar enta campanha?",
      "title": "Campanhas"
    },
    "charges": {
      "title": "Cobranças"
    },
    "chores": {
      "chore": {
        "title": "Outras"
      },
      "title": "Outras"
    },
    "communicationErrors": {
      "communicationError": {
        "appointmentDate": "Data",
        "campaign": "Campanha",
        "customer": "Cliente",
        "patient": "Paciente",
        "phoneNumber": "Número",
        "started": "Data de envio",
        "text": "Texto",
        "title": "Erro de comunicação"
      },
      "title": "Erros de comunicação"
    },
    "communications": {
      "communication": {},
      "comunication": {
        "title": "Comunicação"
      }
    },
    "companies": {
      "details": {
        "disabled": "Esta entidade não tem um plano associado. Fale connosco para activar um tarifário de testes!"
      },
      "new": {
        "title": "Nova Empresa"
      }
    },
    "compoundProduct": {
      "newCompoundProduct": "Novo Artigo Composto",
      "title": "Artigo composto"
    },
    "compoundProducts": {
      "action": {
        "addPrice": "Clique aqui para atribuir um preço ao artigo",
        "dontUsePrice": "Clique aqui se não quiser usar este preço no artigo"
      },
      "messages": {
        "createdSuccess": "Artigo Composto criado com sucesso"
      }
    },
    "configurations": {
      "breeds": {
        "dangerous": "Periogoso",
        "description": "Ver todas as raças",
        "name": "Nome",
        "new": "Nova raça",
        "proportion": "Proporções",
        "title": "Raças"
      },
      "clinic": {
        "title": "Clínico",
        "visitTypes": {
          "description": "Ver todos os tipos de visita",
          "descriptionVisitTypes": "Tipo de visita",
          "name": "Nome",
          "new": "Novo tipo de visita",
          "parent": "Unidade mãe",
          "title": "Tipos de Visita"
        }
      },
      "configItems": {
        "acceptQuotation": "Aceitar orçamento",
        "acceptedQuotation": "Orçamento aceite",
        "enum": {
          "0": {
            "name": "Desparatização",
            "value": "0"
          },
          "1": {
            "name": "Monitorização",
            "value": "1"
          },
          "2": {
            "name": "Exames",
            "value": "2"
          },
          "3": {
            "name": "Tratamentos",
            "value": "3"
          },
          "4": {
            "name": "Vacina",
            "value": "4"
          }
        },
        "generatePDF": "Gerar PDF",
        "generatePayment": "Gerar Pagamento",
        "generateReceipt": "Gerar recibo",
        "generatedPDF": "PDF Gerado",
        "generatedReceipt": "Recibo gerado",
        "goToPos": "Ir para o POS",
        "rejectQuotation": "Rejeitar orçamento",
        "rejectedQuotation": "Orçamento rejeitado",
        "transformIntoOrder": "Transformar em encomenda",
        "transformedIntoOrder": "Transformado em encomenda"
      },
      "general": {
        "title": "Geral"
      },
      "item": {
        "brands": {
          "list": "Ver todas as marcas",
          "new": "Nova marca",
          "title": "Marcas"
        },
        "itemTypes": {
          "list": "Ver todos os tipos de artigos",
          "new": "Novo tipo de artigo",
          "title": "Tipos de Artigos"
        },
        "models": {
          "list": "Ver todos os modelos",
          "new": "Novo modelo",
          "title": "Modelos"
        },
        "unitDimensions": {
          "list": "Ver todas as dimensões de unidades",
          "new": "Nova dimensão de unidades",
          "title": "Dimensões de Unidades"
        },
        "units": {
          "list": "Ver todas as unidades",
          "new": "Nova unidade",
          "title": "Unidades"
        }
      },
      "organization": {
        "advanced": "Avançadas",
        "edit": "Editar da Organização",
        "featureUnavailable": "Para ter acesso tem de possuir o módulo {feature}",
        "name": "Nome da Organização",
        "receiptSlip": "Talão em recibo",
        "sender": "Nome de Remetente",
        "slip": "Talão em fatura",
        "title": "Organização"
      },
      "organizationCommunication": {
        "beforeSchedule": "Estas SMS serão envidas automaticamente no dia que antecede o agendamento.",
        "consultationText": "Texto de agendamento da consulta",
        "consultationTextTitle": "Olá! Informamos que o(a) @@pa tem consulta agendada para dia @@dd, pelas @@hh. Gratos pela preferência.",
        "customerBirthdayText": "Texto de aniversário do cliente",
        "customerBirthdayTextTitle": "Muitos parabéns!",
        "daysBefore": "Dias antes",
        "externalDewormingText": "Texto de desparasitação externo",
        "externalDewormingTextTitle": "Olá! Informamos que o(a) @@pa tem de realizar a desparasitação externa este mês. Gratos pela preferência.",
        "internalDewormingText": "Texto de desparasitação interno",
        "internalDewormingTextTitle": "Olá! Informamos que o(a) @@pa tem de realizar a desparasitação interna este mês. Gratos pela preferência.",
        "othersText": "Texto para outros",
        "othersTextTitle": "Olá! Informamos que o(a) @@pa tem periodico agendedado para este mês. Gratos pela preferência.",
        "patientBirthdayText": "Texto de aniversário de paciente",
        "patientBirthdayTextTitle": "Muitos parabéns!",
        "proceduresAppointmentText": "Texto de agendamento de procedimento",
        "proceduresAppointmentTextTitle": "Olá! Informamos que o(a) @@pa tem procedimento agendado para dia @@dd, pelas @@hh. Gratos pela preferência.",
        "sendHour": "Hora de envio",
        "sendMinute": "Minuto de envio",
        "surgeryAppointmentText": "Texto de agendamento de cirurgia",
        "surgeryAppointmentTextTitle": "Olá! Informamos que o(a) @@pa tem cirurgia agendada para dia @@dd, pelas @@hh. Recordamos que deverá fazer jejum. Gratos pela preferência.",
        "title": "Comunicação da organização",
        "vaccineAppointmentText": "Texto de agendamento de vacina",
        "vaccineAppointmentTextTitle": "Olá! Informamos que o(a) @@pa tem vacina agendado para dia @@dd, pelas @@hh. Gratos pela preferência.",
        "vaccineText": "Texto da vacina",
        "vaccineTextTitle": "Olá! Informamos que o(a) @@pa tem de realizar a vacina este mês. Gratos pela preferência.",
        "wellBeingAppointmentText": "Texto de agendamento de bem estar",
        "wellBeingAppointmentTextTitle": "Olá! Informamos que o(a) @@pa tem marcação agendada para dia @@dd, pelas @@hh. Gratos pela preferência."
      },
      "periodicTypes": {
        "description": "Ver todos os periódicos",
        "descriptionPeriodic": "Descrição",
        "name": "Nome",
        "new": "Novo tipo de periódico",
        "period": "Período",
        "reinforcementNumber": "Nº de reforços",
        "reinforcementPeriod": "Período de reforço",
        "title": "Tipos de Periódicos"
      },
      "species": {
        "description": "Ver todas as especíes",
        "name": "Nome",
        "new": "Nova especíe",
        "title": "Especíes"
      },
      "title": "Configurações"
    },
    "consultations": {
      "consultation": {
        "bathCutReport": "Relatório de Banho e Tosquia",
        "bathCutRequest": "Requisição de Banho e Tosquia",
        "buttons": {
          "backToConsultation": "Continuar Visita",
          "confirmation": "Tem mesmo a certeza que pretende terminar a visita?",
          "finishAndPay": "Terminar e pagar",
          "finishWithoutPay": "Terminar sem pagar",
          "finishedConsultation": "Terminar",
          "pausedConsultation": "Pausar Visita"
        },
        "cantSave": "Deve inserir uma data e hora válidas",
        "checkUpSeniorCat": "Relatório de Check-up Sénior Felino",
        "checkUpSeniorDog": "Relatório de Check-up Sénior Canino",
        "countTime": "Tempo",
        "creator": "Criador da visita",
        "debtConsent": "Declaração de Reconhecimento de Dívida e Plano de Pagamento",
        "declarationRGPD": "Consentimento para fins de RGPD",
        "dischargeLetter": "Carta de alta",
        "ecoReport": "Relatório Ecográfico",
        "electrocardiogramCat": "Electrocardiograma para Gato",
        "electrocardiogramDog": "Electrocardiograma para Cão",
        "euthanasia": "Termo de Eutanásia",
        "generalAnesthesia": "Termo de Anestesia Geral",
        "hospitalizationConsent": "Consentimento de hospitalização",
        "internmentDischarge": "Alta de Internamento",
        "labAnalysisReport": "Relatório de Análise Laboratorial",
        "responsabilityTermInternment": "Termo de Internamento",
        "responsabilityTermSurgery": "Termo de Cirugía",
        "sanityCertificate": "Atestado de Sanidade",
        "searchOptions": {
          "date": "Data"
        },
        "soap": {
          "anamnesis": "Anamnese",
          "assessment": "Avaliação",
          "description": "Descrição do Plano de Tratamento",
          "diagnosisDescription": "Descrição do Diagnóstico",
          "diagnostic": "Diagnóstico",
          "medication": "Medicação",
          "objective": "Objectivo",
          "observations": "Notas Internas",
          "physicalTest": {
            "abdomen": "Abdómen",
            "attitude": "Atitude",
            "bloodPressure": "P. Arterial",
            "capillaryRepletionTime": "TRC",
            "edit": {
              "title": "Editar Exame Físico"
            },
            "ganglia": "Gânglios",
            "heartRate": "Freq. Card.",
            "hydration": "Hidratação",
            "mucous": "Mucosas",
            "observation": "Observações do Exame Físico",
            "pain": "Dor",
            "parameters": "Parâmetros",
            "respiratoryFrequency": "Freq. Resp.",
            "temperature": "Temperatura",
            "title": "Exame Físico",
            "value": "Valor"
          },
          "prescription": {
            "delivered": "Em consulta",
            "prescribed": "Prescrever",
            "title": "Prescrição"
          },
          "subjective": "Subjetivo",
          "symptom": "Sinais Clínicos",
          "symptomDescription": "Descrição do Sinal Clínico",
          "title": "S.O.A.P.",
          "treatmentPlan": "Plano de Tratamento"
        },
        "surgeryDischarge": "Alta de Cirurgia",
        "surgeryReport": "Relatório de Cirurgia",
        "title": "Visita",
        "vet": "Veterinário",
        "visitDate": "Data da consulta",
        "workerObservation": "Observações na visita"
      },
      "messages": {
        "consultationFinished": "Visita terminada com sucesso",
        "consultationSaved": "Visita gravada com sucesso"
      },
      "title": "Visitas"
    },
    "creator": {
      "title": "Criado por"
    },
    "crud": {
      "associate": "Associar",
      "cancel": "Cancelar",
      "close": "Fechar",
      "create": "Criar",
      "createAndAdd": "Guardar e Criar Nova",
      "createSuccess": "Registo bem sucedido para",
      "created": "Criada",
      "delete": "Remover",
      "details": "Detalhes",
      "register": "Registar",
      "registered": "Registado",
      "revert": "Reverter",
      "save": "Gravar",
      "saved": "Gravado",
      "schedule": "Agendar",
      "update": "Editar",
      "updated": "Editado"
    },
    "current": {
      "amountOwed": "Montante em dívida",
      "title": "Conta corrente"
    },
    "customerFieldTypes": {
      "customerFieldType": {
        "editable": "Editável",
        "label": "Descritivo",
        "mandatory": "Obrigatório",
        "title": "Propriedade personalizada",
        "type": "Tipo",
        "types": {
          "boolean": "Sim/não",
          "date": "Data",
          "double": "Nr com virgula",
          "integer": "Número",
          "string": "texto"
        },
        "unique": "Único"
      },
      "title": "Propriedades personalizadas"
    },
    "customers": {
      "actions": {
        "disableCustomer": "Desativar cliente"
      },
      "customer": {
        "accumulatedPoints": "Pontos Acumulados",
        "address": "Morada",
        "addresses": "Moradas de Envio",
        "avatar": "Avatar",
        "balcon": "Banco",
        "barcode": "Código de barras",
        "birthdate": "Data de Nascimento",
        "building": "Edíficio",
        "cc": "Cartão Cidadão",
        "code": "Código",
        "comment": "Comentários",
        "contacts": {
          "authorizations": "Autorizações",
          "contact": "Contacto",
          "contactType": "Tipo de Contacto",
          "contacts": "Contactos",
          "email": "Email",
          "mobilePhone": "Telemóvel",
          "new": "Novo Contacto",
          "phone": "Telefone",
          "preferential": "Preferencial",
          "preferentialcontact": "Contacto Preferencial",
          "typesTranslation": {
            "1": "Newsletter",
            "2": "Periódicos",
            "3": "Promoções",
            "4": "Proíbido"
          }
        },
        "country": "País",
        "customerName": "Nome do Cliente",
        "customerSince": "Cliente desde",
        "customerTitle": "Título",
        "enable": "Autorizar",
        "errors": {
          "alreadyUsed": "Este cliente já foi utilizado em documentos e não pode ser editado",
          "noNumber": "Este cliente não possui um contacto associado"
        },
        "female": "Feminino",
        "generalDiscount": "Descontos Gerais",
        "headerDocuments": "Documentos",
        "iban": "IBAN",
        "irsRetention": "Rentenção IRS",
        "male": "Masculino",
        "name": "Nome",
        "newAddress": "Nova Morada",
        "nif": "NIF",
        "noAnimal": "Este cliente não tem animais associados",
        "origin": "Origem",
        "paymentTerm": "Prazo de Pagamento",
        "personalCharacteristics": "Características Pessoais",
        "preferedPaymentMethod": "Método de pagamento preferido",
        "price": "Preço",
        "profession": "Profissão",
        "reason": "Razão",
        "referencedBy": "Recomendado",
        "sex": "Sexo",
        "stickyNote": "Notas",
        "street": "Rua",
        "tariff": "Tarifa",
        "title": "Cliente",
        "town": "Cidade",
        "unknown": "Desconhecido",
        "usedPoints": "Pontos Usados",
        "validCC": "Validade do C.C.",
        "visitDetails": "Detalhes da Visita",
        "warningList": "Lista de Alertas",
        "zip": "Código Postal"
      },
      "disabled": "Inativo",
      "edit": {
        "title": "Editar Cliente"
      },
      "enabled": "Ativo",
      "form": {
        "allreadyExistsVat": "Este Nif já se encontra registado",
        "category": {
          "address": "Morada",
          "complementarDetails": "Informação Complementar",
          "contacts": "Contactos",
          "financialDetails": "Detalhes Financeiros",
          "personalInformation": "Informação Pessoal",
          "rpContacts": "Contactos Representante"
        },
        "invalidContact": "Este contacto não é válido",
        "invalidVat": "Um Nif deve ter 9 caracteres",
        "placeholder": {
          "addresses": "Moradas de Envio",
          "bancon": "Banco",
          "building": "Edifício",
          "cc": "Cartão Cidadão",
          "comment": "Comentários",
          "contact": "Contacto",
          "email": "Email",
          "generalDiscount": "Descontos Gerais",
          "iban": "IBAN",
          "mobilePhone": "Telemóvel",
          "name": "Exemplo: Pedro Silva",
          "nif": "NIF",
          "personalCharacteristics": "Características Pessoais",
          "phone": "Telefone",
          "profession": "Profissão",
          "stickyNote": "Notas",
          "street": "Rua",
          "town": "Cidade",
          "zip": "Código Postal"
        },
        "select": {
          "contact": "Selecione Tipo de Contacto",
          "country": "Selecione o País",
          "origin": "Selecione Origem",
          "paymentTerm": "Prazo de Pagamento",
          "price": "Selecione Preço",
          "reason": "Selecione Razão",
          "referencedBy": "escolher",
          "removeReferencedBy": "Apagar recomendação"
        },
        "vatAnotherCountry": "Este Nif não é válido"
      },
      "inactiveCustomers": "Clientes inactivos",
      "merge": {
        "crud": "Unir",
        "messages": {
          "confirm": "Tem a certeza que pretende unir estes clientes?",
          "error": "Ocorreu um erro ao unir os clientes",
          "errorSame": "Os clientes são iguais",
          "info": "Esta ação não pode ser revertida!",
          "success": "Clientes unidos com sucesso"
        },
        "title": "Unir Clientes"
      },
      "messages": {
        "createdSuccess": "Cliente criado com sucesso",
        "disable": "Tem a certeza que pretende desativar este cliente?",
        "hasDebt": "Este cliente tem dívidas, deseja continuar?"
      },
      "new": {
        "saveAndAssociatePatient": "Criar » paciente",
        "title": "Novo Cliente"
      },
      "title": "Clientes"
    },
    "dayWeek": {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    },
    "declarations": {
      "prescription": "Prescrição"
    },
    "documents": {
      "accountingAddress": "Endereço de Faturação",
      "accountingName": "Nome de Faturação",
      "accountingTaxId": "NIF",
      "annul": "Anular",
      "annulDocument": "Anular documento",
      "annulReason": "Razão para o anulamento",
      "date": "Data de Pagamento",
      "document": {
        "title": "Documento"
      },
      "edit": {
        "title": "Editar Documento"
      },
      "financialAccount": "Caixa",
      "internalNotes": "Nota interna",
      "invoiceNotes": "Recado de rodapé",
      "payableAmount": "Montante",
      "paymentMethod": "Método de Pagamento",
      "quotationNotes": "Recado de rodapé",
      "reference": "Referência",
      "title": "Documentos",
      "warning": "Tem a certeza de que pretende anular o registo do documento"
    },
    "drugs": {
      "drug": {
        "concentration": "Concentração",
        "delete": "Apagar Medicamento",
        "description": "Descrição",
        "dosageTotal": "Dose total",
        "edit": "Editar Medicamento",
        "name": "Nome",
        "pills": "Comprimidos",
        "quantity": "Quantidade",
        "title": "Medicamento",
        "via": "Via",
        "view": "Ver Detalhes do Medicamento",
        "volumeTotal": "Volume total"
      },
      "edit": {
        "addMoreTypes": "Não consegue encontrar o tipo de que precisa? Envie um email para",
        "nameNotNull": "Um medicamento tem de ter um tipo",
        "selectDrugType": "Tipo de Medicamento",
        "title": "Editar Medicamento"
      },
      "messages": {
        "createdSuccess": "Medicamento criado com sucesso"
      },
      "new": {
        "addMoreTypes": "Não consegue encontrar o tipo de que precisa? Envie um email para",
        "nameNotNull": "Um medicamento tem de ter um tipo",
        "selectDrugType": "Tipo de Medicamento",
        "title": "Novo Medicamento"
      },
      "title": "Medicamentos"
    },
    "emailValidations": {
      "title": "Validação de email",
      "validated": "O seu email foi validado com sucesso, já pode fazer login"
    },
    "enterprises": {
      "edit": {
        "title": "Editar Remetente"
      },
      "messages": {
        "title": "Remetente criado com sucesso"
      },
      "new": {
        "title": "Novo Remetente"
      },
      "title": "Remetente"
    },
    "enums": {
      "chronos": {
        "date": "data",
        "dateUpper": "Data",
        "day": "dia",
        "dayUpper": "Dia",
        "days": "dias",
        "hour": "hora",
        "hourUpper": "Hora",
        "hours": "horas",
        "hoursUpper": "Horas",
        "month": "mês",
        "monthUpper": "Mês",
        "months": "meses",
        "today": "hoje",
        "todayUpper": "Hoje",
        "waitingTime": "tempo de espera",
        "waitingTimeUpper": "Tempo de Espera",
        "week": "semana",
        "weekUpper": "Semana",
        "year": "ano",
        "yearUpper": "Ano",
        "years": "anos"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "Emiratos Árabes Unidos",
        "AF": "Afeganistão",
        "AG": "Antígua e Barbuda",
        "AI": "Anguila",
        "AL": "Albânia",
        "AM": "Arménia",
        "AN": "Antilhas Holandesas",
        "AO": "Angola",
        "AQ": "Antárctica",
        "AR": "Argentina",
        "AS": "Samoa Americana",
        "AT": "Áustria",
        "AU": "Austrália",
        "AW": "Aruba",
        "AZ": "Azerbaijão",
        "BA": "Bósnia e Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgária",
        "BH": "Barém",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolívia",
        "BR": "Brasil",
        "BS": "Bahamas",
        "BT": "Butão",
        "BV": "Ilhas Bouvet",
        "BW": "Botswana",
        "BY": "Bielorrússia",
        "BZ": "Belize",
        "CA": "Canadá",
        "CC": "Ilhas Cocos (Keeling)",
        "CD": "Congo (República Democrático do)",
        "CF": "Centro-Africana (República)",
        "CG": "Congo",
        "CH": "Suíça",
        "CI": "Costa do Marfim",
        "CK": "Ilhas Cook",
        "CL": "Chile",
        "CM": "Camarões",
        "CN": "China",
        "CO": "Colômbia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cabo Verde",
        "CX": "Ilhas Christmas",
        "CY": "Chipre",
        "CZ": "República Checa",
        "DE": "Alemanha",
        "DJ": "Jibuti",
        "DK": "Dinamarca",
        "DM": "Domínica",
        "DO": "República Dominicana",
        "DZ": "Argélia",
        "EC": "Equador",
        "EE": "Estónia",
        "EG": "Egipto",
        "EH": "Sara Ocidental",
        "ER": "Eritreia",
        "ES": "Espanha",
        "ET": "Etiópia",
        "FI": "Finlândia",
        "FJ": "Ilhas Fiji",
        "FK": "Ilhas Falkland (Malvinas)",
        "FM": "Micronésia (Estados Federados da)",
        "FO": "Ilhas Faroé",
        "FR": "França",
        "GA": "Gabão",
        "GB": "Reino Unido",
        "GD": "Granada",
        "GE": "Geórgia",
        "GF": "Guiana Francesa",
        "GH": "Gana",
        "GI": "Gibraltar",
        "GL": "Gronelândia",
        "GM": "Gâmbia",
        "GN": "Guiné",
        "GP": "Guadalupe",
        "GQ": "Guiné Equatorial",
        "GR": "Grécia",
        "GS": "Geórgia do Sul e Ilhas Sandwich",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guiné-Bissau",
        "GY": "Guiana",
        "HK": "Hong Kong",
        "HM": "Ilhas Heard e Ilhas McDonald",
        "HN": "Honduras",
        "HR": "Croácia",
        "HT": "Haiti",
        "HU": "Hungria",
        "ID": "Indonésia",
        "IE": "Irlanda",
        "IL": "Israel",
        "IN": "Índia",
        "IO": "Território Britânico do Oceano Índico",
        "IQ": "Iraque",
        "IR": "Irão (República Islâmica)",
        "IS": "Islândia",
        "IT": "Itália",
        "JM": "Jamaica",
        "JO": "Jordânia",
        "JP": "Japão",
        "KE": "Kenya",
        "KG": "Quirguizistão",
        "KH": "Camboja",
        "KI": "Kiribati",
        "KM": "Comores",
        "KN": "São Cristóvão e Nevis",
        "KP": "Coreia (República Popular Democrática da)",
        "KR": "Coreia (República da)",
        "KW": "Kuwait",
        "KY": "Ilhas Caimão",
        "KZ": "Cazaquistão",
        "LA": "Laos (República Popular Democrática do)",
        "LB": "Líbano",
        "LC": "Santa Lúcia",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Libéria",
        "LS": "Lesoto",
        "LT": "Lituânia",
        "LU": "Luxemburgo",
        "LV": "Letónia",
        "LY": "Líbia (Jamahiriya Árabe da)",
        "MA": "Marrocos",
        "MC": "Mónaco",
        "MD": "Moldova (República de)",
        "MG": "Madagáscar",
        "MH": "Ilhas Marshall",
        "MK": "Macedónia (Antiga República Jugoslava da)",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongólia",
        "MO": "Macau",
        "MP": "Ilhas Marianas do Norte",
        "MQ": "Martinica",
        "MR": "Mauritânia",
        "MS": "Monserrate",
        "MT": "Malta",
        "MU": "Maurícias",
        "MV": "Maldivas",
        "MW": "Malawi",
        "MX": "México",
        "MY": "Malásia",
        "MZ": "Moçambique",
        "NA": "Namíbia",
        "NC": "Nova Caledónia",
        "NE": "Niger",
        "NF": "Ilhas Norfolk",
        "NG": "Nigéria",
        "NI": "Nicarágua",
        "NL": "Países Baixos",
        "NO": "Noruega",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nova Zelândia",
        "OM": "Omã",
        "PA": "Panamá",
        "PE": "Peru",
        "PF": "Polinésia Francesa",
        "PG": "Papuásia-Nova Guiné",
        "PH": "Filipinas",
        "PK": "Paquistão",
        "PL": "Polónia",
        "PM": "São Pedro e Miquelon",
        "PN": "Pitcairn",
        "PR": "Porto Rico",
        "PS": "Território Palestiniano Ocupado",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguai",
        "QA": "Catar",
        "RE": "Reunião",
        "RO": "Roménia",
        "RU": "Rússia (Federação da)",
        "RW": "Ruanda",
        "SA": "Arábia Saudita",
        "SB": "Ilhas Salomão",
        "SC": "Seychelles",
        "SD": "Sudão",
        "SE": "Suécia",
        "SG": "Singapura",
        "SH": "Santa Helena",
        "SI": "Eslovénia",
        "SJ": "Svålbard e a Ilha de Jan Mayen",
        "SK": "Eslovaca (República)",
        "SL": "Serra Leoa",
        "SM": "São Marino",
        "SN": "Senegal",
        "SO": "Somália",
        "SR": "Suriname",
        "ST": "São Tomé e Príncipe",
        "SV": "El Salvador",
        "SY": "Síria (República Árabe da)",
        "SZ": "Suazilândia",
        "TC": "Turcos e Caicos (Ilhas)",
        "TD": "Chade",
        "TF": "Territórios Franceses do Sul",
        "TG": "Togo",
        "TH": "Tailândia",
        "TJ": "Tajiquistão",
        "TK": "Tokelau",
        "TM": "Turquemenistão",
        "TN": "Tunísia",
        "TO": "Tonga",
        "TP": "Timor Leste",
        "TR": "Turquia",
        "TT": "Trindade e Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwan (Província da China)",
        "TZ": "Tanzânia, República Unida da",
        "UA": "Ucrânia",
        "UG": "Uganda",
        "UM": "Ilhas Menores Distantes dos Estados Unidos",
        "US": "Estados Unidos",
        "UY": "Uruguai",
        "UZ": "Usbequistão",
        "VA": "Santa Sé (Cidade Estado do Vaticano)",
        "VC": "São Vicente e Granadinas",
        "VE": "Venezuela",
        "VG": "Ilhas Virgens (britânicas)",
        "VI": "Ilhas Virgens (Estados Unidos)",
        "VN": "Vietname",
        "VU": "Vanuatu",
        "WF": "Wallis e Futuna (Ilha)",
        "WS": "Samoa",
        "YE": "Iémen",
        "YT": "Mayotte",
        "YU": "Jugoslávia",
        "ZA": "África do Sul",
        "ZM": "Zâmbia",
        "ZW": "Zimbabwe"
      },
      "currencies": {
        "$": "Dollar",
        "EUR": "Euro",
        "GBP": "Libra",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "Espanha +354",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Feminino"
        },
        "male": {
          "initial": "M",
          "name": "Masculino"
        }
      }
    },
    "equipmentParameterTypes": {
      "code": "Code",
      "description": "Descrição",
      "new": {
        "title": "Novos tipo de parâmetros de equipamento"
      },
      "title": "Tipos de parâmetros de equipamento"
    },
    "equipmentParameters": {
      "maxVal": "Valor máximo",
      "minVal": "Valor mínimo",
      "new": {
        "title": "Novo parâmetro"
      },
      "title": "Parametros",
      "type": "Tipos de parâmetro"
    },
    "equipmentTypes": {
      "code": "Code",
      "description": "Descrição",
      "new": {
        "title": "Novo tipo de equipamento"
      },
      "title": "Tipos de equipamento"
    },
    "equipments": {
      "new": {
        "title": "Novo equipamento"
      },
      "title": "Equipamento",
      "type": "Tipo de equipamento"
    },
    "error": {
      "adapter": {
        "forbidden": "Não tem permissões para realizar esta acção"
      },
      "noInternet": "Não conseguimos encontrar uma ligação à internet",
      "noPermission": "Você não tem permissão para aceder a esta página!",
      "systemDown": "O sistema encontra-se em baixo",
      "unexistingBreed": "Tem que selecionar uma raça da lista"
    },
    "events": {
      "edit": {
        "title": "Editar Casos"
      },
      "messages": {
        "title": "Caso criado com sucesso"
      },
      "new": {
        "title": "Novos Casos"
      },
      "title": "Casos"
    },
    "exams": {
      "all": "Todos",
      "delected": "Eliminar",
      "doneDate": "Entrega",
      "edit": {
        "title": "Editar Exame"
      },
      "exam": {
        "description": "Descrição",
        "externalLab": "Laboratório externo",
        "name": "Nome",
        "notes": "Resultado",
        "started": "Data",
        "title": "Exame",
        "view": "Ver Exame"
      },
      "examTypes": {
        "exam_abdominocentesis": "Abdominocentese",
        "exam_bio": "Bioquímicas",
        "exam_biopsy": "Biópsia",
        "exam_bloodCount": "Hemograma",
        "exam_cat": "TAC",
        "exam_cystocentesis": "Cistocentese",
        "exam_echocardiogram": "Ecocardiograma",
        "exam_fnac": "CAAF",
        "exam_ionogram": "Ionograma",
        "exam_other": "Outro",
        "exam_pleuralFluidAnalysis": "Análise líquido pleural",
        "exam_smear": "Esfregaço",
        "exam_ultrasound": "Ecografia",
        "exam_urethralCatheterization": "Algaliação",
        "exam_xRay": "Raio X",
        "title": "Tipo"
      },
      "held": "Realizado em",
      "messages": {
        "createdSuccess": "Exame criado com sucesso"
      },
      "new": {
        "addMoreTypes": "Não consegue encontrar o tipo de exame que pretende? Mande um email para",
        "nameNotNull": "O exame tem que ter um tipo",
        "selectExamType": "Tipo de Exame",
        "title": "Novo Exame"
      },
      "observations": "Notas",
      "parameters": {
        "exam_bio_alb": "Albumina",
        "exam_bio_alp": "Fosfatase Alcalina",
        "exam_bio_amyl": "Amilase",
        "exam_bio_bun": "Ureia",
        "exam_bio_ca": "Cálcio",
        "exam_bio_cl": "Cloro",
        "exam_bio_cpk": "Creatinina Quinase",
        "exam_bio_cre": "Creatinina",
        "exam_bio_ggt": "Gama Glutamil Transferase",
        "exam_bio_glu": "Glucose",
        "exam_bio_k": "Potássio",
        "exam_bio_lap": "LAP",
        "exam_bio_ldh": "Lactato Desidrogenase",
        "exam_bio_lip": "Lipase",
        "exam_bio_mg": "Magnésio",
        "exam_bio_na": "Sódio",
        "exam_bio_nh3": "Amónia",
        "exam_bio_tbil": "Bilirubina Total",
        "exam_bio_tcho": "Colesterol Total",
        "exam_bio_tg": "Triglicéridos",
        "exam_bio_tp": "Proteínas Totais",
        "exam_bio_ua": "Acido Úrico",
        "title": "Parâmetros"
      },
      "putResults": "Colocar Resultados",
      "requestDate": "Pedido",
      "results": "Escrever resultados",
      "status": {
        "done": "Realizado",
        "pending": "Pendente",
        "title": "Estado"
      },
      "title": "Exames"
    },
    "families": {
      "family": {
        "children": "Sub-Unidades",
        "familyType": "Tipo de Família",
        "name": "Nome",
        "parent": "Unidade Mãe",
        "title": "Família"
      },
      "form": {
        "placeholder": {
          "children": "Sub-Unidades",
          "familyType": "Tipo de Família",
          "name": "Nome",
          "parent": "Unidade Mãe"
        }
      },
      "title": "Famílias"
    },
    "files": {
      "title": "Ficheiros"
    },
    "filters": {
      "all": "Todos",
      "bundles": "Bundles",
      "byFamily": "Por família",
      "byUser": "Por user",
      "cashTotal": "Numerário",
      "checkTotal": "Cheques",
      "compoundProducts": "Artigos Compostos",
      "creditTotal": "Cartões eletrónicos",
      "creditors": "Credores",
      "debtors": "Devedores",
      "deliveries": "Guias de Transporte",
      "directDebitTotal": "Débitos",
      "invoices": "Faturas",
      "invoicesTotal": "Total em Facturas",
      "memos": "Notas de crédito",
      "memosTotal": "Total em Notas de Crédito",
      "none": "Nenhum",
      "orders": "Encomendas",
      "payments": "Pagamentos",
      "pending": "Pendente",
      "quotations": "Orçamentos",
      "receipts": "Recibos",
      "requests": "Pedidos",
      "total": "Total",
      "totalFA": "Faturas",
      "totalFR": "Faturas recibo",
      "totalFS": "Faturas simplificadas",
      "totalPayment": "Total em pagamentos",
      "totalReceipt": "Recibos",
      "transferTotal": "Transferências"
    },
    "financialAccounts": {
      "actions": {
        "close": "Fecho",
        "open": "Abertura"
      },
      "automaticManagment": "Não tem caixas em gestão manual",
      "bankAccount": "Conta bancária",
      "choose": "Escolha um caixa",
      "close": "Fecho",
      "description": "Ver todas caixas",
      "edit": {
        "title": "Editar Caixa"
      },
      "financialAccount": {
        "accountNumber": "Nr da conta",
        "billed": "Faturado",
        "currency": "Moeda",
        "description": "Descrição",
        "financialAccountFund": "Fundo de Caixa",
        "history": "Histórico",
        "iban": "IBAN",
        "institution": "Instituição Bancária",
        "isManual": "Gestão Manual",
        "name": "Nome",
        "received": "Recebido",
        "swift": "SWIFT",
        "title": "Caixa",
        "total": "Movimentado",
        "totals": "Totais",
        "type": "Tipo"
      },
      "messages": {
        "allreadyClosed": "Caixa já fechada",
        "allreadyOpen": "Caixa já está aberta",
        "createdSuccess": "Caixa criada com sucesso",
        "editedSuccess": "Caixa editada com sucesso",
        "noFinancialAccounts": "Falta abrir Caixa",
        "noFinancialAccountsTypeBankAccount": "Não possui nenhuma caixa do tipo «Conta Bancária» criada",
        "noFinancialAccountsTypeRegister": "Não possui nenhuma caixa do tipo «caixa» criada"
      },
      "new": {
        "title": "Nova Caixa"
      },
      "purpose": "Aqui pode fazer abertura e fecho de caixa",
      "register": "Caixa",
      "seeHistory": "Para consultar o histórico aceda ao módulo de estatísticas",
      "title": "Caixas"
    },
    "footer": {
      "rights": "Todos os direitos reservados",
      "toggle": "Alternar vista"
    },
    "form": {
      "administrative": "Administração",
      "chooseOne": " -- escolha um --",
      "extra": "Extra",
      "general": "Geral",
      "health": "Saúde",
      "look": "Aspeto",
      "required": "Este campo é obrigatório"
    },
    "getChrome": {
      "message": "Esta aplicação apenas funciona com o Google Chrome."
    },
    "header": {
      "logout": "Sair"
    },
    "help": {
      "faq": {
        "last-rev-date": "29 de Junho 2016",
        "link": "FAQ",
        "title": "Perguntas Frequentes"
      },
      "manual": {
        "last-rev-date": "23 de Junho 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Ajuda"
    },
    "howTo": {
      "steps": {
        "createCampaign": "Crie uma campanha",
        "goToPeriodics": "Aceda ao menu dos periódicos",
        "makeAvailable": "Contacte-nos para ativar este sistema",
        "startSendingMessages": "Os SMS's serão enviados automaticamente na data escolhida"
      },
      "text": "Temos agora agora uma funcionalidade de envio de SMS",
      "title": "Ver Mais"
    },
    "icons": {
      "absence": "hand-o-down",
      "absences": "hand-o-down",
      "action": "check-square-o",
      "actions": "check-square-o",
      "alert": "exclamation-triangle",
      "annul": "ban",
      "appointment": "calendar-o",
      "appointmentDone": "calendar-check-o",
      "appointmentNew": "calendar-plus-o",
      "appointments": "calendar-o",
      "at": "usd",
      "avatar": "image",
      "avatars": "image",
      "boxes": "home",
      "calendar": "calendar-o",
      "calendarMenu": "hourglass-start",
      "calendarPlus": "calendar-plus",
      "campaign": "bullhorn",
      "campaigns": "bullhorn",
      "caretDown": "caret-down",
      "caretUp": "caret-up",
      "charge": "credit-card",
      "charges": "credit-card",
      "check": "check",
      "checkout": "sign-out",
      "clinical": "stethoscope",
      "clinicalMenu": "stethoscope",
      "comment": "comment-o",
      "compoundProduct": "tags",
      "configs": "cog",
      "consultation": "medkit",
      "consultations": "medkit",
      "crm": "address-book-o",
      "currency": "€",
      "currentAccount": "folder-o",
      "customer": "user",
      "customers": "user",
      "delete": "trash",
      "deleteO": "trash-o",
      "detail": "eye",
      "disable": "toggle-off",
      "dischargeLetter": "envelope-square",
      "document": "file-text-o",
      "documentDetails": "file-pdf-o",
      "documents": "folder-o",
      "download": "download",
      "edit": "pencil",
      "email": "envelope-o",
      "enable": "toggle-on",
      "error": "warning",
      "events": "th-large",
      "exam": "eyedropper",
      "examion": "eyedropper",
      "exams": "eyedropper",
      "eye": "eye",
      "female": "venus",
      "file": "file-o",
      "files": "files-o",
      "filter": "filter",
      "financialAccounts": "inbox",
      "financialAccountsClosed": "stop-circle-o",
      "financialAccountsOpen": "play-circle-o",
      "flag": "flag",
      "genderless": "genderless",
      "generatePayment": "file-text",
      "generateReceipt": "file-text-o",
      "giveNext": "retweet",
      "goTo": "arrow-right",
      "group": "sitemap",
      "hermaphrodite": "intersex",
      "hotel": "hotel",
      "houseCalls": "home",
      "hr": "id-badge",
      "internment": "h-square",
      "internments": "h-square",
      "inventory": "archive",
      "invites": "user-plus",
      "invoice": "file-o",
      "item": "tag",
      "itemType": "star-o",
      "laboratories": "flask",
      "laboratory": "flask",
      "liaisons": "hand-o-right",
      "listings": "list-ol",
      "load": "spinner fa-spin",
      "male": "mars",
      "manufacturers": "institution",
      "mobilePhone": "mobile",
      "moveStock": "arrows-v",
      "neutral": "neuter",
      "notes": "sticky-note",
      "order": "shopping-cart",
      "organization": "hospital-o",
      "patient": "paw",
      "patients": "paw",
      "pencilSquare": "pencil",
      "periodic": "history",
      "periodics": "history",
      "phone": "phone",
      "phonecall": "phone",
      "plusCircle": "plus-circle",
      "pointOfSales": "money",
      "prescription": "file-text-o",
      "processing": "hourglass-start",
      "procurement": "handshake-o",
      "purchaseInvoice": "file",
      "purchaseInvoices": "file",
      "purchaseOrder": "cart-arrow-down",
      "purchaseOrders": "cart-arrow-down",
      "purchaseRequest": "cart-plus",
      "purchaseRequests": "cart-plus",
      "purchases": "file",
      "referral": "exchange",
      "referrals": "exchange",
      "reject": "times",
      "remove": "times",
      "reward": "certificate",
      "rewards": "certificate",
      "room": "map-marker",
      "rooms": "map-marker",
      "saft": "file-zip-o",
      "sales": "shopping-basket",
      "salesMenu": "shopping-basket",
      "save": "floppy-o",
      "search": "search",
      "secondTimeEmission": "file-pdf-o",
      "send": "paper-plane",
      "sendCampaign": "mail-forward",
      "sheltereds": "paw",
      "shift": "hand-o-up",
      "shifts": "hand-o-up",
      "shoppingCart": "shopping-cart",
      "simpleView": "tv",
      "sms": "comments",
      "stats": "line-chart",
      "stays": "ticket",
      "study": "x-ray",
      "supplier": "industry",
      "supplierItem": "truck",
      "supplierItems": "truck",
      "suppliers": "industry",
      "task": "tasks",
      "tasks": "tasks",
      "taxes": "bank",
      "template": "file-word-o",
      "templates": "file-word-o",
      "thumbsUp": "thumbs-up",
      "timesCircle": "times-circle",
      "transferStock": "exchange",
      "undetermined": "question",
      "view": "columns",
      "visit": "suitcase",
      "waitingRoom": "sign-in",
      "warehouse": "cubes",
      "warehouses": "cubes",
      "weight": "balance-scale",
      "worker": "user-md",
      "workers": "users"
    },
    "inbounds": {
      "customer": "Cliente",
      "facebook": "Facebook",
      "geolocation": "Geolocalização",
      "partner": "Sócio",
      "publicity": "Publicidade",
      "reference": "Referência",
      "unknown": "Desconhecido",
      "website": "Website"
    },
    "index": {
      "addInternments": {
        "description": "Criar ou Pesquisar cliente, preencher os dados do animal e internar.",
        "title": "Internamentos"
      },
      "associateCollaborators": {
        "description": "Associar colaboradores com diferentes funções.",
        "title": "Colaboradores"
      },
      "comments": "Em caso de dúvidas ou se tiver algum comentário, por favor contacte-nos",
      "createAccount": {
        "description": "Preencher os seus dados e fazer o registo da conta.",
        "title": "Criar Conta"
      },
      "createHospital": {
        "description": "Adicionar os dados do hospital e criar.",
        "title": "Registar Hospital"
      },
      "welcome.subtitle": "by {corp}"
    },
    "initials": {
      "catheterLeftForelimb": {
        "description": "Membro anterior esquerdo",
        "name": "MAE"
      },
      "catheterLeftHindlimb": {
        "description": "Membro posterior esquerdo",
        "name": "MPE"
      },
      "catheterRightForelimb": {
        "description": "Membro anterior direito",
        "name": "MAD"
      },
      "catheterRightHindlimb": {
        "description": "Membro posterior direito",
        "name": "MPD"
      },
      "didntEat": {
        "description": "Não comeu",
        "name": "NC"
      },
      "faecesDigestedBlood": {
        "description": "Fezes sangue digerido",
        "name": "FSD"
      },
      "faecesHard": {
        "description": "Fezes duras",
        "name": "FD"
      },
      "faecesHardWithBlood": {
        "description": "Fezes duras com sangue",
        "name": "FDS"
      },
      "faecesLiquid": {
        "description": "Fezes líquidas",
        "name": "FL"
      },
      "faecesLiquidWithBlood": {
        "description": "Fezes líquidas com sangue",
        "name": "FLS"
      },
      "faecesLivingBlood": {
        "description": "Fezes sangue vivo",
        "name": "FSV"
      },
      "faecesNormal": {
        "description": "Fezes normais",
        "name": "FN"
      },
      "faecesSoft": {
        "description": "Fezes moles",
        "name": "FM"
      },
      "faecesSoftMoulded": {
        "description": "Fezes moles moldadas",
        "name": "FMM"
      },
      "fasting": {
        "description": "Jejum",
        "name": "JJ"
      },
      "feedingForced": {
        "description": "Alimentação forçada",
        "name": "AF"
      },
      "feedingNormal": {
        "description": "Alimentação normal",
        "name": "AN"
      },
      "foodForced": {
        "description": "Alimentação forçada",
        "name": "AF"
      },
      "foodNormal": {
        "description": "Alimentação normal",
        "name": "AN"
      },
      "foodTube": {
        "description": "Alimentação por tubo",
        "name": "AT"
      },
      "hematuria": {
        "description": "Hematúria",
        "name": "US"
      },
      "noFaeces": {
        "description": "Sem fezes",
        "name": "SF"
      },
      "noUrine": {
        "description": "Sem urina",
        "name": "SU"
      },
      "urineConcentrated": {
        "description": "Urina concentrada",
        "name": "UC"
      },
      "urineConcentratedByExpression": {
        "description": "Urina concentrada por expressão",
        "name": "UCE"
      },
      "urineJaundiced": {
        "description": "Urina ictérica",
        "name": "UI"
      },
      "urineNormal": {
        "description": "Urina normal",
        "name": "UN"
      },
      "urineNormalByExpression": {
        "description": "Urina normal por expressão",
        "name": "UNE"
      },
      "vomitBile": {
        "description": "Vómito biliar",
        "name": "VB"
      },
      "vomitFoamy": {
        "description": "Vómito espumoso",
        "name": "VE"
      },
      "vomitFood": {
        "description": "Vómito alimentar",
        "name": "VA"
      },
      "vomitRegurgitation": {
        "description": "Vómito com regurgitação",
        "name": "VR"
      },
      "vomitWithBlood": {
        "description": "Vómito com sangue",
        "name": "VS"
      }
    },
    "internmentTypes": {
      "hospitalization": {
        "title": "Hospitalização"
      },
      "hotel": {
        "name": "Hotel",
        "namePlural": "Hotéis"
      },
      "infected": {
        "name": "Infecto",
        "namePlural": "Infectos"
      },
      "intensiveCare": {
        "name": "Cuidados intensivos",
        "namePlural": "Cuidados intensivos"
      },
      "normal": {
        "name": "Normal",
        "namePlural": "Normais"
      },
      "outpatient": {
        "name": "Ambulatório",
        "namePlural": "Ambulatórios"
      },
      "reference": {
        "name": "Referência",
        "namePlural": "Referências"
      },
      "surgery": {
        "name": "Cirurgia",
        "namePlural": "Cirurgias"
      }
    },
    "internments": {
      "actions": {
        "action": {
          "closeCart": "Fechar carrinho",
          "create": {
            "doneBy": "Feito por",
            "doubleClick": "Pode completar as ações fazendo apenas duplo clique sobre a mesma.",
            "errorCreating": "Neste momento não foi possível gravar as suas alterações, por favor tente mais tarde.",
            "lastAction": "Última ação",
            "noNotes": "Sem notas",
            "saveNotes": "Gravar notas",
            "updateScheduling": "Atualizar agendamento",
            "uploadImage": "Carregar imagem",
            "weightChangedDrug": "Os valores deste medicamento podem estar desatualizados, pois o peso do animal foi alterado.",
            "weightChangedSerum": "Os valores deste soro podem estar desatualizados, pois o peso do animal foi alterado."
          },
          "details": {
            "gallery": "Galeria",
            "started": "Data da ação"
          },
          "initials": "Sigla",
          "notes": "Notas",
          "started": "Início",
          "status": {
            "done": "Feita",
            "removed": "Removida",
            "reverted": "Revertida"
          },
          "title": "Ação"
        },
        "shoppingCart": "Ações de carrinho",
        "title": "Ações"
      },
      "clinicAnimal": {
        "admission": "Admissão",
        "entity": "Paciente",
        "entityPlural": "Pacientes",
        "location": "Localização",
        "pmsID": "PMS ID",
        "status": "Estado",
        "temperament": "Temperamento"
      },
      "clinicPerson": {
        "pmsID": "Nr de cliente",
        "role": "Função"
      },
      "clinicalEpisode": {
        "created": "Criado",
        "date": "Data",
        "description": "Descrição",
        "entity": "Episódio Clínico",
        "entityPlural": "Historial Clínico",
        "name": "Nome",
        "type": "Tipo",
        "updated": "Atualizado"
      },
      "details": {
        "allEvents": "Todos os eventos",
        "allTasks": "Todas as tarefas",
        "cantReopen": "Este internamento não pode ser reaberto por favor contacte-nos para mais informação",
        "closed": "Fechado",
        "columnsCount": "Nr de colunas",
        "completedActions": "Ações realizadas",
        "currentDay": "Dia atual",
        "dailyConsumptions": "Os consumos aqui mostrados são apenas deste dia de internamento",
        "dayTasks": "Tarefas do dia",
        "dischargeBy": "Alta agendada",
        "doesntDefecate": "Não defeca",
        "doesntEat": "Não come",
        "doesntUrinate": "Não urina",
        "highlight": "Realçar",
        "hospitalizedStatus": {
          "average": "Mantém",
          "bad": "Piorou",
          "good": "Melhorou"
        },
        "lock": "Bloquear",
        "locked": "Bloqueado",
        "lockedBy": "Bloqueado por",
        "owesMoney": "O cliente deve dinheiro",
        "reopen": "Reabrir",
        "reopenCheck": "Tem a certeza que pretende reabrir?",
        "report": "Relatório",
        "startHour": "Hora de início",
        "step": "Hora de espaço",
        "taskState": {
          "closed": "Fechada",
          "open": "Aberta",
          "title": "Estado"
        },
        "total": "Total",
        "unlock": "Desbloquear",
        "warningReason": "Está a ver este alerta porque não realiza uma ação da tarefa",
        "yesterdaysConsumptions": "Para ver os consumos de dias anteriores ande para trás na ficha"
      },
      "edit": {
        "title": "Editar Internamento"
      },
      "internment": {
        "clinicalEvolution": "Evolução clínica",
        "consumptions": "Consumos",
        "costEstimate": "Nova despesa",
        "create": "Guardar",
        "details": {
          "allEvents": "Todos os eventos",
          "allTasks": "Todas as tarefas",
          "cantReopen": "Este internamento não pode ser reaberto por favor contacte-nos para mais informação",
          "closed": "Fechado",
          "columnsCount": "Nr de colunas",
          "completedActions": "Ações realizadas",
          "currentDay": "Dia atual",
          "dailyConsumptions": "Os consumos aqui mostrados são apenas deste dia de internamento",
          "dayTasks": "Tarefas do dia",
          "dischargeBy": "Alta agendada",
          "doesntDefecate": "Não defeca",
          "doesntEat": "Não come",
          "doesntUrinate": "Não urina",
          "highlight": "Realçar",
          "hospitalizedStatus": {
            "average": "Mantém",
            "bad": "Piorou",
            "good": "Melhorou"
          },
          "lock": "Bloquear",
          "locked": "Bloqueado",
          "lockedBy": "Bloqueado por",
          "owesMoney": "O cliente deve dinheiro",
          "reopen": "Reabrir",
          "reopenCheck": "Tem a certeza que pretende reabrir?",
          "report": "Relatório",
          "reports": "Relatórios",
          "startHour": "Hora de início",
          "step": "Hora de espaço",
          "taskState": {
            "closed": "Fechada",
            "open": "Aberta",
            "title": "Estado"
          },
          "total": "Total",
          "unlock": "Desbloquear",
          "warningReason": "Está a ver este alerta porque não realiza uma ação da tarefa",
          "yesterdaysConsumptions": "Para ver os consumos de dias anteriores ande para trás na ficha"
        },
        "diagnosis": "Diagnóstico",
        "discharge": "Agendar alta",
        "dischargeDate": "Data de alta",
        "dischargeText": "Texto de alta",
        "discharged": "Alta agendada",
        "duration": "Duração",
        "end": "Dar alta",
        "endReason": "Nota de Alta",
        "ended": "Fim",
        "event": {
          "catheter": "Cateter",
          "contactOwner": "Contactar proprietário",
          "faeces": "Fezes",
          "feeding": "Alimentar",
          "fluidRate": "Taxa de fluídos",
          "food": "Alimentação",
          "temperature": "Temperatura",
          "urine": "Urina",
          "vomit": "Vómitos"
        },
        "finances": "Finanças",
        "financialReport": "Relatório Financeiro",
        "hotel": {
          "name": "Hotel",
          "namePlural": "Hotéis"
        },
        "infected": {
          "name": "Infecto",
          "namePlural": "Infectos"
        },
        "internmentType": "Tipo",
        "location": "Localização",
        "newMsg": "Internar",
        "normal": {
          "name": "Normal",
          "namePlural": "Normais"
        },
        "notes": "Notas",
        "otherTasks": "Outras",
        "outpatient": {
          "name": "Ambulatório",
          "namePlural": "Ambulatórios"
        },
        "reason": "Motivo",
        "reference": {
          "name": "Referência",
          "namePlural": "Referências"
        },
        "report": {
          "body": "Body / Colar",
          "clean": "Animal limpo / seco",
          "dressing": "Penso",
          "moreDetails": "Consulte mais detalhes deste internamento em:",
          "release": "Alta",
          "removeCatheter": "Retirar cateter",
          "verify": "Verificar antes de entregar o animal"
        },
        "responsibleVet": "Responsável",
        "revertDischarge": "Reverter alta",
        "started": "Início",
        "status": "Estado",
        "statusDsc": {
          "close": "Terminado",
          "open": "A decorrer"
        },
        "surgery": {
          "name": "Cirurgia",
          "namePlural": "Cirurgias"
        },
        "symptoms": "Sintomas",
        "title": "Internamento",
        "warningMessage": "Alerta",
        "workers": "Funcionários"
      },
      "new": {
        "title": "Novo Internamento"
      },
      "title": "Internamentos",
      "welcome": {
        "hopi": "Se já usa o nosso software de hospitalização então entre aqui",
        "trial": "Experimental",
        "trialMsg": "Experimente gratuitamente o novo módulo de hospitalização"
      }
    },
    "intl": {
      "attention": "Atenção!",
      "close": "Tem a certeza que pretende fechar?",
      "discharge": "Tem a certeza que pretende agendar a alta?",
      "emptyString": "--",
      "error": "Erro",
      "finish": "Tem a certeza que pretende terminar?",
      "information": "info",
      "my": "Meus",
      "noContent": "Não há",
      "or": "ou",
      "pause": "Tem a certeza que pretende pausar a consulta?",
      "remove": "Tem a certeza que pretende remover?",
      "revert": "Tem a certeza que pretende reverter?",
      "revertDischarge": "Tem a certeza que pretende reverter a alta?",
      "showLess": "Mostrar menos...",
      "showMore": "Mostrar mais...",
      "unsavedChanges": "Ao cancelar todas as alterações serão perdidas. Pretende continuar?"
    },
    "invite": {
      "invitedMsg": "Convite enviado para: ",
      "title": "Convidar"
    },
    "invoices": {
      "changeInvoice": "Mudar fatura selecionada",
      "invoice": {
        "annuled": "Documento anulado",
        "date": "Data",
        "emissionDate": "Data de emissão",
        "entity": "Entidade",
        "error": "Falha ao processar",
        "number": "Nº Fatura",
        "processing": "Em processamento",
        "reference": "Referência",
        "status": "Estado",
        "title": "Fatura",
        "total": "Total"
      },
      "new": {
        "title": "Nova Fatura"
      },
      "title": "Faturas"
    },
    "itemAdjustments": {
      "itemAdjustment": {
        "title": "Acerto de Inventário"
      },
      "messages": {
        "createdSuccess": "Acerto de Inventário criado com sucesso"
      },
      "new": {
        "title": "Novo Acerto de Inventário"
      },
      "title": "Acertos"
    },
    "itemSelectList": {
      "compoundTotal": "Total composto",
      "confirm": "Confirmar",
      "name": "Nome",
      "orderNumber": "Ordem",
      "pv": "Preço (s/IVA)",
      "pvSale": "Preço de venda (s/IVA)",
      "pvp": "PVP 1",
      "pvp2": "PVP 2",
      "pvp3": "PVP 3",
      "quantity": "Quantidade",
      "value": "Valor"
    },
    "itemTypes": {
      "description": "Ver todos os tipos de artigos",
      "edit": {
        "title": "Editar Tipo de Artigo"
      },
      "itemType": {
        "description": "Descrição",
        "name": "Nome",
        "parent": "Unidade Mãe",
        "stockable": "Pode movimentar stock",
        "title": "Tipo"
      },
      "new": {
        "title": "Novo Tipo de Artigo"
      },
      "title": "Tipos de Artigo",
      "translation": {
        "ADS": "ADS",
        "Reprodução": "Reprodução",
        "cmdt": "Exames (MCDT)",
        "cmdt-external_imagiology": "Imagiologia Externa",
        "cmdt-external_lab": "Laboratório externo",
        "cmdt-internal_imagiology": "Imagiologia Interna",
        "cmdt-internal_lab": "Laboratório interno",
        "complementary_funeral_services": "Serviços Funebres",
        "complementary_services": "Serviços Complementares",
        "complementary_services_recovery": "Serviços complementares de recolha",
        "consultations": "Consultas",
        "consultations-external": "Externa",
        "consultations-first_consultation": "Primeira Consulta",
        "consultations-followup": "Seguimento",
        "consultations-general": "Geral",
        "consultations-specialty": "Especialidade",
        "consultations-urgency": "Urgencia",
        "consumables": "Consumiveis",
        "consumables-cleaning": "Limpeza",
        "consumables-disposable": "Descartáveis",
        "consumables-mcdt": "MCDT",
        "consumables-stewardship": "Economato",
        "consumables-surgical": "Cirurgicos",
        "drugs": "Medicamentos",
        "drugs-anesthetics": "Anestésicos",
        "drugs-anti_infective": "Anti-infecciosos",
        "drugs-anti_inflammatory": "Anti-inflamatórios",
        "drugs-antibiotics": "Antibióticos",
        "drugs-antidotes": "Antidotos",
        "drugs-antineoplastics": "Antineoplásicos",
        "drugs-cardiovascular _system": "Sistema Cardiovascular",
        "drugs-chemotherapy": "Quimioterapia",
        "drugs-digestive_system": "Sistema Gastrointestinal",
        "drugs-endocrinological": "Sistema Endócrino",
        "drugs-external_Insulin": "Insulina",
        "drugs-external_otological": "Sistema Auditivo",
        "drugs-hormones": "Hormonas",
        "drugs-immunostimulants": "Imunoestimulantes",
        "drugs-immunosuppressors": "Imunossupressores",
        "drugs-integumentary_system": "Sistema Tegumentar",
        "drugs-musculoskeletal_system": "Sistema Locomotor",
        "drugs-nervous_system": "Sistema Nervoso",
        "drugs-other": "Outros",
        "drugs-respiratory_system": "Sistema Respiratório",
        "drugs-urinary_system": "Sistema Urinário",
        "drugs-visual_system": "Sistema Visual",
        "food": "Alimentação",
        "food-diet": "Dieta",
        "food-physiological": "Fisiologica",
        "food-snaks": "Snaks",
        "health_insurance": "Seguros de saúde",
        "health_plans": "Planos de saúde",
        "hospitalization": "Hospitalização",
        "hospitalization-fluidotherapy": "Fluidoterapia",
        "hospitalization-hospitalization": "Internamento",
        "hospitalization-procedures": "Procedimento Hospitalar",
        "hotel": "Hotel",
        "pecuaria": "Pecuaria",
        "petshop": "Pet Shop",
        "petshop-clothing": "Vestuário",
        "petshop-handling": "Maneio",
        "petshop-home": "Casa",
        "petshop-hygiene": "Higiene",
        "petshop-toys": "Brinquedos",
        "procedures": "Procedimentos",
        "procedures_medical": "Procedimento Médico",
        "procedures_nurse": "Enfermagem",
        "profilaxia": "Profilaxia",
        "profilaxia-external_deworming": "Desparasitantes externo",
        "profilaxia-identification": "Identificação",
        "profilaxia-internal_deworming": "Desparasitantes interno",
        "reference_consultation": "Consulta de referência",
        "referral": "Referência",
        "referral-transportation": "Transporte",
        "rehabilitation": "Reabilitação",
        "soft_tissue_breeder": "Tecidos Moles Sistema Reprodutor",
        "soft_tissue_cardiovascular": "Tecidos Moles Sistema Cardiovascular",
        "soft_tissue_gastrointestinal": "Tecidos Moles Sistema Gastrointestinal",
        "soft_tissue_integumentary": "Tecidos Moles Sistema Tegumentar",
        "soft_tissue_locomotor": "Tecidos Moles Sistema Locomotor",
        "soft_tissue_nervous": "Tecidos Moles Sistema Nervoso",
        "soft_tissue_respiratory": "Tecidos Moles Sistema Respiratório",
        "soft_tissue_urinary": "Tecidos Moles Sistema Urinário",
        "soft_tissue_visual": "Tecidos Moles Sistema Visual",
        "studies": "Estudos",
        "suplementos": "Suplementos",
        "surgery": "Cirurgia",
        "surgery-drugs": "Anestesia e Analgesia",
        "surgery-orthopedics": "Ortopedia",
        "surgery-soft_tissue": "Tecidos Moles",
        "vaccines": "Vacinas",
        "wellness": "Bem estar",
        "wellness-recorte": "Tosquias",
        "wellness-shower": "Banhos"
      }
    },
    "items": {
      "chooseUnit": "O campo unidade base é obrigatório",
      "companions": "Artigos associados",
      "disabled": "Inativo",
      "edit": {
        "title": "Editar Artigo",
        "types": "Editar Tipos"
      },
      "enabled": "Ativo",
      "entry": {
        "title": "Entrada de encomendas"
      },
      "form": {
        "category": {
          "generic": "Genérico",
          "lastPurchase": "Última compra",
          "order": "Encomenda",
          "period": "Período",
          "periodic": "Periódico",
          "sellable": "Vendável",
          "stockable": "Movimenta stock",
          "unstockable": "Não movimenta stock"
        },
        "placeholder": {
          "name": "Nome"
        }
      },
      "inactiveItems": "Artigos inativos",
      "item": {
        "barcode": "Código de barras",
        "cantSelectSame": "Este artigo já foi adicionado",
        "companyItemSubType": "Subtipo de artigo",
        "complementaryDescription": "Notas internas",
        "cost": "Custo",
        "defaultWarehouse": "Armazém predefinido",
        "description": "Nome 2a linha",
        "duration": "Duração",
        "errors": {
          "nameAlreadyUsed": "O nome deste artigo não pode ser alterado porque já foi utilizado em documentos",
          "typeAlreadyUsed": "Não pode ser alterado porque já foi utilizado em documentos"
        },
        "examUnit": "Unidade de Exame",
        "inactive": "Este artigo está inativo!",
        "itemClass": {
          "0": "Desconhecido",
          "1": "Serviços",
          "2": "P - matérias-primas",
          "3": "T - trabalhos em curso",
          "4": "S - sub produtos",
          "5": "A - produtos acabados",
          "6": "M - mercadorias",
          "7": "Imobilizado",
          "title": "Classe"
        },
        "linePrice": "Preço de linha",
        "linePriceOld": "Preço de linha antigo",
        "linePriceWithVat": "Preço c/IVA",
        "maxStock": "Stock Max",
        "minStock": "Stock Min",
        "name": "Nome",
        "parameter": "Parâmetro",
        "pmsID": "ID externo",
        "prescription": "Prescrição",
        "price": "Preço (s/IVA)",
        "priceAutomatic": "Preço automático",
        "profit": "lucro",
        "purchasePrice": "Preço de compra (s/IVA)",
        "pvp": "Preço",
        "pvp2": "Preço 2",
        "pvp3": "Preço 3",
        "pvpDiscount": "Desconto",
        "pvpDiscount2": "Desconto 2",
        "pvpDiscount3": "Desconto 3",
        "pvpMargin": "Margem",
        "pvpMargin2": "Margem 2",
        "pvpMargin3": "Margem 3",
        "pvpMarkup": "Markup",
        "pvpMarkup2": "Markup 2",
        "pvpMarkup3": "Markup 3",
        "quantity": "Quantidade",
        "reservedQuantity": "Quantidade Reservada",
        "saleUnit": "Unidade de Venda",
        "saleUnits": "Unidades de Venda",
        "stock": "Stock",
        "stockUnit": "Unidade de Compra",
        "tarifVariable": "Tarifa dinâmica",
        "title": "Artigo",
        "type": "Tipo",
        "unitPrice": "Preço unitário",
        "unitPriceWithVat": "Preço unitário c/IVA",
        "warehouse": "Armazém"
      },
      "messages": {
        "createCondition": "Para 1 basta deixar em branco",
        "createdSuccess": "Artigo criado com sucesso"
      },
      "new": {
        "duplicate": "Duplicar",
        "title": "Novo Artigo"
      },
      "originalRetailPrice": "Preço de retalho",
      "retailPrice1": "Preço de retalho 1",
      "retailPrice2": "Preço de retalho 2",
      "retailPrice3": "Preço de retalho 3",
      "title": "Artigos",
      "totalProducts": "Total de produtos",
      "totalServices": "Total de serviços"
    },
    "laboratories": {
      "actions": {
        "saveAndDoneAnalysis": "Gravar e Terminar"
      },
      "laboratory": {
        "name": "Nome",
        "title": "Laboratório"
      },
      "laboratoryType": {
        "external": "Externo",
        "internal": "Interno",
        "title": "Tipo Laboratório"
      },
      "new": {
        "title": "Novo laboratório"
      },
      "search": {
        "title": "Pesquisar"
      },
      "title": "Laboratórios"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "fr": "Francês",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "liaisons": {
      "messages": {
        "title": "Referente criado com sucesso"
      },
      "new": {
        "title": "Novo veterinário referente"
      },
      "title": "Referentes",
      "vet": "Veterinário referente"
    },
    "listings": {
      "active": "Ativos",
      "all": "Todos",
      "expired": "Expirados",
      "inflow": "Recebimentos",
      "others": "Outros",
      "pending": "Pendentes",
      "salesByUser": "Vendas por utilizador",
      "taxes": "Impostos",
      "title": "Listagens"
    },
    "login": {
      "forgot": "Esqueceu-se da sua password?",
      "invalidPassword": "Password errada",
      "missingAccount": "Ainda não tem uma conta?",
      "submit": "Entrar",
      "subtitle": "Entrar",
      "title": "Entrar",
      "unknownUser": "Email desconhecido"
    },
    "manufacturers": {
      "new": {
        "title": "Novo fabricante"
      },
      "title": "Fabricantes"
    },
    "memos": {
      "advance": "Adiantamento",
      "date": "Data",
      "discountAbsence": "Ausência de desconto na fatura",
      "memo": {
        "title": "Nota de crédito"
      },
      "motive": "Motivo",
      "paymentConditions": "Condições de pagamento",
      "priceError": "Erro no preço",
      "priceincrease": "Aumento de Preço",
      "reference": "Referência",
      "rejectedProduct": "Produto rejeitado",
      "stockRuture": "Rotura de stock",
      "title": "Notas de crédito"
    },
    "menu": {
      "new": "Novo"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "País",
        "email": "E-mail",
        "entity": "Empresa",
        "entityPlural": "Empresas",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Nome Fiscal",
        "notes": "Recados",
        "parent": "Unidade mãe",
        "phone": "Telefone",
        "street": "Morada fiscal",
        "town": "Cidade",
        "vatNumber": "NIF",
        "zip": "Cod. Postal"
      },
      "description": "Ver todos os modelos",
      "employee": {
        "entity": "Funcinário",
        "entityPlural": "Funcionários",
        "profile": "Perfil"
      },
      "form": {
        "placeholder": {
          "name": "Nome"
        }
      },
      "messages": {
        "createdSuccess": "Modelo criado com sucesso"
      },
      "model": {
        "name": "Nome",
        "title": "Modelo"
      },
      "new": {
        "title": "Novo Modelo"
      },
      "person": {
        "country": "País",
        "email": "Email",
        "entity": "Pessoa",
        "entityPlural": "Pessoas",
        "mobilePhone": "Telemóvel",
        "name": "Nome",
        "nif": "NIF",
        "phone": "Telefone",
        "street": "Morada",
        "town": "Cidade",
        "zip": "Código Postal"
      },
      "role": {
        "administrator": "Adminstrador",
        "employee": "Funcionário",
        "undermanager": "Logistica",
        "unknown": "Perfil desconhecido"
      },
      "title": "Modelos",
      "user": {
        "email": "Email",
        "entity": "Utilizador",
        "entityPlural": "Utilizadores",
        "name": "Nome",
        "nif": "NIF",
        "password": "Password"
      }
    },
    "modelsTable": {
      "noDataToShow": "Sem dados para mostrar",
      "of": "de",
      "search": "Filtrar nos resultados:",
      "show": "Mostrar"
    },
    "modules": {
      "management": {
        "description": "Sistema de gestão",
        "inactiveClient": "O cliente selecionado está inativo",
        "tariffNotIncluded": "O seu tarifário atual não inclui a subscrição deste módulo",
        "title": "Gestão",
        "unavailableModule": "Funcionalidade não disponível"
      },
      "sales": {
        "title": "Vendas"
      }
    },
    "monitorings": {
      "monitoring": {
        "abdomen": "Abdómen",
        "attitude": "Atitude",
        "bloodPressure": "P. arterial",
        "capillaryRepletionTime": "TRC",
        "description": "Descrição",
        "ended": "Fim",
        "ganglia": "Gânglios",
        "glucose": "Glicose",
        "heartRate": "Freq. Card.",
        "hematocrit": "Hematócrito",
        "hydration": "Hidratação",
        "maxValue": "Valor máximo",
        "mentalState": "Est. mental",
        "minValue": "Valor mínimo",
        "mucous": "Mucosas",
        "name": "Nome",
        "notes": "Notas",
        "other": "Outro",
        "pain": "Dor",
        "period": "Frequência",
        "physicalExame": "Exame físico",
        "pulse": "Pulso",
        "respiratoryFrequency": "Freq. Resp.",
        "started": "Início",
        "temperature": "Temperatura",
        "title": "Monitorização",
        "totalProteins": "Proteínas Totais"
      },
      "title": "Monitorizações"
    },
    "navigation": {
      "addAsPatient": "Adicionar paciente",
      "back": "Voltar",
      "confirm": "Confirmar"
    },
    "noRecords": "Não há dados para mostrar",
    "notes": {
      "contact": "Contacto",
      "date": "Data",
      "name": "Nome",
      "note": {
        "editNote": "Editar Nota",
        "newNote": "Nova Nota"
      },
      "noteType": "Tipo de nota",
      "outcomeType": "Resultado",
      "patient": "Paciente",
      "title": "Notas",
      "value": "Nota"
    },
    "openItems": {
      "amount": "Montante",
      "debtAmount": "Em falta",
      "discount": "Desconto",
      "dueDate": "Prazo",
      "name": "Documento",
      "notes": "Notas",
      "payingAmount": "A ser pago",
      "paymentCantBeZero": "Um pagamento não pode ser emitido com valores negativos",
      "receiptCantBeZero": "Um recibo tem de possuir um valor positivo para que possa ser emitido",
      "receiptInfoMessage": "Nota: para fechar uma fatura com uma nc, não é recibo (não permite total zero), nesse caso faz-se um pagamento, já permite zero"
    },
    "options": {
      "no": "Não",
      "yes": "Sim"
    },
    "orderLines": {
      "new": {
        "title": "Nova linha"
      },
      "orderLine": {
        "change": "Troco",
        "discount": "Desconto",
        "id": "ID",
        "liquid": "Líquido",
        "name": "Nome",
        "productsAndServices": "Produtos e serviços",
        "quantity": "Quantidade",
        "title": "Linha de carrinho",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Linhas"
    },
    "orders": {
      "edit": {
        "backToStart": "Início"
      },
      "order": {
        "title": "Carrinho"
      },
      "title": "Carrinhos"
    },
    "organizations": {
      "new": {
        "title": "Novo CAMV"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Horário",
        "children": "Sub-unidades",
        "company": "Empresa",
        "country": "País",
        "created": "Criado",
        "details": {
          "disabled": "Esta entidade não tem um plano associado. Por favor contacte o apoio."
        },
        "email": "Email",
        "enable": "Autorizar",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Boa Tarde",
          "hi": "Olá",
          "morning": "Bom dia",
          "night": "Boa Noite"
        },
        "name": "Nome",
        "notes": "Notas",
        "parent": "Unidade Mãe",
        "patientsCount": "Contagem de Pacientes",
        "phone": "Telefone",
        "plan": "Plano",
        "street": "Morada",
        "title": "CAMV",
        "town": "Cidade",
        "zip": "Cod. Postal"
      },
      "title": "Organizações"
    },
    "pages": {
      "absences": "Ausencias",
      "account": "Conta",
      "actions": "Acções",
      "active": "Ativo",
      "appointments": "Agendamentos",
      "approval": "Aprovar de ausências",
      "archive": "Arquivo",
      "avatar": "Avatar",
      "avatars": "Avatares",
      "backoffice": "Configurações",
      "boxes": "Boxes",
      "calendar": "Calendário",
      "campaign": "Campanha",
      "campaigns": "Campanhas",
      "checkinWait": "Sala de Espera",
      "checkoutWait": "Check Out",
      "clinical": "Clínica",
      "clinicalHistory": "História Clínica",
      "clinicalReport": "Relatório clínico",
      "clinicalSummary": "Resumo clínico",
      "clinicalWaitingRooms": "Sala de Espera",
      "companies": "Empresas",
      "company": "Empresa",
      "configurations": "Configurações",
      "consultation": "Visita",
      "consultations": "Visitas",
      "corporate": "Empresa",
      "crm": "CRM",
      "currentAccount": "Conta Corrente",
      "currentAccounts": "Contas Correntes",
      "custom": "Dia",
      "customers": "Clientes",
      "day": "Dia",
      "day2": "Dia v2",
      "debt": "Dívida",
      "debts": "Dívidas",
      "details": "Detalhes",
      "diagnosis": "Diagnóstico",
      "documents": "Documentos",
      "edit": "Editar",
      "employees": "Funcionários",
      "equipment": "Equipamento",
      "equipmentParameter": "Parâmetro",
      "equipmentParameterType": "Tipo de parâmetros",
      "equipmentParameterTypes": "Tipos de parâmetro",
      "equipmentParameters": "Parâmetros",
      "equipmentType": "Tipo de equipamento",
      "equipmentTypes": "Tipos de equipamento",
      "equipments": "Equipamentos",
      "events": "Casos",
      "exams": "Exames",
      "excess": "Excessos",
      "financialAccountAuto": "Histórico",
      "financialAccounts": "Caixas",
      "financialReport": "Relatório Financeiro",
      "general": "Regras",
      "group": "Grupo",
      "hotel": "Hotel",
      "howTo": "SMS",
      "hr": "Recursos Humanos",
      "internal": "Interno",
      "internment": "Internamento",
      "internments": "Internamentos",
      "inventory": "Inventário",
      "invite": "Convidar",
      "invites": "Convites",
      "invoice": "Fatura",
      "invoices": "Faturas",
      "invoicesMemos": "Documentos",
      "item": "Artigo",
      "itemAdjustments": "Acertos de inventário",
      "items": "Artigos",
      "jasmin": "Jasmin",
      "laboratories": "Laboratórios",
      "liaisons": "Remetentes",
      "listings": "Listagens",
      "manufacturer": "Fabricante",
      "manufacturers": "Fabricantes",
      "movements": "Movimentos",
      "new": "Registar",
      "openItems": "Documentos em aberto",
      "openPurchaseInvoice": "Compras em aberto",
      "openPurchaseInvoices": "Compras em aberto",
      "organization": "Organização",
      "organizations": "Organizações",
      "overview": "Vista geral",
      "overviewShopping": "Vista geral de carrinhos",
      "patients": "Pacientes",
      "periodics": "Periódicos",
      "pointOfSales": "Ponto de vendas",
      "procurement": "Aprovisionamento",
      "purchaseInvoice": "Compra",
      "purchaseInvoices": "Compras",
      "purchaseInvoicesDocuments": "Documentos",
      "purchaseMemo": "Nota de crédito",
      "purchaseMemos": "Notas de crédito",
      "purchaseOrder": "Encomenda",
      "purchaseOrders": "Encomendas",
      "purchaseRequest": "Pedido",
      "purchaseRequests": "Pedidos",
      "purchases": "Compras",
      "questionnaireInvite": "Questionário",
      "questionnaireInvites": "Questionário",
      "quotation": "Orçamento",
      "quotations": "Orçamentos",
      "reception": "Receção",
      "recoverPassword": "Recuperação de password",
      "referral": "Referências",
      "referrals": "Referências",
      "reward": "Pontos",
      "rewards": "Pontos",
      "saft": "SAF-T",
      "sales": "Vendas",
      "schedule": "Agenda",
      "scheduleWait": "Atraso",
      "security": "Segurança",
      "settings": "Configurações",
      "sheltereds": "Abrigados",
      "shifts": "Turnos",
      "shopping": "Compras",
      "shoppingCart": "Carrinho",
      "shoppingCarts": "Carrinhos pendentes",
      "shortage": "Faltas",
      "simpleView": "Vista simples",
      "sms": "SMS",
      "stats": "Estatísticas",
      "stays": "Estadia",
      "supplier": "Fornecedor",
      "suppliers": "Fornecedores",
      "tasks": "Tarefas",
      "templates": "Relatórios",
      "today": "Stock Atual",
      "todoChore": "Tarefa",
      "todoChores": "Tarefas",
      "todoDrug": "Medicamento",
      "todoDrugs": "Medicamentos",
      "todoExam": "Exame",
      "todoExams": "Exames",
      "todoMonitoring": "Monitorização",
      "todoMonitorings": "Monitorizações",
      "todoSerum": "Soro",
      "todoSerums": "Soros",
      "transferStock": "Transferir Stock",
      "updateItems": "Editar Tipos",
      "visitWait": "Em visita",
      "waitingRoom": "Sala de Espera",
      "waitingRooms": "Sala de Espera",
      "warehouse": "Armazém",
      "warehouses": "Armazéns",
      "week": "Semana",
      "workers": "Funcionários"
    },
    "patients": {
      "disabled": "Inativo",
      "edit": {
        "title": "Editar Paciente"
      },
      "enabled": "Ativo",
      "form": {
        "category": {
          "fisicalDetails": "Dados Físicos",
          "other": "Outros",
          "personalInformation": "Informações Pessoais"
        },
        "environmentType": {
          "both": "Ambos",
          "indoor": "Interior",
          "outdoor": "Exterior"
        },
        "placeholder": {
          "behavior": "Comportamento",
          "census": "Recenseamento",
          "chip": "Número de Chip",
          "comments": "Comentários",
          "commentsExtra": "Commentários Extra",
          "eyes": "Exemplo: Azul",
          "fur": "Exemplo: Curto",
          "height": "Altura",
          "insuranceType": "Tipo de Seguro",
          "name": "Exemplo: Boby",
          "notes": "Outras Informações",
          "passaport": "Número de Passaporte",
          "pathology": "Selecionar Patologia",
          "pedigree": "Pedigree",
          "temperament": "Exemplo: Calmo"
        },
        "select": {
          "breed": "Selecionar Raça",
          "diet": "Selecionar Tipo de Alimento",
          "environmentType": "Selecionar Tipo de Ambiente",
          "owner": "Selecionar Cliente",
          "price": "Selecionar Preço",
          "species": "Selecionar Espécie",
          "tail": "Selecionar Tipo de Cauda",
          "veterinary": "Selecionar Veterinário Preferencia"
        }
      },
      "inactivePatients": "Pacientes Inativos",
      "messages": {
        "createdSuccess": "Paciente criado com sucesso",
        "mustHaveWeight": "O Paciente deve possuir um peso para usar a calculadora"
      },
      "new": {
        "title": "Novo Paciente"
      },
      "number": "Nº de Pacientes",
      "patient": {
        "age": "Idade",
        "alert": "Alerta",
        "avatar": "Avatar",
        "behavior": "Comportamento",
        "birthdate": "Data de Nascimento",
        "breed": "Raça",
        "census": "Recenseamento",
        "chip": "Chip",
        "comment": "Comentários",
        "comments": "Comentários",
        "commentsExtra": "Comentários Exta",
        "deathReason": "Motivo de óbito",
        "deathdate": "Data de Óbito",
        "deceased": "Falecido",
        "deleted": "Eliminar",
        "diet": "Dieta",
        "environmentType": "Tipo de Ambiente",
        "eyes": "Olhos",
        "female": "Fêmea",
        "fur": "Pêlo",
        "furColor": "Cor",
        "genderless": "Sem género",
        "height": "Altura",
        "hermaphrodite": "Hermafrodita",
        "id": "ID",
        "idcard": "Boletim",
        "inactive": "Inativo",
        "insurance": "Seguro",
        "insuranceType": "Tipo de Seguro",
        "location": "Localização",
        "male": "Macho",
        "mixedbreed": "Raça Cruzada",
        "name": "Nome",
        "neutral": "Neutro",
        "newWeight": "Novo Peso",
        "noOwner": "Não pode realizar um agendamento para um paciente sem dono associado",
        "noOwnerConsultation": "Não pode criar uma consulta para um paciente sem dono associado",
        "notes": "Notas",
        "otherSpecies": "Outras espécies",
        "ownerPrefered": "Favorito do Cliente",
        "passport": "Passaporte",
        "pathologies": "Patologias",
        "pathology": "Patologia",
        "patientSince": "Paciente desde",
        "pedigree": {
          "no": "Não",
          "title": "Pedigree",
          "yes": "Sim"
        },
        "pmsID": "ID antigo",
        "price": "Preço",
        "sex": "Sexo",
        "species": "Especíe",
        "status": "Estado",
        "sterilized": "Esterilizado",
        "tailType": "Tipo de Cauda",
        "tattoo": "Tatuagens",
        "tattooNumber": "Número da Tatuagem",
        "temperament": {
          "agressive": "Agressivo",
          "good": "Bom",
          "moderate": "Moderado",
          "title": "Temperamento"
        },
        "title": "Paciente",
        "unknown": "Desconhecido",
        "veterinaryPrefered": "Veterinário Preferencial",
        "weights": {
          "changedDrug": "Os valores deste medicamento podem estar desatualizados, pois o peso do animal foi alterado.",
          "changedSerum": "Os valores deste soro podem estar desatualizados, pois o peso do animal foi alterado.",
          "title": "Pesos",
          "weight": {
            "new": "Novo Peso",
            "title": "Peso",
            "weighted": "Data"
          }
        }
      },
      "title": "Pacientes"
    },
    "payments": {
      "noOpenItems": "Este cliente não possui notas de crédito em aberto"
    },
    "periodics": {
      "actions": {
        "done": "Marcar como feito"
      },
      "messages": {
        "createdSuccess": "Periódico criado com sucesso"
      },
      "new": {
        "cadency": "Cadência",
        "description": "Descrição",
        "intake": "Administração",
        "labels": {
          "cadency": "Cadência",
          "description": "Descreva o Periódico Brevemente",
          "observations": "Observações Adicionais",
          "product": "Selecione o Produto",
          "selectPeriodicType": "Tipo de Periódico",
          "species": "Selecione as Espécies que Deseja"
        },
        "nextDate": "Próxima Administração",
        "numberOfReinforcements": "Nº de Reforços",
        "observations": "Observações",
        "pastDate": "Está a inserir uma data no passado",
        "previousDate": "Ultima Administração",
        "product": "Produto",
        "reinforcmentCadency": "Cadência de Reforço",
        "species": "Espécies",
        "title": "Novo Periódico",
        "type": "Tipo de Periódico"
      },
      "periodic": {
        "intake": "Toma",
        "intakeDate": "Data",
        "nextPeriodic": "Próxima",
        "title": "Periódico",
        "type": "Tipo"
      },
      "periods": {
        "day": "Dia",
        "drugs": {
          "noRepeat": "Sempre visível",
          "period1": "q1h",
          "period12": "BID",
          "period120": "q5d",
          "period168": "q7d",
          "period2": "q2h",
          "period24": "SID",
          "period3": "q3h",
          "period4": "q4h",
          "period48": "q48h",
          "period5": "q5h",
          "period6": "QID",
          "period72": "q72h",
          "period8": "TID",
          "period96": "q4d",
          "singleTake": "Toma única"
        },
        "month": "Mês",
        "today": "Hoje",
        "week": "Semana",
        "year": "Ano"
      },
      "status": {
        "communicated": "Comunicado",
        "done": "Administrado",
        "nextDose": "Próxima Dose",
        "notCommunicated": "Não comunicado",
        "scheduled": "Agendado",
        "taken": "Histórico",
        "title": "Estado",
        "toTake": "A Tomar"
      },
      "title": "Periódicos"
    },
    "plans": {
      "entity": "Planos",
      "new": {
        "title": "Novo Plano"
      },
      "plan": {
        "entity": "Plano",
        "name": "Nome"
      }
    },
    "pointOfSales": {
      "animal": "Animal",
      "barcodeScan": "Scan de código de barras",
      "bill": "Faturar",
      "billingDatas": {
        "billingData": {
          "title": "Dados de faturação"
        },
        "choose": "Escolher",
        "finalCustomer": "Consumidor final"
      },
      "camera": "Câmera",
      "change": "Troco",
      "discount": "Desconto",
      "documentTypes": {
        "documentType": {
          "title": "Tipo de documento"
        },
        "invoice": "Fatura",
        "invoiceReceipt": "Fatura recibo",
        "simplifiedInvoice": "Fatura simplificada",
        "title": "Tipos de documento"
      },
      "errors": {
        "debt": "Vai ficar a dever!",
        "pdfNotEmiting": "PDF ainda não disponível, por favor faça download manual",
        "pleaseTryDocuments": "O documento foi emitido mas houve um erro a ir buscar o pdf, por favor tente na página de documentos"
      },
      "issue": "Emitir",
      "payAndCheckout": "Pagar e checkout",
      "paymentMethods": {
        "amountPaid": "Montante recebido",
        "card": "Cartão",
        "cash": "Numerário",
        "check": "Cheque",
        "checkDate": "Data do cheque",
        "checkNumber": "Número de cheque",
        "directDebit": "Débito direto",
        "paymentMethod": {
          "title": "Método de pagamento"
        },
        "title": "Métodos de pagamento",
        "transfer": "Transferência"
      },
      "quantity": "Quantidade",
      "saveNewPrice": "Gravar novo PVP?",
      "search": "Procurar Cliente",
      "select": "Selecionar Cliente",
      "title": "Ponto de vendas",
      "warehouse": "Armazém"
    },
    "prices": {
      "price": {
        "title": "Preço"
      },
      "title": "Preços"
    },
    "procurement": {
      "title": "Aprovisionamento"
    },
    "purchaseInvoiceLines": {
      "messages": {
        "createdSuccess": "Linha de compra criada com sucesso"
      },
      "new": {
        "title": "Nova linha de compra"
      },
      "title": "Linha de compra"
    },
    "purchaseInvoices": {
      "duplicate": {
        "title": "Duplicar compra"
      },
      "edit": {
        "saving": "A guardar alterações",
        "savingLines": "A guardar as linhas"
      },
      "invalid": {
        "amount": "Quantidade inválida",
        "badAdjustment": "Faltam dados no acerto de inventário",
        "badHeader": "Faltam dados no cabeçalho",
        "badLines": "Há linhas inválidas",
        "cost": "Custo inválido",
        "noLines": "Falta selecionar as linhas",
        "noSupplier": "Falta selecionar o fornecedor",
        "vat": "IVA inválido"
      },
      "lastPurchasePrice": "Último preço de compra",
      "new": {
        "date": "Entrada",
        "dueDate": "Data de vencimento",
        "exists": "Já existe um documento com este nome",
        "financialAccount": "Caixa",
        "internalNotes": "Notas internas",
        "name": "Nome",
        "notes": "Notas",
        "paidAmount": "Montante Pago",
        "payment": "Pagamento",
        "paymentMethod": "Método de Pagamento",
        "supplier": "Fornecedor",
        "total": "Total"
      },
      "open": {
        "title": "Compras em aberto"
      },
      "purchaseInvoice": {
        "internalData": "Dados internos",
        "lines": "Linhas",
        "paid": "Pago",
        "payment": "Pagamento",
        "status": {
          "issued": "Emitido",
          "saved": "Gravado",
          "title": "Estado"
        },
        "title": "Compra"
      },
      "title": "Compra"
    },
    "purchaseOrders": {
      "date": "Data",
      "details": {
        "title": "Detalhes da Encomenda"
      },
      "done": {
        "title": "Tem a certeza que pretende marcar esta Encomenda como feito?"
      },
      "duplicate": {
        "title": "Duplicar Encomenda"
      },
      "edit": {
        "title": "Editar Encomenda"
      },
      "info": {
        "billed": "Em Fatura",
        "issued": "Emitido",
        "noneBilled": "Nenhuma encomenda faturada",
        "noneIssued": "Nenhuma encomenda emitida",
        "noneSaved": "Nenhuma encomenda gravada",
        "saved": "Gravado"
      },
      "item": "Artigo",
      "line": {
        "lastPrice": "Último € /"
      },
      "markedDone": {
        "message": "Tem a certeza que pretende marcar esta Encomenda como feito? Esta ação não pode ser desfeita.",
        "title": "Marcar como feito"
      },
      "messages": {
        "successfullyIssued": "Encomenda emitida com sucesso",
        "successfullySaved": "Encomenda gravada com sucesso"
      },
      "name": "Nome",
      "new": {
        "title": "Nova Encomenda"
      },
      "note": "Nota",
      "payableAmount": "Montante estimado",
      "quantity": "Quantidade",
      "receive": {
        "title": "Tem a certeza que pretende marcar esta Encomenda como recebido?"
      },
      "send": {
        "title": "Tem a certeza que pretende enviar esta Encomenda?"
      },
      "status": {
        "markedDone": "Feita",
        "received": "Recebida",
        "sent": "Enviada",
        "title": "Estado"
      },
      "supplier": "Fornecedor",
      "title": "Encomenda",
      "updatedSuccess": "Encomenda atualizada com sucesso"
    },
    "purchaseRequests": {
      "date": "Data",
      "details": {
        "title": "Detalhes do Pedido"
      },
      "done": {
        "title": "Tem a certeza que pretende marcar este Pedido como feito?"
      },
      "duplicate": {
        "title": "Duplicar Pedido"
      },
      "edit": {
        "title": "Editar Pedido"
      },
      "info": {
        "billed": "Em Fatura",
        "inOrder": "Em encomenda",
        "issued": "Emitido",
        "noneBilled": "Nenhum pedido faturado",
        "noneInOrder": "Nenhum pedido em encomenda",
        "noneIssued": "Nenhum pedido emitido",
        "noneOrdered": "Nenhum pedido encomendado",
        "noneSaved": "Nenhum pedido gravado",
        "ordered": "Encomendado",
        "saved": "Gravado"
      },
      "item": "Artigo",
      "line": {
        "lastPrice": "Último € /"
      },
      "markedDone": {
        "message": "Tem a certeza que pretende marcar este Pedido como feito? Esta ação não pode ser desfeita.",
        "title": "Marcar como feito"
      },
      "messages": {
        "createdSuccess": "Pedido criado com sucesso"
      },
      "name": "Nome",
      "new": {
        "title": "Novo Pedido"
      },
      "note": "Notas",
      "payableAmount": "Montante estimado",
      "quantity": "Quantidade",
      "receive": {
        "title": "Tem a certeza que pretende marcar este Pedido como recebido?"
      },
      "send": {
        "title": "Tem a certeza que pretende enviar este Pedido?"
      },
      "status": {
        "received": "Recebido",
        "sent": "Enviado",
        "title": "Estado"
      },
      "supplier": "Fornecedor",
      "title": "Pedido",
      "updatedSuccess": "Pedido atualizado com sucesso"
    },
    "purchases": {
      "discount": "Desconto",
      "lot": "Lote",
      "messages": {
        "successfully": "Compra efetuada com sucesso",
        "successfullySaved": "Compra gravada com sucesso"
      },
      "price": "Preço",
      "quantity": "Quantidade",
      "quantityPromotion": "Oferta",
      "shelfLife": "Validade",
      "title": "Compras"
    },
    "quotations": {
      "changeQuotation": "Mudar orçamento",
      "name": "Nome",
      "new": "Novo orçamento",
      "quotation": {
        "title": "Orçamento"
      },
      "quotationsConsumed": "Percentagem",
      "removed": "Orçamento deselecionado",
      "selected": "Orçamento selecionado",
      "title": "Orçamentos"
    },
    "receipts": {
      "edit": {
        "title": "Editar recibo"
      },
      "noOpenItems": "Este cliente não possui faturas em aberto",
      "receipt": {
        "title": "Recibo"
      },
      "title": "Recibos"
    },
    "referrals": {
      "title": "Referências"
    },
    "requestLines": {
      "new": {
        "title": "Nova linha"
      },
      "orderLine": {
        "change": "Troco",
        "discount": "Desconto",
        "id": "ID",
        "liquid": "Líquido",
        "name": "Nome",
        "productsAndServices": "Produtos e serviços",
        "quantity": "Quantidade",
        "title": "Linha de carrinho",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Linhas"
    },
    "rewards": {
      "reward": {
        "available": "Pontos disponíveis",
        "title": "Pontos",
        "use": "Usar pontos"
      },
      "title": "Pontos"
    },
    "rooms": {
      "new": {
        "title": "Nova sala"
      },
      "room": {
        "title": "Sala"
      },
      "title": "Salas"
    },
    "saft": {
      "creator": "Criador",
      "documentDate": "Data",
      "endDate": "Data de fim",
      "name": "Nome",
      "new": {
        "title": "Novo SAF-T"
      },
      "startDate": "Data de início",
      "title": "SAF-T"
    },
    "sales": {
      "balance": "Divida",
      "billItem": {
        "new": "Novo Item"
      },
      "billed": "Faturado",
      "credit": "Crédito",
      "currentAccount": {
        "12to18": "12 - 18 meses",
        "18to24": "18 - 24 meses",
        "3to6": "3 - 6 meses",
        "6to12": "6 - 12 meses",
        "less3": "< 3 meses",
        "more24": "> 24 meses",
        "notDue": "Não vencido"
      },
      "directDebit": "Débito",
      "document": "Número de Documento",
      "documents": {
        "title": "Documentos"
      },
      "invoices": {
        "title": "Faturas"
      },
      "newItem": "Adicionar item  ao carrinho",
      "stats": {
        "assistants": "Assistentes",
        "maps": "Mapas",
        "nurses": "Enfermeiros",
        "products": "Produtos",
        "services": "Serviços",
        "top5": "Top 5",
        "transactions": "Transações",
        "vets": "Veterinários"
      },
      "title": "Faturação",
      "totalDebt": "Dívida Total",
      "totalPaid": "Total Pago",
      "value": "Valor"
    },
    "salesUnits": {
      "title": "Unidades de venda"
    },
    "section": {
      "title": "Secção"
    },
    "serums": {
      "serum": {
        "adjustment": "Ajuste",
        "adjustmentResult": "Taxa Final",
        "amount": "Garrafa",
        "description": "Descrição",
        "ended": "Fim",
        "maintenanceFluidRate": "Taxa Manut.",
        "name": "Nome",
        "period": "Frequência",
        "started": "Início",
        "supplementation": "Suplementação",
        "title": "Soro"
      },
      "title": "Soros"
    },
    "settings": {
      "rules": "Regras"
    },
    "sheltereds": {
      "disabled": "Inativo",
      "edit": {
        "title": "Editar Abrigado"
      },
      "enabled": "Ativo",
      "form": {
        "category": {
          "fisicalDetails": "Dados Físicos",
          "other": "Outros",
          "personalInformation": "Informações Pessoais"
        },
        "environmentType": {
          "both": "Ambos",
          "indoor": "Interior",
          "outdoor": "Exterior"
        },
        "placeholder": {
          "allergies": "Alergias",
          "behavior": "Comportamento",
          "census": "Recenseamento",
          "chip": "Número de Chip",
          "comments": "Comentários",
          "commentsExtra": "Commentários Extra",
          "eyes": "Exemplo: Azul",
          "fur": "Exemplo: Curto",
          "furDescription": "Descrição da pele",
          "height": "Altura",
          "insuranceType": "Tipo de Seguro",
          "name": "Exemplo: Boby",
          "notes": "Outras Informações",
          "passaport": "Número de Passaporte",
          "pathology": "Selecionar Patologia",
          "pedigree": "Pedigree",
          "siraID": "Sira ID",
          "temperament": "Exemplo: Calmo"
        },
        "select": {
          "breed": "Selecionar Raça",
          "diet": "Selecionar Tipo de Alimento",
          "environmentType": "Selecionar Tipo de Ambiente",
          "owner": "Selecionar Cliente",
          "price": "Selecionar Preço",
          "species": "Selecionar Espécie",
          "tail": "Selecionar Tipo de Cauda",
          "veterinary": "Selecionar Veterinário Preferencia"
        }
      },
      "new": {
        "title": "Novo Abrigado"
      },
      "sheltered": {
        "admissionDate": "Data de Entrada",
        "admissionType": "Tipo de Entrada",
        "age": "Idade",
        "allergies": "Alergias",
        "avatar": "Avatar",
        "behavior": "Comportamento",
        "birthdate": "Data de Nascimento",
        "breed": "Raça",
        "census": "Recenseamento",
        "chip": "Chip",
        "comment": "Comentários",
        "comments": "Comentários",
        "commentsExtra": "Comentários Exta",
        "deathdate": "Data de Óbito",
        "deceased": "Falecido",
        "deleted": "Eliminar",
        "diet": "Dieta",
        "environmentType": "Tipo de Ambiente",
        "eyes": "Olhos",
        "fur": "Pêlo",
        "furColor": "Cor",
        "furDescription": "Descrição da pele",
        "furLength": "Comprimento do pêlo",
        "furType": "Tipo de pêlo",
        "height": "Altura",
        "id": "ID",
        "inactive": "Inativo",
        "insurance": "Seguro",
        "insuranceType": "Tipo de Seguro",
        "location": "Localização",
        "name": "Nome",
        "newWeight": "Novo Peso",
        "notes": "Notas",
        "ownerPrefered": "Favorito do Cliente",
        "passport": "Passaporte",
        "pathologies": "Patologias",
        "pathology": "Patologia",
        "pedigree": "Pedigree",
        "price": "Preço",
        "proportion": "Proporção",
        "releaseDate": "Data de Saída",
        "releaseType": "Tipo de Saída",
        "sex": "Sexo",
        "siraID": "Sira ID",
        "species": "Especíe",
        "status": "Estado",
        "sterilized": "Esterilizado",
        "tailType": "Tipo de Cauda",
        "tattoo": "Tatuagens",
        "tattooNumber": "Número da Tatuagem",
        "temperament": {
          "agressive": "Agressivo",
          "good": "Bom",
          "moderate": "Moderado",
          "title": "Temperamento"
        },
        "title": "Abrigado",
        "veterinaryPrefered": "Veterinário Preferencial",
        "weights": {
          "title": "Pesos",
          "weight": {
            "title": "Peso",
            "weighted": "Data"
          }
        }
      },
      "title": "Abrigados"
    },
    "shifts": {
      "create": "Criar Turno",
      "edit": "Editar Turno",
      "endDate": "Data de fim",
      "name": "Nome",
      "notes": "Notas",
      "startDate": "Data de início",
      "title": "Turnos",
      "worker": "Funcionário"
    },
    "shoppingCartLines": {
      "new": {
        "title": "Nova linha"
      },
      "shoppingCartLine": {
        "discount": "Desconto",
        "id": "ID",
        "liquid": "Líquido",
        "name": "Nome",
        "productsAndServices": "Produtos e serviços",
        "quantity": "Quantidade",
        "title": "Linha de carrinho",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Linhas de carrinho"
    },
    "shoppingCarts": {
      "checkPaid": "Marcar como Pago",
      "details": "Ver carrinho",
      "edit": {
        "backToStart": "Início"
      },
      "markAsPaid": "Tem a certeza que pretende marcar como pago?",
      "name": "Nome",
      "noLines": "Este carrinho não possui linhas",
      "onGoingInternment": "Internamento em curso",
      "openTitle": "Carrinhos em aberto",
      "paid": "Faturado",
      "pending": "Pendente",
      "percentagePaid": "Percentagem de carrinhos pagos",
      "shoppingCart": {
        "title": "Carrinho"
      },
      "showPaidCarts": "Incluir Carrinhos já pagos",
      "status": "Estado",
      "title": "Carrinhos"
    },
    "sms": {
      "new": {
        "title": "Novo SMS"
      },
      "sent": {
        "title": "SMS"
      },
      "sms": {
        "appointmentDate": "Data",
        "campaign": "Campanha",
        "customer": "Cliente",
        "patient": "Paciente",
        "phoneNumber": "Número",
        "started": "Data de envio",
        "text": "Texto",
        "title": "SMS"
      },
      "title": "SMS"
    },
    "species": {
      "101": "Rouxinol-do-japão",
      "103": "Inseparável-de-faces-rosadas",
      "107": "Águia Imperial Ibérica",
      "108": "Águia-Perdigueira",
      "109": "Aratinga",
      "113": "Pombos",
      "117": "Petauro Do Açúcar",
      "118": "Gecko",
      "119": "Serpentes",
      "12": "Melro Preto",
      "120": "Camaleão",
      "121": "Pyrrhura",
      "125": "Galinha",
      "126": "Papagaio do Senegal",
      "128": "Ring neck",
      "130": "Rola Comum",
      "14": "Catatuas",
      "16": "Araras",
      "17": "Papagaio Louro",
      "18": "Papagaio da Patagónia",
      "2": "Chordata",
      "20": "Caturras",
      "21": "Piriquitos",
      "22": "Papagaio Cinzento",
      "28": "Porcos",
      "33": "Cães",
      "34": "Felidae",
      "35": "Felis",
      "36": "Gatos",
      "40": "Furões",
      "42": "Ouriços",
      "46": "Coelhos",
      "50": "Porquinho da Índia",
      "53": "Chinchillas cauda curta",
      "54": "Chinchillas cauda longa",
      "56": "Hamsters",
      "59": "Ratos",
      "62": "Deguas",
      "64": "Cães da Pradaria",
      "68": "Dragão Barbudo",
      "7": "Chloris chloris",
      "70": "Iguana",
      "72": "Varanus",
      "73": "Varano da Savana",
      "74": "Tartarugas",
      "79": "Cavalos",
      "8": "Canários",
      "81": "Burros",
      "82": "Phodopus",
      "83": "Anão Russo",
      "86": "Elefantes",
      "9": "Pintassilgos",
      "90": "Vacas",
      "92": "Capra",
      "93": "Cabras",
      "95": "Ovelhas",
      "98": "Piriquito Australiano"
    },
    "stats": {
      "averageTimes": "Tempo médio",
      "billedThisMonth": "Mês",
      "billedThisWeek": "7 dias",
      "billedThisYear": "Ano",
      "billedToday": "Hoje",
      "decreased": "Diminuiu",
      "down": "Down",
      "female": "F",
      "increased": "Aumentou",
      "invoicesNumber": "Número de faturas",
      "listOfCreatedCustomers": "Lista de clientes criados",
      "male": "M",
      "mostRecentDate": "Data mais recente",
      "new": "Novos",
      "organization": "Estatísticas",
      "overview": "Vista Geral",
      "salesByWorker": "Vendas por trabalhador",
      "same": "Igual",
      "todayTotalChange": "Total de mudanças hoje",
      "total": "Total",
      "totalBilled": "Total Faturado",
      "totalInInvoices": "Total em faturas",
      "totals": "Totais",
      "up": "Up",
      "variations": "Variações",
      "vsLastYear": "vs Ano Passado"
    },
    "stays": {
      "box": "Box",
      "create": "Criar Estadia",
      "details": "Detalhes da Estadia",
      "edit": "Editar Estadia",
      "endDate": "Data de fim",
      "name": "Nome",
      "patient": "Paciente",
      "startDate": "Data de início",
      "staysArrivingToday": "Estadias a dar entrada hoje",
      "staysLeavingToday": "Estadias a sair hoje",
      "title": "Estadias"
    },
    "stockUnits": {
      "title": "Unidades de Stock"
    },
    "subfamily": {
      "title": "Subfamília"
    },
    "supplierItems": {
      "new": {
        "title": "Novo artigo de fornecedor"
      },
      "supplierItem": {
        "supplierId": "Código do fornecedor",
        "title": "Artigo de fornecedor"
      },
      "title": "Artigos de fornecedor"
    },
    "suppliers": {
      "building": "Edifício",
      "country": "País",
      "edit": {
        "title": "Editar Fornecedor"
      },
      "email": "Email",
      "messages": {
        "createdSuccess": "Fornecedor criado com sucesso"
      },
      "mobilePhone": "Telefone",
      "name": "Nome",
      "new": {
        "title": "Novo Fornecedor"
      },
      "street": "Rua",
      "supplier": {
        "supplierId": "ID de Fornecedor",
        "title": "Fornecedor"
      },
      "title": "Fornecedores",
      "town": "Cidade",
      "vat": "Nif",
      "zip": "Código Postal"
    },
    "system": {
      "alerts": {
        "offline": {
          "html": "<p>Não conseguimos acesso ao nosso servidor.</p><p>A tentar nova ligação <i class='fa fa-spinner fa-pulse fa-lg fa-fw'></i></p>",
          "title": "Sem acesso à internet!"
        },
        "online": {
          "text": "Já pode continuar o seu trabalho.",
          "title": "Conecção restabelecida!"
        }
      }
    },
    "systemDown1": "O sistema será desligado para manutenção às",
    "systemDown2": "durante aproximadamente duas horas.",
    "tasks": {
      "archive": {
        "title": "Arquivo"
      },
      "create": {
        "addDrug1": "Para adicionar um novo princípio ativo envie email para",
        "addSupplementation1": "Para adicionar uma nova suplementação",
        "addSupplementation2": "clique aqui e envie-nos um email",
        "amount": "Quantidade",
        "byKG": "Por kg",
        "changes": "Todas as alterações à tarefa serão aplicadas a partir da última ação feita",
        "createdBy": "Criada por",
        "dosagePerKilogram": "Dose por Kg",
        "doseCalculator": "Calculadora de doses",
        "email": "info@petuniversal.com",
        "freqInfo": "Uma tarefa \"Sempre visível\" não é agendada e pode ser registada manualmente. Sem agendamento a tarefa não aparece na vista geral.",
        "name": "Criar tarefa",
        "newActivePrinciple": "Novo princípio ativo",
        "rateInfo": "Segundo a formula: (peso * 30 + 70) / 24",
        "startedInfo": "Se clicar no ícone do relógio é inserida automáticamente a hora atual.",
        "toAdminister": "A administrar",
        "total": "Total",
        "type": {
          "injectable": "Injetável",
          "pill": "Comprimido",
          "title": "Tipo"
        },
        "volumePerKilogram": "Volume por Kg"
      },
      "done": "Realizar",
      "edit": {
        "title": "Editar Tarefa"
      },
      "form": {
        "select": {
          "priority": "Selecionar Prioridade",
          "worker": "Selecionar Funcionário"
        }
      },
      "generatedBySystem": "Esta tarefa foi gerada pelo sistema",
      "messages": {
        "createdSuccess": "Tarefa criada com sucesso"
      },
      "myTasks": "Minhas tarefas",
      "new": {
        "title": "Nova Tarefa"
      },
      "priorities": {
        "high": "Alta",
        "low": "Baixa",
        "urgency": "Urgência"
      },
      "select": {
        "all": "Todas"
      },
      "status": {
        "done": "Realizada",
        "inDelay": "Em Atraso",
        "pending": "Pendente"
      },
      "task": {
        "comment": "Comentário",
        "description": "Notas",
        "name": "Descrição",
        "patientcustomer": "Paciente/Cliente",
        "period": "Frequência",
        "priority": "Prioridade",
        "status": "Estado",
        "taskDate": "Data",
        "taskTime": "Hora",
        "title": "Tarefa"
      },
      "taskType": "Tipo de tarefa",
      "timeline": "Cronograma",
      "title": "Tarefas",
      "withoutSelectedPriority": "Sem prioridade seleccionada",
      "withoutTasks": "Sem Tarefas",
      "withoutWorker": "Sem funcionário"
    },
    "taxes": {
      "title": "Impostos",
      "vat": {
        "title": "IVA"
      }
    },
    "templates": {
      "name": "Nome",
      "new": {
        "title": "Novo Relatório"
      },
      "template": {
        "title": "Relatório"
      },
      "templateSections": {
        "title": "Secções de relatório"
      },
      "templateSectionsFields": {
        "title": "Campos de secção de relatório"
      },
      "title": "Relatórios"
    },
    "terms": {
      "conditionedDischarge": "Termo de Alta Condicionada",
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "debt": "Termo de Divida",
      "general": {
        "link": "Condições",
        "title": "Termos e condições Gerais"
      },
      "language-disclaimer": "",
      "last-rev-date": "01 de janeiro 2016",
      "last-rev-date-desc": "Data da última revisão",
      "privacy": {
        "link": "Privacidade",
        "title": "Política de Privacidade"
      },
      "procedure": "Termo de Procedimento",
      "responsability": "Termo de Não tratamento",
      "title": "Termos",
      "translation-not-available": ""
    },
    "titles": {
      "arquitect": "Arquitecto (a)",
      "dr": "Doutor",
      "dra": "Doutora",
      "engineer": "Engenheiro",
      "engineerFemale": "Engenheira",
      "father": "Padre",
      "miss": "Menina",
      "mr": "Senhor",
      "mrs": "Senhora",
      "nurse": "Enfermeiro",
      "nurseFemale": "Enfermeira",
      "professor": "Professor",
      "professorFemale": "Professora",
      "sister": "Irmã"
    },
    "transfersStock": {
      "aTDocCodeID": "Código AT",
      "loading": {
        "loadingBuildingNumber": "Número",
        "loadingCityName": "Cidade",
        "loadingCountry": "País",
        "loadingDateTime": "Data",
        "loadingPostalZone": "Código Postal",
        "loadingStreetName": "Rua",
        "sourceWarehouse": "Origem",
        "title": "Carregamento"
      },
      "new": {
        "title": "Nova transferência de stock"
      },
      "title": "Transferências de stock",
      "unloading": {
        "targetWarehouse": "Destino",
        "title": "Descarregamento",
        "unloadingBuildingNumber": "Número",
        "unloadingCityName": "Cidade",
        "unloadingCountry": "País",
        "unloadingDateTime": "Data",
        "unloadingPostalZone": "Código Postal",
        "unloadingStreetName": "Rua"
      }
    },
    "u-icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "plan": "ticket",
      "plans": "ticket",
      "user": "user",
      "worker": "user-md"
    },
    "unexpectedError": "Ocorreu um erro inesperado.\nVolta a tentar novamente dentro de alguns instantes.\nSe o erro persistir por favor avise-nos.",
    "unitDimensions": {
      "description": "Todas as dimensões unidades",
      "form": {
        "placeholder": {
          "baseUnit": "Unidade base",
          "description": "Descrição",
          "name": "Nome",
          "title": "Dimensões de unidades"
        }
      },
      "new": {
        "title": "Nova dimensão de unidade"
      },
      "title": "Dimensões de unidades",
      "unitDimension": {
        "baseUnit": "Unidade base",
        "description": "Descrição",
        "name": "Nome",
        "title": "Dimensão"
      }
    },
    "units": {
      "ampoules": "ampolas",
      "bandages": "ligaduras",
      "capsules": "capsulas",
      "centimeters": "cm",
      "description": "todas as unidades",
      "drops": "gotas",
      "form": {
        "placeholder": {
          "baseUnit": "Unidade Base",
          "conversionFactor": "Fator de Conversão",
          "externalID": "ID Externo",
          "name": "Nome",
          "precisionDigits": "Números de Precisão",
          "salesItems": "Unidades de Venda",
          "stockItems": "Unidades de Compra",
          "symbol": "Símbolo",
          "title": "Unidade de stock"
        }
      },
      "grams": "g",
      "internationalUnits": "IU",
      "kilograms": "kg",
      "liters": "l",
      "micrograms": "μg",
      "microgramsPerMilliliter": "μg/ml",
      "milliequivalents": "mEq",
      "milligrams": "mg",
      "milligramsPerKilogram": "mg/kg",
      "milligramsPerMilliliter": "mg/ml",
      "milliliters": "ml",
      "millilitersPerHour": "ml/h",
      "millilitersPerKilogram": "ml/kg",
      "min": "minutos",
      "new": {
        "title": "Nova Unidade"
      },
      "percentage": "%",
      "pills": "comprimidos",
      "puff": "puffs",
      "sachets": "sachets",
      "title": "Unidades",
      "unit": {
        "baseUnit": "Unidade Base",
        "conversionFactor": "Fator de Conversão",
        "externalID": "ID Externo",
        "name": "Nome",
        "precisionDigits": "Números de Precisão",
        "salesItems": "Unidades de Venda",
        "stockItems": "Unidades de Compra",
        "symbol": "Símbolo",
        "title": "Unidade",
        "totalStock": "Stock Total"
      }
    },
    "user": {
      "edit": {
        "title": "Dados Pessoais"
      },
      "new": {
        "agree-terms": "Ao clicar no botão, aceita os nossos termos e condições e confirma que leu a nossa política de privacidade",
        "confirmPassword": "Confirmar password",
        "email-example": "email@example.com",
        "name-example": "Nome de Utilizador",
        "password-example": "password",
        "passwordsDontMatch": "As senhas não são iguais",
        "title": "Registar",
        "validationEmailSent": "Obrigado por se registar, por favor vá ao seu email e valide a sua conta"
      },
      "recoverPassword.success": "Foi-lhe enviado um email com a sua nova password"
    },
    "validations": {
      "fieldRequired": "Campo é obrigatório.",
      "maxCharacters": "No máximo {number} caracteres",
      "minCharacters": "No mínimo {number} caracteres",
      "minQuantity": "Tem de possuir no mínimo {number}",
      "numberCharacters": "Tem de possuir {number} caracteres",
      "specialCharacters": "Não pode inserir caracteres especiais (ex: >, <) ou terminar com um espaço"
    },
    "via": {
      "IntralesionalInjectable": "Injetável - IL",
      "auricularBoth": "Auricular ambos",
      "auricularLeft": "Auricular esquerdo",
      "auricularRight": "Auricular direito",
      "inhalation": "Inalação",
      "intramuscularInjectable": "Injetável - IM",
      "intraosseousInjectable": "Injetável - IO",
      "intraperitonealInjectable": "Injetável - IP",
      "intravenousInjectable": "Injetável - IV",
      "ocularBoth": "Ocular ambos",
      "ocularLeft": "Ocular esquerdo",
      "ocularRight": "Ocular direito",
      "oral": "Oral",
      "rectal": "Retal",
      "subcutaneousInjectable": "Injetável - SC",
      "topical": "Tópico",
      "transdermal": "Transdérmica"
    },
    "visits": {
      "futureVisits": "Visitas Futuras",
      "historicalVisits": "Histórico de Visitas",
      "newVisit": "Nova Visita",
      "status": {
        "closed": "Fechado",
        "open": "Em Aberto",
        "title": "Estado"
      },
      "title": "Visitas",
      "visit": {
        "title": "Visita"
      },
      "visitTypes": {
        "visitSubType": "Seleccionar Sub Tipo de Visita",
        "visitType": "Selecionar Tipo de Visita"
      }
    },
    "waitingRooms": {
      "arrival": "Chegada",
      "late": "Atraso",
      "status": {
        "noPatientsCheckOut": "Nenhum paciente em checkout",
        "noPatientsConsultation": "Nenhum paciente em visita",
        "noPatientsWaiting": "Nenhum paciente em espera",
        "pausedConsultations": "Visitas em Pausa",
        "withScheduling": "Com Agendamento",
        "withoutAppointments": "Sem Agendamentos"
      },
      "title": "Sala de Espera",
      "warning": "Aviso",
      "warningDate": "Data de Aviso"
    },
    "warehouses": {
      "edit": {
        "title": "Editar Armazém"
      },
      "form": {
        "category": {
          "base": "Base"
        },
        "placeholder": {
          "name": "Nome"
        }
      },
      "messages": {
        "createdSuccess": "Armazém criado com sucesso"
      },
      "new": {
        "title": "Novo Armazém"
      },
      "stock": "Stock",
      "title": "Armazéns",
      "viewWarehouse": "Ver Armazém",
      "warehouse": {
        "description": "Notas",
        "name": "Nome",
        "title": "Armazém"
      }
    },
    "workers": {
      "edit": {
        "placeholder": {
          "address": "Morada",
          "email": "exemplo@email.com",
          "mobilePhone": "Telemóvel",
          "name": "Exemplo: João Silva",
          "phone": "Telefone",
          "professionalLetterNumber": "Nº Profissional"
        },
        "select": {
          "employee": "Employee",
          "role": "Selecione Função"
        },
        "title": "Editar Funcionário"
      },
      "form": {
        "select": {
          "worker": "Selecionar Funcionário"
        }
      },
      "new": {
        "placeholder": {
          "address": "Morada",
          "email": "exemplo@email.com",
          "mobilePhone": "Telemóvel",
          "name": "Exemplo: João Silva",
          "phone": "Telefone",
          "professionalLetterNumber": "Nº Profissional"
        },
        "select": {
          "employee": "Employee",
          "role": "Selecione Função"
        },
        "title": "Juntar existente"
      },
      "title": "Funcionários",
      "worker": {
        "address": "Morada",
        "birthdate": "Data de Nascimento",
        "email": "Email",
        "enable": "Ativo",
        "externalID": "ID Externo",
        "mobilePhone": "Telemóvel",
        "name": "Nome",
        "phone": "Telefone",
        "professionalLetterNumber": "Nº Profissional",
        "role": "Função",
        "roles": {
          "administrator": "Administração",
          "assistant": "Auxiliares",
          "nurse": "Enfermagem",
          "other": "Outro",
          "receptionist": "Rececionista",
          "veterinary": "Veterinária"
        },
        "title": "Funcionário"
      }
    }
  };
  _exports.default = _default;
});