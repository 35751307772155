define("crm/models/report-section", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    report: _emberData.default.belongsTo("report", {
      inverse: "reportSections"
    }),
    templateSection: _emberData.default.belongsTo("template-section"),
    reportFields: _emberData.default.hasMany("report-field", {
      inverse: "reportSection"
    })
  });

  _exports.default = _default;
});