define("crm/components/input-pills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["pills"],
    init: function init() {
      this._super();

      if (this.get("selected")) {
        this.set("selectedValue", this.get("selected"));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("selected")) {
        this.set("selectedValue", this.get("selected"));
      } else {
        this.set("selectedValue", null);
      }
    },
    actions: {
      setValue: function setValue(value) {
        if (this.get("selectedValue") === value) {
          this.set("selectedValue", null);
          this.set("selected", null);
          this.get("onchange")();
        } else {
          this.set("selectedValue", value);
          this.set("selected", value);
          this.get("onchange")(value);
        }
      }
    }
  });

  _exports.default = _default;
});