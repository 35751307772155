define("crm/controllers/organizations/organization/group/suppliers/supplier/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    filteredModels: Ember.computed("model.supplierPurchaseInvoices.[]", "model.supplierPurchaseMemos.[]", "model.supplierPurchaseOrders.[]", "model.supplierPurchaseReceipts.[]", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.supplierPurchaseInvoices"))) {
        result.addObjects(this.get("model.supplierPurchaseInvoices"));
      }

      if (!Ember.isEmpty(this.get("model.supplierPurchaseMemos"))) {
        result.addObjects(this.get("model.supplierPurchaseMemos"));
      }

      if (!Ember.isEmpty(this.get("model.supplierPurchaseOrders"))) {
        result.addObjects(this.get("model.supplierPurchaseOrders"));
      }

      if (!Ember.isEmpty(this.get("model.supplierPurchaseReceipts"))) {
        result.addObjects(this.get("model.supplierPurchaseReceipts"));
      }

      return result;
    }),
    columns: Ember.computed("model.@each.supplier", "model.@each.item", function () {
      return [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get("intl").t("purchaseInvoices.new.date"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "dueDate",
        "component": "umt-date",
        "title": this.get("intl").t("purchaseInvoices.new.dueDate"),
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("purchaseInvoices.new.name"),
        "component": "title-models-table-display"
      }, {
        "title": this.get("intl").t("purchaseInvoices.new.paymentMethod"),
        "propertyName": "paymentMethod",
        "className": "column-price-medium"
      }, {
        "propertyName": "paidAmount",
        "title": this.get("intl").t("purchaseInvoices.new.paidAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "propertyName": "payableAmount",
        "title": this.get("intl").t("documents.payableAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "propertyName": "open",
        "title": "Em aberto",
        "className": "column-id text-right"
      }];
    }),
    actions: {
      newSupplierItems: function newSupplierItems() {
        this.send("openModal", {
          entity: "supplier-item",
          action: "new",
          model: {
            supplier: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});