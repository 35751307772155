define("crm/translations/es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contactos"
    },
    "absences": {
      "approve": {
        "title": "Aprobar solicitudes de Ausencia"
      },
      "edit": {
        "title": "Editar Ausencia"
      },
      "new": {
        "title": "Nueva Ausencia"
      },
      "title": "Ausencias",
      "type": {
        "funeral": "Funeral",
        "holidays": "Vacaciones",
        "marriage": "Matrimonio",
        "parental": "Parental",
        "sick": "Enfermedad",
        "title": "Tipo de Ausencia"
      }
    },
    "account": {
      "emailUs": "email",
      "freeVersion": "Versión gratuita!",
      "getStarted": {
        "addData": "Añadar los daros de su hospital o clínica.",
        "clickCreate": "Haga un clic en <Crear>.",
        "clickPlus": "Haga un clic en “+ Crear Hospital”.",
        "guide": "Ver la Guía de inicio",
        "seeDetails": "Después de crear una mascota pode hacer la hospitalization.",
        "step1": "Passo 1",
        "step1Done": "Hospital registrado com sucesso.",
        "step2": "Passo 2",
        "step2Done": "Animales registrados.",
        "step3": "Passo 3",
        "title1": "Registrar Hospital / Clínica",
        "title2": "Registre su primer paciente",
        "title3": "Crear una hospitalization"
      },
      "limitedUse": "La funcionalidad puede ser limitada. Para desbloquear nos envía un",
      "personEditSubtitle": "Actualice sus datos personales.",
      "securityEditSubtitle": "Cambiar la información de inicio de sesión.",
      "settingsSubtitle": "Personaliza el sistema como quieras.",
      "softwareUpdated": "Hay una actualización disponible - {module} versión: {version}.<br> <b>Haga clic aquí para actualizar!</b>",
      "title": "Cuenta"
    },
    "actions": {
      "addLine": "Añadir línea",
      "advanceOneMonth": "Siguiente un mes",
      "approve": "Aprobar",
      "bill": "Facturar",
      "checkIn": "Check-in",
      "checkOut": "Salidas",
      "checkOutTitle": "Poper en salidas sin cerrar la ficha",
      "checkedOut": "Se Ha Ido",
      "checkoutWithoutPaying": "Salir sin pagar",
      "clear": "Borrar Filtros",
      "clearCart": "Borrar",
      "close": "Cerrar",
      "continue": "Continuar",
      "create": {
        "lastAction": "Última Acción"
      },
      "currentHour": "Fecha y hora actuales",
      "delete": "Borrar",
      "details": "Detalles",
      "disable": "Desactivar",
      "doTask": "Realizar Tarea",
      "download": "Descargar",
      "edit": "Editar",
      "editProfile": "Editar Perfil",
      "enable": "Activar",
      "filters": "Filtros",
      "finalConsumer": "Consumidor Final",
      "giveNext": "Dar el siguiente",
      "giveUp": "Ha Desistido",
      "goBackOneMonth": "Recargar un mes",
      "goToConsultation": "Ir a Cita",
      "makeSale": "Venta",
      "markAsPaid": "Marcar como pagado",
      "memos": "Emitir abono",
      "missed": "Ausente",
      "moveStock": "Mover Stock",
      "newPurchaseInvoiceLine": "Nueva línea de compra",
      "newScheduling": "Nueva Cita",
      "openConsultation": "Ver Cita",
      "openShoppingCartDetails": "Ver carrito de compras",
      "openShoppingCartEdit": "Editar carrito de compras",
      "paid": "Pagado",
      "pay": "Pagar",
      "print": "Imprimir",
      "receive": "Recibir",
      "recordResults": "Registrar Resultados",
      "reject": "Rechazar",
      "removeAppointmentConsultation": "Estás seguro de que deseas eliminar esta cita?",
      "reschedule": "Cambiar Cita",
      "rescheduled": "Cita Cambiada",
      "roundPrices": "Ronda",
      "schedule": "Crear Cita",
      "scheduled": "Cita Creada",
      "search": "Cliente o Paciente",
      "searchItems": "Artículo",
      "selectCustomer": "Seleccionar cliente",
      "selectItem": "Seleccionar articulo",
      "selectPatient": "Seleccionar paciente",
      "send": "Enviar",
      "showInvoice": "Generar factura",
      "title": "Acciones",
      "transferStock": "Transferir Stock",
      "undoTask": "Reabrir tarea",
      "view": "Ver",
      "viewItem": "Ver Artículo",
      "viewProfile": "Ver Perfil",
      "viewTask": "Ver Tarea"
    },
    "adjustmentReasons": {
      "adjustmentReason": {
        "title": "Razón"
      }
    },
    "alerts": {
      "adjustmentWithItemStock": "Esta razón solo se usa para artículos que nunca tuvieron un stock inicial",
      "appointmentCheckout": "Visita terminada",
      "appointmentGivenUp": "El cliente ha desistido",
      "appointmentMissed": "Cliente ausente",
      "appointmentRescheduled": "Visita reprogramada",
      "changesSaved": "Sus alteraciones han sido guardadas",
      "creatingItem": "Aquí está creando nuevos artículos",
      "customerWithoutNif": "No puede pagar con los datos de facturación de cliente si el cliente seleccionado no tiene un CIF asociado",
      "done": "Está seguro de que desea marcar esta tarea como realizada?",
      "inProgress": "En desarrollo...",
      "inactiveCustomer": "Este cliente esta inactivo",
      "inactivePatient": "Este paciente esta inactivo",
      "invalidItems": "Hay artículos no válidos",
      "leave": "Si sale perde los cambios",
      "missingOwner": "Sin propietario asignado",
      "missingQuotation": "Este cliente no tiene ningún presupuesto",
      "missingWeight": "There's no weight assigned",
      "mustExistPrice": "Una línea debe tener un precio",
      "negativeStock": "La cantidad no puede ser inferior a 0",
      "noDiagnostic": "Aún no has agregado ningun diagnostico",
      "noDrugs": "Aún no has agregado ninguna medicación",
      "noMcd": "Aún no ha añadido ningún examen",
      "noSpaces": "Este campo no acepta espacios o acentos",
      "noSymptoms": "Aún no has agregado ningun sintoma",
      "noTreatmentPlan": "Aún no has agregado ningun plano de tratamiento",
      "noWarehouseItems": "Este almacén no tiene ningún artículo",
      "referralCustomer": "Este cliente es remitido",
      "referralPatient": "Este paciente es remitido",
      "roomAllreadyWithAppointment": "Esta sala ya tiene una cita asociada en este período",
      "sendEmail": "Está seguro de que desea enviar este correo electrónico?",
      "simplifiedInvoiceWarning": "Una factura simplificada no puede tener un total superior a 100",
      "transferDatesMessage": "La fecha de carga debe ser anterior a la fecha de descarga",
      "weightUpdated": "El peso no se encuentra actualizado, por favor atualice para poder continuar",
      "workerAllreadyWithAppointment": "Este trabajador ya tiene una cita programada para este período"
    },
    "application": {
      "loading": "Cargando"
    },
    "appointments": {
      "appointment": {
        "appointmentDate": "Fecha de programación",
        "appointmentType": "Tipo de cita",
        "arrivalTime": "Hora de Llegada",
        "calls": "Llamadas",
        "dateHasPassed": "No se puede programar una cita para una fecha pasada",
        "end": "Finalizar",
        "endConsultation": "Fin de cita",
        "exitTime": "Hora de Salida",
        "giveUpDate": "Fecha del Desistimiento",
        "giveUpReason": "Motivo del Desistimiento",
        "googleAppointmentId": "Cita con Google ID",
        "madeBy": "Realizado por:",
        "missedDate": "Fecha de la Ausencia",
        "missedReason": "Motivo de la Ausencia",
        "note": "Nota",
        "notice": "Aviso",
        "noticeDate": "Fecha de Aviso",
        "observation": "Observaciones",
        "periodicInfo": "Información Periódica",
        "receptionNotes": "Notas de Recepción",
        "recurrenceInfo": "Infomación Recurrente",
        "resource": "Recursos",
        "scheduledBy": "Programado por:",
        "scheduledFor": "Programado para:",
        "start": "Comenzar",
        "status": "Estado",
        "subject": "Asunto",
        "timeConsultation": "Tiempo de cita",
        "title": "Cita",
        "type": "Tipo",
        "weight": "Peso"
      },
      "checkin": "Checkin",
      "edit": {
        "title": "Editar Cita"
      },
      "filters": {
        "noFilters": "No hay datos porque los filtros estan inactivos",
        "title": "Filtros"
      },
      "messages": {
        "checkOut": "Check out efectuado com sucesso.",
        "deleted": "Se ha eliminado la cita",
        "deletedConsultation": "Se ha eliminado la consulta",
        "reschedule": "Por favor escoja una nueva fecha para la visita de: "
      },
      "new": {
        "associatePatient": "Vincular Paciente",
        "changeCustomer": "Cambiar cliente",
        "changePatientAndCustomer": "Cambiar paciente y cliente",
        "changeSupplier": "Cambiar fornecedor",
        "completeRecord": "Completar Ficha",
        "expectedDuration": "Duración",
        "fast": {
          "title": "Nueva Cita Rápida"
        },
        "noDataToShow": "Sin datos que mostrar",
        "reason": "Motivo",
        "repetition": "Repeticíon",
        "title": "Nueva Cita",
        "to": "hasta",
        "worker": "Seleccionar Trabajador"
      },
      "noWorkerAssigned": "Ningún empleado asignado",
      "status": {
        "checkinWithScheduling": "Checkin con marcacíon",
        "checkinWithoutscheduling": "Checkin sin marcacíon",
        "checkout": "Checkout",
        "finished": "Terminado",
        "getOut": "A salir",
        "givenUp": "Desistencia",
        "inCheckout": "En Checkout",
        "inConsultation": "En cita",
        "inProgress": "Abierto",
        "inVisit": "En cita",
        "missed": "Ausente",
        "paused": "En Pausa",
        "rescheduledPhrase": "Reprogramada",
        "scheduled": "Citado"
      },
      "title": "Citas"
    },
    "approvals": {
      "approve": {
        "title": "Aprobar"
      },
      "messages": {
        "approve": "Estás seguro de que quieres aprobar esta ausencia?",
        "reject": "Estás seguro de que quieres rechazar esta ausencia?"
      },
      "reject": {
        "title": "Rechazar"
      },
      "status": {
        "approved": "Aprobado",
        "pending": "Pendiente",
        "rejected": "Rechazado",
        "title": "Estado"
      },
      "title": "Aprobar de ausencias"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescripción 1",
        "prescription2": "Prescripción 2",
        "round": "Ronda",
        "sunglasses": "Gafas de Sol",
        "title": "Accesorios",
        "wayfarers": "Wayfarer"
      },
      "clothes": {
        "blazerShirt": "Chaqueta camiseta",
        "blazerSweater": "Chaqueta suéter",
        "collarSweater": "Suéter de cuello",
        "graphicShirt": "Camiseta Gráfica",
        "hoodie": "Sudadera",
        "overall": "Mono",
        "shirtCrewNeck": "Camiseta de cuello redondo",
        "shirtScoopNeck": "Camiseta de cuello ovalado",
        "shirtVNeck": "Camiseta de cuello en V",
        "title": "Ropa"
      },
      "colorFabric": {
        "black": "Negro",
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Lavanda",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Naranja Pastel",
        "pastelRed": "Rojo Pastel",
        "pastelYellow": "Amarillo Pastel",
        "pink": "Rosa",
        "red": "Rojo",
        "title": "Colores del tejido",
        "white": "Blanco"
      },
      "eyebrow": {
        "angry": "Enfadado",
        "angryNatural": "Enfadado Natural",
        "default": "Por defecto",
        "defaultNatural": "Natural por defecto",
        "flatNatural": "Liso Natural",
        "raisedExcited": "Emocionado",
        "raisedExcitedNatural": "Emocionado Natural",
        "sadConcerned": "Triste",
        "sadConcernedNatural": "Triste Natural",
        "title": "Cejas",
        "unibrowNatural": "Unicejo",
        "upDown": "Arriba Abajo",
        "upDownNatural": "Arriba Abajo Natural"
      },
      "eyes": {
        "close": "Cerrados",
        "cry": "llorar",
        "default": "Por defecto",
        "dizzy": "Tonto",
        "eyeRoll": "Rolo de Olhos",
        "happy": "Feliz",
        "hearts": "Corazones",
        "side": "Lado",
        "squint": "Estrabismo",
        "surprised": "Sorprendido",
        "title": "Ojos",
        "wink": "Guiño",
        "winkWacky": "Guiño chiflado"
      },
      "hatColor": {
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Lavanda",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Naranja Pastel",
        "pastelRed": "Rojo Pastel",
        "pastelYellow": "Amarillo Pastel",
        "pink": "Rosa",
        "red": "Rojo",
        "title": "Color del Sombrero",
        "white": "Blanco"
      },
      "mouth": {
        "concerned": "Preocupado",
        "default": "Por defecto",
        "disbelief": "Incrédulo",
        "eating": "Comiendo",
        "grimace": "Careta",
        "sad": "Triste",
        "screamOpen": "Miedo",
        "serious": "Serio",
        "smile": "Sonrisa",
        "title": "Boca",
        "tongue": "Lengua",
        "twinkle": "Parpadeo",
        "vomit": "Vómito"
      },
      "skin": {
        "black": "Negra",
        "brown": "Marrón",
        "darkBrown": "Marrón oscuro",
        "light": "Clara",
        "pale": "Pálida",
        "tanned": "Morena",
        "title": "Piel",
        "yellow": "Amarilla"
      },
      "top": {
        "ShortHairShortFlat": "Pelo corto liso",
        "eyepatch": "Parche ojo",
        "hat": "Sombrero",
        "hijab": "Hijab",
        "longHairBigHair": "Pelo Largo",
        "longHairBob": "Pelo Largo Bob",
        "longHairBun": "Moño de pelo largo",
        "longHairCurly": "Pelo Largo Rizado",
        "longHairCurvy": "Pelo Largo Ondulado",
        "longHairDreads": "Pelo Largo Desastroso",
        "longHairFrida": "Pelo Largo Frida",
        "longHairFro": "Pelo Largo Fro",
        "longHairFroBand": "Pelo Largo Fro con cinta",
        "longHairMiaWallace": "Pelo largo Mia Wallace",
        "longHairNotTooLong": "Pelo no muy largo",
        "longHairShavedSides": "Pelo largo con laterales rapados",
        "longHairStraight1": "Pelo largo raya recta",
        "longHairStraight2": "Pelo largo raya recta 2",
        "longHairStraightStrand": "Pelo largo raya zigzag",
        "shortHairDreads1": "Pelo corto temible 1",
        "shortHairDreads2": "Pelo corto temible 2",
        "shortHairFrizzle": "Pelo corto rizado",
        "shortHairShaggyMullet": "Pelo corto despeinado",
        "shortHairShortCurly": "Pelo corto encaracolado",
        "shortHairShortRound": "Pelo Corto",
        "shortHairShortWaved": "Pelo Corto Ondulado",
        "shortHairSides": "Pelo corto a los lados",
        "shortHairTheCaesar": "Pelo corto César",
        "shortHairTheCaesarSidePart": "Pelo Corto César Parte Lateral",
        "title": "Cabeza",
        "turban": "Turbante",
        "winterHat1": "Sombrero de Invierno 1",
        "winterHat2": "Sombrero de Invierno 2",
        "winterHat3": "Sombrero de Invierno 3",
        "winterHat4": "Sombrero de Invierno 4"
      }
    },
    "boxes": {
      "create": "Crear Box",
      "edit": "Editar Box",
      "name": "Nombre",
      "status": {
        "checkin": "Checkin",
        "checkout": "Checkout",
        "free": "Livre",
        "occupied": "Ocupada",
        "title": "Estado"
      },
      "title": "Boxes"
    },
    "brands": {
      "brand": {
        "name": "Nombre",
        "title": "Marca"
      },
      "description": "Ver todas las marcas",
      "form": {
        "placeholder": {
          "name": "Nombre"
        }
      },
      "messages": {
        "createdSuccess": "Marca creada con éxito"
      },
      "new": {
        "title": "Nueva Marca"
      },
      "title": "Marcas"
    },
    "breeds": {
      "0": "Desconocida",
      "1": "Pointer Inglés",
      "10": "Border Terrier",
      "100": "Wire-haired Pointing Griffon Korthals",
      "101": "Spaniel Picardo",
      "102": "Clumber Spaniel",
      "103": "Curly Coated Retriever",
      "104": "Golden Retriever",
      "105": "Briard",
      "106": "Spaniel De Pont-audemer",
      "107": "Saint Germain Pointer",
      "108": "Dogo De Burdeos",
      "109": "Deutsch Langhaar",
      "11": "Bull Terrier",
      "110": "Gran Munsterlander",
      "111": "German Short- Haired Pointing Dog",
      "112": "Setter Irlandés Rojo",
      "113": "Flat Coated Retriever",
      "114": "Labrador Retriever",
      "115": "Field Spaniel",
      "116": "Perro de aguas irlandés",
      "117": "Springer Spaniel Inglés",
      "118": "Springer Spaniel Galés",
      "119": "Sussex Spaniel",
      "12": "Fox Terrier de Pelo Liso",
      "120": "King Charles Spaniel",
      "121": "Smålandsstövare",
      "122": "Drever",
      "123": "Schillerstövare",
      "124": "Hamiltonstövare",
      "125": "French Pointing Dog - Gascogne Type",
      "126": "French Pointing Dog - Pyrenean Type",
      "127": "Swedish Lapphund",
      "128": "Cavalier King Charles Spaniel",
      "129": "Perro De Montaña De Los Pirineos",
      "13": "English Toy Terrier",
      "130": " Pastor De Los Pirineos De Cara Rasa",
      "131": "Terrier Irlandés",
      "132": "Boston Terrier",
      "133": "Perro Pastor De Los Pirineos De Pelo Largo",
      "134": "Slovakian Chuvach",
      "135": "Dobermann",
      "136": "Boxer",
      "137": "Leonberger",
      "138": "Perro Crestado Rodesiano",
      "139": "Rottweiler",
      "14": "Swedish Vallhund",
      "140": "Bulldog",
      "141": "Sabueso Serbio",
      "142": "Sabueso De Istria De Pelo Corto",
      "143": "Sabueso De Istria De Pelo Duro",
      "144": "Dálmata",
      "145": "Sabueso Del Valle De Save",
      "146": "Sabueso De Bosnia De Pelo Cerdoso-llamado Barak",
      "147": "Collie De Pelo Largo",
      "148": "Bullmastiff",
      "149": "Galgo",
      "15": "Perro De Pastor Belga",
      "150": "Foxhound Inglés",
      "151": "Lebrel Irlandés",
      "152": "Beagle",
      "153": "Whippet",
      "154": "Basset Hound",
      "155": "Lebrel Escocés",
      "156": "Espinone",
      "157": "Pastor Alemán",
      "158": "Cocker spaniel americano",
      "159": "Dandie Dinmont Terrier",
      "16": "Antiguo Perro De Pastor Inglés",
      "160": "Fox Terrier De Pelo Alambre",
      "161": "Perro De Castro Laboreiro",
      "162": "Boyero De Las Ardenas",
      "163": "Caniche",
      "164": "Perro De La Sierra De La Estrela",
      "165": "Spaniel Francés",
      "166": "Pastor De Picardía",
      "167": "Braco Del Ariege",
      "168": "Braco Del Borbonesado",
      "169": "Braco De Auvernia",
      "17": "Grifón Del Nivernais",
      "170": "Schnauzer Gigante",
      "171": "Schnauzer",
      "172": "Schnauzer Miniatura",
      "173": "Pinscher Alemán",
      "174": "Pinscher Miniatura",
      "175": "Affenpinscher",
      "176": "Perdiguero Portugués",
      "177": "Sloughi",
      "178": "Perro Finlandés De Laponia",
      "179": "Hovawart",
      "18": "Briquet Grifon Vendeano",
      "180": "Bouvier Des Flandres",
      "181": "Kromfohrländer",
      "182": "Borzoi - Russian Hunting Sighthound",
      "183": "Perro De Pastor Bergamasco",
      "184": "Volpino Italiano",
      "185": "Bolognese",
      "186": "Mastín Napolitano",
      "187": "Sabueso Italiano De Pelo Duro",
      "188": "Cirneco Del Etna",
      "189": "Pequeño Lebrel Italiano",
      "19": "Sabueso Del Ariege",
      "190": "Perro De Pastor De La Maremma Y De Los Abruzos",
      "191": "Pointing Italiano",
      "192": "Sabueso Noruego",
      "193": "Sabueso Español",
      "194": "Chow Chow",
      "195": "Spaniel Japonés",
      "196": "Pekingese",
      "197": "Shih Tzu",
      "198": "Terrier Tibetano",
      "199": "Perro Esquimal Canadiense",
      "2": "Setter Inglés",
      "20": "Gascon Saintongeois",
      "200": "Samoyedo",
      "201": "Rastreador De Hannover",
      "202": "Sabueso Helénico",
      "203": "Bichon De Pelo Rizado",
      "204": "Pudelpointer",
      "205": "Rastreador Montañes De Baviera",
      "206": "Chihuahua",
      "207": "Sabueso Francés Tricolor",
      "208": "Sabueso Francés Blanco Y Negro",
      "209": "Perro De Agua Frison",
      "21": "Gran Sabueso Azul De Gascuña",
      "210": "Perdiguero Frisón",
      "211": "Pastor Holandés",
      "212": "Perdiguero De Drente",
      "213": "Fila Brasileño",
      "214": "Landseer (Tipo Europeo Continental)",
      "215": "Lhasa Apso",
      "216": "Lebrel Afgano",
      "217": "Sabueso Tricolor Serbio",
      "218": "Dogo Del Tibet",
      "219": "Spaniel Tibetano",
      "22": "Sabueso Poitevin",
      "220": "Perro De Muestra Alemán De Pelo Cerdoso",
      "221": "Pequeño Perro León",
      "222": "Xoloitzcuintle",
      "223": "Gran Danés",
      "224": "Terrier Sedoso Australiano",
      "225": "Buhund Noruego",
      "226": "Mudi",
      "227": "Braco Húngaro De Pelo Duro",
      "228": "Lebrel Húngaro",
      "229": "Sabueso Húngaro - Sabueso De Transilvania",
      "23": "Billy",
      "230": "Cazador De Alces Noruego Gris",
      "231": "Malamute De Alaska",
      "232": "Sabueso Eslovaco",
      "233": "Grifón De Muestra Bohemio De Pelo Duro",
      "234": "Terrier Checo",
      "235": "Perro de Montaña del Atlas (Aïdi)",
      "236": "Perro Del Faraón",
      "237": "Ca De Bou",
      "238": "Bichon Habanero",
      "239": "Perro De Pastor Polaco De Las Llanuras",
      "24": "Sabueso Artesiano",
      "240": "Perro De Pastor Polaco De Podhale",
      "241": "Doguillo",
      "242": "Dachsbracke De Los Alpes",
      "243": "Akita",
      "244": "Shiba",
      "245": "Terrier Japonés",
      "246": "Tosa",
      "247": "Hokkaido",
      "248": "Spitz Japonés",
      "249": "Chesapeake Bay Retriever",
      "25": "Porcelaine",
      "250": "Mastín",
      "251": "Lundehund Noruego",
      "252": "Sabueso De Hygen",
      "253": "Sabueso Halden",
      "254": "Cazador De Alces Noruego Negro",
      "255": "Saluki",
      "256": "Husky Siberiano",
      "257": "Collie Barbudo",
      "258": "Norfolk Terrier",
      "259": "Perro de Canaan",
      "26": "Pequeño Sabueso Azul De Gascuña",
      "260": "Perro De Groenlandia",
      "261": "Spitz De Norrbotten",
      "262": "Perro Pastor Croata",
      "263": "Pastor De Karst",
      "264": "Sabueso De Montaña Del Montenegro",
      "265": "Antiguo Perro De Muestra Danés",
      "266": "Gran Grifon Vendeano",
      "267": "Coton De Tulear",
      "268": "Pastor Finlandés De Laponia",
      "269": "Galgo Español",
      "27": "Grifón Azul De Gascuña",
      "270": "American Staffordshire Terrier",
      "271": "Boyero Australiano",
      "272": "Perro Crestado Chino",
      "273": "Perro De Pastor Islandés",
      "274": "Beagle-harrier",
      "275": "Eurásico",
      "276": "Dogo Argentino",
      "277": "Australian Kelpie",
      "278": "Perro De Nutria",
      "279": "Harrier",
      "28": "Gran Basset Grifón Vendeano",
      "280": "Collie De Pelo Corto",
      "281": "Border Collie",
      "282": "Perro De Agua De Romagna",
      "283": "Sabueso Alemán",
      "284": "Perro Negro Y Fuego Para La Caza Del Mapache",
      "285": "Perro De Agua Americano",
      "286": "Terrier Glen De Imaal Irlandés",
      "287": "Foxhound Americano",
      "288": "Laika Ruso-europeo",
      "289": "Laika De Siberia Oriental",
      "29": "Basset Artesiano De Normandía",
      "290": "Laika De Siberia Occidental",
      "291": "Azawakh",
      "292": "Perro Smous Holandés",
      "293": "Shar Pei",
      "294": "Perro Sin Pelo Del Perú",
      "295": "Perro Lobo De Saarloos",
      "296": "Perro Cobrador De Nueva Escocia",
      "297": "Schapendoes Neerlandés",
      "298": "Nederlandse Kooikerhondje",
      "299": "Broholmer",
      "3": "Kerry Blue Terrier",
      "30": "Basset Azul De Gascuña",
      "300": "Sabueso Francés Blanco Y Naranja",
      "301": "Kai",
      "302": "Kishu",
      "303": "Shikoku",
      "304": "Braco Eslovaco de Pelo Duro",
      "305": "Perro De Pastor Mallorqu?n",
      "306": "Gran Sabueso Anglo-francés Tricolor",
      "307": "Gran Sabueso Anglo-francés Blanco Y Negro",
      "308": "Gran Sabueso Anglo-francés Blanco Y Naranja",
      "309": "Sabueso Anglo-francés De Tamaño Mediano",
      "31": "Basset Leonado De Bretaña",
      "310": "Perro De Pastor De Rusia Meridional",
      "311": "Terrier Ruso Negro",
      "312": "Perro Pastor Del Cáucaso",
      "313": "Podenco Canario",
      "314": "Setter Irlandés Rojo Y Blanco",
      "315": "Perro De Pastor Kangal",
      "316": "Perro Lobo Checoslovaco",
      "317": "Lebrel Polaco",
      "318": "Korea Jindo Dog",
      "319": "Perro Pastor De Asia Central",
      "32": "Perro De Agua Portugués",
      "320": "Perro De Agua Español",
      "321": "Sabueso Italiano De Pelo Raso",
      "322": "Thai Ridgeback Dog",
      "323": "Parson Russell Terrier",
      "324": "Fila De San Miguel",
      "325": "Terrier Brasileño",
      "326": "Perro Pastor Australiano",
      "327": "Cane Corso",
      "328": "Akita Americano",
      "329": "Terrier Jack Russell",
      "33": "Welsh Corgi (Cardigan)",
      "330": "Dogo Canario",
      "331": "Pastor Blanco Suizo",
      "332": "Perro De Taiwan",
      "333": "Perro Pastor Rumano De Mioritza",
      "334": "Perro De Pastor Rumano De Los Cárpatos carpatin",
      "335": "Pequeño Perro Ruso",
      "336": "Cimarrón Uruguayo",
      "337": "Go?czy Polski",
      "338": "Pastor De Bosnia Y Herzegovina - Croacia",
      "339": "Bull Terrier Miniatura",
      "34": "Welsh Corgi (Pembroke)",
      "340": "Abisinio",
      "341": "Bobtail Americano",
      "342": "American Bobtail Pelo Corto",
      "343": "Curl Americano",
      "344": "Curl Americano Pelo Largo",
      "345": "Curl Americano Pelo Corto",
      "346": "American Pelo Corto",
      "347": "American Pelo Corto",
      "348": "Mist Australiano",
      "349": "Balinese",
      "35": "Soft Coated Wheaten Terrier Irlandés",
      "350": "Bengalí",
      "351": "Bengalí Pelo Largo",
      "352": "Sagrado de Birmania",
      "353": "Bombay",
      "354": "British",
      "355": "British Pelo Largo",
      "356": "British Pelo Corto",
      "357": "Burmese",
      "358": "Burmilla",
      "359": "Burmilla Pelo Largo",
      "36": "Perro De Pastor Yugoslavo De Charplanina",
      "360": "Chartreux",
      "361": "Chausie",
      "362": "Colorpoint Pelo Corto",
      "363": "Cornish Rex",
      "364": "Cymric",
      "365": "Devon Rex",
      "366": "Donskoy",
      "367": "Egyptian Mau",
      "368": "European",
      "369": "European Burmese",
      "37": "Perro Cazador De Alces Sueco",
      "370": "Exótico",
      "371": "Exótico Pelo Corto",
      "372": "Havana",
      "373": "German Rex",
      "374": "Himalayan",
      "375": "Javanese",
      "376": "Japanese Bobtail",
      "377": "Japanese Bobtail Pelo Largo",
      "378": "Khao Manee",
      "379": "Korat",
      "38": "Basenji",
      "380": "Kurilian Bobtail",
      "381": "Kurilian Bobtail Pelo Corto",
      "382": "Kurilian Bobtail Pelo Largo",
      "383": "LaPerm",
      "384": "LaPerm Pelo Corto",
      "385": "Lykoi",
      "386": "Maine Coon",
      "387": "Manx",
      "388": "Minuet",
      "389": "Minuet Pelo Largo",
      "39": "Pastor De Beauce",
      "390": "Munchkin",
      "391": "Munchkin Pelo Largo",
      "392": "Nebelung",
      "393": "Bosque de Noruega",
      "394": "Ocicat",
      "395": "Oriental",
      "396": "Oriental Pelo Corto",
      "397": "Oriental Pelo Largo",
      "398": "Persa",
      "399": "Peterbald",
      "4": "Cairn Terrier",
      "40": "Boyero De Montana Bernes",
      "400": "Pixiebob",
      "401": "Pixiebob Pelo Largo",
      "402": "Ragamuffin",
      "403": "Ragdoll",
      "404": "Azul Ruso",
      "405": "Savannah",
      "406": "Scottish Fold",
      "407": "Scottish Fold Pelo Largo",
      "408": "Scottish Straight",
      "409": "Scottish Straight Pelo Largo",
      "41": "Perro Boyero De Appenzell",
      "410": "Selkirk Rex",
      "411": "Selkirk Rex Pelo Largo",
      "412": "Seychellois",
      "413": "Seychellois Pelo Corto",
      "414": "Seychellois Pelo Largo",
      "415": "Siamés",
      "416": "Siberiano",
      "417": "Singapura",
      "418": "Snowshoe",
      "419": "Sokoke",
      "42": "Perro Boyero De Entlebuch",
      "420": "Somalí",
      "421": "Sphynx",
      "422": "Thai",
      "423": "Tonkinese",
      "424": "Toyger",
      "425": "Angora Turco",
      "426": "Turkish Van",
      "428": "Teckel",
      "429": "Cão de Gado Transmontano",
      "43": "Perro De Osos De Carelia",
      "44": "Spitz Finlandés",
      "45": "Terranova",
      "46": "Sabueso Finlandés",
      "47": "Sabueso Polaco",
      "48": "Komondor",
      "49": "Kuvasz",
      "5": "Cocker Spaniel Inglés",
      "50": "Puli",
      "51": "Pumi",
      "52": "Braco Húngaro De Pelo Corto",
      "53": "Gran Boyero Suizo",
      "54": "Sabueso Suizo",
      "55": "Sabueso Suizo Pequeño",
      "56": "Perro San Bernardo",
      "57": "Sabueso Estirio De Pelo Áspero",
      "58": "Sabueso Austriaco Negro y Fuego",
      "59": "Pinscher Austriaco",
      "6": "Gordon Setter",
      "60": "Maltese",
      "61": "Grifón Leonado De Bretaña",
      "62": "Pequeño Basset Grifón Vendeano",
      "63": "Sabueso Del Tirol",
      "64": "Lakeland Terrier",
      "65": "Manchester Terrier",
      "66": "Norwich Terrier",
      "67": "Scottish Terrier",
      "68": "Sealyham Terrier",
      "69": "Skye Terrier",
      "7": "Airedale Terrier",
      "70": "Staffordshire Bull Terrier",
      "71": "Spaniel Continental Enano De Compañía",
      "72": "Welsh Terrier",
      "73": "Griffon Bruxellois",
      "74": "Griffon Belge",
      "75": "Petit Brabançon",
      "76": "Schipperke",
      "77": "Chien De Saint Hubert",
      "78": "West Highland White Terrier",
      "79": "Yorkshire Terrier",
      "8": "Australian Terrier",
      "80": "Perro De Pastor Catalán",
      "81": "Perro Pastor De Shetland",
      "82": "Podenco Ibicenco",
      "83": "Perdiguero De Burgos",
      "84": "Mastín Español",
      "85": "Mastín Del Pirineo",
      "86": "Perro De Pastor Portugués",
      "87": "Podenco Portugués",
      "88": "Spaniel Bretón",
      "89": "Rafeiro Del Alentejo",
      "9": "Bedlington Terrier",
      "90": "Spitz Alemán",
      "91": "German Wire- Haired Pointing Dog",
      "92": "Weimaraner",
      "93": "Westphalian Dachsbracke",
      "94": "Bulldog Francés",
      "95": "Pequeño Münsterländer",
      "96": "Terrier Cazador Alemán",
      "97": "Perdiguero Alemán",
      "98": "Perro De Agua Francés",
      "99": "Spaniel Azul De Picardia"
    },
    "bugReports": {
      "bugReport": {
        "message": "Mensaje",
        "subject": "Sujeto",
        "title": "Reportar error"
      },
      "title": "Reportar errores"
    },
    "bundle": {
      "newBundle": "Nuevo Bundle"
    },
    "bundles": {
      "bundle": {
        "title": "Bundle"
      },
      "messages": {
        "createdSuccess": "Bundle creado con éxito"
      },
      "title": "Bundles"
    },
    "calendar": {
      "title": "Calendario"
    },
    "campaigns": {
      "campaign": {
        "audience": "Audiencia",
        "audienceDetermined": "El público es todos los pacientes con revistas programadas entre",
        "audienceEveryone": "La audiencia es cada cliente",
        "description": "Descripción",
        "ended": "Fin",
        "errors": {
          "periodicsAlreadyCommunicated": "Las publicaciones periódicas seleccionadas ya se han comunicado"
        },
        "message": "Mensaje",
        "name": "Nombre",
        "sendDate": "Fecha de envio",
        "species": "Especíes",
        "started": "Inicio",
        "title": "Campaña"
      },
      "new": {
        "createdSuccess": "Campaña creada con éxito",
        "title": "Nueva Campaña"
      },
      "sendConfirmation": "¿Seguro que quieres enviar esta campaña?",
      "title": "Campañas"
    },
    "charges": {
      "title": "Cobranças"
    },
    "chores": {
      "chore": {
        "title": "Otros"
      },
      "title": "Otros"
    },
    "communicationErrors": {
      "communicationError": {
        "appointmentDate": "Fecha",
        "campaign": "Campaña",
        "customer": "Cliente",
        "patient": "Paciente",
        "phoneNumber": "Número",
        "started": "Fecha de envío",
        "text": "Texto",
        "title": "Errores de comunicación"
      },
      "title": "Errores de comunicación"
    },
    "communications": {
      "communication": {},
      "comunication": {
        "title": "Comunicacion"
      }
    },
    "companies": {
      "details": {
        "disabled": "Esta empresa no tiene un plan associado, para iniciar un plan de prueba por favor contáctenos por correo electrónico!"
      },
      "new": {
        "title": "Nueva Empresa"
      }
    },
    "compoundProduct": {
      "newCompoundProduct": "Nuevo Produto Composto",
      "title": "Produto Composto"
    },
    "compoundProducts": {
      "action": {
        "addPrice": "Haga clic aquí para fijar el precio del artículo",
        "dontUsePrice": "Haga clic aquí si no desea utilizar este precio en el artículo"
      },
      "messages": {
        "createdSuccess": "Produto Composto creado con éxito"
      }
    },
    "configurations": {
      "breeds": {
        "dangerous": "Periogoso",
        "description": "Ver todas las razas",
        "name": "Nombre",
        "new": "Nova raça",
        "proportion": "Proporções",
        "title": "Razas"
      },
      "clinic": {
        "title": "Clinico",
        "visitTypes": {
          "description": "Veja todos los tipos",
          "descriptionVisitTypes": "Tipo",
          "name": "Nombre",
          "new": "Nuevo tipo",
          "parent": "Unidade mãe",
          "title": "Tipos de Visita"
        }
      },
      "configItems": {
        "acceptQuotation": "Aceptar presupuesto",
        "acceptedQuotation": "Presupuesto aceptado",
        "enum": {
          "0": {
            "name": "Desparasitado",
            "value": "0"
          },
          "1": {
            "name": "Monitorización",
            "value": "1"
          },
          "2": {
            "name": "Exámenes",
            "value": "2"
          },
          "3": {
            "name": "Tratamientos",
            "value": "3"
          },
          "4": {
            "name": "Vacuna",
            "value": "4"
          }
        },
        "generatePDF": "Generar PDF",
        "generatePayment": "Generar pago",
        "generateReceipt": "Ticket de venta",
        "generatedPDF": "PDF Generado",
        "generatedReceipt": "Ticket generado",
        "goToPos": "Ir a Punto de venta",
        "rejectQuotation": "Rechazar presupuesto",
        "rejectedQuotation": "Presupuesto rechazado",
        "transformIntoOrder": "Convertir en pedido",
        "transformedIntoOrder": "Transformado en pedido"
      },
      "general": {
        "title": "Geral"
      },
      "item": {
        "brands": {
          "list": "Ver todas as marcas",
          "new": "Nova marca",
          "title": "Marcas"
        },
        "itemTypes": {
          "list": "Ver todos los tipos",
          "new": "Nuevo tipo",
          "title": "Tipos"
        },
        "models": {
          "list": "Ver todos os modelos",
          "new": "Nuevo modelo",
          "title": "Modelos"
        },
        "unitDimensions": {
          "list": "Ver todas as dimensões de unidades",
          "new": "Nova dimensão de unidades",
          "title": "Dimensões de Unidades"
        },
        "units": {
          "list": "Ver todas as unidades",
          "new": "Nova unidade",
          "title": "Unidades"
        }
      },
      "organization": {
        "advanced": "Avanzado",
        "edit": "Editar de la organización",
        "featureUnavailable": "Para acceder debes tener el módulo {feature}",
        "name": "Nombre de la organização",
        "receiptSlip": "Comprobante de pago",
        "sender": "Nombre del Remitente",
        "slip": "Facturas de cuentas",
        "title": "Organizacion"
      },
      "organizationCommunication": {
        "beforeSchedule": "Estos SMS se enviarán automáticamente el día anterior a la cita",
        "consultationText": "Texto del cita de consulta",
        "consultationTextTitle": "Hola! Tenga en cuenta que @@ pa tiene una consulta programada para @@ dd, en @@ hh. Gracias por su preferencia.",
        "customerBirthdayText": "Texto de cumpleaños del cliente",
        "customerBirthdayTextTitle": "¡Felicidades!",
        "daysBefore": "Días antes",
        "externalDewormingText": "Texto de desparasitación externa",
        "externalDewormingTextTitle": "Hola! Tenga en cuenta que @@ pa tiene que realizar una desparasitación externa este mes. Gracias por su preferencia.",
        "internalDewormingText": "Texto de desparasitación interna",
        "internalDewormingTextTitle": "Hola! Tenga en cuenta que @@ pa tiene que realizar la desparasitación interna este mes. Gracias por su preferencia.",
        "othersText": "Texto para otras",
        "othersTextTitle": "¡Hola! Les informamos que @@pa tiene programada una revista para este mes. Gracias por la preferencia.",
        "patientBirthdayText": "Texto de cumpleaños del paciente",
        "patientBirthdayTextTitle": "¡Felicidades!",
        "proceduresAppointmentText": "Texto de cita de procedimientos",
        "proceduresAppointmentTextTitle": "Hola! Tenga en cuenta que @@ pa tiene un procedimiento programado para @@ dd, por @@ hh. Gracias por su preferencia",
        "sendHour": "Hora de envío",
        "sendMinute": "Minuto de envío",
        "surgeryAppointmentText": "Texto del cita de cirugía",
        "surgeryAppointmentTextTitle": "Hola! Tenga en cuenta que @@ pa tiene una cirugía programada para @@ dd, en @@ hh. Le recordamos que debe ayunar. Gracias por su preferencia.",
        "title": "Comunicación de la organización",
        "vaccineAppointmentText": "Texto del cita de vacunación",
        "vaccineAppointmentTextTitle": "Hola! Tenga en cuenta que @@ pa tiene una vacuna programada para @@ dd, por @@ hh. Gracias por su preferencia.",
        "vaccineText": "Texto de vacuna",
        "vaccineTextTitle": "Hola! Tenga en cuenta que @@ pa debe vacunarse este mes. Gracias por su preferencia.",
        "wellBeingAppointmentText": "Texto del cita de bienestar",
        "wellBeingAppointmentTextTitle": "Hola! Tenga en cuenta que @@ pa tiene una cita programada para @@ dd, por @@ hh. Gracias por su preferencia."
      },
      "periodicTypes": {
        "description": "Veja todos os periódicos",
        "descriptionPeriodic": "Descripción",
        "name": "Nombre",
        "new": "Nuevo tipo de periódico",
        "period": "Periodo",
        "reinforcementNumber": "Nº de reforços",
        "reinforcementPeriod": "Período de reforço",
        "title": "Tipos de Periódicos"
      },
      "species": {
        "description": "Veja todas as especíes",
        "name": "Nombre",
        "new": "Nova especíe",
        "title": "Especíes"
      },
      "title": "Configuraciones"
    },
    "consultations": {
      "consultation": {
        "bathCutReport": "Informe de baño y esquila",
        "bathCutRequest": "Solicitud de baño y esquila",
        "buttons": {
          "backToConsultation": "Volver a la Consulta",
          "confirmation": "¿Seguro que desea finalizar esta cita?",
          "finishAndPay": "Finalizar y pagar",
          "finishWithoutPay": "Finalizar sin pagar",
          "finishedConsultation": "Finalizar",
          "pausedConsultation": "Cita en Pausa"
        },
        "cantSave": "Debe ingresar una fecha y hora válidas",
        "checkUpSeniorCat": "Informe de revisión felina senior",
        "checkUpSeniorDog": "Informe de revisión canina senior",
        "countTime": "Tiempo",
        "creator": "Creador de citas",
        "debtConsent": "Declaración de reconocimiento de deuda y plan de pago",
        "declarationRGPD": "Declaración y consentimiento para fines de RGPD",
        "dischargeLetter": "Carta de descarga",
        "ecoReport": "Informe de ultrasonido",
        "electrocardiogramCat": "Electrocardiograma de gato",
        "electrocardiogramDog": "Electrocardiograma de perro",
        "euthanasia": "Término de responsabilidad de eutanasia",
        "generalAnesthesia": "Descargo de responsabilidad para la anestesia general",
        "hospitalizationConsent": "Consentimiento de hospitalización",
        "internmentDischarge": "Alta de internamiento",
        "labAnalysisReport": "Informe de análisis de laboratorio",
        "responsabilityTermInternment": "Término de responsabilidad del paciente hospitalizado",
        "responsabilityTermSurgery": "Término de responsabilidad de Cirugía",
        "sanityCertificate": "Atestado de Sanidad",
        "searchOptions": {
          "date": "Fecha"
        },
        "soap": {
          "anamnesis": "Anamnesis",
          "assessment": "Evaluación",
          "description": "Descripción del Plan de Tratamiento",
          "diagnosisDescription": "Descripción del Diagnóstico",
          "diagnostic": "Diagnóstico",
          "medication": "medicamento",
          "objective": "Análisis Objetivo",
          "observations": "Notas internas",
          "physicalTest": {
            "abdomen": "Abdomen",
            "attitude": "Conducta",
            "bloodPressure": "Pres. Art.",
            "capillaryRepletionTime": "CRT",
            "edit": {
              "title": "Editar Exploración Física"
            },
            "ganglia": "Ganglios",
            "heartRate": "Frec. Card.",
            "hydration": "Hidratación",
            "mucous": "Mucosa",
            "observation": "Observaciones",
            "pain": "Dolor",
            "parameters": "Parámetros",
            "respiratoryFrequency": "Frec. Resp.",
            "temperature": "Temperatura",
            "title": "Exploración Física",
            "value": "Valor"
          },
          "prescription": {
            "delivered": "En consulta",
            "prescribed": "Prescribir",
            "title": "Prescripción"
          },
          "subjective": "Análisis Subjetivo",
          "symptom": "Signos Clínicos",
          "symptomDescription": "Descripción del Signo Clínico",
          "title": "S.O.A.P.",
          "treatmentPlan": "Plan de Tratamiento"
        },
        "surgeryDischarge": "Alta de Cirugía",
        "surgeryReport": "Informe de Cirugía",
        "title": "Cita",
        "vet": "Veterinario",
        "visitDate": "Fecha de cita",
        "workerObservation": "Observaciones"
      },
      "messages": {
        "consultationFinished": "Cita finalizada con éxito",
        "consultationSaved": "Cita salvada exitosamente"
      },
      "title": "Citas"
    },
    "creator": {
      "title": "Creado por"
    },
    "crud": {
      "associate": "Asociar",
      "cancel": "Cancelar",
      "close": "Cerca",
      "create": "Crear",
      "createAndAdd": "Crear y agregar nuevos",
      "createSuccess": "Registro con éxito para",
      "created": "Creado",
      "delete": "Borrar",
      "details": "Detalhes",
      "register": "Registro",
      "registered": "Registrado",
      "revert": "Revertir",
      "save": "Guardar",
      "saved": "Guardado",
      "schedule": "Programar",
      "update": "Actualizar",
      "updated": "Actualizado"
    },
    "current": {
      "amountOwed": "Cantidad pendiente",
      "title": "Cuenta"
    },
    "customerFieldTypes": {
      "customerFieldType": {
        "editable": "Editable",
        "label": "Descripción",
        "mandatory": "Obligatorio",
        "title": "Propiedades personalizadas",
        "type": "Tipo",
        "types": {
          "boolean": "Si/no",
          "date": "Fecha",
          "double": "Nr con virgula",
          "integer": "Número",
          "string": "texto"
        },
        "unique": "Único"
      },
      "title": "Propiedades personalizadas"
    },
    "customers": {
      "actions": {
        "disableCustomer": "Deshabilitar cliente"
      },
      "customer": {
        "accumulatedPoints": "Puntos Acumulados",
        "address": "Dirección",
        "addresses": "Dirección de Envío",
        "avatar": "Avatar",
        "balcon": "Banco",
        "barcode": "Código de barras",
        "birthdate": "Fecha de Nacimiento",
        "building": "Portal",
        "cc": "DNI",
        "code": "Código",
        "comment": "Commentario",
        "contacts": {
          "authorizations": "Autorizaciones",
          "contact": "Contato",
          "contactType": "Tipo",
          "contacts": "Contatos",
          "email": "Email",
          "mobilePhone": "Móvil",
          "new": "Nuevo contato",
          "phone": "Teléfono fijo",
          "preferential": "Preferencial",
          "preferentialcontact": "Preferencial",
          "typesTranslation": {
            "1": "Newsletter",
            "2": "Periódicos",
            "3": "Promoções",
            "4": "Proíbido"
          }
        },
        "country": "País",
        "customerName": "Nombre Cliente",
        "customerSince": "Cliente desde",
        "customerTitle": "Título",
        "enable": "Autorizar",
        "errors": {
          "alreadyUsed": "Este cliente ya se ha utilizado en documentos y no se puede actualizar",
          "noNumber": "Este cliente no tiene un contato asociado"
        },
        "female": "Femenino",
        "generalDiscount": "Descuento General",
        "headerDocuments": "Documentos",
        "iban": "IBAN",
        "irsRetention": "Retención IRPF",
        "male": "Masculino",
        "name": "Nombre",
        "newAddress": "Nueva Dirección",
        "nif": "CIF",
        "noAnimal": "Sin pacientes vinculados",
        "origin": "Origen",
        "paymentTerm": "Plazos de Pago",
        "personalCharacteristics": "Características Personales",
        "preferedPaymentMethod": "Método de pago preferido",
        "price": "Precio",
        "profession": "Profesión",
        "reason": "Razón",
        "referencedBy": "Sugerido",
        "sex": "Género",
        "stickyNote": "Notas",
        "street": "Dirección",
        "tariff": "Tarifa",
        "title": "Cliente",
        "town": "Ciudad",
        "unknown": "Desconocido",
        "usedPoints": "Puntos Usados",
        "validCC": "Validad DNI",
        "visitDetails": "Detalles de la Visita",
        "warningList": "Lista de Alertas",
        "zip": "Código Postal"
      },
      "disabled": "Deshabilitado",
      "edit": {
        "title": "Editar Cliente"
      },
      "enabled": "Activo",
      "form": {
        "allreadyExistsVat": "Este CIF ya está registrado",
        "category": {
          "address": "Dirección",
          "complementarDetails": "Información Complementaria",
          "contacts": "Contatos",
          "financialDetails": "Detalles Financieros",
          "personalInformation": "Información Personal",
          "rpContacts": "Contatos Representante"
        },
        "invalidContact": "Este contato no es válido",
        "invalidVat": "CIF debe tener 9 caracteres",
        "placeholder": {
          "addresses": "Dirección de Envío",
          "bancon": "Banco",
          "building": "Portal",
          "cc": "DNI",
          "comment": "Comentarios",
          "contact": "Contato",
          "email": "Email",
          "generalDiscount": "Descuento General",
          "iban": "IBAN",
          "mobilePhone": "Móvil",
          "name": "Por ejemplo: María Salvador",
          "nif": "CIF",
          "personalCharacteristics": "Características Personales",
          "phone": "Teléfono Fijo",
          "profession": "Profesión",
          "stickyNote": "Notas",
          "street": "Dirección",
          "town": "Ciudad",
          "zip": "Código postal"
        },
        "select": {
          "contact": "Seleccione Tipo de Contato",
          "country": "Seleccione País",
          "origin": "Seleccione Origen",
          "paymentTerm": "Plazos de Pago",
          "price": "Seleccione Precio",
          "reason": "Seleccione Motivo",
          "referencedBy": "Recomendado Por",
          "removeReferencedBy": "Cambiar Recomendado Por"
        },
        "vatAnotherCountry": "Este CIF no es válido"
      },
      "inactiveCustomers": "Clientes inactivos",
      "merge": {
        "crud": "Unir",
        "messages": {
          "confirm": "Está seguro que quiere unir estos clientes?",
          "error": "No se pudo unir los clientes",
          "errorSame": "No se puede unir un cliente con si mismo",
          "info": "Esta operación no se puede deshacer!",
          "success": "Clientes unidos con éxito"
        },
        "title": "Unir Clientes"
      },
      "messages": {
        "createdSuccess": "Cliente creado con éxito",
        "disable": "Seguro que quieres deshabilitar a este cliente?",
        "hasDebt": "Este cliente está endeudado, quiere continuar?"
      },
      "new": {
        "saveAndAssociatePatient": "Asociar Paciente",
        "title": "Nuevo Cliente"
      },
      "title": "Clientes"
    },
    "dayWeek": {
      "0": "Domingo",
      "1": "Lunes",
      "2": "Martes",
      "3": "Miércoles",
      "4": "Jueves",
      "5": "Viernes",
      "6": "Sábado"
    },
    "declarations": {
      "prescription": "Pescripción"
    },
    "documents": {
      "accountingAddress": "Dirección de Facturación",
      "accountingName": "Nombre de Facturación",
      "accountingTaxId": "CIF",
      "annul": "Deshacer",
      "annulDocument": "Deshacer documento",
      "annulReason": "Razón de la cancelación",
      "date": "Fecha de pago",
      "document": {
        "title": "Documento"
      },
      "edit": {
        "title": "Editar Documento"
      },
      "financialAccount": "Caja",
      "internalNotes": "Nota interna",
      "invoiceNotes": "Nota de pie de página",
      "payableAmount": "Suma",
      "paymentMethod": "Método de Pago",
      "quotationNotes": "Nota de pie de página",
      "reference": "Remitidos",
      "title": "Documentos",
      "warning": "Está seguro de que desea anular el registro del documento"
    },
    "drugs": {
      "drug": {
        "concentration": "Concentración",
        "delete": "Borrar Medicación",
        "description": "Descripción",
        "dosageTotal": "Dosificación total",
        "edit": "Editar Medicación",
        "name": "Nombre",
        "pills": "Pastillas",
        "quantity": "Quantidad",
        "title": "Medicación",
        "via": "Via",
        "view": "Ver detalles de la medicacion",
        "volumeTotal": "Volumen total"
      },
      "edit": {
        "addMoreTypes": "No encuentras el tipo que necesitas? Envíenos un correo electrónico a",
        "nameNotNull": "Una medicación debe tener un tipo",
        "selectDrugType": "Tipo de Medicación",
        "title": "Editar medicación"
      },
      "messages": {
        "createdSuccess": "Medicación creada con successo"
      },
      "new": {
        "addMoreTypes": "No encuentras el tipo que necesitas? Envíenos un correo electrónico a",
        "nameNotNull": "Una medicación debe tener un tipo",
        "selectDrugType": "Tipo de Medicación",
        "title": "Nueva medicación"
      },
      "title": "Medicacións"
    },
    "emailValidations": {
      "title": "Validación de correo electrónico",
      "validated": "Su correo electrónico fue validado con éxito, ahora puede iniciar sesión"
    },
    "enterprises": {
      "edit": {
        "title": "Editar Remitente"
      },
      "messages": {
        "title": "Remitente creado con sucesso"
      },
      "new": {
        "title": "Nuevo Remitente"
      },
      "title": "Remitente"
    },
    "enums": {
      "chronos": {
        "date": "fecha",
        "dateUpper": "Fecha",
        "day": "día",
        "dayUpper": "días",
        "days": "días",
        "hour": "hora",
        "hourUpper": "Hora",
        "hours": "horas",
        "hoursUpper": "Horas",
        "month": "mes",
        "monthUpper": "Mes",
        "months": "meses",
        "today": "hoy",
        "todayUpper": "Hoy",
        "waitingTime": "tiempo de espera",
        "waitingTimeUpper": "Tiempo de Espera",
        "week": "semana",
        "weekUpper": "Semana",
        "year": "año",
        "yearUpper": "Año",
        "years": "años"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "Emiratos Arabes",
        "AF": "Afganistán",
        "AG": "Antigua y Barbuda",
        "AI": "Anguilla",
        "AL": "Albania",
        "AM": "Armenia",
        "AN": "Países Bajos Antillas",
        "AO": "Angola",
        "AQ": "Antártida",
        "AR": "Argentina",
        "AS": "Samoa Americana",
        "AT": "Austria",
        "AU": "Australia",
        "AW": "Aruba",
        "AZ": "Azerbaiyán",
        "BA": "Bosnia Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BH": "Baréin",
        "BI": "Burundi",
        "BJ": "Benín",
        "BM": "Bermudas",
        "BN": "Brunéi Darussalam",
        "BO": "Bolivia",
        "BR": "Brasil",
        "BS": "Bahamas",
        "BT": "Bután",
        "BV": "Isla Bouvet",
        "BW": "Botsuana",
        "BY": "Bielorusia",
        "BZ": "Belice",
        "CA": "Canadá",
        "CC": "Islas Cocos (Keeling)",
        "CD": "Congo, República Democrática del",
        "CF": "República Centroafricana",
        "CG": "Congo",
        "CH": "Suiza",
        "CI": "Côte d’Ivoire",
        "CK": "Islas Cook",
        "CL": "Chile",
        "CM": "Camerún",
        "CN": "China",
        "CO": "Colombia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cabo Verde",
        "CX": "Isla de Navidad",
        "CY": "Chipre",
        "CZ": "República Checa",
        "DE": "Alemania",
        "DJ": "Djibouti",
        "DK": "Dinamarca",
        "DM": "Dominica",
        "DO": "República Dominicana",
        "DZ": "Argelia",
        "EC": "Ecuador",
        "EE": "Estonia",
        "EG": "Egipto",
        "EH": "Sáhara Occidental",
        "ER": "Eritrea",
        "ES": "España",
        "ET": "Etiopía",
        "FI": "Finlandia",
        "FJ": "Fiyi",
        "FK": "Falkland, Islas (Malvinas)",
        "FM": "Estados Federados de Micronesia",
        "FO": "Islas Feroe",
        "FR": "Francia",
        "GA": "Gabón",
        "GB": "Reino Unido",
        "GD": "Granada",
        "GE": "Georgia",
        "GF": "Guayana Francesa",
        "GH": "Gana",
        "GI": "Gibraltar",
        "GL": "Groenlandia",
        "GM": "Gambia",
        "GN": "Guinea",
        "GP": "Guadalupe",
        "GQ": "Guinea Ecuatorial",
        "GR": "Grecia",
        "GS": "Islas Georgias del Sur y Sandwich del Sur",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HK": "Hong Kong",
        "HM": "Islas Heard y McDonald",
        "HN": "Honduras",
        "HR": "Croacia",
        "HT": "Xls",
        "HU": "Hungría",
        "ID": "Indonesia",
        "IE": "Irlanda",
        "IL": "Israel",
        "IN": "India",
        "IO": "Territorio Britanico del oceano Indico",
        "IQ": "Irak",
        "IR": "Irán (República Islámica de)",
        "IS": "Islandia",
        "IT": "Italia",
        "JM": "Jamaica",
        "JO": "Jordania",
        "JP": "Japón",
        "KE": "Kenia",
        "KG": "Kirguistán",
        "KH": "Camboya",
        "KI": "Kiribati",
        "KM": "Comoras",
        "KN": "San Cristóbal y Nieves",
        "KP": "República Popular Democrática de Corea",
        "KR": "Corea, República de",
        "KW": "Kuwait",
        "KY": "Islas Caimán",
        "KZ": "Kazakstán",
        "LA": "República Democrática Popular de Laos",
        "LB": "Líbano",
        "LC": "Santa Lucía",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Liberia",
        "LS": "Lesoto",
        "LT": "Lituania",
        "LU": "Luxemburgo",
        "LV": "Letonia",
        "LY": "Libia",
        "MA": "Marruecos",
        "MC": "Mónaco",
        "MD": "República de Moldova",
        "MG": "Madagascar",
        "MH": "Islas Marshall",
        "MK": "Macedonia, República de",
        "ML": "Malí",
        "MM": "Myanmar",
        "MN": "Mongolia",
        "MO": "Macao",
        "MP": "Islas Marianas del Norte",
        "MQ": "Martinica",
        "MR": "Mauritania",
        "MS": "Montserrat",
        "MT": "Malta",
        "MU": "Mauricio",
        "MV": "Maldivas",
        "MW": "Malawi",
        "MX": "México",
        "MY": "Malasia",
        "MZ": "Mozambique",
        "NA": "Austria",
        "NC": "Nueva Caledonia",
        "NE": "Niger",
        "NF": "Isla de Norfolk",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Países Bajos",
        "NO": "Noruega",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nueva Zelanda",
        "OM": "Omán",
        "PA": "Panamá",
        "PE": "Perú",
        "PF": "Polinesia Francesa",
        "PG": "Papúa Nueva Guinea",
        "PH": "Filipinas",
        "PK": "Pakistán",
        "PL": "Polonia",
        "PM": "San Pedro y Miquelón",
        "PN": "Islas Pitcairn",
        "PR": "Puerto Rico",
        "PS": "Territorio Ocupado Palestino",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguay",
        "QA": "Catar",
        "RE": "Reunión",
        "RO": "Rumanía",
        "RU": "Rusia",
        "RW": "Ruanda",
        "SA": "Arabia Saudí",
        "SB": "Salomón, Islas",
        "SC": "Seychelles",
        "SD": "Sudán",
        "SE": "Suecia",
        "SG": "Singapur",
        "SH": "Santa Helena",
        "SI": "Eslovenia",
        "SJ": "Svalbard y Jan Mayen",
        "SK": "Eslovaquia",
        "SL": "Sierra Leona",
        "SM": "San Marino",
        "SN": "Senegal",
        "SO": "Somalia",
        "SR": "Surinam",
        "ST": "Santo Tomé y Príncipe",
        "SV": "El Salvador",
        "SY": "República Árabe Siria",
        "SZ": "Swazilandia",
        "TC": "Islas Turcas y Caicos",
        "TD": "Chad",
        "TF": "Territorios Australes Franceses",
        "TG": "Togo",
        "TH": "Tailandia",
        "TJ": "Tayikistán",
        "TK": "Tokelau",
        "TM": "Turkmenistán",
        "TN": "Túnez",
        "TO": "Tonga",
        "TP": "Timor Oriental",
        "TR": "Turquía",
        "TT": "Trinidad y Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwán",
        "TZ": "Tanzania, República Unida de",
        "UA": "Ucrania",
        "UG": "Uganda",
        "UM": "Islas Ultramarinas de Estados Unidos",
        "US": "Estados Unidos",
        "UY": "Uruguay",
        "UZ": "Uzbekistán",
        "VA": "Santa Sede (Ciudad Estado del Vaticano)",
        "VC": "San Vicente y las Granadinas",
        "VE": "Venezuela",
        "VG": "Islas Vírgenes Británicas",
        "VI": "Islas Vírgenes de los Estados Unidos",
        "VN": "Vietnam",
        "VU": "Vanuatu",
        "WF": "Wallis y Futuna",
        "WS": "Samoa",
        "YE": "Yemen",
        "YT": "Mayotte",
        "YU": "Yugoslavia",
        "ZA": "Sudáfrica",
        "ZM": "Zambia",
        "ZW": "Zimbabue"
      },
      "currencies": {
        "$": "Dólar",
        "EUR": "Euro",
        "GBP": "Libra",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "España +34",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Femenino"
        },
        "male": {
          "initial": "M",
          "name": "Masculino"
        }
      }
    },
    "equipmentParameterTypes": {
      "code": "Código",
      "description": "Descripción",
      "new": {
        "title": "Nuevo tipo de parametro"
      },
      "title": "Tipos de parametro"
    },
    "equipmentParameters": {
      "maxVal": "Valor máximo",
      "minVal": "Valor mínimo",
      "new": {
        "title": "Nuevo parametro"
      },
      "title": "Parametros",
      "type": "Tipo de parametro"
    },
    "equipmentTypes": {
      "code": "Código",
      "description": "Descripción",
      "new": {
        "title": "Nuevo tipo de equipo"
      },
      "title": "Tipos de equipo"
    },
    "equipments": {
      "new": {
        "title": "Nuevo equipo"
      },
      "title": "Equipos",
      "type": "Tipo de equipo"
    },
    "error": {
      "adapter": {
        "forbidden": "No tienes permisos para hacer esto"
      },
      "noInternet": "No podemos encontrar una conexión a Internet",
      "noPermission": "¡No tienes permisos para acceder a esta página!",
      "systemDown": "El sistema está actualmente inactivo",
      "unexistingBreed": "Debe seleccionar una raza de la lista"
    },
    "events": {
      "edit": {
        "title": "Editar Casos"
      },
      "messages": {
        "title": "Caso creado con sucesso"
      },
      "new": {
        "title": "Nuevos Casos"
      },
      "title": "Casos"
    },
    "exams": {
      "all": "Todos",
      "delected": "Apagar",
      "doneDate": "Entrega",
      "edit": {
        "title": "Editar Examen"
      },
      "exam": {
        "description": "Descripción",
        "externalLab": "Laboratorio externo",
        "name": "Nombre",
        "notes": "Resultados",
        "started": "Fecha",
        "title": "Examen",
        "view": "Ver Examen"
      },
      "examTypes": {
        "exam_abdominocentesis": "Paracentesis",
        "exam_bio": "Bioquímico",
        "exam_biopsy": "Biopsia",
        "exam_bloodCount": "Recuento Sanguíneo",
        "exam_cat": "TAC",
        "exam_cystocentesis": "Cistocentesis",
        "exam_echocardiogram": "Ecocardiograma",
        "exam_fnac": "Citología por Aspiración Aguja Fina",
        "exam_ionogram": "Ionograma",
        "exam_other": "Otros",
        "exam_pleuralFluidAnalysis": "Análisis Líquido Pleural",
        "exam_smear": "Frotis",
        "exam_ultrasound": "Ecografía",
        "exam_urethralCatheterization": "Cateterismo Uretral",
        "exam_xRay": "Radiografía",
        "title": "Tipo"
      },
      "held": "Celebrada en",
      "messages": {
        "createdSuccess": "Examen creado con éxito"
      },
      "new": {
        "addMoreTypes": "¿No encuentra el tipo de examen que necesita? Envíe un mail a",
        "nameNotNull": "Debe escoger un tipo de examen",
        "selectExamType": "Tipo de Examen",
        "title": "Nuevo Examen"
      },
      "observations": "Observaciones",
      "parameters": {
        "exam_bio_alb": "Albúmina",
        "exam_bio_alp": "Fosfatasa Alcalina",
        "exam_bio_amyl": "Amilasa",
        "exam_bio_bun": "Urea",
        "exam_bio_ca": "Calcio",
        "exam_bio_cl": "Cloro",
        "exam_bio_cpk": "Creatinina Quinasa",
        "exam_bio_cre": "Creatinina",
        "exam_bio_ggt": "Gama de Glutamil transferasa",
        "exam_bio_glu": "Glucosa",
        "exam_bio_k": "Potasio",
        "exam_bio_lap": "LAP",
        "exam_bio_ldh": "Lactato Deshidrogenasa",
        "exam_bio_lip": "Lipasa",
        "exam_bio_mg": "Magnesio",
        "exam_bio_na": "Sodio",
        "exam_bio_nh3": "Amonia",
        "exam_bio_tbil": "Bilirubina Total",
        "exam_bio_tcho": "Colesterol Total",
        "exam_bio_tg": "Triglicéridos",
        "exam_bio_tp": "Proteínas totales",
        "exam_bio_ua": "Acido Úrico",
        "title": "Parámetros"
      },
      "putResults": "Introducir Resultados",
      "requestDate": "Pedido",
      "results": "Escribir resultado",
      "status": {
        "done": "Realizado",
        "pending": "Pendiente",
        "title": "Estado"
      },
      "title": "Exámenes"
    },
    "families": {
      "family": {
        "children": "Sub-unidades",
        "familyType": "Tipo de Familia",
        "name": "Nombre",
        "parent": "Unidad Madre",
        "title": "Familia"
      },
      "form": {
        "placeholder": {
          "children": "Sub-unidades",
          "familyType": "Tipo de Familia",
          "name": "Nombre",
          "parent": "Unidad Madre"
        }
      },
      "title": "Familias"
    },
    "files": {
      "title": "Archivos"
    },
    "filters": {
      "all": "Todos",
      "bundles": "Bundles",
      "byFamily": "Por familia",
      "byUser": "Por usuario",
      "cashTotal": "Total Efectivo",
      "checkTotal": "Cheque",
      "compoundProducts": "Produtos Compostos",
      "creditTotal": "Total en tarjeta",
      "creditors": "Acreedores",
      "debtors": "Deudores",
      "deliveries": "Guías de transporte",
      "directDebitTotal": "Total en débito",
      "invoices": "Facturas",
      "invoicesTotal": "Total en Facturas",
      "memos": "Notas de crédito",
      "memosTotal": "Total en Notas de Crédito",
      "none": "Ninguno",
      "orders": "Orden de Compra",
      "payments": "Pagos",
      "pending": "Pendiente",
      "quotations": "Presupuestos",
      "receipts": "Tickets",
      "requests": "Pedido de Compra",
      "total": "Total",
      "totalFA": "Total em faturas",
      "totalFR": "Total em faturas recibo",
      "totalFS": "Total em faturas simplificadas",
      "totalPayment": "Pagos totales",
      "totalReceipt": "Total em recibos",
      "transferTotal": "Total en transferencias"
    },
    "financialAccounts": {
      "actions": {
        "close": "Cerrar Caja",
        "open": "Abrir Caja"
      },
      "automaticManagment": "No tienes cajas en gestión manual",
      "bankAccount": "Conta bancaria",
      "choose": "Elegir una Caja",
      "close": "Cierre",
      "description": "Ver todas las cajas",
      "edit": {
        "title": "Editar Caja"
      },
      "financialAccount": {
        "accountNumber": "Nr da conta",
        "billed": "Facturado",
        "currency": "Moneda",
        "description": "Descripción",
        "financialAccountFund": "Fundo de Caja",
        "history": "Histórico",
        "iban": "IBAN",
        "institution": "Institución bancaria",
        "isManual": "Manejo Manual",
        "name": "Nombre",
        "received": "Recibido",
        "swift": "SWIFT",
        "title": "Caja",
        "total": "Total",
        "totals": "Totales",
        "type": "Tipo"
      },
      "messages": {
        "allreadyClosed": "Esta Caja ya está cerrada",
        "allreadyOpen": "Esta Caja ya está abierta",
        "createdSuccess": "Caja creada con éxito",
        "editedSuccess": "Caja editada con éxito",
        "noFinancialAccounts": "No tiene ninguna Caja disponible",
        "noFinancialAccountsTypeBankAccount": "No tiene ninguna Caja del tipo de conta bancaria creada",
        "noFinancialAccountsTypeRegister": "No tiene ninguna Caja del tipo de caja creada"
      },
      "new": {
        "title": "Nueva Caja"
      },
      "purpose": "Aquí puedes abrir y cerrar cajas",
      "register": "Caja",
      "seeHistory": "Para ver los datos históricos acceda al módulo de estadísticas",
      "title": "cajas"
    },
    "footer": {
      "rights": "Todos los derechos reservados",
      "toggle": "Cambio vista"
    },
    "form": {
      "administrative": "Administrativo",
      "chooseOne": " -- elige uno --",
      "extra": "Extra",
      "general": "General",
      "health": "Salud",
      "look": "Mira",
      "required": "Este campo es requerido"
    },
    "getChrome": {
      "message": "Esta aplicación sólo funciona con Google Chrome."
    },
    "header": {
      "logout": "Cerrar sesión"
    },
    "help": {
      "faq": {
        "last-rev-date": "29 de junio de 2016",
        "link": "FAQ",
        "title": "Preguntas más frecuentes"
      },
      "manual": {
        "last-rev-date": "29 de junio de 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Ayuda"
    },
    "howTo": {
      "steps": {
        "createCampaign": "Crea una campaña",
        "goToPeriodics": "Acceda al menú del Periódicos",
        "makeAvailable": "Contáctenos para que este sistema esté disponible",
        "startSendingMessages": "Los SMS se enviarán automáticamente en la fecha elegida"
      },
      "text": "Pet Universal ahora tiene la funcionalidad de envío de SMS",
      "title": "Ver Mas"
    },
    "icons": {
      "absence": "hand-o-down",
      "absences": "hand-o-down",
      "action": "check-square-o",
      "actions": "check-square-o",
      "alert": "exclamation-triangle",
      "annul": "ban",
      "appointment": "calendar-o",
      "appointmentDone": "calendar-check-o",
      "appointmentNew": "calendar-plus-o",
      "appointments": "calendar-o",
      "at": "usd",
      "avatar": "image",
      "avatars": "image",
      "boxes": "home",
      "calendar": "calendar-o",
      "calendarMenu": "hourglass-start",
      "calendarPlus": "calendar-plus",
      "campaign": "bullhorn",
      "campaigns": "bullhorn",
      "caretDown": "caret-down",
      "caretUp": "caret-up",
      "charge": "credit-card",
      "charges": "credit-card",
      "check": "check",
      "checkout": "sign-out",
      "clinical": "stethoscope",
      "clinicalMenu": "stethoscope",
      "comment": "comment-o",
      "compoundProduct": "tags",
      "configs": "cog",
      "consultation": "medkit",
      "consultations": "medkit",
      "crm": "address-book-o",
      "currency": "€",
      "currentAccount": "folder-o",
      "customer": "user",
      "customers": "user",
      "delete": "trash",
      "deleteO": "trash-o",
      "detail": "eye",
      "disable": "toggle-off",
      "dischargeLetter": "envelope-square",
      "document": "file-text-o",
      "documentDetails": "folder-open",
      "documents": "folder-o",
      "download": "download",
      "edit": "pencil",
      "email": "envelope-o",
      "enable": "toggle-on",
      "error": "warning",
      "events": "th-large",
      "exam": "eyedropper",
      "examion": "eyedropper",
      "exams": "eyedropper",
      "eye": "eye",
      "female": "venus",
      "file": "file-o",
      "files": "files-o",
      "filter": "filter",
      "financialAccounts": "inbox",
      "financialAccountsClosed": "stop-circle-o",
      "financialAccountsOpen": "play-circle-o",
      "flag": "flag",
      "genderless": "genderless",
      "generatePayment": "file-text",
      "generateReceipt": "file-text-o",
      "giveNext": "retweet",
      "goTo": "arrow-right",
      "group": "sitemap",
      "hermaphrodite": "intersex",
      "hotel": "hotel",
      "houseCalls": "home",
      "hr": "id-badge",
      "internment": "h-square",
      "internments": "h-square",
      "inventory": "archive",
      "invites": "user-plus",
      "invoice": "file-o",
      "item": "tag",
      "itemType": "star-o",
      "laboratories": "flask",
      "laboratory": "flask",
      "liaisons": "hand-o-right",
      "listings": "list-ol",
      "load": "spinner fa-spin",
      "male": "mars",
      "manufacturers": "institution",
      "mobilePhone": "mobile",
      "moveStock": "arrows-v",
      "neutral": "neuter",
      "notes": "sticky-note",
      "order": "shopping-cart",
      "organization": "hospital-o",
      "patient": "paw",
      "patients": "paw",
      "pencilSquare": "pencil",
      "periodic": "history",
      "periodics": "history",
      "phone": "phone",
      "phonecall": "phone",
      "plusCircle": "plus-circle",
      "pointOfSales": "money",
      "prescription": "file-text-o",
      "processing": "hourglass-start",
      "procurement": "handshake-o",
      "purchaseInvoice": "file-text",
      "purchaseInvoices": "file-text",
      "purchaseOrder": "cart-arrow-down",
      "purchaseOrders": "cart-arrow-down",
      "purchaseRequest": "cart-plus",
      "purchaseRequests": "cart-plus",
      "purchases": "exchange",
      "referral": "exchange",
      "referrals": "exchange",
      "reject": "times",
      "remove": "times",
      "reward": "certificate",
      "rewards": "certificate",
      "room": "map-marker",
      "rooms": "map-marker",
      "saft": "file-zip-o",
      "sales": "shopping-basket",
      "salesMenu": "shopping-basket",
      "save": "floppy-o",
      "search": "search",
      "secondTimeEmission": "file-pdf-o",
      "send": "paper-plane",
      "sendCampaign": "mail-forward",
      "sheltereds": "paw",
      "shift": "hand-o-up",
      "shifts": "hand-o-up",
      "shoppingCart": "shopping-cart",
      "simpleView": "tv",
      "sms": "comments",
      "stats": "line-chart",
      "stays": "ticket",
      "study": "x-ray",
      "supplier": "industry",
      "supplierItem": "truck",
      "supplierItems": "truck",
      "suppliers": "industry",
      "task": "tasks",
      "tasks": "tasks",
      "taxes": "bank",
      "template": "file-word-o",
      "templates": "file-word-o",
      "thumbsUp": "thumbs-up",
      "timesCircle": "times-circle",
      "transferStock": "exchange",
      "undetermined": "question",
      "view": "columns",
      "visit": "suitcase",
      "waitingRoom": "sign-in",
      "warehouse": "cubes",
      "warehouses": "cubes",
      "weight": "balance-scale",
      "worker": "user-md",
      "workers": "users"
    },
    "inbounds": {
      "customer": "Customer",
      "facebook": "Facebook",
      "geolocation": "Geolocation",
      "partner": "Partner",
      "publicity": "Publicity",
      "reference": "Remitido",
      "unknown": "Unkwown",
      "website": "Website"
    },
    "index": {
      "addInternments": {
        "description": "Crear o Buscar cliente, llenar los detalles de los animales y el pasante.",
        "title": "Internos"
      },
      "associateCollaborators": {
        "description": "Asociar colaboradores con diferentes funciones.",
        "title": "Colaboradores"
      },
      "comments": "Si tiene alguna duda o comentario, póngase en contacto con",
      "createAccount": {
        "description": "Rellene sus datos y registre su cuenta.",
        "title": "Crear una cuenta"
      },
      "createHospital": {
        "description": "Añadir los detalles del hospital y crear.",
        "title": "Registro Hospital"
      },
      "welcome.subtitle": "Por {corp}"
    },
    "initials": {
      "catheterLeftForelimb": {
        "description": "Miembro anterior izquierda",
        "name": "MAI"
      },
      "catheterLeftHindlimb": {
        "description": "Miembro posterior izquierdo",
        "name": "MPI"
      },
      "catheterRightForelimb": {
        "description": "Miembro anterior derecho",
        "name": "MAD"
      },
      "catheterRightHindlimb": {
        "description": "Miembro posterior derecho",
        "name": "MPD"
      },
      "didntEat": {
        "description": "No comió",
        "name": "NC"
      },
      "faecesDigestedBlood": {
        "description": "Heces sangre digerida",
        "name": "ESD"
      },
      "faecesHard": {
        "description": "Heces duros",
        "name": "ED"
      },
      "faecesHardWithBlood": {
        "description": "Heces duros con sangre",
        "name": "EDS"
      },
      "faecesLiquid": {
        "description": "Heces líquidos",
        "name": "EL"
      },
      "faecesLiquidWithBlood": {
        "description": "Heces líquidos con sangre",
        "name": "ELS"
      },
      "faecesLivingBlood": {
        "description": "Heces sangre vivo",
        "name": "ESV"
      },
      "faecesNormal": {
        "description": "Heces normal",
        "name": "EN"
      },
      "faecesSoft": {
        "description": "Heces sueltas",
        "name": "HS"
      },
      "faecesSoftMoulded": {
        "description": "Heces sueltas moldeados",
        "name": "HSM"
      },
      "fasting": {
        "description": "Ayuno",
        "name": "AY"
      },
      "feedingForced": {
        "description": "Alimentación forzada",
        "name": "AF"
      },
      "feedingNormal": {
        "description": "Alimentación normal",
        "name": "AN"
      },
      "foodForced": {
        "description": "Comida forzada",
        "name": "CF"
      },
      "foodNormal": {
        "description": "Comida normal",
        "name": "CN"
      },
      "foodTube": {
        "description": "Alimentación por sonda",
        "name": "AS"
      },
      "hematuria": {
        "description": "hematuria",
        "name": "OH"
      },
      "noFaeces": {
        "description": "Sin heces",
        "name": "NF"
      },
      "noUrine": {
        "description": "No orina",
        "name": "NO"
      },
      "urineConcentrated": {
        "description": "Orina concentrada",
        "name": "OC"
      },
      "urineConcentratedByExpression": {
        "description": "Orina concentrada por presión",
        "name": "OCP"
      },
      "urineJaundiced": {
        "description": "Orina ictérica",
        "name": "OI"
      },
      "urineNormal": {
        "description": "Orina normal",
        "name": "ON"
      },
      "urineNormalByExpression": {
        "description": "Orina normal por presión",
        "name": "ONP"
      },
      "vomitBile": {
        "description": "Vómito biliar",
        "name": "VB"
      },
      "vomitFoamy": {
        "description": "Vómito espumoso",
        "name": "VE"
      },
      "vomitFood": {
        "description": "Vómito alimenticio",
        "name": "VA"
      },
      "vomitRegurgitation": {
        "description": "Vómitos con regurgitación",
        "name": "VR"
      },
      "vomitWithBlood": {
        "description": "Vómito con sangre",
        "name": "VS"
      }
    },
    "internmentTypes": {
      "hospitalization": {
        "title": "Hospitalización"
      },
      "hotel": {
        "name": "Hotel",
        "namePlural": "Hoteles"
      },
      "infected": {
        "name": "Infectado",
        "namePlural": "Infectados"
      },
      "intensiveCare": {
        "name": "Cuidados intensivos",
        "namePlural": "Cuidados intensivos"
      },
      "normal": {
        "name": "Normal",
        "namePlural": "Normales"
      },
      "outpatient": {
        "name": "Ambulatorio",
        "namePlural": "Ambulatorios"
      },
      "reference": {
        "name": "Remitido",
        "namePlural": "Remitidos"
      },
      "surgery": {
        "name": "Cirugía",
        "namePlural": "Cirugías"
      }
    },
    "internments": {
      "actions": {
        "action": {
          "closeCart": "Cerrar carrito",
          "create": {
            "doneBy": "Hecho por",
            "doubleClick": "Para completar la acción puede hacer simplemente doble clic.",
            "errorCreating": "No hemos podido guardar sus cambios en este momento. Inténtelo de nuevo más tarde.",
            "lastAction": "Última Acción",
            "noNotes": "No hay notas",
            "saveNotes": "Guardar notas",
            "updateScheduling": "Actualizar agendamento",
            "uploadImage": "Cargar imagen",
            "weightChangedDrug": "Los valores desta medicación pueden estar desactualizados, porque el peso dela mascota ha cambiado.",
            "weightChangedSerum": "Los valores de este suero pueden estar desactualizados, porque el peso dela mascota ha cambiado."
          },
          "details": {
            "gallery": "Galería",
            "started": "Fecha de acción"
          },
          "initials": "Iniciales",
          "notes": "Notas",
          "started": "Empezado",
          "status": {
            "done": "Hecha",
            "removed": "Removida",
            "reverted": "Revertida"
          },
          "title": "Acción"
        },
        "shoppingCart": "Acciones de carrito",
        "title": "Acciones"
      },
      "clinicAnimal": {
        "admission": "Admisión",
        "entity": "Paciente",
        "entityPlural": "Pacientes",
        "location": "Localización",
        "pmsID": "PMS ID",
        "status": "Estado",
        "temperament": "Temperamento"
      },
      "clinicPerson": {
        "pmsID": "Número de persona",
        "role": "Papel"
      },
      "clinicalEpisode": {
        "created": "Creado",
        "date": "Fecha",
        "description": "Descripción",
        "entity": "Episodio Clínico",
        "entityPlural": "Histórico Clínico",
        "name": "Nombre",
        "type": "Tipo",
        "updated": "Actualizado"
      },
      "details": {
        "allEvents": "Todos los eventos",
        "allTasks": "Todas las tareas",
        "cantReopen": "Este hospitalización no puede ser reabierto póngase en contato con nosotros para obtener más información",
        "closed": "Cerrado",
        "columnsCount": "Nr de columnas",
        "completedActions": "Las acciones completadas",
        "currentDay": "Día actual",
        "dailyConsumptions": "Los consumos que se muestran aquí son solo para este día",
        "dayTasks": "Tareas del día",
        "dischargeBy": "Alta dada:",
        "doesntDefecate": "No defeca",
        "doesntEat": "No come",
        "doesntUrinate": "No orina",
        "highlight": "Realce",
        "hospitalizedStatus": {
          "average": "Igual",
          "bad": "Peor",
          "good": "Mejor"
        },
        "lock": "Bloquear",
        "locked": "Bloqueado",
        "lockedBy": "Bloqueado por",
        "owesMoney": "El cliente debe dinero",
        "reopen": "Reabrir",
        "reopenCheck": "¿Seguro que quieres volver a abrir?",
        "report": "Informe",
        "startHour": "Hora inicio",
        "step": "Espacio entre horas",
        "taskState": {
          "closed": "Cerrada",
          "open": "Abierta",
          "title": "Estado"
        },
        "total": "Total",
        "unlock": "Desbloquear",
        "warningReason": "Estás viendo esta advertencia porque no has hecho una acción de la tarea",
        "yesterdaysConsumptions": "Para ver los consumos de días anteriores, regrese a la tabla"
      },
      "edit": {
        "title": "Editar Internamento"
      },
      "internment": {
        "clinicalEvolution": "Evolución clínica",
        "consumptions": "Consumos",
        "costEstimate": "Nuevo gasto",
        "create": "Guardar",
        "details": {
          "allEvents": "Todos los eventos",
          "allTasks": "Todas las tareas",
          "cantReopen": "Este hospitalización no puede ser reabierto póngase en contato con nosotros para obtener más información",
          "closed": "Cerrado",
          "columnsCount": "Nr de columnas",
          "completedActions": "Las acciones completadas",
          "currentDay": "Día actual",
          "dailyConsumptions": "Los consumos que se muestran aquí son solo para este día",
          "dayTasks": "Tareas del día",
          "dischargeBy": "Alta dada:",
          "doesntDefecate": "No defeca",
          "doesntEat": "No come",
          "doesntUrinate": "No orina",
          "highlight": "Realce",
          "hospitalizedStatus": {
            "average": "Igual",
            "bad": "Peor",
            "good": "Mejor"
          },
          "lock": "Bloquear",
          "locked": "Bloqueado",
          "lockedBy": "Bloqueado por",
          "owesMoney": "El cliente debe dinero",
          "reopen": "Reabrir",
          "reopenCheck": "¿Seguro que quieres volver a abrir?",
          "report": "Informe",
          "reports": "Informes",
          "startHour": "Hora inicio",
          "step": "Espacio entre horas",
          "taskState": {
            "closed": "Cerrada",
            "open": "Abierta",
            "title": "Estado"
          },
          "total": "Total",
          "unlock": "Desbloquear",
          "warningReason": "Estás viendo esta advertencia porque no has hecho una acción de la tarea",
          "yesterdaysConsumptions": "Para ver los consumos de días anteriores, regrese a la tabla"
        },
        "diagnosis": "Diagnóstico",
        "discharge": "Programar alta",
        "dischargeDate": "Fecha de alta",
        "dischargeText": "Texto de alta",
        "discharged": "Alta programada",
        "duration": "Duración",
        "end": "Dar alta",
        "endReason": "Nota de alta",
        "ended": "Fin",
        "event": {
          "catheter": "Catéter",
          "contactOwner": "Contactar al propietario",
          "faeces": "Heces",
          "feeding": "Alimentar",
          "fluidRate": "Tasa de fluidos",
          "food": "Comida",
          "temperature": "Temperatura",
          "urine": "Orina",
          "vomit": "Vómito"
        },
        "finances": "Finanzas",
        "financialReport": "Informe financiero",
        "hotel": {
          "name": "Hotel",
          "namePlural": "Hoteles"
        },
        "infected": {
          "name": "Infectado",
          "namePlural": "Infectados"
        },
        "internmentType": "Tipo",
        "location": "Lugar",
        "newMsg": "Nueva hospitalización",
        "normal": {
          "name": "Normal",
          "namePlural": "Normales"
        },
        "notes": "Notas",
        "otherTasks": "Otros",
        "outpatient": {
          "name": "Ambulatorio",
          "namePlural": "Ambulatorios"
        },
        "reason": "Motivo",
        "reference": {
          "name": "Remitido",
          "namePlural": "Remitidos"
        },
        "report": {
          "body": "Body / Colar",
          "clean": "Mascota limpia / seca",
          "dressing": "Vendaje",
          "moreDetails": "Ver más detalles de este hospitalización en:",
          "release": "Liberación",
          "removeCatheter": "Retire catéter",
          "verify": "Compruebe antes de entregar la mascota"
        },
        "responsibleVet": "Responsable",
        "revertDischarge": "Revertir alta",
        "started": "Principio",
        "status": "Estado",
        "statusDsc": {
          "close": "Terminado",
          "open": "A transcurrir"
        },
        "surgery": {
          "name": "Cirugía",
          "namePlural": "Cirugías"
        },
        "symptoms": "Síntomas",
        "title": "Internamento",
        "warningMessage": "Advertencia",
        "workers": "Funcionarios"
      },
      "new": {
        "title": "Nuevo Internamento"
      },
      "title": "Internamentos",
      "welcome": {
        "hopi": "Si ya utiliza nuestro software de hospitalización ingrese aquí",
        "trial": "Experimental",
        "trialMsg": "Prueba gratis el nuevo módulo de hospitalización"
      }
    },
    "intl": {
      "attention": "¡Atención!",
      "close": "¿Seguro que quieres cerrar?",
      "discharge": "¿Estás seguro de que quieres programar una descarga?",
      "emptyString": "--",
      "error": "Error",
      "finish": "¿Está seguro de que desea terminar?",
      "information": "info",
      "my": "Mi",
      "noContent": "No existen",
      "or": "o",
      "pause": "Tem a certeza que pretende pausar a consulta?",
      "remove": "¿Estás seguro de que desea eliminar?",
      "revert": "¿Está seguro de que desea revertir?",
      "revertDischarge": "¿Está seguro de que desea revertir la descarga?",
      "showLess": "Mostrar menos...",
      "showMore": "Mostrar más...",
      "unsavedChanges": "Al cancelar todos los cambios no guardados se perderán. ¿Desea continuar?"
    },
    "invite": {
      "invitedMsg": "Se ha enviado una invitación a: ",
      "title": "Invitar"
    },
    "invoices": {
      "changeInvoice": "Cambiar fatura selecionada",
      "invoice": {
        "annuled": "Documento cancelado",
        "date": "Data",
        "emissionDate": "Fecha de emisión",
        "entity": "Entidade",
        "error": "No se pudo procesar",
        "number": "Nº Factura",
        "processing": "Procesando",
        "reference": "Procedencia",
        "status": "Estado",
        "title": "Factura",
        "total": "Total"
      },
      "new": {
        "title": "Nueva Factura"
      },
      "title": "Facturas"
    },
    "itemAdjustments": {
      "itemAdjustment": {
        "title": "Ajuste de artículo"
      },
      "messages": {
        "createdSuccess": "Ajuste de artículo creado con éxito"
      },
      "new": {
        "title": "Nuevo Ajuste de Artículo"
      },
      "title": "Ajustes de artículo"
    },
    "itemSelectList": {
      "compoundTotal": "Total composto",
      "confirm": "Confirmar",
      "name": "Nombre",
      "orderNumber": "Orden",
      "pv": "PV",
      "pvSale": "PV",
      "pvp": "PVP1",
      "pvp2": "PVP2",
      "pvp3": "PVP3",
      "quantity": "Cantidad",
      "value": "Valor"
    },
    "itemTypes": {
      "description": "Ver todos los tipos de artículos",
      "edit": {
        "title": "Editar Tipo Artículo"
      },
      "itemType": {
        "description": "Descripción",
        "name": "Nombre",
        "parent": "Unidad Madre",
        "stockable": "Puede mover acciones",
        "title": "Tipo"
      },
      "new": {
        "title": "Nuevo Tipo Artículo"
      },
      "title": "Tipos de Artículo",
      "translation": {
        "ADS": "ADS",
        "Reprodução": "Reprodução",
        "cmdt": "Exames (MCDT)",
        "cmdt-external_imagiology": "Imagiologia Externa",
        "cmdt-external_lab": "Laboratório externo",
        "cmdt-internal_imagiology": "Imagiologia Interna",
        "cmdt-internal_lab": "Laboratório interno",
        "complementary_funeral_services": "Serviços Funebres",
        "complementary_services": "Serviços Complementares",
        "complementary_services_recovery": "Serviços complementares de recolha",
        "consultations": "Consultas",
        "consultations-external": "Externa",
        "consultations-first_consultation": "Primeira Consulta",
        "consultations-followup": "Seguimento",
        "consultations-general": "Geral",
        "consultations-specialty": "Especialidade",
        "consultations-urgency": "Urgencia",
        "consumables": "Consumiveis",
        "consumables-cleaning": "Limpeza",
        "consumables-disposable": "Descartáveis",
        "consumables-mcdt": "MCDT",
        "consumables-stewardship": "Economato",
        "consumables-surgical": "Cirurgicos",
        "drugs": "Medicamentos",
        "drugs-anesthetics": "Anestésicos",
        "drugs-anti_infective": "Anti-infecciosos",
        "drugs-anti_inflammatory": "Anti-inflamatórios",
        "drugs-antibiotics": "Antibióticos",
        "drugs-antidotes": "Antidotos",
        "drugs-antineoplastics": "Antineoplásicos",
        "drugs-cardiovascular _system": "Sistema Cardiovascular",
        "drugs-chemotherapy": "Quimioterapia",
        "drugs-digestive_system": "Sistema Gastrointestinal",
        "drugs-endocrinological": "Sistema Endócrino",
        "drugs-external_Insulin": "Insulina",
        "drugs-external_otological": "Sistema Auditivo",
        "drugs-hormones": "Hormonas",
        "drugs-immunostimulants": "Imunoestimulantes",
        "drugs-immunosuppressors": "Imunossupressores",
        "drugs-integumentary_system": "Sistema Tegumentar",
        "drugs-musculoskeletal_system": "Sistema Locomotor",
        "drugs-nervous_system": "Sistema Nervoso",
        "drugs-other": "Outros",
        "drugs-respiratory_system": "Sistema Respiratório",
        "drugs-urinary_system": "Sistema Urinário",
        "drugs-visual_system": "Sistema Visual",
        "food": "Alimentação",
        "food-diet": "Dieta",
        "food-physiological": "Fisiologica",
        "food-snaks": "Snaks",
        "health_insurance": "Seguros de saúde",
        "health_plans": "Planos de saúde",
        "hospitalization": "Hospitalização",
        "hospitalization-fluidotherapy": "Fluidoterapia",
        "hospitalization-hospitalization": "Internamento",
        "hospitalization-procedures": "Procedimento Hospitalar",
        "hotel": "Hotel",
        "pecuaria": "Pecuaria",
        "petshop": "Pet Shop",
        "petshop-clothing": "Vestuário",
        "petshop-handling": "Maneio",
        "petshop-home": "Casa",
        "petshop-hygiene": "Higiene",
        "petshop-toys": "Brinquedos",
        "procedures": "Procedimentos",
        "procedures_medical": "Procedimento Médico",
        "procedures_nurse": "Enfermagem",
        "profilaxia": "Profilaxia",
        "profilaxia-external_deworming": "Desparasitantes externo",
        "profilaxia-identification": "Identificação",
        "profilaxia-internal_deworming": "Desparasitantes interno",
        "reference_consultation": "Consulta de referência",
        "referral": "Referência",
        "referral-transportation": "Transporte",
        "rehabilitation": "Reabilitação",
        "soft_tissue_breeder": "Tecidos Moles Sistema Reprodutor",
        "soft_tissue_cardiovascular": "Tecidos Moles Sistema Cardiovascular",
        "soft_tissue_gastrointestinal": "Tecidos Moles Sistema Gastrointestinal",
        "soft_tissue_integumentary": "Tecidos Moles Sistema Tegumentar",
        "soft_tissue_locomotor": "Tecidos Moles Sistema Locomotor",
        "soft_tissue_nervous": "Tecidos Moles Sistema Nervoso",
        "soft_tissue_respiratory": "Tecidos Moles Sistema Respiratório",
        "soft_tissue_urinary": "Tecidos Moles Sistema Urinário",
        "soft_tissue_visual": "Tecidos Moles Sistema Visual",
        "studies": "Estudos",
        "suplementos": "Suplementos",
        "surgery": "Cirurgia",
        "surgery-drugs": "Anestesia e Analgesia",
        "surgery-orthopedics": "Ortopedia",
        "surgery-soft_tissue": "Tecidos Moles",
        "vaccines": "Vacinas",
        "wellness": "Bem estar",
        "wellness-recorte": "Tosquias",
        "wellness-shower": "Banhos"
      }
    },
    "items": {
      "chooseUnit": "El campo de unidad base es obligatorio",
      "companions": "Artículos asociados",
      "disabled": "Deshabilitado",
      "edit": {
        "title": "Editar Artículo",
        "types": "Editar Tipos"
      },
      "enabled": "Activo",
      "entry": {
        "title": "Entrada de pedidos"
      },
      "form": {
        "category": {
          "generic": "Genérico",
          "lastPurchase": "Ultima compra",
          "order": "Orden",
          "period": "Periodo",
          "periodic": "Periódico",
          "sellable": "Vendible",
          "stockable": "Gestiona stock",
          "unstockable": "No Gestiona stock"
        },
        "placeholder": {
          "name": "Nombre"
        }
      },
      "inactiveItems": "Artículos inactivos",
      "item": {
        "barcode": "Código de Barras",
        "cantSelectSame": "Este artículo ya fue agregado",
        "companyItemSubType": "Subtipo de artículo",
        "complementaryDescription": "Descripción interna",
        "cost": "Precio",
        "defaultWarehouse": "Armacén defecto",
        "description": "Descripción",
        "duration": "Duracion",
        "errors": {
          "nameAlreadyUsed": "El nombre de este elemento no se puede cambiar porque ya se usó en documentos",
          "typeAlreadyUsed": "No se puede cambiar porque ya se usó en documentos"
        },
        "examUnit": "Unidad de examen",
        "inactive": "Este artículo está inactivo!",
        "itemClass": {
          "0": "Desconocido",
          "1": "Servicios",
          "2": "Material",
          "3": "Abierto",
          "4": "Subproducto",
          "5": "Finalizado",
          "6": "Mercancía",
          "7": "Propiedades, planta y equipo",
          "title": "Clase"
        },
        "linePrice": "Precio de línea",
        "linePriceOld": "Precio de línea antiguo",
        "linePriceWithVat": "Precio de línea c/iva",
        "maxStock": "Stock Max",
        "minStock": "Stock Min",
        "name": "Nombre",
        "parameter": "Parametro",
        "pmsID": "ID externo",
        "prescription": "Prescripción",
        "price": "Precio (s/IVA)",
        "priceAutomatic": "Precio automático",
        "profit": "beneficio",
        "purchasePrice": "Precio de compra (s/IVA)",
        "pvp": "Precio (c/IVA)",
        "pvp2": "Precio2 (c/IVA)",
        "pvp3": "Precio3 (c/IVA)",
        "pvpDiscount": "Desconto Precio",
        "pvpDiscount2": "Desconto Precio2",
        "pvpDiscount3": "Desconto Precio3",
        "pvpMargin": "Margen",
        "pvpMargin2": "Margen 2",
        "pvpMargin3": "Margen 3",
        "pvpMarkup": "Markup",
        "pvpMarkup2": "Markup 2",
        "pvpMarkup3": "Markup 3",
        "quantity": "Cantidad",
        "reservedQuantity": "Cantidad reservada",
        "saleUnit": "Unidade de Venda",
        "saleUnits": "Unidades de Venda",
        "stock": "Stock",
        "stockUnit": "Unidade de Compra",
        "tarifVariable": "Tarifa variable",
        "title": "Artículo",
        "type": "Tipo",
        "unitPrice": "Precio de unidad",
        "unitPriceWithVat": "Precio de unidad c/iva",
        "warehouse": "Armacén"
      },
      "messages": {
        "createCondition": "Para 1 déjalo en blanco",
        "createdSuccess": "Artículo creado con éxito"
      },
      "new": {
        "duplicate": "Duplicar",
        "title": "Nuevo Artículo"
      },
      "originalRetailPrice": "Precio al por menor",
      "retailPrice1": "Precio al por menor 1",
      "retailPrice2": "Precio al por menor 2",
      "retailPrice3": "Precio al por menor 3",
      "title": "Artículos",
      "totalProducts": "Total de productos",
      "totalServices": "Total de servicios"
    },
    "laboratories": {
      "actions": {
        "saveAndDoneAnalysis": "Guardar y Finalizar"
      },
      "laboratory": {
        "name": "Nombre",
        "title": "Laboratorio"
      },
      "laboratoryType": {
        "external": "Externo",
        "internal": "Interno",
        "title": "Tipo de Laboratorio"
      },
      "new": {
        "title": "Nuevo laboratorio"
      },
      "search": {
        "title": "Buscar"
      },
      "title": "Laboratorios"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "fr": "Francés",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "liaisons": {
      "messages": {
        "title": "Remitente creado con sucesso"
      },
      "new": {
        "title": "Nuevo remitente veterinario"
      },
      "title": "Remitentes",
      "vet": "Veterinario remitente"
    },
    "listings": {
      "active": "Activos",
      "all": "Todos",
      "expired": "Caducados",
      "inflow": "Recibido",
      "others": "Otros",
      "pending": "Pendientes",
      "salesByUser": "Ventas por usuario",
      "taxes": "Impuestos",
      "title": "Listados"
    },
    "login": {
      "forgot": "Se te olvidó tu contraseña?",
      "invalidPassword": "Contraseña invalida",
      "missingAccount": "¿No tiene una cuenta?",
      "submit": "Iniciar sesión",
      "subtitle": "Iniciar sesión en Pet Universal",
      "title": "Iniciar sesión",
      "unknownUser": "Email desconocido"
    },
    "manufacturers": {
      "new": {
        "title": "Nueavo fabricante"
      },
      "title": "Fabricantes"
    },
    "memos": {
      "advance": "ventaja",
      "date": "Fecha",
      "discountAbsence": "Ausencia de descuento en la factura",
      "memo": {
        "title": "Nota de crédito"
      },
      "motive": "Motivo",
      "paymentConditions": "Condiciones de pago",
      "priceError": "Error en el precio",
      "priceincrease": "Aumento de precio",
      "reference": "Procedencia",
      "rejectedProduct": "Producto rechazado",
      "stockRuture": "Ruptura de stock",
      "title": "Notas de crédito"
    },
    "menu": {
      "new": "Nuevo"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "País",
        "email": "Email",
        "entity": "Empresa",
        "entityPlural": "Empresas",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Nome",
        "notes": "Recados",
        "parent": "Unidade mãe",
        "phone": "Telefone",
        "street": "Morada",
        "town": "Cidade",
        "vatNumber": "CIF",
        "zip": "Cod. Postal"
      },
      "description": "Ver todos los modelos",
      "employee": {
        "entity": "Empleado",
        "entityPlural": "Empleados",
        "profile": "Perfil"
      },
      "form": {
        "placeholder": {
          "name": "Nombre"
        }
      },
      "messages": {
        "createdSuccess": "Modelo creado con éxito"
      },
      "model": {
        "name": "Nombre",
        "title": "Modelo"
      },
      "new": {
        "title": "Nuevo Modelo"
      },
      "person": {
        "country": "País",
        "email": "Email",
        "entity": "Persona",
        "entityPlural": "Gente",
        "mobilePhone": "Móvil",
        "name": "Nombre",
        "nif": "UTR",
        "phone": "Teléfono",
        "street": "Dirección",
        "town": "Pueblo",
        "zip": "Código postal"
      },
      "role": {
        "administrator": "Adminstrador",
        "employee": "Empleado",
        "undermanager": "Logistica",
        "unknown": "Desconocido"
      },
      "title": "Modelos",
      "user": {
        "email": "Email",
        "entity": "Usuario",
        "entityPlural": "Usuarios",
        "name": "Nombre",
        "nif": "UTR",
        "password": "Contraseña"
      }
    },
    "modelsTable": {
      "noDataToShow": "Sin datos que mostrar",
      "of": "de",
      "search": "Buscar",
      "show": "Mostrar"
    },
    "modules": {
      "management": {
        "description": "Sistema de gestión para veterinaria",
        "inactiveClient": "El cliente seleccionado está inactivo",
        "tariffNotIncluded": "Su tarifa actual no incluye esta suscripción de módulo",
        "title": "Gestión",
        "unavailableModule": "Funcionalidad no disponible"
      },
      "sales": {
        "title": "Ventas"
      }
    },
    "monitorings": {
      "monitoring": {
        "abdomen": "Abdomen",
        "attitude": "Actitud",
        "bloodPressure": "Presión arterial",
        "capillaryRepletionTime": "Tiempo de relleno capilar",
        "description": "Descripción",
        "ended": "Fin",
        "ganglia": "Ganglios",
        "glucose": "Glucosa",
        "heartRate": "Ritmo cardíaco",
        "hematocrit": "Hematocrito",
        "hydration": "Hidratación",
        "maxValue": "Valor máximo",
        "mentalState": "Estado mental",
        "minValue": "Valor mínimo",
        "mucous": "Mucosas",
        "name": "Nombre",
        "notes": "Notas",
        "other": "Otro",
        "pain": "Dolor",
        "period": "Frecuencia",
        "physicalExame": "Examen físico",
        "pulse": "Pulso",
        "respiratoryFrequency": "Frecuencia respiratoria",
        "started": "Principio",
        "temperature": "Temperatura",
        "title": "Monitorización",
        "totalProteins": "Proteínas totales"
      },
      "title": "Monitorizaciones"
    },
    "navigation": {
      "addAsPatient": "Añadir como paciente",
      "back": "Atrás",
      "confirm": "Confirmar"
    },
    "noRecords": "No hay datos para mostrar",
    "notes": {
      "contact": "Contato",
      "date": "Fecha",
      "name": "Nombre",
      "note": {
        "editNote": "Editar Nota",
        "newNote": "Nueva Nota"
      },
      "noteType": "Tipo de nota",
      "outcomeType": "Resultado",
      "patient": "Paciente",
      "title": "Notas",
      "value": "Nota"
    },
    "openItems": {
      "amount": "Cantidad",
      "debtAmount": "Cantidad que falta pagar",
      "discount": "Descuento",
      "dueDate": "Plazo",
      "name": "Documento",
      "notes": "Notas",
      "payingAmount": "Cantidad a pagar",
      "paymentCantBeZero": "Un pago no puede ser emitido con valores negativos",
      "receiptCantBeZero": "Un recibo debe tener un valor positivo para que se emita",
      "receiptInfoMessage": "Nota: para cerrar una factura con un nc, no es un ticket (no permite cero total), en este caso se realiza un pago, ya permite cero"
    },
    "options": {
      "no": "No",
      "yes": "Sí"
    },
    "orderLines": {
      "new": {
        "title": "Nueva línea"
      },
      "orderLine": {
        "change": "Cambio",
        "discount": "Descuento",
        "id": "ID",
        "liquid": "Precio bruto",
        "name": "Nombre",
        "productsAndServices": "Productos y sevicios",
        "quantity": "Cantidad",
        "title": "Línea de carrito",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Líneas de carrito"
    },
    "orders": {
      "edit": {
        "backToStart": "Comienzo"
      },
      "order": {
        "title": "Carrito"
      },
      "title": "Carritos"
    },
    "organizations": {
      "new": {
        "title": "Nueva Clinica"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Horario",
        "children": "Sub-unidades",
        "company": "Empresa",
        "country": "País",
        "created": "Creado",
        "details": {
          "disabled": "Esta entidad no tiene un plan asociado. Por favor contacte con Pet Universal"
        },
        "email": "Email",
        "enable": "Autorizar",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Buenas tardes",
          "hi": "Hola",
          "morning": "Buenos días",
          "night": "Buenas noches"
        },
        "name": "Nombre",
        "notes": "Notas",
        "parent": "Unidad Madre",
        "patientsCount": "Contador de Pacientes",
        "phone": "Teléfono",
        "plan": "Plan",
        "street": "Dirección",
        "title": "Organización",
        "town": "Ciudad",
        "zip": "Código Postal"
      },
      "title": "Organizaciones"
    },
    "pages": {
      "absences": "Ausencias",
      "account": "Cuenta",
      "actions": "Acciones",
      "active": "Activo",
      "appointments": "Citas",
      "approval": "Aprobar Ausencias",
      "archive": "Archivar",
      "avatar": "Avatar",
      "avatars": "Avatares",
      "backoffice": "Configuraciones",
      "boxes": "Boxes",
      "calendar": "Calendario",
      "campaign": "Campanha",
      "campaigns": "Campanhas",
      "checkinWait": "Sala de Espera",
      "checkoutWait": "Salidas",
      "clinical": "Clínico",
      "clinicalHistory": "Histórico Clínico",
      "clinicalReport": "Informe clínico",
      "clinicalSummary": "Resumen clínico",
      "clinicalWaitingRooms": "Sala de Espera",
      "companies": "Empresas",
      "company": "Empresa",
      "configurations": "Configuraciones",
      "consultation": "Cita",
      "consultations": "Citas",
      "corporate": "Empresa",
      "crm": "CRM",
      "currentAccount": "Conta Corrente",
      "currentAccounts": "Contas Correntes",
      "custom": "Día",
      "customers": "Clientes",
      "day": "Día",
      "day2": "Día v2",
      "debt": "Deuda",
      "debts": "Deudas",
      "details": "Detalles",
      "diagnosis": "Diagnóstico",
      "documents": "Documentos",
      "edit": "Editar",
      "employees": "Empleados",
      "equipment": "Equipo",
      "equipmentParameter": "Parametro",
      "equipmentParameterType": "Tipo de parametro",
      "equipmentParameterTypes": "Tipos de parametro",
      "equipmentParameters": "Parametros",
      "equipmentType": "Tipo de equipo",
      "equipmentTypes": "Tipos de equipo",
      "equipments": "Equipos",
      "events": "Casos",
      "exams": "Examenes",
      "excess": "Excesos",
      "financialAccountAuto": "Histórico",
      "financialAccounts": "cajas",
      "financialReport": "Informe financiero",
      "general": "Regras",
      "group": "Grupo",
      "hotel": "Hotel",
      "howTo": "SMS",
      "hr": "HR",
      "internal": "Interno",
      "internment": "Hospitalización",
      "internments": "Hospitalizaciones",
      "inventory": "Inventario",
      "invite": "Invitar",
      "invites": "Invitaciones",
      "invoice": "Factura",
      "invoices": "Facturas",
      "invoicesMemos": "Documentos",
      "item": "Articulos",
      "itemAdjustments": "Ajustes de artículo",
      "items": "Articulos",
      "jasmin": "Jasmin",
      "laboratories": "Laboratorios",
      "liaisons": "Remitentes",
      "listings": "Listados",
      "manufacturer": "Fabricante",
      "manufacturers": "Fabricantes",
      "movements": "Movimientos de stock",
      "new": "Nuevo",
      "openItems": "Documentos en aberto",
      "openPurchaseInvoice": "Compras abiertas",
      "openPurchaseInvoices": "Compras en abierto",
      "organization": "Organización",
      "organizations": "Organizaciones",
      "overview": "Panorama general",
      "overviewShopping": "Panorama general de carritos",
      "patients": "Pacientes",
      "periodics": "Periódicos",
      "pointOfSales": "Punto de venta",
      "procurement": "Abastecimiento",
      "purchaseInvoice": "Compra",
      "purchaseInvoices": "Compras",
      "purchaseInvoicesDocuments": "Documentos",
      "purchaseMemo": "Nota de crédito",
      "purchaseMemos": "Notas de crédito",
      "purchaseOrder": "Detalles de la orden de compra",
      "purchaseOrders": "Orden de compra",
      "purchaseRequest": "Detalles de la orden de compra",
      "purchaseRequests": "Orden de compra",
      "purchases": "Compras",
      "questionnaireInvite": "Questionário",
      "questionnaireInvites": "Questionário",
      "quotation": "Presupuesto",
      "quotations": "Presupuestos",
      "reception": "Recepción",
      "recoverPassword": "Recuperación de contraseña",
      "referral": "Remitidos",
      "referrals": "Remitidos",
      "reward": "Puntos",
      "rewards": "Puntos",
      "saft": "SAF-T",
      "sales": "Ventas",
      "schedule": "Agenda",
      "scheduleWait": "Atraso",
      "security": "Seguridad",
      "settings": "Configuración",
      "sheltereds": "Sheltereds",
      "shifts": "Turnos",
      "shopping": "Compras",
      "shoppingCart": "Carrito de compras",
      "shoppingCarts": "Carritos de compras pendientes",
      "shortage": "Debajo del mínimo",
      "simpleView": "Vista simple",
      "sms": "SMS",
      "stats": "Estadística",
      "stays": "Estada",
      "supplier": "Proveedor",
      "suppliers": "Proveedores",
      "tasks": "Tareas",
      "templates": "Relatorios",
      "today": "Stock Actual",
      "todoChore": "Tarea",
      "todoChores": "Tareas",
      "todoDrug": "Medicación",
      "todoDrugs": "Medicaciones",
      "todoExam": "Examen",
      "todoExams": "Exámenes",
      "todoMonitoring": "Monitorizacion",
      "todoMonitorings": "Monitorizaciones",
      "todoSerum": "Suero",
      "todoSerums": "Sueros",
      "transferStock": "Transferir Stock",
      "updateItems": "Editar Tipos",
      "visitWait": "En cita",
      "waitingRoom": "Sala de Espera",
      "waitingRooms": "Sala de Espera",
      "warehouse": "Almacén",
      "warehouses": "Almacénes",
      "week": "Semana",
      "workers": "Trabajadores"
    },
    "patients": {
      "disabled": "Deshabilitado",
      "edit": {
        "title": "Editar Paciente"
      },
      "enabled": "Activo",
      "form": {
        "category": {
          "fisicalDetails": "Detalles Fiscales",
          "other": "Otros",
          "personalInformation": "Información Personal"
        },
        "environmentType": {
          "both": "Ambos",
          "indoor": "Interior",
          "outdoor": "Exterior"
        },
        "placeholder": {
          "behavior": "Conducta",
          "census": "Censo",
          "chip": "Número de Chip",
          "comments": "Comentarios",
          "commentsExtra": "Comentarios Extra",
          "eyes": "Por Ejemplo: Azul",
          "fur": "Por Ejemplo: Corto",
          "height": "Altura",
          "insuranceType": "Tipo de Seguro",
          "name": "Por Ejemplo: Manchitas",
          "notes": "Otra Información",
          "passaport": "Número de Pasaporte",
          "pathology": "Seleccionar Patología",
          "pedigree": "Pedigree",
          "temperament": "Por Ejemplo: Calmada"
        },
        "select": {
          "breed": "Seleccionar Raza",
          "diet": "Seleccionar Dieta",
          "environmentType": "Seleccionar Tipo de Ambiente",
          "owner": "Seleccionar Dueño",
          "price": "Seleccionar Precio",
          "species": "Seleccionar Especie",
          "tail": "Seleccionar Tipo de Cola",
          "veterinary": "Seleccionar Veterinario Responsable"
        }
      },
      "inactivePatients": "Pacientes inactivos",
      "messages": {
        "createdSuccess": "Paciente creado con éxito",
        "mustHaveWeight": "El paciente debe tener un peso para usar la calculadora"
      },
      "new": {
        "title": "Nuevo Paciente"
      },
      "number": "Nº de Pacientes",
      "patient": {
        "age": "Edade",
        "alert": "Alerta",
        "avatar": "Avatar",
        "behavior": "Conducta",
        "birthdate": "Fecha de Nacimiento",
        "breed": "Raza",
        "census": "Censo",
        "chip": "Chip",
        "comment": "Comentarios",
        "comments": "Comentarios",
        "commentsExtra": "Comentarios Extra",
        "deathReason": "Razón de la muerte",
        "deathdate": "Fecha de Fallecimiento",
        "deceased": "Fallecido",
        "deleted": "Eliminar",
        "diet": "Dieta",
        "environmentType": "Tipo de Ambiente",
        "eyes": "Ojos",
        "female": "Hembra",
        "fur": "Pelo",
        "furColor": "Color",
        "genderless": "Sin género",
        "height": "Altura",
        "hermaphrodite": "Hermafrodita",
        "id": "ID",
        "idcard": "Identidad",
        "inactive": "Inactivo",
        "insurance": "Seguro",
        "insuranceType": "Tipo de Seguro",
        "location": "Localización",
        "male": "Masculino",
        "mixedbreed": "Raza Cruzada",
        "name": "Nombre",
        "neutral": "Neutral",
        "newWeight": "Nuevo Peso",
        "noOwner": "No se puede programar una cita para un paciente sin propietario",
        "noOwnerConsultation": "No se puede programar una consulta para un paciente sin propietario",
        "notes": "Notas",
        "otherSpecies": "Otras especies",
        "ownerPrefered": "Dueño Preferencial",
        "passport": "Pasaporte",
        "pathologies": "Patologías",
        "pathology": "Patología",
        "patientSince": "Paciente desde",
        "pedigree": {
          "no": "No",
          "title": "Pedigree",
          "yes": "Si"
        },
        "pmsID": "ID antigo",
        "price": "Precio",
        "sex": "Sexo",
        "species": "Especie",
        "status": "Estado",
        "sterilized": "Esterilizado",
        "tailType": "Tipo de Cola",
        "tattoo": "Tatuaje",
        "tattooNumber": "Número de Tatuaje",
        "temperament": {
          "agressive": "Agresivo",
          "good": "Bueno",
          "moderate": "Moderado",
          "title": "Temperamento"
        },
        "title": "Paciente",
        "unknown": "Desconocido",
        "veterinaryPrefered": "Veterinario Preferente",
        "weights": {
          "changedDrug": "Los valores de este medicamento pueden estar desactualizados, porque el peso dela mascota ha cambiado.",
          "changedSerum": "Los valores de este soro pueden estar desactualizados, porque el peso dela mascota ha cambiado.",
          "title": "Pesos",
          "weight": {
            "new": "Nuevo Peso",
            "title": "Peso",
            "weighted": "Fecha"
          }
        }
      },
      "title": "Pacientes"
    },
    "payments": {
      "noOpenItems": "Este cliente no tiene notas de crédito"
    },
    "periodics": {
      "actions": {
        "done": "Marcar como hecho"
      },
      "messages": {
        "createdSuccess": "Periódico creado con éxito"
      },
      "new": {
        "cadency": "Cadencia",
        "description": "Descripción",
        "intake": "Consumiendo",
        "labels": {
          "cadency": "Cadencia",
          "description": "Descripción",
          "observations": "Observaciones",
          "product": "Seleccione el Producto",
          "selectPeriodicType": "Tipo de Periódico",
          "species": "Seleccione las Especies que Desea"
        },
        "nextDate": "Próxima Administración",
        "numberOfReinforcements": "Nº de Refuerzos",
        "observations": "Observaciones",
        "pastDate": "Está introduciendo una fecha en el pasado",
        "previousDate": "Última Administración",
        "product": "Producto",
        "reinforcmentCadency": "Cadencia del Refuerzo",
        "species": "Especies",
        "title": "Nuevo Periódico",
        "type": "Tipo de Periódico"
      },
      "periodic": {
        "intake": "Toma",
        "intakeDate": "Fecha",
        "nextPeriodic": "Siguiente",
        "title": "Periódico",
        "type": "Tipo"
      },
      "periods": {
        "day": "Día",
        "drugs": {
          "noRepeat": "Repita siempre",
          "period1": "q1h",
          "period12": "BID",
          "period120": "q5d",
          "period168": "q7d",
          "period2": "q2h",
          "period24": "SID",
          "period3": "q3h",
          "period4": "q4h",
          "period48": "q48h",
          "period5": "q5h",
          "period6": "QID",
          "period72": "q72h",
          "period8": "TID",
          "period96": "q4d",
          "singleTake": "Única toma"
        },
        "month": "Mes",
        "today": "Hoje",
        "week": "Semana",
        "year": "Año"
      },
      "status": {
        "communicated": "Comunicado",
        "done": "Administrado",
        "nextDose": "Próxima Dósis",
        "notCommunicated": "No comunicado",
        "scheduled": "Programado",
        "taken": "Tomado",
        "title": "Estado",
        "toTake": "Para Tomar"
      },
      "title": "Periódicos"
    },
    "plans": {
      "entity": "Plans",
      "new": {
        "title": "Nuevo Plano"
      },
      "plan": {
        "entity": "Plan",
        "name": "Nombre"
      }
    },
    "pointOfSales": {
      "animal": "Animal",
      "barcodeScan": "Escaneo de código de barras",
      "bill": "Faturar",
      "billingDatas": {
        "billingData": {
          "title": "Datos de facturación"
        },
        "choose": "Escoger",
        "finalCustomer": "Consumidor final"
      },
      "camera": "Camara",
      "change": "Cambio",
      "discount": "Descuento",
      "documentTypes": {
        "documentType": {
          "title": "Tipo de documentos"
        },
        "invoice": "Factura",
        "invoiceReceipt": "Factura recibo",
        "simplifiedInvoice": "Factura simplificada",
        "title": "Tipos de documentos"
      },
      "errors": {
        "debt": "Deuda!",
        "pdfNotEmiting": "PDF aún no disponible, por favor descárguelo manualmente",
        "pleaseTryDocuments": "Se emitió el documento pero se produjo un error al recuperar el pdf, intente en la página de documentos"
      },
      "issue": "Emitir",
      "payAndCheckout": "Pagar y checkout",
      "paymentMethods": {
        "amountPaid": "Cantidad entregada",
        "card": "Tarjeta electrónica",
        "cash": "Efectivo",
        "check": "Cheque",
        "checkDate": "Fecha de cheque",
        "checkNumber": "Número de cheque",
        "directDebit": "Débito directo",
        "paymentMethod": {
          "title": "Método de pago"
        },
        "title": "Métodos de pago",
        "transfer": "Transferencia"
      },
      "quantity": "Cantidad",
      "saveNewPrice": "Guardar nuevo precio?",
      "search": "Buscar Cliente",
      "select": "Seleccionar Client",
      "title": "Punto de venta",
      "warehouse": "Almacén"
    },
    "prices": {
      "price": {
        "title": "Precio"
      },
      "title": "Precios"
    },
    "procurement": {
      "title": "Abastecimiento"
    },
    "purchaseInvoiceLines": {
      "messages": {
        "createdSuccess": "Línea de compra creada con éxito"
      },
      "new": {
        "title": "Nueva línea de compra"
      },
      "title": "Línea de compra"
    },
    "purchaseInvoices": {
      "duplicate": {
        "title": "Duplicar compra"
      },
      "edit": {
        "saving": "Guardando cambios",
        "savingLines": "Guardando las líneas"
      },
      "invalid": {
        "amount": "Cantidad inválida",
        "badAdjustment": "Faltan datos en el ajuste de inventario",
        "badHeader": "Faltan datos en la cabecera",
        "badLines": "Líneas inválidas",
        "cost": "costo inválido",
        "noLines": "Faltan líneas",
        "noSupplier": "Falta el proveedor",
        "vat": "IVA inválido"
      },
      "lastPurchasePrice": "Último precio de compra",
      "new": {
        "date": "Reception",
        "dueDate": "Fecha de vencimiento",
        "exists": "Ya existe un documento con este nombre",
        "financialAccount": "Caja",
        "internalNotes": "Notas internas",
        "name": "Nombre",
        "notes": "Notas",
        "paidAmount": "Cantidad pagada",
        "payment": "Pago",
        "paymentMethod": "Método de pago",
        "supplier": "Proveedor",
        "total": "Total"
      },
      "open": {
        "title": "Compras en abierto"
      },
      "purchaseInvoice": {
        "internalData": "Datos internos",
        "lines": "Líneas",
        "paid": "Pagado",
        "payment": "Pago",
        "status": {
          "issued": "Emitido",
          "saved": "Gravado",
          "title": "Estado"
        },
        "title": "Compra"
      },
      "title": "Compras"
    },
    "purchaseOrders": {
      "date": "Fecha",
      "details": {
        "title": "Detalles de la orden de compra"
      },
      "done": {
        "title": "Está seguro de que desea marcar esta orden de compra como hecha?"
      },
      "duplicate": {
        "title": "Duplicar Orden de compra"
      },
      "edit": {
        "title": "Editar Orden de compra"
      },
      "info": {
        "billed": "En factura",
        "issued": "emitida",
        "noneBilled": "Sin orden de compra facturada",
        "noneIssued": "Sin orden de compra emitida",
        "noneSaved": "Sin orden de compra registrada",
        "saved": "registrada"
      },
      "item": "Artículo",
      "line": {
        "lastPrice": "Último € /"
      },
      "markedDone": {
        "message": "Está seguro de que desea marcar esta orden de compra como hecha? Esta acción no se puede deshacer.",
        "title": "Marcar como hecha"
      },
      "messages": {
        "successfullyIssued": "Orden de compra emitida con éxito",
        "successfullySaved": "Orden de compra guardada con éxito"
      },
      "name": "Nombre",
      "new": {
        "title": "Nueva Orden de compra"
      },
      "note": "Nota",
      "payableAmount": "Suma estimada",
      "quantity": "Cantidad",
      "receive": {
        "title": "Está seguro de que desea marcar esta orden de compra como recibida?"
      },
      "send": {
        "title": "Está seguro de que desea enviar esta orden de compra?"
      },
      "status": {
        "markedDone": "Hecha",
        "received": "Recibida",
        "sent": "Enviada",
        "title": "Estado"
      },
      "supplier": "Proveedor",
      "title": "Orden de compra",
      "updatedSuccess": "Orden de compra actualizada con éxito"
    },
    "purchaseRequests": {
      "date": "Fecha",
      "details": {
        "title": "Detalles del pedido de compra"
      },
      "done": {
        "title": "Está seguro de que desea marcar este pedido de compra como hecha?"
      },
      "duplicate": {
        "title": "Duplicar Pedido de compra"
      },
      "edit": {
        "title": "Editar Pedido de compra"
      },
      "info": {
        "billed": "En factura",
        "inOrder": "en orden",
        "issued": "emitida",
        "noneBilled": "ninguna en facturada",
        "noneInOrder": "ninguna en orden",
        "noneIssued": "ninguna emitida",
        "noneOrdered": "ninguna ordenada",
        "noneSaved": "ninguna gravada",
        "ordered": "ordenada",
        "saved": "gravada"
      },
      "item": "Artículo",
      "line": {
        "lastPrice": "Último € /"
      },
      "markedDone": {
        "message": "Está seguro de que desea marcar este pedido de compra como hecha? Esta acción no se puede deshacer.",
        "title": "Marcar como hecha"
      },
      "messages": {
        "createdSuccess": "Nuevo Pedido creado con éxito"
      },
      "name": "Nombre",
      "new": {
        "title": "Nuevo Pedido de compra"
      },
      "note": "Nota",
      "payableAmount": "Suma estimada",
      "quantity": "Cantidad",
      "receive": {
        "title": "Está seguro de que desea marcar este pedido de compra como recibido?"
      },
      "send": {
        "title": "Está seguro de que desea enviar este pedido de compra?"
      },
      "status": {
        "received": "Recibido",
        "sent": "Enviado",
        "title": "Estado"
      },
      "supplier": "Proveedor",
      "title": "Pedido de compra",
      "updatedSuccess": "Pedido de compra actualizado con éxito"
    },
    "purchases": {
      "discount": "Desconto",
      "lot": "Lote",
      "messages": {
        "successfully": "Compra exitosa",
        "successfullySaved": "Compra guardada con éxito"
      },
      "price": "Precio",
      "quantity": "Cantidad",
      "quantityPromotion": "Regalos",
      "shelfLife": "Validez",
      "title": "Compras"
    },
    "quotations": {
      "changeQuotation": "Cambiar Presupuesto",
      "name": "Nombre",
      "new": "Nuevo presupuesto",
      "quotation": {
        "title": "Presupuesto"
      },
      "quotationsConsumed": "Porcentaje de presupuesto consumido",
      "removed": "Presupuesto desvinculado",
      "selected": "Presupuesto asignado",
      "title": "Presupuestos"
    },
    "receipts": {
      "edit": {
        "title": "Editar ticket"
      },
      "noOpenItems": "Este cliente no tiene facturas abiertas",
      "receipt": {
        "title": "Ticket"
      },
      "title": "Tickets"
    },
    "referrals": {
      "title": "Remitidos"
    },
    "requestLines": {
      "new": {
        "title": "Nueva línea"
      },
      "orderLine": {
        "change": "Cambio",
        "discount": "Descuento",
        "id": "ID",
        "liquid": "Precio bruto",
        "name": "Nombre",
        "productsAndServices": "Productos y sevicios",
        "quantity": "Cantidad",
        "title": "Línea de carrito",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Líneas de carrito"
    },
    "rewards": {
      "reward": {
        "available": "Puntos disponibles",
        "title": "Puntos",
        "use": "Utiliser des points"
      },
      "title": "Puntos"
    },
    "rooms": {
      "new": {
        "title": "Nueva Habitacione"
      },
      "room": {
        "title": "Habitacione"
      },
      "title": "Habitaciones"
    },
    "saft": {
      "creator": "Creador",
      "documentDate": "Fecha",
      "endDate": "Fecha de finalización",
      "name": "Nombre",
      "new": {
        "title": "Nuevo SAF-T"
      },
      "startDate": "Fecha de inicio",
      "title": "SAF-T"
    },
    "sales": {
      "balance": "Value",
      "billItem": {
        "new": "Nuevo Item"
      },
      "billed": "Facturado",
      "credit": "Crédito",
      "currentAccount": {
        "12to18": "12 - 18 meses",
        "18to24": "18 - 24 meses",
        "3to6": "3 - 6 meses",
        "6to12": "6 - 12 meses",
        "less3": "< 3 meses",
        "more24": "> 24 meses",
        "notDue": "No vencido"
      },
      "directDebit": "Débito",
      "document": "Número",
      "documents": {
        "title": "Documentos"
      },
      "invoices": {
        "title": "Facturas"
      },
      "newItem": "Añadir artículo al carrito",
      "stats": {
        "assistants": "Auxiliares",
        "maps": "Mapas",
        "nurses": "Enfermeras",
        "products": "Productos",
        "services": "Servicios",
        "top5": "Top 5",
        "transactions": "Transacciones",
        "vets": "Veterinarios"
      },
      "title": "Ventas",
      "totalDebt": "Deuda total",
      "totalPaid": "Total pagado",
      "value": "Valor"
    },
    "salesUnits": {
      "title": "Unidades de venta"
    },
    "section": {
      "title": "Sección"
    },
    "serums": {
      "serum": {
        "adjustment": "Ajuste",
        "adjustmentResult": "Resultado de ajuste",
        "amount": "Botella",
        "description": "Descripción",
        "ended": "Fin",
        "maintenanceFluidRate": "Velocidad",
        "name": "Nombre",
        "period": "Frecuencia",
        "started": "Principio",
        "supplementation": "Suplementación",
        "title": "Suero"
      },
      "title": "Sueros"
    },
    "settings": {
      "rules": "Reglas"
    },
    "sheltereds": {
      "disabled": "Deshabilitado",
      "edit": {
        "title": "Editar Sheltered"
      },
      "enabled": "Activo",
      "form": {
        "category": {
          "fisicalDetails": "Detalles Fiscales",
          "other": "Otros",
          "personalInformation": "Información Personal"
        },
        "environmentType": {
          "both": "Ambos",
          "indoor": "Interior",
          "outdoor": "Exterior"
        },
        "placeholder": {
          "allergies": "Alergias",
          "behavior": "Conducta",
          "census": "Censo",
          "chip": "Número de Chip",
          "comments": "Comentarios",
          "commentsExtra": "Comentarios Extra",
          "eyes": "Por Ejemplo: Azul",
          "fur": "Por Ejemplo: Corto",
          "furDescription": "Descripción de pieles",
          "height": "Altura",
          "insuranceType": "Tipo de Seguro",
          "name": "Por Ejemplo: Manchitas",
          "notes": "Otra Información",
          "passaport": "Número de Pasaporte",
          "pathology": "Seleccionar Patología",
          "pedigree": "Pedigree",
          "siraID": "Sira ID",
          "temperament": "Por Ejemplo: Calmada"
        },
        "select": {
          "breed": "Seleccionar Raza",
          "diet": "Seleccionar Dieta",
          "environmentType": "Seleccionar Tipo de Ambiente",
          "owner": "Seleccionar Dueño",
          "price": "Seleccionar Precio",
          "species": "Seleccionar Especie",
          "tail": "Seleccionar Tipo de Cola",
          "veterinary": "Seleccionar Veterinario Responsable"
        }
      },
      "new": {
        "title": "Nuevo Sheltered"
      },
      "sheltered": {
        "admissionDate": "Fecha de Admisión",
        "admissionType": "Tipo de Admisión",
        "age": "Edade",
        "allergies": "Alergias",
        "avatar": "Avatar",
        "behavior": "Conducta",
        "birthdate": "Fecha de Nacimiento",
        "breed": "Raza",
        "census": "Censo",
        "chip": "Chip",
        "comment": "Comentarios",
        "comments": "Comentarios",
        "commentsExtra": "Comentarios Extra",
        "deathdate": "Fecha de Fallecimiento",
        "deceased": "Fallecido",
        "deleted": "Eliminar",
        "diet": "Dieta",
        "environmentType": "Tipo de Ambiente",
        "eyes": "Ojos",
        "fur": "Pelo",
        "furColor": "Color",
        "furDescription": "Descripción de pieles",
        "furLength": "Longitud de la piel",
        "furType": "Tipo de pêlo",
        "height": "Altura",
        "id": "ID",
        "inactive": "Inactivo",
        "insurance": "Seguro",
        "insuranceType": "Tipo de Seguro",
        "location": "Localización",
        "name": "Nombre",
        "newWeight": "Nuevo Peso",
        "notes": "Notas",
        "ownerPrefered": "Dueño Preferencial",
        "passport": "Pasaporte",
        "pathologies": "Patologías",
        "pathology": "Patología",
        "pedigree": "Pedigree",
        "price": "Precio",
        "proportion": "Proporción",
        "releaseDate": "Fecha de Saída",
        "releaseType": "Tipo de Saída",
        "sex": "Sexo",
        "siraID": "Sira ID",
        "species": "Especie",
        "status": "Estado",
        "sterilized": "Esterilizado",
        "tailType": "Tipo de Cola",
        "tattoo": "Tatuaje",
        "tattooNumber": "Número de Tatuaje",
        "temperament": {
          "agressive": "Agresivo",
          "good": "Bueno",
          "moderate": "Moderado",
          "title": "Temperamento"
        },
        "title": "Resgatado",
        "veterinaryPrefered": "Veterinario Preferente",
        "weights": {
          "title": "Pesos",
          "weight": {
            "title": "Peso",
            "weighted": "Fecha"
          }
        }
      },
      "title": "Sheltereds"
    },
    "shifts": {
      "create": "Crear Turno",
      "edit": "Editar Turno",
      "endDate": "Fecha final",
      "name": "Nombre",
      "notes": "Notas",
      "startDate": "Fecha de inicio",
      "title": "Turnos",
      "worker": "Empleado"
    },
    "shoppingCartLines": {
      "new": {
        "title": "Nueva línea"
      },
      "shoppingCartLine": {
        "discount": "Descuento",
        "id": "ID",
        "liquid": "Precio bruto",
        "name": "Nombre",
        "productsAndServices": "Productos y sevicios",
        "quantity": "Cantidad",
        "title": "Línea de carrito",
        "total": "Total",
        "totalWithVAT": "Total c/IVA",
        "totalWithoutVAT": "Total s/IVA",
        "vat": "IVA"
      },
      "title": "Líneas de carrito"
    },
    "shoppingCarts": {
      "checkPaid": "Marcar como pago",
      "details": "Ver carrito",
      "edit": {
        "backToStart": "Comienzo"
      },
      "markAsPaid": "Estás seguro de que deseas marcar como pagado?",
      "name": "Nombre",
      "noLines": "Este carrito no tiene líneas",
      "onGoingInternment": "Internamiento continuo",
      "openTitle": "Carritos de compras abiertos",
      "paid": "Pago",
      "pending": "Pendiente",
      "percentagePaid": "Porcentaje de carritos pagados",
      "shoppingCart": {
        "title": "Carrito de compras"
      },
      "showPaidCarts": "Incluir carritos de compras ya pagados",
      "status": "Estado",
      "title": "Carritos de compras"
    },
    "sms": {
      "new": {
        "title": "Nuevo SMS"
      },
      "sent": {
        "title": "SMS Enviados"
      },
      "sms": {
        "appointmentDate": "Fecha",
        "campaign": "Campaña",
        "customer": "Cliente",
        "patient": "Paciente",
        "phoneNumber": "Número",
        "started": "Fecha de envío",
        "text": "Texto",
        "title": "SMS"
      },
      "title": "SMS"
    },
    "species": {
      "101": "Ruiseñor del Japón",
      "103": "Inseparable de Namibia",
      "107": "Águia Imperial Ibérica",
      "108": "Águia-Perdigueira",
      "109": "Aratinga",
      "113": "Pombos",
      "117": "Petauro Do Açúcar",
      "118": "Gecko",
      "119": "Serpentes",
      "12": "Mirlo Preto",
      "120": "Camaleon",
      "121": "Pyrrhura",
      "125": "Gallina",
      "126": "loro senegalés",
      "128": "Ring neck",
      "130": "Paloma",
      "14": "Cacatúas",
      "16": "Araras",
      "17": "Loro Lobo",
      "18": "Loro de la Patagonia",
      "2": "Chordata",
      "20": "Caturras",
      "21": "Piriquitos",
      "22": "Loro gris",
      "28": "Cerdos",
      "33": "Perros",
      "34": "Felidae",
      "35": "Felis",
      "36": "Gatos",
      "40": "Hurones",
      "42": "Erizos",
      "46": "Conejos",
      "50": "Cavia porcellus",
      "53": "Chinchillas cola corta",
      "54": "Chinchillas cola larga",
      "56": "Cricetinos",
      "59": "Ratos",
      "62": "Ratones",
      "64": "Perritos de las praderas",
      "68": "Pogona",
      "7": "Chloris chloris",
      "70": "Iguana",
      "72": "Varanus",
      "73": "Varano de Sabana",
      "74": "Tortuga",
      "79": "Caballo",
      "8": "Canarios",
      "81": "Burros",
      "82": "Phodopus",
      "83": "Hámster Enano",
      "86": "Elefantes",
      "9": "Jilgueros",
      "90": "Vacas",
      "92": "Capra",
      "93": "Cabras",
      "95": "Ovejas",
      "98": "Periquito australiano"
    },
    "stats": {
      "averageTimes": "Tiempo Medio",
      "billedThisMonth": "Mes",
      "billedThisWeek": "7 días",
      "billedThisYear": "Año",
      "billedToday": "Hoy",
      "decreased": "Ha disminuido",
      "down": "Bajo",
      "female": "F",
      "increased": "Ha aumentado",
      "invoicesNumber": "Numero de facturas",
      "listOfCreatedCustomers": "Lista de clientes creados",
      "male": "M",
      "mostRecentDate": "Fecha más reciente",
      "new": "Nuevos",
      "organization": "Estadísticas",
      "overview": "Resumen",
      "salesByWorker": "Ventas por trabajador",
      "same": "Igual",
      "todayTotalChange": "Total de cambios hoy",
      "total": "Total",
      "totalBilled": "Total Faturado",
      "totalInInvoices": "Total en facturas",
      "totals": "Totales",
      "up": "Arriba",
      "variations": "Variaciones",
      "vsLastYear": "vs Año Passado"
    },
    "stays": {
      "box": "Box",
      "create": "Crear estadia",
      "details": "Detalhes de estadia",
      "edit": "Editar estadia",
      "endDate": "Fecha final",
      "name": "Nombre",
      "patient": "Paciente",
      "startDate": "Fecha de inicio",
      "staysArrivingToday": "Estadias a registrarse hoy",
      "staysLeavingToday": "Estadias a salir hoy",
      "title": "Estadias"
    },
    "stockUnits": {
      "title": "Unidades de stock"
    },
    "subfamily": {
      "title": "Subfamilia"
    },
    "supplierItems": {
      "new": {
        "title": "Nuevo artículo del proveedor"
      },
      "supplierItem": {
        "supplierId": "Código de proveedor",
        "title": "Artículo del proveedor"
      },
      "title": "Artículos del proveedor"
    },
    "suppliers": {
      "building": "Edificio",
      "country": "Pais",
      "edit": {
        "title": "Editar Proveedor"
      },
      "email": "Email",
      "messages": {
        "createdSuccess": "Proveedor creado con éxito"
      },
      "mobilePhone": "Teléfono",
      "name": "Nombre",
      "new": {
        "title": "Nuevo Proveedor"
      },
      "street": "Calle",
      "supplier": {
        "supplierId": "ID de Proveedor",
        "title": "Proveedor"
      },
      "title": "Proveedores",
      "town": "Ciudad",
      "vat": "CIF",
      "zip": "Zip"
    },
    "system": {
      "alerts": {
        "offline": {
          "html": "<p>No es posible conectarse a nuestro servidor.</p><p>Intentando una nueva conexión <i class='fa fa-spinner fa-pulse fa-lg fa-fw'></i></p>",
          "title": "Sin acceso a Internet!"
        },
        "online": {
          "text": "Ahora puedes continuar tu trabajo.",
          "title": "Conexión restablecida!"
        }
      }
    },
    "systemDown1": "El sistema estará inactivo por mantenimiento a las",
    "systemDown2": "durante aproximadamente dos horas.",
    "tasks": {
      "archive": {
        "title": "Archivo"
      },
      "create": {
        "addDrug1": "Para añadir un nuevo principio activo envíe un correo a",
        "addSupplementation1": "Añadir nueva suplementación",
        "addSupplementation2": "Haga clic aquí y envíenos un correo electrónico",
        "amount": "Cantidad",
        "byKG": "Por kg",
        "changes": "Todos los cambios en una tarea se aplicarán a partir de la última acción realizada",
        "createdBy": "Creada por",
        "dosagePerKilogram": "Dosis por Kg",
        "doseCalculator": "Calculadora de dosis",
        "email": "info@petuniversal.com",
        "freqInfo": "Una tarea \"Siempre visible\" no está programada y puede registrarse manualmente. Sin la programación, la tarea no aparece en la vista general.",
        "name": "Crear tarea",
        "newActivePrinciple": "Nuevo principio activo",
        "rateInfo": "Con la formula: (peso * 30 + 70) / 24",
        "startedInfo": "Al hacer clic en el icono del reloj se inserta automáticamente la hora actual.",
        "toAdminister": "Para administrar",
        "total": "Total",
        "type": {
          "injectable": "Inyectable",
          "pill": "pastillas",
          "title": "Tipo"
        },
        "volumePerKilogram": "Volumen por Kg"
      },
      "done": "Realizado",
      "edit": {
        "title": "Editar Tarea"
      },
      "form": {
        "select": {
          "priority": "Seleccionar Prioridad",
          "worker": "Seleccionar Trabajador"
        }
      },
      "generatedBySystem": "Esta tarea fue generada por el sistema",
      "messages": {
        "createdSuccess": "Tarea creada con éxito"
      },
      "myTasks": "Mis Tareas",
      "new": {
        "title": "Nueva Tarea"
      },
      "priorities": {
        "high": "Alta",
        "low": "Baja",
        "urgency": "Urgente"
      },
      "select": {
        "all": "Todas"
      },
      "status": {
        "done": "Realizada",
        "inDelay": "Retrasada",
        "pending": "Pendiente"
      },
      "task": {
        "comment": "Comentario",
        "description": "Notas",
        "name": "Descripción",
        "patientcustomer": "Paciente/Cliente",
        "period": "Frecuencia",
        "priority": "Prioridad",
        "status": "Estado",
        "taskDate": "Fecha",
        "taskTime": "Hora",
        "title": "Tarea"
      },
      "taskType": "Tipo de Tarea",
      "timeline": "Línea de tiempo",
      "title": "Tareas",
      "withoutSelectedPriority": "Sin prioridad asignada",
      "withoutTasks": "Sin Tareas",
      "withoutWorker": "Sin usuario"
    },
    "taxes": {
      "title": "Impuestos",
      "vat": {
        "title": "IVA"
      }
    },
    "templates": {
      "name": "Nombre",
      "new": {
        "title": "Nuevo Relatorio"
      },
      "template": {
        "title": "Relatorio"
      },
      "templateSections": {
        "title": "Sección de relatorio"
      },
      "templateSectionsFields": {
        "title": "Campos de sección de relatorio"
      },
      "title": "Relatorios"
    },
    "terms": {
      "conditionedDischarge": "Carta de responsabilidad: Alta voluntaria",
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "debt": "Carta de responsabilidad: Deuda",
      "general": {
        "link": "Términos",
        "title": "Términos y Condiciones"
      },
      "language-disclaimer": "Este acuerdo fue escrito en portugués (pt-PT). En la medida en que cualquier versión traducida de este acuerdo entra en conflicto con la versión portuguesa, la versión portuguesa controla",
      "last-rev-date": "1 de enero de 2016",
      "last-rev-date-desc": "Fecha de la última revisión",
      "privacy": {
        "link": "Privacidad",
        "title": "Política de privacidad"
      },
      "procedure": "Carta de responsabilidad: Procedimiento",
      "responsability": "Carta de responsabilidad: Responsabilidad",
      "title": "Termos",
      "translation-not-available": "Esta página no está disponible en español, por ahora sólo ofrecemos una versión en portugués."
    },
    "titles": {
      "arquitect": "Arquitecto",
      "dr": "Doctor",
      "dra": "Doctora",
      "engineer": "Ingeniero",
      "engineerFemale": "Ingeniera",
      "father": "Sacerdote",
      "miss": "Dona",
      "mr": "Señor",
      "mrs": "Señora",
      "nurse": "Enfermero",
      "nurseFemale": "Enfermera",
      "professor": "Profesor",
      "professorFemale": "Profesora",
      "sister": "Hermana"
    },
    "transfersStock": {
      "aTDocCodeID": "Código da AT",
      "loading": {
        "loadingBuildingNumber": "Número",
        "loadingCityName": "Ciudada",
        "loadingCountry": "Paísa",
        "loadingDateTime": "Fecha",
        "loadingPostalZone": "Zona Postala",
        "loadingStreetName": "Callea",
        "sourceWarehouse": "Origen",
        "title": "Carga"
      },
      "new": {
        "title": "Nueva transferência de stock"
      },
      "title": "Transferências de stock",
      "unloading": {
        "targetWarehouse": "Destino",
        "title": "Descarga",
        "unloadingBuildingNumber": "Número",
        "unloadingCityName": "Ciudad",
        "unloadingCountry": "País",
        "unloadingDateTime": "Fecha",
        "unloadingPostalZone": "Zona Postal",
        "unloadingStreetName": "Calle"
      }
    },
    "u-icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "plan": "ticket",
      "plans": "ticket",
      "user": "user",
      "worker": "user"
    },
    "unexpectedError": "Error inesperado. Espere unos minutos y vuelva a intentarlo. Si el error persiste, póngase en contacto con Pet Universal.",
    "unitDimensions": {
      "description": "Ver todas las dimensiones unidades",
      "form": {
        "placeholder": {
          "baseUnit": "Unidad base",
          "description": "Descripción",
          "name": "Nombre",
          "title": "Dimensiones de la unidad"
        }
      },
      "new": {
        "title": "Nuevas dimensiones de la unidad"
      },
      "title": "Dimensiones de la unidad",
      "unitDimension": {
        "baseUnit": "Unidad base",
        "description": "Descripción",
        "name": "Nombre",
        "title": "Dimensión"
      }
    },
    "units": {
      "ampoules": "Ampolla",
      "bandages": "Vendajes",
      "capsules": "Cápsulas",
      "centimeters": "Centímetros",
      "description": "Ver todas las unidades",
      "drops": "Gotas",
      "form": {
        "placeholder": {
          "baseUnit": "Unidad Base",
          "conversionFactor": "Factor de Conversión",
          "externalID": "ID Externo",
          "name": "Nombre",
          "precisionDigits": "Dígitos de Precisión",
          "salesItems": "Unidades de Venta",
          "stockItems": "Unidades de Compra",
          "symbol": "Símbolo",
          "title": "Unidad de stock"
        }
      },
      "grams": "g",
      "internationalUnits": "IU",
      "kilograms": "kg",
      "liters": "l",
      "micrograms": "μg",
      "microgramsPerMilliliter": "μg/ml",
      "milliequivalents": "mEq",
      "milligrams": "mg",
      "milligramsPerKilogram": "mg/kg",
      "milligramsPerMilliliter": "mg/ml",
      "milliliters": "ml",
      "millilitersPerHour": "ml/h",
      "millilitersPerKilogram": "ml/kg",
      "min": "Minutos",
      "new": {
        "title": "Nueva Unidad"
      },
      "percentage": "%",
      "pills": "Comprimidos",
      "puff": "puff",
      "sachets": "Sobres",
      "title": "Unidades",
      "unit": {
        "baseUnit": "Unidad Base",
        "conversionFactor": "Factor de Conversión",
        "externalID": "ID Externo",
        "name": "Nombre",
        "precisionDigits": "Dígitos de Precisión",
        "salesItems": "Unidades de Venta",
        "stockItems": "Unidades de Compra",
        "symbol": "Símbolo",
        "title": "Unidad",
        "totalStock": "Stock Total"
      }
    },
    "user": {
      "edit": {
        "title": "Información de usuario"
      },
      "new": {
        "agree-terms": "Al hacer clic en Inicio de sesión, acepta nuestros términos y ha leído nuestra política de privacidad",
        "confirmPassword": "Confirmar contraseña",
        "email-example": "tu.nombre@example.com",
        "name-example": "Nombre de usuario",
        "password-example": "contraseña",
        "passwordsDontMatch": "Las contraseñas no coinciden",
        "title": "Regístrate",
        "validationEmailSent": "Gracias por registrarse, vaya a su correo electrónico y valide su cuenta"
      },
      "recoverPassword.success": "Un email fue enviado a usted con su nueva contraseña"
    },
    "validations": {
      "fieldRequired": "Campo es obligatorio",
      "maxCharacters": "Escriba como máximo {number} caracteres",
      "minCharacters": "Escriba como mínimo {number} caracteres",
      "minQuantity": "Debes tener al menos {number}",
      "numberCharacters": "Debe contener {number} caracteres",
      "specialCharacters": "No puede ingresar caracteres especiales (ex: >, <) o terminar con un espacio"
    },
    "via": {
      "IntralesionalInjectable": "Inyectable - IL",
      "auricularBoth": "Auricular ambos",
      "auricularLeft": "Auricular izquierdo",
      "auricularRight": "Auricular derecho",
      "inhalation": "Inhalación",
      "intramuscularInjectable": "Inyectable - IM",
      "intraosseousInjectable": "Inyectable - IO",
      "intraperitonealInjectable": "Inyectable - IP",
      "intravenousInjectable": "Inyectable - IV",
      "ocularBoth": "Ocular ambos",
      "ocularLeft": "Ocular izquierdo",
      "ocularRight": "Ocular derecho",
      "oral": "Oral",
      "rectal": "Rectal",
      "subcutaneousInjectable": "Inyectable - SC",
      "topical": "Tópico",
      "transdermal": "Transdérmica"
    },
    "visits": {
      "futureVisits": "Visitas Futuras",
      "historicalVisits": "Histórico de Citas",
      "newVisit": "Nueva Visita",
      "status": {
        "closed": "Finalizada",
        "open": "Abierto",
        "title": "Estado"
      },
      "title": "Consultas",
      "visit": {
        "title": "Visita"
      },
      "visitTypes": {
        "visitSubType": "Seleccionar Subtipo de Cita",
        "visitType": "Seleccionar Tipo de Cita"
      }
    },
    "waitingRooms": {
      "arrival": "Llegada",
      "late": "Atraso",
      "status": {
        "noPatientsCheckOut": "Ningún paciente en checkout",
        "noPatientsConsultation": "Ningún paciente en cita",
        "noPatientsWaiting": "Ningún paciente en la sala de espera",
        "pausedConsultations": "Citas en Pausa",
        "withScheduling": "Con Cita",
        "withoutAppointments": "Sin Citas"
      },
      "title": "Sala de Espera",
      "warning": "Aviso",
      "warningDate": "Fecha del Aviso"
    },
    "warehouses": {
      "edit": {
        "title": "Editar Almacén"
      },
      "form": {
        "category": {
          "base": "Base"
        },
        "placeholder": {
          "name": "Nombre"
        }
      },
      "messages": {
        "createdSuccess": "Almacén creado con éxito"
      },
      "new": {
        "title": "Nuevo Almacén"
      },
      "stock": "Stock",
      "title": "Almacenes",
      "viewWarehouse": "Ver Almacén",
      "warehouse": {
        "description": "Descripción",
        "name": "Nombre",
        "title": "Almacén"
      }
    },
    "workers": {
      "edit": {
        "placeholder": {
          "address": "Dirección",
          "email": "ejemplo@email.com",
          "mobilePhone": "Móvil",
          "name": "Por Ejemplo: María Salvador",
          "phone": "Teléfono",
          "professionalLetterNumber": "Nº Profesional"
        },
        "select": {
          "employee": "Empleado",
          "role": "Seleccionar Función"
        },
        "title": "Editar Trabajdor"
      },
      "form": {
        "select": {
          "worker": "Seleccionar Trabajador"
        }
      },
      "new": {
        "placeholder": {
          "address": "Dirección",
          "email": "ejemplo@email.com",
          "mobilePhone": "Móvil",
          "name": "Por Ejemplo: María Salvador",
          "phone": "Teléfono",
          "professionalLetterNumber": "Nº Profesional"
        },
        "select": {
          "employee": "Empleado",
          "role": "Seleccione Función"
        },
        "title": "Nuevo Trabajador"
      },
      "title": "Trabajadores",
      "worker": {
        "address": "Dirección",
        "birthdate": "Fecha de Nacimiento",
        "email": "Email",
        "enable": "Activar",
        "externalID": "ID Externo",
        "mobilePhone": "Móvil",
        "name": "Nombre",
        "phone": "Teléfono",
        "professionalLetterNumber": "Nº Profesional",
        "role": "Función",
        "roles": {
          "administrator": "Administración",
          "assistant": "Auxiliar",
          "nurse": "Enfermería",
          "other": "Otros",
          "receptionist": "Rececionista",
          "veterinary": "veterinaria"
        },
        "title": "Trabajador"
      }
    }
  };
  _exports.default = _default;
});