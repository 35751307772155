define("crm/models/receipt-memo", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    sourceDocId: _emberData.default.attr("string"),
    settled: _emberData.default.attr("number"),
    receipt: _emberData.default.belongsTo("receipt"),
    memo: _emberData.default.belongsTo("memo")
  });

  _exports.default = _default;
});