define("crm/components/periodic-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.smsSent.id", function () {
      if (this.get("record.smsSent.id")) {
        return this.get('intl').t("icons.check");
      }
    }),
    statusName: Ember.computed("record.smsSent.id", function () {
      if (this.get("record.smsSent.id")) {
        return this.get('intl').t("periodics.status.communicated");
      }
    })
  });

  _exports.default = _default;
});