define("crm/routes/organizations/organization/hotel/boxes/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor("organizations.organization");

      if (model.get("hotelFeature")) {
        var organizationID = model.get("id");
        this.get("store").query("box", {
          organization: organizationID
        }).then(function (boxes) {
          model.set("boxes", boxes);
        });
        this.get("store").query("stay", {
          organization: organizationID,
          startDate: (0, _moment.default)().format("YYYY-MM-DD"),
          endDate: (0, _moment.default)().format("YYYY-MM-DD")
        }).then(function (stays) {
          model.set("stays", stays);
        });
        return model;
      }
    }
  });

  _exports.default = _default;
});