define("crm/routes/organizations/organization/sms/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do crm')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization");

      if (model.get("campaignsFeature")) {
        model.set("createdAfter", params.createdAfter ? (0, _moment.default)(params.createdAfter).startOf('day').format() : (0, _moment.default)().startOf('day').format());
        model.set("createdBefore", params.createdBefore ? (0, _moment.default)(params.createdBefore).endOf('day').format() : (0, _moment.default)().endOf('day').format());
        var organizationID = model.get("id");

        if ((0, _moment.default)(model.get("createdBefore")).diff(model.get("createdAfter"), "days") <= this.get("dateLimit")) {
          return this.get("store").query("sms-sent", {
            organization: organizationID,
            sendDateAfter: model.get("createdAfter"),
            sendDateBefore: model.get("createdBefore")
          });
        } else {
          return null;
        }
      }
    },
    resetController: function resetController(model, isExiting) {
      if (isExiting) {
        model.set("createdAfter", null);
        model.set("createdBefore", null);
      }
    }
  });

  _exports.default = _default;
});