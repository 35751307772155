define("crm/models/customer-field-string", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    type: _emberData.default.belongsTo("customerFieldType"),
    customer: _emberData.default.belongsTo("customer"),
    value: _emberData.default.attr("string")
  });

  _exports.default = _default;
});