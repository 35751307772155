define("crm/components/absences-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.status", function () {
      if (Ember.isEmpty(this.get("record.status"))) {
        return this.get('intl').t("approvals.status.pending");
      }

      if (this.get("record.status") === 0) {
        return this.get('intl').t("approvals.status.pending");
      }

      if (this.get("record.status") === 1) {
        return this.get('intl').t("approvals.status.approved");
      }

      if (this.get("record.status") === 2) {
        return this.get('intl').t("approvals.status.rejected");
      }
    })
  });

  _exports.default = _default;
});