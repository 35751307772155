define("crm/routes/organizations/organization/absences/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        absences: this.get("store").query("absence", {
          organization: this.modelFor("organizations.organization").get("id"),
          startDate: (0, _moment.default)(params.startDate).startOf("d").format(),
          endDate: (0, _moment.default)(params.endDate).endOf("d").format()
        })
      }).then(function (hashResult) {
        return hashResult.absences;
      });
    },
    afterModel: function afterModel(model) {
      if (model) {
        model.set("organizaiton", this.modelFor("organizations.organization"));
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("startDate", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
        controller.set("endDate", (0, _moment.default)().endOf("month").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});