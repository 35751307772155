define("crm/routes/organizations/organization/purchase-requests/purchase-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.store.findRecord("purchase-request", params.purchase_request_id),
        item: this.store.query("item", {
          purchaseRequest: params.purchase_request_id
        }),
        units: this.store.query("item-unit", {
          purchaseRequest: params.purchase_request_id
        }),
        lines: this.store.query("purchase-request-line", {
          purchaseRequest: params.purchase_request_id
        })
      }).then(function (hash) {
        hash.lines.forEach(function (line) {
          line.set("enabled", !line.get("deleted"));
        });
        return hash.model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      model.set("purchaseOrders", this.store.query("purchase-order", {
        purchaseRequest: model.id
      }));
    }
  });

  _exports.default = _default;
});