define("crm/components/procurement/purchase-entity-line-mark-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      enableLine: function enableLine(record) {
        record.set("enabled", true);
        this.sendAction("enableLine", record);
      },
      disableLine: function disableLine(record) {
        record.set("enabled", false);
        this.sendAction("disableLine", record);
      }
    }
  });

  _exports.default = _default;
});