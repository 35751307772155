define("crm/routes/organizations/organization/financial-accounts/financial-account", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("financialAccount", params.financial_account_id);
    },
    afterModel: function afterModel(model) {
      this.set("breadCrumb", {
        finalTitle: model.get("internalDescription")
      });
      var self = this;
      var financialAccountID = model.get("id");
      this.store.query("financialAccountOpen", {
        financialAccount: financialAccountID,
        latest: true
      }).then(function (financialAccountOpens) {
        var createdAfter;

        if (!Ember.isEmpty(financialAccountOpens)) {
          var financialAccountOpen = financialAccountOpens.get("firstObject");
          createdAfter = (0, _moment.default)(financialAccountOpen.get("created")).format();
        } else {
          createdAfter = (0, _moment.default)().startOf("day").format();
        }

        model.set("financialAccountOpen", financialAccountOpen);
        model.set("invoices", self.get("store").query("invoice", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter
        }));
        model.set("receipts", self.get("store").query("receipt", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter
        }));
        model.set("purchaseInvoices", self.get("store").query("purchaseInvoice", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter
        }));
        model.set("payments", self.get("store").query("payment", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter
        }));
      });
      this.store.query("financialAccountClose", {
        financialAccount: financialAccountID,
        latest: true
      }).then(function (financialAccountClosed) {
        model.set("financialAccountClosed", financialAccountClosed.get("firstObject"));
      });
    }
  });

  _exports.default = _default;
});