define("crm/routes/organizations/organization/current-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var model = this.modelFor("organizations.organization");
      this.get("store").query("debt-debt-status", {
        company: model.get("company.id")
      }); //TODO

      this.get("store").query("debt", {
        company: model.get("company.id"),
        open: true
      }).then(function (debts) {
        model.set("debts", debts);
      });
      return model;
    }
  });

  _exports.default = _default;
});