define("crm/controllers/organizations/organization/internments/internment/clinical-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sortedMessages: Ember.computed("model.internmentStatus.chat.messages.@each.created", function () {
      if (this.get('model.internmentStatus.chat.messages')) {
        return this.get("model.internmentStatus.chat.messages").sortBy('created').reverse();
      }
    })
  });

  _exports.default = _default;
});