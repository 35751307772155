define("crm/components/documents-list", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("createdAfter", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
      this.set("createdBefore", (0, _moment.default)().endOf("month").format("YYYY-MM-DD"));
      var numTotal = 0;
      var debTotal = 0;
      var transferTotal = 0;
      var eletronicCardTotal = 0;
      var frTotal = 0;
      var faTotal = 0;
      var fsTotal = 0;
      var receiptTotal = 0;
      var paymentTotal = 0;

      if (this.get("queryInvoices") && this.get("model.length")) {
        this.get("model").forEach(function (filteredModel) {
          if ((filteredModel.get("constructor.modelName") == "invoice" || filteredModel.get("constructor.modelName") == "receipt" || filteredModel.get("constructor.modelName") == "payment") && !Ember.isEmpty(filteredModel.get("payableAmount"))) {
            if (filteredModel.get("paymentMethod") == "NUM" && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              numTotal = numTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "DEB" && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              debTotal = debTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "TRA" && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              transferTotal = transferTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "CARD" && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              eletronicCardTotal = eletronicCardTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FR" && filteredModel.get("name") !== "Em processamento...") {
              frTotal = frTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FA" && filteredModel.get("name") !== "Em processamento...") {
              faTotal = faTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FS" && filteredModel.get("name") !== "Em processamento...") {
              fsTotal = fsTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("constructor.modelName") == "receipt" && filteredModel.get("name") !== "Em processamento...") {
              receiptTotal = receiptTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("constructor.modelName") == "payment" && filteredModel.get("name") !== "Em processamento...") {
              paymentTotal = paymentTotal + filteredModel.get("payableAmount");
            }
          }
        });
      }

      this.set("numTotal", numTotal.toFixed(2));
      this.set("debTotal", debTotal.toFixed(2));
      this.set("transferTotal", transferTotal.toFixed(2));
      this.set("eletronicCardTotal", eletronicCardTotal.toFixed(2));
      this.set("frTotal", frTotal.toFixed(2));
      this.set("faTotal", faTotal.toFixed(2));
      this.set("fsTotal", fsTotal.toFixed(2));
      this.set("receiptTotal", receiptTotal.toFixed(2));
      this.set("paymentTotal", paymentTotal.toFixed(2));
    },
    minCreatedAfter: Ember.computed("createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdBefore"))) {
        return (0, _moment.default)(this.get("createdBefore")).subtract(45, "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxCreatedBefore: Ember.computed("createdAfter", function () {
      if (!Ember.isEmpty(this.get("createdAfter"))) {
        return (0, _moment.default)(this.get("createdAfter")).add(45, "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    invoicesTotal: Ember.observer("queryInvoices", "model.@each.constructor", "model.@each.status", "model.@each.documentType", "model.@each.payableAmount", "model.@each.paymentMethod", function () {
      var numTotal = 0;
      var debTotal = 0;
      var transferTotal = 0;
      var eletronicCardTotal = 0;
      var frTotal = 0;
      var faTotal = 0;
      var fsTotal = 0;
      var receiptTotal = 0;
      var paymentTotal = 0;

      if (!Ember.isEmpty(this.get("queryInvoices")) && !Ember.isEmpty(this.get("model"))) {
        this.get("model").forEach(function (filteredModel) {
          if ((filteredModel.get("constructor.modelName") == "invoice" || filteredModel.get("constructor.modelName") == "receipt" || filteredModel.get("constructor.modelName") == "payment") && !Ember.isEmpty(filteredModel.get("payableAmount"))) {
            if (filteredModel.get("paymentMethod") == "NUM" && filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              numTotal = numTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "DEB" && filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              debTotal = debTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "TRA" && filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              transferTotal = transferTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("paymentMethod") == "CARD" && filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") !== "FA" && filteredModel.get("name") !== "Em processamento...") {
              eletronicCardTotal = eletronicCardTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FR" && filteredModel.get("name") !== "Em processamento...") {
              frTotal = frTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FA" && filteredModel.get("name") !== "Em processamento...") {
              faTotal = faTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("documentType") === "FS" && filteredModel.get("name") !== "Em processamento...") {
              fsTotal = fsTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("constructor.modelName") == "receipt" && filteredModel.get("name") !== "Em processamento...") {
              receiptTotal = receiptTotal + filteredModel.get("payableAmount");
            }

            if (filteredModel.get("payableAmount") && Ember.isEmpty(filteredModel.get("status")) && filteredModel.get("constructor.modelName") == "payment" && filteredModel.get("name") !== "Em processamento...") {
              paymentTotal = paymentTotal + filteredModel.get("payableAmount");
            }
          }
        });
      }

      this.set("numTotal", numTotal.toFixed(2));
      this.set("debTotal", debTotal.toFixed(2));
      this.set("transferTotal", transferTotal.toFixed(2));
      this.set("eletronicCardTotal", eletronicCardTotal.toFixed(2));
      this.set("frTotal", frTotal.toFixed(2));
      this.set("faTotal", faTotal.toFixed(2));
      this.set("fsTotal", fsTotal.toFixed(2));
      this.set("receiptTotal", receiptTotal.toFixed(2));
      this.set("paymentTotal", paymentTotal.toFixed(2));
    }),
    columns: Ember.computed(function () {
      var result = [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-date hidden-xs"
      }, {
        "propertyName": "name",
        "component": "title-models-table-display",
        "className": "column-nif text-strong"
      }, {
        "title": " ",
        "propertyName": "paymentMethod",
        "className": "column-method hidden-xs"
      }, {
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "className": "column-price"
      }, {
        "component": "documents-list-actions",
        "className": this.get("hideExtraActions") || Ember.$("body").width() < 768 ? "column-actions-3 hide-extra-actions" : "column-actions-5"
      }];

      if (!this.get("hideName")) {
        result.splice(3, 0, {
          "propertyName": "customerName",
          "title": this.get('intl').t("customers.customer.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.customers.customer",
          "routeProperty": "customer.id"
        });
      }

      if (this.get("displayPatient") && Ember.$("body").width() > 767) {
        result.splice(3, 0, {
          "propertyName": "patient.name",
          "title": this.get('intl').t("patients.patient.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.patients.patient",
          "routeProperty": "patient.id"
        });
      }

      return result;
    }),
    actions: {
      openModal: function openModal(document) {
        this.sendAction("openModal", {
          entity: "document",
          action: "details",
          model: document
        });
      },
      annulDocument: function annulDocument(document) {
        this.sendAction("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      },
      editDocumentModal: function editDocumentModal(document) {
        this.sendAction("openModal", {
          entity: "document",
          action: "edit",
          model: document
        });
      },
      showPdf: function showPdf(url) {
        var model = {
          url: url
        };
        this.sendAction("openModal", {
          entity: "pdf-viewer",
          action: "view-pdf",
          model: model
        });
      },
      transitionToRoute: function transitionToRoute(route, params) {
        this.sendAction("transitionToRoute", route, params);
      },
      sendEmail: function sendEmail(model) {
        var self = this;

        if (confirm(this.get('intl').t('alerts.sendEmail'))) {
          Ember.$.ajax({
            method: "GET",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
              request.setRequestHeader("Content-Type", "application/pdf");
            },
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + model.get("_internalModel.modelName") + "s/" + model.get("id") + "/email",
            success: function success() {
              self.get("flashMessages").success("Email enviado com sucesso");
            },
            error: function error(result) {
              self.get("es").handle(result);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});