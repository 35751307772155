define("crm/models/company", ["exports", "ember-data", "universe-gui/models/company"], function (_exports, _emberData, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    companyItemTypes: _emberData.default.hasMany("company-item-type"),
    suppliers: _emberData.default.hasMany("supplier"),
    taxes: _emberData.default.hasMany("tax"),
    itemTypes: Ember.computed("companyItemTypes.@each.itemType", function () {
      return this.get("companyItemTypes") ? this.get("companyItemTypes").map(function (e) {
        return e.get("itemType");
      }).sortBy("name") : [];
    }),
    itemTypesRoot: Ember.computed("itemTypes.[]", function () {
      return this.get("itemTypes") ? this.get("itemTypes").filter(function (i) {
        return !i.get("parent.id");
      }).sortBy("name") : [];
    }),
    citMap: Ember.computed("companyItemTypes.[]", function () {
      if (!this.get("companyItemTypes")) {
        return {};
      }

      var cits = {};
      this.get("companyItemTypes").forEach(function (cit) {
        return cits[cit.get("itemType.id")] = cit;
      });
      return cits;
    }),
    taxesSorted: Ember.computed("taxes.@each.amount", function () {
      return this.get("taxes") ? this.get("taxes").filter(function (t) {
        return !t.get("purchase");
      }).sortBy("amount") : null;
    }),
    taxesPurchaseSorted: Ember.computed("taxes.@each.amount", function () {
      return this.get("taxes") ? this.get("taxes").sortBy("amount") : null;
    }),
    suppliersSorted: Ember.computed("suppliers.@each.name", function () {
      return this.get("suppliers") ? this.get("suppliers").sortBy("name") : null;
    }),
    units: Ember.computed("organizations.@each.units", function () {
      var result = Ember.A();
      this.get("organizations").forEach(function (o) {
        if (o.get("units")) {
          o.get("units").forEach(function (u) {
            return result.addObject(u);
          });
        }
      });
      return result;
    })
  });

  _exports.default = _default;
});