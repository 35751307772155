define("crm/components/item-status-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("column.propertyName", "record", function () {
      if (this.get("record").get(this.get("column.propertyName"))) {
        return this.get('intl').t("icons.check");
      }

      return "";
    }),
    statusName: Ember.computed("column.propertyName", "record", function () {
      if (this.get("record").get(this.get("column.propertyName"))) {
        return this.get('intl').t("items.form.category." + this.get("column.propertyName"));
      }

      return "";
    })
  });

  _exports.default = _default;
});