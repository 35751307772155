define("crm/routes/organizations/organization/open-purchase-invoices/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      dueDate: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization");
      var organizationID = model.get("id");
      model.set("dueDate", params.dueDate || (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));

      if (model.get("dueDate")) {
        return this.store.query("purchase-invoice", {
          organization: organizationID,
          open: true,
          dueDateBefore: model.get("dueDate")
        });
      } else {
        return [];
      }
    }
  });

  _exports.default = _default;
});