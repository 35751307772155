define("crm/routes/organizations/organization/purchase-invoices/purchase-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("purchase-invoice", params.purchase_invoice_id);
    }
  });

  _exports.default = _default;
});