define("crm/models/equipment-parameter", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    minVal: _emberData.default.attr("string"),
    maxVal: _emberData.default.attr("string"),
    parameter: _emberData.default.belongsTo("equipment-parameter-type", {
      inverse: "equipmentParameters"
    }),
    equipment: _emberData.default.belongsTo("equipment"),
    species: _emberData.default.belongsTo("species")
  });

  _exports.default = _default;
});