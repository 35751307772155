define("crm/models/shopping-cart", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    consultation: _emberData.default.belongsTo("consultation"),
    customer: _emberData.default.belongsTo("customer"),
    patient: _emberData.default.belongsTo("patient"),
    appointment: _emberData.default.belongsTo("appointment", {
      inverse: "shoppingCart"
    }),
    lines: _emberData.default.hasMany("shopping-cart-line"),
    invoiceShoppingCarts: _emberData.default.hasMany("invoice-shopping-cart"),
    total: Ember.computed("lines.@each.linePrice2", function () {
      if (!Ember.isEmpty(this.get("lines"))) {
        var total = 0;
        this.get("lines").forEach(function (line) {
          total += line.get("linePrice2") ? line.get("linePrice2") : 0;
        });
        return total;
      }
    }),
    isOpen: Ember.computed("status", function () {
      return this.get("status") != 1;
    })
  });
  /*
  Status
  0 - not paid
  1 - paid
  3 - current shopping cart on an internment
  */


  _exports.default = _default;
});