define("crm/models/family", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    parentID: _emberData.default.attr('number'),
    familyType: _emberData.default.attr('string'),
    children: _emberData.default.hasMany('family', {
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('family', {
      inverse: 'children'
    }),
    section: _emberData.default.belongsTo('section')
  });

  _exports.default = _default;
});