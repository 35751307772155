define("crm/models/contact", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    contactType: _emberData.default.attr('number'),
    contact: _emberData.default.attr('string'),
    preferential: _emberData.default.attr('boolean'),
    supplier: _emberData.default.belongsTo('supplier', {
      inverse: 'contacts'
    }),
    customer: _emberData.default.belongsTo('customer', {
      inverse: 'contacts'
    }),
    authorizations: _emberData.default.hasMany('contact-authorization-type', {
      inverse: 'contact'
    })
  });

  _exports.default = _default;
});