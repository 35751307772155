define("crm/components/appointments-list", ["exports", "moment", "ember-models-table/themes/bootstrap3"], function (_exports, _moment, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    appointmentsList: Ember.computed("showAll", "model.@each.start", function () {
      return this.get("showAll") ? this.get("model") : this.get("model").filter(function (a) {
        return a && (0, _moment.default)().isBefore(a.get("start"));
      });
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "start",
        "component": "umt-datetime2",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("enums.chronos.dateUpper"),
        "className": "column-date-time2"
      }, {
        "propertyName": "display",
        "component": "title-models-table-display",
        "title": this.get('intl').t("appointments.appointment.appointmentType"),
        "className": "column-big"
      }, {
        "propertyName": "observation",
        "component": "title-models-table-display",
        "title": this.get('intl').t("appointments.appointment.observation"),
        "className": "hidden-xs"
      }, {
        "component": "give-up-display",
        "title": this.get('intl').t("actions.giveUp"),
        "className": "hidden-xs column-id text-right"
      }, {
        "component": "missed-display",
        "title": this.get('intl').t("actions.missed"),
        "className": "hidden-xs column-id text-right"
      }, {
        "component": "appointments-list-actions",
        "className": "column-actions-1"
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t("modelsTable.show") + " %@ - %@ " + this.get('intl').t("modelsTable.of") + " %@",
          "noDataToShow": this.get('intl').t("modelsTable.noDataToShow")
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "fa fa-times form-control-feedback",
        "clearAllFiltersIcon": ""
      });
    }),
    actions: {
      callModal: function callModal(arg) {
        this.get("callModal")(arg);
      }
    }
  });

  _exports.default = _default;
});