define("crm/components/umt-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "span",
    done: Ember.computed("record.[]", function () {
      return this.get("record." + this.get("column.propertyName"));
    })
  });

  _exports.default = _default;
});