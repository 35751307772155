define("crm/components/item-adjustment-actions", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    exporting: false,
    session: Ember.inject.service("session"),
    hideEmitPdf: Ember.computed("record.status", function () {
      return !Ember.isEmpty(this.get("record.status")) && [3, 4, 9].includes(this.get("record.status"));
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "item-adjustment",
          action: action,
          model: this.get("record")
        });
      },
      showRecordPDF: function showRecordPDF() {
        var self = this;
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/itemAdjustments/" + self.get("record.id") + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.sendAction("showPdf", window.URL.createObjectURL(blob));
          }
        });
      },
      emitSecond: function emitSecond() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/itemAdjustments/" + self.get("record.id") + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord("itemAdjustment", self.get("record.id")).then(function (savedDocument) {
              self.set("exporting", false);
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("name") + ".pdf";
              link.click();
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});