define("crm/routes/organizations/organization/purchase-orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      return Ember.RSVP.hash({
        savedOrders: this.get("store").query("purchase-order", {
          organization: organizationID,
          status: 0
        }, {
          reload: true
        }),
        emittedOrders: this.get("store").query("purchase-order", {
          organization: organizationID,
          issued: true
        }, {
          reload: true
        }),
        ordersInSavedInvoices: this.get("store").query("purchase-order", {
          organization: organizationID,
          billed: true
        }, {
          reload: true
        })
      }).then(function (hashResult) {
        return hashResult;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});