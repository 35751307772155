define("crm/components/organization-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("organization", this.get("model.id"), {
          reload: true
        });
      }
    },
    close: function close() {
      Ember.$("#modal-organization-edit").modal("hide");
    },
    actions: {
      editOrgName: function editOrgName() {
        this.set("disabledSubmission", true);
        var self = this;
        this.get("model").save().then(function () {
          self.sendAction("transitionToRoute", "organizations");
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});