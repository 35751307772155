define("crm/controllers/organizations/organization/group/financial-accounts", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super();

      this.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    isToday: Ember.computed("date", function () {
      return (0, _moment.default)(this.get("date")).format("YYYY-MM-DD") === (0, _moment.default)().format("YYYY-MM-DD");
    }),
    actions: {
      today: function today() {
        this.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
        this.set("createdAfter", (0, _moment.default)(this.get("date")).startOf("day").format("YYYY-MM-DD"));
        this.set("createdBefore", (0, _moment.default)(this.get("date")).endOf("day").format("YYYY-MM-DD"));
      },
      setDateFilters: function setDateFilters() {
        if (!Ember.isEmpty(this.get("date"))) {
          this.set("createdAfter", (0, _moment.default)(this.get("date")).startOf("day").format("YYYY-MM-DD"));
          this.set("createdBefore", (0, _moment.default)(this.get("date")).endOf("day").format("YYYY-MM-DD"));
        }
      }
    }
  });

  _exports.default = _default;
});