define("crm/components/physical-exam-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ["row"],
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;

      if (this.get("entity.physicalExam.id")) {
        this.get("store").findRecord("physicalExam", this.get("entity.physicalExam.id")).then(function (foundPhysicalExam) {
          self.set("model", foundPhysicalExam);
        });
      } else {
        var create = {
          consultation: this.get("entity._internalModel.modelName") === "consultation" ? this.get("entity") : null,
          action: this.get("entity._internalModel.modelName") === "action" ? this.get("entity") : null
        };
        this.set("model", this.get("store").createRecord("physicalExam", create));
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        this.get("model").save().then(function (saved) {
          return _this.get("created")(saved);
        });
      }
    }
  });

  _exports.default = _default;
});