define("crm/components/file-upload", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    isImage: Ember.computed("fileName", function () {
      if (this.get("fileName")) {
        var str = this.get("fileName");
        var patt1 = /.+.(jpg)|(gif)|(tif)|(png)|(bmp)/i;
        return str.match(patt1);
      } else {
        return false;
      }
    }),
    endpoint: Ember.computed("model._internalModel.modelName", "model.id", function () {
      if (this.get("serviceEndpoint")) {
        return _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + this.get("serviceEndpoint");
      } else {
        var _service = this.camelize("file " + this.get("entity._internalModel.modelName"));

        return _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + _service + "s/upload";
      }
    }),
    camelize: function camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    actions: {
      upload: function upload() {
        var self = this;
        this.set("lockButton", true);
        var url = this.get("endpoint");
        var blob = this.dataURLtoBlob(this.get("data"));
        var form = this.$("form")[0];
        var formData = new FormData(form);
        formData.append('attachment', blob, this.get('fileName').normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        Ember.$.ajax({
          url: url,
          type: 'post',
          data: formData,
          cache: false,
          contentType: false,
          //required for multipart
          processData: false,
          //required for multipart
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("U-Entity", self.get('entity._internalModel.modelName'));
            request.setRequestHeader("U-Entity-Id", self.get('entity.id'));
            request.setRequestHeader("Accept-Language", self.get('intl.locale'));
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }).then(function (savedFile) {
          self.$("#pu-input-image").val(undefined);
          self.set("data", undefined);
          self.sendAction("uploaded", savedFile);
          self.set("lockButton", false);
        }, function (result) {
          self.set("lockButton", false);
          self.get("es").errorReturned(result);
        });
      }
    },
    dataURLtoBlob: function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], {
        type: mime
      });
    }
  });

  _exports.default = _default;
});