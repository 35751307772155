define("crm/controllers/organizations/new", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    disableBtn: false,
    isDirty: true,
    sortedCompanies: Ember.computed("companies", function () {
      return Ember.isEmpty(this.get("companies")) ? null : this.get("companies").sort(function (a, b) {
        return a.get("id").localeCompare(b.get("id"), undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      }).reverse();
    }),
    organizations: Ember.computed("model.company.id", function () {
      return this.get("store").query("organization", {
        company: this.get("model.company.id")
      });
    }),
    countries: Ember.computed(function () {
      return _uConstants.default.countries;
    }),
    patternZipFirstPart: Ember.computed("zipFirstBox", "selectedCountry", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{4}";
      }
    }),
    patternZipSecondPart: Ember.computed("zipSecondPart", "selectedCountry", function () {
      if (this.get("selectedCountry.code") === "PT") {
        return "[0-9]{3}";
      }
    }),
    actions: {
      create: function create() {
        var self = this;
        this.set('disableBtn', true);

        if (!Ember.isEmpty(this.get("zipFirstPart"))) {
          if (!Ember.isEmpty(self.get("zipSecondPart"))) {
            self.set("model.zip", self.get("zipFirstPart") + "-" + self.get("zipSecondPart"));
          } else {
            self.set("model.zip", self.get("zipFirstPart"));
          }
        }

        var organization = this.get('store').createRecord('organization', this.get('model'));
        organization.save().then(function (result) {
          self.send("created", result.id);
          self.set('disableBtn', false);
        }).catch(function () {
          self.get('store').unloadRecord(organization);
          self.set('disableBtn', false);
        });
      },
      cancel: function cancel() {
        this.send("cancel");
      },
      setCompanySelectAnswer: function setCompanySelectAnswer(companyID) {
        this.set("model.company", this.get("store").peekRecord("company", companyID));
      },
      setParentSelectAnswer: function setParentSelectAnswer(parentID) {
        this.set("model.parent", this.get("store").peekRecord("organization", parentID));
      },
      setCountrySelectAnswer: function setCountrySelectAnswer(value) {
        this.set('model.country', value);
      }
    }
  });

  _exports.default = _default;
});