define("crm/components/open-shopping-carts-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record", function () {
      if (this.get("record.status") == 1) {
        return this.get('intl').t("shoppingCarts.paid");
      } else if (this.get("record.status") == 3) {
        return this.get('intl').t("shoppingCarts.onGoingInternment");
      } else {
        return this.get('intl').t("shoppingCarts.pending");
      }
    })
  });

  _exports.default = _default;
});