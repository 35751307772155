define("crm/components/pet-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["modal", "animated", "fadeIn"],
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;
      this.$().on('hide.bs.modal', function ()
      /*event*/
      {
        //self.$().removeClass("zoomIn");
        //self.$().addClass("zoomOut");
        self.reset();
      });
    },
    reset: function reset() {},
    actions: {
      sendAction1: function sendAction1(action1Param) {
        if (action1Param) {
          this.sendAction("action1", action1Param);
        } else {
          this.sendAction("action1");
        }
      },
      sendAction2: function sendAction2(action2Param) {
        if (action2Param) {
          this.sendAction("action2", action2Param);
        } else {
          this.sendAction("action2");
        }
      },
      sendAction3: function sendAction3(action3Param) {
        if (action3Param) {
          this.sendAction("action3", action3Param);
        } else {
          this.sendAction("action3");
        }
      },
      sendAction4: function sendAction4(action4Param) {
        if (action4Param) {
          this.sendAction("action4", action4Param);
        } else {
          this.sendAction("action4");
        }
      },
      sendAction5: function sendAction5(action5Param) {
        if (action5Param) {
          this.sendAction("action5", action5Param);
        } else {
          this.sendAction("action5");
        }
      },
      sendAction6: function sendAction6(action6Param) {
        if (action6Param) {
          this.sendAction("action6", action6Param);
        } else {
          this.sendAction("action6");
        }
      },
      sendAction7: function sendAction7(action7Param) {
        if (action7Param) {
          this.sendAction("action7", action7Param);
        } else {
          this.sendAction("action7");
        }
      },
      sendAction8: function sendAction8(action8Param) {
        if (action8Param) {
          this.sendAction("action8", action8Param);
        } else {
          this.sendAction("action8");
        }
      },
      sendAction9: function sendAction9(action9Param) {
        if (action9Param) {
          this.sendAction("action9", action9Param);
        } else {
          this.sendAction("action9");
        }
      },
      sendAction10: function sendAction10(action10Param) {
        if (action10Param) {
          this.sendAction("action10", action10Param);
        } else {
          this.sendAction("action10");
        }
      },
      canceled: function canceled() {
        Ember.$("#" + this.get('id')).modal("hide");

        if (this.get('canceled')) {
          this.sendAction("canceled");
        }
      }
    }
  });

  _exports.default = _default;
});