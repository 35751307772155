define("crm/controllers/organizations/organization/listings/sales/items-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("intl.locale", function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("items.item.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.items.item"
      }, {
        "propertyName": "created",
        "title": this.get('intl').t("organizations.organization.created"),
        "className": "column-date",
        "component": "umt-date"
      }];
    })
  });

  _exports.default = _default;
});