define("crm/routes/organizations/organization/patients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    actions: {
      delete: function _delete(id) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          var patientToDelete = this.get('store').peekRecord('patient', id);
          patientToDelete.destroyRecord().then(function () {
            self.transitionTo("organizations.organization.patients");
          });
        }
      },
      enable: function enable(patient) {
        this.get("store").findRecord("patient", patient.get("id"), {
          reload: true
        }).then(function (savedPatient) {
          savedPatient.set('enabled', true);
          savedPatient.save();
        });
      },
      disable: function disable(patient) {
        this.get("store").findRecord("patient", patient.get("id"), {
          reload: true
        }).then(function (savedPatient) {
          savedPatient.set('enabled', false);
          savedPatient.save();
        });
      }
    }
  });

  _exports.default = _default;
});