define("crm/components/contacts-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoCustomer: Ember.computed("can", function () {
      return this.get("can").can("create customer");
    }),
    whatsapp: Ember.computed("record.contact", function () {
      if (this.get("record.contactType") === 1 || this.get("record.contactType") === 2) {
        return this.get("record.contact");
      }

      return null;
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "contact",
          action: action,
          model: this.get("record")
        });
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});