define("crm/routes/organizations/organization/management-stats", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    model: function model() {
      return {};
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do stat')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    setupController: function setupController(controller) {
      var self = this;
      this.set("breadCrumb", {
        finalTitle: self.get("intl").t("sales.title")
      });
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/hour?organization=" + organizationID + "&createdAfter=2019-12-31T23%3A59%3A59%2B00%3A00",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("invoices", result);
        }
      });
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/memos/hour?organization=" + organizationID + "&createdAfter=2019-12-31T23%3A59%3A59%2B00%3A00",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("memos", result);
        }
      });
    }
  });

  _exports.default = _default;
});