define("crm/controllers/organizations/organization/equipment-types/equipment-type/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    actions: {
      enable: function enable() {
        this.get("store").findRecord("equipment-type", this.get("model.id"), {
          reload: true
        }).then(function (savedEquipmentType) {
          savedEquipmentType.set('enable', true);
          savedEquipmentType.save();
        });
      },
      disable: function disable() {
        this.get("store").findRecord("equipment-type", this.get("model.id"), {
          reload: true
        }).then(function (savedEquipmentType) {
          savedEquipmentType.set('enable', false);
          savedEquipmentType.save();
        });
      }
    }
  });

  _exports.default = _default;
});