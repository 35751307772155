define("crm/routes/organizations/organization/stats/procurement/item-types", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get('id');
      var createdAfter = this.modelFor("organizations.organization.stats").createdAfter;
      var createdBefore = this.modelFor("organizations.organization.stats").createdBefore;
      var createdAfter2 = this.modelFor("organizations.organization.stats").createdAfter2;
      var createdBefore2 = this.modelFor("organizations.organization.stats").createdBefore2;
      var dateFrom_1 = (0, _moment.default)(createdAfter).startOf("day").utc().toISOString();
      var dateTo_1 = (0, _moment.default)(createdBefore).endOf("day").utc().toISOString();
      var dateFrom_2 = (0, _moment.default)(createdAfter2).startOf("day").utc().toISOString();
      var dateTo_2 = (0, _moment.default)(createdBefore2).endOf("day").utc().toISOString();
      return Ember.RSVP.hash({
        createdAfter: createdAfter,
        createdBefore: createdBefore,
        createdAfter2: createdAfter2,
        createdBefore2: createdBefore2,
        organization: organization,
        model1: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/purchases/itemTypes?organization=" + organizationID + "&createdAfter=" + dateFrom_1 + "&createdBefore=" + dateTo_1,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        model2: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/purchases/itemTypes?organization=" + organizationID + "&createdAfter=" + dateFrom_2 + "&createdBefore=" + dateTo_2,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        })
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("itemTypes.title")
      });
    }
  });

  _exports.default = _default;
});