define("crm/components/unit-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disableCreateBtn: false,
    newUnit: {},
    init: function init() {
      this._super();

      this.reset();
    },
    reset: function reset() {
      this.set('newUnit', {});
      this.set('selectUnitDimensionID', null);
      this.set('disableCreateBtn', false);
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$('#modal-unit-create').modal('hide');
    },
    actions: {
      create: function create() {
        this.set('disableCreateBtn', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newUnit.organization', this.get('organization'));
        }

        if (this.get('selectBaseUnitID')) {
          var baseUnit = this.get('store').peekRecord('unit', this.get('selectBaseUnitID'));
          this.set('newUnit.baseUnit', baseUnit);
        }

        if (this.get('selectUnitDimensionID')) {
          var unitDimension = this.get('store').peekRecord('unit-dimension', this.get('selectUnitDimensionID'));
          this.set('newUnit.unitDimension', unitDimension);
        }

        var createUnit = this.get('store').createRecord('unit', this.get('newUnit'));
        createUnit.save().then(function (savedUnit) {
          self.get('units').addObject(savedUnit._internalModel);
          self.reset();
        }).catch(function () {
          self.set('disableCreateBtn', false);
        });
      },
      setBaseUnit: function setBaseUnit(unit) {
        this.set('newUnit.baseUnit', unit);
      }
    }
  });

  _exports.default = _default;
});