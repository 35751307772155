define("crm/models/legal-declaration", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    documentDate: _emberData.default.attr("localdatetime"),
    startDate: _emberData.default.attr("localdatetime"),
    endDate: _emberData.default.attr("localdatetime"),
    totalCredit: _emberData.default.attr(),
    totalDebit: _emberData.default.attr(),
    organization: _emberData.default.belongsTo("organization"),
    status: _emberData.default.attr(),
    version: _emberData.default.attr("string"),
    purpose: _emberData.default.attr(),
    note: _emberData.default.attr("string"),
    remarks: _emberData.default.attr("string"),
    numberOfDocuments: _emberData.default.attr(),
    externalID: _emberData.default.attr()
  });

  _exports.default = _default;
});