define("crm/components/current-accounts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    showReceivingDebt: true,
    showGivingDebt: true,
    filteredModels: Ember.computed("model.[]", "model.each.amount", "showReceivingDebt", "showGivingDebt", function () {
      var result = [];
      var self = this;

      if (!Ember.isEmpty(this.get("showReceivingDebt")) || !Ember.isEmpty(this.get("showGivingDebt"))) {
        if (!Ember.isEmpty(this.get("model"))) {
          result.addObjects(this.get("model").sortBy("amount").reverse().filter(function (debt) {
            return self.get("showReceivingDebt") && debt.get("amount") > 0 || self.get("showGivingDebt") && debt.get("amount") < 0;
          }));
        }
      } else {
        result = [];
      }

      return result;
    }),
    filteredModelsTotal: Ember.computed("filteredModels.@each.each.amount", function () {
      var total = 0;

      if (!Ember.isEmpty(this.get("filteredModels"))) {
        this.get("filteredModels").forEach(function (filteredModel) {
          if (!Ember.isEmpty(Ember.get(filteredModel, "amount"))) {
            total += Number(Ember.get(filteredModel, "amount"));
          }
        });
      }

      return Number(total.toFixed(2));
    }),
    columns: Ember.computed(function () {
      return [{
        "title": "",
        "propertyName": "customer.code",
        "className": "column-id"
      }, {
        "propertyName": "customer.name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "component": "title-models-table-display",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "created",
        "title": this.get('intl').t("organizations.organization.created"),
        "className": "column-date",
        "component": "umt-date"
      }, {
        "propertyName": "dueDate",
        "title": this.get('intl').t("openItems.dueDate"),
        "className": "column-date",
        "component": "umt-date"
      }, {
        "propertyName": "currentDebtDebtStatus.debtStatus.name",
        "title": this.get('intl').t("appointments.appointment.status"),
        "className": "column-status",
        "component": "title-models-table-display"
      }, {
        "propertyName": "amount",
        "title": this.get('intl').t("sales.balance"),
        "className": "column-price-large",
        "component": "umt-price-display"
      }, {
        "className": "column-actions-3",
        "component": "current-account-list-actions"
      }];
    }),
    actions: {
      openModal: function openModal(arg) {
        this.sendAction("openModal", arg);
      }
    }
  });

  _exports.default = _default;
});