define("crm/components/internment/action-components", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    icon: "",
    count: 0,
    removed: false,
    isPastColumn: function isPastColumn(columnTime) {
      return columnTime.isBefore((0, _moment.default)(), 'hour');
    },
    isFutureColumn: function isFutureColumn(columnTime) {
      return columnTime.isAfter((0, _moment.default)(), 'hour');
    },
    isCurrentColumn: function isCurrentColumn(columnTime) {
      return columnTime.isSame((0, _moment.default)(), 'hour');
    }
  });

  _exports.default = _default;
});