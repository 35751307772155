define("crm/components/worker-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    roles: Ember.computed.alias("catalogs.workerRoles"),
    colors: Ember.computed.alias("catalogs.colors"),
    disableBtn: false,
    isDirty: true,
    selectedColor: null,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.color")) {
        this.set("selectedColor", this.get("model.color"));
      }
    },

    /*
     * Functions
     */
    reset: function reset() {
      Ember.$('#openEditWorker').modal('hide');
    },

    /*
     * Actions
     */
    actions: {
      setManager: function setManager() {
        if (this.get("model.manager")) {
          this.set("model.manager", false);
        } else {
          this.set("model.manager", true);
          this.set("model.undermanager", false);
        }
      },
      setUndermanager: function setUndermanager() {
        if (this.get("model.undermanager")) {
          this.set("model.undermanager", false);
        } else {
          this.set("model.undermanager", true);
          this.set("model.manager", false);
        }
      },
      setDate: function setDate(date) {
        this.set("model.birthdate", date);
      },
      modalEditWorker: function modalEditWorker() {
        var self = this;
        this.set('disableBtn', true);
        this.get('model').save().then(function (result) {
          Ember.$('#openEditWorker').modal('hide');
          self.send("updated", result.id);
          self.set('disableBtn', false);
        }).catch(function () {
          self.set('disableBtn', false);
        });
      },
      cancel: function cancel() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.get('model').rollbackAttributes();
          this.send("canceled");
          this.reset();
        }
      },
      setColor: function setColor(colorToPresent) {
        if (this.get("selectedColor") === colorToPresent.color) {
          this.set("selectedColor", null);
          this.set("model.color", null);
          colorToPresent.active = false;
        } else {
          this.get("colors").forEach(function (colorToSet) {
            return colorToSet.active = false;
          });
          colorToPresent.active = true;
          this.set("selectedColor", colorToPresent.color);
          this.set("model.color", colorToPresent.color);
        }
      },
      cancelEditWorkerModal: function cancelEditWorkerModal() {
        this.get('model').rollbackAttributes();
        this.reset();
      }
    }
  });

  _exports.default = _default;
});