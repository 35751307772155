define("crm/models/sticky-note", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    expected: _emberData.default.attr('isodate'),
    notice: _emberData.default.attr('isodate'),
    effective: _emberData.default.attr('isodate'),
    comment: _emberData.default.attr('string'),
    done: _emberData.default.attr('boolean'),
    patient: _emberData.default.hasMany('patient'),
    periodicPeriodic: _emberData.default.hasMany('periodic-periodic')
  });

  _exports.default = _default;
});