define("crm/helpers/title-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculatePriceDisplay = calculatePriceDisplay;
  _exports.default = void 0;

  function calculatePriceDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var self = this;

      switch (Number(params[0])) {
        case 1:
          return self.get("intl").t("titles.mr");

        case 2:
          return self.get("intl").t("titles.mrs");

        case 3:
          return self.get("intl").t("titles.miss");

        case 4:
          return self.get("intl").t("titles.dr");

        case 5:
          return self.get("intl").t("titles.dra");

        case 6:
          return self.get("intl").t("titles.engineer");

        case 7:
          return self.get("intl").t("titles.engineerFemale");

        case 8:
          return self.get("intl").t("titles.nurse");

        case 9:
          return self.get("intl").t("titles.nurseFemale");

        case 10:
          return self.get("intl").t("titles.professor");

        case 11:
          return self.get("intl").t("titles.professorFemale");

        case 12:
          return self.get("intl").t("titles.arquitect");

        case 13:
          return self.get("intl").t("titles.father");

        case 14:
          return self.get("intl").t("titles.sister");

        default:
          return " ";
      }
    }
  });

  _exports.default = _default;
});