define("crm/controllers/organizations/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    inboundTypes: Ember.computed.alias("catalogs.inboundTypes"),
    actions: {
      expiredConfirm: function expiredConfirm() {//this.transitionToRoute("organizations.organization.waitingRooms");
      },
      updateItemTypes: function updateItemTypes() {
        alert("ERROR GRAVE, POR FAVOR REPORTAR À PET UNIVERSAL! ERRO: 02");
      },
      speciesFiltered: Ember.computed("species.[]", "intl", function () {
        if (this.get("intl") && !Ember.isEmpty(this.get("species"))) {
          var self = this;
          return this.get("species").then(function (res) {
            return res.map(function (s) {
              return s.set("name", self.get("intl").t("species." + s.get("id")));
            });
          });
        } else {
          return [];
        }
      }),
      created: function created(entity, model) {
        if ("supplier" === entity) {
          this.set("suppliers", this.get("suppliers").toArray());
          this.get("suppliers").push(model);
        }

        this.send("reloadModel");
      }
    }
  });

  _exports.default = _default;
});