define("crm/routes/organizations/organization/listings/crm/patients/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var begin = this.modelFor("organizations.organization.listings").createdAfter;
      var end = this.modelFor("organizations.organization.listings").createdBefore;

      if (begin && end) {
        if ((0, _moment.default)(begin).diff(end, "days") <= this.get("dateLimit")) {
          return this.get("store").query("patient", {
            organization: organizationID,
            enable: true,
            createdAfter: new Date(_moment.default.utc(begin).startOf("d")).toISOString(),
            createdBefore: new Date(_moment.default.utc(end).endOf("d")).toISOString()
          });
        }
      }

      return [];
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get('intl').t("patients.patient.patientSince")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("columns", [{
        "propertyName": "created",
        "title": this.get('intl').t("patients.patient.patientSince"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-datetime",
        "className": "column-date-time"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.patients.patient"
      }]);
    }
  });

  _exports.default = _default;
});