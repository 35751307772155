define("crm/components/order-line-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    newOrderLine: {
      quantity: 1
    },
    selectedItemPrice: 0,
    init: function init() {
      this._super();

      this.reset();
    },
    reset: function reset() {
      this.set('newOrderLine', {
        quantity: 1
      });
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$('#modal-order-line-create').modal('hide');
    },
    finalPrice: Ember.computed('newOrderLine.item.pvp', 'newOrderLine.quantity', function () {
      if (this.get('newOrderLine.item.pvp')) {
        return this.get('newOrderLine.item.pvp') * this.get('newOrderLine.quantity');
      }
    }),
    actions: {
      onSelectItem: function onSelectItem(itemID) {
        if (itemID) {
          var item = this.get('store').peekRecord('item', itemID);
          this.set('newOrderLine.item', item);
        }
      },
      create: function create() {
        var self = this;

        if (this.get('newOrderLine.item')) {
          var orderLine = this.get('store').createRecord('orderLine', this.get('newOrderLine'));
          orderLine.set('linePrice', this.get('finalPrice'));
          orderLine.set('name', this.get('newOrderLine.item.name'));

          if (this.get('order')) {
            orderLine.set('order', this.get('order'));
            orderLine.save();
            self.reset();
          }
        }
      }
    }
  });

  _exports.default = _default;
});