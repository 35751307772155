define("crm/helpers/moment-seconds-str", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageDisplay = ageDisplay;
  _exports.default = void 0;

  function ageDisplay() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var x = (0, _moment.default)().startOf('day');
      x.seconds(params[0]);
      return x.format('H:mm:ss');
    }
  });

  _exports.default = _default;
});