define("crm/components/open-items-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    openItemsFiltered: Ember.computed("model.[]", function () {
      if (Ember.isEmpty(this.get("model"))) {
        return null;
      }

      var result = {};
      this.get("model").forEach(function (i) {
        if (!result[i.get("enterprise")]) {
          result[i.get("enterprise")] = [];
        }

        result[i.get("enterprise")].push(i);
      });
      return result;
    })
  });

  _exports.default = _default;
});