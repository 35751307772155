define("crm/controllers/organizations/organization/index", ["exports", "moment", "universe-gui/u-constants"], function (_exports, _moment, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    isGod: Ember.computed.readOnly("sessionAccount.currentUser.isGod"),
    store: Ember.inject.service("store"),
    dayTime: Ember.computed(function () {
      var h = (0, _moment.default)().hours();

      if (h > 20 || h < 6) {
        return "night";
      } else if (h > 12) {
        return "afterNoon";
      } else {
        return "morning";
      }
    }),
    countries: Ember.computed(function () {
      return _uConstants.default.countries;
    }),
    currencies: Ember.computed(function () {
      return _uConstants.default.currencies;
    }),
    sortedLastConsultations: Ember.computed("model.consultations", function () {
      return this.get("model.consultations") ? this.get("model.consultations").sortBy("date").reverse().slice(0, 5) : [];
    }),
    countryName: Ember.computed("model.country", function () {
      var _this = this;

      return this.get("countries").find(function (item) {
        return item.code === _this.get("model.country");
      }).name;
    }),
    actions: {
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      },
      goToHowTo: function goToHowTo() {
        this.transitionToRoute("organizations.organization.how-to");
      },
      showFormExample: function showFormExample() {
        Ember.$('#modal-custom-form-example').modal();
      }
    }
  });

  _exports.default = _default;
});