define("crm/controllers/organizations/organization/financial-accounts/financial-account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    canDoFinancialAccounts: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    documents: Ember.computed("model.invoices.[]", "model.purchaseInvoices.[]", "model.receipts.[]", "model.payments.[]", "model.financialAccountOpens.[]", "model.financialAccountCloses.[]", function () {
      var result = [];
      this.get("model.invoices") && this.get("model.invoices").forEach(function (d) {
        return !d.get("status") && result.addObject(d);
      });
      this.get("model.purchaseInvoices") && this.get("model.purchaseInvoices").forEach(function (d) {
        return result.addObject(d);
      });
      this.get("model.receipts") && this.get("model.receipts").forEach(function (d) {
        return result.addObject(d);
      });
      this.get("model.payments") && this.get("model.payments").forEach(function (d) {
        return result.addObject(d);
      });
      this.get("model.financialAccountOpens") && this.get("model.financialAccountOpens").forEach(function (d) {
        return result.addObject(d);
      });
      this.get("model.financialAccountCloses") && this.get("model.financialAccountCloses").forEach(function (d) {
        return result.addObject(d);
      });
      return result;
    }),
    cleanData: Ember.computed("documents.[]", function () {
      return this.get("documents").filter(function (d) {
        return d.get("payableAmount") && (Ember.isEmpty(d.get("status")) || d.get("status") !== 8);
      });
    }),
    inTotal: Ember.computed("cashTotal", "debTotal", "transferTotal", "cardTotal", function () {
      return this.get("cashTotal") + this.get("debTotal") + this.get("transferTotal") + this.get("cardTotal");
    }),
    outTotal: Ember.computed("cashTotalP", "debTotalP", "transferTotalP", "cardTotalP", function () {
      return this.get("cashTotalP") + this.get("debTotalP") + this.get("transferTotalP") + this.get("cardTotalP");
    }),
    currentVal: Ember.computed("model.financialAccountOpen.payableAmount", "inTotal", "outTotal", function () {
      return this.get("model.financialAccountOpen.payableAmount") + this.get("inTotal") - this.get("outTotal");
    }),
    inOutTotal: Ember.computed("inTotal", "outTotal", function () {
      return this.get("inTotal") - this.get("outTotal");
    }),

    /*
     * received
     */
    inDocs: Ember.computed("cleanData.[]", function () {
      return this.get("cleanData").filter(function (document) {
        return (document.get("constructor.modelName") === "invoice" || document.get("constructor.modelName") === "receipt") && (Ember.isEmpty(document.get("documentType")) || document.get("documentType") !== "FA") && !Ember.isEmpty(document.get("billingID"));
      });
    }),
    cashTotal: Ember.computed("inDocs.[]", function () {
      return this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "NUM" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    debTotal: Ember.computed("inDocs.[]", function () {
      return this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "DEB" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    transferTotal: Ember.computed("inDocs.[]", function () {
      return this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "TRA" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    cardTotal: Ember.computed("inDocs.[]", function () {
      return this.get("inDocs").filter(function (document) {
        return document.get("paymentMethod") === "CARD" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),

    /*
     * payments
     */
    outDocs: Ember.computed("cleanData.[]", function () {
      return this.get("cleanData").filter(function (document) {
        return document.get("_internalModel.modelName") === "payment" || document.get("_internalModel.modelName") === "purchase-invoice";
      });
    }),
    cashTotalP: Ember.computed("outDocs.[]", function () {
      return this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "NUM" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    debTotalP: Ember.computed("outDocs.[]", function () {
      return this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "DEB" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    transferTotalP: Ember.computed("outDocs.[]", function () {
      return this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "TRA" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    cardTotalP: Ember.computed("outDocs.[]", function () {
      return this.get("outDocs").filter(function (document) {
        return document.get("paymentMethod") === "CARD" && ![8, 9].includes(document.get("status"));
      }).reduce(function (acc, obj) {
        return acc + obj.get("payableAmount");
      }, 0);
    }),
    actions: {
      openFinancialAccount: function openFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountOpen",
          action: "new",
          model: this.get("model")
        });
      },
      closeFinancialAccount: function closeFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountClose",
          action: "new",
          model: this.get("model")
        });
      },
      openDetails: function openDetails(document) {
        this.send("openModal", {
          entity: "document",
          action: "details",
          model: document
        });
      },
      annulDocument: function annulDocument(document) {
        this.send("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      },
      editDocumentModal: function editDocumentModal(document) {
        this.send("openModal", {
          entity: "document",
          action: "edit",
          model: document
        });
      },
      showPdf: function showPdf(url) {
        var model = {
          url: url
        };
        this.send("openModal", {
          entity: "pdf-viewer",
          action: "view-pdf",
          model: model
        });
      },
      transitionToIntendedRoute: function transitionToIntendedRoute(route, params) {
        //Name couldn't be "transitionToRoute" because it conflited with the send
        this.send("transitionToRoute", route, params);
      },
      openReceiptGenerate: function openReceiptGenerate() {
        this.send("openModal", {
          entity: "receipt",
          action: "generate",
          model: {
            financialAccount: this.get("model")
          }
        });
      },
      openPurchaseModal: function openPurchaseModal() {
        this.send("openModal", {
          entity: "purchase-invoice",
          action: "new",
          model: {
            financialAccount: this.get("model"),
            "paymentMethod": "NUM"
          }
        });
      },
      openPaymentGenerate: function openPaymentGenerate() {
        this.send("openModal", {
          entity: "payment",
          action: "generate",
          model: {
            financialAccount: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});