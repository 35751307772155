define("crm/models/sub-task-item-type", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    code: _emberData.default.attr("string"),
    unit: _emberData.default.attr("string"),
    examTaskType: _emberData.default.belongsTo("examTaskType", {
      inverse: "subTaskItemTypes"
    }),
    subTaskItems: _emberData.default.hasMany("subTaskItem", {
      inverse: "subTaskItemType"
    })
  });

  _exports.default = _default;
});