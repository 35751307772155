define("crm/components/purchase-invoice-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.status", function () {
      var result = "";

      switch (this.get("record.status")) {
        case 0:
          result = this.get("intl").t("icons.calendarMenu");
          break;

        case 4:
          result = this.get("intl").t("icons.error");
          break;

        case 8:
          result = this.get("intl").t("icons.annul");
          break;

        default:
          break;
      }

      return result;
    }),
    title: Ember.computed("record.status", function () {
      var result = "";

      switch (this.get("record.status")) {
        case 0:
          result = this.get("intl").t("purchaseInvoices.status.saved");
          break;

        case 4:
          result = this.get("intl").t("invoices.invoice.error");
          break;

        case 8:
          result = this.get("intl").t("invoices.invoice.annuled");
          break;

        default:
          break;
      }

      return result;
    })
  });

  _exports.default = _default;
});