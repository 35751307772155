define("crm/routes/organizations/organization/periodics/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    session: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      nextDateStart: {
        refreshModel: true
      },
      nextDateEnd: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var begin;
      var end;

      if (!Ember.isEmpty(params.nextDateStart) && !Ember.isEmpty(params.nextDateEnd) && (0, _moment.default)(params.nextDateStart) > (0, _moment.default)("1970-01-01")) {
        begin = params.nextDateStart;
        end = params.nextDateEnd;
      }

      if (begin && end) {
        if ((0, _moment.default)(end).diff(begin, "days") <= this.get("dateLimit")) {
          var self = this;
          return Ember.$.ajax({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/periodics/overview" + "?organization=" + this.modelFor("organizations.organization").get("id") + "&dateFrom=" + begin + "&dateTo=" + end,
            accept: "application/json",
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          }).then(function (res) {
            if (res && res.length) {
              return res.map(function (l) {
                var i = 0;
                return {
                  patientID: l[i++],
                  patientCode: l[i++],
                  patientName: l[i++],
                  customerID: l[i++],
                  customerCode: l[i++],
                  customerName: l[i++],
                  itemID: l[i++],
                  itemName: l[i++],
                  periodicID: l[i++],
                  doneDate: l[i++],
                  nextDate: l[i++],
                  tel: l[i++]
                };
              });
            } else {
              return null;
            }
          });
        }
      }

      return null;
    }
  });

  _exports.default = _default;
});