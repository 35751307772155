define("crm/routes/organizations/organization/invites/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var aux = window.location.href.split("/organizations")[0] + "/i/";
      return {
        'organization': this.modelFor("organizations.organization"),
        'baseUrl': aux
      };
    }
  });

  _exports.default = _default;
});