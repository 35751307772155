define("crm/routes/organizations/organization/events/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      startedAfter: {
        refreshModel: true,
        as: "from"
      },
      startedBefore: {
        refreshModel: true,
        as: "until"
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do referral')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      //        if(params.startedAfter && params.startedBefore) {
      //            var after = moment(params.startedAfter);
      //            var before = moment(params.startedBefore);
      //
      //            if(after.isValid() && before.isValid()) {
      //                after = moment(params.startedAfter).startOf("day").format("YYYY-MM-DD");
      //                before = moment(params.startedBefore).endOf("day").format("YYYY-MM-DD");
      //
      //                var organization = this.modelFor("organizations.organization");
      //                return this.get("store").query("event", {organization: organization.get("id"), startedAfter: after, startedBefore: before});
      //            } else {
      //                transition.queryParams = {};
      //            }
      //        }
      var model = this.modelFor("organizations.organization");

      if (model.get("referralFeature")) {
        model.set("startedAfter", params.startedAfter || (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
        model.set("startedBefore", params.startedBefore || (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
        var startedBefore = (0, _moment.default)(model.get("startedBefore")).endOf("day").format();
        var startedAfter = (0, _moment.default)(model.get("startedAfter")).startOf("day").format();
        var organizationID = model.get("id");

        if ((0, _moment.default)(model.get("startedBefore")).diff(model.get("startedAfter"), "days") <= this.get("dateLimit")) {
          this.get("store").query("event", {
            organization: organizationID,
            startedAfter: startedAfter,
            startedBefore: startedBefore
          }).then(function (events) {
            model.set("events", events);
          });
        } else {
          model.set("events", null);
        }
      }

      return model;
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });

  _exports.default = _default;
});