define("crm/models/report", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    consultation: _emberData.default.belongsTo("consultation", {
      inverse: "reports"
    }),
    patient: _emberData.default.belongsTo("patient"),
    template: _emberData.default.belongsTo("template"),
    exam: _emberData.default.belongsTo("exam"),
    reportSections: _emberData.default.hasMany("report-section", {
      inverse: "report"
    }),
    fields: Ember.computed("reportSections.@each.reportFields", function () {
      if (!Ember.isEmpty(this.get("reportSections"))) {
        var fields = Ember.A();
        this.get("reportSections").forEach(function (reportSection) {
          if (!Ember.isEmpty(reportSection.get("reportFields"))) {
            fields.addObject(reportSection.get("reportFields"));
          }
        });
        return fields;
      }
    })
  });

  _exports.default = _default;
});