define("crm/components/internment/todo-monitoring-edit", ["exports", "crm/components/internment/todo-edit"], function (_exports, _todoEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoEdit.default.extend({
    modelName: "todo-monitoring"
  });

  _exports.default = _default;
});