define("crm/components/customer-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    shwActions: false,
    classNames: ["card", "contact-box", "customer-card"],
    color: Ember.computed("model.currentAccount", function () {
      if (this.get("model.currentAccount")) {
        if (this.get("model.currentAccount") > 0) {
          return "text-danger";
        } else {
          return "text-info";
        }
      }
    }),
    avatarUrl: Ember.computed("model.avatar.url", "catalogs", function () {
      return this.get("model.avatar.url") ? this.get("model.avatar.url") : this.get("catalogs.images.customer");
    }),
    actions: {
      updateCustomerModal: function updateCustomerModal(model) {
        this.sendAction("openUpdateCustomerModal", model);
      },
      enable: function enable(model) {
        this.sendAction("setEnabled", model.content ? model.content : model);
      },
      disable: function disable(model) {
        this.sendAction("setEnabled", model.content ? model.content : model);
      },
      toggleActions: function toggleActions() {
        this.set("showActions", !this.get("showActions"));
      }
    }
  });

  _exports.default = _default;
});