define("crm/components/configurations-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ibox"],
    actions: {
      openCreate: function openCreate() {
        this.sendAction("openCreate", this.get('entity'));
      },
      openList: function openList() {
        this.sendAction("openList", this.get('entity'));
      }
    }
  });

  _exports.default = _default;
});