define("crm/controllers/organizations/organization/appointments/custom", ["exports", "moment", "crm/controllers/organizations/organization/appointments"], function (_exports, _moment, _appointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointments.default.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    currentData: Ember.computed.alias("sessionAccount.currentData"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: ["oldAppointment", "intakeDate", "day", "patient", "customer", "type", "goTo", "goToId"],
    displayChartDateInitInput: false,
    endingHour: 23,
    startHour: Ember.computed("model.organization.id", function () {
      return this.get("model.organization.id") === "403" ? 7 : 8;
    }),
    step: 30,
    showAside: false,
    init: function init() {
      this._super();

      if (Ember.$("body").width() < 768 || Ember.$("body").width() > 1600) {
        this.set("showAside", true);
      }
    },
    nobody: Ember.computed("intl", function () {
      return {
        id: 0,
        name: this.get("intl").t("tasks.withoutWorker"),
        appointable: true,
        employee: {
          user: {
            avatarUrl: "https://avataaars.io/?avatarStyle=Transparent&topType=undefined&accessoriesType=undefined&hatColor=undefined&clotheType=undefined&clotheColor=undefined&eyeType=undefined&eyebrowType=undefined&mouthType=undefined&skinColor="
          }
        }
      };
    }),
    isBeforeToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isAfter(this.get("chartDateInit"), "days");
    }),
    isToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isSame(this.get("chartDateInit"), "days");
    }),
    isAfterToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isBefore(this.get("chartDateInit"), "days");
    }),
    hourWindow: Ember.computed("step", "startHour", "endingHour", function () {
      return (1 + this.get("endingHour") - this.get("startHour")) * 60 / this.get("step");
    }),
    chartDateInit: Ember.computed("day", function () {
      return (this.get("day") ? (0, _moment.default)(this.get("day")) : (0, _moment.default)()).startOf("day").hours(this.get("startHour"));
    }),
    hours: Ember.computed("chartDateInit", "hourWindow", "step", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        var newHour = (0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), "minutes");
        result.addObject(newHour);
      }

      return result;
    }),
    tasksFiltered: Ember.computed("tasks.@each.name", function () {
      return Ember.isEmpty(this.get("tasks")) ? undefined : this.get("tasks").filter(function (t) {
        return !t.get("name").match("Contactar:.+") && !t.get("patient.deathdate");
      });
    }),
    absentToday: Ember.computed("model.absences.[]", function () {
      return Ember.isEmpty(this.get("model.absences")) ? [] : this.get("model.absences").filter(function (a) {
        a.set("worker.absentToday", true);
        return a.get("status") === 1;
      });
    }),
    absentTodayOther: Ember.computed("model.absences.[]", function () {
      return Ember.isEmpty(this.get("model.absences")) ? [] : this.get("model.absences").filter(function (a) {
        return a.get("status") === 0;
      });
    }),
    shiftToday: Ember.computed("model.shifts.[]", function () {
      return Ember.isEmpty(this.get("model.shifts")) ? [] : this.get("model.shifts");
    }),
    workersInShift: Ember.computed("shiftToday", function () {
      return Ember.isEmpty(this.get("shiftToday")) ? [] : this.get("shiftToday").uniqBy('worker.id').map(function (s) {
        return s.get("worker");
      }).sortBy("name");
    }),
    otherToday: Ember.computed("availableWorkers.@each.appointable", "availableWorkers.@each.enable", "absentToday.[]", "shiftToday.[]", function () {
      var self = this;
      return Ember.isEmpty(this.get("availableWorkers")) ? [] : this.get("availableWorkers").filter(function (w) {
        return !self.get("shiftToday").map(function (a) {
          return a.get("worker");
        }).some(function (w2) {
          return w2.get("id") === w.get("id");
        });
      }).sortBy("name");
    }),
    availableWorkers: Ember.computed("model.organization.settings.calendar_shift_strict", "absentToday.[]", function () {
      var self = this;
      var absent = self.get("absentToday"); // defined here to text-decorate for 'calendar_absent_display'

      if (this.get("model.organization.settings.calendar_shift_strict")) {
        return this.get("model.workers").filterBy("enable", true).filter(function (w) {
          return !absent.map(function (a) {
            return a.get("worker");
          }).some(function (w2) {
            return w2.get("id") === w.get("id");
          });
        }).filter(function (w) {
          return self.get("shiftToday").map(function (a) {
            return a.get("worker");
          }).some(function (w2) {
            return w2.get("id") === w.get("id");
          });
        }).sortBy("name");
      } else if (this.get("model.organization.settings.calendar_absent_display")) {
        return this.get("model.workers").filterBy("enable", true);
      } else {
        return this.get("model.workers").filter(function (w) {
          return w.get("enable") && !absent.map(function (a) {
            return a.get("worker");
          }).find(function (w2) {
            return w2.get("id") === w.get("id");
          });
        });
      }
    }),
    activeWorkers: Ember.computed("model.workers.@each.active", "availableWorkers.[]", "model.calendarFiltersWorkers.[]", "currentData.hiddenobody", "otherToday.[]", function () {
      var self = this;
      var activeWorkers = [];

      if (!this.get("currentData.hiddenobody")) {
        activeWorkers.push(this.get("nobody"));
      }

      if (this.get("model.calendarFiltersWorkers.length") && self.get("availableWorkers")) {
        this.get("model.calendarFiltersWorkers").sortBy("name").forEach(function (workerFilter) {
          self.get("availableWorkers").forEach(function (available) {
            if (available.get("id") === workerFilter.get("worker.id")) {
              workerFilter.set("worker.active", true);
              activeWorkers.addObject(workerFilter.get("worker"));
              /* Absences */

              if (self.get("absentTodayOther").some(function (a) {
                return a.get("worker.id") == workerFilter.get("worker.id");
              })) {
                workerFilter.get("worker").set("absentMaybe", true);
              } else {
                workerFilter.get("worker").set("absentMaybe", undefined);
              }
            }
          });
        });
      }

      return activeWorkers;
    }),
    activeRooms: Ember.computed("model.organization.rooms.@each.active", "model.calendarFiltersRooms.[]", function () {
      var self = this;
      var result = [];

      if (this.get("model.calendarFiltersRooms.length") && this.get("model.organization.rooms")) {
        this.get("model.calendarFiltersRooms").sortBy("name").forEach(function (filter) {
          self.get("model.organization.rooms").forEach(function (available) {
            if (available.get("id") === filter.get("room.id")) {
              filter.set("room.active", true);
              result.addObject(filter.get("room"));
            }
          });
        });
      }

      return result;
    }),
    rootVisitItemTypes: Ember.computed("model.organization.company.itemTypes.@each.isVisit", function () {
      if (this.get("model.organization.company.itemTypes.length")) {
        return this.get("model.organization.company.itemTypes").filter(function (a) {
          return a.get("isVisit") === true && !a.get("parent.id");
        }).sortBy("name");
      } else {
        return null;
      }
    }),
    enabledItemTypes: Ember.computed("rootVisitItemTypes.@each.enabled", "model.itemTypeFilters.@each.itemType", function () {
      var enabledItemTypes = [];

      if (this.get("model.itemTypeFilters.length")) {
        this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
          itemTypeFilter.set("itemType.enabled", true);
          enabledItemTypes.addObject(itemTypeFilter.get("itemType"));
        });
      }

      return enabledItemTypes.sortBy("name");
    }),
    columns: Ember.computed("model.@each.start", "model.@each.typeTypeRoot", "model.@each.category", "model.@each.worker", "hours.[]", "enabledItemTypes.@each.name", "activeRooms.@each.name", "activeWorkers.@each.name", "filteredTasks.@each.taskTime", function () {
      var _this = this;

      var result = {};
      var self = this;

      if (!this.get("activeWorkers.length") && !self.get("enabledItemTypes.length")) {
        return null;
      }

      this.get("hours").forEach(function (hour) {
        result[hour] = {};
        self.get("activeRooms").forEach(function (room) {
          return result[hour][Ember.get(room, "name")] = [];
        });
        self.get("activeWorkers").forEach(function (worker) {
          return result[hour][Ember.get(worker, "name")] = [];
        });
        self.get("enabledItemTypes").forEach(function (root) {
          return result[hour][root.get("name")] = [];
        });
      });
      /*
       * Set available slots
       */

      this.get("shiftToday").forEach(function (shift) {
        var first = true;

        _this.get("hours").forEach(function (hour) {
          if ((0, _moment.default)(shift.get("startDate")).isSameOrBefore(hour) && (0, _moment.default)(shift.get("endDate")).isAfter(hour)) {
            if (result[hour][shift.get("worker.name")]) {
              if (first) {
                first = false;

                if (result[(0, _moment.default)(hour).add(-30, "minutes")] && result[(0, _moment.default)(hour).add(-30, "minutes")][shift.get("worker.name")]) {
                  result[(0, _moment.default)(hour).add(-30, "minutes")][shift.get("worker.name")].addObject({
                    absent: true,
                    shift: shift,
                    first: true
                  });
                }
              }

              result[hour][shift.get("worker.name")].addObject({
                present: true,
                shift: shift
              });
            }
          }
        });
      });
      /*
       * Sets shift gray area
       */

      this.get("shiftToday").forEach(function (shift) {
        _this.get("hours").forEach(function (hour) {
          if (result[hour][shift.get("worker.name")] && result[hour][shift.get("worker.name")].length === 0) {
            result[hour][shift.get("worker.name")].addObject({
              absent: true
            });
          }
        });
      });
      /*
       * defines shift period
       */

      this.get("shiftToday").forEach(function (shift) {
        _this.get("hours").forEach(function (hour) {
          if (result[hour][shift.get("worker.name")] && result[hour][shift.get("worker.name")][0] && result[hour][shift.get("worker.name")][0].present) {
            result[hour][shift.get("worker.name")] = [{
              present: true,
              shift: result[hour][shift.get("worker.name")][0].shift
            }];
          }
        });
      });
      /*
       * Absenes
       */

      this.get("absentToday").forEach(function (absent) {
        _this.get("hours").forEach(function (hour) {
          if (result[hour][absent.get("worker.name")]) {
            result[hour][absent.get("worker.name")].addObject({
              present: true,
              absent: absent
            });
          }
        });
      });
      this.get("model").filter(function (appointment) {
        return appointment.get("scheduled");
      }).forEach(function (appointment) {
        var room = null;
        /* Room */

        if (appointment.get("room.id")) {
          room = appointment.get("room.name");
        }

        var itemRootTypeID = null;
        /* Type */

        if (appointment.get("type.id")) {
          itemRootTypeID = appointment.get("typeTypeRoot.name");
        } else {
          itemRootTypeID = appointment.get("category.root.name");
        }
        /* Worker */


        var workerID = appointment.get("worker.name") ? appointment.get("worker.name") : _this.get("nobody.name");
        /* Time */

        var appointmentStart = (0, _moment.default)(appointment.get("start"));
        var minutes = appointmentStart.minutes();
        var adjustedHour;

        if (minutes < self.get("step")) {
          adjustedHour = appointmentStart.startOf("hour");
        } else {
          adjustedHour = appointmentStart.startOf("hour").add(self.get("step"), "minutes");
        }
        /* Add by room */


        if (result[adjustedHour] && result[adjustedHour][room]) {
          result[adjustedHour][room] = result[adjustedHour][room].addObject(appointment);

          if (appointment.get("calendarSteps") > 1) {
            var lastStep = adjustedHour;

            for (var k = 1; i < appointment.get("calendarSteps"); k++) {
              lastStep = lastStep.add(self.get("step"), "minutes");

              if (result[adjustedHour] && result[adjustedHour][room]) {
                result[adjustedHour][room] = result[adjustedHour][room].addObject({});
              }
            }
          }
        }
        /* Add by type */


        if (result[adjustedHour] && result[adjustedHour][itemRootTypeID]) {
          result[adjustedHour][itemRootTypeID] = result[adjustedHour][itemRootTypeID].addObject(appointment);

          if (appointment.get("calendarSteps") > 1) {
            lastStep = adjustedHour;

            for (var j = 1; i < appointment.get("calendarSteps"); j++) {
              lastStep = lastStep.add(self.get("step"), "minutes");

              if (result[adjustedHour] && result[adjustedHour][itemRootTypeID]) {
                result[adjustedHour][itemRootTypeID] = result[adjustedHour][itemRootTypeID].addObject({});
              }
            }
          }
        }
        /* Add by worker */


        if (result[adjustedHour] && result[adjustedHour][workerID]) {
          result[adjustedHour][workerID] = result[adjustedHour][workerID].addObject(appointment);

          if (appointment.get("calendarSteps") > 1) {
            lastStep = adjustedHour;

            for (var i = 1; i < appointment.get("calendarSteps"); i++) {
              lastStep = lastStep.add(self.get("step"), "minutes");

              if (result[adjustedHour] && result[adjustedHour][workerID]) {
                result[adjustedHour][workerID] = result[adjustedHour][workerID].addObject({});
              }
            }
          }
        }
      });

      if (this.get("filteredTasks")) {
        this.get("filteredTasks").forEach(function (task) {
          task.set("isTask", true);
          /* Worker */

          var workerID = task.get("worker.name") ? task.get("worker.name") : self.get("nobody.name");
          /* Time */

          var appointmentStart = (0, _moment.default)(task.get("taskDate"));
          appointmentStart.hours(task.get("taskTime").split(":")[0]);
          appointmentStart.minutes(task.get("taskTime").split(":")[1].split(":")[0]);
          var minutes = appointmentStart.minutes();
          var adjustedHour;

          if (minutes < self.get("step")) {
            adjustedHour = appointmentStart.startOf("hour");
          } else {
            adjustedHour = appointmentStart.startOf("hour").add(self.get("step"), "minutes");
          }

          if (result[adjustedHour] && result[adjustedHour][workerID]) {
            result[adjustedHour][workerID] = result[adjustedHour][workerID].addObject(task);
          }
        });
      }

      Ember.$(".calendar td").hover(function () {
        Ember.$('table tr th').eq(Ember.$(this).index()).add(this).toggleClass('highlight');
      });
      return result;
    }),
    actions: {
      hideNobody: function hideNobody() {
        this.get('session').set("data.user_" + this.get("currentUser.id"), {
          hiddenobody: !this.get("currentData.hiddenobody")
        });
      },
      taskCreate: function taskCreate() {
        this.send("openModal", {
          entity: "task",
          action: "create"
        });
      },
      previous: function previous() {
        var day = this.get("chartDateInit").subtract(1, "d");
        this.transitionToRoute("organizations.organization.appointments.custom", this.get("model.organization.id"), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      next: function next() {
        var day = this.get("chartDateInit").add(1, "d");
        this.transitionToRoute("organizations.organization.appointments.custom", this.get("model.organization.id"), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      clearReschedule: function clearReschedule() {
        this.set("model.oldAppointment", null);
      },
      setChartDateInit: function setChartDateInit() {
        this.set("displayChartDateInitInput", false);
      },
      setDisplayChartDateInitInput: function setDisplayChartDateInitInput() {
        this.set("displayChartDateInitInput", true);
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send("openModalAppointmentDetails", appointment);
      },
      openAppointmentCreate: function openAppointmentCreate(hour, workerID, shift) {
        var start = hour ? hour : (0, _moment.default)().format();
        var newAppointment = {
          "organization": this.get("model.organization"),
          "start": start
        };

        if (shift) {
          newAppointment.category = shift.get("category");
        }

        if (this.get("patient")) {
          newAppointment.patient = this.get("store").peekRecord("patient", this.get("patient"));
        }

        if (this.get("model.oldAppointment")) {
          newAppointment.oldAppointment = this.get("model.oldAppointment");
          this.set("oldAppointment", null);
        } else {
          if (this.get("patient")) {
            newAppointment.patient = this.get("store").peekRecord("patient", this.get("patient"));
          }

          if (this.get("customer")) {
            newAppointment.customer = this.get("store").peekRecord("customer", this.get("customer"));
          }

          if (this.get("intakeDate")) {
            newAppointment.intakeDate = this.get("intakeDate");
          }

          if (this.get("type")) {
            newAppointment.type = this.get("type");
          }
        }

        if (this.get("worker")) {
          newAppointment.worker = this.get("store").peekRecord("worker", this.get("worker"));
        }

        if (workerID) {
          newAppointment.worker = this.get("model.workers").filterBy("name", workerID).objectAt(0);
        }

        if (this.get("goTo")) {
          newAppointment.goTo = this.get("goTo");
          newAppointment.goToId = this.get("goToId");
        }

        this.send("openModal", {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set("appointment", appointment);
          Ember.$("#openCreateAppointment").modal();
        }
      },
      openDoTaskModal: function openDoTaskModal(record) {
        this.send("openDoTask", record);
      },
      checkIn: function checkIn(appointment) {
        appointment.set("arrivalTime", (0, _moment.default)().format());
        appointment.set("status", 3);
        appointment.save();
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      deleteAppointment: function deleteAppointment(appointment) {
        if (false == Ember.isEmpty(appointment)) {
          appointment.destroyRecord();
        }
      },
      createdConsultation: function createdConsultation(consultationID) {
        this.transitionToRoute("organizations.organization.consultations.consultation", consultationID);
      },
      transitionToWeek: function transitionToWeek() {
        var day = (0, _moment.default)(this.get("day")).startOf('isoWeek');
        this.transitionToRoute("organizations.organization.appointments.week", this.get("model.organization.id"), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      today: function today() {
        this.set("day", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      },
      transitionToDay: function transitionToDay() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.day", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToDay2: function transitionToDay2() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.day2", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToCustom: function transitionToCustom() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.custom", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      toggleWorkerFilter: function toggleWorkerFilter(worker) {
        var self = this;
        var exists = false;
        var filterToDelete = undefined;

        if (this.get("model.calendarFiltersWorkers.length")) {
          this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
            if (workerFilter.get("worker.id") == Ember.get(worker, "id")) {
              exists = true;
              filterToDelete = workerFilter;
            }
          });
        }

        if (exists) {
          if (filterToDelete) {
            self.get("model.calendarFiltersWorkers").removeObject(filterToDelete);
            filterToDelete.set("worker.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-worker", {
            owner: this.get("currentWorker"),
            worker: worker
          }).save().then(function (calendarFilterWorker) {
            self.get("model.calendarFiltersWorkers").addObject(calendarFilterWorker._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleWorkerFilterAll: function toggleWorkerFilterAll() {
        var self = this;

        if (this.get("model.calendarFiltersWorkers.length")) {
          this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
            self.get("otherToday").forEach(function (worker) {
              if (workerFilter.get("worker.id") == Ember.get(worker, "id")) {
                worker.set("itExists", true);
              }
            });
          });
        }

        this.get("otherToday").forEach(function (worker) {
          if (!worker.get("itExists")) {
            self.get("store").createRecord("calendar-filter-worker", {
              owner: self.get("currentWorker"),
              worker: worker
            }).save().then(function (calendarFilterWorker) {
              self.get("model.calendarFiltersWorkers").addObject(calendarFilterWorker._internalModel);
            }).catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        });
      },
      untoggleWorkerFilterAll: function untoggleWorkerFilterAll() {
        var self = this;
        this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
          workerFilter.set("worker.active", false);
          workerFilter.destroyRecord().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        });
        this.get("model.calendarFiltersWorkers").forEach(function () {
          self.get("model.calendarFiltersWorkers").removeObject(self.get("model.calendarFiltersWorkers.firstObject"));
        });
      },
      toggleRoomFilter: function toggleRoomFilter(room) {
        var self = this;
        var exists = false;
        var filterToDelete = undefined;

        if (this.get("model.calendarFiltersRooms.length")) {
          this.get("model.calendarFiltersRooms").forEach(function (roomFilter) {
            if (roomFilter.get("room.id") == Ember.get(room, "id")) {
              exists = true;
              filterToDelete = roomFilter;
            }
          });
        }

        if (exists) {
          if (filterToDelete) {
            self.get("model.calendarFiltersRooms").removeObject(filterToDelete);
            filterToDelete.set("room.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-room", {
            owner: this.get("currentWorker"),
            room: room
          }).save().then(function (calendarFilterroom) {
            self.get("model.calendarFiltersRooms").addObject(calendarFilterroom._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleItemTypeFilter: function toggleItemTypeFilter(itemType) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.itemTypeFilters.length")) {
          this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
            if (itemTypeFilter.get("itemType.id") == itemType.get("id")) {
              exists = true;
              filterToDelete = itemTypeFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.itemTypeFilters").removeObject(filterToDelete);
            filterToDelete.set("itemType.enabled", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-itemType", {
            owner: this.get("currentWorker"),
            itemType: itemType
          }).save().then(function (calendarFilterItemType) {
            self.get("model.itemTypeFilters").addObject(calendarFilterItemType._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      },
      moveAppointment: function moveAppointment(appointment, ops) {
        var hour = ops.target.hour ? (0, _moment.default)(ops.target.hour).toDate() : null; // var workerID = ops.target.workerID;

        var delta = (0, _moment.default)(hour).diff(appointment.get("start"), "minutes");
        var s = (0, _moment.default)(appointment.get("start"));
        var e = (0, _moment.default)(appointment.get("end"));
        var minutes = s.minutes();
        delta += minutes < this.get("step") ? minutes : minutes - this.get("step");
        appointment.set("start", s.add(delta, "minutes")).toDate();
        appointment.set("end", e.add(delta, "minutes")).toDate();
        appointment.save();
      }
    }
  });

  _exports.default = _default;
});