define("crm/components/bundle-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("items.item.name"),
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "retailPrice",
        "title": this.get('intl').t("items.item.pvp")
      }, {
        "component": "bundle-actions"
      }];
    }),
    actions: {
      openDetails: function openDetails(bundle) {
        this.sendAction("openDetails", {
          entity: "bundle",
          action: "details",
          model: bundle
        });
      },
      openEditItemModal: function openEditItemModal(itemToEdit) {
        this.sendAction('openEditItem', itemToEdit);
      }
    }
  });

  _exports.default = _default;
});