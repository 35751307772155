define("crm/components/item-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    es: Ember.inject.service(),
    classNames: ["module-sales"],
    btnClass: "module-sales",
    sellable: undefined,
    stockable: undefined,
    reset: function reset() {
      Ember.$('#modal-item-select').modal('hide');
      this.set("searchText", "");
      this.set("oldString", "");
      this.set("items", []);
      this.set("sellable", undefined);
      this.set("stockable", undefined);
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "code",
        "title": "",
        "component": "title-models-table-display",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "sellable",
        "title": "",
        "component": "item/umt-icons",
        "className": "column-icons-3"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("items.item.name"),
        "component": "title-models-table-display",
        "sortPrecedence": 0,
        "className": "text-strong columns"
      }, {
        "propertyName": "complementaryDescription",
        "title": this.get('intl').t("items.item.complementaryDescription"),
        "component": "title-models-table-display",
        "className": "hidden-xs"
      }, {
        "propertyName": "pvp",
        "component": "umt-price-display",
        "title": this.get('intl').t("items.item.pvp"),
        "className": "column-price"
      }, {
        "propertyName": "displayPrice",
        "title": this.get('intl').t("itemSelectList.compoundTotal"),
        "component": "umt-price-display",
        "className": "column-price"
      }];
    }),
    actions: {
      searchItems: function searchItems(searchText) {
        this.set("items", []); // needed to reset the table, otherwise it will send, again, the last item form the last text search.

        if (searchText != null && searchText.length > 1) {
          var self = this;
          this.set("lockButton", true);
          this.get("store").query("item", {
            company: self.get("organization.company.id"),
            query: searchText,
            sellable: this.get("sellable") ? this.get("sellable") : undefined,
            stockable: this.get("stockable") ? this.get("stockable") : undefined
          }).then(function (items) {
            self.set("items", items);
            self.set("lockButton", false);
          }).catch(function (e) {
            self.get("es").handle(e);
          });
        }
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable.get('selectedItems.length')) {
          this.set("selectedItem", dataTable.get('selectedItems')[0]);
          this.sendAction("selected", dataTable.get('selectedItems')[0]);

          if (this.get("resetOnSelect")) {
            this.reset();
          }
        }
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});