define("crm/models/absence-type", ["exports", "crm/models/abstract-model"], function (_exports, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({});

  _exports.default = _default;
});