define("crm/controllers/organizations/organization/documents/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    exporting: false,
    queryParams: ["queryQuotations", "queryOrders", "queryInvoices", "queryMemos", "queryReceipts", "queryPayments", "queryDeliveries", "createdAfter", "createdBefore"],
    queryQuotations: true,
    queryOrders: true,
    queryInvoices: true,
    queryMemos: true,
    queryReceipts: true,
    queryPayments: true,
    queryDeliveries: true,
    canDoDocuments: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    filteredModels: Ember.computed('model.invoices.[]', 'model.orders.[]', 'model.quotations.[]', 'model.memos.[]', 'model.receipts.[]', 'model.payments.[]', 'model.deliveries.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices"));
      }

      if (!Ember.isEmpty(this.get("model.orders"))) {
        result.addObjects(this.get("model.orders"));
      }

      if (!Ember.isEmpty(this.get("model.quotations"))) {
        result.addObjects(this.get("model.quotations"));
      }

      if (!Ember.isEmpty(this.get("model.memos"))) {
        result.addObjects(this.get("model.memos"));
      }

      if (!Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts"));
      }

      if (!Ember.isEmpty(this.get("model.payments"))) {
        result.addObjects(this.get("model.payments"));
      }

      if (!Ember.isEmpty(this.get("model.deliveries"))) {
        result.addObjects(this.get("model.deliveries"));
      }

      return result;
    }),
    actions: {
      updateQuery: function updateQuery(name, params) {
        this.set(name, params);
      },
      openReceiptGenerate: function openReceiptGenerate() {
        this.send("openModal", {
          entity: "receipt",
          action: "generate"
        });
      },
      openPaymentGenerate: function openPaymentGenerate() {
        this.send("openModal", {
          entity: "payment",
          action: "generate"
        });
      },
      openPurchaseModal: function openPurchaseModal() {
        this.send("openModal", {
          entity: "purchase-invoice",
          action: "new"
        });
      },
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      },
      export: function _export() {
        var self = this;
        var createdAfter = (0, _moment.default)(this.get("createdAfter")).startOf("day").add(1, "h").toISOString();
        var createdBefore = (0, _moment.default)(this.get("createdBefore")).endOf("day").add(1, "h").toISOString();
        this.set("exporting", true);
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/invoices/xlsx?organization=" + self.get("model.organization.id") + "&createdBefore=" + createdBefore + "&createdAfter=" + createdAfter,
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "invoices-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});