define("crm/components/customer-select-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldString: null,
    disabled: false,
    reset: function reset() {
      Ember.$('#modal-customer-select').modal('hide');
      this.set('searchText', "");
      this.set("customers", []);
      this.set("oldString", null);
    },
    columns: Ember.computed("model.contacts", function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }, {
        "propertyName": "lastContact.contact",
        "title": this.get('intl').t("customers.customer.contacts.preferentialcontact"),
        "className": "column-small",
        "searchable": true
      }, {
        "propertyName": "nif",
        "title": this.get('intl').t("customers.customer.nif"),
        "className": "column-small hidden-xs",
        "searchable": true
      }];
    }),
    searching: Ember.computed("searchText", "disabled", function () {
      return this.get("searchText") && this.get("disabled");
    }),
    actions: {
      searchCustomers: function searchCustomers(query) {
        var self = this;
        this.set("lockButton", true);
        this.get("store").query("customer", {
          company: self.get("organization.company.id"),
          query: query,
          enable: true
        }).then(function (savedEntities) {
          self.set("customers", savedEntities);
          self.set("lockButton", false);
        });
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable.get('selectedItems.length')) {
          this.set("selectedCustomer", dataTable.get('selectedItems')[0]);
          this.set("oldString", null);
          this.reset();
        }
      },
      canceled: function canceled() {
        this.reset();
      },
      close: function close() {
        Ember.$('#modal-customer-select').modal('hide');
      },
      reset: function reset() {
        this.set("oldString", null);
        this.set("searchText", "");
        this.set("customers", []);
      }
    }
  });

  _exports.default = _default;
});