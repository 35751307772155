define("crm/models/report-field", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    reportSection: _emberData.default.belongsTo("report-section", {
      inverse: "reportFields"
    }),
    templateSectionField: _emberData.default.belongsTo("template-section-field"),
    notes: _emberData.default.attr("string"),
    type: Ember.computed("templateSectionField.type", function () {
      return this.get("templateSectionField.type");
    })
  });

  _exports.default = _default;
});