define("crm/controllers/organizations/organization/manufacturers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    actions: {
      createManufacturer: function createManufacturer() {
        this.set("disabledSubmission", true);
        var self = this;
        this.set("model.organization", this.get("organization"));
        this.get("store").createRecord("manufacturer", this.get("model")).save().then(function () {
          self.set("disabledSubmission", false);
          self.transitionToRoute('organizations.organization.manufacturers');
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledSubmission", false);
        });
      },
      canceled: function canceled() {
        this.transitionToRoute('organizations.organization.manufacturers');
      }
    }
  });

  _exports.default = _default;
});