define("crm/models/enterprise", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    organization: _emberData.default.belongsTo("organization"),
    vat: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    liaisons: _emberData.default.hasMany("liaison")
  });

  _exports.default = _default;
});