define("crm/routes/organizations/organization/invites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (this.get("can").cannot('create invite')) {
        transition.abort();
        this.send("noPermissions");
      }
    }
  });

  _exports.default = _default;
});