define("crm/models/worker", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    address: _emberData.default.attr("string"),
    birthdate: _emberData.default.attr("isodate"),
    color: _emberData.default.attr("string"),
    enable: _emberData.default.attr("boolean"),
    email: _emberData.default.attr("string"),
    externalID: _emberData.default.attr("number"),
    manager: _emberData.default.attr("boolean"),
    mobilePhone: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    professionalLetterNumber: _emberData.default.attr("number"),
    role: _emberData.default.attr("number"),
    undermanager: _emberData.default.attr("boolean"),
    organization: _emberData.default.belongsTo("organization"),
    employee: _emberData.default.belongsTo("employee"),
    appointmentStyle: Ember.computed("color", function () {
      if (!this.get("color")) {
        return "";
      }

      var color = "#" + this.get("color") + " !important;";
      return Ember.String.htmlSafe("background-color: " + color);
    }),
    imageUrl: Ember.computed(function () {
      return "https://www.connecticutchildrens.org/wp-content/uploads/2016/12/DoctorAvatars_DoctorAvatar_Blue-e1485529651530.png";
    }),
    appointable: Ember.computed("role", function () {
      // return [1, 2, 3, 5].includes(this.get("role")) && this.get("name") !== "Pet Universal" && this.get("enable");
      return this.get("enable") && this.get("name") !== "Pet Universal" && this.get("name") !== "automation";
    }),
    isCurrentWorker: Ember.computed("employee.user.id", "currentUser.id", function () {
      return this.get("employee.user.id") === this.get("currentUser.id");
    }),
    isAdmin: Ember.computed("manager", function () {
      return this.get("manager");
    }),
    isVet: Ember.computed("role", function () {
      return this.get("role") == 2;
    }),
    nameSimple: Ember.computed("name", function () {
      var x = this.get("name").split(" ");
      return x[0] + " " + x[x.length - 1];
    })
  });
  /*
   Roles
   1 - Admin
   2 - Vet
   3 - Enfermeiro
   4 - Auxiliar
   5 - Rececionista
  */


  _exports.default = _default;
});