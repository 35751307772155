define("crm/routes/organizations/organization/appointments/appointment/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var appointmentID = this.modelFor("organizations.organization.appointments.appointment").get('id');
      var appointment = this.get('store').findRecord('appointment', appointmentID);
      return Ember.RSVP.hash({
        appointment: appointment,
        workersByOrganization: organization.get("workers"),
        itemTypes: this.store.findAll('itemType')
      }).then(function (hashResult) {
        var appointmentModel = hashResult.appointment;
        Ember.set(appointment, "workersByOrganization", hashResult.workersByOrganization);
        Ember.set(appointment, "itemTypes", hashResult.itemTypes);
        return appointmentModel;
      });
    },
    actions: {
      updated: function updated() {
        this.transitionTo('organizations.organization.appointments');
      },
      canceled: function canceled() {
        this.transitionTo('organizations.organization.appointments');
      }
    }
  });

  _exports.default = _default;
});