define("crm/routes/organizations/organization/hotel/stays/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization");

      if (model.get("hotelFeature")) {
        model.set("date", params.date || (0, _moment.default)().format("YYYY-MM-DD"));
        var organizationID = model.get("id");
        this.get("store").query("stay", {
          organization: organizationID,
          date: model.get("date")
        }).then(function (stays) {
          model.set("stays", stays);
        });
        this.get("store").query("stay", {
          organization: organizationID,
          startDate: (0, _moment.default)().format("YYYY-MM-DD")
        }).then(function (stays) {
          model.set("staysComing", stays);
        });
        this.get("store").query("stay", {
          organization: organizationID,
          endDate: (0, _moment.default)().format("YYYY-MM-DD")
        }).then(function (stays) {
          model.set("staysLeaving", stays);
        });
        return model;
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("date", null);
      }
    }
  });

  _exports.default = _default;
});