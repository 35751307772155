define("crm/models/questionnaire-answer-integer", ["exports", "ember-data", "crm/models/questionnaire-answer"], function (_exports, _emberData, _questionnaireAnswer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _questionnaireAnswer.default.extend({
    value: _emberData.default.attr("number")
  });

  _exports.default = _default;
});