define("crm/routes/organizations/organization/internments/internment/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var organizationID = this.modelFor("organizations.organization").get("id");
      controller.set("itemTypes", this.get("store").query("item-type", {
        organization: organizationID
      }));
      this.get("store").query("item-type", {
        organization: organizationID
      }).then(function (itemTypes) {
        if (!Ember.isEmpty(itemTypes)) {
          itemTypes.forEach(function (itemType) {
            Ember.set(itemType, "name", self.get('intl').t("itemTypes.translation." + itemType.get("code")));
          });
        }

        controller.set("itemTypes", itemTypes);
      });
      controller.set("workers", this.get("store").query("worker", {
        organization: organizationID
      }));
      this.get('store').findRecord("patient", model.get("patient.id")).then(function (patient) {
        if (patient.get("owner.id")) {
          self.get("store").query("contact", {
            customer: patient.get("owner.id")
          });
        }
      });
      controller.set("clock", (0, _moment.default)());
    }
  });

  _exports.default = _default;
});