define("crm/helpers/chronos-is-between", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chronosIsBetween = chronosIsBetween;
  _exports.default = void 0;

  function chronosIsBetween() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var array = params[2];

      if (array && array.get('length') > 0) {
        var start = (0, _moment.default)(params[0]);
        var end = (0, _moment.default)(params[1]);
        var result = [];
        array.forEach(function (object) {
          var objectCreationDate = (0, _moment.default)(object.get('created')).minutes(0);

          if (objectCreationDate.isSameOrAfter(start) && objectCreationDate.isSameOrBefore(end)) {
            result.addObject(object);
          }
        });
        return result;
      }
    }
  });

  _exports.default = _default;
});