define("crm/models/todo-chore", ["exports", "ember-data", "crm/models/todo"], function (_exports, _emberData, _todo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todo.default.extend({
    kind: "chore",
    nextTodo: _emberData.default.belongsTo('todo-chore', {
      inverse: 'prevTodo'
    }),
    prevTodo: _emberData.default.belongsTo('todo-chore', {
      inverse: 'nextTodo'
    }),
    internment: _emberData.default.belongsTo('internment', {
      inverse: "chores"
    }),
    lastActionEat: Ember.computed("allActions.length", function () {
      if (this.get("allActions.length") > 0) {
        return this.get("allActions").filter(function (action) {
          return action.get("initials") === null || action.get("initials") !== 20;
        }).sortBy("started").reverse().get("firstObject");
      }
    })
  });

  _exports.default = _default;
});