define("crm/models/sms-to-send", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    organization: _emberData.default.belongsTo('organization'),
    started: _emberData.default.attr('isodate'),
    appointmentDate: _emberData.default.attr('isodate'),
    customerName: _emberData.default.attr('string'),
    patientID: _emberData.default.attr('number'),
    patientName: _emberData.default.attr('string'),
    text: _emberData.default.attr('string')
  });

  _exports.default = _default;
});