define("crm/components/stay-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("selectedPatient.id") && this.get("model")) {
        this.set("model.patient", this.get('selectedPatient'));
      }

      if (this.get("model.startDate")) {
        this.set("model.startDate", (0, _moment.default)(this.get("model.startDate")).format("YYYY-MM-DD"));
      }

      if (this.get("model.endDate")) {
        this.set("model.endDate", (0, _moment.default)(this.get("model.endDate")).format("YYYY-MM-DD"));
      }
    },
    boxesList: Ember.computed("organization.id", "model.startDate", "model.endDate", function () {
      if (this.get("model.startDate") && this.get("model.endDate")) {
        return this.get("store").query("box", {
          organization: this.get("organization.id"),
          startDate: (0, _moment.default)(this.get("model.startDate")).format(),
          endDate: (0, _moment.default)(this.get("model.endDate")).format(),
          available: true
        });
      }
    }),
    close: function close() {
      Ember.$("#modal-stay-edit").modal("hide");
    },
    actions: {
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
      },
      createStay: function createStay() {
        this.set("disabledSubmission", true);
        var self = this;
        this.set("model.startDate", new Date(this.get("model.startDate")));
        this.set("model.endDate", new Date(this.get("model.endDate")));
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.set("disabledSubmission", false);
          self.get('flashMessages').danger(result.errors ? result.errors : "Error");
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
        this.set("selectedPatient", null);
      },
      canceled: function canceled() {
        this.sendAction("reloadModel");
        this.close();
      }
    }
  });

  _exports.default = _default;
});