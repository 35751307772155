define("crm/controllers/organizations/organization/invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "customer.name",
        "title": this.get('intl').t("customers.customer.title")
      }, {
        "propertyName": "created",
        "component": "umt-datetime",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("invoices.invoice.date")
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("invoices.invoice.title")
      }, {
        "propertyName": "payableAmount",
        "component": "show-table-money",
        "title": this.get('intl').t("invoices.invoice.total")
      }, {
        "component": "invoices-list-actions"
      }];
    }),
    actions: {
      openDetails: function openDetails(invoice) {
        this.send("openModal", {
          entity: "invoice",
          action: "details",
          model: invoice
        });
      }
    }
  });

  _exports.default = _default;
});