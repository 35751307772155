define("crm/helpers/chronos-is-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chronosIsDay = chronosIsDay;
  _exports.default = void 0;

  function chronosIsDay(params
  /*, hash*/
  ) {
    return (0, _moment.default)().isSame(params[0], 'day');
  }

  var _default = Ember.Helper.helper(chronosIsDay);

  _exports.default = _default;
});