define("crm/routes/organizations/organization/purchase-orders/purchase-order/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      model.set("warehouse", Ember.get(model, "purchaseOrderLines.firstObject.warehouse"));
      model.get("purchaseOrderLines").forEach(function (line) {
        return controller.send("addLine", line.get("item"), line.get("quantity"), line.get("purchaseRequestLine"), line);
      });
    }
  });

  _exports.default = _default;
});