define("crm/controllers/organizations/organization/stats/sales/item-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    itemTypes: Ember.computed.alias("model.organization.company.itemTypes"),
    itemTypesRoot: Ember.computed.alias("model.organization.company.itemTypesRoot"),
    itemTypeMap: Ember.computed("itemTypes.[]", function () {
      if (!this.get("itemTypes")) {
        return {};
      }

      var result = {
        0: {
          root: {
            id: 0
          }
        }
      };
      this.get("itemTypes").forEach(function (it) {
        result[it.get("id")] = it;
      });
      return result;
    }),
    data1: Ember.computed("itemTypesRoot.[]", "itemTypeMap.[]", "model.model1.[]", function () {
      if (Ember.isEmpty(this.get("itemTypesRoot")) || Ember.isEmpty(this.get("itemTypeMap")) || Ember.isEmpty(this.get("model.model1"))) {
        return {};
      }

      var self = this;
      var aux = {
        0: {
          name: "",
          quantity: 0,
          value: 0,
          type: null
        }
      };
      var total = 0;
      this.get("itemTypesRoot").forEach(function (itemTypeRoot) {
        aux[itemTypeRoot.get("id")] = {
          name: itemTypeRoot.get("name"),
          quantity: 0,
          value: 0,
          type: itemTypeRoot
        };
      });
      this.get("model.model1").forEach(function (x) {
        if (!x[0]) {
          x[0] = 0;
        }

        var it = self.get("itemTypeMap")[x[0]];

        if (!it) {
          it = self.get("itemTypeMap")[0];
        }

        var itemTypeRootID = Ember.get(Ember.get(it, "root"), "id");
        aux[itemTypeRootID].quantity += x[1];
        aux[itemTypeRootID].value += x[2];
        total += x[2];
      });
      this.set("total1", total);
      return aux;
    }),
    data2: Ember.computed("itemTypesRoot.[]", "itemTypeMap.[]", "model.model2.[]", function () {
      if (Ember.isEmpty(this.get("itemTypesRoot")) || Ember.isEmpty(this.get("itemTypeMap")) || Ember.isEmpty(this.get("model.model2"))) {
        return {};
      }

      var self = this;
      var aux = {
        0: {
          name: "",
          quantity: 0,
          value: 0,
          type: null
        }
      };
      var total = 0;
      this.get("itemTypesRoot").forEach(function (itemTypeRoot) {
        aux[itemTypeRoot.get("id")] = {
          name: itemTypeRoot.get("name"),
          quantity: 0,
          value: 0,
          type: itemTypeRoot
        };
      });
      this.get("model.model2").forEach(function (x) {
        if (!x[0]) {
          x[0] = 0;
        }

        var it = self.get("itemTypeMap")[x[0]];

        if (!it) {
          it = self.get("itemTypeMap")[0];
        }

        var itemTypeRootID = Ember.get(Ember.get(it, "root"), "id");
        aux[itemTypeRootID].quantity += x[1];
        aux[itemTypeRootID].value += x[2];
        total += x[2];
      });
      this.set("total2", total);
      return aux;
    }),
    diffs: Ember.computed("data1.[]", "data2.[]", function () {
      var _this = this;

      if (Ember.isEmpty(this.get("model.model1")) || Ember.isEmpty(this.get("model.model2"))) {
        return [];
      }

      var result = Object.keys(this.get("data1")).map(function (k) {
        var d1 = _this.get("data1")[k];

        var d2 = _this.get("data2")[k];

        return {
          name: d1.name,
          quantity: d1.quantity,
          value: d1.value,
          quantityOld: d2.quantity,
          valueOld: d2.value
        };
      });
      return result.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    values1: Ember.computed("data1.[]", function () {
      var _this2 = this;

      return Object.keys(this.get("data1")).map(function (k) {
        return _this2.get("data1")[k];
      }).sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    values2: Ember.computed("data2.[]", function () {
      var _this3 = this;

      return Object.keys(this.get("data2")).map(function (k) {
        return _this3.get("data2")[k];
      }).sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    chartOptions: {
      legend: {
        display: false
      }
    },
    chartData1: Ember.computed("values1.[]", function () {
      return {
        labels: this.get("values1") ? this.get("values1").map(function (x) {
          return x.name;
        }) : [],
        datasets: this.get("values1") ? [{
          data: this.get("values1").map(function (x) {
            return x.value ? x.value.toFixed(2) : "";
          }),
          backgroundColor: this.get("values1").map(function (x) {
            return x.type ? x.type.get("color") : "#CCC";
          })
        }] : []
      };
    }),
    chartData2: Ember.computed("values2.[]", function () {
      return {
        labels: this.get("itemTypesRoot").map(function (x) {
          return x.get("name");
        }),
        datasets: this.get("values2") ? [{
          data: this.get("values2").map(function (x) {
            return x.value ? x.value.toFixed(2) : "";
          }),
          backgroundColor: this.get("values2").map(function (x) {
            return x.type ? x.type.get("color") : "#CCC";
          })
        }] : []
      };
    }),
    columns1: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "Tipo",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "quantity",
        "title": "#",
        "className": "column-price"
      }, {
        "propertyName": "value",
        "title": "Valor",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "%",
        "component": "umt-number-variation",
        "className": "u-ibox-tip-warpper"
      }];
    }),
    columns2: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "Tipo",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "quantity",
        "title": "#",
        "className": "column-price"
      }, {
        "propertyName": "value",
        "title": "Valor",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    }),
    columnsDiff: Ember.computed(function () {
      return [{
        "propertyName": "quantity",
        "title": "#",
        "className": "column-price"
      }, {
        "propertyName": "value",
        "title": "€",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "propertyName": "diff",
        "title": "%"
      }];
    })
  });

  _exports.default = _default;
});