define("crm/routes/organizations/organization/stats/internments/daily", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    session: Ember.inject.service('session'),
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get('id');
      var createdAfter = this.modelFor("organizations.organization.stats").createdAfter;
      var createdBefore = this.modelFor("organizations.organization.stats").createdBefore;
      var createdAfter2 = this.modelFor("organizations.organization.stats").createdAfter2;
      var createdBefore2 = this.modelFor("organizations.organization.stats").createdBefore2;
      return Ember.RSVP.hash({
        createdAfter: createdAfter,
        createdBefore: createdBefore,
        createdAfter2: createdAfter2,
        createdBefore2: createdBefore2,
        model1: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/internments/daily?organization=" + organizationID + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        model2: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/internments/daily?organization=" + organizationID + "&createdAfter=" + createdAfter2 + "&createdBefore=" + createdBefore2,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        })
      });
    }
  });

  _exports.default = _default;
});