define("crm/components/internment/consumptions-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stuff: Ember.computed("model.@each.created", function () {
      var result = {};
      var filter = this.get("filter");

      if (filter && filter === "today") {
        var firstHour;

        if ((0, _moment.default)().hours() >= 8) {
          firstHour = (0, _moment.default)().startOf('day').hours(8);
        } else {
          firstHour = (0, _moment.default)().startOf('day').hours(8).subtract(24, 'hours');
        }

        this.get("model").forEach(function (consumption) {
          if (firstHour.isSameOrBefore(consumption.get('created'))) {
            if (consumption.get("consumable.name")) {
              var key = consumption.get("consumable.name");

              if (result[key]) {
                var tmp = result[key];
                tmp.get('consumptionsAux').addObject(consumption);
                result[key] = tmp;
              } else {
                var consumableAux = consumption.get("consumable");
                Ember.set(consumableAux, 'consumptionsAux', [consumption]);
                result[key] = consumableAux;
              }
            }
          }
        });
      } else {
        this.get("model").forEach(function (consumption) {
          if (consumption.get("consumable.name")) {
            var key = consumption.get("consumable.name");

            if (result[key]) {
              var tmp = result[key];
              tmp.get('consumptionsAux').addObject(consumption);
              result[key] = tmp;
            } else {
              var consumableAux = consumption.get("consumable");
              Ember.set(consumableAux, 'consumptionsAux', [consumption]);
              result[key] = consumableAux;
            }
          }
        });
      }

      var ordered = {};
      Object.keys(result).sort().forEach(function (key) {
        ordered[key] = result[key];
      });
      return ordered;
    }),
    actions: {
      addConsumption: function addConsumption() {
        this.set('plus', this.get('plus') + 1);
        this.set('ConsumptionsAuxLength', this.get('ConsumptionsAuxLength') + 1);
      },
      deleteConsumption: function deleteConsumption() {
        if (this.get('ConsumptionsAuxLength') > 1) {
          this.set('minus', this.get('minus') + 1);
          this.set('ConsumptionsAuxLength', this.get('ConsumptionsAuxLength') - 1);
        }

        return;
      },
      save: function save() {
        var self = this;
        var difference = 0;

        if (this.get('plus') > this.get('minus')) {
          difference = this.get('plus') - this.get('minus');

          for (var i = 0; i < difference; i++) {
            var consumption = this.get('store').createRecord('consumption', {
              internment: self.get('internment'),
              doer: self.get('currentVet'),
              consumable: self.get('modalConsumable')
            });
            consumption.save().catch(function () {
              self.get('store').unloadRecord(consumption);
            });
          }
        } else if (this.get('plus') < this.get('minus')) {
          difference = this.get('minus') - this.get('plus');
          var consumptions = this.get('model').sortBy('created');
          var filteredConsumptions = consumptions.filter(function (consumption) {
            return consumption.get('consumable.id') == self.get('modalConsumable.id');
          });

          for (var v = 0; v < difference; v++) {
            var consumptionToDelete = filteredConsumptions.get(v);
            consumptionToDelete.destroyRecord();
          }
        }

        Ember.$('#consumableModal').modal('hide');
      },
      openConsumableModal: function openConsumableModal(consumable) {
        if (!this.get('canEdit')) {
          return;
        }

        this.set('modalConsumable', consumable);
        this.set('plus', 0);
        this.set('minus', 0);
        this.set('ConsumptionsAuxLength', consumable.get('consumptionsAux.length'));
        Ember.$('#consumableModal').modal();
      },
      closeConsumableModal: function closeConsumableModal() {
        Ember.$('#consumableModal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});