define("crm/controllers/organizations/organization/group/overview-sales", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    can: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    queryParams: ["startedAfter", "startedAfter2", "startedBefore", "startedBefore2"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedAfter2", (0, _moment.default)().subtract(1, 'days').startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore2", (0, _moment.default)().subtract(1, 'days').endOf("day").format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "sortPrecedence": 0,
        "title": "Mes"
      }];
    }),
    columns2: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "sortPrecedence": 0,
        "title": "Mes"
      }];
    }),
    actions: {
      setFilter1: function setFilter1(filter) {
        Ember.set(filter, "active", Ember.get(filter, "active") ? false : true);

        switch (filter.id) {
          case "d":
            this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
            this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
            break;

          case "w":
            this.set("startedAfter", (0, _moment.default)().startOf("week").format("YYYY-MM-DD"));
            this.set("startedBefore", (0, _moment.default)().endOf("week").format("YYYY-MM-DD"));
            break;

          case "m":
            this.set("startedAfter", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
            this.set("startedBefore", (0, _moment.default)().endOf("month").format("YYYY-MM-DD"));
            break;

          case "y":
            this.set("startedAfter", (0, _moment.default)().startOf("year").format("YYYY-MM-DD"));
            this.set("startedBefore", (0, _moment.default)().endOf("year").format("YYYY-MM-DD"));
            break;
        }
      },
      setFilter2: function setFilter2(filter) {
        Ember.set(filter, "active", Ember.get(filter, "active") ? false : true);

        switch (filter.id) {
          case "d":
            this.set("startedAfter2", (0, _moment.default)(this.get("startedAfter")).subtract(1, 'days').startOf("day").format("YYYY-MM-DD"));
            this.set("startedBefore2", (0, _moment.default)(this.get("startedBefore")).subtract(1, 'days').endOf("day").format("YYYY-MM-DD"));
            break;

          case "w":
            this.set("startedAfter2", (0, _moment.default)(this.get("startedAfter")).subtract(1, 'weeks').startOf("day").format("YYYY-MM-DD"));
            this.set("startedBefore2", (0, _moment.default)(this.get("startedBefore")).subtract(1, 'weeks').endOf("day").format("YYYY-MM-DD"));
            break;

          case "m":
            this.set("startedAfter2", (0, _moment.default)(this.get("startedAfter")).subtract(1, 'months').startOf("day").format("YYYY-MM-DD"));
            this.set("startedBefore2", (0, _moment.default)(this.get("startedBefore")).subtract(1, 'months').endOf("day").format("YYYY-MM-DD"));
            break;

          case "y":
            this.set("startedAfter2", (0, _moment.default)(this.get("startedAfter")).subtract(1, 'years').startOf("day").format("YYYY-MM-DD"));
            this.set("startedBefore2", (0, _moment.default)(this.get("startedBefore")).subtract(1, 'years').endOf("day").format("YYYY-MM-DD"));
            break;
        }
      }
    }
  });

  _exports.default = _default;
});