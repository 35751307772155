define("crm/components/bundle-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      openDetails: function openDetails() {
        this.sendAction('openDetails', this.get("record"));
      },
      delete: function _delete(item) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          item.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});