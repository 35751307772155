define("crm/components/consultation-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoConsultationDetails: Ember.computed("can", function () {
      return this.get("can").cannot("do clinical");
    }),
    actions: {
      openModalMDCDetails: function openModalMDCDetails(exam) {
        this.sendAction('openModal', {
          entity: "exam",
          action: "details",
          model: exam
        });
      },
      openDrugDetailsModal: function openDrugDetailsModal(drug) {
        this.sendAction("openModal", {
          entity: "drug",
          action: "details",
          model: drug
        });
      },
      consultationDetails: function consultationDetails() {
        Ember.$("#modal-consultation-details").modal("hide");
        Ember.$("#openAppointmentDetails").modal("hide");
        this.sendAction("transitionToRouteID", "organizations.organization.consultations.consultation.details", this.get("model").get("id"));
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$('#openAssociatePatientOnAppointment').modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction('openEditPatientModal', model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        Ember.$("#modal-consultation-details").modal("hide");
        this.sendAction('openCreateAppointmentModal', model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction('openUpdateCustomerModal', record);
      }
    }
  });

  _exports.default = _default;
});