define("crm/components/show-task-priority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    taskPriorities: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('tasks.priorities.low'),
        color: "text-primary"
      }, {
        id: 2,
        name: this.get('intl').t('tasks.priorities.high'),
        color: "text-warning"
      }, {
        id: 3,
        name: this.get('intl').t('tasks.priorities.urgency'),
        color: "text-danger"
      }];
    }),
    priority: Ember.computed('record.priority', function () {
      for (var i = 0; i < this.get('taskPriorities.length'); i++) {
        var priority = this.get('taskPriorities').objectAt(i);

        if (priority.id == this.get('record.priority')) {
          return priority;
        }
      }
    })
  });

  _exports.default = _default;
});