define("crm/routes/organizations/organization/item-adjustments/upload", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return {
        organization: this.modelFor("organizations.organization"),
        stockDate: (0, _moment.default)().format("YYYY-MM-DD")
      };
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("itemAdjustments.new.title")
      });
    }
  });

  _exports.default = _default;
});