define("crm/controllers/organizations/organization/equipment-parameters/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoEquipmentParameters: Ember.computed("can", function () {
      return this.get("can").can("do menu");
    }),
    columns: Ember.computed('intl.locale', function () {
      return [{
        propertyName: "id",
        className: "column-id"
      }, {
        propertyName: "name",
        title: this.get('intl').t("suppliers.name"),
        "component": "title-models-table-display"
      }, {
        propertyName: "parameter.name",
        title: this.get('intl').t("equipmentParameters.type"),
        "component": "title-models-table-display",
        className: "column-status"
      }, {
        propertyName: "equipment.name",
        title: this.get('intl').t("pages.equipment"),
        "component": "title-models-table-display",
        className: "column-status"
      }, {
        propertyName: "minVal",
        title: this.get('intl').t("equipmentParameters.minVal"),
        "component": "title-models-table-display",
        className: "column-status"
      }, {
        propertyName: "maxVal",
        title: this.get('intl').t("equipmentParameters.maxVal"),
        "component": "title-models-table-display",
        className: "column-status"
      }, {
        propertyName: "species.name",
        title: this.get('intl').t("patients.patient.species"),
        "component": "title-models-table-display",
        className: "column-status"
      }, {
        component: "manufacturers-equipments-actions",
        className: "column-actions-3"
      }];
    }),
    actions: {
      transitionToRoute: function transitionToRoute(path, id) {
        this.send("transitionToRouteID", path, id);
      }
    }
  });

  _exports.default = _default;
});