define("crm/components/purchase-invoice-documents-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    displayEditButton: Ember.computed("record.status", function () {
      return ![8].includes(this.get("record.status"));
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "purchase-invoice",
          action: action,
          model: this.get("record")
        });
      },
      setPaid: function setPaid() {
        if (confirm(this.get("intl").t("shoppingCarts.markAsPaid"))) {
          this.set("record.paidAmount", this.get("record.payableAmount"));
          this.get("record").save();
        }
      },
      delete: function _delete(action) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          action.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});