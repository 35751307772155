define("crm/controllers/organizations/organization/internments/internment/financial-report", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super();

      this.set('start', (0, _moment.default)().startOf('hour').subtract(1, "days"));
      this.set('end', (0, _moment.default)().endOf('hour'));
    },
    filteredFinancials: Ember.computed('model.id', 'model.financials', 'start', 'end', function () {
      if (this.get('model.financials.length') > 0) {
        var self = this;
        var result = [];
        this.get('model.financials').forEach(function (financial) {
          var financialCreationDate = (0, _moment.default)(financial.get('created')).minutes(0);

          if (financialCreationDate.isSameOrAfter(self.get('start')) && financialCreationDate.isSameOrBefore(self.get('end'))) {
            result.addObject(financial);
          }
        });
        return result;
      }
    }),
    filteredFinancialsChanged: Ember.observer('filteredFinancials.[]', function () {
      if (this.get('filteredFinancials.length') > 0) {
        //Financials Totals
        var totalCost = 0;
        var totalPaid = 0;
        var notPaid = 0;
        this.get('filteredFinancials').forEach(function (financial) {
          totalCost = totalCost + (Ember.isEmpty(financial.get('costEstimate')) ? 0 : financial.get('costEstimate'));
          totalPaid = totalPaid + (Ember.isEmpty(financial.get('paid')) ? 0 : financial.get('paid'));
        });

        if (totalCost > totalPaid) {
          notPaid = totalCost - totalPaid;
        }

        this.set('totalCost', totalCost);
        this.set('totalPaid', totalPaid);
        this.set('notPaid', notPaid);
      }
    }),
    filteredConsumptions: Ember.computed('model.id', 'model.consumptions.@each.created', 'start', 'end', function () {
      if (this.get('model.consumptions.length') > 0) {
        var self = this;
        var result = [];
        this.get('model.consumptions').forEach(function (consumption) {
          var consumptionCreationDate = (0, _moment.default)(consumption.get('created')).minutes(0);

          if (consumptionCreationDate.isSameOrAfter(self.get('start')) && consumptionCreationDate.isSameOrBefore(self.get('end'))) {
            result.addObject(consumption);
          }
        });
        return result;
      }
    })
  });

  _exports.default = _default;
});