define("crm/routes/organizations/organization/configurations/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor("organizations.organization");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get("campaignsFeature")) {
        this.store.findRecord("organization-communication", model.get("id")).then(function (organizationCommunication) {
          if (!Ember.isEmpty(organizationCommunication)) {
            controller.set("organizationCommunication", organizationCommunication);

            if (!controller.get("organizationCommunication.daysBefore")) {
              controller.set("organizationCommunication.daysBefore", 3);
            }

            if (controller.get("organizationCommunication.hour").includes("undefined")) {
              controller.set("organizationCommunication.hourToSend", (0, _moment.default)().hour(19).minutes(0).format("HH:mm"));
            } else {
              controller.set("organizationCommunication.hourToSend", controller.get("organizationCommunication.hour"));
            }
          } else {
            controller.set("organizationCommunication", {});
          }
        });
      }
    },
    actions: {
      openCreatePeriodicTypeModal: function openCreatePeriodicTypeModal() {
        this.send("openModalPeriodicTypeCreate");
      }
    }
  });

  _exports.default = _default;
});