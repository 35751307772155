define("crm/components/umt-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "span",
    title: Ember.computed("record.status", function () {
      return this.get("record.status") === 1 ? this.get('intl').t('exams.status.done') : this.get('intl').t('exams.status.pending');
    })
  });

  _exports.default = _default;
});