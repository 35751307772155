define("crm/routes/organizations/organization/customers/customer/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var customerID = this.modelFor("organizations.organization.customers.customer").get('id');
      return this.get('store').findRecord('customer', customerID);
    },
    actions: {
      updated: function updated(id) {
        this.transitionTo('organizations.organization.customers.customer', id);
      },
      canceled: function canceled(customerID) {
        this.transitionTo('organizations.organization.customers.customer', customerID);
      }
    }
  });

  _exports.default = _default;
});