define("crm/components/supplier-item-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    lines: [],
    disableItemSelect: false,
    disableSupplierSelect: false,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.item.id")) {
        this.set("disableItemSelect", true);
      }

      if (this.get("model.supplier.id")) {
        this.set("disableSupplierSelect", true);
      }
    },
    reset: function reset() {
      Ember.$("#modal-supplier-item-new").modal("hide");
      this.set("disabledAction", false);
      this.set("model", {});
    },
    actions: {
      addItem: function addItem(selectedItem) {
        this.set("model.item", selectedItem);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.get("store").createRecord("supplier-item", this.get("model")).save().then(function () {
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function () {
          self.set("disabledAction", false);
          self.sendAction("reloadModel");
          self.reset();
        });
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});