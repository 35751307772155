define("crm/components/purchase-order-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disabledAction: false,
    recordsToDestroy: [],
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("purchase-order", this.get("model.id"));
        this.get("store").query("purchase-order-line", {
          purchaseOrder: this.get("model.id")
        });
      }
    },
    close: function close() {
      Ember.$("#modal-purchase-order-edit").modal("hide");
    },
    disabledSubmissionPOEdit: Ember.computed("model.purchaseOrderLines.@each.line", function () {
      return this.get("model.purchaseOrderLines.length") ? false : true;
    }),
    hideIconPOEdit: Ember.computed("model.purchaseOrderLines.[]", function () {
      return this.get("model.purchaseOrderLines.length") ? false : true;
    }),
    actions: {
      setSelectedItem: function setSelectedItem(item) {
        var self = this;

        if (this.get("model.purchaseOrderLines.length") > 0) {
          var exists = false;
          var pol;
          this.get("model.purchaseOrderLines").forEach(function (purchaseOrderLine) {
            pol = purchaseOrderLine;

            if (item.get("id") === purchaseOrderLine.get("item.id")) {
              exists = true;
            }
          });

          if (exists === true) {
            pol.set("quantity", Number(pol.get("quantity")) + 1);
          } else {
            self.get("model.purchaseOrderLines").addObject(self.get("store").createRecord("purchase-order-line", {
              item: item,
              quantity: 1,
              warehouse: null
            }));
          }
        } else {
          this.get("model.purchaseOrderLines").addObject(this.get("store").createRecord("purchase-order-line", {
            item: item,
            quantity: 1,
            warehouse: null
          }));
        }
      },
      canceled: function canceled() {
        this.close();

        if (this.get("model.id")) {
          this.get("store").findRecord("purchase-order", this.get("model.id"));
          this.set("model.purchaseOrderLines", this.get("store").query("purchase-order-line", {
            purchaseOrder: this.get("model.id")
          }));
        }
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledAction", false);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.get("recordsToDestroy").forEach(function (record) {
          record.destroyRecord();
        });
        this.set("model.organization", this.get("organization"));
        this.get("model").save().then(function (purchaseOrder) {
          self.get("model.purchaseOrderLines").forEach(function (purchaseOrderLine) {
            purchaseOrderLine.set("purchaseOrder", purchaseOrder);
            purchaseOrderLine.save();
          });
          self.sendAction("reloadModel");
          self.close();
          self.get("flashMessages").success(self.get("intl").t("purchaseOrder.messages.createdSuccess"));
        }).catch(function () {
          self.get("store").unloadRecord(self.get("model"));
          self.set("disabledAction", false);
        });
      },
      deletePurchaseOrderLine: function deletePurchaseOrderLine(line) {
        this.get("model.purchaseOrderLines").removeObject(line);
        this.get("recordsToDestroy").addObject(line);
      }
    }
  });

  _exports.default = _default;
});