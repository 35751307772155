define("crm/routes/internal/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.createdAfter && (0, _moment.default)(params.createdAfter).isValid() && params.createdBefore && (0, _moment.default)(params.createdBefore).isValid()) {
        var self = this;
        var createdAfter = (0, _moment.default)(params.createdAfter).startOf("day").utcOffset(0, true).format();
        var createdBefore = (0, _moment.default)(params.createdBefore).endOf("day").utcOffset(0, true).format();
        return Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/internments/total" + "?createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        }).then(function (lines) {
          return lines.map(function (i) {
            return {
              id: i[0],
              name: i[1],
              count: i[2]
            };
          });
        });
      }
    }
  });

  _exports.default = _default;
});