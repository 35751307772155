define("crm/models/purchase-memo-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    discount2: _emberData.default.attr("number"),
    discountMoney: _emberData.default.attr("number"),
    lot: _emberData.default.attr("string"),
    purchasePrice: _emberData.default.attr("number"),
    quantityPromotion: _emberData.default.attr("number"),
    shelfLife: _emberData.default.attr("localdate"),
    unitPriceWithVat: _emberData.default.attr("number"),
    purchaseMemo: _emberData.default.belongsTo("purchase-memo", {
      inverse: "lines"
    }),
    vat: _emberData.default.belongsTo("tax"),
    itemAdjustmentLines: _emberData.default.hasMany("item-adjustment-line", {
      inverse: "purchaseMemoLine"
    })
  });

  _exports.default = _default;
});