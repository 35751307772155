define("crm/models/item-unit", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    quantity: _emberData.default.attr(),
    preferred: _emberData.default.attr("boolean"),
    inbound: _emberData.default.attr("boolean"),
    outbound: _emberData.default.attr("boolean"),
    price: _emberData.default.attr("number"),
    pricePercentage: _emberData.default.attr("number"),
    item: _emberData.default.belongsTo("item"),
    unit: _emberData.default.belongsTo("unit")
  });

  _exports.default = _default;
});