define("crm/helpers/appointment-scheduled-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appointmentScheduledDisplay = appointmentScheduledDisplay;
  _exports.default = void 0;

  function appointmentScheduledDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      if (params[0] > 0) {
        return this.get('intl').t('appointments.appointment.yes');
      } else {
        return this.get('intl').t('appointments.appointment.no');
      }
    }
  });

  _exports.default = _default;
});