define("crm/components/internment-shopping-cart-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      openDetails: function openDetails(record) {
        var self = this;
        this.get("store").findRecord("shopping-cart", record.get("shoppingCart.id")).then(function (shoppingCart) {
          self.sendAction("openDetails", shoppingCart);
        });
      }
    }
  });

  _exports.default = _default;
});