define("crm/routes/organizations/organization/internments/internment/clinical-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      if (this.modelFor("organizations.organization.internments.internment").get('internmentStatus.chat.id')) {
        this.store.query('chat-message', {
          chat: this.modelFor("organizations.organization.internments.internment").get('internmentStatus.chat.id')
        });
      }

      return this.modelFor("organizations.organization.internments.internment");
    }
  });

  _exports.default = _default;
});