define("crm/routes/organizations/organization/group/financial-accounts/financial-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor("organizations.organization.group.financialAccounts");
      model.set("financialAccount", this.store.findRecord("financial-account", params.financial_account_id));
      return model;
    },
    afterModel: function afterModel(model) {
      this.set("breadCrumb", {
        finalTitle: model.get("name")
      });
    }
  });

  _exports.default = _default;
});