define("crm/components/order-line-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    tariff: 1,

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      // using a copy to prevent changing real obj without save action
      this.set("unitPrice", this.get("model.unitPrice")) || 0;
      this.set("quantity", this.get("model.quantity")) || 1;
      this.set("item", this.get("model.item"));
      this.set("name", this.get("model.name"));

      if (this.get("model.item.pvp") == this.get("model.unitPrice") && (!this.get("model.discount") || this.get("model.item.pvpDiscount") == this.get("model.discount"))) {
        this.set("tariff", 1);
      } else if (this.get("model.item.pvp2") == this.get("model.unitPrice") && (!this.get("model.discount") || this.get("model.item.pvpDiscount2") == this.get("model.discount"))) {
        this.set("tariff", 2);
      } else if (this.get("model.item.pvp3") == this.get("model.unitPrice") && (!this.get("model.discount") || this.get("model.item.pvpDiscount3") == this.get("model.discount"))) {
        this.set("tariff", 3);
      }

      this.set("discount", this.get("model.discount"));
    },

    /*
     * Computed
     */
    usesDecimals: Ember.computed("model.item.baseUnit.precisionDigits", "model.organization.pet.usesDecimals", function () {
      return this.get("model.organization.pet.usesDecimals") || (this.get("model.organization.id") === "45" || this.get("model.organization.id") === "46") && this.get("model.item.baseUnit.precisionDigits");
    }),
    tariffs: Ember.computed("model.item.id", function () {
      var result = [];

      if (this.get("model.item.pvp")) {
        result.push({
          id: 1,
          name: "pvp"
        });
      }

      if (this.get("model.item.pvp2")) {
        result.push({
          id: 2,
          name: "pvp2"
        });
      }

      if (this.get("model.item.pvp3")) {
        result.push({
          id: 3,
          name: "pvp3"
        });
      }

      return result;
    }),
    canChangePrice: Ember.computed("currentWorker.isAdmin", "model.isConsultation", "model.organization.id", function () {
      return !this.get("model.isConsultation") || this.get("can").can("changeCartPrice item");
    }),
    bruto: Ember.computed("unitPrice", "quantity", function () {
      return this.money(this.get("unitPrice") * this.get("quantity"));
    }),
    valorTotalDesc: Ember.computed("bruto", "discount", function () {
      return this.money(this.get("bruto") * (Ember.isEmpty(this.get("discount")) ? 1 : this.get("discount") / 100));
    }),
    brutoDesc: Ember.computed("bruto", "valorTotalDesc", function () {
      return this.money(this.get("bruto") - this.get("valorTotalDesc"));
    }),

    /*
     * Functions
     */
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    updateItemPvp: function updateItemPvp(item) {
      switch (this.get("tariff")) {
        case 3:
          item.set("pvp3", this.get("unitPrice"));
          break;

        case 2:
          item.set("pvp2", this.get("unitPrice"));
          break;

        default:
          item.set("pvp", this.get("unitPrice"));

          if (item.get("vat.vat.amount")) {
            item.set("salePrice", (item.get('pvp') * 100 / (100 + item.get('vat.vat.amount'))).toFixed(2)); // TODO: move to java
          }

          break;
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },

    /*
     * Actions
     */
    actions: {
      cancel: function cancel() {
        if (this.get("canceled")) {
          this.sendAction("canceled");
        }

        this.close();
      },
      delete: function _delete() {
        this.sendAction("deleteLine", this.get("model"));
        this.close();
      },
      save: function save() {
        var _this = this;

        var self = this;
        this.set("saving", true);

        if (this.get("updateItemPrice")) {
          var item = this.get("store").peekRecord("item", this.get("model.item.id"));
          this.updateItemPvp(item);
          item.save();
        }

        this.set("model.name", this.get("name"));
        this.set("model.quantity", this.get("quantity"));
        this.set("model.unitPrice", this.get("unitPrice"));
        this.set("model.valorTotalDesc", this.get("valorTotalDesc"));
        this.set("model.linePrice", this.get("brutoDesc"));
        this.set("model.discount", this.get("discount")); //this.set("model.tariff", this.get("tariff")); // TODO ?????????????? is not saved?

        if (self.get("model.isConsultation")) {
          // is shopping cart
          self.get("model").save().then(function () {
            return self.close();
          }).catch(function (r) {
            return self.get("es").errorReturned(r);
          }).finally(function () {
            return _this.set("saving", false);
          });
        } else {
          self.close();
          this.set("saving", false);
        }
      },
      setLineTariff: function setLineTariff(tariff) {
        this.set("tariff", tariff);
        var unitPrice = null;
        var discount = null;

        switch (tariff) {
          case 2:
            unitPrice = this.get("model.item.saleItemUnit2") ? this.get("model.item.saleUnitPrice2") : this.get("model.item.pvp2");
            discount = this.get("model.item.pvpDiscount2");
            break;

          case 3:
            unitPrice = this.get("model.item.saleItemUnit3") ? this.get("model.item.saleUnitPrice3") : this.get("model.item.pvp3");
            discount = this.get("model.item.pvpDiscount3");
            break;
        }

        unitPrice = unitPrice || (this.get("model.item.saleItemUnit") ? this.get("model.item.saleUnitPrice") : this.get("model.item.pvp"));
        discount = discount || this.get("model.item.pvpDiscount");

        if (discount) {
          if (this.get("model.lineGroupDiscount")) {
            discount = discount + this.get("model.lineGroupDiscount") * (100 - discount) / 100;
          } else {// discount = discount;
          }
        } else {
          if (this.get("model.lineGroupDiscount")) {
            discount = this.get("model.lineGroupDiscount");
          } else {
            discount = 0;
          }
        }

        this.set("unitPrice", unitPrice);
        this.set("discount", discount);
      }
    }
  });

  _exports.default = _default;
});