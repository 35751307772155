define("crm/components/purchase-documents-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service("session"),
    exporting: false,
    canDoDocuments: Ember.computed("can", function () {
      //return this.get("can").can("do sale"); TODOJD
      return true;
    }),
    isPurchaseInvoice: Ember.computed("record._internalModel.modelName", function () {
      return this.get("record._internalModel.modelName") === "purchase-invoice";
    }),
    failled: Ember.computed("record.status", function () {
      return this.get("record").constructor.modelName == "invoice" && this.get("record.status") === 4;
    }),
    editDocument: Ember.computed("record.paymentMethod", function () {
      return !Ember.isEmpty(this.get("record.paymentMethod")) && ![3, 4, 8, 9].includes(this.get("record.status"));
    }),
    order: Ember.computed("record.order", function () {
      var model = this.get("record");

      if (model.constructor.modelName == "order") {
        if (this.get("record.status") == 1 || this.get("record.status") == 2) {
          this.set("hasInvoice", true);
        } else {
          this.set("hasInvoice", false);
        }

        return true;
      } else {
        return false;
      }
    }),
    actions: {
      openDetails: function openDetails(document) {
        var route = "organizations.organization." + document.constructor.modelName + "s." + document.constructor.modelName;
        this.sendAction("transitionToRouteID", route, document.get("id"));
      },
      delete: function _delete(document) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          document.destroyRecord();
        }
      },
      editDocumentModal: function editDocumentModal(document) {
        this.sendAction("editDocumentModal", document);
      },
      sendEmail: function sendEmail(document) {
        this.sendAction("sendEmail", document);
      }
    }
  });

  _exports.default = _default;
});