define("crm/controllers/organizations/organization/items/item/movements", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ["start", "end"],
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [{
        "propertyName": "PostingDate",
        "title": this.get("intl").t("saft.documentDate"),
        "className": "column-date",
        "component": "umt-date",
        "sortDirection": "asc",
        "sortPrecedence": 1
      }, {
        "propertyName": "Document",
        "title": this.get("intl").t("documents.document.title")
      }, {
        "propertyName": "Description",
        "title": this.get("intl").t("items.item.name")
      }, {
        "propertyName": "Warehouse",
        "title": this.get("intl").t("warehouses.warehouse.title")
      }, {
        "propertyName": "Quantity",
        "title": this.get("intl").t("purchases.quantity"),
        "className": "column-date text-right"
      }, {
        "propertyName": "Stock",
        "title": this.get("intl").t("items.item.stock"),
        "className": "column-date text-right",
        "component": "umt-number"
      }];
      return listCollumns;
    }),
    actions: {
      search: function search(dates) {
        var self = this;
        self.set("start", (0, _moment.default)(dates.nextDateStart).startOf("day").format("YYYY-MM-DD"));
        self.set("end", (0, _moment.default)(dates.nextDateEnd).endOf("day").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});