define("crm/components/absence-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    close: function close() {
      this.sendAction("reloadModel");
      this.toggleProperty("closed");
    },
    actions: {
      create: function create() {
        var self = this;
        this.set("disabledAction", true);
        this.set("model.status", 0);
        this.get("store").createRecord("absence", this.get("model")).save().then(function () {
          return self.close();
        }).catch(function (e) {
          return self.get("es").handle(e);
        }).finally(function () {
          return self.set("disabledAction", false);
        });
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});