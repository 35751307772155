define("crm/components/purchase-order-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      callModal: function callModal(arg) {
        this.get("callModal")(arg);
      },
      openPurchaseRequest: function openPurchaseRequest(purchaseRequest) {
        this.sendAction("openPurchaseRequest", purchaseRequest);
      },
      receivePurchaseOrder: function receivePurchaseOrder(purchaseOrder) {
        this.sendAction("receivePurchaseOrder", purchaseOrder);
      },
      openPurchaseOrder: function openPurchaseOrder(purchaseOrder) {
        this.sendAction("openPurchaseOrder", purchaseOrder);
      },
      receivePurchaseRequest: function receivePurchaseRequest(purchaseRequest) {
        this.sendAction("receivePurchaseRequest", purchaseRequest);
      }
    }
  });

  _exports.default = _default;
});