define("crm/components/room-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("brands.brand.name")
      }, {
        "component": "room-actions",
        "className": "column-actions-2"
      }];
    }),
    actions: {
      editRoom: function editRoom(model) {
        Ember.$("#modal-room-list").modal('hide');
        this.sendAction("openModal", {
          entity: "room",
          action: "edit",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});