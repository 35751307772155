define("crm/components/invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.[]", function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("invoices.invoice.title"),
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "created",
        "component": "show-table-created-date-time",
        "title": this.get('intl').t("invoices.invoice.date")
      }, {
        "propertyName": "payableAmount",
        "component": "show-table-money",
        "title": this.get('intl').t("invoices.invoice.total")
      }, {
        "component": "invoices-list-actions"
      }];
    })
  });

  _exports.default = _default;
});