define("crm/components/give-up-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    giveUp: Ember.computed("record.giveUpReason", "record.giveUpDate", function () {
      switch (!Ember.isEmpty(this.get("record.giveUpReason")) || !Ember.isEmpty(this.get("record.giveUpDate"))) {
        case true:
          return this.get('intl').t("icons.check");

        default:
          return "";
      }
    })
  });

  _exports.default = _default;
});