define("crm/components/internment/task-action", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    eventIO: null,
    attributeBindings: ["title"],
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    doubleClick: function doubleClick() {
      if (this.get('task.internment.disabled')) {
        return;
      } //check if there is any event for single click, disable it


      var eventIO = this.get('eventIO');

      if (eventIO) {
        Ember.run.cancel(eventIO);
        this.set('eventIO', undefined);
      }

      if (!this.get('action')) {
        this.doAction(1);
      } else if (this.get('action.status') === 5) {
        this.updateAction(1);
      } else {// ??
      }
    },
    click: function click() {
      if (this.get('action.status') === 4) {
        return;
      }

      var self = this;
      var eventIO = this.get('eventIO');

      if (!eventIO) {
        //if this is the first click, will schedule it for later
        this.set('eventIO', Ember.run.later(this, function () {
          if (self.get("task.constructor.modelName") == "todo-exam" && self.get("task.type.id")) {
            if (!Ember.isEmpty(self.get("action")) && !Ember.isEmpty(self.get("action.examination.id"))) {
              self.get("store").findRecord("exam", self.get("action.examination.id")).then(function (savedExam) {
                if (!Ember.isEmpty(savedExam.get("doneDate"))) {
                  self.sendAction("openModal", {
                    entity: "exam",
                    action: "details",
                    model: savedExam
                  });
                } else {
                  self.sendAction("openModal", {
                    entity: "exam",
                    action: "edit",
                    model: savedExam
                  });
                }
              });
            } else {
              self.sendAction("openModal", {
                entity: "exam",
                action: "create",
                model: {
                  patient: self.get("task.internment.patient"),
                  type: self.get("task.type"),
                  organization: self.get("task.internment.organization"),
                  action: {
                    exam: self.get("task"),
                    status: 1,
                    doer: self.get("currentWorker"),
                    started: (0, _moment.default)(self.get("thisDate"))
                  }
                }
              });
            }
          } else {
            this.sendAction('openAction', this.get('task'), self.get('thisDate'), this.get('action'), self.get('statusColor'), self.get('prevAction'));
          } ///clear additional events


          var eventIO = this.get('eventIO');

          if (eventIO) {
            Ember.run.cancel(eventIO);
            this.set('eventIO', undefined);
          }
        }, 600));
      }
    },
    doAction: function doAction() {
      var self = this;
      var action = this.get('store').createRecord('action', {
        status: 1,
        started: (0, _moment.default)(this.get('thisDate')),
        doer: self.get('currentWorker')
      });
      var taskType = this.get('task.constructor.modelName');

      if (taskType.includes("-")) {
        taskType = taskType.split("-")[1];
      }

      action.set(taskType, this.getTrueTask(this.get('thisDate'), this.get('task')));
      action.save().catch(function (error) {
        self.get("es").errorReturned(error);
        self.get('store').unloadRecord(action);
      });
    },
    updateAction: function updateAction() {
      var self = this;

      if (this.get('task.isPhysicalExam') && !Ember.isEmpty(this.get('physicalExame'))) {
        var physicalExame = JSON.stringify(this.get('physicalExame'));
        this.set('notes', physicalExame);
      }

      var action = this.get("action");
      action.set('status', 1);
      action.set('doer', self.get('currentWorker'));
      action.save().catch(function (error) {
        return self.get("es").errorReturned(error);
      });
    },
    getTrueTask: function getTrueTask(thisDate, task) {
      if (task.get('prevTodo.started') && (0, _moment.default)(thisDate).isBefore(task.get('started'), "h")) {
        return this.getTrueTask(thisDate, this.get('store').peekRecord(task.get('constructor.modelName'), task.get('prevTodo.id')));
      } else {
        return task;
      }
    }
  });

  _exports.default = _default;
});