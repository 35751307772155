define("crm/components/input-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["stars"],
    init: function init() {
      this._super();

      if (this.get("selected")) {
        this.set("selectedValue", this.get("selected"));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("selected")) {
        this.set("selectedValue", this.get("selected"));
      } else {
        this.set("selectedValue", null);
      }
    },
    values: Ember.computed("selectedValue", "max", function () {
      var result = [];

      for (var i = 0; i < (this.get("max") || 5); i++) {
        result.push({
          value: i + 1,
          active: this.get("selectedValue") && i < this.get("selectedValue")
        });
      }

      return result;
    }),
    actions: {
      setValue: function setValue(value) {
        if (this.get("selectedValue") === value) {
          this.set("selectedValue", null);
          this.set("selected", null);
          this.get("onchange")();
        } else {
          this.set("selectedValue", value);
          this.set("selected", value);
          this.get("onchange")(value);
        }
      }
    }
  }); // geral@hospitalveterinario.eu


  _exports.default = _default;
});