define("crm/models/periodic", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    nextPeriodic: _emberData.default.attr("date"),
    intakeDate: _emberData.default.attr("date"),
    //observations:       DS.attr("string"),
    patient: _emberData.default.belongsTo("patient", {
      inverse: "periodics"
    }),
    task: _emberData.default.belongsTo("task"),
    type: _emberData.default.belongsTo("item", {
      inverse: "periodics"
    }),
    consultation: _emberData.default.belongsTo("consultation"),
    smsSent: _emberData.default.belongsTo("sms-sent", {
      inverse: "periodic"
    })
  });

  _exports.default = _default;
});