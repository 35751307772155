define("crm/components/stats-sales-item-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: "ul",
    classNames: ["list-group", "clear-list", "m-t"],
    top: Ember.computed("model.[]", function () {
      return this.get("model").slice(1, 3);
    }),
    rest: Ember.computed("model.[]", function () {
      return this.get("model").slice(3);
    }),
    parent: Ember.computed("model.firstObject.name", function () {
      var types = {};
      this.get("store").peekAll('item-type').map(function (t) {
        return types[t.get("name")] = t;
      });
      return this.get("model.firstObject.name") ? types[this.get("model.firstObject.name")] ? types[this.get("model.firstObject.name")].get("codesStr") : "" : "";
    })
  });

  _exports.default = _default;
});