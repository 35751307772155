define("crm/components/sheltered-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeModalAppointmentDetailBySheltered: function closeModalAppointmentDetailBySheltered(shelteredID) {
        Ember.$("#openAppointmentDetails").modal('hide');
        this.sendAction('closeAppointmentDetailBySheltered', shelteredID);
      },
      goToSheltered: function goToSheltered(shelteredID) {
        this.sendAction('goToShelteredPage', shelteredID);
      }
    }
  });

  _exports.default = _default;
});