define("crm/components/financial-account-edit", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    newFinancialAccount: {},
    disabledSubmissionFinancialEdit: false,
    countries: _uConstants.default.countries,
    currencies: _uConstants.default.currencies,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.get("store").findRecord("financial-account", this.get("model.id"));
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      selectCountry: function selectCountry(country) {
        this.set('country', country.code);
      },
      selectCurrency: function selectCurrency(currency) {
        this.set('currency', currency.code);
      },
      showBankAccount: function showBankAccount() {
        this.set('showBankAccount', true);
        this.set('show', true);
      },
      show: function show() {
        this.set('showBankAccount', false);
        this.set('show', true);
      },
      create: function create() {
        this.set('disabledSubmissionFinancialEdit', true);
        var self = this;

        if (this.get('organization')) {
          this.set('model.organization', this.get('organization'));
        }

        this.get("model").save().then(function () {
          self.get('flashMessages').success(self.get('intl').t('financialAccounts.messages.editedSuccess'));
          self.close();
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set('disabledSubmissionFinancialEdit', false);
        });
      },
      reset: function reset() {
        this.set('disabledSubmissionFinancialEdit', false);
        this.get("model") && this.get("model").rollbackAttributes();
        this.set('disabledAction', false);
      }
    }
  });

  _exports.default = _default;
});