define("crm/routes/organizations/organization/items/item/movements", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    queryParams: {
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (!params.start || !params.end) {
        return null;
      }

      var self = this;
      var start = (0, _moment.default)(params.start);
      var end = (0, _moment.default)(params.end);
      var organization = this.modelFor("organizations.organization"); // needs to be current org not items org

      var item = this.modelFor("organizations.organization.items.item");

      if (start.isValid() && end.isValid()) {
        return Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/movements" + "?organization=" + organization.get("id") + "&start=" + start.format("YYYY-MM-DD") + "&end=" + end.format("YYYY-MM-DD") + "&item=" + item.get("id"),
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(result) {
            result.data = JSON.parse(result.data);
            return result;
          },
          error: function error() {
            return {};
          }
        });
      } else {
        return [];
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
      }
    }
  });

  _exports.default = _default;
});