define("crm/controllers/organizations/organization/saft/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    saftsMinDate: Ember.computed("model.legalDeclarations.@each.endDate", "model.legalDeclarations.[]", function () {
      if (this.get("model.legalDeclarations.length")) {
        return this.get("model.legalDeclarations").sortBy("endDate").get("lastObject");
      } else {
        return null;
      }
    }),
    actions: {
      openSaftNew: function openSaftNew() {
        this.send("openModal", {
          entity: "saft",
          action: "new",
          model: {
            minDate: this.get("saftsMinDate.endDate") ? (0, _moment.default)(this.get("saftsMinDate.endDate")).format("YYYY-MM-DD") : null,
            startDate: this.get("saftsMinDate.endDate") ? (0, _moment.default)(this.get("saftsMinDate.endDate")).add(1, "month").startOf("month").format("YYYY-MM-DD") : (0, _moment.default)().startOf("month").add(-1, "month").format("YYYY-MM-DD"),
            endDate: this.get("saftsMinDate.endDate") ? (0, _moment.default)(this.get("saftsMinDate.endDate")).add(1, "month").endOf("month").format("YYYY-MM-DD") : (0, _moment.default)().endOf("month").add(-1, "month").format("YYYY-MM-DD")
          }
        });
      },
      openSaftDetails: function openSaftDetails(model) {
        this.send("openModal", {
          entity: "saft",
          action: "details",
          model: model
        });
      },
      export: function _export() {}
    }
  });

  _exports.default = _default;
});