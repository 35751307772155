define("crm/components/purchase-request-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    displayEditButton: Ember.computed("record.status", function () {
      return [0].includes(this.get("record.status"));
    }),
    actions: {
      duplicatePurchaseRequest: function duplicatePurchaseRequest() {
        this.sendAction("openPurchaseRequest", this.get("record"));
      }
    }
  });

  _exports.default = _default;
});