define("crm/components/stats-totals", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    windowsUnit: Ember.computed(function () {
      return "d";
    }),
    window: Ember.computed("model.createdAfter", "model.createdBefore", function () {
      if (this.get("model.createdAfter") && this.get("model.createdBefore")) {
        return (0, _moment.default)(this.get("model.createdBefore")).diff(this.get("model.createdAfter"), this.get("windowsUnit"));
      } else {
        return 0;
      }
    }),
    days: Ember.computed("model.createdAfter", "window", function () {
      var result = [];
      result.addObject(this.get("model.createdAfter"));

      for (var i = 1; i <= this.get("window"); i++) {
        result.addObject((0, _moment.default)(result.objectAt(i - 1)).add(1, this.get("windowsUnit")).format("YYYY-MM-DD"));
      }

      return result;
    }),
    days2: Ember.computed("model.createdAfter2", "window", function () {
      var result = [];
      result.addObject(this.get("model.createdAfter2"));

      for (var i = 1; i <= this.get("window"); i++) {
        result.addObject((0, _moment.default)(result.objectAt(i - 1)).add(1, this.get("windowsUnit")).format("YYYY-MM-DD"));
      }

      return result;
    }),
    chartOptions: {
      legend: {
        display: false
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    values1: Ember.computed("days.[]", "model.model1.[]", function () {
      var _this = this;

      var result = [];
      var total1 = 0;
      this.get("days").forEach(function (d) {
        var flag = false;

        _this.get("model.model1").forEach(function (x) {
          if (d === x[0]) {
            flag = true;
            total1 += x[_this.get("totalFor") || 1];
            result.addObject({
              name: x[0],
              quantity: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: d,
            quantity: 0
          });
        }
      });
      this.set("total1", total1);
      return result;
    }),
    values2: Ember.computed("days2.[]", "model.model2.[]", function () {
      var _this2 = this;

      var result = [];
      var total2 = 0;
      this.get("days2").forEach(function (d) {
        var flag = false;

        _this2.get("model.model2").forEach(function (x) {
          if (d === x[0]) {
            flag = true;
            total2 += x[_this2.get("totalFor") || 1];
            result.addObject({
              name: x[0],
              quantity: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: d,
            quantity: 0
          });
        }
      });
      this.set("total2", total2);
      return result;
    }),
    chartData: Ember.computed("values1.[]", "values2.[]", function () {
      if (!this.get("values1") && !this.get("values2")) {
        return [];
      }

      return {
        labels: this.get("days"),
        datasets: [{
          label: "Periodo actual",
          data: this.get("values1").map(function (x) {
            return x.quantity;
          }),
          backgroundColor: 'rgba(26,179,148,0.5)',
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff"
        }, {
          label: "Periodo homologo",
          data: this.get("values2").map(function (x) {
            return x.quantity;
          }),
          backgroundColor: 'rgba(220, 220, 220, 0.5)',
          pointBorderColor: "#fff"
        }]
      };
    }),
    chartData2: Ember.computed("model.model1.[]", "model.model2.[]", function () {
      if (!this.get("model.model1") && !this.get("model.model2")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("model.model1")).map(function (d) {
          return d.name;
        }),
        datasets: [{
          data: this.get("model.model1").map(function (d) {
            return d.quantity;
          })
        }, {
          data: this.get("model.model2").map(function (d) {
            return d.quantity;
          })
        }]
      };
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "Dia",
        "component": "umt-date",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "quantity",
        "title": "#",
        "component": this.get("dataType") == "currency" ? "umt-price-display" : "title-models-table-display",
        "className": this.get("dataType") == "currency" ? "column-price-medium text-right text-xs-small" : "column-id text-right text-xs-small"
      }];
    })
  });

  _exports.default = _default;
});