define("crm/components/export-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("after", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      save: function save() {
        this.get("model.func")(this.get("before"), this.get("after"));
        this.close();
      },
      reset: function reset() {
        Ember.$("modal-export-export").modal("hide");
        this.set("after", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
        this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});