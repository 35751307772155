define("crm/models/task", ["exports", "ember-data", "moment", "crm/models/abstract-model"], function (_exports, _emberData, _moment, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    comments: _emberData.default.hasMany('taskComment'),
    description: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization'),
    patient: _emberData.default.belongsTo('patient'),
    priority: _emberData.default.attr('number'),
    status: _emberData.default.attr('number'),
    taskDate: _emberData.default.attr('localdate'),
    taskTime: _emberData.default.attr(),
    endHour: _emberData.default.attr(),
    doneTaskDate: _emberData.default.attr('isodate'),
    worker: _emberData.default.belongsTo('worker'),
    consultation: _emberData.default.attr('number'),
    type: _emberData.default.belongsTo('task-type'),
    isDue: Ember.computed("dateTime", "status", function () {
      if (this.get("status") == 1) {
        if (this.get("time")) {
          if ((0, _moment.default)().isBefore(this.get("dateTime"))) {
            return false;
          } else {
            return true;
          }
        } else {
          if ((0, _moment.default)().isBefore(this.get("date"), "day")) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }),
    isOpen: Ember.computed("isDue", function () {
      return !this.get("isDue");
    }),
    isDone: Ember.computed("status", function () {
      return this.get("status") === 2;
    }),
    priorityColor: Ember.computed("priority", function () {
      return this.get("priority") === 1 ? "primary" : this.get("priority") === 2 ? "warning" : this.get("priority") === 3 ? "danger" : "";
    }),
    date: Ember.computed("taskDate", function () {
      return this.get("taskDate");
    }),
    defaultDuration: 30,
    time: Ember.computed("taskTime", function () {
      return this.get("taskTime");
    }),
    dateTime: Ember.computed("taskDate", "taskTime", function () {
      if (this.get("taskDate")) {
        if (this.get("taskTime")) {
          return (0, _moment.default)(this.get("date").getFullYear() + "-" + (this.get("date").getMonth() + 1) + "-" + this.get("date").getDate() + "T" + this.get("time"), "YYYY-MM-DDTHH:mm").toDate();
        } else {
          return (0, _moment.default)(this.get("date").getFullYear() + "-" + (this.get("date").getMonth() + 1) + "-" + this.get("date").getDate() + "T" + "00:00:00", "YYYY-MM-DDTHH:mm").toDate();
        }
      } else {
        return null;
      }
    }),
    start: Ember.computed("dateTime", function () {
      if (!this.get("date") || !this.get("time")) {
        return null;
      }

      return (0, _moment.default)(this.get("date").getFullYear() + "-" + (this.get("date").getMonth() + 1) + "-" + this.get("date").getDate() + "T" + this.get("time"), "YYYY-MM-DDTHH:mm").toDate();
    }),
    end: Ember.computed("date", "start", "endHour", function () {
      if (!this.get("date") || !this.get("start") || !this.get("endHour")) {
        return null;
      }

      var end = (0, _moment.default)(this.get("date").getFullYear() + "-" + (this.get("date").getMonth() + 1) + "-" + this.get("date").getDate() + "T" + this.get("endHour"), "YYYY-MM-DDTHH:mm");

      if ((0, _moment.default)(this.get("start")).isAfter(end)) {
        end = end.add(1, "d");
      }

      return end.toDate();
    }),
    height: Ember.computed("start", "end", function () {
      if (this.get("end")) {
        var midnight = (0, _moment.default)(this.get("start")).endOf("days");
        var newEnd = (0, _moment.default)(this.get("end")).isAfter(midnight) ? midnight : this.get("end");
        var diff = (0, _moment.default)(newEnd).diff(this.get("start"), "minutes");
        return diff ? diff : 15;
      } else {
        return this.get("defaultDuration");
      }
    }),
    top: Ember.computed("taskTime", function () {
      if (this.get("taskTime")) {
        var start = (0, _moment.default)(this.get("taskTime"), 'HH:mm:ss');

        if (start.minutes() < 30) {
          return start.minutes();
        } else {
          return start.minutes() - 30;
        }
      }
    })
  });

  _exports.default = _default;
});