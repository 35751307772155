define("crm/helpers/sex-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sexSymbol = sexSymbol;
  _exports.default = void 0;

  function sexSymbol() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return "fa fa-fw fa-mars text-blue";

        case 2:
          return "fa fa-fw fa-venus text-pink";

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});