define("crm/routes/organizations/organization/purchase-memos/purchase-memo/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("documentLines", this.store.query("purchase-memo-line", {
        purchaseMemo: model.get("id")
      }));
    }
  });

  _exports.default = _default;
});