define("crm/components/procurement/purchase-invoice-header-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * components/procurement/purchase-invoice-header-new
   */
  var _default = Ember.Component.extend({
    financialAccountsUsable: Ember.computed("financialAccounts.@each.financialAccountType", "paymentMethod", function () {
      var self = this;
      var financialAccounts = [];

      if (this.get("model") && this.get("financialAccounts")) {
        if (this.get("financialAccounts.length")) {
          this.get("financialAccounts").filter(function (financialAccount) {
            if (financialAccount.get("financialAccountType") == 1) {
              self.set("selectedFinancialAccount", financialAccount);

              if (financialAccount.get("isManual")) {
                if (financialAccount.get("isOpen")) {
                  financialAccounts.addObject(financialAccount);
                }
              } else {
                financialAccounts.addObject(financialAccount);
              }
            }
          }).objectAt(0);
        }

        if (financialAccounts.get("length") === 1) {
          this.set("model.financialAccount", financialAccounts[0]);
        } else {
          this.set("model.financialAccount", null);
        }
      }

      return financialAccounts;
    }),
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 2).get("length") > 0 ? true : false;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsUsable", function () {
      return this.get("financialAccountsUsable").filterBy("financialAccountType", 1).get("length") > 0 || this.get("paymentMethod") === "DEB" ? true : false;
    }),
    actions: {
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        if (paymentMethod) {
          this.set("model.paymentMethod", paymentMethod);
        } else {
          this.set("model.paymentMethod", null);
        }
      }
    }
  });

  _exports.default = _default;
});