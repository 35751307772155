define("crm/controllers/organizations/organization/internments/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ["startedAfter", "startedBefore"],
    startedAfter: (0, _moment.default)().startOf("day").format("YYYY-MM-DD"),
    startedBefore: (0, _moment.default)().endOf("day").format("YYYY-MM-DD"),
    reset: function reset() {
      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "start",
        "component": "umt-datetime2",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": this.get("intl").t("internments.internment.started"),
        "className": "column-date-time"
      }, {
        "propertyName": "end",
        "component": "umt-datetime2",
        "title": this.get("intl").t("internments.internment.ended"),
        "className": "column-date-time"
      }, {
        "propertyName": "patient.name",
        "title": this.get("intl").t("patients.patient.title"),
        "routeProperty": "patient.id",
        "routeName": "organizations.organization.patients.patient",
        "className": "module-clinical"
      }, {
        "propertyName": "reason",
        "title": this.get("intl").t("internments.internment.reason")
      }, {
        "propertyName": "endReason",
        "title": this.get("intl").t("internments.internment.endReason")
      }, {
        "component": "internment/list-actions",
        "className": "column-actions-3"
      }];
    }),
    actions: {
      openInternmentCreate: function openInternmentCreate() {
        this.send("openModal", {
          entity: "internment",
          action: "create"
        });
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      }
    }
  });

  _exports.default = _default;
});