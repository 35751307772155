define("crm/controllers/organizations/organization/stats/sales-table-days", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    totals: {},
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    filter: {
      invoices: false,
      memos: false
    },
    filterOptions: Ember.computed("intl", function () {
      var result = [];

      for (var key in this.get("filter")) {
        result.push({
          name: this.get("intl").t("filters." + key),
          code: "filter." + key,
          active: this.get("filter")[key]
        });
      }

      return result;
    }),
    // Line chart graphic data
    chartData: Ember.computed('billingByDay.[]', function () {
      return {
        labels: this.get("labels"),
        datasets: [{
          label: "Label",
          data: this.get("billingByDay"),
          fillColor: "#2f4050",
          backgroundColor: "rgba(220,220,220,0.2)",
          pointBackgroundColor: "rgba(220,220,220,1)",
          pointRadius: 2,
          pointHoverRadius: 2,
          pointHoverColor: "rgba(220,220,220,1)",
          pointStyle: 'circle',
          lineTension: 0
        }]
      };
    }),
    labels: Ember.computed(function () {
      var result = [];

      for (var mI = 1; mI < 13; mI++) {
        var m = (0, _moment.default)("2022-" + mI + "-01").format("MMM");
        result.push(m);
      }

      return result;
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "sortPrecedence": 0,
        "title": "Mes"
      }];
    }),
    billingByMonth: Ember.computed("data.[]", function () {
      var result = [];

      for (var yearName in this.get("data")) {
        var yearData = this.get("data")[yearName];

        for (var monthName in yearData) {
          if (monthName !== "amount") {
            var monthData = yearData[monthName];
            var aux = {
              name: monthName
            };

            for (var dayName in monthData) {
              var dayData = monthData[dayName];
              aux[dayName] = dayData.amount;
            }

            result.push(aux);
          }
        }
      }

      return result;
    }),
    billingByDay: Ember.computed("invoices.[]", "memos.[]", function () {
      var _this = this;

      if (Ember.isEmpty(this.get("invoices"))) {
        return {};
      }

      var result = {};
      this.get("invoices").forEach(function (document) {
        var date = (0, _moment.default)(document[0]);
        var year = date.year();
        var month = date.format("MMM");
        var day = date.date();
        /* setup */

        if (!result[year]) {
          result[year] = {};
          _this.get("totals")[year] = {
            amount: 0,
            amountI: 0,
            amountNC: 0,
            year: year,
            months: []
          };

          for (var d = 1; d < 38; d++) {
            switch (d % 7) {
              case 1:
                result[year][d] = {
                  dayOfTheWeek: "seg"
                };
                break;

              case 2:
                result[year][d] = {
                  dayOfTheWeek: "ter"
                };
                break;

              case 3:
                result[year][d] = {
                  dayOfTheWeek: "qua"
                };
                break;

              case 4:
                result[year][d] = {
                  dayOfTheWeek: "qui"
                };
                break;

              case 5:
                result[year][d] = {
                  dayOfTheWeek: "sex"
                };
                break;

              case 6:
                result[year][d] = {
                  dayOfTheWeek: "sab"
                };
                break;

              case 0:
                result[year][d] = {
                  dayOfTheWeek: "dom"
                };
                break;
            }

            for (var mI = 1; mI < 13; mI++) {
              var monthDate = (0, _moment.default)(year + "-" + mI + "-01");
              var mDays = monthDate.daysInMonth();
              var m = monthDate.format("MMM");
              var dayShift = monthDate.isoWeekday();

              if (d < dayShift || d > mDays + dayShift - 1) {
                result[year][d][m] = {};
              } else {
                if ((0, _moment.default)().isSame(year + "-" + mI + "-" + (d - dayShift + 1), "d")) {
                  result[year][d][m] = {
                    amount: 0,
                    amountI: 0,
                    amountNC: 0,
                    day: d - dayShift + 1,
                    isToday: true
                  };
                } else {
                  result[year][d][m] = {
                    amount: 0,
                    amountI: 0,
                    amountNC: 0,
                    day: d - dayShift + 1
                  };
                }
              }

              _this.get("totals")[year]["months"][m] = {
                amount: 0,
                amountI: 0,
                amountNC: 0
              };
            }
          }
        }
        /* Body */


        var shift = (0, _moment.default)(year + "-" + (date.month() + 1) + "-01").isoWeekday() - 1;
        result[year][day + shift][month].amountI += document[2];
        result[year][day + shift][month].amount += document[2];
        _this.get("totals")[year].amount += document[2];
        _this.get("totals")[year].amountI += document[2];
        _this.get("totals")[year]["months"][month].amount += document[2];
        _this.get("totals")[year]["months"][month].amountI += document[2];
      });

      if (!Ember.isEmpty(this.get("memos"))) {
        this.get("memos").forEach(function (document) {
          var date = (0, _moment.default)(document[0]);
          var year = date.year();
          var month = date.format("MMM");
          var day = date.date();
          var shift = (0, _moment.default)(year + "-" + (date.month() + 1) + "-01").isoWeekday() - 1;
          result[year][day + shift][month].amount -= document[2];
          result[year][day + shift][month].amountNC += document[2];
          _this.get("totals")[year].amount -= document[2];
          _this.get("totals")[year].amountNC += document[2];
          _this.get("totals")[year]["months"][month].amount -= document[2];
          _this.get("totals")[year]["months"][month].amountNC += document[2];
        });
      }

      return result;
    }),
    actions: {
      setFilter: function setFilter(filter) {
        this.set(filter, this.get(filter) ? false : true);
      }
    }
  });

  _exports.default = _default;
});