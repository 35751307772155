define("crm/routes/organizations/organization/listings/sales/financial-accounts/financial-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      if (!model.get("isManual")) {
        this.transitionTo("organizations.organization.listings.sales.financial-accounts.financial-account.documents");
      } else {
        this.transitionTo("organizations.organization.listings.sales.financial-accounts.financial-account.financial-account-opens");
      }

      this.set("breadCrumb", {
        finalTitle: model.get("internalDescription")
      });
    }
  });

  _exports.default = _default;
});