define("crm/controllers/organizations/organization/tasks/archive", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    store: Ember.inject.service(),
    queryParams: ["startedAfter", "startedBefore"],
    startedAfter: (0, _moment.default)().format("YYYY-MM-DD"),
    startedBefore: (0, _moment.default)().format("YYYY-MM-DD"),
    canDoTasks: Ember.computed("can", function () {
      return this.get("can").can("do task");
    }),
    columns: Ember.computed("tasksByOrganization", function () {
      return [{
        "propertyName": "status",
        "title": "",
        "component": "show-task-status",
        "className": "column-icon-responsive"
      }, {
        "propertyName": "priority",
        "title": "",
        "component": "show-task-priority",
        "className": "column-icon-responsive"
      }, {
        "propertyName": "dateTime",
        "title": this.get('intl').t("tasks.task.taskDate"),
        "component": "umt-datetime3",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "className": "column-date-time hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("tasks.task.name"),
        "component": "title-models-table-display",
        "className": "text-calendar"
      }, {
        "propertyName": "worker.name",
        "title": this.get('intl').t("workers.worker.title"),
        "className": "hidden-xs"
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("patients.patient.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.patients.patient",
        "routeProperty": "patient.id",
        "className": "hidden-xs"
      }, {
        "component": "show-task-actions",
        "className": "column-actions-5"
      }];
    }),
    filteredModel: Ember.computed("model.@each.isDue", "model.@each.priority", function () {
      if (Ember.isEmpty(this.get("model")) || !this.get("model.length")) {
        return null;
      }

      var result = [];
      this.get("model").forEach(function (x) {
        return result.push(x);
      });
      return result.sort(function (a, b) {
        return a.get("isDue") && !b.get("isDue") ? -1 : !a.get("isDue") && b.get("isDue") ? 1 : b.get("priority") - a.get("priority");
      });
    }),
    actions: {
      search: function search(dates) {
        this.set("startedAfter", dates.nextDateStart);
        this.set("startedBefore", dates.nextDateEnd);
      },
      setCheckBox: function setCheckBox(status) {
        if (status.checked) {
          status.set("checked", false);
        } else {
          status.set("checked", true);
        }
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      openCreate: function openCreate() {
        this.send("openModal", {
          entity: "task",
          action: "create"
        });
      },
      openDone: function openDone(task) {
        this.send("openModal", {
          entity: "task",
          action: "done",
          model: task
        });
      },
      openDelete: function openDelete(task) {
        this.send("openModal", {
          entity: "task",
          action: "delete",
          model: task
        });
      },
      openCommentCreate: function openCommentCreate(task) {
        this.send("openModal", {
          entity: "task",
          action: "commentCreate",
          model: {
            task: task
          }
        });
      },
      updateModel: function updateModel(startedAfter, startedBefore) {
        var self = this;
        self.set("startedAfter", (0, _moment.default)(startedAfter).format('YYYY-MM-DD'));
        self.set("startedBefore", (0, _moment.default)(startedBefore).format('YYYY-MM-DD'));
      }
    }
  });

  _exports.default = _default;
});