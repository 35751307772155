define("crm/components/consultation-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    drugs: Ember.computed("model.id", function () {
      return this.get("model.id") ? this.get("store").query("drug", {
        consultation: this.get("model.id")
      }) : [];
    }),
    actions: {
      openModalMDCDetails: function openModalMDCDetails(exam) {
        this.sendAction('openModalMDCDetails', exam);
      },
      openDrugDetails: function openDrugDetails(drug) {
        this.sendAction('openDrugDetailsModal', drug);
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$('#openAssociatePatientOnAppointment').modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction('openEditPatientModal', model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        Ember.$("#modal-consultation-details").modal("hide");
        this.sendAction('openCreateAppointmentModal', model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction('openUpdateCustomerModal', record);
      }
    }
  });

  _exports.default = _default;
});