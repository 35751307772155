define("crm/components/financial-account-close-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    closeFinancialAccount: {},
    showContent: true,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        var self = this;
        this.get("store").findRecord("financial-account", this.get("model.id"), {
          reload: true
        }).then(function (financialAccount) {
          if (!financialAccount.get("isOpen")) {
            self.set("showContent", false);
          }
        });
      }
    },
    close: function close() {
      Ember.$("#modal-financialAccountClose-new").modal("hide");
    },
    actions: {
      create: function create() {
        var self = this;
        this.get("store").findRecord("financial-account", this.get("model.id"), {
          reload: true
        }).then(function (financialAccount) {
          if (!financialAccount.get("isOpen")) {
            self.get('flashMessages').info(self.get('intl').t('financialAccounts.messages.allreadyClosed'));
          } else {
            self.set("closeFinancialAccount.organization", self.get("organization"));
            self.set("closeFinancialAccount.financialAccount", self.get("model"));
            self.get("store").createRecord("financialAccountClose", self.get("closeFinancialAccount")).save().then(function () {
              self.sendAction("reloadModel");
              self.close();
            });
          }
        });
      },
      reset: function reset() {
        this.set("closeFinancialAccount", {});
        this.set("disabledAction", false);
        this.set("showContent", true);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});