define("crm/components/customer-details-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    canDoCustomer: Ember.computed("can", function () {
      return this.get("can").can("update customer");
    })
  });

  _exports.default = _default;
});