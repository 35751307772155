define("crm/components/suppliers-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoSuppliers: Ember.computed("can", function () {
      //return this.get("can").can("do procurement"); TODOJD
      return true;
    }),
    actions: {
      openSupplierDetails: function openSupplierDetails() {
        this.sendAction('openSupplierDetails', this.get("record"));
      },
      openSupplierEdit: function openSupplierEdit() {
        this.sendAction('openSupplierEdit', this.get("record"));
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});