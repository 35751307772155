define("crm/components/shopping-cart-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedItemType: null,
    control: 0,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("model.shoppingCart.id")) {
        if (this.get("model.shoppingCart.internment.id")) {
          Ember.RSVP.hash({
            cart: this.get("store").findRecord("internment-shopping-cart", this.get("model.shoppingCart.id"), {
              reload: true
            }),
            lines: this.get("store").query("shoppingCartLine", {
              shoppingCart: this.get("model.shoppingCart.id")
            }, {
              reload: true
            }),
            items: this.get("store").query("item", {
              shoppingCart: this.get("model.shoppingCart.id")
            })
          });
        } else {
          Ember.RSVP.hash({
            cart: this.get("store").findRecord("shoppingCart", this.get("model.shoppingCart.id"), {
              reload: true
            }),
            lines: this.get("store").query("shoppingCartLine", {
              shoppingCart: this.get("model.shoppingCart.id")
            }, {
              reload: true
            }),
            items: this.get("store").query("item", {
              shoppingCart: this.get("model.shoppingCart.id")
            })
          });
        }
      }
    },
    reset: function reset() {
      this.set("selectedItemType", null);
      this.set("searchValue", null);
      this.set("model", null);
      this.set("isBlocked", false);
      this.close();
    },
    close: function close() {
      Ember.$("#modal-shopping-cart-edit").modal("hide");
    },
    disabledActionBtn: Ember.computed("shoppingCart.name", "isBlocked", function () {
      return this.get("isBlocked") || !this.get("model.shoppingCart.name");
    }),
    sortedShoppingCartLines: Ember.computed("shoppingCart.lines.@each.lineGroup", "shoppingCart.lines.[]", function () {
      return Ember.isEmpty(this.get("model.shoppingCart.lines")) ? [] : this.get("model.shoppingCart.lines").sortBy("lineGroup").toArray();
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    hideRoundPricesButton: Ember.computed("shoppingCart.internment.id", "shoppingCart.lines.@each.linePrice", "shoppingCart.lines.@each.linePriceOld", "shoppingCart.status", function () {
      var hide = true;

      if (this.get("model.shoppingCart.internment.id") && this.get("model.shoppingCart.status") == 0 && !Ember.isEmpty(this.get("model.shoppingCart.lines"))) {
        this.get("model.shoppingCart.lines").forEach(function (line) {
          if (line.get("linePrice") % 1 != 0) {
            // has lines with decimals
            hide = false;
          }
        });
      }

      return hide;
    }),
    actions: {
      selectItemType: function selectItemType(itemType) {
        this.set("searchValue", null);
        this.set("selectedItemType", itemType);
      },
      backToStart: function backToStart() {
        this.set("selectedItemType", null);
      },
      clearLines: function clearLines() {
        this.get("store").peekRecord("shoppingCart", this.get("model.shoppingCart.id")).destroyRecord();
        this.reset();
      },
      pay: function pay() {
        var queryParams = {
          shoppingCartId: this.get("model.shoppingCart.id")
        };

        if (this.get("model.shoppingCart.id")) {
          if (this.get("customer.id")) {
            queryParams["customerId"] = this.get("customer.id");
          }

          this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
        }
      },
      openDetails: function openDetails(entity) {
        entity.set("isConsultation", true);
        this.sendAction("openModal", {
          entity: "order-line",
          action: "details",
          model: entity
        });
      },
      openDetailsModal: function openDetailsModal(entity, model) {
        if (this.get("model.shoppingCart.lines")) {
          this.sendAction("openModal", {
            entity: entity,
            action: "details",
            model: {
              lines: this.get("model.shoppingCart.lines").filter(function (l) {
                if (l.get("lineGroupTree")) {
                  return Ember.get(l, "lineGroupTree") && (Ember.get(l, "lineGroupTree") + "_").startsWith(Ember.get(model, "lineGroupTree") + "_");
                }

                return false;
              }),
              isConsultation: true
            }
          });
        }
      },
      addLine: function addLine(selectedItem) {
        var self = this;

        if (this.get("model.shoppingCart.id")) {
          this.addLine(selectedItem);
        } else if (this.get("model.consultation.id")) {
          this.get("store").createRecord("shoppingCart", {
            organization: this.get("model.consultation.organization"),
            customer: this.get("model.consultation.owner"),
            patient: this.get("model.consultation.patient"),
            created: this.get("model.consultation.date"),
            consultation: this.get("model.consultation")
          }).save().then(function (savedShoppingCart) {
            self.set("model.shoppingCart", savedShoppingCart);

            if (self.get("model.consultation.appointment.type.id")) {
              self.addLine(self.get("model.consultation.appointment.type"), true);
            }

            self.addLine(selectedItem, true); // true here to workaround locking control
          });
        } else if (this.get("model.appointment.id")) {
          this.get("store").createRecord("shoppingCart", {
            organization: this.get("model.appointment.organization"),
            customer: this.get("model.appointment.customer"),
            patient: this.get("model.appointment.patient"),
            created: this.get("model.appointment.start"),
            appointment: this.get("model.appointment")
          }).save().then(function (savedShoppingCart) {
            self.set("model.appointment.shoppingCart", savedShoppingCart);
            self.get("model.appointment").save();
            self.set("model.shoppingCart", savedShoppingCart);

            if (self.get("model.appointment.type.id")) {
              self.addLine(self.get("model.appointment.type"), true);
            }

            self.addLine(selectedItem, true); // true hwere to workaround locking control
          });
        } else {
          self.get("flashMessages").danger("Unexpected error. Shopping cart error.");
        }
      },
      roundPrices: function roundPrices() {
        if (this.get("model.shoppingCart.lines")) {
          this.get("model.shoppingCart.lines").forEach(function (line) {
            line.set("linePriceOld", line.get("linePrice"));
            line.set("linePrice", Math.ceil(line.get("linePrice")));
            line.save();
          });
        }
      }
    },
    addSimpleLine: function addSimpleLine(item, quantity, discount) {
      var self = this;
      var exists = false;
      var pvp = item.get("saleUnitPrice");

      if (item.get("pvpDiscount")) {
        discount = this.getNewDiscount(discount, item.get("pvpDiscount"));
      }

      if (item.get("variationType") == 1 && this.get("model.shoppingCart.patient.currentWeight.weight")) {
        if (this.get("model.shoppingCart.patient.currentWeight.weight") > item.get("pvp3Variation")) {
          pvp = item.get("saleUnitPrice3");
          discount = this.getNewDiscount(discount, item.get("pvpDiscount3"));
        } else if (this.get("model.shoppingCart.patient.currentWeight.weight") > item.get("pvp2Variation")) {
          pvp = item.get("saleUnitPrice2");
          discount = this.getNewDiscount(discount, item.get("pvpDiscount2"));
        }
      }

      if (self.get("model.shoppingCart.lines")) {
        self.get("model.shoppingCart.lines").forEach(function (line) {
          if (Ember.isEmpty(Ember.get(line, "lineGroup")) && Ember.get(line, "item") && Ember.get(line, "item.id") === item.get("id") && Ember.get(line, "unitPrice") == pvp && (Ember.isEmpty(Ember.get(line, "discount")) || Ember.get(line, "discount") == discount)) {
            exists = true;
            Ember.set(line, "quantity", Ember.get(line, "quantity") + quantity);
            var bruto = self.bruto(pvp, Ember.get(line, "quantity"));
            var valorTotalDesc = self.valorTotalDesc(bruto, discount);
            Ember.set(line, "linePrice", self.brutoDesc(bruto, valorTotalDesc));
            line.save().then(function () {
              if (Ember.$("body").width() < 768) {
                self.get("flashMessages").info(item.get("name") + "      (" + quantity + "x" + ")      " + self.money((self.money(line.get("quantity")) + quantity) * line.get("unitPrice")) + self.get("intl").t("icons.currency"));
              }
            });
          }
        });
      }

      if (!exists) {
        var bruto = self.bruto(pvp, quantity);
        var valorTotalDesc = self.valorTotalDesc(bruto, discount);
        self.get("store").createRecord("shoppingCartLine", {
          item: item,
          name: item.get("name"),
          quantity: quantity,
          discountTotal: valorTotalDesc,
          linePrice: self.brutoDesc(bruto, valorTotalDesc),
          discount: discount,
          unitPrice: pvp,
          itemUnit: item.get("saleItemUnit"),
          vat: item.get("vat"),
          vatAmount: item.get("vat.vat.amount"),
          shoppingCart: self.get("model.shoppingCart")
        }).save().then(function () {
          if (Ember.$("body").width() < 768) {
            self.get("flashMessages").info(item.get("name") + "      (" + (Ember.isEmpty(item.get("quantity")) ? quantity : item.get("quantity")) + "x" + ")     " + item.get("saleUnitPrice") + self.get("intl").t("icons.currency"));
          }

          if (item.get("period") && self.get("model.shoppingCart.patient.id")) {
            self.get("store").query("periodic", {
              patient: self.get("model.shoppingCart.patient.id")
            });
          }
        });
      }

      self.set("isBlocked", false);
    },
    nextLineGroup: function nextLineGroup() {
      this.set("model.shoppingCart.lastLineGroup", 1 + this.get("model.shoppingCart.lastLineGroup"));
      return this.get("model.shoppingCart.lastLineGroup");
    },
    nextLineGroupTree: function nextLineGroupTree(lineGroupTree, treeLevel) {
      var result;
      var lineGroups = lineGroupTree.split("_");

      if (lineGroups.length <= treeLevel) {
        result = lineGroupTree + "_1";
      } else {
        lineGroups[treeLevel] = Number(lineGroups[treeLevel]) + 1;
        result = lineGroups.slice(0, treeLevel + 1).join("_");
      }

      return result;
    },
    getNewDiscountP: function getNewDiscountP(itemItemDiscount, discount) {
      var result;

      if (itemItemDiscount) {
        if (discount) {
          result = (discount + itemItemDiscount * (100 - discount) / 100) / 100;
        } else {
          result = itemItemDiscount / 100;
        }
      } else {
        if (discount) {
          result = discount / 100;
        } else {
          result = 0;
        }
      }

      return result;
    },
    getNewDiscount: function getNewDiscount(itemItemDiscount, discount) {
      var result;

      if (discount) {
        if (itemItemDiscount) {
          result = discount + itemItemDiscount * (100 - discount) / 100;
        } else {
          result = discount;
        }
      } else {
        if (itemItemDiscount) {
          result = itemItemDiscount;
        } else {
          result = 0;
        }
      }

      return result;
    },
    addCompound: function addCompound(savedItem, itemItems, quantity, discount, factor, lineGroup, tree, treeLevel) {
      var self = this;
      var pvp = savedItem.get("pvp") ? Number(savedItem.get("pvp")) * factor : 0;

      if (savedItem.get("variationType") && savedItem.get("variationType") == 1 && this.get("model.shoppingCart.patient") && this.get("model.shoppingCart.patient.currentWeight") && this.get("model.shoppingCart.patient.currentWeight.weight")) {
        if (this.get("model.shoppingCart.patient.currentWeight.weight") > savedItem.get("pvp3Variation")) {
          pvp = savedItem.get("pvp2") ? Number(savedItem.get("pvp2")) * factor : 0;
        } else if (this.get("model.shoppingCart.patient.currentWeight.weight") > savedItem.get("pvp2Variation")) {
          pvp = savedItem.get("pvp3") ? Number(savedItem.get("pvp3")) * factor : 0;
        }
      }
      /*
       * Add Parent
       */


      var parentShoppingCartLine = {
        item: savedItem,
        name: savedItem.get("name"),
        quantity: quantity,
        discountTotal: self.money(pvp * quantity * (discount / 100)),
        linePrice: discount ? self.money(pvp * quantity - pvp * quantity * (discount / 100)) : self.money(pvp * quantity),
        discount: discount,
        unitPrice: pvp,
        vat: savedItem.get("vat"),
        vatAmount: savedItem.get("vat.vat.amount"),
        shoppingCart: self.get("model.shoppingCart"),
        lineGroup: lineGroup,
        lineGroupTree: tree,
        treeLevel: treeLevel,
        groupOdd: lineGroup % 2
      };
      self.get("store").createRecord("shoppingCartLine", parentShoppingCartLine).save();
      var previousTree = tree;
      var newLevel = treeLevel + 1;
      itemItems.forEach(function (itemItem) {
        var newTree = self.nextLineGroupTree(previousTree, newLevel);
        previousTree = newTree;
        Ember.RSVP.hash({
          itemItemFound: self.get("store").findRecord("item-item", itemItem.get("id")),
          itemUnits: self.get("store").query("item-unit", {
            item: itemItem.get("child.id")
          }),
          savedChild: self.get("store").findRecord("item", itemItem.get("child.id")),
          savedChildItemItems: self.get("store").query("item-item", {
            parent: itemItem.get("child.id")
          })
        }).then(function (hashResult) {
          var itemItemFound = hashResult.itemItemFound;
          var savedChild = hashResult.savedChild;
          var savedChildItemItems = hashResult.savedChildItemItems;
          var newQuantity = itemItemFound.get("quantity") * quantity;
          var newDiscountP = self.getNewDiscountP(itemItemFound.get("discount"), discount);
          var newDiscount = self.getNewDiscount(itemItemFound.get("discount"), discount);

          if (!Ember.isEmpty(savedChildItemItems)) {
            var newFactor = factor;

            if (itemItemFound.get("salePrice")) {
              newFactor *= itemItemFound.get("salePrice") / savedChild.get("displayPrice");
            }

            self.addCompound(savedChild, savedChildItemItems, newQuantity, newDiscount, newFactor, lineGroup, newTree, newLevel, itemItemFound.get("displayLinePrice"), itemItemFound);
          } else if (!Ember.isEmpty(Ember.get(itemItemFound, "saleUnitPrice")) || savedChild.get("sellable")) {
            var itemItemPrice = self.money(Number(itemItemFound.get("saleUnitPrice") ? itemItemFound.get("saleUnitPrice") : 0) * factor);
            var raw = self.money(itemItemPrice * newQuantity);
            var discountTotal = self.money(raw * newDiscountP);
            self.get("store").createRecord("shoppingCartLine", {
              item: savedChild,
              name: savedChild.get("name"),
              quantity: newQuantity,
              unitPrice: itemItemPrice,
              discountTotal: discountTotal,
              linePrice: self.money(raw - discountTotal),
              discount: newDiscount,
              lineGroup: lineGroup,
              lineGroupTree: newTree,
              treeLevel: newLevel,
              groupOdd: lineGroup % 2,
              itemUnit: savedChild.get("saleItemUnit"),
              vat: savedChild.get("vat"),
              vatAmount: savedChild.get("vat.vat.amount"),
              lineGroupDiscount: newDiscount,
              shoppingCart: self.get("model.shoppingCart")
            }).save().then(function () {
              if (Ember.$("body").width() < 768) {
                self.get("flashMessages").info(savedChild.get("name") + "      (" + itemItemFound.get("quantity") + "x" + ")      " + self.money(itemItemPrice * (itemItemFound.get("quantity") * quantity)) + self.get("intl").t("icons.currency"));
              }
            });
          }

          self.set("isBlocked", false);
        });
      });
    },
    addLine: function addLine(item, skip) {
      if (this.get("isBlocked") === true && !skip) {
        return;
      }

      var self = this;
      this.set("isBlocked", true);
      Ember.RSVP.hash({
        item: this.get("store").findRecord("item", item.get("id"), {
          reload: true
        }),
        itemUnits: this.get("store").query("item-unit", {
          item: item.get("id")
        }, {
          reload: true
        }),
        itemCompanions: this.get("store").query("item-companion", {
          item: item.get("id")
        }, {
          reload: true
        }),
        itemItems: this.get("store").query("item-item", {
          parent: item.get("id")
        }, {
          reload: true
        })
      }).then(function (hashResult) {
        var savedItem = hashResult.item;
        var itemItems = hashResult.itemItems;
        var itemCompanions = hashResult.itemCompanions;
        var discount = 0;

        if (self.get("model.shoppingCart.customer") && self.get("model.shoppingCart.customer.generalDiscounts")) {
          discount = self.get("model.shoppingCart.customer.generalDiscounts");
        }
        /*
         * Companions
         */


        if (!Ember.isEmpty(itemCompanions)) {
          itemCompanions.forEach(function (itemCompanion) {
            self.get("store").findRecord("item", itemCompanion.get("companion.id")).then(function (savedCompanion) {
              self.get("store").query("item-unit", {
                item: savedCompanion.get("id")
              }).then(function () {
                self.addSimpleLine(savedCompanion, Ember.isEmpty(itemCompanion.get("quantity")) ? 1 : itemCompanion.get("quantity"), discount);
              });
            });
          });
        }

        if (Ember.isEmpty(itemItems) || savedItem.get("isFixedPriceBundle")) {
          self.addSimpleLine(savedItem, 1, discount);
        } else {
          var lg = self.nextLineGroup();
          self.addCompound(savedItem, itemItems, 1, // quantity
          discount, 1, // factor
          lg, // line group
          lg.toString(), // tree
          0 // tree level
          );
        }

        self.set("isBlocked", false);
      });
    },
    bruto: function bruto(unitPrice, quantity) {
      return this.money(unitPrice * quantity);
    },
    valorTotalDesc: function valorTotalDesc(bruto, discount) {
      return this.money(bruto * (Ember.isEmpty(discount) ? 1 : discount / 100));
    },
    brutoDesc: function brutoDesc(bruto, valorTotalDesc) {
      return this.money(bruto - valorTotalDesc);
    }
  });

  _exports.default = _default;
});