define("crm/routes/organizations/organization/schedule/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      startedAfter: {
        refreshModel: true
      },
      startedBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      organization.set("startedAfter", params.startedAfter || (0, _moment.default)().startOf("day").format());
      organization.set("startedBefore", params.startedBefore || (0, _moment.default)().endOf("day").format());
      var createdAfter = (0, _moment.default)(organization.get("startedAfter")).startOf("day").format();
      var createdBefore = (0, _moment.default)(organization.get("startedBefore")).endOf("day").format();

      if ((0, _moment.default)(organization.get("startedBefore")).diff(organization.get("startedAfter"), "days") <= this.get("dateLimit")) {
        return Ember.RSVP.hash({
          appointments: this.store.query("appointment", {
            organization: organizationID,
            startedAfter: createdAfter,
            startedBefore: createdBefore
          }),
          customers: this.store.query("customer", {
            organization: organizationID,
            appointmentStartedAfter: createdAfter,
            appointmentStartedBefore: createdBefore
          }, {
            reload: true
          }),
          patients: this.store.query("patient", {
            organization: organizationID,
            appointmentStartedAfter: createdAfter,
            appointmentStartedBefore: createdBefore
          }, {
            reload: true
          })
        }).then(function (hashResult) {
          return hashResult.appointments;
        });
      } else {
        return null;
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('organization', this.modelFor("organizations.organization"));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
        controller.set("startedBefore", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});