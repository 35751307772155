define("crm/components/customer-finances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    balance: Ember.computed("model.@each.open", function () {
      var total = 0;
      this.get("model").forEach(function (openItem) {
        if (openItem.get("open")) {
          total += openItem.get("open");
        }
      });
      return total.toFixed(2);
    }),
    color: Ember.computed("balance", function () {
      if (this.get("balance")) {
        if (this.get("balance") > 0) {
          return "text-danger";
        } else {
          return "text-success";
        }
      }
    }),
    totalAccumulated: Ember.computed("invoices.@each.payableAmmount", function () {
      var total = 0;
      this.get("invoices").forEach(function (invoice) {
        if (invoice.get("status") !== 8) {
          if (invoice.get("payableAmount")) {
            total += invoice.get("payableAmount");
          }
        }
      });
      return total.toFixed(2);
    }),
    filteredShopping: Ember.computed("model.@each.created", function () {
      return this.get("model").filterBy("dueDate");
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "propertyName": "dueDate",
        "component": "umt-date",
        "title": this.get('intl').t("enums.chronos.dateUpper")
      }, {
        "propertyName": "sourceDoc",
        "title": this.get('intl').t("sales.document")
      }, {
        "propertyName": "open",
        "component": "show-customer-finances-money",
        "title": this.get('intl').t("sales.value")
      }];
    }),
    actions: {
      showPdf: function showPdf(url) {
        var model = {
          url: url
        };
        this.sendAction("openModal", {
          entity: "pdf-viewer",
          action: "view-pdf",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});