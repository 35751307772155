define("crm/abilities/invite", ["exports", "crm/abilities/manager"], function (_exports, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manager.default.extend();

  _exports.default = _default;
});