define("crm/controllers/organizations/organization/configurations/email", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    es: Ember.inject.service(),
    isGoogle: Ember.computed("model.smtpUser", function () {
      return this.get("model.smtpUser") && this.get("model.smtpUser").endsWith("gmail.com");
    }),
    doSave: function doSave() {
      if (this.get("model.smtpUser")) {
        this.set("model.replyToEmail", this.get("model.smtpUser"));
      }

      if (this.get("isGoogle")) {
        this.set("model.smtpAddr", "smtp.gmail.com");
      }

      this.get("model").save();
    },
    actions: {
      save: function save() {
        this.doSave();
      },
      test: function test() {
        var self = this;
        Ember.$.ajax({
          type: "POST",
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/organizationCommunicationEmails/" + this.get("model.id") + "/test/" + this.get("testAddr"),
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          accept: "application/json",
          contentType: "application/json; charset=utf-8",
          success: function success() {
            self.get("flashMessages").success("OK!");
          },
          error: function error(e) {
            self.get("es").handle(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});