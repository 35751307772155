define("crm/components/saft-new", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    control: 0,
    startDate: Ember.observer('model.documentDate', function () {
      this.set('documentDate', (0, _moment.default)().format('YYYY-MM-DD'));
    }),
    reset: function reset() {
      Ember.$("#modal-saft-new").modal("hide");
      this.set("disabledActionSaftNew", false);
      this.set("model", {});
    },
    actions: {
      create: function create() {
        var self = this;
        var id;
        var modelName = "legal-declaration";
        this.set("disabledActionSaftNew", true);
        this.set("model.documentDate", (0, _moment.default)(this.get("documentDate")).format("YYYY-MM-DD"));
        this.get("store").createRecord("legal-declaration", this.get("model")).save().then(function (savedDeclaration) {
          id = savedDeclaration.get("id");
          Ember.$.ajax({
            method: "GET",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/legalDeclarations/" + savedDeclaration.get("id") + "/xml",
            xhrFields: {
              responseType: "blob"
            },
            success: function success(blob) {
              self.get("store").findRecord(modelName, id).then(function (savedLegalDeclaration) {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = savedLegalDeclaration.get("name") + ".xml";
                link.click();
              });
            }
          });
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledActionSaftNew", false);
        });
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});