define("crm/routes/organizations/organization/manufacturers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      return this.store.query("manufacturer", {
        organization: organizationID
      });
    }
  });

  _exports.default = _default;
});