define("crm/components/laboratory-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    newLaboratory: {},
    disabledAction: false,
    init: function init() {
      this._super();

      this.reset();
    },
    reset: function reset() {
      this.set('disabledAction', false);
      this.set('newLaboratory', {});
      this.close();
    },
    close: function close() {
      Ember.$(".modal").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.reset();
      },
      create: function create() {
        this.set('disabledAction', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newLaboratory.organization', this.get('organization'));
        }

        var newLaboratory = this.get('store').createRecord('laboratory', this.get('newLaboratory'));
        newLaboratory.save().then(function () {
          self.reset();
        }).catch(function () {
          self.set('disableCreateBtn', false);
        });
      }
    }
  });

  _exports.default = _default;
});