define("crm/components/appointment-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //tagName: "appointment-card",
    intl: Ember.inject.service(),
    classNames: ["appointment-card-component"],
    eventIO: null,
    clock: null,
    firstConsultation: Ember.computed("appointment", function () {
      if (this.get("appointment.type.type.id") == 21) {
        return true;
      } else {
        return false;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.refresh();
    },
    timeLabel: Ember.computed("intl", "clock", function () {
      var status = this.get("appointment.status");
      var currentTime;
      var labelIcon;
      var timeLabel = {};
      var waitingTime;

      switch (status) {
        case 2:
          labelIcon = this.get("intl").t("icons.waitingRoom");
          currentTime = this.get("appointment.arrivalTime");
          break;

        case 3:
          labelIcon = this.get("intl").t("icons.waitingRoom");
          currentTime = this.get("appointment.arrivalTime");
          break;

        case 4:
          labelIcon = this.get("intl").t("icons.visit");
          currentTime = this.get("appointment.timeConsultation");
          break;

        case 5:
          labelIcon = this.get("intl").t("icons.checkout");
          currentTime = this.get("appointment.endConsultation");
          break;
      }

      if (currentTime || labelIcon) {
        var duration = (0, _moment.default)().diff(currentTime, "minutes");

        if (duration > 30) {
          waitingTime = 100;
        } else if (duration > 15) {
          waitingTime = 100 * duration / 30;
        } else {
          waitingTime = 100 * duration / 15;
        }

        timeLabel.color = this.chromosFromNowColor(currentTime);
        timeLabel.text = (0, _moment.default)(currentTime).fromNow();
        timeLabel.icon = labelIcon;
        timeLabel.waitingTime = waitingTime;
      }

      return timeLabel;
    }),
    refresh: function refresh() {
      var self = this;
      this.set("clock", (0, _moment.default)());
      Ember.run.later(function () {
        if (self && !self.isDestroyed) self.refresh();
      }, 1000);
    },
    chromosFromNowColor: function chromosFromNowColor(refDate) {
      var diff = (0, _moment.default)().diff(refDate); // var jumkData = params[1]; // refresh trigger

      var step1 = 1 * 60 * 1000;
      var step2 = 15 * 60 * 1000;
      var step3 = 30 * 60 * 1000;
      var result = "danger";

      if (diff < step1) {
        result = "success";
      } else if (diff < step2) {
        result = "default";
      } else if (diff < step3) {
        result = "warning";
      }

      return result;
    },
    click: function click() {
      var eventIO = this.get("eventIO"); //if this is the first click , schedule it for later

      if (eventIO === null) {
        eventIO = Ember.run.later(this, function () {
          //do single click stuff
          var eventIO = this.get("eventIO");
          this.sendAction("clickAction", this.get("appointment")); ///clear additional events

          if (eventIO != null) {
            Ember.run.cancel(eventIO);
            this.set("eventIO", null);
          }
        }, 600); //register event to the component

        this.set("eventIO", eventIO);
      }
    },
    doubleClick: function doubleClick() {
      var eventIO = this.get("eventIO"); //check if there is any event for single click, disable it

      if (eventIO != null) {
        Ember.run.cancel(eventIO);
        this.set("eventIO", null);
      } // do the stuff with double click


      this.sendAction("doubleClickAction", this.get("appointment"));
    }
  });

  _exports.default = _default;
});