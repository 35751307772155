define("crm/routes/organizations/organization/financial-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var self = this;
      var organizationID = this.modelFor("organizations.organization").get("id");
      return this.get("store").query("financialAccount", {
        organization: organizationID
      }).then(function (accounts) {
        if (Ember.isEmpty(accounts)) {
          return {};
        } else if (accounts.get("length") === 1) {
          self.transitionTo("organizations.organization.financialAccounts.financialAccount", accounts.get("firstObject.id"));
        } else {
          var open;
          accounts.forEach(function (account) {
            if (account.get("isManual") && account.get("isOpen") && account.get("updater.id") === self.get("currentUser.id")) {
              open = account;
            }
          });

          if (open) {
            self.transitionTo("organizations.organization.financialAccounts.financialAccount", open.get("id"));
          } else {
            return accounts.sortBy("name");
          }
        }
      });
    }
  });

  _exports.default = _default;
});