define("crm/controllers/organizations/organization/purchase-requests/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ["purchaseRequest"],
    updateLines: function updateLines(savedPurchaseRequest) {
      var self = this;
      this.get("model.lines").forEach(function (line) {
        if (Ember.get(line, "id")) {
          if (line.get("warehouse.id") !== self.get("model.warehouse.id")) {
            line.set("warehouse", self.get("model.warehouse"));
          }

          if (line.get("hasDirtyAttributes")) {
            line.save().then(function () {
              self.get("model.lines").removeObject(line);
            }, function (error) {
              self.set("creating", false);
              self.get("es").errorReturned(error);
            });
          }
        } else {
          line.purchaseRequest = savedPurchaseRequest; // Because: self.get("model") has no id yet

          line.warehouse = self.get("model.warehouse");
          self.get("store").createRecord("purchase-request-line", line).save().then(function () {
            self.get("model.lines").removeObject(line);
          }, function (error) {
            self.set("creating", false);
            self.get("es").errorReturned(error);
          });
        }
      });
    },
    reset: function reset() {
      this.set("purchaseRequest", null);
    },

    /*
        USED TO LIST ALL ITEMS UNDER MIN STOCK
        supplierItems: computed("model.organization.company.id", "model.supplier.id", function() {
          //      if(this.get("model.organization.company.id") && this.get("model.supplier.id")) {
          //         return this.get("store").query("item", { company: this.get("model.organization.company.id"), supplier: this.get("model.supplier.id") });
          //      } else {
          return [];
          //      }
       }),
        items: computed("model.shortageItems.[]", "supplierItems.@each.name", "model.warehouse.name", function() {
          var result = [];
          var self = this;
           if(this.get("model.warehouse.name") && this.get("supplierItems") && this.get("model.shortageItems")) {
             this.get("supplierItems").forEach(i => {
                self.get("model.shortageItems").forEach(s => {
                   if(s.Item == i.get("id") && s.Warehouse.toUpperCase() === self.get("model.warehouse.name").toUpperCase()) {
                      result.push({
                         name: i.get("name"),
                         type: i.get("type"),
                         baseUnit: { name: s.Stock + " (" + s.MinStock + ")" },
                         item: i,
                         quantity: s.MinStock - s.Stock
                      });
                   }
                });
             });
          }
           return result;
       }),
    */
    cannotIssue: Ember.computed("cannotSave", "model.lines.@each.quantity", function () {
      if (this.get("cannotSave")) {
        return this.get("cannotSave");
      } else {
        var error;
        this.get("model.lines").forEach(function (l) {
          if (!Ember.get(l, "quantity")) {
            error = Ember.get(l, "item.name");
          }
        });

        if (error) {
          return error;
        }

        return null;
      }
    }),
    cannotSave: Ember.computed("creating", "model.lines.[]", "model.name", "model.supplier", "model.warehouse", function () {
      if (this.get("creating")) {
        return this.get("intl").t("crud.save");
      } else if (!this.get("model.warehouse")) {
        return this.get("intl").t("warehouses.warehouse.title");
      } else if (!this.get("model.supplier")) {
        return this.get("intl").t("suppliers.supplier.title");
      } else if (!this.get("model.lines.length")) {
        return this.get("intl").t("purchaseInvoices.invalid.noLines");
      } else {
        return null;
      }
    }),
    linesPerID: Ember.computed("model.lines.[]", function () {
      if (this.get("model.lines")) {
        return this.get("model.lines").mapBy("item.id");
      } else {
        return [];
      }
    }),
    payableAmount: Ember.computed("model.lines.[]", "model.lines.@each.quantity", function () {
      var result = 0;

      if (this.get("model.lines")) {
        this.get("model.lines").forEach(function (line) {
          if (Ember.get(line, "lastPrice") && Ember.get(line, "quantity")) {
            result += Ember.get(line, "quantity") * Ember.get(line, "lastPrice");
          }
        });
      }

      return result;
    }),
    actions: {
      duplicatePurchaseRequest: function duplicatePurchaseRequest(purchaseRequest) {
        var self = this;
        Ember.RSVP.hash({
          lines: this.store.query("purchase-request-line", {
            purchaseRequest: purchaseRequest.get("id")
          }),
          items: this.store.query("item", {
            purchaseRequest: purchaseRequest.get("id")
          })
        }).then(function (res) {
          var lines = res.lines;

          if (!Ember.isEmpty(lines)) {
            lines.sortBy("name").forEach(function (purchaseRequestLine) {
              self.send("addLine", purchaseRequestLine.get("item"), purchaseRequestLine.get("quantity"));
            });
          }
        }, function (reason) {
          return self.get("es").handle(reason);
        });
      },
      addLine: function addLine(item, quantity, line) {
        if (this.get("linesPerID").includes(item.get("id"))) {
          return;
        }

        var self = this;
        this.get("store").query("purchase-invoice-line", {
          item: item.get("id"),
          top: 1
        }).then(function (pils) {
          var newLine;

          if (line) {
            newLine = line;
          } else {
            newLine = {
              name: item.get("name"),
              item: item,
              quantity: item.get("maxStock") ? item.get("maxStock") - item.get("minStock") + quantity : quantity
            };
          }

          if (!Ember.isEmpty(pils)) {
            Ember.set(newLine, "lastPrice", pils.get("firstObject.unitPrice"));
          }

          if (!self.get("model.lines")) {
            self.set("model.lines", []);
          }

          self.get("model.lines").addObject(newLine);
        });
      },
      deleteLine: function deleteLine(line) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("model.lines").removeObject(line);

          if (Ember.get(line, "id")) {
            line.destroyRecord();
          }
        }
      },
      save: function save() {
        var self = this;
        this.set("creating", true);

        if (!this.get("model.status")) {
          this.set("model.status", 0);
        }

        this.set("model.payableAmount", self.get("payableAmount"));
        this.get("store").createRecord("purchase-request", this.get("model")).save().then(function (savedPurchaseRequest) {
          self.updateLines(savedPurchaseRequest);
          self.set("creating", false);
          self.transitionToRoute("organizations.organization.purchase-requests.purchase-request", savedPurchaseRequest.get("id"));
        }, function (result) {
          self.set("creating", false);
          self.get("es").errorReturned(result);
        });
      },
      update: function update() {
        var self = this;
        this.set("creating", true);
        this.set("model.payableAmount", self.get("payableAmount"));

        if (this.get("model.hasDirtyAttributes")) {
          this.get("model").save().then(function () {
            self.updateLines(self.get("model"));
            self.set("creating", false);
            self.transitionToRoute("organizations.organization.purchase-requests.purchase-request", self.get("model.id"));
          }, function (error) {
            self.set("creating", false);
            self.get("es").errorReturned(error);
          });
        } else {
          self.updateLines(self.get("model"));
          self.set("creating", false);
          self.transitionToRoute("organizations.organization.purchase-requests.purchase-request", self.get("model.id"));
        }
      },
      issue: function issue() {
        this.set("model.date", (0, _moment.default)());
        this.set("model.status", 3);
        this.get("model.id") ? this.send("update") : this.send("save");
      }
    }
  });

  _exports.default = _default;
});