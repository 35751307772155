define("crm/components/weight-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    newWeight: {},
    weightedSeed: (0, _moment.default)().format('YYYY-MM-DD HH:mm'),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.set('weightedSeed', (0, _moment.default)());
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      createWeight: function createWeight() {
        this.set('disabledActionWeightNew', true);
        var self = this;

        if (Ember.isEmpty(this.get("newWeight.weighted"))) {
          this.set("newWeight.weighted", this.get("weightedSeed"));
        }

        this.set("newWeight.patient", this.get("model"));
        this.get('store').createRecord('weight', this.get("newWeight")).save().then(function () {
          self.get("store").findRecord("patient", self.get("model.id"));
          self.close();
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set('disabledActionWeightNew', false);
        });
      },
      reset: function reset() {
        this.set("newWeight", {});
        this.set("disabledActionWeightNew", false);
        this.set('weightedSeed', (0, _moment.default)());
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});