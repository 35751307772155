define("crm/components/shift-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    saving: false,
    visitTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return type.get('isVisit') && Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    disabledAction: Ember.computed("saving", "model.startDate", "model.endDate", function () {
      return this.get("saving") || !this.get("model.startDate") || !this.get("model.endDate");
    }),
    reset: function reset() {
      Ember.$("#modal-shift-new").modal("hide");
      this.set("saving", false);
      this.set("model", {});
      this.set("selectedPeriodType", null);
    },
    actions: {
      createShift: function createShift() {
        /* Set name */
        var s = (0, _moment.default)(this.get("model.startDate"));
        var name = this.get("model.worker.name");

        if (this.get("model.startDate")) {
          name += " - " + s.format("YYYY-MM-DD HH:mm");
        }

        if (this.get("model.endDate")) {
          name += " | " + (0, _moment.default)(this.get("model.endDate")).format("YYYY-MM-DD HH:mm");
        }

        this.set("model.name", name);
        this.set("saving", true);
        var self = this; // period

        if (this.get("selectedPeriodType") && this.get("selectedPeriodType.id")) {
          this.set("model.periodType", this.get("selectedPeriodType.id"));
        }

        if (this.get("model.periodUntil")) {
          this.set("model.periodUntil", (0, _moment.default)(this.get("model.periodUntil")).endOf("day").format());
        }

        this.get("store").createRecord("shift", this.get("model")).save().then(function () {
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function (result) {
          self.get("es").handle(result);
          self.set("saving", false);
        }).finally(function () {
          self.set("saving", false);
        });
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});