define("crm/models/exam-task-type", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    code: _emberData.default.attr("string"),
    subTaskItemTypes: _emberData.default.hasMany("subTaskItemType", {
      inverse: "examTaskType"
    }),
    exams: _emberData.default.hasMany("exam", {
      inverse: "examTaskType"
    })
  });

  _exports.default = _default;
});