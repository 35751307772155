define("crm/controllers/organizations/organization/listings/procurement/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: [{
      "title": "Data",
      "propertyName": "invoiceDate",
      "component": "umt-date",
      "className": "column-date",
      "sortDirection": "desc",
      "sortPrecedence": 0
    }, {
      "title": "Nome",
      "propertyName": "supplierName",
      "className": "column-name",
      "routeName": "organizations.organization.suppliers.supplier",
      "routeProperty": "supplierID"
    }, {
      "title": "NIF",
      "propertyName": "vat",
      "className": "column-vat"
    }, {
      "title": "Documento",
      "propertyName": "purchaseInvoiceName",
      "className": "column-contact"
    }, {
      "title": "Total",
      "propertyName": "payableAmount",
      "component": "umt-price-display",
      "className": "column-price-large"
    }, {
      "title": "Pendente",
      "propertyName": "openAmount",
      "className": "column-price-large"
    }, {
      "title": "Prazo",
      "propertyName": "dueDate",
      "component": "umt-date",
      "className": "column-date"
    }]
  });

  _exports.default = _default;
});