define("crm/routes/organizations/organization/listings/crm/customers/all", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      return Ember.RSVP.hash({
        customers: Ember.$.get({
          url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/customers/info" + "?company=" + organization.get("company.id")),
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return r.customers.map(function (l) {
          return l ? {
            code: l[0],
            name: l[1],
            nif: l[2]
          } : null;
        });
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("listings.all")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("columns", [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer"
      }, {
        "propertyName": "nif"
      }]);
    }
  });

  _exports.default = _default;
});