define("crm/components/show-bundle-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    fixedPrice: Ember.computed('record.retailPrice', function () {
      if (this.get('record.retailPrice')) {
        return Number(this.get('record.retailPrice')).toFixed(2);
      }
    })
  });

  _exports.default = _default;
});