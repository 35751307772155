define("crm/components/internment/dosage-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    dosageCalculator: [],

    /*
     * Computed properties
     */
    isDirty: Ember.computed("dosageCalculator.[]", function () {
      return true;
      /* TODO  button cancel*/
    }),
    CalcIsLiquid: Ember.computed("CalcType", function () {
      return Number(this.get("CalcType")) === 1;
    }),
    CalcIsSolid: Ember.computed("CalcType", function () {
      return Number(this.get("CalcType")) === 2;
    }),
    CalcDosageTotal: Ember.computed("CalcDosage", function () {
      return this.get("CalcDosage") ? (this.get("CalcDosage") * this.get("model.patient.currentWeight.weight")).toFixed(3) : null;
    }),
    totalValue: Ember.observer("firstValue", "secondValue", function () {
      var CalcVolumeTotal = 0;
      this.set("CalcConcentration", null);
      this.set("CalcDosage", null);

      if (this.get("firstValue")) {
        CalcVolumeTotal = CalcVolumeTotal + eval(this.get("firstValue"));
      }

      if (this.get("secondValue")) {
        CalcVolumeTotal = CalcVolumeTotal + eval(this.get("secondValue"));
      }

      this.set("CalcVolumeTotal", CalcVolumeTotal.toFixed(2));
    }),

    /*
     * Actions
     */
    actions: {
      setCalcUnitSelectAnswer: function setCalcUnitSelectAnswer(value) {
        this.set("CalcUnit", Number(value));
      },
      setCalcTypeSelectAnswer: function setCalcTypeSelectAnswer(value) {
        this.set("CalcType", Number(value));
      },
      CalcConcentrationChanged: function CalcConcentrationChanged(event) {
        if (Ember.isEmpty(event.target.value)) {
          this.set("CalcVolumeKg", null);
          this.set("CalcVolumeTotal", null);
          this.set("CalcConcentration", null);
        } else {
          this.set("CalcConcentration", Number(event.target.value));

          if (this.get("CalcDosage")) {
            this.set("CalcVolumeKg", Number(this.get("CalcDosage") / Number(event.target.value)).toFixed(3));
            this.set("CalcVolumeTotal", (Number(this.get("CalcDosage") / Number(event.target.value)) * this.get("model.patient.currentWeight.weight")).toFixed(3));
          }
        }
      },
      CalcDosageChanged: function CalcDosageChanged(event) {
        if (Ember.isEmpty(event.target.value)) {
          this.set("CalcVolumeKg", null);
          this.set("CalcVolumeTotal", null);
          this.set("CalcDosage", null);
        } else {
          this.set("CalcDosage", Number(event.target.value));

          if (this.get("CalcConcentration")) {
            this.set("CalcVolumeKg", (Number(event.target.value) / Number(this.get("CalcConcentration"))).toFixed(3));
            this.set("CalcVolumeTotal", (Number(event.target.value) / Number(this.get("CalcConcentration")) * this.get("model.patient.currentWeight.weight")).toFixed(3));
          }
        }
      },
      CalcVolumeKgChanged: function CalcVolumeKgChanged(event) {
        if (Ember.isEmpty(event.target.value)) {
          this.set("CalcDosage", null);
          this.set("CalcVolumeTotal", null);
          this.set("CalcVolumeKg", null);
        } else {
          this.set("CalcVolumeKg", Number(event.target.value));

          if (this.get("CalcConcentration")) {
            this.set("CalcDosage", (Number(event.target.value) * Number(this.get("CalcConcentration"))).toFixed(3));
            this.set("CalcVolumeTotal", (Number(event.target.value) * this.get("model.patient.currentWeight.weight")).toFixed(3));
          }
        }
      },
      setCalculatorValues: function setCalculatorValues() {
        if (this.get("CalcIsLiquid")) {
          this.set("model.todo.unit", 5);
        }

        if (this.get("CalcIsSolid")) {
          this.set("model.todo.unit", 7);
        }

        this.set("model.todo.volumeTotal", Number(this.get("CalcVolumeTotal")));
        this.set("volumeTotalInput", Number(this.get("CalcVolumeTotal")));
        this.send("closeCalculatorDetails");
      },
      setPillAmountSelectAnswer: function setPillAmountSelectAnswer(value) {
        this.set("pillAmountAux", this.get("pillAmountAux") === value ? null : value);
        this.set("CalcVolumeTotal", this.get("pillAmountAux") !== value ? null : value);
      },
      closeCalculatorDetails: function closeCalculatorDetails() {
        this.set("shouldHideCalculator", true);
        this.resetCalculator();
        Ember.$("#modal-calculator-details").modal("hide");
      },
      hideCalculator: function hideCalculator() {
        if (Ember.isEmpty(this.get("model.actions"))) {
          this.resetCalculator();
          this.send("closeCalculatorDetails");
        }
      },
      canceled: function canceled() {
        this.set("shouldHideCalculator", true);
        this.resetCalculator();
        Ember.$("#modal-calculator-details").modal("hide");
      }
    },

    /*
     * Helpters
     */
    resetCalculator: function resetCalculator() {
      this.set("CalcType", null);
      this.set("CalcUnit", undefined);
      this.set("CalcVolumeTotal", undefined);
      this.set("CalcConcentration", undefined);
      this.set("CalcDosage", undefined);
      this.set("CalcVolumeKg", undefined);
      this.set("firstValue", undefined);
      this.set("secondValue", undefined);
    },

    /*
     * Helpters for enums
     */
    types: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("tasks.create.type.injectable")
      }, {
        id: 2,
        name: this.get("intl").t("tasks.create.type.pill")
      }];
    }),
    fractions: [{
      id: 1,
      name: "0"
    }, {
      id: 2,
      name: "1/4"
    }, {
      id: 3,
      name: "1/2"
    }, {
      id: 4,
      name: "3/4"
    }]
  });

  _exports.default = _default;
});