define("crm/routes/organizations/organization/internments/internment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed("controller.model.patient.name", function () {
      if (this.get("controller.model.patient.name")) {
        return {
          finalTitle: this.get("controller.model.patient.name")
        };
      } else {
        return {
          finalTitle: this.get("intl").t("internments.internment.title")
        };
      }
    }),
    model: function model(params) {
      var _this = this;

      return Ember.RSVP.hash({
        internment: this.get("store").findRecord("internment", params.internment_id),
        actions: this.get("store").query("action", {
          internment: params.internment_id
        }),
        drugs: this.get("store").query("todo-drug", {
          internment: params.internment_id
        }),
        exams: this.get("store").query("todo-exam", {
          internment: params.internment_id
        }),
        serums: this.get("store").query("todo-serum", {
          internment: params.internment_id
        }),
        monitorings: this.get("store").query("todo-monitoring", {
          internment: params.internment_id
        }),
        chores: this.get("store").query("todo-chore", {
          internment: params.internment_id
        }),
        shoppingCarts: this.get("store").query("internment-shopping-cart", {
          internment: params.internment_id
        })
      }).then(function (hash) {
        if (hash.internment.get('internmentStatus.chat.id')) {
          _this.get('store').query("chat-message", {
            chat: hash.internment.get("internmentStatus.chat.id")
          });
        }

        return hash.internment;
      });
    }
  });

  _exports.default = _default;
});