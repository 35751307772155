define("crm/models/organization-avatar", ["exports", "ember-data", "crm/models/organization"], function (_exports, _emberData, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organization.default.extend({
    base64: _emberData.default.attr("string"),
    height: _emberData.default.attr("number"),
    width: _emberData.default.attr("number"),
    file: _emberData.default.attr("string")
  });

  _exports.default = _default;
});