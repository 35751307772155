define("crm/components/periodics-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    newPeriodic: {},
    canDoPeriodic: Ember.computed("can", function () {
      return this.get("can").can("do crm");
    }),
    didInsertElement: function didInsertElement() {
      this.$('tr').each(function () {
        var x = Ember.$(this).find(".table-actions");

        if (x.hasClass("periodic-today")) {
          Ember.$(this).addClass("alert alert-success");
        } else if (x.hasClass("periodic-late")) {
          Ember.$(this).addClass("alert alert-danger");
        }
      });
    },
    actions: {
      callModal: function callModal(arg) {
        this.callModal(arg);
      },
      gotoAppointmentNew: function gotoAppointmentNew(model) {
        this.gotoAppointmentNew(model);
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        this.doNextPeriodic(periodic);
      }
    },
    columns: Ember.computed('intl', function () {
      var listCollumns;

      if (this.get("hideType")) {
        listCollumns = [];
      } else {
        if (this.get("canDoPeriodic")) {
          listCollumns = [{
            "propertyName": "type.name",
            "title": this.get('intl').t("periodics.periodic.type"),
            "component": "title-models-table-display",
            "path": "organizations.organization.items.item",
            "routeProperty": "type.id",
            "className": "text-strong"
          }];
        } else {
          listCollumns = [{
            "propertyName": "type.name",
            "title": this.get('intl').t("periodics.periodic.type"),
            "component": "title-models-table-display",
            "className": "text-strong"
          }];
        }
      }

      if (!this.get("hidePatient")) {
        listCollumns.splice(0, 0, {
          "propertyName": "patient.name",
          "title": this.get('intl').t("patients.patient.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.patients.patient",
          "routeProperty": "patient.id",
          "className": "module-crm"
        });
      }

      listCollumns.splice(0, 0, {
        "propertyName": "nextPeriodic",
        "component": "umt-date",
        "title": this.get('intl').t("periodics.periodic.nextPeriodic"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date next-periodic-date"
      });

      if (!this.get("hideDate")) {
        listCollumns.splice(0, 0, {
          "propertyName": "intakeDate",
          "component": "umt-date",
          "title": this.get('intl').t("periodics.periodic.intakeDate"),
          "className": "column-date"
        });
      }

      if (this.get("showOwner")) {
        listCollumns.push({
          "propertyName": "patient.owner.name",
          "title": this.get('intl').t("customers.customer.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.customers.customer",
          "routeProperty": "patient.owner.id",
          "className": "module-crm"
        });
      }

      if (this.get("showContact")) {
        listCollumns.push({
          "propertyName": "patient.owner.phoneContact.contact",
          "title": this.get('intl').t("customers.customer.contacts.contact"),
          "className": "column-contact"
        });
      }

      if (this.get("showSMS")) {
        listCollumns.push({
          "propertyName": "smsSent",
          "component": "periodic-status",
          "title": this.get('intl').t("sms.sms.title"),
          "className": "column-status-icon"
        });
      }

      if (!this.get("hideActions")) {
        listCollumns.push({
          "component": "periodic-actions",
          "className": "column-actions-4"
        });
      }

      return listCollumns;
    })
  });

  _exports.default = _default;
});