define("crm/controllers/organizations/organization/internments/overview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    step: 1,
    hourWindow: 3,
    clock: (0, _moment.default)(),
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.$("body").width() >= 1600) {
        this.set("hourWindow", 12);
      } else if (Ember.$("body").width() >= 1200) {
        this.set("hourWindow", 6);
      } else if (Ember.$("body").width() >= 768) {
        this.set("hourWindow", 4);
      }

      this.set("chartDateInit", (0, _moment.default)().startOf("hour").subtract(Math.trunc(this.get("hourWindow") / 2), 'hours'));
    },
    filteredModel: Ember.computed("model.@each.typeName", "model.@each.patientName", function () {
      var result = {};

      if (!Ember.isEmpty(this.get("model"))) {
        var filter = "typeName";
        this.get("model").sortBy("patientName").sortBy("typeName").forEach(function (i) {
          if (!result[i.get(filter)]) {
            result[i.get(filter)] = Ember.A();
          }

          result[i.get(filter)].addObject(i);
        });
      }

      return result;
    }),
    windowSize: Ember.computed('hourWindow', function () {
      return "window-" + this.get("hourWindow");
    }),
    isCurrentDay: Ember.computed('chartDateInit', "hourWindow", "step", function () {
      return (0, _moment.default)().diff((0, _moment.default)(this.get("chartDateInit")).add(Math.trunc(this.get("hourWindow") / 2), 'hours'), "hours") === 0;
    }),
    windowOptions: Ember.computed(function () {
      var result = [];

      for (var i = 1; i <= 12; i++) {
        result.push(i);
      }

      return result;
    }),

    /*
        updateStart: observer("model.clock", function() {
            if(this.get("chartDateInit").diff(moment().startOf("hour").subtract(Math.trunc(this.get("hourWindow") / 2), "h")) < 2) {
                this.set("chartDateInit", moment().startOf("hour").subtract(Math.trunc(this.get("hourWindow") / 2), "h"));
            }
        }),
    */
    chartHours: Ember.computed('chartDateInit', "hourWindow", "step", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        var newHour = (0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), 'hours');
        result.addObject(newHour);
      }

      return result;
    }),
    openWithTrueTask: function openWithTrueTask(thisDate, task) {
      if (thisDate.isSameOrAfter(task.get('started'), "h")) {
        this.set('actionModalModel', task);
        Ember.$('#modal-action-edit').modal();
      } else {
        if (task.get('prevTodo.started')) {
          this.openWithTrueTask(thisDate, this.get('store').peekRecord(task.get('constructor.modelName'), task.get('prevTodo.id')));
        } else {
          this.set('actionModalModel', task);
          Ember.$('#modal-action-edit').modal();
        }
      }
    },
    actions: {
      setHourWindow: function setHourWindow(w) {
        this.set("chartDateInit", (0, _moment.default)().startOf("hour").subtract(Math.trunc(w / 2), 'hours'));
        this.set("hourWindow", w);
      },
      currentDay: function currentDay() {
        this.set("chartDateInit", (0, _moment.default)().startOf("hour").subtract(Math.trunc(this.get("hourWindow") / 2), 'hours'));
      },
      back: function back() {
        this.set("chartDateInit", (0, _moment.default)(this.get("chartDateInit")).subtract(1, "hours"));

        if ((0, _moment.default)(this.get("chartDateInit")).diff(this.get("model.openAfter"), "hours") < 12) {
          var openAfter = (0, _moment.default)(this.get("model.openAfter")).subtract(12, "hours").format();
          this.set("model.openAfter", openAfter);
          var organizationID = this.get("organization.id");

          if (organizationID && openAfter) {
            Ember.RSVP.hash({
              chores: this.store.query("todo-chore", {
                organization: organizationID,
                openAfter: openAfter
              }),
              drugs: this.store.query("todo-drug", {
                organization: organizationID,
                openAfter: openAfter
              }),
              exames: this.store.query("todo-exam", {
                organization: organizationID,
                openAfter: openAfter
              }),
              monitorings: this.store.query("todo-monitoring", {
                organization: organizationID,
                openAfter: openAfter
              }),
              serums: this.store.query("todo-serum", {
                organization: organizationID,
                openAfter: openAfter
              }),
              actions: this.store.query("action", {
                organization: organizationID,
                startedAfter: openAfter
              })
            });
          }
        }
      },
      forward: function forward() {
        this.set("chartDateInit", (0, _moment.default)(this.get("chartDateInit")).add(1, "hours"));
      },
      openAction: function openAction(task, thisDate, action, prevAction) {
        if (task.get('internment.disabled')) {
          return;
        }

        this.set('actionModalModel', task);
        this.set('modalthisDate', thisDate);
        this.set('modalAction', action);

        if (Ember.isEmpty(action)) {
          this.set('modalShouldDisplayRemoveButton', true);
        }

        this.set('modalPrevAction', prevAction);

        if (Ember.isEmpty(action)) {
          this.openWithTrueTask((0, _moment.default)(thisDate), task);
        } else {
          this.set('actionModalModel', task);
          Ember.$('#modal-action-edit').modal();
        }
      },
      openInternmentCreate: function openInternmentCreate() {
        this.send("openModal", {
          entity: "internment",
          action: "create"
        });
      },
      callModal: function callModal(args) {
        this.send("openModal", args);
      }
    }
  });

  _exports.default = _default;
});