define("crm/components/task-to-do", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    doTaskDay: Ember.computed('model.taskDate', function () {
      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    doTaskTime: Ember.computed('model.taskDate', function () {
      return (0, _moment.default)().format('HH:mm');
    }),
    actions: {
      modalToDoTask: function modalToDoTask() {
        var self = this;
        this.set('model.doneTaskDate', (0, _moment.default)(this.get('doTaskDay') + ' ' + this.get('doTaskTime')).format());
        var task = this.get('model');
        task.set('status', 2);
        task.save().then(function (result) {
          if (self.get('updateTask')) {
            self.get('updateTask').removeObject(result);
          }

          Ember.$('#modal-task-done').modal('hide');
        });
      }
    }
  });

  _exports.default = _default;
});