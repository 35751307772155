define("crm/controllers/organizations/organization/group/consultations/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    consultationTypes: Ember.computed.alias("catalogs.consultationType"),
    exporting: false,
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ["before", "after"],
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    init: function init() {
      this._super();

      this.set("after", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
      this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    newBySpecies: Ember.computed("model.newBySpecies.[]", function () {
      return !this.get("model.newBySpecies") ? [] : this.get("model.newBySpecies").map(function (s) {
        return {
          speciesID: s[0],
          speciesName: s[1],
          amount: s[2]
        };
      });
    }),
    newByType: Ember.computed("model.newByType.[]", function () {
      return !this.get("model.newByType") ? [] : this.get("model.newByType").map(function (s) {
        return {
          typeID: s[0],
          typeName: s[1],
          amount: s[2]
        };
      });
    })
  });

  _exports.default = _default;
});