define("crm/components/campaign-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    audienceType: "Customers",
    init: function init() {
      this._super.apply(this, arguments);

      this.set('startDay', (0, _moment.default)().format('YYYY-MM-DD'));
      this.set('startTime', (0, _moment.default)().add(30, 'minutes').format('HH:mm'));
    },
    close: function close() {
      Ember.$("#modal-campaign-new").modal('hide');
    },
    sortedSpeciesList: Ember.computed("speciesList.@each.name", function () {
      if (this.get("speciesList") && this.get("speciesList.length") > 0) {
        var self = this;
        this.get("speciesList").forEach(function (specie) {
          specie.set("name", self.get("intl").t("species." + specie.get("id")).toString());
        });
        return this.get("speciesList").sortBy("name");
      }
    }),
    textTemplateStr: Ember.computed("model.textTemplate", function () {
      if (Ember.isEmpty(this.get("model.textTemplate"))) {
        return "";
      }

      var result = this.get("model.textTemplate");
      result = result.split("@@cc").join("<b>John Doe</b>");
      result = result.split("@@pa").join("<b>Mickey Mouse</b>");
      result = result.split("@@pe").join("<b>Vaccine</b>");
      result = result.split("@@dd").join("<b>2021/12/26</b>");
      result = result.split("@@hh").join("<b>19:15</b>");
      return Ember.String.htmlSafe(result);
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setSpecies: function setSpecies(species) {
        if (false === Ember.isEmpty(species)) {
          this.set("model.species", species);
        }
      },
      setAudienceType: function setAudienceType(audienceType) {
        if (Ember.isEmpty(this.get("model.periodic"))) {
          this.set("audienceType", audienceType);
        }
      },
      create: function create() {
        this.set('disabledAction', true);
        var self = this;

        if (this.get('organization')) {
          this.set('model.organization', this.get('organization'));
        }

        this.set("model.sendDate", (0, _moment.default)(this.get("startDay") + "T" + this.get("startTime"), "YYYY-MM-DDTHH:mm").format());

        if (this.get("audienceType") == "Customers") {
          this.set("model.audience", "everyone");
        }

        if (this.get("model.periodic")) {
          this.set("model.audience", "periodics");
        }

        var newCampaign = this.get('store').createRecord('campaign', this.get('model'));
        newCampaign.save().then(function () {
          self.get('flashMessages').success(self.get('intl').t('campaigns.new.createdSuccess'));
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (reason) {
          self.get('store').unloadRecord(newCampaign);
          self.get('flashMessages').danger(reason.errors ? reason.errors[0].campaign ? self.get("intl").t("campaigns.campaign.errors." + reason.errors[0].campaign) : Object.keys(reason.errors[0])[0] : "Error");
          self.set('disabledAction', false);
        });
      },
      reset: function reset() {
        this.set('model', {});
        this.set('disabledAction', false);
        Ember.$('select').prop('selectedIndex', 0);
        this.set('startDay', (0, _moment.default)().format('YYYY-MM-DD'));
        this.set('startTime', (0, _moment.default)().add(30, 'minutes').format('HH:mm'));
        this.set('audienceType', "Customers");
      }
    }
  });

  _exports.default = _default;
});