define("crm/controllers/organizations/organization/stats/items", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    queryParams: ["endedAfter", "endedBefore"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("endedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("endedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minEndedAfter: Ember.computed("endedBefore", function () {
      if (!Ember.isEmpty(this.get("endedBefore"))) {
        return (0, _moment.default)(this.get("endedBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxEndedBefore: Ember.computed("endedAfter", function () {
      if (!Ember.isEmpty(this.get("endedAfter"))) {
        return (0, _moment.default)(this.get("endedAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "vatAmount",
        "className": "column-big text-right small",
        "title": this.get("intl").t("taxes.vat.title")
      }, {
        "propertyName": "totalLinePrice",
        "component": "umt-price-display",
        "className": "column-big text-right small",
        "title": "Valor de linhas total"
      }, {
        "propertyName": "totalLinePriceWoVat",
        "component": "umt-price-display",
        "className": "column-big text-right small",
        "title": "Valor de linhas total sem IVA"
      }, {
        "propertyName": "totalVatLinePrice",
        "component": "umt-price-display",
        "className": "column-small text-right small",
        "title": "Valor de IVA das linhas total"
      }, {
        "propertyName": "total",
        "component": "umt-price-display",
        "className": "column-big text-right small",
        "title": "Valor total"
      }];
    }),
    invoices: Ember.computed("model.invoices.[]", function () {
      return this.get("model.invoices") ? this.get("model.invoices").map(function (x) {
        return {
          stockable: x[0],
          vatAmount: x[1],
          totalLinePrice: x[2],
          totalLinePriceWoVat: x[3],
          totalVatLinePrice: x[4],
          total: x[5] // totalLinePriceWoVat + totalVatLinePrice

        };
      }) : null;
    }),
    memos: Ember.computed("model.memos.[]", function () {
      return this.get("model.memos") ? this.get("model.memos").map(function (x) {
        return {
          stockable: x[0],
          vatAmount: x[1],
          totalLinePrice: x[2],
          totalLinePriceWoVat: x[3],
          totalVatLinePrice: x[4],
          total: x[5] // totalLinePriceWoVat + totalVatLinePrice

        };
      }) : null;
    }),
    invoicesStockableItems: Ember.computed("invoices.@each.stockable", function () {
      if (!Ember.isEmpty(this.get("invoices"))) {
        return this.get("invoices").filterBy("stockable", true);
      }
    }),
    memosStockableItems: Ember.computed("memos.@each.stockable", function () {
      if (!Ember.isEmpty(this.get("memos"))) {
        return this.get("memos").filterBy("stockable", true);
      }
    }),
    invoicesUnstockableItems: Ember.computed("invoices", function () {
      if (!Ember.isEmpty(this.get("invoices"))) {
        return this.get("invoices").filterBy("stockable", false);
      }
    }),
    memosUnstockableItems: Ember.computed("memos", function () {
      if (!Ember.isEmpty(this.get("memos"))) {
        return this.get("memos").filterBy("stockable", false);
      }
    }),
    totalStockableItems: Ember.computed("invoicesStockableItems.@each.vatAmount", "memosStockableItems.@each.vatAmount", function () {
      var result = [];
      var self = this;

      if (Ember.isEmpty(this.get("invoicesStockableItems"))) {
        return result;
      }

      this.get("invoicesStockableItems").forEach(function (invoicesStockableItem) {
        var mesmosStockableItem = null;

        if (!Ember.isEmpty(self.get("memosStockableItems"))) {
          mesmosStockableItem = self.get("memosStockableItems").filterBy("vatAmount", invoicesStockableItem.vatAmount).objectAt(0);
        }

        result.addObject({
          stockable: true,
          vatAmount: invoicesStockableItem.vatAmount,
          totalLinePrice: invoicesStockableItem.totalLinePrice - (mesmosStockableItem ? mesmosStockableItem.totalLinePrice : 0),
          totalLinePriceWoVat: invoicesStockableItem.totalLinePriceWoVat - (mesmosStockableItem ? mesmosStockableItem.totalLinePriceWoVat : 0),
          totalVatLinePrice: invoicesStockableItem.totalVatLinePrice - (mesmosStockableItem ? mesmosStockableItem.totalVatLinePrice : 0),
          total: invoicesStockableItem.total - (mesmosStockableItem ? mesmosStockableItem.total : 0)
        });
      });
      return result;
    }),
    totalUnstockableItems: Ember.computed("invoicesUnstockableItems.@each.vatAmount", "memosUnstockableItems.[]", "memosUnstockableItems.@each.vatAmount", "memosUnstockableItems.@each.totalLinePrice", function () {
      var result = [];
      var self = this;

      if (Ember.isEmpty(this.get("invoicesUnstockableItems"))) {
        return result;
      }

      this.get("invoicesUnstockableItems").forEach(function (invoicesUnstockableItem) {
        var memosUnstockableItem = null;

        if (!Ember.isEmpty(self.get("memosUnstockableItems"))) {
          memosUnstockableItem = self.get("memosUnstockableItems").filterBy("vatAmount", invoicesUnstockableItem.vatAmount).objectAt(0);
        }

        result.addObject({
          stockable: true,
          vatAmount: invoicesUnstockableItem.vatAmount,
          totalLinePrice: invoicesUnstockableItem.totalLinePrice - (memosUnstockableItem ? memosUnstockableItem.totalLinePrice : 0),
          totalLinePriceWoVat: invoicesUnstockableItem.totalLinePriceWoVat - (memosUnstockableItem ? memosUnstockableItem.totalLinePriceWoVat : 0),
          totalVatLinePrice: invoicesUnstockableItem.totalVatLinePrice - (memosUnstockableItem ? memosUnstockableItem.totalVatLinePrice : 0),
          total: invoicesUnstockableItem.total - (memosUnstockableItem ? memosUnstockableItem.total : 0)
        });
      });
      return result;
    }),
    filtersObserver: Ember.observer("endedAfter", "endedBefore", function () {
      if (!Ember.isEmpty(this.get("endedAfter")) && !Ember.isEmpty(this.get("endedBefore"))) {
        this.set("endedAfter", (0, _moment.default)(this.get("endedAfter")).startOf("day").format("YYYY-MM-DD"));
        this.set("endedBefore", (0, _moment.default)(this.get("endedBefore")).endOf("day").format("YYYY-MM-DD"));
      }
    })
  });

  _exports.default = _default;
});