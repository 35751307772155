define("crm/controllers/organizations/organization/stats/sales/overview/item-types/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    appointmentTypes: Ember.computed.alias("catalogs.appointmentType"),
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    modelStatus: Ember.computed("model.itemTypes.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("model.itemTypes.totalByStatus") ? [] : this.get("model.itemTypes.totalByStatus").map(function (s) {
        return {
          type: _this.get("appointmentTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("model.total") + "%")
        };
      });
    }),
    chartOptions: {
      legend: {
        display: false
      }
    },
    itemsTodayChartData: Ember.computed("itemsTodayByTypeRoot.[]", function () {
      var _this2 = this;

      if (!this.get("itemsTodayByTypeRoot")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("itemsTodayByTypeRoot")).map(function (k) {
          return _this2.get("itemsTodayByTypeRoot")[k].label;
        }),
        datasets: [{
          data: Object.keys(this.get("itemsTodayByTypeRoot")).map(function (k) {
            return _this2.get("itemsTodayByTypeRoot")[k].data ? _this2.get("itemsTodayByTypeRoot")[k].data.toFixed(0) : "";
          }),
          backgroundColor: Object.keys(this.get("itemsTodayByTypeRoot")).map(function (k) {
            return _this2.get("itemsTodayByTypeRoot")[k].color;
          })
        }]
      };
    }),
    itemsThisWeekChartData: Ember.computed("itemsThisWeekByTypeRoot.[]", function () {
      var _this3 = this;

      if (!this.get("itemsThisWeekByTypeRoot")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("itemsThisWeekByTypeRoot")).map(function (k) {
          return _this3.get("itemsThisWeekByTypeRoot")[k].label;
        }),
        datasets: [{
          data: Object.keys(this.get("itemsThisWeekByTypeRoot")).map(function (k) {
            return _this3.get("itemsThisWeekByTypeRoot")[k].data ? _this3.get("itemsThisWeekByTypeRoot")[k].data.toFixed(0) : "";
          }),
          backgroundColor: Object.keys(this.get("itemsThisWeekByTypeRoot")).map(function (k) {
            return _this3.get("itemsThisWeekByTypeRoot")[k].color;
          })
        }]
      };
    }),
    itemsThisMonthChartData: Ember.computed("itemsThisMonthByTypeRoot.[]", function () {
      var _this4 = this;

      if (!this.get("itemsThisMonthByTypeRoot")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("itemsThisMonthByTypeRoot")).map(function (k) {
          return _this4.get("itemsThisMonthByTypeRoot")[k].label;
        }),
        datasets: [{
          data: Object.keys(this.get("itemsThisMonthByTypeRoot")).map(function (k) {
            return _this4.get("itemsThisMonthByTypeRoot")[k].data ? _this4.get("itemsThisMonthByTypeRoot")[k].data.toFixed(0) : "";
          }),
          backgroundColor: Object.keys(this.get("itemsThisMonthByTypeRoot")).map(function (k) {
            return _this4.get("itemsThisMonthByTypeRoot")[k].color;
          })
        }]
      };
    }),
    itemsThisYearChartData: Ember.computed("itemsThisYearByTypeRoot.[]", function () {
      var _this5 = this;

      if (!this.get("itemsThisYearByTypeRoot")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("itemsThisYearByTypeRoot")).map(function (k) {
          return _this5.get("itemsThisYearByTypeRoot")[k].label;
        }),
        datasets: [{
          data: Object.keys(this.get("itemsThisYearByTypeRoot")).map(function (k) {
            return _this5.get("itemsThisYearByTypeRoot")[k].data ? _this5.get("itemsThisYearByTypeRoot")[k].data.toFixed(0) : "";
          }),
          backgroundColor: Object.keys(this.get("itemsThisYearByTypeRoot")).map(function (k) {
            return _this5.get("itemsThisYearByTypeRoot")[k].color;
          })
        }]
      };
    }),
    itemsTodayByType: Ember.computed("model.itemTypes.itemsToday.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsToday"));
    }),
    itemsTodayByTypeRoot: Ember.computed("itemsTodayByType.[]", function () {
      var _this6 = this;

      var result = {};
      this.get("itemsTodayByType").forEach(function (i) {
        var rootType = _this6.get("store").peekRecord("itemType", i.id).get("root");

        if (result[rootType.get("id")]) {
          result[rootType.get("id")].data += i.price;
        } else {
          result[rootType.get("id")] = {
            label: rootType.get("name"),
            color: rootType.get("color"),
            data: i.price
          };
        }
      });
      return result;
    }),
    itemsThisWeekByType: Ember.computed("model.itemTypes.itemsThisWeek.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsThisWeek"));
    }),
    itemsThisWeekByTypeRoot: Ember.computed("itemsThisWeekByType.[]", function () {
      var _this7 = this;

      var result = {};
      this.get("itemsThisWeekByType").forEach(function (i) {
        var rootType = _this7.get("store").peekRecord("itemType", i.id).get("root");

        if (result[rootType.get("id")]) {
          result[rootType.get("id")].data += i.price;
        } else {
          result[rootType.get("id")] = {
            label: rootType.get("name"),
            color: rootType.get("color"),
            data: i.price
          };
        }
      });
      return result;
    }),
    itemsThisMonthByType: Ember.computed("model.itemTypes.itemsThisMonth.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsThisMonth"));
    }),
    itemsThisMonthByTypeRoot: Ember.computed("itemsThisMonthByType.[]", function () {
      var _this8 = this;

      var result = {};
      this.get("itemsThisMonthByType").forEach(function (i) {
        var rootType = _this8.get("store").peekRecord("itemType", i.id).get("root");

        if (result[rootType.get("id")]) {
          result[rootType.get("id")].data += i.price;
        } else {
          result[rootType.get("id")] = {
            label: rootType.get("name"),
            color: rootType.get("color"),
            data: i.price
          };
        }
      });
      return result;
    }),
    itemsThisYearByType: Ember.computed("model.itemTypes.itemsThisYear.[]", function () {
      var x = this.waitByType(this.get("model.itemTypes.itemsThisYear"));
      return x;
    }),
    itemsThisYearByTypeRoot: Ember.computed("itemsThisYearByType.[]", function () {
      var _this9 = this;

      var result = {};
      this.get("itemsThisYearByType").forEach(function (i) {
        var rootType = _this9.get("store").peekRecord("itemType", i.id).get("root");

        if (result[rootType.get("id")]) {
          result[rootType.get("id")].data += i.price;
        } else {
          result[rootType.get("id")] = {
            label: rootType.get("name"),
            color: rootType.get("color"),
            data: i.price
          };
        }
      });
      return result;
    }),
    creatorsToday: Ember.computed("model.invoiceCreators.creatorsToday.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsToday"));
    }),
    creatorsThisWeek: Ember.computed("model.invoiceCreators.creatorsThisWeek.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisWeek"));
    }),
    creatorsThisMonth: Ember.computed("model.invoiceCreators.creatorsThisMonth.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisMonth"));
    }),
    creatorsThisYear: Ember.computed("model.invoiceCreators.creatorsThisYear.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisYear"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[0],
          quantity: s[1],
          price: s[2],
          id: s[3]
        };
      });
    }
  });

  _exports.default = _default;
});