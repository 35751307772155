define("crm/controllers/organizations/organization/sheltereds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    associatedSheltered: false,
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("sheltereds.sheltered.name"),
        "routeName": "organizations.organization.sheltereds.sheltered",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "sex",
        "component": "show-sex-symbol",
        "title": this.get('intl').t("sheltereds.sheltered.sex")
      }, {
        "propertyName": "species",
        "component": "show-species",
        "title": this.get('intl').t("sheltereds.sheltered.species"),
        "sortedBy": "species.name"
      }, {
        "propertyName": "breed",
        "component": "show-breeds",
        "title": this.get('intl').t("sheltereds.sheltered.breed"),
        "sortedBy": "breed.name"
      }, {
        "propertyName": "owner",
        "component": "show-owners-by-animal",
        "title": this.get('intl').t("customers.customer.title"),
        "sortedBy": "owner.name"
      }, {
        "component": "sheltered-actions"
      }];
    }),
    actions: {
      openModalCreateSheltered: function openModalCreateSheltered() {
        this.send('openCreateSheltered');
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      openEditShelteredModal: function openEditShelteredModal(shelteredToEdit) {
        this.send('openEditSheltered', shelteredToEdit);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(sheltered) {
        this.transitionToRoute('organizations.organization.appointments.custom', {
          queryParams: {
            shelteredid: sheltered.get('id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});