define("crm/models/stock-transfer-order", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    billingID: _emberData.default.attr("string"),
    documentDate: _emberData.default.attr("isodate"),
    documentType: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    deletedReason: _emberData.default.attr("string"),
    slip: _emberData.default.attr("boolean"),
    aTDocCodeID: _emberData.default.attr("string"),
    deleted: _emberData.default.attr("isodate"),
    loadingStreetName: _emberData.default.attr("string"),
    loadingBuildingNumber: _emberData.default.attr("string"),
    loadingPostalZone: _emberData.default.attr("string"),
    loadingCityName: _emberData.default.attr("string"),
    loadingCountry: _emberData.default.attr("string"),
    loadingDateTime: _emberData.default.attr("isodate"),
    unloadingStreetName: _emberData.default.attr("string"),
    unloadingBuildingNumber: _emberData.default.attr("string"),
    unloadingPostalZone: _emberData.default.attr("string"),
    unloadingCityName: _emberData.default.attr("string"),
    unloadingCountry: _emberData.default.attr("string"),
    unloadingDateTime: _emberData.default.attr("isodate"),
    sourceWarehouse: _emberData.default.belongsTo("warehouse"),
    targetWarehouse: _emberData.default.belongsTo("warehouse"),
    lines: _emberData.default.hasMany("stock-transfer-order-line", {
      inverse: "stockTransferOrder"
    })
  });

  _exports.default = _default;
});