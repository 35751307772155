define("crm/components/document-annul", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;

      if (this.get("model.id")) {
        this.get("store").findRecord(this.get("model.constructor.modelName"), this.get("model.id"), {
          reload: true
        }).catch(function (error) {
          self.get('flashMessages').danger(error);
        });
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      edit: function edit() {
        this.set("disabledAction", true);
        var self = this;
        this.set("model.status", 8);
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (e) {
          self.set("disabledAction", false);
          self.get("es").handle(e);
        });
      },
      reset: function reset() {
        this.set("disabledAction", false);
        this.get("model") && this.get("model").rollbackAttributes();
      },
      canceled: function canceled() {
        this.close();
        this.get("model") && this.get("model").rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});