define("crm/routes/organizations/organization/exams/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization");
      var self = this;
      model.set("createdAfter", params.createdAfter || (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      model.set("createdBefore", params.createdBefore || (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
      var createdBefore = (0, _moment.default)(model.get("createdBefore")).endOf("day").format();
      var createdAfter = (0, _moment.default)(model.get("createdAfter")).startOf("day").format();

      if ((0, _moment.default)(model.get("createdBefore")).diff(model.get("createdAfter"), "days") <= this.get("dateLimit")) {
        this.store.query('exam', {
          organization: model.get('id'),
          requestDateAfter: createdAfter,
          requestDateBefore: createdBefore
        }).then(function (exams) {
          // GET all needed patients
          self.store.query("patient", {
            organization: model.get('id'),
            requestDateAfter: createdAfter,
            requestDateBefore: createdBefore
          }, {
            reload: true
          }).then(function () {
            model.set("exams", exams);
          });
        });
      } else {
        model.set("exams", null);
      }

      return model;
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
      }
    },
    actions: {
      openCreateExam: function openCreateExam() {
        this.send('openModalCreateExam');
      },
      openEditExam: function openEditExam(examToEdit) {
        this.send('openEditExamModal', examToEdit);
      }
    }
  });

  _exports.default = _default;
});