define("crm/components/supplier-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    showActions: false,
    classNames: ["card", "contact-box", "customer-card"],
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id") && !this.get("isFull")) {
        this.get("store").findRecord("supplier", this.get("model.id"));
      }
    },
    actions: {
      toggleActions: function toggleActions() {
        this.set("showActions", !this.get("showActions"));
      }
    }
  });

  _exports.default = _default;
});