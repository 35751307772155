define("crm/routes/organizations/organization/stats/sales/overview/sales", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    session: Ember.inject.service('session'),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdAfter2: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      },
      createdBefore2: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get('id');

      if (params.createdAfter && params.createdBefore && params.createdAfter2 && params.createdAfter2) {
        return Ember.RSVP.hash({
          model1: Ember.$.get({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/day?organization=" + organizationID + "&createdAfter=" + params.createdAfter + "&createdBefore=" + params.createdBefore,
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
            }
          }),
          model2: Ember.$.get({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/day?organization=" + organizationID + "&createdAfter=" + params.createdAfter2 + "&createdBefore=" + params.createdBefore2,
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
            }
          })
        });
      } else {
        return {
          model1: [],
          model2: []
        };
      }
    }
  });

  _exports.default = _default;
});