define("crm/routes/organizations/organization/stats/clinical/types", ["exports", "crm/routes/organizations/organization/stats/stats-catalogs"], function (_exports, _statsCatalogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _statsCatalogs.default.extend({
    intl: Ember.inject.service(),
    service: "/stats/clinical/consultations/types",
    afterModel: function afterModel(model) {
      model.catalog = model.organization.get("company.itemTypes");
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("itemTypes.title")
      });
    }
  });

  _exports.default = _default;
});