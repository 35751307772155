define("crm/controllers/questionnaire-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    disableBtn: false,
    isDirty: true,
    fieldTypes: Ember.computed("questionnaire.questions", function () {
      return this.get("questionnaire.questions") ? this.get("questionnaire.questions") : null;
    }),
    avatar: Ember.computed("model.invite.invoice.organization.id", function () {
      return this.get("model.invite.invoice.organization.id") ? this.get("store").findRecord("organization-avatar", this.get("model.invite.invoice.organization.id")) : undefined;
    }),
    questions: Ember.computed("model.invite.questionnaire.id", function () {
      return this.get("model.invite.questionnaire.id") ? this.get("store").query("questionnaire-question", {
        questionnaire: this.get("model.invite.questionnaire.id")
      }) : null;
    }),
    answers: Ember.computed("questions.[]", function () {
      return this.get("questions.length") ? this.get("questions").map(function (q) {
        return {
          type: q
        };
      }) : [];
    }),
    actions: {
      setAvatar: function setAvatar(url) {
        this.set("model.avatar", url);
      },
      create: function create() {
        var self = this;
        this.set("disableBtn", true);
        this.get("store").createRecord("questionnaire-reply", {
          questionnaire: this.get("model.invite.questionnaire"),
          invite: this.get("model.invite"),
          customer: this.get("model.invite.invoice.customer")
        }).save().then(function (savedReply) {
          self.get("answers").sortBy("type.id").forEach(function (answer) {
            var aux = {
              value: answer.value,
              reply: savedReply,
              type: answer.type
            };

            switch (aux.type.get("type")) {
              case 0:
              case 6:
                self.get("store").createRecord("questionnaire-answer-string", aux).save();
                break;

              case 4:
              case 9:
                self.get("store").createRecord("questionnaire-answer-integer", aux).save();
                break;
            }
          });
        });
        this.set("model.invite.deleted", new Date());
        this.set("disableBtn", false);
      },
      cancel: function cancel() {}
    }
  });

  _exports.default = _default;
});