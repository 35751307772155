define("crm/controllers/organizations/organization/jasmin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    featuresFiltered: Ember.computed("model.organizationFeatures.[]", function () {
      var _this = this;

      var result = [];
      var map = {};

      if (this.get("model.organizationFeatures.length")) {
        this.get("model.organizationFeatures").forEach(function (organizationFeature) {
          return map[organizationFeature.get("feature.id")] = organizationFeature;
        });
      }

      this.store.peekAll("feature").sortBy("office", "name").forEach(function (f) {
        if (map[f.get("id")]) {
          result.push(map[f.get("id")]);
        } else {
          result.push({
            feature: f,
            organization: _this.get("model")
          });
        }
      });
      return result;
    }),
    equipmentsFiltered: Ember.computed("equipmentOrganizations.@each.id", "equipments.@each.id", function () {
      var _this2 = this;

      this.get("equipments").forEach(function (f) {
        f.set("equipmentOrganization", undefined);

        _this2.get("equipmentOrganizations").forEach(function (of) {
          if (f.get("id") === of.get("equipment.id")) {
            f.set("equipmentOrganization", of);
          }
        });
      });
      return this.get("equipments").sortBy("name");
    }),
    actions: {
      toggleFeature: function toggleFeature(organizationFeature) {
        if (Ember.get(organizationFeature, "id")) {
          organizationFeature.destroyRecord();
        } else {
          this.get("store").createRecord("organizationFeature", organizationFeature).save();
        }
      },
      toggleEquipment: function toggleEquipment(equipment) {
        var _this3 = this;

        equipment.set("creating", true);

        if (equipment.get("equipmentOrganization")) {
          equipment.get("equipmentOrganization").destroyRecord().then(function () {
            return equipment.set("equipmentOrganization", undefined);
          }).finally(function () {
            equipment.set("creating", false);

            _this3.send("reloadModel");
          });
        } else {
          this.get("store").createRecord("equipmentOrganization", {
            organization: this.get("model"),
            equipment: equipment
          }).save().then(function (result) {
            return equipment.set("equipmentOrganization", result);
          }).finally(function () {
            equipment.set("creating", false);

            _this3.send("reloadModel");
          });
        }
      },
      saveCompanyJasmin: function saveCompanyJasmin() {
        this.get("companyJasmin").save();
      },
      saveOrganizationJasmin: function saveOrganizationJasmin() {
        this.get("organizationJasmin").save();
      },
      saveOrganizationInvisible: function saveOrganizationInvisible() {
        this.get("organizationInvisible").save();
      },
      saveOrganizationPet: function saveOrganizationPet() {
        this.get("organizationPet").save();
      },
      goToJasmin: function goToJasmin() {
        window.open("https://my.jasminsoftware.com/" + this.get("companyJasmin.endpoint") + "/#/start");
      }
    }
  });

  _exports.default = _default;
});