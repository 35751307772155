define("crm/helpers/drug-fraction-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.drugFractionDisplay = drugFractionDisplay;
  _exports.default = void 0;

  function drugFractionDisplay() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      if (params[1] === 7) {
        switch (params[0]) {
          case 0.125:
          case 0.13:
            return "1/8";

          case 0.25:
            return "1/4";

          case 0.33:
            return "1/3";

          case 0.5:
            return "1/2";

          case 0.75:
            return "3/4";

          case 1.125:
          case 1.13:
            return "1 + 1/8";

          case 1.25:
            return "1 + 1/4";

          case 1.33:
            return "1 + 1/3";

          case 1.5:
            return "1 + 1/2";

          case 1.75:
            return "1 + 3/4";

          case 2.125:
          case 2.13:
            return "2 + 1/8";

          case 2.25:
            return "2 + 1/4";

          case 2.33:
            return "2 + 1/3";

          case 2.5:
            return "2 + 1/2";

          case 2.75:
            return "2 + 3/4";

          case 3.125:
          case 3.13:
            return "3 + 1/8";

          case 3.25:
            return "3 + 1/4";

          case 3.33:
            return "3 + 1/3";

          case 3.5:
            return "3 + 1/2";

          case 3.75:
            return "3 + 3/4";

          default:
            return params[0];
        }
      } else {
        return params[0];
      }
    }
  });

  _exports.default = _default;
});