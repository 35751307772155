define("crm/components/internment/todo-serum-edit", ["exports", "crm/components/internment/todo-edit"], function (_exports, _todoEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoEdit.default.extend({
    catalogs: Ember.inject.service(),
    modelName: "todo-serum",
    adjustments: Ember.computed.alias("catalogs.adjustments"),

    /*
     * Functions
     */
    clone: function clone() {
      return {
        adjustment: this.get("model.adjustment"),
        amount: this.get("model.amount"),
        fluidRate: this.get("model.fluidRate")
      };
    },
    actions: {
      setAdjustmentSelectAnswer: function setAdjustmentSelectAnswer(value) {
        this.set("model.adjustment", this.get("model.adjustment") === value ? null : value);

        if (this.get("model.adjustment")) {
          this.set("model.fluidRate", (this.get("model.internment.patient.maintenanceFluidRate") * this.get("model.adjustment")).toFixed(3));
        } else {
          this.set("model.fluidRate", null);
        }
      }
    }
  });

  _exports.default = _default;
});