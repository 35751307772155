define("crm/routes/organizations/campanhas", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly('sessionAccount.currentUser'),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (!window.location.href.includes("dev.petuniversal.com") && !window.location.href.includes("localhost") && this.get("currentUser.id") !== "33") {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var self = this;
      var url = _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/campaigns/automatic";
      return Ember.$.ajax({
        url: url,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      });
    }
  });

  _exports.default = _default;
});