define("crm/routes/organizations/organization/appointments/configurations", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: {
      day: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var me = this.get("currentWorker");
      var day = transition.queryParams.day ? transition.queryParams.day : (0, _moment.default)().format("YYYY-MM-DD");

      if (!(0, _moment.default)(day).isValid()) {
        return undefined;
      }

      var s = (0, _moment.default)(day).startOf("day").format();
      var e = (0, _moment.default)(day).endOf("day").format();
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      return {
        organization: organization,
        shifts: this.store.query("shift", {
          organization: organizationID,
          startDate: s,
          endDate: e
        }),
        absences: this.store.query("absence", {
          organization: organizationID,
          startDate: s,
          endDate: e
        }),
        calendarFiltersWorkers: this.store.query("calendar-filter-worker", {
          owner: me.get("id")
        }),
        calendarFiltersRooms: this.store.query("calendar-filter-room", {
          owner: me.get("id")
        }),
        itemTypeFilters: this.store.query("calendar-filter-item-type", {
          owner: me.get("id")
        })
      };
    }
  });

  _exports.default = _default;
});