define("crm/components/enterprise-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledActionEnterpriseEdit: false,
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      edit: function edit() {
        this.set("disabledActionEnterpriseEdit", true);
        var self = this;
        this.get("model").save().then(function () {
          self.close();
          self.get("flashMessages").success(self.get("intl").t("enterprises.messages.title"));
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set("disabledActionEnterpriseEdit", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledActionEnterpriseEdit", false);
      }
    }
  });

  _exports.default = _default;
});