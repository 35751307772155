define("crm/components/quotations-list", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    listQuotations: Ember.computed('model.quotations.@each.status', function () {
      var self = this;
      return this.get('model.quotations').filter(function (quotation) {
        return (quotation.get('status') == 0 || quotation.get('status') == 1) && (Ember.isEmpty(quotation.get('order.id')) || quotation.get("id") === self.get("model.order.quotation.id")) && self.get('model.owner.id') == quotation.get('customer.id');
      });
    }),
    actions: {
      selectedQuotation: function selectedQuotation(quotation) {
        if (this.get('model.order.quotation.id') == quotation.get('id')) {
          this.set('model.order.quotation', null);
          this.get('flashMessages').info(this.get('intl').t('quotations.removed')); //temporary feedback
        } else {
          this.set('model.order.quotation', quotation);
          this.get('flashMessages').success(this.get('intl').t('quotations.selected')); //temporary feedback
        }

        this.get('model.order').save();
      },
      showPdf: function showPdf(record) {
        var self = this;
        var modelName = record.constructor.modelName + "s";
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + modelName + '/' + record.id + '/pdf',
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            self.sendAction("showPdf", window.URL.createObjectURL(blob));
          }
        });
      }
    }
  });

  _exports.default = _default;
});