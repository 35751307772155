define("crm/components/umt-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    text: Ember.computed("record.[]", function () {
      return this.get("record." + this.get("column.propertyName")) ? (0, _moment.default)(this.get("record." + this.get("column.propertyName"))).format("YYYY-MM-DD") : "--";
    })
  });

  _exports.default = _default;
});