define("crm/routes/organizations/organization/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    can: Ember.inject.service(),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      },
      createdAfter2: {
        refreshModel: true
      },
      createdBefore2: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('read stat')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      return {
        createdAfter: params.createdAfter,
        createdBefore: params.createdBefore,
        createdAfter2: params.createdAfter2,
        createdBefore2: params.createdBefore2,
        organization: this.modelFor("organizations.organization")
      };
    }
  });

  _exports.default = _default;
});