define("crm/components/appointments-now-line", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["horizontalLine"],
    attributeBindings: ["style"],
    style: Ember.computed(function () {
      var minutes = (0, _moment.default)().minutes();
      var top;

      if (minutes < 30) {
        top = minutes;
      } else {
        top = minutes - 30;
      }

      return Ember.String.htmlSafe("top: " + top + "px");
    })
  });

  _exports.default = _default;
});