define("crm/components/customer-edit", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dataValidator: Ember.inject.service(),
    session: Ember.inject.service("session"),
    contact: {},
    catalogs: Ember.inject.service(),
    inboundTypes: Ember.computed.alias("catalogs.inboundTypes"),
    titles: Ember.computed.alias("catalogs.titles"),
    disabledSubmissionCustomerEdit: false,
    disabledNif: false,
    previousNif: null,
    savedAddress: {},
    es: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set("countries", this.get("store").peekAll("country"));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      var self = this;

      if (this.get("model.id")) {
        this.get("store").findRecord("customer", this.get("model.id"), {
          reload: true
        }).then(function (savedCustomer) {
          if (savedCustomer.get("firstAddress")) {
            self.set("savedAddress", savedCustomer.get("firstAddress"));
          } else {
            self.set("savedAddress", self.get("organization.country"));
          }

          if (savedCustomer.get("nif")) {
            self.get("store").query("invoice", {
              customer: savedCustomer.get("id")
            }).then(function (invoices) {
              if (invoices.get("length") > 0) {
                self.set("disabledNif", true);
              }
            });
          }

          if (self.get("model.nif")) {
            self.set("previousNif", self.get("model.nif"));
          }

          if (savedCustomer.get("inboundType") || savedCustomer.get("inboundType") === 0) {
            self.set("selectedInboundType", self.get("inboundTypes")[savedCustomer.get("inboundType")]);
          } else {
            self.set("selectedInboundType", undefined);
          }

          if (savedCustomer.get("title")) {
            self.set("selectedTitle", savedCustomer.get("title"));
          } else {
            self.set("selectedTitle", undefined);
          }

          if (self.get("countries.length")) {
            if (!Ember.isEmpty(savedCustomer.get("language"))) {
              self.set("selectedCountry", savedCustomer.get("language"));
            } else {
              self.set("selectedCountry", self.get("countries").find(function (item) {
                return Ember.get(item, "code") === self.get("organization.country");
              }));
            }
          }
        });
      }
    },
    countryName: Ember.computed("model.firstAddress.country", function () {
      var _this = this;

      return this.get("countries").find(function (item) {
        return Ember.get(item, "code") === _this.get("model.firstAddress.country");
      });
    }),
    adherenceReasons: Ember.computed("model.id", function () {
      return this.get("store").findAll("adherenceReason");
    }),
    origins: Ember.computed("model.id", function () {
      return this.get("store").findAll("origin");
    }),
    paymentTerms: Ember.computed("model.id", function () {
      return this.get("store").findAll("paymentTerm");
    }),
    editMobilePhone: Ember.computed("model.contacts", function () {
      if (this.get("model.contacts")) {
        return this.get("model.contacts").filterBy("preferential", true).filterBy("contactType", 1).get(0);
      }
    }),
    editEmail: Ember.computed("model.contacts", function () {
      if (this.get("model.contacts")) {
        return this.get("model.contacts").filterBy("preferential", true).filterBy("contactType", 3).get(0);
      }
    }),
    birthdate: Ember.computed("model.birthdate", function () {
      if (this.get("model.birthdate")) {
        return (0, _moment.default)(this.get("model.birthdate")).format("YYYY-MM-DD");
      }
    }),
    isRequired: Ember.computed("savedAddress.country", "savedAddress.street", "savedAddress.building", "savedAddress.zip", "savedAddress.town", function () {
      if (Ember.isEmpty(this.get("savedAddress.country")) && Ember.isEmpty(this.get("savedAddress.street")) && Ember.isEmpty(this.get("savedAddress.building")) && Ember.isEmpty(this.get("savedAddress.zip")) && Ember.isEmpty(this.get("savedAddress.town"))) {
        return false;
      } else {
        return true;
      }
    }),
    close: function close() {
      Ember.$("#openUpdateCustomer").modal("hide");
    },
    disableAction: Ember.observer("usageNifMessage.[]", "validTaxNumber", function () {
      if (this.get("usageNifMessage") || !this.get("validTaxNumber")) {
        this.set("disabledSubmissionCustomerEdit", true);
      } else {
        this.set("disabledSubmissionCustomerEdit", false);
      }
    }),
    nifAllreadyExists: Ember.computed("model.nif.[]", function () {
      if (this.get("model.nif.length") >= 9) {
        return this.get("store").query("customer", {
          company: Number(this.get("organization.company.id")),
          vat: this.get("model.nif").toString()
        });
      }
    }),
    usageNifMessage: Ember.computed("nifAllreadyExists.[]", "model.nif", "previousNif", function () {
      if (!Ember.isEmpty(this.get("model.nif")) && this.get("model.nif") !== this.get("previousNif")) {
        if (!Ember.isEmpty(this.get("nifAllreadyExists"))) {
          return this.get('intl').t("customers.form.allreadyExistsVat");
        }
      }
    }),
    validTaxNumber: Ember.computed("selectedCountry.code", "model.nif", "organization.country", function () {
      if (Ember.isEmpty(this.get("model.nif")) || Ember.isEmpty(this.get("selectedCountry.code")) && Ember.isEmpty(this.get("organization.country"))) {
        return true;
      }

      if (this.get("model.nif") === "999999990") {
        return false;
      }

      var self = this;
      var aux;
      var country;

      if (this.get("selectedCountry.code")) {
        country = this.get("selectedCountry.code");
        aux = this.get("dataValidator").taxNumber(this.get("selectedCountry.code"), this.get("model.nif"));
      } else {
        country = this.get("organization.country");
        aux = this.get("dataValidator").taxNumber(this.get("organization.country"), this.get("model.nif"));
      }

      if (aux) {
        self.set("validatingTaxNumber", true);
        Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/checkvat?country=" + country + "&vat=" + this.get("model.nif"),
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale "));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(result) {
            if (result.valid) {// TODO if data comes back fill automatically

              /*if(isEmpty(self.get("customer.name"))) {
                  self.set("customer.name", result.name);
              }
              if(isEmpty(self.get("customer.zip"))) {
                  var aux = result.address.zipcode.split("-");
                  if(aux) {
                      self.set("zipFirstPart", aux[0]);
                      self.set("zipSecondPart", aux[1]);
                      self.set("customer.zip", self.get("zipFirstPart") +"-" + self.get("zipSecondPart") );
                  }
              }
              if(isEmpty(self.get("customer.town"))) {
                  self.set("customer.town", result.address.city);
              }
              if(isEmpty(self.get("customer.street"))) {
                  self.set("customer.street", result.address.street);
              }*/
            }

            self.set("validatingTaxNumber", false);
          },
          error: function error() {
            self.set("validatingTaxNumber", false);
          }
        });
      } else {
        /*this.set("validatingTaxNumber", null);
        this.set("customer.name", null);
        this.set("zipFirstPart", null);
        this.set("zipSecondPart", null);
        this.set("customer.town", null);
        this.set("customer.street", null);
        this.set("customer.phone", null);
        this.set("customer.email", null);
        this.set("customer.facebook", null);*/
      }

      return aux;
    }),
    actions: {
      selectCountry: function selectCountry(country) {
        if (country) {
          this.set("selectedCountry", country);
        } else {
          this.set("selectedCountry", null);
        }
      },
      imageUpdated: function imageUpdated(image) {
        var self = this;
        this.get("store").findRecord("customer", this.get("model.id"), {
          reload: true
        }).then(function (savedCustomer) {
          savedCustomer.set("avatar", image);
          savedCustomer.save().catch(function (result) {
            return self.get("es").handle(result);
          });
        });
      },
      setCountry: function setCountry(country) {
        this.set("selectedCountry", Ember.get(country, "code"));
      },
      modalUpdateCustomer: function modalUpdateCustomer() {
        this.set("disabledSubmissionCustomerEdit", true);
        var self = this;

        if (this.get("paymentTermID")) {
          var paymentTerm = this.get("store").peekRecord("paymentTerm", this.get("paymentTermID"));
          this.set("model.paymentTerm", paymentTerm);
        }

        if (this.get("referencedByID")) {
          var referencedBy = this.get("store").peekRecord("customer", this.get("referencedByID"));
          this.set("model.referencedBy", referencedBy);
        }

        if (this.get("reasonID")) {
          var reason = this.get("store").peekRecord("adherenceReason", this.get("reasonID"));
          this.set("model.reason", reason);
        }

        if (this.get("originID")) {
          var origin = this.get("store").peekRecord("origin", this.get("originID"));
          this.set("model.origin", origin);
        }

        if (this.get("selectedInboundType")) {
          this.set("model.inboundType", this.get("selectedInboundType").id);
        }

        if (this.get("selectedTitle")) {
          this.set("model.title", this.get("selectedTitle"));
        }

        if (this.get("selectedCountry.id") != this.get("model.language.id")) {
          this.set("model.language", this.get("selectedCountry"));
        }

        this.set("model.completed", true);
        this.get("model").save().then(function () {
          self.close();
        }).catch(function (result) {
          self.get("es").handle(result);
          self.set("disabledSubmissionCustomerEdit", false);
        });
      },
      setInboundType: function setInboundType(inboundType) {
        this.set("selectedInboundType", inboundType);
      },
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        this.set("model.paymentMethod", paymentMethod);
      },
      cancelUpdateCustomerModal: function cancelUpdateCustomerModal() {
        this.close();
      },
      reset: function reset() {
        this.set("disabledSubmissionCustomerEdit", false);
        this.set("showMoreFields", false);
        this.set("contact", {});
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("savedAddress", {});
        this.set("disabledNif", false);
      }
    }
  });

  _exports.default = _default;
});