define("crm/components/warehouse-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    es: Ember.inject.service(),
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      modalCreateWarehouse: function modalCreateWarehouse() {
        this.set('disabledAction', true);
        var self = this;
        this.set('model.organization', this.get('organization'));
        this.set('model.enabled', true);
        this.get('store').createRecord('warehouse', this.get('model')).save().then(function () {
          self.sendAction('reloadModel');
          self.close();
          self.get('flashMessages').success(self.get('intl').t('warehouses.messages.createdSuccess'));
        }).catch(function (result) {
          self.set('disabledAction', false);
          self.get("es").handle(result);
        });
      },
      reset: function reset() {
        this.set('disabledAction', false);
        Ember.$('select').prop('selectedIndex', 0);
        this.set('model', {});
      }
    }
  });

  _exports.default = _default;
});