define("crm/components/sheltered-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("sheltered", this.get("model.id"), {
          reload: true
        });
      }
    },
    speciesBreeds: Ember.computed("speciesID", function () {
      return [];
    }),
    maxDeathdate: (0, _moment.default)().format('YYYY-MM-DD'),
    environments: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('sheltereds.form.environmentType.indoor')
      }, {
        id: 2,
        name: this.get('intl').t('sheltereds.form.environmentType.outdoor')
      }, {
        id: 3,
        name: this.get('intl').t('sheltereds.form.environmentType.both')
      }];
    }),
    birthdate: Ember.computed('model.birthdate', function () {
      if (this.get('model.birthdate')) {
        return (0, _moment.default)(this.get('model.birthdate')).format('YYYY-MM-DD');
      }
    }),
    choices: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('sheltereds.sheltered.yes')
      }, {
        id: 0,
        name: this.get('intl').t('sheltereds.sheltered.no')
      }];
    }),
    reset: function reset() {
      Ember.$('#openEditSheltered').modal('hide');
    },
    actions: {
      selectSex: function selectSex(sex) {
        if (this.get("model.sex")) {
          if (this.get("model.sex") === sex) {
            this.set("model.sex", null);
          } else {
            this.set("model.sex", sex);
          }
        } else {
          this.set("model.sex", sex);
        }
      },
      setOwner: function setOwner(value) {
        this.set('customer', value);
      },
      setSterialized: function setSterialized(value) {
        this.set('model.sterilized', value);
      },
      setTattoo: function setTattoo(value) {
        this.set('model.tattoo', value);
      },
      setInsurance: function setInsurance(value) {
        this.set('model.insurance', value);
      },
      selectDiet: function selectDiet(diet) {
        this.set("diet", diet);
      },
      selectTail: function selectTail(tailType) {
        this.set('shelteredModel.tailType', tailType);
      },
      setFavoriteOwner: function setFavoriteOwner(favoriteOwner) {
        this.set('model.ownerPrefered', favoriteOwner);
      },
      setTail: function setTail(tail) {
        this.set('tail', tail);
      },
      setWorker: function setWorker(worker) {
        this.set('worker', worker);
      },
      setEnvironment: function setEnvironment(value) {
        this.set('model.environmentType', value);
      },
      setPrice: function setPrice(value) {
        this.set('price', value);
      },
      setDiet: function setDiet(diet) {
        this.set("diet", diet);
      },
      modalEditSheltered: function modalEditSheltered(sheltered) {
        var self = this;

        if (sheltered) {
          if (this.get('speciesID')) {
            var specie = this.get('store').peekRecord('species', this.get("speciesID"));
            sheltered.set("species", specie);
          }

          if (this.get('breedID')) {
            var breed = this.get('store').peekRecord('breed', this.get("breedID"));
            sheltered.set("breed", breed);
          }

          if (this.get('customer')) {
            var owner = this.get('store').peekRecord('customer', this.get("customer"));
            sheltered.set("owner", owner);
          }

          if (this.get('tail')) {
            var tail = this.get('store').peekRecord('tail', this.get("tail"));
            sheltered.set("tailType", tail);
          }

          if (this.get('diet')) {
            var diet = this.get('store').peekRecord('diet', this.get("diet"));
            sheltered.set("diet", diet);
          }

          if (this.get('worker')) {
            var worker = this.get('store').peekRecord('worker', this.get("worker"));
            sheltered.set("veterinaryPrefered", worker);
          }

          if (this.get('birthdate')) {
            sheltered.set("birthdate", this.get('birthdate'));
          }

          if (this.get('model.deathdate')) {
            sheltered.set('enabled', false);
          }

          sheltered.set('completed', true);
          var peekSheltered = this.get('store').peekRecord('sheltered', sheltered.get('id'));
          peekSheltered.save().then(function () {
            self.reset();
          });
        }
      },
      cancelEditShelteredModal: function cancelEditShelteredModal() {
        this.get('model').rollbackAttributes();
        this.reset();
      }
    }
  });

  _exports.default = _default;
});