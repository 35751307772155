define("crm/routes/organizations/organization/listings/sales/items-new", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var createdAfter = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdAfter).startOf("day").utc().toISOString();
      var createdBefore = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdBefore).endOf("day").utc().toISOString();
      return Ember.RSVP.hash({
        items: Ember.$.get({
          url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/items/info" + "?company=" + organization.get("company.id")) + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return r.items.map(function (l) {
          var i = 0;
          var x = {
            code: l[i++],
            name: l[i++],
            created: l[i++]
          };
          return x;
        });
      });
    }
  });

  _exports.default = _default;
});