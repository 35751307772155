define("crm/components/patient-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      editPatientModal: function editPatientModal() {
        this.sendAction('openEditPatientModal', this.get('model'));
      },
      delete: function _delete(id) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.sendAction("delete", id);
        }
      },
      enable: function enable(object) {
        this.sendAction("enable", object);
      },
      disable: function disable(object) {
        this.sendAction("disable", object);
      }
    },
    pedigreeText: Ember.computed("model.pedigree", function () {
      if (this.get("model.pedigree")) {
        return this.get('intl').t('patients.patient.pedigree.yes');
      } else {
        return this.get('intl').t('patients.patient.pedigree.no');
      }
    })
  });

  _exports.default = _default;
});