define("crm/routes/organizations/organization/purchase-orders/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      purchaseOrder: {
        refreshModel: true
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      /*
      USED TO LIST ALL ITEMS UNDER MIN STOCK
      return $.ajax({
         url: ENV.universe.api.host + "/" + ENV.universe.api.namespace + "/inventory/shortage?organization=" + organization.get("id"),
         accept: "application/json",
         beforeSend: function(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
         }
      }).then(result => ({ organization: organization, lines: [], shortageItems: JSON.parse(result.data) }));
      */

      return {
        organization: organization,
        lines: []
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var suppliers = model.organization.get("company.suppliers");
      controller.set("suppliers", suppliers);

      if (!Ember.isEmpty(suppliers) && suppliers.get("length") == 1) {
        Ember.set(model, "supplier", suppliers.objectAt(0));
      }

      this.store.query("warehouse", {
        organization: model.organization.get("id")
      }).then(function (warehouses) {
        controller.set("warehouses", warehouses);

        if (!Ember.isEmpty(warehouses) && warehouses.get("length") == 1) {
          Ember.set(model, "warehouse", warehouses.objectAt(0));
        }
      });

      if (controller.get("purchaseOrder")) {
        this.store.findRecord("purchase-order", controller.get("purchaseOrder")).then(function (purchaseOrder) {
          Ember.set(model, "supplier", purchaseOrder.get("supplier"));
          Ember.set(model, "warehouse", purchaseOrder.get("warehouse"));
          controller.send("duplicatePurchaseOrder", purchaseOrder);
        });
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.reset();
      }
    }
  });

  _exports.default = _default;
});