define("crm/components/task-comment-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    saving: false,
    canDoTasks: Ember.computed("can", function () {
      return this.get("can").can("do task");
    }),
    modelFull: Ember.computed("model.task.id", function () {
      return Ember.isEmpty(this.get("model.task.id")) ? {} : this.get("store").findRecord("task", this.get("model.task.id"));
    }),
    disabledSubmissionTaskComment: Ember.computed("model.name", "saving", function () {
      return this.get("model.name") ? !this.get("model.name").replace(/\s/g, '') : true || this.get("saving");
    }),
    close: function close() {
      Ember.$("#modal-task-commentCreate").modal('hide');
    },
    actions: {
      togglePriority: function togglePriority(value) {
        this.set("priorityID", value);
      },
      create: function create() {
        this.set('saving', true);
        var self = this;
        var comment = this.get('store').createRecord('taskComment', this.get('model'));
        comment.save().then(function () {
          self.close();
        }).catch(function () {
          self.set('saving', false);
        });
      },
      cancel: function cancel() {
        this.close();
      },
      reset: function reset() {
        this.set("model", {});
        this.set('saving', false);
      }
    }
  });

  _exports.default = _default;
});