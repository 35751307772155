define("crm/routes/organizations/organization/sheltereds/sheltered/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var sheltered = this.modelFor("organizations.organization.sheltereds.sheltered");
      return Ember.RSVP.hash({
        sheltered: sheltered,
        shelteredWeights: this.store.query("weight", {
          sheltered: sheltered.get('id')
        }),
        //shelteredPathologies: this.store.query('shelteredPathology', {sheltered: sheltered.get('id')}), TODO
        pathologies: this.store.findAll('pathology'),
        appointmentsBySheltered: this.store.query('appointment', {
          sheltered: sheltered.get('id'),
          organization: organization.get('id')
        }),
        customers: this.store.query('customer', {
          organization: organization.get("id")
        }),
        speciesList: this.store.findAll('species'),
        workers: this.store.query('worker', {
          organization: organization.get('id')
        }),
        diets: this.store.findAll('diet'),
        tails: this.store.findAll('tail') //shelteredPeriodics: this.store.query('periodic', {organization: organization.get('id'), sheltered: sheltered.get('id')}) TODO

      }).then(function (hashResult) {
        var p = hashResult.sheltered;
        Ember.set(p, "shelteredWeights", hashResult.shelteredWeights.sortBy('weighted').reverse()); // Ember.set(p, "shelteredPathologies", hashResult.shelteredPathologies.sortBy('date').reverse()); TODO

        Ember.set(p, "pathologies", hashResult.pathologies);
        Ember.set(p, "appointmentsBySheltered", hashResult.appointmentsBySheltered.sortBy('start').reverse());
        Ember.set(p, "customers", hashResult.customers);
        Ember.set(p, "speciesList", hashResult.speciesList);
        Ember.set(p, "workers", hashResult.workers);
        Ember.set(p, "diets", hashResult.diets);
        Ember.set(p, "tails", hashResult.tails); // Ember.set(p, "shelteredPeriodics", hashResult.shelteredPeriodics); TODO

        return p;
      });
    },
    actions: {
      /*getPathologies() {
          var model = this.modelFor("organizations.organization.sheltereds.sheltered");
           this.store.query('shelteredPathology', {sheltered: model.get('id')}).then(function(shelteredPathologies) {
              Ember.set(model, "shelteredPathologies", shelteredPathologies.sortBy('date').reverse());
          });
      },*/
      // TODO
      openEditSheltered: function openEditSheltered(shelteredToEdit) {
        this.send('openEditShelteredModal', shelteredToEdit);
      },
      openCreateAppointment: function openCreateAppointment(options) {
        this.send('openModalCreateAppointment', options);
      }
    }
  });

  _exports.default = _default;
});