define("crm/helpers/choice-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choiceDisplay = choiceDisplay;
  _exports.default = void 0;

  function choiceDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case true:
          return this.get('intl').t("icons.check");

        case false:
          return this.get('intl').t("icons.remove");

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});