define("crm/components/dashboard-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    priceListed: Ember.computed("model", function () {
      if (this.get("model")) {
        return this.get("model");
      } else {
        return 0;
      }
    }),
    percentage: Ember.computed("model", "oldValue", function () {
      var val = Number(this.get("model"));
      var valOld = Number(this.get("oldValue"));
      return val && valOld ? (val * 100 / valOld - 100).toFixed(2) : "";
    }),
    arrow: Ember.computed("model", "oldValue", function () {
      var val = Number(this.get("model"));
      var valOld = Number(this.get("oldValue"));
      return val && valOld ? val > valOld ? "up" : "down" : "";
    }),
    text: Ember.computed("model", "oldValue", "inverse", function () {
      var val = Number(this.get("model"));
      var valOld = Number(this.get("oldValue"));

      if (val && valOld) {
        if (this.get("inverse")) {
          return val < valOld ? "success" : "danger";
        } else {
          return val > valOld ? "success" : "danger";
        }
      } else {
        return "";
      }
    })
  });

  _exports.default = _default;
});