define("crm/routes/organizations/organization/stats/clinical/unscheduled", ["exports", "crm/routes/organizations/organization/stats/stats-totals"], function (_exports, _statsTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _statsTotals.default.extend({
    service: "/stats/clinical/consultations/unscheduled"
  });

  _exports.default = _default;
});