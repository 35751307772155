define("crm/components/item-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set('fields', [Ember.Object.create({})]);
    },
    reset: function reset() {
      this.set('fields', [Ember.Object.create({})]);
      this.close();
    },
    close: function close() {
      Ember.$('#openEntryItem').modal('hide');
    },
    actions: {
      newLine: function newLine() {
        this.get('fields').addObject(Ember.Object.create({}));
      },
      save: function save() {
        this.get('fields').forEach(function (field) {
          field.get('item.warehouseItems').forEach(function (warehouseItem) {
            if (!Ember.isEmpty(warehouseItem.get('quantityAux'))) {
              warehouseItem.set('quantity', warehouseItem.get('quantity') + Number(warehouseItem.get('quantityAux')));
              warehouseItem.save();
            }
          });
        });
        this.sendAction('reloadModel');
        this.reset();
      }
    }
  });

  _exports.default = _default;
});