define("crm/components/template-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    displayedValue: Ember.computed("model", function () {
      switch (this.get("model")) {
        case "yes":
        case "true":
          return this.get("intl").t("options.yes");

        case "no":
        case "false":
          return this.get("intl").t("options.no");

        default:
          return this.get("model");
      }
    })
  });

  _exports.default = _default;
});