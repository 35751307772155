define("crm/models/financial-account-close", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    financialAccount: _emberData.default.belongsTo("financial-account")
  });

  _exports.default = _default;
});