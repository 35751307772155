define("crm/components/internment/todo-new", ["exports", "moment", "crm/components/pu-crud-modal"], function (_exports, _moment, _puCrudModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _puCrudModal.default.extend({
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    periods: Ember.computed.alias("catalogs.todoPeriods"),

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        if (!this.get("model.started")) {
          this.set("model.started", (0, _moment.default)().startOf("h"));
        }
      }

      if (this.get("model")) {
        if (!this.get("model.period")) {
          this.set("model.period", 0);
        }
      }
    },

    /*
     * Computed
     */
    allowsDecimals: Ember.computed("organizationPet.usesDecimals", "model.internment.organization.id", function () {
      return this.get("organizationPet.usesDecimals") || ["70"].includes(this.get("model.internment.organization.id"));
    }),

    /*
     * Functions
     */
    close: function close() {
      this.toggleProperty("closed");
    },
    reset: function reset() {//
    },

    /*
     * Actions
     */
    actions: {
      setType: function setType(type) {
        if (type) {
          this.set("model.type", type);
          this.set("model.name", type.get("name"));
        } else {
          this.set("model.type", null);
          this.set("model.name", null);
        }
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});