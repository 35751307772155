define("crm/components/boxes-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    boxesWithStay: Ember.computed("model.boxes.[]", "model.stays.[]", function () {
      if (this.get("model.boxes") && this.get("model.stays.length")) {
        var result = {};
        this.get("model.stays").forEach(function (stay) {
          result[stay.get("box.id")] = stay;
        });
        return result;
      }
    }),
    aa: Ember.computed("model.boxes[]", "model.stays.[]", "boxesWithStay", function () {
      var result = [];
      var self = this;

      if (this.get("model.boxes")) {
        this.get("model.boxes").forEach(function (box) {
          var aux = {
            box: box
          }; //var stay = self.get("boxesWithStay")[box.get("id")];

          if (self.get("boxesWithStay") && self.get("boxesWithStay")[box.get("id")]) {
            var stay = self.get("boxesWithStay")[box.get("id")];
            var today = (0, _moment.default)().startOf("day");

            if (today.isSame(stay.get("startDate"), "d")) {
              aux["status"] = 1;
            } else if (today.isSame(stay.get("endDate"), "d")) {
              aux["status"] = 3;
            } else {
              aux["status"] = 2;
            }
          } else {
            aux["status"] = 0;
          }

          result.push(aux);
        });
      }

      return result;
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "status",
        "component": "show-box-status",
        "className": "column-small"
      }, {
        "propertyName": "box.name",
        "title": this.get('intl').t("boxes.name"),
        "className": "column-big"
      }, {
        "component": "boxes-actions",
        "className": "column-actions-2"
      }];
    }),
    actions: {
      openDetails: function openDetails(box) {
        this.sendAction("openModal", {
          entity: "box",
          action: "details",
          model: box
        });
      },
      editBoxModal: function editBoxModal(box) {
        this.sendAction("openModal", {
          entity: "box",
          action: "edit",
          model: box
        });
      },
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    }
  });

  _exports.default = _default;
});