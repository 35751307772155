define("crm/controllers/organizations/organization/purchase-orders/purchase-order/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    displayAnnulButton: Ember.computed("model.status", "currentWorker.isAdmin", "model.purchaseInvoices.[]", function () {
      return this.get("model.status") ? this.get("model.status") !== 8 && this.get("currentWorker.isAdmin") && this.get("model.purchaseInvoices.length") === 0 : false;
    }),
    canEdit: Ember.computed("model.status", "model.id", function () {
      return [0].includes(this.get("model.status"));
    }),
    requestsColumns: Ember.computed("intl", function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("purchaseRequests.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.purchase-requests.purchase-request",
        "routeProperty": "id"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get('intl').t("purchaseRequests.date")
      }, {
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("purchaseRequests.supplier"),
        "path": "organizations.organization.suppliers.supplier",
        "routeProperty": "supplier.id"
      }];
    }),
    invoiceColumns: Ember.computed("intl", function () {
      return [{
        "component": "purchase-invoice-status",
        "className": "column-icon"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get("intl").t("purchaseInvoices.new.date"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "title": this.get("intl").t("suppliers.supplier.title"),
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "className": "column-small",
        "path": "organizations.organization.suppliers.supplier",
        "routeProperty": "supplier.id"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("purchaseInvoices.new.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.purchase-invoices.purchase-invoice",
        "routeProperty": "id"
      }, {
        "title": this.get("intl").t("purchaseInvoices.new.paymentMethod"),
        "propertyName": "paymentMethod",
        "className": "column-price-medium"
      }, {
        "propertyName": "payableAmount",
        "title": this.get("intl").t("documents.payableAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "propertyName": "paidAmount",
        "title": this.get("intl").t("purchaseInvoices.new.paidAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }];
    }),
    actions: {
      annulDocument: function annulDocument(document) {
        this.send("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      },
      delete: function _delete() {
        var _this = this;

        var self = this;

        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("model").destroyRecord().then(function () {
            return _this.transitionToRoute("organizations.organization.purchase-orders");
          }, function (error) {
            return self.get("es").handle(error);
          });
        }
      },
      export: function _export() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseOrders/" + self.get("model.id") + "/xlsx",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = self.get("model.name") + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      },
      markDone: function markDone() {
        var self = this;

        if (confirm(this.get('intl').t("purchaseOrders.markedDone.message"))) {
          Ember.$.ajax({
            method: "PUT",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseOrders/" + self.get("model.id") + "/done",
            success: function success() {
              Ember.set(self.get("model"), "status", null);
              self.get('flashMessages').success(self.get('intl').t('purchaseOrders.updatedSuccess'));
              self.transitionToRoute("organizations.organization.purchase-orders");
            },
            error: function error(result) {
              self.get("es").handle(result);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});