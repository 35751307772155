define("crm/components/pet-datetime-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["pet-datetime-picker"],
    classNameBindings: ["slim"],
    resetHourToZero: false,

    /*
     * Hooks
     */
    init: function init() {
      this._super();

      if (this.get("seed")) {
        var tmp = (0, _moment.default)(this.get("seed"));
        this.set('minutes', tmp.minutes());
        this.set('hours', tmp.hours());
        this.set('date', tmp.format("YYYY-MM-DD"));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("seed")) {
        var tmp = (0, _moment.default)(this.get("seed"));
        this.set('minutes', tmp.minutes());
        this.set('hours', tmp.hours());
        this.set('date', tmp.format("YYYY-MM-DD"));
      } else {
        this.set('minutes', null);
        this.set('hours', null);
        this.set('date', null);
      }
    },

    /*
     * Computed
     */
    isCurrentHour: Ember.computed("date", "hours", "minutes", function () {
      if (this.get("date") && this.get("hours")) {
        var dateTime = (0, _moment.default)(this.get("date") + "T" + this.get("hours") + ":" + (this.get("minutes") || "00"), "YYYY-MM-DDTHH:mm");
        return (0, _moment.default)().isSame(dateTime, "hours");
      } else {
        return false;
      }
    }),
    isToday: Ember.computed("date", function () {
      return this.get("date") === (0, _moment.default)().format("YYYY-MM-DD");
    }),
    isTomorow: Ember.computed("date", function () {
      return this.get("date") === (0, _moment.default)().add(1, "days").format("YYYY-MM-DD");
    }),
    startHour: Ember.computed("start", "date", function () {
      return this.get('start') && (0, _moment.default)(this.get("date")).isSame(this.get('start'), "day") ? (0, _moment.default)(this.get("start")).format("HH") : "00";
    }),
    endHour: Ember.computed("end", "date", function () {
      if (!Ember.isEmpty(this.get("end")) && (0, _moment.default)(this.get("date")).isSame(this.get("end"), "day")) {
        return (0, _moment.default)(this.get("end")).format("HH");
      } else {
        return "23";
      }
    }),
    startMinutes: Ember.computed("start", "date", "hours", function () {
      if (this.get("start")) {
        return (0, _moment.default)(this.get("date") + "T" + this.get("hours"), "YYYY-MM-DDTHH").isSame(this.get("start"), "hour") ? (0, _moment.default)(this.get("start")).format("mm") : "00";
      }
    }),
    endMinutes: Ember.computed("end", "date", "hours", function () {
      if (!Ember.isEmpty(this.get("end")) && (0, _moment.default)(this.get("date") + "T" + this.get("hours"), "YYYY-MM-DDTHH").isSame(this.get("end"), "hour")) {
        return (0, _moment.default)(this.get("end")).format("mm");
      } else {
        return "59";
      }
    }),
    parsedStart: Ember.computed("start", function () {
      return this.get("start") ? (0, _moment.default)(this.get("start")).format("YYYY-MM-DD") : null;
    }),
    parsedEnd: Ember.computed("end", function () {
      return this.get("end") ? (0, _moment.default)(this.get("end")).format("YYYY-MM-DD") : null;
    }),

    /*
     * Functions
     */
    sendDatetime: function sendDatetime() {
      var dateTime = null;

      if (this.get("date") && this.get("hours") && this.get("minutes")) {
        dateTime = (0, _moment.default)(this.get("date") + "T" + this.get("hours") + ":" + this.get("minutes"), "YYYY-MM-DDTHH:mm");
      } else if (this.get("date") && this.get("hours")) {
        this.set("minutes", "00");
        dateTime = (0, _moment.default)(this.get("date") + "T" + this.get("hours") + ":" + "00", "YYYY-MM-DDTHH:mm");
      }

      this.get('action')(dateTime);
    },

    /*
     * Actions
     */
    actions: {
      setDateToday: function setDateToday() {
        this.send("setDate", (0, _moment.default)().format("YYYY-MM-DD"));
      },
      setDateTomorow: function setDateTomorow() {
        this.send("setDate", (0, _moment.default)().add(1, "days").format("YYYY-MM-DD"));
      },
      setDate: function setDate(date) {
        this.set("date", date);
        this.sendDatetime();
      },
      setHours: function setHours(hours) {
        this.set("hours", hours);

        if (this.get('hours') && Ember.isEmpty(this.get('minutes'))) {
          this.set('minutes', "00");
        }

        this.sendDatetime();
      },
      setMinutes: function setMinutes(minutes) {
        this.set("minutes", minutes);
        this.sendDatetime();
      },
      setCurrentTime: function setCurrentTime() {
        if (!this.get('disabled')) {
          var currentTime = (0, _moment.default)();
          this.set('date', currentTime.format("YYYY-MM-DD"));
          this.set('hours', this.get("resetHourToZero") ? "00" : currentTime.hours());
          this.set('minutes', this.get("resetMinutesToZero") ? "00" : currentTime.minutes());
          this.sendDatetime();
        }
      }
    }
  });

  _exports.default = _default;
});