define("crm/routes/organizations/organization/stats/sales/totals", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      after: {
        refreshModel: true
      },
      before: {
        refreshModel: true
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get('id');
      var createdAfter = this.modelFor("organizations.organization.stats").createdAfter;
      var createdBefore = this.modelFor("organizations.organization.stats").createdBefore; //var createdAfter2 = this.modelFor("organizations.organization.stats").createdAfter2;
      //var createdBefore2 = this.modelFor("organizations.organization.stats").createdBefore2;

      var begin = (0, _moment.default)(createdAfter).startOf("day").format();
      var end = (0, _moment.default)(createdBefore).endOf("day").format();
      return Ember.RSVP.hash({
        createdAfter: createdAfter,
        createdBefore: createdBefore,
        invoices: this.get("store").query("invoice", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        memos: this.get("store").query("memo", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        receipts: this.get("store").query("receipt", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        payments: this.get("store").query("payment", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        orders: this.get("store").query("order", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        quotation: this.get("store").query("quotation", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        }),
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          organization: organizationID,
          createdAfter: begin,
          createdBefore: end,
          status: null
        })
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("financialAccounts.financialAccount.totals")
      });
    }
  });

  _exports.default = _default;
});