define("crm/controllers/organizations/organization/listings/sales/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "itp",
        "title": this.get("intl").t("items.item.name")
      }, {
        "propertyName": "total",
        "component": "umt-price-display",
        "className": "column-big text-right",
        "title": this.get("intl").t("invoices.invoice.total")
      }];
    }),
    filteredModel: Ember.computed("model.[]", function () {
      // itp3.parent_id, itp3.id, itp2.id, itp.id, ROUND(IFNULL(SUM(il.linePrice), 0), 2) AS total, ROUND(IFNULL(SUM(il.linePriceWoVat), 0), 2) AS wov
      return this.get("model") ? this.get("model").map(function (x) {
        return {
          itp3: x[0],
          itp2: x[1],
          itp: x[2],
          total: x[3],
          wov: x[4]
        };
      }).sortBy("total").reverse() : null;
    })
  });

  _exports.default = _default;
});