define("crm/controllers/organizations/organization/update-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    selectedLeftItemType: null,
    selectedRightItemType: null,
    isLoading: false,
    actions: {
      updateItem: function updateItem(item) {
        if (this.get("selectedRightItemType.id")) {
          var self = this;
          this.set("isLoading", true);
          this.get("store").findRecord("item", item.get("id"), {
            reload: true
          }).then(function (savedItem) {
            savedItem.set("type", self.get("selectedRightItemType"));
            savedItem.save().then(function () {
              self.set("isLoading", false);
              self.updateRightItems();
              self.updateLeftItems();
            }).catch(function (e) {
              return self.get("es").handle(e);
            });
          });
        }
      },
      setLeftItemType: function setLeftItemType(value) {
        this.set("selectedLeftItemType", value);
        value ? this.updateLeftItems() : this.set("leftItems", []);
      },
      setRightItemType: function setRightItemType(value) {
        this.set("selectedRightItemType", value);
        value ? this.updateRightItems() : this.set("rightItems", []);
      }
    },
    updateLeftItems: function updateLeftItems() {
      var self = this;
      this.get("store").query("item", {
        company: this.get("model.company.id"),
        type: this.get("selectedLeftItemType.id")
      }).then(function (items) {
        return self.set("leftItems", items);
      });
    },
    updateRightItems: function updateRightItems() {
      var self = this;
      this.get("store").query("item", {
        company: this.get("model.company.id"),
        type: this.get("selectedRightItemType.id")
      }).then(function (items) {
        return self.set("rightItems", items);
      });
    }
  });

  _exports.default = _default;
});