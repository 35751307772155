define("crm/components/customer-field-type-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.label")
      }, {
        "propertyName": "editable",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.editable")
      }, {
        "propertyName": "mandatory",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.mandatory")
      }, {
        "propertyName": "unique",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.unique")
      }, {
        "propertyName": "type",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.type"),
        "component": "customer-field-type-display"
      }, {
        "component": "customer-field-type-actions"
      }];
    })
  });

  _exports.default = _default;
});