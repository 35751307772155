define("crm/components/debt-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    openItems: Ember.computed("model.debt.customer.id", function () {
      if (this.get("model.debt.customer.id")) {
        return this.get("store").query("openItem", {
          customer: this.get("model.debt.customer.id"),
          company: this.get("model.organization.company.id")
        });
      }
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      transitionToDocument: function transitionToDocument() {
        this.sendAction("transitionToRouteID", "organizations.organization." + this.get("model.debt.constructor.modelName") + "s." + this.get("model.debt.constructor.modelName"), this.get("model.debt.id"));
        this.close();
      }
    }
  });

  _exports.default = _default;
});