define("crm/models/shift", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    startDate: _emberData.default.attr("isodate"),
    endDate: _emberData.default.attr("isodate"),
    category: _emberData.default.belongsTo("item-type"),
    notes: _emberData.default.attr("string"),
    worker: _emberData.default.belongsTo("worker"),
    room: _emberData.default.belongsTo("room"),
    period: _emberData.default.attr("number"),
    periodType: _emberData.default.attr("string"),
    periodUntil: _emberData.default.attr("isodate")
  });

  _exports.default = _default;
});