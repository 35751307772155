define("crm/components/item/item-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setStatus: function setStatus(status) {
        var _this = this;

        this.set("model.enable", status);
        this.get("model").save().catch(function (r) {
          return _this.get("es").handle(r);
        });
      }
    }
  });

  _exports.default = _default;
});