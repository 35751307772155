define("crm/components/weights-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disableCreateBtn: false,

    /* INJECTS */
    store: Ember.inject.service(),

    /* LIFECYCLE */
    init: function init() {
      this._super();

      this.refreshComponent();
    },

    /* ACTIONS */
    actions: {
      createWeight: function createWeight() {
        this.set('disableCreateBtn', true);
        var self = this;
        this.set("newWeight.patient", this.get("patient"));
        var newWeight = this.get('store').createRecord('weight', this.get("newWeight"));
        newWeight.save().then(function (createdWeight) {
          self.refreshComponent();
          self.sendAction('createdWeight', createdWeight);
        }).catch(function () {
          self.set('disableCreateBtn', false);
          self.get('store').unloadRecord(newWeight);
        });
      },
      canceled: function canceled() {
        this.sendAction('canceledCreateWeight');
        this.refreshComponent();
      }
    },

    /* HELPERS */
    refreshComponent: function refreshComponent() {
      this.set('weightedSeed', (0, _moment.default)());
      this.set('newWeight', {
        weighted: (0, _moment.default)()
      });
      this.set('disableCreateBtn', false);
    }
  });

  _exports.default = _default;
});