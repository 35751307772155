define("crm/routes/organizations/organization/purchase-memos/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * route
   * organizations/organization/purchase-memos/new
   */
  var _default = Ember.Route.extend({
    queryParams: {
      purchaseOrder: {
        refreshModel: true
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      return this.store.createRecord("purchaseMemo", {
        organization: organization,
        stockDate: (0, _moment.default)().format("YYYY-MM-DD"),
        linesAux: []
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var self = this;
      var organization = model.get("organization");
      var organizationID = organization.get("id");
      var companyID = organization.get("company.id"); // var self = this;

      controller.set("financialAccounts", this.store.query("financial-account", {
        organization: organizationID
      }));
      controller.set("organizationPet", this.store.findRecord("organization-pet", organizationID));
      controller.set("organization", organization);
      controller.set("suppliers", organization.get("company.suppliers"));
      controller.set("purchaseTaxes", this.store.query("tax", {
        company: companyID,
        purchase: true
      }));
      this.store.query("warehouse", {
        organization: organizationID
      }).then(function (ww) {
        if (ww && ww.get("length") === 1) {
          model.set("warehouse", ww.get("firstObject"));
        }

        controller.set("warehouses", ww);
      });
      this.store.findAll("adjustment-reason").then(function (adjustmentReasons) {
        if (!organization.get("hasInternalConsumption")) {
          adjustmentReasons.removeObject(adjustmentReasons.filterBy("code", "05").objectAt(0));
        }

        controller.set("adjustmentReason", adjustmentReasons.filterBy("code", "10").objectAt(0));
      });

      if (controller.purchaseOrder) {
        this.store.findRecord("purchaseOrder", controller.purchaseOrder).then(function (foundPurchaseOrder) {
          if (foundPurchaseOrder) {
            model.set("supplier", foundPurchaseOrder.get("supplier") ? foundPurchaseOrder.get("supplier") : null);
            model.set("purchaseOrder", foundPurchaseOrder ? foundPurchaseOrder : null);
          }

          self.store.query("purchase-order-line", {
            purchaseOrder: controller.purchaseOrder
          }).then(function (purchaseOrderLines) {
            if (purchaseOrderLines) {
              purchaseOrderLines.forEach(function (line) {
                controller.set("showLoading", true);
                var newLine = {
                  item: line.get("item"),
                  index: model.get("linesAux.length"),
                  quantity: line.get("quantity")
                };
                self.get("store").query("purchase-memo-line", {
                  item: line.get("item").get("id"),
                  top: 1
                }).then(function (savedLines) {
                  self.get("store").query("item-unit", {
                    item: line.get("item").get("id")
                  }).then(function () {
                    self.store.query("supplier-item", {
                      item: line.get("item").get("id"),
                      supplier: foundPurchaseOrder.get("supplier.id")
                    }).then(function (supplierItems) {
                      if (!Ember.isEmpty(savedLines)) {
                        var lastLine = savedLines.objectAt(0);
                        Ember.set(newLine, "lastPurchaseMemoLine", lastLine);
                        Ember.set(newLine, "discount", lastLine.get("discount"));
                        Ember.set(newLine, "linePrice", lastLine.get("linePrice"));
                        Ember.set(newLine, "unitPrice", lastLine.get("unitPrice"));
                        Ember.set(newLine, "warehouse", self.get("model.warehouse"));
                        Ember.set(newLine, "discount2", lastLine.get("discount2"));
                        Ember.set(newLine, "discountMoney", lastLine.get("discountMoney"));
                        Ember.set(newLine, "lot", lastLine.get("lot"));
                        Ember.set(newLine, "quantityPromotion", lastLine.get("quantityPromotion"));
                        Ember.set(newLine, "shelfLife", lastLine.get("shelfLife") ? (0, _moment.default)(lastLine.get("shelfLife")).format("YYYY-MM-DD") : null);
                        Ember.set(newLine, "vat", lastLine.get("vat"));
                      }

                      if (foundPurchaseOrder.get("supplier.id")) {
                        Ember.set(newLine, "supplier", foundPurchaseOrder.get("supplier"));

                        if (Ember.isEmpty(supplierItems)) {
                          Ember.set(newLine, "supplierItem", null);
                        } else {
                          Ember.set(newLine, "supplierItem", supplierItems.objectAt(0));
                          Ember.set(newLine, "supplierId", supplierItems.objectAt(0).get("supplierId"));
                        }
                      }

                      if (line.get("quantity")) {
                        Ember.set(newLine, "quantity2", line.get("quantity"));
                      }

                      model.get("linesAux").addObject(newLine);
                    });
                  });
                });
                controller.set("showLoading", false);
              });
            }
          });
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("purchaseOrder", undefined);
      }
    },
    willTransition: function willTransition() {
      if (this.get("controller.model") && !this.get("controller.model.id")) {
        this.store.unloadRecord(this.get("controller.model"));
      }
    },
    actions: {
      openCreateItem: function openCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      }
    }
  });

  _exports.default = _default;
});