define("crm/controllers/organizations/organization/purchase-invoices/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ["purchaseOrder", "purchaseInvoice"],
    purchaseOrder: null,
    purchaseInvoice: null,
    reset: function reset() {
      this.set("purchaseOrder", null);
      this.set("purchaseInvoice", null);
      this.set("model.savedHomonym", null);
    },
    save: function save() {
      var _this = this;

      var self = this;
      this.set("model.saving", true);
      this.get("model").save().then(function (saved) {
        if (self.get("model.lines.length")) {
          Promise.all(self.get("model.lines").map(function (line) {
            line.save().then(function () {
              _this.transitionToRoute("organizations.organization.purchase-invoices.purchase-invoice.edit", saved.get("id"));

              _this.set("model.saving", false);
            }, function (error) {
              self.get("es").handle(error);

              _this.set("model.saving", false);
            });
          }));
        } else {
          _this.transitionToRoute("organizations.organization.purchase-invoices.purchase-invoice.edit", saved.get("id"));

          _this.set("model.saving", false);
        }
      }, function (error) {
        self.get("es").handle(error);

        _this.set("model.saving", false);
      });
    },
    setName2: function setName2() {
      var _this2 = this;

      this.set("model.saving", true);
      this.get("store").query("purchase-invoice", {
        company: this.get("model.organization.company.id"),
        supplier: this.get("model.supplier.id"),
        name: this.get("model.name")
      }).then(function (savedHomonymous) {
        if (savedHomonymous && savedHomonymous.get("firstObject.id") && savedHomonymous.get("firstObject.id") !== _this2.get("model.id")) {
          _this2.set("model.savedHomonym", savedHomonymous.get("firstObject"));

          _this2.set("model.saving", false);

          return;
        } else {
          _this2.set("model.savedHomonym", null); // to reset the already saved purchase invoice with the same name


          _this2.save();
        }
      });
    },
    actions: {
      setName: function setName(name) {
        this.set("model.name", name);

        if (name) {
          Ember.run.debounce(this, this.setName2, 2000);
        }
      },
      setField: function setField(field, value) {
        if ("name" === field) {
          this.send("setName", value);
          return;
        }

        if ("paid" === field) {
          if (value && !this.get("model.paidAmount")) {
            this.set("model.financialAccount", null);
            this.set("model.paidAmount", this.get("model.payableAmount"));
          } else {
            this.set("model.paymentMethod", null);
            this.set("model.paidAmount", null);
            this.set("model.financialAccount", null);
          }
        }

        if (typeof value === "string") {
          value = value.trim();
        }

        this.set("model." + field, value);

        if (this.get("model.name")) {
          Ember.run.debounce(this, this.save, 2000);
        }
      }
    }
  });

  _exports.default = _default;
});