define("crm/components/patient-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      editPatientModal: function editPatientModal(record) {
        this.sendAction('openEditPatientModal', record);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
        this.sendAction("update", record);
      },
      makeSale: function makeSale(record) {
        var queryParams = {
          patientId: record.get("id"),
          customerId: record.get("owner.id")
        };
        this.sendAction("makeSale", queryParams);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
        this.sendAction("update", record);
      },
      createAppointmentModal: function createAppointmentModal(record) {
        this.sendAction('openCreateAppointmentModal', record);
      }
    }
  });

  _exports.default = _default;
});