define("crm/components/purchase-order-lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["u-models-table"],
    actions: {
      delete: function _delete(line) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          line.item.exists = false;
          this.get("lines").removeObject(line);
        }
      }
    }
  });

  _exports.default = _default;
});