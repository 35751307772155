define("crm/controllers/organizations/organization/stats/sales/overview/item-types/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    appointmentTypes: Ember.computed.alias("catalogs.appointmentType"),
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    modelStatus: Ember.computed("model.itemTypes.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("model.itemTypes.totalByStatus") ? [] : this.get("model.itemTypes.totalByStatus").map(function (s) {
        return {
          type: _this.get("appointmentTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("model.total") + "%")
        };
      });
    }),
    waits: Ember.computed("model", function () {
      if (!this.get("model")) {
        return [];
      }

      return {
        labels: ["checkin", "visit", "checkout"],
        datasets: [{
          data: [this.get("model.checkinWait"), this.get("model.visitWait"), this.get("model.checkoutWait")],
          backgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"],
          hoverBackgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"]
        }]
      };
    }),
    itemsTodayByType: Ember.computed("model.itemTypes.itemsToday.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsToday"));
    }),
    itemsThisWeekByType: Ember.computed("model.itemTypes.itemsThisWeek.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsThisWeek"));
    }),
    itemsThisMonthByType: Ember.computed("model.itemTypes.itemsThisMonth.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsThisMonth"));
    }),
    itemsThisYearByType: Ember.computed("model.itemTypes.itemsThisYear.[]", function () {
      return this.waitByType(this.get("model.itemTypes.itemsThisYear"));
    }),
    creatorsToday: Ember.computed("model.invoiceCreators.creatorsToday.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsToday"));
    }),
    creatorsThisWeek: Ember.computed("model.invoiceCreators.creatorsThisWeek.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisWeek"));
    }),
    creatorsThisMonth: Ember.computed("model.invoiceCreators.creatorsThisMonth.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisMonth"));
    }),
    creatorsThisYear: Ember.computed("model.invoiceCreators.creatorsThisYear.[]", function () {
      return this.waitByType(this.get("model.invoiceCreators.creatorsThisYear"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[0],
          quantity: s[1],
          price: s[2]
        };
      });
    }
  });

  _exports.default = _default;
});