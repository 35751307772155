define("crm/components/pu-crud-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),

    /*
     * Computed
     */
    disableBtn: Ember.computed("saving", function () {
      return this.get("saving");
    }),

    /*
     * Functions
     */
    error: function error(entity, result) {
      this.get("es").errorReturned(result);
      this.get("store").unloadRecord(entity);
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    save: function save() {
      if (this.get("modelName")) {
        var self = this;
        this.set("saving", true);
        var entity = this.get("store").createRecord(this.get("modelName"), this.get("model"));
        entity.save().then(function () {
          return self.close();
        }).catch(function (result) {
          return self.error(entity, result);
        }).finally(function () {
          return self.set("saving", false);
        });
      } else {
        this.get("es").handle("There is a bug in CURD Modal, please tell Pet Universal about it!");
      }
    },

    /*
     * Actions
     */
    actions: {
      save: function save() {
        this.save();
      },
      reset: function reset() {// just because you fell
      },
      cancel: function cancel() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});