define("crm/components/stats-catalogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    backgroundColor: ["#a3e1d4", "#b5b8cf", "#dedede", '#87d6c6', "#d3d3d3", "#79d2c0", "#bababa", '#54cdb4', '#e4f0fb', "#1ab394"],
    chartOptions: Ember.computed("hideLegend", function () {
      if (this.get("hideLegend")) {
        return {
          legend: {
            display: false
          }
        };
      } else {
        return {};
      }
    }),
    usedCatalog: Ember.computed("model.catalog.[]", "model.model1.[]", "model.model2.[]", function () {
      if (Ember.isEmpty(this.get("model.catalog.length")) || !this.get("model.model1") && !this.get("model.model2")) {
        return null;
      }

      var ids = [];
      this.get("model.model1").forEach(function (m) {
        if (!ids.includes("" + m[0])) {
          ids.push("" + m[0]);
        }
      });
      this.get("model.model2").forEach(function (m) {
        if (!ids.includes("" + m[0])) {
          ids.push("" + m[0]);
        }
      });
      return this.get("model.catalog").filter(function (c) {
        return ids.includes(Ember.get(c, "id"));
      });
    }),
    catalogMap: Ember.computed("usedCatalog.[]", "catalogMapOverride.[]", function () {
      if (this.get("catalogMapOverride")) {
        return this.get("catalogMapOverride");
      }

      if (!this.get("usedCatalog") || Ember.isEmpty(this.get("usedCatalog"))) {
        return null;
      }

      var result = {
        0: {
          id: 0,
          name: "null"
        }
      };
      this.get("usedCatalog").forEach(function (catalogItem) {
        result[catalogItem.get("id")] = catalogItem;
      });
      return result;
    }),
    data1: Ember.computed("catalogMap.[]", "model.model1.[]", function () {
      var _this = this;

      if (!Object.keys(this.get("catalogMap"))) {
        return null;
      }

      var self = this;
      var result = {
        0: {
          name: " N.A.",
          quantity: 0,
          value: 0,
          type: null
        }
      };
      var total = 0;
      this.get("usedCatalog").forEach(function (catalogItem) {
        result[catalogItem.get("id")] = {
          name: catalogItem.get("name"),
          quantity: 0,
          value: 0,
          type: catalogItem
        };
      });
      this.get("model.model1").forEach(function (rawData) {
        if (!rawData[0]) {
          rawData[0] = 0;
        }

        var catalogItemID = Ember.get(self.get("catalogMap")[rawData[0]], "id");
        result[catalogItemID].quantity += rawData[1];
        result[catalogItemID].value += rawData[2];
        total += result[catalogItemID][_this.get("chartFor") || "quantity"];
      });
      this.set("total1", total);
      return result;
    }),
    data2: Ember.computed("catalogMap.[]", "model.model2.[]", function () {
      var _this2 = this;

      if (!Object.keys(this.get("catalogMap"))) {
        return null;
      }

      var self = this;
      var result = {
        0: {
          name: " N.A.",
          quantity: 0,
          value: 0,
          type: null
        }
      };
      var total = 0;
      this.get("usedCatalog").forEach(function (catalogItem) {
        result[catalogItem.get("id")] = {
          name: catalogItem.get("name"),
          quantity: 0,
          value: 0,
          type: catalogItem
        };
      });
      this.get("model.model2").forEach(function (rawData) {
        if (!rawData[0]) {
          rawData[0] = 0;
        }

        var catalogItemID = Ember.get(self.get("catalogMap")[rawData[0]], "id");
        result[catalogItemID].quantity += rawData[1];
        result[catalogItemID].value += rawData[2];
        total += result[catalogItemID][_this2.get("chartFor") || "quantity"];
      });
      this.set("total2", total);
      return result;
    }),
    diffs: Ember.computed("data1.[]", "data2.[]", function () {
      var _this3 = this;

      if (Ember.isEmpty(this.get("model.model1"))) {
        return [];
      }

      var result = Object.keys(this.get("data1")).map(function (k) {
        var d1 = _this3.get("data1")[k];

        var d2 = _this3.get("data2")[k];

        if (d2) {
          return {
            name: d1.name,
            quantity: d1.quantity,
            value: d1.value,
            quantityOld: d2.quantity,
            valueOld: d2.value
          };
        } else {
          return d1;
        }
      });
      return result.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    values1: Ember.computed("data1.[]", function () {
      var _this4 = this;

      return !this.get("data1") ? null : Object.keys(this.get("data1")).map(function (k) {
        return _this4.get("data1")[k];
      }).sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    values2: Ember.computed("data2.[]", function () {
      var _this5 = this;

      return !this.get("data2") ? null : Object.keys(this.get("data2")).map(function (k) {
        return _this5.get("data2")[k];
      }).sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }),
    chartData1: Ember.computed("values1.[]", function () {
      var _this6 = this;

      return {
        labels: this.get("values1") ? this.get("values1").map(function (x) {
          return x.name;
        }) : [],
        datasets: this.get("values1") ? [{
          data: this.get("values1").map(function (x) {
            return Ember.get(x, _this6.get("chartFor") || "quantity") ? Ember.get(x, _this6.get("chartFor") || "quantity").toFixed(2) : "";
          }),
          backgroundColor: this.get("backgroundColor")
        }] : []
      };
    }),
    chartData2: Ember.computed("values2.[]", function () {
      var _this7 = this;

      return {
        labels: this.get("values2") ? this.get("values2").map(function (x) {
          return x.name;
        }) : [],
        datasets: this.get("values2") ? [{
          data: this.get("values2").map(function (x) {
            return Ember.get(x, _this7.get("chartFor") || "quantity") ? Ember.get(x, _this7.get("chartFor") || "quantity").toFixed(2) : "";
          }),
          backgroundColor: this.get("backgroundColor")
        }] : []
      };
    }),
    columns1: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "quantity",
        "title": "#",
        "className": "column-id"
      }];
    }),
    columns2: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": "",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "quantity",
        "title": "#",
        "className": "column-id"
      }];
    }),
    columnsDiff: Ember.computed(function () {
      return [{
        "propertyName": "quantity",
        "title": "#",
        "className": "column-price"
      }, {
        "propertyName": "value",
        "title": "€",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "propertyName": "diff",
        "title": "%"
      }];
    }),
    palette: function palette(hex, size) {
      var result = [];

      for (var i = 1; i <= size; i++) {
        result.push(this.getGradientColor(hex, "#FFF", i / 100));
      }

      return result;
    },
    getGradientColor: function getGradientColor(start_color, end_color, percent) {
      // strip the leading # if it's there
      start_color = start_color.replace(/^\s*#|\s*$/g, '');
      end_color = end_color.replace(/^\s*#|\s*$/g, ''); // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`

      if (start_color.length == 3) {
        start_color = start_color.replace(/(.)/g, '$1$1');
      }

      if (end_color.length == 3) {
        end_color = end_color.replace(/(.)/g, '$1$1');
      } // get colors


      var start_red = parseInt(start_color.substr(0, 2), 16),
          start_green = parseInt(start_color.substr(2, 2), 16),
          start_blue = parseInt(start_color.substr(4, 2), 16);
      var end_red = parseInt(end_color.substr(0, 2), 16),
          end_green = parseInt(end_color.substr(2, 2), 16),
          end_blue = parseInt(end_color.substr(4, 2), 16); // calculate new color

      var diff_red = end_red - start_red;
      var diff_green = end_green - start_green;
      var diff_blue = end_blue - start_blue;
      diff_red = (diff_red * percent + start_red).toString(16).split('.')[0];
      diff_green = (diff_green * percent + start_green).toString(16).split('.')[0];
      diff_blue = (diff_blue * percent + start_blue).toString(16).split('.')[0]; // ensure 2 digits by color

      if (diff_red.length == 1) diff_red = '0' + diff_red;
      if (diff_green.length == 1) diff_green = '0' + diff_green;
      if (diff_blue.length == 1) diff_blue = '0' + diff_blue;
      return '#' + diff_red + diff_green + diff_blue;
    }
  });

  _exports.default = _default;
});