define("crm/controllers/organizations/organization/internments/internment/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    displayDiagnosisButtons: false,
    classNameBindings: ['criticalStatus'],
    attributeBindings: ["idx"],
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    currentWorker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    id: Ember.computed("model.id", function () {
      return "internemnt-" + this.get("model.id");
    }),
    background: Ember.computed("model.criticalStatus", function () {
      switch (this.get("model.criticalStatus")) {
        case 1:
          return "alert-danger";

        case 2:
          return "alert-warning";

        default:
          return "white-bg";
      }
    }),
    todaysSymptoms: Ember.computed("model.internmentStatus.chat.messages.@each.created", "chartDateInit", "chartDateEnd", function () {
      var self = this;

      if (this.get("model.internmentStatus.chat.messages")) {
        return this.get("model.internmentStatus.chat.messages").filter(function (symptom) {
          return !Ember.isEmpty(symptom.get("created")) && (0, _moment.default)(symptom.get("created")).isSameOrAfter((0, _moment.default)(self.get("chartDateInit")).subtract(1, "d"));
        }).sortBy("created").reverse();
      }
    }),
    actions: {
      callModal: function callModal(container) {
        this.send("openModal", container);
      },
      callShoppingCartEdit: function callShoppingCartEdit() {
        var model = {
          shoppingCart: this.get("model.shoppingCart"),
          customer: this.get("model.patient.owner"),
          hidePayButton: true
        };
        this.send("openModal", {
          entity: "shopping-cart",
          action: "edit",
          model: model
        });
      },
      count: function count() {
        var self = this;

        if (self.get("model.shoppingCart.lines.length")) {
          self.get("store").findRecord("internment-shopping-cart", self.get("model.shoppingCart.id"), {
            reload: true
          }).then(function (currentShoppingCart) {
            currentShoppingCart.set("status", 0);
            currentShoppingCart.save();
            self.get("store").createRecord("internment-shopping-cart", {
              internment: self.get("model"),
              organization: self.get("model.organization"),
              patient: self.get("model.patient"),
              customer: self.get("model.patient.owner")
            }).save().then(function (savedCart) {
              var model = {
                shoppingCart: currentShoppingCart,
                organization: self.get("model.organization"),
                patient: self.get("model.patient"),
                customer: self.get("model.patient.owner"),
                hidePayButton: true
              };
              self.send("openModal", {
                entity: "shopping-cart",
                action: "edit",
                model: model
              });
              self.set("model.shoppingCart", savedCart);
            });
          });
        }
      },
      callWeightNew: function callWeightNew() {
        this.send("openModal", {
          entity: "weight",
          action: "new",
          model: this.get("model.patient")
        });
      },
      transitionToClinicalReport: function transitionToClinicalReport() {
        this.send("transitionToRoute", "organizations.organization.internments.internment.clinical-report");
      },
      shoppingCartsList: function shoppingCartsList() {
        this.send("transitionToRoute", "organizations.organization.internments.internment.shoppingCarts");
      },
      saveDiagnosis: function saveDiagnosis() {
        var self = this;
        var internmentStatus = this.get("store").peekRecord("internmentStatus", this.get("model.internmentStatus.id"));
        internmentStatus.set("creator", this.get("currentUser"));
        internmentStatus.save().then(function () {
          self.set("displayDiagnosisButtons", false);
        }).catch(function (result) {
          self.get("es").handle(result);
        });
      },
      cancelDiagnosis: function cancelDiagnosis() {
        if (confirm(this.get("intl").t("intl.unsavedChanges"))) {
          this.set("displayDiagnosisButtons", false);
          var internmentStatus = this.get("store").peekRecord("internmentStatus", this.get("model.internmentStatus.id"));
          internmentStatus.rollbackAttributes();
        }
      },
      displayDiagnosisButtons: function displayDiagnosisButtons() {
        this.set("displayDiagnosisButtons", true);
      },
      dischargeSchedule: function dischargeSchedule() {
        this.send("callModal", {
          entity: "internment",
          action: "discharge",
          model: this.get("model")
        });
      },
      dischargeText: function dischargeText() {
        this.send("callModal", {
          entity: "internment",
          action: "discharge-text",
          model: this.get("model")
        });
      },
      dischargeUnschedule: function dischargeUnschedule() {
        var self = this;

        if (confirm(this.get("intl").t("intl.revertDischarge"))) {
          this.set("model.discharger", undefined);
          this.set("model.dischargeDate", undefined);
          this.get("model").save().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      finish: function finish() {
        this.send("callModal", {
          entity: "internment",
          action: "finish",
          model: this.get("model")
        });
      },
      reopen: function reopen() {
        var self = this;

        if (confirm(this.get("intl").t("internments.details.reopenCheck"))) {
          this.set("model.end", undefined);
          this.set("model.endReason", undefined);
          this.get("model").save().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      callDischargeLetter: function callDischargeLetter() {
        this.send("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "dischargeLetter",
            patient: this.get("model.patient"),
            worker: this.get("currentWorker"),
            values: [{
              value: this.get("model.dischargeText")
            }]
          }
        });
      }
    }
    /* *********************************************************************************************************************
    
    startChanged: observer('start', function() {
        this.set("chartDateInit", moment(this.get("chartDateInit")).startOf('day').hours(this.get("start")));
    }),
      actions: {
        update() {
            if (this.get('model.hasDirtyAttributes')) {
                this.get('model').save();
            }
        },
         cancel() {
            if (isEmpty(this.get("model"))) {
             } else {
                if (confirm(this.get('intl').t("intl.unsavedChanges"))) {
                    this.get('model').rollbackAttributes();
                }
            }
        },
         openShoppingCartLineCreate() {
            this.send("openModal", {entity: "shoppingCart-line", action: "create", model: {shoppingCart: this.get("model.shoppingCart"), items: this.get("model.items")}});
        },
           back() {
            this.sendAction("cancel");
        },
         closeInternment(internmentID) {
            var self = this;
             if (confirm(this.get('intl').t('intl.close'))) {
                self.get('store').findRecord('internment', internmentID).then(function(internment) {
                    internment.set('ended', new Date());
                    internment.set('status', 1);
                    internment.set('dischargeDate', new Date());
                    internment.set('discharger', self.get("currentUser"));
                     internment.save().then(function () {
                        if(self.get('deceased')) {
                            self.get('store').findRecord('patient', self.get('model.patient.id')).then(function(animal) {
                                animal.set('deceased', self.get('deceasedDate'));
                                animal.save()
                                    .catch(function() {
                                    });
                            });
                        }
                        self.sendAction('removeInternment', internment);
                     }).catch(function() {
                    });
                });
            }
        },
         toPerson(personID) {
            this.sendAction('toPerson', personID);
        },
         discharge() {
            var self = this;
             this.set('model.status', 1);
            this.set('model.discharger', self.get('discharger'));
            this.set('model.dischargeDate', self.get('dischargeDate'));
             this.get('model').save().then(function() {
                self.set('displayDischarge', false);
                self.closeDischargeModal();
             }).catch(function() {
                self.set('displayDischarge', true);
            });
        },
           lockInternment() {
            var self = this;
             this.get('model').set('locked', true);
            this.get('model').set('blocker', self.get('currentUser'));
            this.get('model').save()
                .then(function() {
                 }).catch(function() {
                 });
        },
         unlockInternment() {
            this.get('model').set('locked', false);
            this.get('model').set('blocker', undefined);
            this.get('model').save()
                .then(function() {
                 }).catch(function() {
                 });
        },
         setDischargerSelectAnswer(value) {
            this.set('discharger', this.get('store').peekRecord('clinic-person', value));
        },
         setDischargeDate(datetimeStr) {
            this.set("dischargeDate", datetimeStr);
        },
           openConsumptionsModal() {
            $('#consumptionsModal').modal();
        },
         closeConsumptionsModal() {
            $('#consumptionsModal').modal('hide');
        },
         openModalAssociatePatient(){
            $('#openAssociatePatientOnAppointment').modal();
        },
         openShoppingCartDetails() {
            this.send("openModal", {entity: "open-shopping-carts", action: "details", model: this.get("shoppingCart")});
        },
         // Diagnosis
        saveDiagnosis() {
            var self = this;
            var internmentStatus = this.get('store').peekRecord('internment-status', this.get('model.internmentStatus.id'));
             internmentStatus.save()
                .then(function() {
                    self.set('displayDiagnosisButtons', false);
                 }).catch(function() {
                 });
        },
         cancelDiagnosis() {
            if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
                this.set('displayDiagnosisButtons', false);
                var internmentStatus = this.get('store').findRecord('internment-status', this.get('model.InternmentStatus.id'));
                internmentStatus.rollbackAttributes();
            }
        },
         displayDiagnosisButtons() {
            this.set('displayDiagnosisButtons', true);
        },
    
           openTaskCreate(type, task) {
            if(this.get('model.shouldBeLocked')) {
                return;
            }
             this.set('newTaskType', type);
            this.set('taskModalModel', (isEmpty(task) ? {internment: this.get('model')} : task));
             $('#taskCreateModal').modal({backdrop: 'static', keyboard: false});
        },
         cancelTaskCreate() {
            $("#taskCreateModal").modal("hide");
        },
    
         transitionToActionDetails(clinicID, internmentID, taskID, actionID, taskType) {
            this.sendAction("transitionToActionDetails", clinicID, internmentID, taskID, actionID, taskType);
        },
           finishAndPay() {
            var self = this;
             var internment = this.store.peekRecord("internment", this.get("model.id"));
             this.set("model.open", false);
             if(self.get("model.organization.salesFeature")) {
                internment.set("status", 1);
             } else {
                if(this.get("model.shoppingCart.id") != null) {
                    this.set("model.shoppingCart.status", 1);
                    this.get("model.shoppingCart").save();
                }
                internment.set("status", 1);
            }
             internment.set("end", moment());
             this.get("model").save();
             internment.save()
                .then(function() {
                    $("#openFinishedConsultationModal").modal("hide");
                     if(self.get("model.organization.salesFeature")) {
                        var queryParams = {
                            customerId: self.get("model.patient.owner.id"),
                            patientId: self.get("model.patient.id"),
                            shoppingCartId: self.get("model.shoppingCart.id"),
                            internmentId: self.get("model.id")
                        };
                         self.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
                    } else {
                        self.send("transitionToRoute", "organizations.organization.waitingRooms");
                    }
                });
        },
         finishInternment() {
            var self = this;
             var internment = this.store.peekRecord("internment", this.get("model.id"));
             this.set("model.open", false);
             if(this.get("model.organization.salesFeature")) {
                internment.set("status", 1);
             } else {
                if(this.get("model.shoppingCart.id") != null) {
                    this.set("model.shoppingCart.status", 1);
                    this.get("model.shoppingCart").save();
                }
                internment.set("status", 1);
            }
             internment.set("end", moment());
             this.get("model").save();
             internment.save().then(function() {
                $('#openFinishedConsultationModal').modal('hide');
                self.send("transitionToRoute", "organizations.organization.internments");
            });
         },
         transitionToClinicalReport() {
            this.send("transitionToRoute", "organizations.organization.internments.internment.reports.clinicalReport");
        }
    },
     closeDischargeModal() {
        $('#dischargeModal').modal('hide');
        this.set("dischargeDate", undefined);
    }*/

  });

  _exports.default = _default;
});