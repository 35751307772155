define("crm/components/compound-product-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("items.item.name"),
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "originalRetailPrice",
        "title": this.get('intl').t("items.item.pvp")
      }, {
        "component": "compound-product-actions"
      }];
    }),
    actions: {
      openDetails: function openDetails(compoundProduct) {
        this.sendAction("openDetails", {
          entity: "compound-product",
          action: "details",
          model: compoundProduct
        });
      },
      openEditItemModal: function openEditItemModal(compoundProduct) {
        this.sendAction('openEditItem', compoundProduct);
      }
    }
  });

  _exports.default = _default;
});