define("crm/models/note", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    started: _emberData.default.attr('isodate'),
    patient: _emberData.default.belongsTo('patient'),
    type: _emberData.default.belongsTo('note-type'),
    outcome: _emberData.default.belongsTo('outcome-type'),
    contact: _emberData.default.belongsTo('contact'),
    customer: _emberData.default.belongsTo('customer')
  });

  _exports.default = _default;
});