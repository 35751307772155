define("crm/models/quotation-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    quotation: _emberData.default.belongsTo('quotation', {
      inverse: 'lines'
    })
  });

  _exports.default = _default;
});