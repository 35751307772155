define("crm/components/open-shopping-carts-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    invoiceShoppingCarts: [],
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        var self = this;
        Ember.RSVP.hash({
          shoppingCart: this.get("store").findRecord("shopping-cart", this.get("model.id"), {
            reload: true
          }),
          invoiceShoppingCart: this.get("store").query("invoice-shopping-cart", {
            shoppingCart: this.get("model.id")
          }),
          lines: this.get("store").query("shopping-cart-line", {
            shoppingCart: this.get("model.id")
          })
        }).then(function (r) {
          self.set("invoiceShoppingCarts", r.invoiceShoppingCart);
          self.set("lines", r.lines);
        });
      }
    },
    billable: Ember.computed("organization.salesFeature", "model.status", "model.customer.enabled", function () {
      return this.get("organization.salesFeature") && this.get("model.status") !== 1 && this.get("model.status") != 3 && this.get("model.customer.enabled");
    }),
    payable: Ember.computed("organization.salesFeature", "model.status", function () {
      return !this.get("organization.salesFeature") && this.get("model.status") !== 1;
    }),
    deletable: Ember.computed("model.status", "model.appointment.id", "model.appointment.status", function () {
      return (Ember.isEmpty(this.get("model.appointment.id")) || this.get("model.appointment.status") > 5) && this.get("model.status") != 1 && this.get("model.status") != 3;
    }),
    editable: Ember.computed("model.status", function () {
      return this.get("model.status") != 1 && this.get("model.status") != 3;
    }),
    close: function close() {
      Ember.$("#modal-open-shopping-carts-details").modal("hide");
      Ember.$("#openAppointmentDetails").modal("hide");
    },
    actions: {
      reopen: function reopen() {
        if (this.get("model.invoiceShoppingCarts")) {
          this.get("model.invoiceShoppingCarts").forEach(function (e) {
            return e.destroyRecord();
          });
        }

        this.set("model.status", 0);
        this.get("model").save();
      },
      checkPaid: function checkPaid(model) {
        if (confirm(this.get('intl').t("shoppingCarts.markAsPaid"))) {
          this.set("disabledAction1", true);
          var self = this;
          model.set("status", 1);
          model.save().then(function () {
            self.sendAction("reloadModel");
            self.set("disabledAction1", false);
          });
        }
      },
      callShoppingCartEdit: function callShoppingCartEdit() {
        this.close();
        this.sendAction("openModal", {
          entity: "shopping-cart",
          action: "edit",
          model: {
            shoppingCart: this.get("model"),
            hidePayButton: true
          }
        });
      },
      goToPos: function goToPos() {
        var model = this.get("model");
        var queryParams = {
          shoppingCartId: model.get("id"),
          customerId: model.get("customer.id"),
          patientId: model.get("patient.id")
        };

        if (!Ember.isEmpty(this.get("model.consultation.id"))) {
          this.get("store").findRecord("consultation", this.get("model.consultation.id"), {
            reload: true
          }).then(function (consultation) {
            queryParams.patientId = consultation.get("patient.id");
          });
        }

        this.close();

        if (queryParams) {
          this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
        } else {
          this.sendAction("transitionToRoute", "organizations.organization.pointOfSales");
        }
      },
      markAsPaid: function markAsPaid() {
        if (confirm(this.get('intl').t("shoppingCarts.markAsPaid"))) {
          var self = this;
          this.get("model").set("status", 1);
          this.get("model").save().then(function () {
            self.sendAction("reloadModel");
            self.close();
          }).catch(function (result) {
            self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
          });
        }
      },
      shopingCartDetails: function shopingCartDetails() {
        this.close();
        this.sendAction("transitionToRouteID", "organizations.organization.shopping-cart", this.get("model").get("id"));
      },
      transitionToInvoice: function transitionToInvoice(id) {
        this.close();
        this.sendAction("transitionToRouteID", "organizations.organization.invoices.invoice", id);
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          this.get("store").peekRecord("shoppingCart", this.get("model.id")).destroyRecord().then(function () {
            self.sendAction("reloadModel");
            self.close();
          }).catch(function (result) {
            self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
          });
        }
      }
    }
  });

  _exports.default = _default;
});