define("crm/routes/companies/company/employees", ["exports", "universe-gui/routes/companies/company/employees"], function (_exports, _employees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _employees.default;
    }
  });
});