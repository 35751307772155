define("crm/routes/organizations/organization/listings/sales/shopping-carts", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      var since = (0, _moment.default)(dateFrom).startOf("day").format();
      var until = (0, _moment.default)(dateTo).endOf("day").format();
      return this.get("store").query("shopping-cart", {
        organization: organizationID,
        createdAfter: since,
        createdBefore: until
      });
    }
  });

  _exports.default = _default;
});