define("crm/routes/organizations/organization/purchase-invoices/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    es: Ember.inject.service(),
    resetController: function resetController(controller) {
      controller.reset();
    },
    model: function model(params) {
      var organization = this.modelFor("organizations.organization");
      var model = this.store.createRecord("purchaseInvoice", {
        organization: organization,
        status: 0,
        stockDate: (0, _moment.default)().format("YYYY-MM-DD"),
        warehouse: organization.get("warehouses.firstObject")
      });

      if (params && params.purchaseInvoice) {
        var r = this.clone(model, "purchaseInvoice", params.purchaseInvoice);
        params.purchaseInvoice = undefined;
        return r;
      } else if (params && params.purchaseOrder) {
        var _r = this.clone(model, "purchaseOrder", params.purchaseOrder);

        params.purchaseOrder = undefined;
        return _r;
      } else {
        return model;
      }
    },
    clone: function clone(model, srcDocType, srcDocID) {
      var _this = this;

      var self = this;
      var options = {};
      options[srcDocType] = srcDocID;
      return Ember.RSVP.hash({
        document: this.store.findRecord(srcDocType, srcDocID),
        documentLines: this.store.query(srcDocType + "Line", options),
        items: this.store.query("item", options),
        itemUnits: this.store.query("item-unit", options),
        supplierItems: this.get("store").query("supplier-item", options)
      }).then(function (hash) {
        var placement = 1;
        var supplierItemsMap = {};

        if (hash.supplierItems && hash.supplierItems.get("length")) {
          hash.supplierItems.forEach(function (si) {
            return supplierItemsMap[si.get("item.id")] = si;
          });
        }

        model.set("supplier", hash.document.get("supplier"));
        model.set("warehouse", hash.document.get("warehouse"));

        if (hash.documentLines && hash.documentLines.get("length")) {
          hash.documentLines.sortBy("name").sortBy("id").sortBy("placement").forEach(function (line) {
            var newLine = self.cloneLine(line, model);
            newLine.placement = placement++;
            newLine.warehouse = newLine.item.get("stockable") ? hash.document.get("warehouse") : null;
            newLine.purchaseInvoice = model;
            newLine[srcDocType + "Line"] = line;

            _this.get("store").createRecord("purchaseInvoiceLine", newLine); // Frontend only


            newLine.supplierId = supplierItemsMap[line.get("item.id")] ? supplierItemsMap[line.get("item.id")].get("supplierId") : null;
          });
        }

        model.set(srcDocType, hash.document); // Frontend only

        return model;
      });
    },
    cloneLine: function cloneLine(oldLine) {
      return {
        item: oldLine.get("item"),
        name: oldLine.get("item.name"),
        quantity: oldLine.get("quantity"),
        quantityPromotion: oldLine.get("quantityPromotion"),
        vat: oldLine.get("tax"),
        unitPrice: oldLine.get("unitPrice"),
        unitPriceWithVat: oldLine.get("unitPriceWithVat"),
        discount: oldLine.get("discount"),
        discount2: oldLine.get("discount2"),
        linePrice: oldLine.get("linePrice"),
        linePriceWithVat: oldLine.get("linePrice") * (100 + oldLine.get("tax.vat.amount")) / 100,
        purchasePrice: oldLine.get("purchasePrice"),
        itemUnit: oldLine.get("item.purchaseItemUnit")
      };
    }
  });

  _exports.default = _default;
});