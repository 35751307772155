define("crm/models/todo", ["exports", "ember-data", "moment", "crm/models/abstract-model"], function (_exports, _emberData, _moment, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    description: _emberData.default.attr("string"),
    ended: _emberData.default.attr("isodate"),
    period: _emberData.default.attr("number"),
    started: _emberData.default.attr("isodate"),
    status: _emberData.default.attr("number"),
    // internment:    DS.belongsTo("internment"), // LP: overrided on children
    nextTodo: _emberData.default.belongsTo("todo", {
      inverse: "prevTodo",
      polymorphic: true
    }),
    prevTodo: _emberData.default.belongsTo("todo", {
      inverse: "nextTodo",
      polymorphic: true
    }),
    type: _emberData.default.belongsTo("item"),
    actions: _emberData.default.hasMany("action"),
    kind: Ember.computed(function () {
      return "todo";
    }),
    fullName: Ember.computed(function () {
      return this.get("name") + (Ember.isEmpty(this.get("description")) ? "" : "\n" + this.get("description"));
    }),
    prevTodos: Ember.computed("prevTodo.prevTodos.[]", function () {
      var result = [];
      result.addObject(this);

      if (this.get("prevTodo.id")) {
        result = result.concat(this.get("prevTodo.prevTodos"));
      }

      return result.sortBy("created");
    }),

    /* True started */
    prevStarted: Ember.computed("prevTodo.prevStarted", "started", function () {
      return this.get("prevTodo.prevStarted") && this.get("prevTodo.prevStarted") < this.get("started") ? this.get("prevTodo.prevStarted") : this.get("started");
    }),
    hasEnded: Ember.computed("ended", function () {
      return this.get("ended") && (0, _moment.default)().isSameOrAfter(this.get("ended"), "minutes");
    }),
    disabled: Ember.computed("hasEnded", "internment.disabled", function () {
      return this.get("hasEnded") || this.get("internment.disabled");
    }),
    canClose: Ember.computed("ended", "internment.ended", "started", function () {
      if ((Ember.isEmpty(this.get("ended")) || (0, _moment.default)(this.get("ended")).isSameOrAfter((0, _moment.default)())) && ( // task is open
      Ember.isEmpty(this.get("model.internment.disabled")) || this.get("model.internment.disabled") == false) && // internment is enabled
      (0, _moment.default)(this.get("prevStarted")).isSameOrBefore((0, _moment.default)()) // task has started
      ) {
          return true;
        }
    }),

    /*
     * Actions
     */
    allActions: Ember.computed("actions.@each.started", "prevTodo.allActions.@each.started", function () {
      var result = [];

      if (this.get("actions").filter(function (action) {
        return action;
      })) {
        result.pushObjects(this.get("actions").filter(function (action) {
          return action;
        }).toArray());
      }

      if (this.get("prevTodo.id") && this.get("prevTodo.allActions")) {
        result.pushObjects(this.get("prevTodo.allActions").toArray());
      }

      return result.sortBy("started").reverseObjects();
    }),
    filteredActions: Ember.computed("allActions.[]", function () {
      return Ember.isEmpty(this.get("allActions")) ? [] : this.get("allActions").filter(function (action) {
        return action.get("status") !== 4 && action.get("status") !== 5;
      });
    }),
    trueFirstAction: Ember.computed("allActionsDone.@each.status", function () {
      var actions = this.get("allActionsDone").sortBy("started");
      return actions[0];
    }),
    firstAction: Ember.computed("actionsDone.@each.status", function () {
      var actions = this.get("actionsDone").sortBy("started");
      return actions[0];
    }),
    lastAction: Ember.computed("allActions.@each.started", "allActions.@each.[]", function () {
      if (this.get("allActions.length") > 0) {
        return this.get("allActions").sortBy("started").reverse().get("firstObject");
      }
    }),
    lastActionFood: Ember.computed("allActions.@each.started", "allActions.@each.[]", function () {
      if (this.get("allActions.length") > 0) {
        return this.get("allActions").filter(function (x) {
          return x.get("initials") !== 20;
        }).sortBy("started").reverse().get("firstObject"); // Retorna última ação que não seja Não Come
      }
    }),
    lastActionFaeces: Ember.computed("allActions.@each.started", "allActions.@each.[]", function () {
      if (this.get("allActions.length") > 0) {
        return this.get("allActions").filter(function (x) {
          return x.get("initials") !== 27;
        }).sortBy("started").reverse().get("firstObject"); // Retorna última ação que não seja Não Defeca
      }
    }),
    lastActionUrine: Ember.computed("allActions.@each.started", "allActions.@each.[]", function () {
      if (this.get("allActions.length") > 0) {
        return this.get("allActions").filter(function (x) {
          return x.get("initials") !== 28;
        }).sortBy("started").reverse().get("firstObject"); // Retorna última ação que não seja Não Urina
      }
    }),
    allActionsDone: Ember.computed("allActions.@each.status", function () {
      return this.get("allActions").filterBy("status", 1);
    }),
    actionsDone: Ember.computed("actions.@each.status", function () {
      return this.get("actions").filterBy("status", 1);
    }),
    totalShoppingCartLines: Ember.computed("actionsDone.@each.shoppingCartLine.unitPrice", function () {
      // Fix @each on 2nd level
      var total = 0;
      this.get("actions").forEach(function (action) {
        if (action.get("shoppingCartLine.unitPrice")) total += 1;
      });
      return total;
    }),
    totalActionsQuantity: Ember.computed("actionsDone.@each.quantity", function () {
      var total = 0;
      this.get("actions").forEach(function (action) {
        if (action.get("quantity")) total += action.get("quantity");
      });
      return total;
    }),
    actionsToday: Ember.computed("actions.@each.status", function () {
      var result = [];
      var firstHour;

      if ((0, _moment.default)().hours() >= 8) {
        firstHour = (0, _moment.default)().startOf("day").hours(8);
      } else {
        firstHour = (0, _moment.default)().startOf("day").hours(8).subtract(24, "hours");
      }

      this.get("actions").forEach(function (action) {
        if (action.get("status") === 1 && firstHour.isSameOrBefore(action.get("created"))) {
          result.addObject(action);
        }
      });
      return result;
    }),
    lastActionUntil: function lastActionUntil(until) {
      for (var _len = arguments.length, status = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        status[_key - 1] = arguments[_key];
      }

      var result = null;

      if (status) {
        this.get("allActions").sortBy("started").filter(function (action) {
          if (until.isAfter(action.get("started")) && status.includes(action.get("status")) && !action.get("skipSchedule")) {
            result = action;
          }
        });
      }

      this.get("allActions").sortBy("started").forEach(function (action) {
        if (until.isAfter(action.get("started")) && !action.get("skipSchedule")) {
          result = action;
        }
      });
      return result;
    },
    sortDoneByCreated: Ember.computed("allActions.@each.created", function () {
      var result = [];

      if (this.get("allActions")) {
        result = this.get("allActions").filterBy("status", 1).sortBy("created");
      }

      return result;
    }),
    periodHasChanged: Ember.computed("period", "prevTodo.period", function () {
      return this.get('prevTodo.id') && this.get('period') !== this.get('prevTodo.period');
    }),
    endedHasChanged: Ember.computed("ended", "prevTodo.ended", function () {
      return this.get('prevTodo.id') && !(0, _moment.default)(this.get("ended")).isSame((0, _moment.default)(this.get("prevTodo.ended")));
    }),
    startedHasChanged: Ember.computed("started", "prevTodo.started", function () {
      return this.get('prevTodo.id') && !(0, _moment.default)(this.get("started")).isSame((0, _moment.default)(this.get("prevTodo.started")));
    }),
    descriptionHasChanged: Ember.computed("description", "prevTodo.description", function () {
      return this.get('prevTodo.id') && this.get("description") != this.get("prevTodo.description");
    }),
    createdHasChanged: Ember.computed("created", "prevTodo.created", function () {
      return this.get('prevTodo.id') && !(0, _moment.default)(this.get("created")).isSame((0, _moment.default)(this.get("prevTodo.created")));
    }),
    creatorHasChanged: Ember.computed("creator", "prevTodo.creator", function () {
      return this.get('prevTodo.id') && this.get("creator") != this.get("prevTodo.creator");
    })
  });

  _exports.default = _default;
});