define("crm/components/show-box-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.status", function () {
      if (this.get("record.status") === 1) {
        return this.get('intl').t("boxes.status.checkin");
      }

      if (this.get("record.status") === 2) {
        return this.get('intl').t("boxes.status.occupied");
      }

      if (this.get("record.status") === 3) {
        return this.get('intl').t("boxes.status.checkout");
      }

      if (this.get("record.status") === 0) {
        return this.get('intl').t("boxes.status.free");
      }
    })
  });

  _exports.default = _default;
});