define("crm/components/internment/notes-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    displayNotesButtons: false,
    titleEditable: false,
    editNote: [],
    actions: {
      updateNotes: function updateNotes() {
        if (this.get('model')) {
          this.set('displayNotesButtons', false);
          this.get('model').save();
        }

        this.set('titleEditable', false);
      },
      cancelNotes: function cancelNotes() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.set('displayNotesButtons', false);
          this.get('model').rollbackAttributes();
          this.set('titleEditable', false);
        }
      },
      toggleTitleEditable: function toggleTitleEditable() {
        if (!this.get('disabled')) {
          this.set('titleEditable', true);
          this.set('displayNotesButtons', true);
        }
      }
    }
  });

  _exports.default = _default;
});