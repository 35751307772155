define("crm/routes/organizations/organization/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      enable: function enable(item) {
        this.get("store").findRecord("item", item.get("id"), {
          reload: true
        }).then(function (savedItem) {
          savedItem.set('enable', true);
          savedItem.save();
        });
      },
      disable: function disable(item) {
        this.get("store").findRecord("item", item.get("id"), {
          reload: true
        }).then(function (savedItem) {
          savedItem.set('enable', false);
          savedItem.save();
        });
      }
    }
  });

  _exports.default = _default;
});