define("crm/models/organization-pet", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    invoiceSlip: _emberData.default.attr("boolean"),
    receiptSlip: _emberData.default.attr("boolean"),
    sender: _emberData.default.attr("string"),
    usesDecimals: _emberData.default.attr("boolean"),
    defaultWarehouse: _emberData.default.belongsTo("warehouse")
  });

  _exports.default = _default;
});