define("crm/models/consumption", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr('isodate'),
    doer: _emberData.default.belongsTo('worker'),
    consumable: _emberData.default.belongsTo('consumable')
  });

  _exports.default = _default;
});