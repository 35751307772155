define("crm/components/total-drug-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    totalDrugVolume: Ember.computed('model', 'modelActions', function () {
      if (this.get("model") && this.get("modelActions")) {
        return this.get("model") * this.get("modelActions");
      }
    })
  });

  _exports.default = _default;
});