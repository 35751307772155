define("crm/components/exam-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disabledAction: false,
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        var self = this;
        this.get("store").query("file-exam", {
          exam: this.get("model.id")
        });
        this.get("store").findRecord("exam", this.get("model.id"), {
          reload: true
        }).then(function (savedExam) {
          self.get("store").findRecord("item", savedExam.get("type.id")).then(function (parentItem) {
            self.get("store").query("item-item", {
              parent: parentItem.get("id")
            }).then(function (children) {
              if (children) {
                children.forEach(function (itemItem) {
                  self.get("store").findRecord("item", itemItem.get("child.id")).then(function (savedItem) {
                    if (savedItem.get("parameter.id") && self.get("model.patient.species.id")) {
                      self.get("store").query("equipment-parameter", {
                        parameter: savedItem.get("parameter.id"),
                        species: self.get("model.patient.species.id"),
                        organization: self.get("organization.id")
                      });
                    }
                  });
                });
              }

              if (parentItem.get("parameter.id") && self.get("model.patient.species.id")) {
                self.get("store").query("equipment-parameter", {
                  parameter: parentItem.get("parameter.id"),
                  species: self.get("model.patient.species.id"),
                  organization: self.get("organization.id")
                });
              }
            });
          });
        }).catch(function (error) {
          self.get("flashMessages").danger(error);
        });
        this.get("store").query("exam-line", {
          exam: this.get("model.id")
        }, {
          reload: true
        });
      }
    },
    close: function close() {
      Ember.$("#modal-exam-details").modal("hide");
    },
    actions: {
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          this.get("model").destroyRecord().then(function () {
            return self.close();
          }).catch(function (e) {
            return self.get("es").handle(e);
          });
        }
      }
    }
  });

  _exports.default = _default;
});