define("crm/components/internment/todo-ibox", ["exports", "crm/components/internment/todo-components"], function (_exports, _todoComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoComponents.default.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    translationToDisplay: Ember.computed("model.constructor.modelName", function () {
      return this.get("model.constructor.modelName") ? this.get("intl").t(this.get("model.constructor.modelName").replace("todo-", "") + "s." + this.get("model.constructor.modelName").replace("todo-", "") + ".title") : "";
    }),
    canDeleteTask: Ember.computed("model.allActions.[]", "model.internment.disabled", function () {
      return Ember.isEmpty(this.get("model.allActions")) && !this.get("model.internment.disabled");
    }),
    actions: {
      edit: function edit() {
        this.callModal({
          entity: this.get("model.constructor.modelName"),
          action: "edit",
          model: this.get("model")
        });
      },
      terminate: function terminate() {
        var self = this;

        if (confirm(this.get('intl').t('intl.close'))) {
          this.set('model.ended', new Date());
          this.get("model").save().then(function (savedTodo) {
            var action = self.get('store').createRecord('action', {
              status: 4,
              started: this.get('model.ended'),
              doer: self.get('currentWorker')
            });
            action.set(savedTodo.get('constructor.modelName').replace("todo-", ""), savedTodo);
            action.save().then(function () {
              return self.transitionTo('clinics.clinic.internments.internment');
            }).catch(function () {
              return self.get('store').unloadRecord(action);
            });
          }).catch(function () {
            return self.get('store').findRecord(self.get('model.constructor.modelName'), self.get("model.id"));
          });
        }
      },
      delete: function _delete() {
        var _this = this;

        this.set("deleting", true);

        if (confirm(this.get("intl").t("intl.remove"))) {
          var self = this;
          var task = this.get("model");
          task.deleteRecord();
          task.save().then(function () {
            if (self.deleted) {
              self.deleted();
            }
          }).catch(function (result) {
            return self.get("es").errorReturned(result);
          }).finally(function () {
            return _this.set("deleting", false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});