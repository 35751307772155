define("crm/components/weights-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* INJECTS */
    intl: Ember.inject.service(),
    classNames: ["module-crm"],

    /* PROPERTIES */
    displayedWeightsNumber: 2,
    displayedWeights: Ember.computed("weights.@each.weighted", "displayedWeightsNumber", function () {
      return this.get("weights") ? this.get("weights").sortBy("weighted").reverse().slice(0, this.get("displayedWeightsNumber")) : [];
    }),
    // Line weights graphic data
    data: Ember.computed("weights.@each.weighted", "model.@each.weight", function () {
      if (this.get("weights")) {
        var dates = this.get("weights").map(function (weight) {
          return (0, _moment.default)(weight.get("weighted")).format("DD/MM/YYYY");
        });
        var weights = this.get("weights").mapBy("weight");
        return {
          labels: dates,
          datasets: [{
            label: this.get('intl').t("patients.patient.weights.weight.title"),
            data: weights,
            fillColor: "#2f4050",
            backgroundColor: "rgba(220,220,220,0.2)",
            pointBackgroundColor: "rgba(220,220,220,1)",
            pointRadius: 2,
            pointHoverRadius: 2,
            pointHoverColor: "rgba(220,220,220,1)",
            pointStyle: "circle",
            lineTension: 0
          }]
        };
      }
    }),
    // Line weights graphic options
    options: {
      legend: {
        display: false
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },

    /* ACTIONS */
    actions: {
      openWeightsModal: function openWeightsModal() {
        this.sendAction("openWeightsModal", this.get("weights"));
      },
      deleteWeight: function deleteWeight(weight) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          var self = this;
          weight.destroyRecord().then(function () {
            if (self.get("model.length") == 1) {
              Ember.$("#weightsModal").modal("hide");
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});