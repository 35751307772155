define("crm/components/tasks-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    checked: '-',
    showAll: false,
    filteredTasks: Ember.computed("tasks.@each.status", "tasks.@each.taskDate", "showAll", "tasks.[]", function () {
      if (this.get("tasks")) {
        return this.get("showAll") ? this.get("tasks") : this.get("tasks").filterBy("status", 1).sortBy("taskDate").sortBy("priority").reverse();
      }
    }),
    columns: Ember.computed(function () {
      var listCollumns = [{
        "propertyName": "taskDate",
        "title": this.get('intl').t("tasks.task.taskDate"),
        "component": "umt-date",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("tasks.task.name"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "priority",
        "title": " ",
        "component": "show-task-priority",
        "className": "column-icon"
      }, {
        "propertyName": "status",
        "title": this.get('intl').t("tasks.task.status"),
        "component": "show-task-status",
        "className": "column-status-icon"
      }, {
        "component": "show-task-actions",
        "className": this.get("hideExtraActions") ? "column-actions-1 hide-extra-actions" : "column-actions-5"
      }];

      if (!this.get("hideUser")) {
        listCollumns.splice(4, 0, {
          "propertyName": "patient.name",
          "title": this.get('intl').t("patients.patient.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.patients.patient",
          "routeProperty": "patient.id"
        });
      }

      if (!this.get("hideWorker")) {
        listCollumns.splice(4, 0, {
          "propertyName": "worker.name",
          "component": "title-models-table-display",
          "title": this.get('intl').t("workers.worker.title")
        });
      }

      return listCollumns;
    }),
    actions: {
      done: function done(task) {
        var self = this;

        if (confirm(this.get('intl').t('alerts.done')) + "?") {
          if (Ember.isEmpty(task.get('doneTaskDate'))) {
            task.set('checked', 'check');
            task.set('status', 2);
            task.set('doneTaskDate', (0, _moment.default)());
            self.get('tasks').removeObject(task);
          } else {
            if (this.get('checked') !== '-') {
              task.set('checked', '-');
            }

            task.set('status', 1);
            task.set('doneTaskDate', null);
          }

          task.save();
        }
      },
      seeMore: function seeMore() {
        this.sendAction('transitToTasks');
      },
      callModal: function callModal(arg) {
        this.get("callModal")(arg);
      },
      updateModel: function updateModel() {
        this.sendAction("updateModel");
      }
    }
  });

  _exports.default = _default;
});