define("crm/controllers/organizations/organization/inventory/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    itemsChosen: [],
    purchase: [],
    resetItem: function resetItem() {
      this.set("selectedItem", null);
      Ember.$("select").prop("selectedIndex", 0);
      this.get("itemsChosen").forEach(function (item) {
        item.get("warehouseItems").forEach(function (warehouseItem) {
          warehouseItem.set("addedQuantity", null);
        });
      });
      this.set("itemsChosen", []);
    },
    selectedItemObserver: Ember.observer("selectedItem.id", function () {
      if (this.get("selectedItem.id")) {
        var item = this.get("selectedItem");
        this.get("itemsChosen").addObject(item);
        this.get("store").query("warehouse-item", {
          item: item.get("id"),
          organization: this.get("organization")
        });
      }
    }),
    actions: {
      deleteItem: function deleteItem(item) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("itemsChosen").removeObject(item);
        }
      },
      saveStock: function saveStock() {
        var self = this;
        this.get("itemsChosen").forEach(function (item) {
          item.get("warehouseItems").forEach(function (warehouseItem) {
            if (warehouseItem.get("quantity") > 0) {
              var purchase = self.get("store").createRecord("purchase", {
                price: warehouseItem.get("price"),
                quantity: warehouseItem.get("quantity"),
                lot: warehouseItem.get("lot"),
                expireDate: warehouseItem.get("expireDate"),
                warehouseItem: warehouseItem
              });
              purchase.save();
            }
          });
        });
        this.get("flashMessages").success(this.get('intl').t("items.messages.createdSuccess"));
        this.resetItem();
      }
    }
  });

  _exports.default = _default;
});