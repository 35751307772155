define("crm/routes/organizations/organization/listings/sales/items", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      var begin = this.modelFor("organizations.organization.listings").createdAfter;
      var end = this.modelFor("organizations.organization.listings").createdBefore;
      var billedAfter = (0, _moment.default)(begin).startOf("day").format();
      var billedBefore = (0, _moment.default)(end).endOf("day").format();
      var self = this;

      if ((0, _moment.default)(billedBefore).diff(billedAfter, "days") <= this.get("dateLimit")) {
        return Ember.RSVP.hash({
          items: Ember.$.get({
            url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/lists/items?organization=" + organizationID + "&billedAfter=" + (0, _moment.default)(billedAfter).toISOString() + "&billedBefore=" + (0, _moment.default)(billedBefore).toISOString()),
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          }),
          itemsNegative: Ember.$.get({
            url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/lists/itemsNegative?organization=" + organizationID + "&billedAfter=" + (0, _moment.default)(billedAfter).toISOString() + "&billedBefore=" + (0, _moment.default)(billedBefore).toISOString()),
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          })
        }).then(function (r) {
          return {
            items: r.items,
            itemsNegative: r.itemsNegative
          };
        });
      } else {
        return null;
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});