define("crm/models/invoice-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    invoice: _emberData.default.belongsTo('invoice', {
      inverse: 'lines'
    }),
    realCreator: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});