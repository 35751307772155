define("crm/models/open-item-receipts", ["exports", "crm/models/open-item"], function (_exports, _openItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _openItem.default.extend();

  _exports.default = _default;
});