define("crm/controllers/organizations/organization/customers/customer/open-items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    totalAmount: Ember.computed("model.openItems.@each.amount", function () {
      if (Ember.isEmpty(this.get("model.openItems"))) {
        return 0;
      }

      return this.get("model.openItems").reduce(function (acc, e) {
        return acc + Number(e.get("amount"));
      }, 0);
    }),
    totalDebt: Ember.computed("model.openItems.@each.open", function () {
      if (Ember.isEmpty(this.get("model.openItems"))) {
        return 0;
      }

      return this.get("model.openItems").reduce(function (acc, e) {
        return acc + Number(e.get("open"));
      }, 0);
    }),
    columns: Ember.computed(function () {
      var result = [{
        "propertyName": "sourceDoc",
        "title": this.get("intl").t("openItems.name")
      }, {
        "propertyName": "dueDate",
        "component": "umt-date",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date",
        "title": this.get("intl").t("openItems.dueDate")
      }, {
        "propertyName": "amount",
        "component": "umt-price-display",
        "className": "column-price-large text-right",
        "title": this.get("intl").t("openItems.amount")
      }, {
        "propertyName": "open",
        "component": "umt-price-display",
        "className": "column-price-large text-right",
        "title": this.get("intl").t("openItems.debtAmount")
      }];
      return result;
    })
  });

  _exports.default = _default;
});