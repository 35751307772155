define("crm/controllers/organizations/organization/item-adjustments/upload", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledButton: Ember.computed("saving", "model.warehouse", "model.stockDate", "data", function () {
      return this.get("saving") || !this.get("model.warehouse") || !this.get("model.stockDate") || !this.get("data");
    }),

    /*
     * Helpers
     */
    dataURLtoBlob: function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], {
        type: mime
      });
    },
    actions: {
      save: function save() {
        var self = this;
        this.set("saving", true);
        var blob = this.dataURLtoBlob(this.get("data"));
        var url = _environment.default.universe.api.wildfly + "/excelAdjustments/upload" + "?organization=" + this.get("model.organization.id") + "&warehouse=" + this.get("model.warehouse.id") + "&date=" + (0, _moment.default)(this.get("model.stockDate")).format("YYYY-MM-DD") + "&year=" + (0, _moment.default)(this.get("model.stockDate")).format("YYYY") + "&month=" + (0, _moment.default)(this.get("model.stockDate")).format("MM") + "&day=" + (0, _moment.default)(this.get("model.stockDate")).format("DD") + "&idColumn=0" + "&nameColumn=1" + "&stockColumn=2";
        var formData = new FormData();
        formData.append("attachment", blob, this.get("fileName").normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        Ember.$.ajax({
          url: url,
          type: "post",
          data: formData,
          cache: false,
          contentType: false,
          //required for multipart
          processData: false,
          //required for multipart
          dataType: "json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("U-Entity", "item-adjustment");
            request.setRequestHeader("U-Entity-Id", (0, _moment.default)().unix());
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(result) {
            self.set("saving", false);

            if (result.entity) {
              self.set("adjustment", result.entity);
            } else {
              self.set("adjustment", result);
            }
          },
          error: function error(result) {
            self.set("saving", false);
            self.get("es").handle(result);
          }
        });
      }
    }
  });

  _exports.default = _default;
});