define("crm/controllers/organizations/organization/appointments/corporate", ["exports", "moment", "crm/controllers/organizations/organization/appointments"], function (_exports, _moment, _appointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointments.default.extend({
    queryParams: ["oldAppointment", "intakeDate", "day", "patient"],
    startHour: 8,
    endingHour: 23,
    init: function init() {
      this._super();

      this.set("currentHour", (0, _moment.default)().format("HH"));
    },
    hours: Ember.computed(function () {
      return this.eachHalfHour(this.startHour, this.endingHour);
    }),
    eachHalfHour: function eachHalfHour(min, max) {
      var arr = [];
      var range = max - min;

      for (var i = min; i <= range + min; i++) {
        for (var j = 0; j < 2; j++) {
          var auxI = i < 10 ? "0" + i : i;
          arr.push(auxI + ":" + (j === 0 ? "00" : 30 * j));
        }
      }

      return arr;
    },
    innerStyle: Ember.computed(function () {
      return Ember.String.htmlSafe(this.get("resize") ? "position: absolute; top: " + this.get("appointment.top") + "px; height:" + this.get("appointment.height") + "px" : "height: 30px");
    }),
    days: Ember.computed("model.startOfWeek", function () {
      var result = [];
      var startOfWeek = this.get("model.startOfWeek");

      for (var i = 0; i < 7; i++) {
        result.addObject((0, _moment.default)(startOfWeek).startOf("h").add(i, "d").format("YYYY-MM-DD"));
      }

      return result;
    }),
    isToday: Ember.computed("hours.[]", "days.[]", "model.[]", function () {
      var now = (0, _moment.default)();
      return this.get("model.startOfWeek").isBefore(now) && (0, _moment.default)(this.get("model.startOfWeek")).add(7, "days").isAfter(now);
    }),
    sortedOrganizations: Ember.computed("model.organizations.[]", function () {
      return this.get("model.organizations").sortBy("id");
    }),
    columns: Ember.computed("hours.[]", "days.[]", "sortedOrganizations.[]", "model.[]", function () {
      var result = {};
      var self = this;
      this.get("hours").forEach(function (hour) {
        result[hour] = {};
        self.get("days").forEach(function (day) {
          result[hour][day] = [];
          self.get("sortedOrganizations").forEach(function (organization) {
            result[hour][day][organization.get("id")] = [];
          });
        });
      });
      self.get("model").forEach(function (appointment) {
        var minutes = (0, _moment.default)(appointment.get("start")).minutes();
        var adjustedHour = minutes < 30 ? (0, _moment.default)(appointment.get("start")).startOf("hour") : (0, _moment.default)(appointment.get("start")).startOf("hour").add(30, "minutes");
        var hour = adjustedHour.format("HH:mm");
        var day = adjustedHour.format("YYYY-MM-DD");

        if (result[hour]) {
          if (Ember.isEmpty(result[hour][day][appointment.get("organization.id")])) {
            result[hour][day][appointment.get("organization.id")] = [];
          }

          result[hour][day][appointment.get("organization.id")] = result[hour][day][appointment.get("organization.id")].addObject(appointment);
        } else {//console.log("name: '" + appointment.get("name") + "', start: '" + moment(appointment.get("start")).format("YYYY-MM-DD HH:mm") + "' | hour was: '" + hour + "'");
        }
      });
      return result;
    }),
    activeWorkers: Ember.computed("model.workers.@each.active", "model.workerFilters.@each.worker", function () {
      var activeWorkers = [];

      if (this.get("model.workerFilters.length")) {
        this.get("model.workerFilters").forEach(function (workerFilter) {
          workerFilter.set("worker.active", true);
          activeWorkers.addObject(workerFilter.get("worker"));
        });
      }

      return activeWorkers.sortBy("id");
    }),
    rootVisitItemTypes: Ember.computed("model.itemTypes.[]", function () {
      if (this.get("model.itemTypes.length")) {
        return this.get("model.itemTypes").filter(function (a) {
          return a.get("isVisit") === true && !a.get("parent.id");
        }).sortBy("name");
      }
    }),
    activeItemTypes: Ember.computed("model.itemTypeFilters.[]", function () {
      var activeItemTypes = [];

      if (this.get("model.itemTypeFilters.length")) {
        this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
          itemTypeFilter.set("itemType.active", true);
          activeItemTypes.addObject(itemTypeFilter.get("itemType"));
        });
      }

      return activeItemTypes;
    }),
    actions: {
      previous: function previous() {
        this.set("day", (0, _moment.default)(this.get("day")).subtract(7, "days").format("YYYY-MM-DD"));
      },
      next: function next() {
        this.set("day", (0, _moment.default)(this.get("day")).add(7, "days").format("YYYY-MM-DD"));
      },
      openCreate: function openCreate() {
        this.send("openModal", {
          entity: "task",
          action: "create"
        });
      },
      openModalAppointmentDetails: function openModalAppointmentDetails(appointment) {
        this.send("openAppointmentDetails", appointment);
      },
      openAppointmentCreate: function openAppointmentCreate(hour, workerID) {
        var start = hour ? hour : (0, _moment.default)().format();
        var newAppointment = {
          "organization": this.get('model.organization'),
          "start": start
        };

        if (this.get('patient')) {
          newAppointment.patient = this.get('store').peekRecord('patient', this.get('patient'));
        }

        if (this.get('worker')) {
          newAppointment.worker = this.get('store').peekRecord('worker', this.get('worker'));
        }

        if (this.get('oldAppointment')) {
          newAppointment.oldAppointment = this.get('store').peekRecord('appointment', this.get('oldAppointment'));
        }

        if (this.get('customer')) {
          newAppointment.customer = this.get('store').peekRecord('customer', this.get('customer'));
        }

        if (workerID) {
          newAppointment.worker = this.get('store').peekRecord('worker', workerID);
        }

        this.send('openModal', {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      openModalCreateAppointment: function openModalCreateAppointment(date, organizationID) {
        var newAppointment = {
          "organization": this.get('store').peekRecord('organization', organizationID),
          "start": date
        };
        this.send("openModal", {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      created: function created(appointment) {
        if ((0, _moment.default)(appointment.get("start")).startOf("day").isSameOrAfter((0, _moment.default)(this.get("model.startOfWeek")), "day") && (0, _moment.default)(appointment.get("start")).startOf("day").isSameOrBefore((0, _moment.default)(this.get("model.startOfWeek").add(6, "days")), "day")) {
          this.sendAction("createdAppointment");
        }
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set("appointment", appointment);
          Ember.$("#openCreateAppointment").modal();
        }
      },
      openDoTaskModal: function openDoTaskModal(record) {
        this.set("recordToDo", record);
        Ember.$("#openToDoTask").modal();
      },
      checkIn: function checkIn(appointment) {
        appointment.set("arrivalTime", (0, _moment.default)().format());
        appointment.set("status", 3);
        appointment.save();
      },
      closeModalAppointmentDetail: function closeModalAppointmentDetail() {
        Ember.$("#openAppointmentDetails").modal("hide");
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      deleteAppointment: function deleteAppointment(appointment) {
        if (false == Ember.isEmpty(appointment)) {
          appointment.destroyRecord();
        }
      },
      createdConsultation: function createdConsultation(consultationID) {
        this.transitionToRoute("organizations.organization.consultations.consultation", consultationID);
      },
      today: function today() {
        this.set("day", (0, _moment.default)().startOf("isoWeek").format("YYYY-MM-DD"));
      },
      transitionToCustom: function transitionToCustom() {
        this.transitionToRoute('organizations.organization.appointments.custom', {
          queryParams: {
            day: this.get("day")
          }
        });
      },
      toggleWorkerFilter: function toggleWorkerFilter(worker) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.workerFilters.length")) {
          this.get("model.workerFilters").forEach(function (workerFilter) {
            if (workerFilter.get("worker.id") == worker.get("id")) {
              exists = true;
              filterToDelete = workerFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.workerFilters").removeObject(filterToDelete);
            filterToDelete.set("worker.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-worker", {
            owner: this.get("model.me"),
            worker: worker
          }).save().then(function (calendarFilterWorker) {
            self.get("model.workerFilters").addObject(calendarFilterWorker._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleItemTypeFilter: function toggleItemTypeFilter(itemType) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.itemTypeFilters.length")) {
          this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
            if (itemTypeFilter.get("itemType.id") == itemType.get("id")) {
              exists = true;
              filterToDelete = itemTypeFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.itemTypeFilters").removeObject(filterToDelete);
            filterToDelete.set("itemType.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-itemType", {
            owner: this.get("model.me"),
            itemType: itemType
          }).save().then(function (calendarFilterItemType) {
            self.get("model.itemTypeFilters").addObject(calendarFilterItemType._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      }
    }
  });

  _exports.default = _default;
});