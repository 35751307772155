define("crm/controllers/organizations/organization/charges/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    organizationController: Ember.inject.controller("organizations.organization"),
    organization: Ember.computed.alias("organizationController.model"),
    store: Ember.inject.service(),
    actions: {
      cancel: function cancel(m) {
        if (m.organizationInventory) {
          if (confirm(this.get('intl').t('intl.remove'))) {
            m.organizationInventory.destroyRecord();
          }
        }

        this.send("reloadModel");
      },
      updateIntermediary: function updateIntermediary(organizationIntermediary) {
        var saved = organizationIntermediary.get("id") ? organizationIntermediary : this.get("store").createRecord("organizationIntermediary", organizationIntermediary);
        saved.save();
      }
    }
  });

  _exports.default = _default;
});