define("crm/routes/organizations/organization/group/calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    queryParams: {
      day: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var startOfWeek = transition.queryParams.day ? (0, _moment.default)(transition.queryParams.day).startOf("isoWeek") : (0, _moment.default)().startOf("isoWeek");
      var endOfWeek = (0, _moment.default)(startOfWeek).add(7, 'days');
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get('id');

      if (startOfWeek.isValid() && endOfWeek.isValid()) {
        var self = this;
        var me = this.get("worker");
        return Ember.RSVP.hash({
          comp: organization.get("company"),
          organizations: organization.get("siblings"),
          filteredAppointments: self.store.query("appointment", {
            company: organization.get("company.id"),
            startedAfter: startOfWeek.format(),
            startedBefore: endOfWeek.format()
          }),
          itemTypes: self.store.findAll("itemType"),
          workers: self.store.query("worker", {
            organization: organizationID
          }),
          workerFilters: self.store.query("calendar-filter-worker", {
            owner: me.get("id")
          }),
          itemTypeFilters: self.store.query("calendar-filter-item-type", {
            owner: me.get("id")
          }),
          patients: self.store.query("patient", {
            organization: organizationID,
            appointmentStartedAfter: startOfWeek.format(),
            appointmentStartedBefore: endOfWeek.format()
          }),
          customers: self.store.query("customer", {
            organization: organizationID,
            appointmentStartedAfter: startOfWeek.format(),
            appointmentStartedBefore: endOfWeek.format()
          })
        }).then(function (hashResult) {
          var orgs = [];
          hashResult.organizations.forEach(function (org) {
            return orgs.push(org);
          });
          orgs.push(organization);
          orgs.forEach(function (o) {
            return Ember.set(o, "company", hashResult.comp);
          });
          var appointments = hashResult.filteredAppointments;
          Ember.set(appointments, "organizations", orgs);
          Ember.set(appointments, "organization", organization);
          Ember.set(appointments, "startOfWeek", startOfWeek);
          Ember.set(appointments, "endOfWeek", (0, _moment.default)(startOfWeek).add(6, "days"));
          Ember.set(appointments, "itemTypes", hashResult.itemTypes);
          Ember.set(appointments, "workers", hashResult.workers);
          Ember.set(appointments, "workerFilters", hashResult.workerFilters);
          Ember.set(appointments, "itemTypeFilters", hashResult.itemTypeFilters);
          Ember.set(appointments, "me", me);
          return appointments;
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("day", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    },
    actions: {
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send('openModalAppointmentDetails', appointment);
      }
    }
  });

  _exports.default = _default;
});