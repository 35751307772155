define("crm/models/bundle", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    barcode: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    longDescription: _emberData.default.attr('string'),
    retailPrice: _emberData.default.attr(),
    type: _emberData.default.belongsTo('item-type'),
    unit: _emberData.default.belongsTo('unit'),
    organization: _emberData.default.belongsTo('organization'),
    bundleItems: _emberData.default.hasMany('bundle-item', {
      inverse: 'bundle'
    })
  });

  _exports.default = _default;
});