define("crm/components/invoice-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ["well", "m-lg"],
    date: Ember.computed("model.created", function () {
      return (0, _moment.default)(this.get("model.created")).format('YYYY-MM-DD HH:mm');
    })
  });

  _exports.default = _default;
});