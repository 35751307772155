define("crm/components/periodic-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disableCreateBtn: false,
    catalogs: Ember.inject.service(),
    today: (0, _moment.default)().format("YYYY-MM-DD"),
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isEmpty(this.get('startDay'))) {
        this.set('startDay', (0, _moment.default)().format('YYYY-MM-DD'));
        this.set('nextDay', (0, _moment.default)().add(1, 'year').format('YYYY-MM-DD'));
      } else if (this.get('startDay')) {
        this.set('nextDay', (0, _moment.default)(this.get('startDay')).add(1, 'year').format('YYYY-MM-DD'));
      }

      if (this.get("model")) {
        if (this.get("model.nextPeriodic")) {
          var self = this;
          var time = this.get("model.nextPeriodic");

          if (time >= 8760) {
            this.set("model.period", time / 8760);
            this.get("periodTypes").forEach(function (periodType) {
              if (periodType.id == time) self.set("selectedPeriod", periodType);
            });
          } else if (time >= 720) {
            this.set("model.period", time / 720);
            this.get("periodTypes").forEach(function (periodType) {
              if (periodType.id == time) self.set("selectedPeriod", periodType);
            });
          } else if (time >= 168) {
            this.set("model.period", time / 168);
            this.get("periodTypes").forEach(function (periodType) {
              if (periodType.id == time) self.set("selectedPeriod", periodType);
            });
          } else {
            this.set("model.period", time / 24);
            this.get("periodTypes").forEach(function (periodType) {
              if (periodType.id == time) self.set("selectedPeriod", periodType);
            });
          }
        }
      }
    },
    periodTypes: Ember.computed("catalogs.periodTypes", function () {
      return this.get("catalogs.periodTypes");
    }),
    pastDate: Ember.computed("startDay", function () {
      if (this.get('startDay') < (0, _moment.default)().format('YYYY-MM-DD')) {
        return true;
      }
    }),
    periodics: Ember.computed("itemTypes.@each.parent", "itemTypes.@each.isPeriodic", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return type.get('isPeriodic');
        });
      }
    }),
    close: function close() {
      Ember.$("#modal-periodic-edit").modal("hide");
    },
    actions: {
      selectItem: function selectItem(value) {
        var self = this;

        if (value) {
          this.set("model.type", value);

          if (value.get("periodBuild")) {
            this.get("store").findRecord("item", value.get("id"), {
              reload: true
            }).then(function (item) {
              // find to get the period
              self.get("periodTypes").forEach(function (periodValue) {
                if (item.get("periodBuild") % periodValue.id == 0) {
                  self.set("model.period", Number(item.get("periodBuild") / periodValue.id));
                  self.set("selectedPeriod", periodValue);
                }
              });
            });
          } else {
            this.set("model.period", null);
            this.set("selectedPeriod", null);
          }
        } else {
          this.set("model.type", null);
        }
      },
      setSelectedPeriod: function setSelectedPeriod(value) {
        this.set("selectedPeriod", value);
      },
      modalCreatePeriodic: function modalCreatePeriodic() {
        this.set('disableCreateBtn', true);
        var self = this;
        this.set("model.period", Number(this.get("selectedPeriod.id")) * Number(this.get("model.period")));

        if (this.get('model.consultation')) {
          this.set('model.consultation', this.get('model.consultation'));
        }

        if (this.get('startDay')) {
          this.set('model.intakeDate', new Date(this.get('startDay')));
        }

        if (this.get("model.period")) {
          this.set('model.nextPeriodic', new Date((0, _moment.default)(this.get('model.intakeDate')).add(this.get("model.period"), "hours").format()));
        }

        this.set('model.patient', this.get('model.patient'));
        this.get('model').save().then(function (savedPeriodic) {
          if (self.get('periodics')) {
            self.get('periodics').addObject(savedPeriodic);
          }

          if (self.get('model.shoppingCart')) {
            self.get('store').findRecord('shopping-cart', self.get('model.shoppingCart.id'));
          }

          self.sendAction("reloadModel");
          self.close();
          self.get('flashMessages').success(self.get('intl').t('periodics.messages.createdSuccess'));
        }).catch(function (result) {
          self.set('disableCreateBtn', false);
          self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
        });
      },
      canceled: function canceled() {
        this.get("model").rollbackAttributes();
        this.close();
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set('disableCreateBtn', false);
        this.set('selectedItemType', null);
        this.set("selectedPeriod", null);
      }
    }
  });

  _exports.default = _default;
});