define("crm/components/order-line-compound-product-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      // using a copy to prevent changing real obj without save action
      var children = Ember.A();
      var childrenOld = {};
      var src = this.get("model.lines")[0];
      var dst = {
        unitPrice: Ember.get(src, "unitPrice") || 0,
        quantity: Ember.get(src, "quantity") || 1,
        item: Ember.get(src, "item"),
        tariff: this.findTariff(src),
        discount: Ember.get(src, "discount"),
        lineGroup: Ember.get(src, "lineGroup"),
        lineGroupTree: Ember.get(src, "lineGroupTree"),
        linePrice: Ember.get(src, "linePrice")
      };
      this.set("parent", dst);
      this.set("parentOld", src);

      for (var i = 1; i < this.get("model.lines.length"); i++) {
        src = this.get("model.lines")[i];
        dst = {
          name: Ember.get(src, "name"),
          unitPrice: Ember.get(src, "unitPrice") || 0,
          quantity: Ember.get(src, "quantity") || 1,
          item: Ember.get(src, "item"),
          tariff: this.findTariff(src),
          discount: Ember.get(src, "discount"),
          lineGroup: Ember.get(src, "lineGroup"),
          lineGroupTree: Ember.get(src, "lineGroupTree"),
          linePrice: Ember.get(src, "linePrice")
        };
        children.addObject(dst);
        childrenOld[Ember.get(src, "lineGroupTree")] = src;
      }

      this.set("children", children);
      this.set("childrenOld", childrenOld);
    },

    /*
     * Computed
     */
    netValue: Ember.computed("parent.unitPrice", "parent.discount", "parent.quantity", function () {
      var gross = this.gross(this.get("parent.unitPrice"), this.get("parent.quantity"));
      var grossPlusDiscount = this.grossPlusDiscount(gross, this.get("parent.discount"));
      return this.net(gross, grossPlusDiscount);
    }),
    filteredData: Ember.computed("parent.discount", "parent.quantity", "children.[]", function () {
      var _this = this;

      var self = this;
      var parent = this.get("parent");
      var parentOld = this.get("parentOld");

      if (this.get("children.length")) {
        this.get("children").forEach(function (child) {
          var childOld = _this.get("childrenOld")[Ember.get(child, "lineGroupTree")];

          var pQuantity = Number(Ember.get(parent, "quantity"));
          var pQuantityO = Ember.get(parentOld, "quantity");
          var quantityO = Ember.get(childOld, "quantity");
          var quantity = quantityO * pQuantity / pQuantityO;
          var pDiscountO = Ember.get(parentOld, "discount");
          var pDiscount = Ember.get(parent, "discount");
          var discount = Number(Ember.get(childOld, "discount"));

          if (pDiscountO != pDiscount) {
            if (Ember.isEmpty(pDiscount) || pDiscount == 0) {
              discount = 0;
            } else {
              discount = self.getNewDiscount(Number(Ember.get(parent, "discount")), Number(Ember.get(childOld, "discount")));
            }
          }

          Ember.set(child, "quantity", quantity);
          Ember.set(child, "discount", discount);
          Ember.set(child, "discountTotal", self.grossPlusDiscount(self.gross(Ember.get(child, "unitPrice"), quantity), discount));
          Ember.set(child, "linePrice", self.net(self.gross(Ember.get(child, "unitPrice"), quantity), Ember.get(child, "discountTotal")));
        });
      }

      return this.get("children");
    }),
    getNewDiscount: function getNewDiscount(discount1, discount2) {
      var result;

      if (discount2) {
        if (discount1) {
          result = discount2 + discount1 * (100 - discount2) / 100;
        } else {
          result = discount2;
        }
      } else {
        if (discount1) {
          result = discount1;
        } else {
          result = 0;
        }
      }

      return result;
    },
    total: Ember.computed("netValue", "children@each.linePrice", function () {
      return this.get("netValue") || this.get("children.length") ? this.get("netValue") + this.get("children").reduce(function (acc, obj) {
        return acc + Ember.get(obj, "linePrice");
      }, 0) : "";
    }),

    /*
     * Functions
     */
    findTariff: function findTariff(src) {
      if (Ember.get(src, "item.pvp") == Ember.get(src, "unitPrice") && (!Ember.get(src, "discount") || Ember.get(src, "item.pvpDiscount") == Ember.get(src, "discount"))) {
        return 1;
      } else if (Ember.get(src, "item.pvp2") == Ember.get(src, "unitPrice") && (!Ember.get(src, "discount") || Ember.get(src, "item.pvpDiscount2") == Ember.get(src, "discount"))) {
        return 2;
      } else if (Ember.get(src, "item.pvp3") == Ember.get(src, "unitPrice") && (!Ember.get(src, "discount") || Ember.get(src, "item.pvpDiscount3") == Ember.get(src, "discount"))) {
        return 3;
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },
    gross: function gross(unitPrice, quantity) {
      return this.round(Number(unitPrice || 0) * Number(quantity || 1));
    },
    grossPlusDiscount: function grossPlusDiscount(gross, discount) {
      return this.round(Number(gross) * Number(discount || 0) / 100);
    },
    net: function net(gross, grossPlusDiscount) {
      return this.round(gross - grossPlusDiscount);
    },

    /*
     * Actions
     */
    actions: {
      save: function save() {
        this.set("disabledAction", true); // var self = this;
        // model.lines

        var src = this.get("parent");
        var dst = this.get("model.lines")[0];
        Ember.set(dst, "unitPrice", this.get("parent.unitPrice") || 0);
        Ember.set(dst, "quantity", this.get("parent.quantity") || 1);
        Ember.set(dst, "linePrice", this.get("netValue"));
        Ember.set(dst, "discount", Ember.get(src, "discount"));

        for (var i = 0; i < this.get("children.length"); i++) {
          src = this.get("children")[i];
          dst = this.get("childrenOld")[Ember.get(src, "lineGroupTree")];
          Ember.set(dst, "unitPrice", Ember.get(src, "unitPrice") || 0);
          Ember.set(dst, "quantity", Ember.get(src, "quantity") || 1);
          Ember.set(dst, "item", Ember.get(src, "item")); // set(dst, "tariff", self.findTariff(src));

          Ember.set(dst, "discount", Ember.get(src, "discount"));
          Ember.set(dst, "lineGroup", Ember.get(src, "lineGroup"));
          Ember.set(dst, "lineGroupTree", Ember.get(src, "lineGroupTree"));
          Ember.set(dst, "linePrice", Ember.get(src, "linePrice"));
        }

        if (this.get("model.isConsultation")) {
          this.get("model.lines").forEach(function (l) {
            return l.save();
          });
        }

        this.close();
        this.set("disabledAction", false);
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {//
      },
      setLineTariff: function setLineTariff(tariff) {
        this.set("tariff", tariff);
        var unitPrice = null;
        var discount = null;

        switch (tariff) {
          case 2:
            unitPrice = this.get("model.lines.firstObject.item.saleItemUnit2") ? this.get("model.lines.firstObject.item.saleUnitPrice2") : this.get("model.lines.firstObject.item.pvp2");
            discount = this.get("model.lines.firstObject.item.pvpDiscount2");
            break;

          case 3:
            unitPrice = this.get("model.lines.firstObject.item.saleItemUnit3") ? this.get("model.lines.firstObject.item.saleUnitPrice3") : this.get("model.lines.firstObject.item.pvp3");
            discount = this.get("model.lines.firstObject.item.pvpDiscount3");
            break;
        }

        unitPrice = unitPrice || (this.get("model.lines.firstObject.item.saleItemUnit") ? this.get("model.lines.firstObject.item.saleUnitPrice") : this.get("model.lines.firstObject.item.pvp"));
        discount = discount || this.get("model.lines.firstObject.item.pvpDiscount");

        if (discount) {
          if (this.get("model.lines.firstObject.lineGroupDiscount")) {
            discount = discount + this.get("model.lines.firstObject.lineGroupDiscount") * (100 - discount) / 100;
          } else {// discount = discount;
          }
        } else {
          if (this.get("model.lines.firstObject.lineGroupDiscount")) {
            discount = this.get("model.lines.firstObject.lineGroupDiscount");
          } else {
            discount = 0;
          }
        }

        this.set("unitPrice", unitPrice);
        this.set("discount", discount);
      }
    }
    /*
    setPvp(number) {
       var self = this;
           self.get("childs").forEach(function(child) {
             if(itemItem.get("child.id") == get(child, "item.id")) {
                if(number == 1) {
                   set(child, "temporaryUnitPrice", itemItem.get("saleUnitPrice"));
                 } else if(number == 2) {
                   set(child, "temporaryUnitPrice", itemItem.get("saleUnitPrice2"));
                 } else if(number == 3) {
                   set(child, "temporaryUnitPrice", itemItem.get("saleUnitPrice3"));
                }
             }
          });
        if(number == 1) {
          set(self.get("model.lines.firstObject"), "temporaryUnitPrice", self.get("model.lines.firstObject.item.saleItemUnit") ? self.get("model.lines.firstObject.item.saleUnitPrice").toFixed(2) : self.get("model.lines.firstObject.item.pvp").toFixed(2));
        } else if(number == 2) {
          set(self.get("model.lines.firstObject"), "temporaryUnitPrice", self.get("model.lines.firstObject.item.saleItemUnit2") ? self.get("model.lines.firstObject.item.saleUnitPrice2").toFixed(2) : self.get("model.lines.firstObject.item.pvp2ToUse").toFixed(2));
        } else if(number == 3) {
          set(self.get("model.lines.firstObject"), "temporaryUnitPrice", self.get("model.lines.firstObject.item.saleItemUnit3") ? self.get("model.lines.firstObject.item.saleUnitPrice3").toFixed(2) : self.get("model.lines.firstObject.item.pvp3ToUse").toFixed(2));
       }
    }
    */

  });

  _exports.default = _default;
});