define("crm/routes/organizations/organization/charges/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var organization = this.modelFor("organizations.organization");
      return this.store.query("organization-intermediary", {
        organization: organization.get("id")
      }).then(function (organizationInventories) {
        var result = [];
        var mapOI = {};
        organizationInventories.forEach(function (organizationInventory) {
          mapOI[organizationInventory.get("intermediary.id")] = organizationInventory;
        });

        _this.store.peekAll("intermediary").forEach(function (intermediary) {
          if (mapOI[intermediary.get("id")]) {
            result.push(mapOI[intermediary.get("id")]);
          } else {
            result.push({
              intermediary: intermediary,
              organization: organization,
              fake: true
            });
          }
        });

        return result;
      });
    }
  });

  _exports.default = _default;
});