define("crm/controllers/organizations/organization/transfer-stock/new", ["exports", "moment", "crm/config/environment", "universe-gui/u-constants"], function (_exports, _moment, _environment, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    countries: _uConstants.default.countries,
    initialSeed: (0, _moment.default)(),
    saving: false,
    documentLines: [],
    warehousesOrgFiltered: Ember.computed("warehousesOrg.[]", function () {
      if (this.get("warehousesOrg") && this.get("warehousesOrg.length") === 1) {
        this.send("setSourceWarehouse", this.get("warehousesOrg.firstObject"));
      }

      return this.get("warehousesOrg");
    }),
    receivingWarehouses: Ember.computed("warehousesCompany.[]", "model.sourceWarehouse.id", function () {
      if (!Ember.isEmpty(this.get("warehousesCompany")) && !Ember.isEmpty(this.get("model.sourceWarehouse"))) {
        var self = this;
        var receiving = this.get("warehousesCompany").filter(function (warehouseItem) {
          return warehouseItem.get("id") !== self.get("model.sourceWarehouse.id");
        });

        if (receiving && receiving.get("length") === 1) {
          this.send("setTargetWarehouse", receiving.get("firstObject"));
        }

        return receiving;
      }
    }),
    invalidDates: Ember.computed("model.loadingDateTime", "model.unloadingDateTime", function () {
      return this.get("model.loadingDateTime") > this.get("model.unloadingDateTime") || this.get("model.unloadingDateTime") < this.get("model.loadingDateTime");
    }),
    disabledAction: Ember.computed("saving", "model.sourceWarehouse", "model.targetWarehouse", "model.documentDate", "documentLines.[]", "invalidDates", function () {
      return this.get("saving") || Ember.isEmpty(this.get("model.sourceWarehouse")) || Ember.isEmpty(this.get("model.targetWarehouse")) || Ember.isEmpty(this.get("model.documentDate")) || this.get("documentLines.length") == 0 || this.get("invalidDates");
    }),
    initialLoadingDate: Ember.computed("moment", function () {
      return (0, _moment.default)().add(30, "minutes");
    }),
    initialUnloadingDate: Ember.computed("moment", "initialLoadingDate", function () {
      return (0, _moment.default)(this.get("initialLoadingDate")).add(1, "hour");
    }),
    actions: {
      setSourceWarehouse: function setSourceWarehouse(w) {
        if (w) {
          this.set("model.sourceWarehouse", w);
          this.set("model.loadingStreetName", w.get("loadingStreetName"));
          this.set("model.loadingBuildingNumber", w.get("loadingBuildingNumber"));
          this.set("model.loadingPostalZone", w.get("loadingPostalZone"));
          this.set("model.loadingCityName", w.get("loadingCityName"));
          this.set("selectedLoadingCountry", w.get("loadingCountry"));
        } else {
          this.set("model.sourceWarehouse", null);
          this.set("model.loadingStreetName", null);
          this.set("model.loadingBuildingNumber", null);
          this.set("model.loadingPostalZone", null);
          this.set("model.loadingCityName", null);
          this.set("selectedLoadingCountry", null);
        }
      },
      setTargetWarehouse: function setTargetWarehouse(w) {
        if (w) {
          this.set("model.targetWarehouse", w);
          this.set("model.unloadingStreetName", w.get("loadingStreetName"));
          this.set("model.unloadingBuildingNumber", w.get("loadingBuildingNumber"));
          this.set("model.unloadingPostalZone", w.get("loadingPostalZone"));
          this.set("model.unloadingCityName", w.get("loadingCityName")); //this.set("selectedUnloadingCountry", w.get("loadingCountry"));
        } else {
          this.set("model.targetWarehouse", null);
          this.set("model.unloadingStreetName", null);
          this.set("model.unloadingBuildingNumber", null);
          this.set("model.unloadingPostalZone", null);
          this.set("model.unloadingCityName", null); //this.set("selectedUnloadingCountry", null);
        }
      },
      save: function save() {
        this.set("saving", true);
        var self = this;
        this.set("model.organization", this.get("organization"));
        this.set("model.documentDate", new Date());

        if (this.get("selectedLoadingCountry.code")) {
          this.set("model.loadingCountry", this.get("selectedLoadingCountry.code"));
        }

        if (this.get("selectedUnloadingCountry.code")) {
          this.set("model.unloadingCountry", this.get("selectedUnloadingCountry.code"));
        }

        this.get("store").createRecord("stock-transfer-order", this.get("model")).save().then(function (stockTransferOrder) {
          self.get("documentLines").forEach(function (documentLine) {
            documentLine.set("stockTransferOrder", stockTransferOrder);
            documentLine.set("itemIsStockable", documentLine.get("item.stockable"));
          });
          Ember.$.ajax({
            method: "POST",
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(self.get("documentLines")),
            url: _environment.default.universe.api.wildfly + "/" + "stockTransferOrderLines" + "/list"
          }).then(function () {
            //self.send("getPdf", stockTransferOrder);
            self.set("selectedLoadingCountry", null);
            self.set("selectedUnloadingCountry", null);
            self.transitionToRoute("organizations.organization.transfer-stock");
          }, function (result) {
            if (result.responseText.includes("already being created")) {
              self.get("flashMessages").info("Este documento vai demorar a processar. Verifique na pagina dos documentos em breve.");
              self.set("saving", false);
            } else {
              self.get("es").handle(result);
              self.set("saving", false);
            }
          });
        }).catch(function (error) {
          self.get("es").handle(error);
        }).finally(function () {
          self.set("saving", false);
        });
      },
      canceled: function canceled() {
        this.transitionToRoute("organizations.organization.transfer-stock");
      },
      selectedItem: function selectedItem(item) {
        var self = this;
        self.get("documentLines").addObject(self.get("store").createRecord("stock-transfer-order-line", {
          item: item,
          quantity: 1,
          name: item.get("name")
        }));
      },
      deleteLine: function deleteLine(line) {
        this.get("documentLines").removeObject(line);
      },
      getPdf: function getPdf(model) {
        var self = this;
        var modelName = model.constructor.modelName;
        var id = model.get("id");
        this.set("emitedPdfId", model.get("id"));
        model.unloadRecord();
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stockTransferOrders/" + id + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("name") + ".pdf";
              link.click();
              self.set("selectedLoadingCountry", null);
              self.set("selectedUnloadingCountry", null);
              self.transitionToRoute("organizations.organization.transfer-stock");
            });
          },
          error: function error(result) {
            self.get("es").handle(result);
          }
        });
      }
    }
  });

  _exports.default = _default;
});