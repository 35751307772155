define("crm/routes/organizations/organization/absences/approval/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor("organizations.organization");
      var organizationID = model.get("id");
      this.get("store").query("absence", {
        organization: organizationID,
        status: 0
      }).then(function (absences) {
        model.set("absences", absences);
      });
      return model;
    }
  });

  _exports.default = _default;
});