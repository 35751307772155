define("crm/controllers/organizations/organization/group/items", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    queryParams: ["endedAfter", "endedBefore"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("endedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("endedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minEndedAfter: Ember.computed("endedBefore", function () {
      if (!Ember.isEmpty(this.get("endedBefore"))) {
        return (0, _moment.default)(this.get("endedBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxEndedBefore: Ember.computed("endedAfter", function () {
      if (!Ember.isEmpty(this.get("endedAfter"))) {
        return (0, _moment.default)(this.get("endedAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "className": "module-sales",
        "title": this.get("intl").t("items.item.name"),
        "routeName": "organizations.organization.items.item",
        "routeProperty": "id"
      }, {
        "propertyName": "quantity",
        "title": this.get("intl").t("units.title"),
        "className": "column-big text-right small"
      }, {
        "propertyName": "priceAvg",
        "component": "umt-price-display",
        "className": "column-big text-right small",
        "title": this.get("intl").t("items.item.unitPrice")
      }, {
        "propertyName": "vat",
        "className": "column-big text-right small",
        "title": this.get("intl").t("taxes.vat.title")
      }, {
        "propertyName": "invoices",
        "className": "column-big text-right",
        "title": this.get("intl").t("invoices.title")
      }, {
        "propertyName": "priceTotal",
        "component": "umt-price-display",
        "className": "column-big text-right",
        "title": this.get("intl").t("invoices.invoice.total")
      }];
    }),
    totalModel: Ember.computed("model.items.[]", "model.itemsNegative.[]", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.items"))) {
        result.addObjects(this.get("model.items"));
      }

      if (!Ember.isEmpty(this.get("model.itemsNegative"))) {
        result.addObjects(this.get("model.itemsNegative"));
      }

      return result;
    }),
    filteredModel: Ember.computed("totalModel.[]", function () {
      return this.get("totalModel") ? this.get("totalModel").map(function (x) {
        return {
          id: x[0],
          code: x[1],
          name: x[2],
          // item name
          priceTotal: x[3],
          // lines total in sales
          quantity: x[4],
          // lines total units
          type: x[5],
          // item type id
          invoices: x[6],
          // number of lines
          priceAvg: x[7],
          // avg price
          vat: x[8],
          // vat
          stockable: x[9] // stockable

        };
      }).sortBy("name") : null;
    }),
    stockableItems: Ember.computed("filteredModel.stockable", function () {
      if (!Ember.isEmpty(this.get("filteredModel"))) {
        return this.get("filteredModel").filterBy("stockable", true);
      }
    }),
    stockableItemsWithVat0: Ember.computed("stockableItems.@each.vat", "stockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("stockableItems"))) {
        var total = 0;
        var items = this.get("stockableItems").filterBy("vat", 0);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    stockableItemsWithVat6: Ember.computed("stockableItems.@each.vat", "stockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("stockableItems"))) {
        var total = 0;
        var items = this.get("stockableItems").filterBy("vat", 6);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    stockableItemsWithVat13: Ember.computed("stockableItems.@each.vat", "stockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("stockableItems"))) {
        var total = 0;
        var items = this.get("stockableItems").filterBy("vat", 13);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    stockableItemsWithVat23: Ember.computed("stockableItems.@each.vat", "stockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("stockableItems"))) {
        var total = 0;
        var items = this.get("stockableItems").filterBy("vat", 23);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    stockableItemsTotal: Ember.computed("stockableItems.priceTotal", function () {
      if (!Ember.isEmpty(this.get("stockableItems"))) {
        var total = 0;
        this.get("stockableItems").forEach(function (item) {
          total = total + Ember.get(item, "priceTotal");
        });
        return total;
      }
    }),
    unstockableItems: Ember.computed("filteredModel.stockable", function () {
      if (!Ember.isEmpty(this.get("filteredModel"))) {
        return this.get("filteredModel").filterBy("stockable", false);
      }
    }),
    unstockableItemsWithVat0: Ember.computed("unstockableItems.@each.vat", "unstockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("unstockableItems"))) {
        var total = 0;
        var items = this.get("unstockableItems").filterBy("vat", 0);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    unstockableItemsWithVat6: Ember.computed("unstockableItems.@each.vat", "unstockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("unstockableItems"))) {
        var total = 0;
        var items = this.get("unstockableItems").filterBy("vat", 6);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    unstockableItemsWithVat13: Ember.computed("unstockableItems.@each.vat", "unstockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("unstockableItems"))) {
        var total = 0;
        var items = this.get("unstockableItems").filterBy("vat", 13);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    unstockableItemsWithVat23: Ember.computed("unstockableItems.@each.vat", "unstockableItems.@each.priceTotal", function () {
      if (!Ember.isEmpty(this.get("unstockableItems"))) {
        var total = 0;
        var items = this.get("unstockableItems").filterBy("vat", 23);

        if (!Ember.isEmpty(items)) {
          items.forEach(function (item) {
            total = total + Ember.get(item, "priceTotal");
          });
        }

        return total;
      }
    }),
    unstockableItemsTotal: Ember.computed("unstockableItems.priceTotal", function () {
      if (!Ember.isEmpty(this.get("unstockableItems"))) {
        var total = 0;
        this.get("unstockableItems").forEach(function (item) {
          total = total + Ember.get(item, "priceTotal");
        });
        return total;
      }
    }),
    filtersObserver: Ember.observer("endedAfter", "endedBefore", function () {
      if (!Ember.isEmpty(this.get("endedAfter")) && !Ember.isEmpty(this.get("endedBefore"))) {
        this.set("endedAfter", (0, _moment.default)(this.get("endedAfter")).startOf("day").format("YYYY-MM-DD"));
        this.set("endedBefore", (0, _moment.default)(this.get("endedBefore")).endOf("day").format("YYYY-MM-DD"));
      }
    })
  });

  _exports.default = _default;
});