define("crm/models/appointment", ["exports", "ember-data", "moment", "crm/models/abstract-model"], function (_exports, _emberData, _moment, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    catalogs: Ember.inject.service(),
    environmentTypes: Ember.computed.alias("catalogs.appointmentType"),
    subject: _emberData.default.attr("string"),
    observation: _emberData.default.attr("string"),
    start: _emberData.default.attr("isodate"),
    end: _emberData.default.attr("isodate"),
    //label:                DS.belongsTo("appointmentLabel"),
    resource: _emberData.default.attr("number"),
    periodicInfo: _emberData.default.attr("string"),
    recurrenceInfo: _emberData.default.attr("string"),
    status: _emberData.default.attr("number"),
    arrivalTime: _emberData.default.attr("isodate"),
    // moved to checkin
    timeConsultation: _emberData.default.attr("isodate"),
    // moved to appointment
    endConsultation: _emberData.default.attr("isodate"),
    // moved to checkout
    exitTime: _emberData.default.attr("isodate"),
    // left the building
    patient: _emberData.default.belongsTo("patient"),
    waitingRoom: _emberData.default.attr("boolean"),
    notice: _emberData.default.attr("boolean"),
    noticeDate: _emberData.default.attr("isodate"),
    worker: _emberData.default.belongsTo("worker"),
    weight: _emberData.default.belongsTo("weight"),
    calls: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    consultation: _emberData.default.belongsTo("consultation"),
    room: _emberData.default.belongsTo("room"),
    googleAppointmentId: _emberData.default.attr("string"),
    missedReason: _emberData.default.attr("string"),
    missedDate: _emberData.default.attr("isodate"),
    giveUpReason: _emberData.default.attr("string"),
    giveUpDate: _emberData.default.attr("isodate"),
    receptionNotes: _emberData.default.attr("string"),
    rescheduledDate: _emberData.default.attr("isodate"),
    customer: _emberData.default.belongsTo("customer"),
    type: _emberData.default.belongsTo("item"),
    category: _emberData.default.belongsTo("item-type"),
    internment: _emberData.default.belongsTo("internment"),
    skipNotification: _emberData.default.attr("boolean"),
    period: _emberData.default.attr("number"),
    periodType: _emberData.default.attr("string"),
    periodUntil: _emberData.default.attr("isodate"),
    shoppingCart: _emberData.default.belongsTo("shopping-cart", {
      inverse: "appointment"
    }),
    typeTypeRoot: Ember.computed("type.typeRoot", "category.root", function () {
      if (this.get("type")) {
        return this.get("type.typeRoot");
      } else {
        return this.get("category.root");
      }
    }),
    codes: Ember.computed("type.codes", "category.codesStr", function () {
      return Ember.isEmpty(this.get("type.id")) ? this.get("category.codesStr") : this.get("type.codes");
    }),
    typeNames: Ember.computed("type.name", "category.name", function () {
      return Ember.isEmpty(this.get("type.id")) ? this.get("category.names") : this.get("type.names");
    }),
    defaultDuration: 30,
    duration: Ember.computed("start", "end", function () {
      if (this.get("end")) {
        return (0, _moment.default)(this.get("end")).diff(this.get("start"), "minutes");
      } else {
        return this.get("defaultDuration");
      }
    }),
    delayDuration: Ember.computed("start", "arrivalTime", function () {
      var duration = "";
      var color = "";

      if (this.get("start")) {
        if (this.get("arrivalTime")) {
          duration = (0, _moment.default)(this.get("arrivalTime")).diff(this.get("start"), "minutes");
        } else {
          duration = (0, _moment.default)().diff(this.get("start"), "minutes");
        }

        if (duration > 30) {
          color = "danger";
        } else if (duration > 15) {
          color = "warning";
        } else if (duration > 0) {
          color = "info";
        }
      }

      return {
        duration: duration,
        color: color
      };
    }),
    checkinDuration: Ember.computed("arrivalTime", function () {
      var duration = "";
      var color = "";

      if (this.get("arrivalTime")) {
        if (this.get("timeConsultation")) {
          duration = (0, _moment.default)(this.get("timeConsultation")).diff(this.get("arrivalTime"), "minutes");
        } else {
          duration = (0, _moment.default)().diff(this.get("arrivalTime"), "minutes");
        }

        if (duration > 30) {
          color = "danger";
        } else if (duration > 15) {
          color = "warning";
        } else if (duration > 0) {
          color = "info";
        }
      }

      return {
        duration: duration,
        color: color
      };
    }),
    visitDuration: Ember.computed("arrivalTime", function () {
      var duration = "";
      var color = "";

      if (this.get("timeConsultation")) {
        if (this.get("endConsultation")) {
          duration = (0, _moment.default)(this.get("endConsultation")).diff(this.get("timeConsultation"), "minutes");
        } else {
          duration = (0, _moment.default)().diff(this.get("timeConsultation"), "minutes");
        }

        if (duration > 30) {
          color = "danger";
        } else if (duration > 15) {
          color = "warning";
        } else if (duration > 0) {
          color = "info";
        }
      }

      return {
        duration: duration,
        color: color
      };
    }),
    checkoutDuration: Ember.computed("arrivalTime", function () {
      var duration = "";
      var color = "";

      if (this.get("endConsultation")) {
        if (this.get("exitTime")) {
          duration = (0, _moment.default)(this.get("exitTime")).diff(this.get("endConsultation"), "minutes");
        } else {
          duration = (0, _moment.default)().diff(this.get("endConsultation"), "minutes");
        }

        if (duration > 30) {
          color = "danger";
        } else if (duration > 15) {
          color = "warning";
        } else if (duration > 0) {
          color = "info";
        }
      }

      return {
        duration: duration,
        color: color
      };
    }),
    done: Ember.computed("exitTime", function () {
      return this.get("exitTime");
    }),
    isAppointmentStatus11or7or8: Ember.computed("done", "status", function () {
      return this.get("done") || this.get("status") == 11 || this.get("status") == 7 || this.get("status") == 8;
    }),
    top: Ember.computed("start", function () {
      var start = (0, _moment.default)(this.get("start"));

      if (start.minutes() < 30) {
        return start.minutes();
      } else {
        return start.minutes() - 30;
      }
    }),
    height: Ember.computed("start", "end", function () {
      if (this.get("end")) {
        var midnight = (0, _moment.default)(this.get("start")).add(1, "d").startOf("days");
        var newEnd = (0, _moment.default)(this.get("end")).isAfter(midnight) ? midnight : this.get("end");
        var diff = (0, _moment.default)(newEnd).diff(this.get("start"), "minutes");
        return diff ? diff : 15;
      } else {
        return this.get("defaultDuration");
      }
    }),
    calendarSteps: Ember.computed("start", "end", function () {
      var minDiff = (0, _moment.default)(this.get("end")).diff(this.get("start"), "minutes");
      var quotient = Math.floor(minDiff / 30);
      var remainder = minDiff % 30;
      return remainder > 0 ? ++quotient : quotient;
    }),
    alertMessage: Ember.computed("status", function () {
      var alertMessage = "";

      switch (this.get("status")) {
        case 6:
          alertMessage = "alerts.appointmentCheckout";
          break;

        case 7:
          alertMessage = "alerts.appointmentMissed";
          break;

        case 8:
          alertMessage = "alerts.appointmentGivenUp";
          break;

        case 11:
          alertMessage = "alerts.appointmentRescheduled";
          break;
      }

      return alertMessage;
    }),
    statusShown: Ember.computed("status", "environmentTypes", function () {
      var _this = this;

      return this.get("environmentTypes").filter(function (e) {
        return e.id === _this.get("status");
      })[0].name;
    }),
    display: Ember.computed("category.name", "type.name", function () {
      return Ember.isEmpty(this.get("type.name")) ? this.get("category.name") : this.get("type.name");
    }),
    missed: Ember.computed("status", function () {
      return this.get("status") === 7;
    }),
    gaveUp: Ember.computed("status", function () {
      return this.get("status") === 8;
    }),
    rescheduled: Ember.computed("status", function () {
      return this.get("status") === 11;
    }),
    scheduled: Ember.computed("status", function () {
      return this.get("status") !== 2 && this.get("status") !== 11;
    })
  });
  /*
  STATUS
  1 - Scheduled
  2 - Checkin without scheduling
  3 - Checkin with scheduling
  4 - In consultation
  5 - In checkout
  6 - Checked out
  7 - Missed
  8 - Gave up
  9 - Paused
  10 - Get Out
  11 - Rescheduled
  */


  _exports.default = _default;
});