define("crm/models/stay", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    startDate: _emberData.default.attr("date"),
    endDate: _emberData.default.attr("date"),
    box: _emberData.default.belongsTo("box", {
      inverse: "stays"
    }),
    patient: _emberData.default.belongsTo("patient"),
    hasStartAndEnd: Ember.computed("startDate", "endDate", function () {
      return !Ember.isEmpty(this.get("startDate")) && !Ember.isEmpty(this.get("endDate"));
    })
  });

  _exports.default = _default;
});