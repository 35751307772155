define("crm/helpers/initials-description-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialsDescriptionDisplay = initialsDescriptionDisplay;
  _exports.default = void 0;

  function initialsDescriptionDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return this.get("intl").t("initials.urineNormal.description");

        case 2:
          return this.get("intl").t("initials.urineConcentrated.description");

        case 3:
          return this.get("intl").t("initials.urineNormalByExpression.description");

        case 4:
          return this.get("intl").t("initials.urineConcentratedByExpression.description");

        case 5:
          return this.get("intl").t("initials.urineJaundiced.description");

        case 6:
          return this.get("intl").t("initials.hematuria.description");

        case 7:
          return this.get("intl").t("initials.faecesNormal.description");

        case 8:
          return this.get("intl").t("initials.faecesLiquid.description");

        case 9:
          return this.get("intl").t("initials.faecesLiquidWithBlood.description");

        case 10:
          return this.get("intl").t("initials.faecesHard.description");

        case 11:
          return this.get("intl").t("initials.faecesHardWithBlood.description");

        case 12:
          return this.get("intl").t("initials.faecesSoft.description");

        case 13:
          return this.get("intl").t("initials.feedingNormal.description");

        case 14:
          return this.get("intl").t("initials.feedingForced.description");

        case 15:
          return this.get("intl").t("initials.vomitFood.description");

        case 16:
          return this.get("intl").t("initials.vomitFoamy.description");

        case 17:
          return this.get("intl").t("initials.vomitBile.description");

        case 18:
          return this.get("intl").t("initials.foodNormal.description");

        case 19:
          return this.get("intl").t("initials.foodForced.description");

        case 20:
          return this.get("intl").t("initials.didntEat.description");

        case 21:
          return this.get("intl").t("initials.fasting.description");

        case 22:
          return this.get("intl").t("initials.faecesSoftMoulded.description");

        case 23:
          return this.get("intl").t("initials.catheterRightForelimb.description");

        case 24:
          return this.get("intl").t("initials.catheterLeftForelimb.description");

        case 25:
          return this.get("intl").t("initials.catheterRightHindlimb.description");

        case 26:
          return this.get("intl").t("initials.catheterLeftHindlimb.description");

        case 27:
          return this.get("intl").t("initials.noFaeces.description");

        case 28:
          return this.get("intl").t("initials.noUrine.description");

        case 29:
          return this.get("intl").t("initials.faecesDigestedBlood.description");

        case 30:
          return this.get("intl").t("initials.faecesLivingBlood.description");

        case 31:
          return this.get("intl").t("initials.vomitWithBlood.description");

        case 32:
          return this.get("intl").t("initials.vomitRegurgitation.description");

        case 33:
          return this.get("intl").t("initials.foodTube.description");

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});