define("crm/components/pdf-abstract-viewer", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    docWidth: 210,
    docHeight: 297 - 12.5 - 15,
    leftMargin: 20,
    fontSizeDefault: 9,
    sortedFields: Ember.computed("model.fields.[]", function () {
      return this.get("model.fields").sort(function (a, b) {
        return Ember.get(a, "type.orderNumber") - Ember.get(b, "type.orderNumber");
      });
    }),
    steps: Ember.computed("model.nameToCall.name", function () {
      if (!this.get("model")) {
        return [];
      }

      var steps = [{
        setFont: "courier"
      }];
      this.defaultReport(steps, this.header(steps, this.get("model.nameToCall.name")));
      this.puStamp(steps);
      this.footer(steps, this.get("model.organization"));
      return steps;
    }),
    addImage: function addImage(steps, height, organization) {
      var imageHeight = 21.6;
      var avatar = organization.get("avatar");

      if (!Ember.isEmpty(avatar) && avatar.get("width")) {
        var imageWidth = avatar.get("width") * imageHeight / avatar.get("height");
        var left = (210 - imageWidth) / 2;
        steps.push({
          addImage: [avatar.get("base64"), avatar.get("file"), left, height, imageWidth, imageHeight]
        });
      } else {
        this.addCentered(steps, height + 10, organization.get("name"), 13);
      }

      return height += imageHeight; // image height
    },
    header: function header(steps, title) {
      var height = 8;
      height = this.addImage(steps, height, this.get("model.organization"));

      if (title) {
        height += 7; // title margin top

        height += 5; // line height

        this.addCentered(steps, height, title, 15);
        height += 15; // title margin bottom
      }

      steps.push({
        setFontSize: this.get("fontSizeDefault")
      });
      return height;
    },
    footer: function footer(steps, organization) {
      var footer = organization.get("footer");

      if (footer) {
        var height = this.addCentered(steps, this.get("docHeight"), "______________________________________________________________________");
        height = this.addCentered(steps, height, Ember.get(footer, "line1"), 8);
        height = this.addCentered(steps, height, Ember.get(footer, "line2"), 7);

        if (Ember.get(footer, "line3")) {
          this.addCentered(steps, height, Ember.get(footer, "line3"), 7);
        }
      }
    },
    addLocationDate: function addLocationDate(steps, height) {
      var locationDate = this.get("model").organization.get("town") ? this.get("model").organization.get("town") + ", " + (0, _moment.default)().format("YYYY-MM-DD") : (0, _moment.default)().format("YYYY-MM-DD");
      return this.addParagraphWithTab(steps, height || 235, locationDate, this.get("leftMargin") + 10);
    },
    addSignature: function addSignature(steps, height, text, fontSize) {
      fontSize = fontSize || this.get("fontSizeDefault");
      steps.push({
        setFontSize: fontSize
      });
      height += 20;
      var line = "___________________________________";
      this.addCentered(steps, height, line, fontSize);
      height += 5;
      steps.push({
        setFontSize: 10
      });
      this.addCentered(steps, height, text, fontSize);
      height += 5;
      return height;
    },
    addTableLine: function addTableLine(steps, height, row) {
      var _this = this;

      var fontSize = this.get("fontSizeDefault");
      row.forEach(function (cell) {
        _this.lineHeight(fontSize);

        steps.push({
          text: [cell[0], height, cell[1]]
        });
      });
      return height + 5;
    },
    addParagraph: function addParagraph(steps, height, text, fontSize) {
      var _this2 = this;

      if (!text) {
        return height;
      }

      fontSize = fontSize || this.get("fontSizeDefault");
      height += 1; // paragraph top margin

      if (text.includes("\n")) {
        text.split("\n").forEach(function (p) {
          _this2.breakLines(p.split(" "), fontSize).forEach(function (l) {
            height += _this2.lineHeight(fontSize) + 1;
            steps.push({
              text: [_this2.get("leftMargin"), height, l]
            });
          });
        });
      } else {
        this.breakLines(text.split(" "), fontSize).forEach(function (l) {
          height += _this2.lineHeight(fontSize) + 1;
          steps.push({
            text: [_this2.get("leftMargin"), height, l]
          });
        });
      }

      height += 1; // paragraph bottom margin

      return height;
    },
    addParagraphWithTab: function addParagraphWithTab(steps, height, text, tab) {
      var _this3 = this;

      tab = tab || this.get("leftMargin") + 5;
      var fontSize = this.get("fontSizeDefault");
      height += 1; // paragraph top margin

      if (text.includes("\n")) {
        text.split("\n").forEach(function (p) {
          _this3.breakLines(p.split(" "), fontSize).forEach(function (l) {
            height += _this3.lineHeight(fontSize) + 1;
            steps.push({
              text: [tab, height, l]
            });
          });
        });
      } else {
        this.breakLines(text.split(" "), fontSize).forEach(function (l) {
          height += _this3.lineHeight(fontSize) + 1;
          steps.push({
            text: [tab, height, l]
          });
        });
      }

      height += 1; // paragraph bottom margin

      return height;
    },
    addColumnParagraph: function addColumnParagraph(steps, height, text, right) {
      return this.addColumnLines(steps, height, this.breakLinesColumn(text.split(" ")), right);
    },
    addColumnLines: function addColumnLines(steps, height, lines, right) {
      var _this4 = this;

      lines.forEach(function (l) {
        if (l) {
          steps.push({
            text: [right ? 5 + _this4.get("docWidth") / 2 : _this4.get("leftMargin"), height, l]
          });
        } else {
          steps.push({
            text: [right ? 5 + _this4.get("docWidth") / 2 : _this4.get("leftMargin"), height, ""]
          });
        }

        height += 5; // line height
      });
      height += 0; // paragraph bottom margin

      return height;
    },
    addCentered: function addCentered(steps, height, text, fontSize) {
      fontSize = fontSize || this.get("fontSizeDefault");
      steps.push({
        setFontSize: fontSize
      });
      var left = (this.columns(fontSize) - text.length) / 2 * this.textSize(fontSize);
      steps.push({
        text: [left, height, text]
      });
      height += 5;
      return height;
    },
    addSquare: function addSquare(steps, height, width) {
      steps.push({
        setFillColor: "#EDEDED"
      });
      steps.push({
        rect: [width, height, 5, 5, "F"]
      });
    },
    ageDisplay: function ageDisplay(params) {
      var years = (0, _moment.default)().diff(params, 'years');
      var months = (0, _moment.default)().subtract(years, 'year').diff(params, 'months');
      var result = "";

      if (years === 1) {
        result += years + " " + this.get('intl').t('enums.chronos.year') + " ";
      } else if (years > 1) {
        result += years + " " + this.get('intl').t('enums.chronos.years') + " ";
      }

      if (months === 1) {
        result += months + " " + this.get('intl').t('enums.chronos.month') + " ";
      }

      if (months > 1) {
        result += months + " " + this.get('intl').t('enums.chronos.months') + " ";
      }

      if (years < 1 && months < 1) {
        var days = (0, _moment.default)().subtract(months, 'month').diff(params, 'days');

        if (days === 1) {
          result += days + " " + this.get('intl').t('enums.chronos.day') + " ";
        } else {
          result += days + " " + this.get('intl').t('enums.chronos.days') + " ";
        }
      }

      return result;
    },
    printTable: function printTable(steps, height, start, end) {
      var self = this;
      var sortedFields = this.get("sortedFields");
      var i = start ? start : 0;
      var length = end ? end - 1 : Ember.get(sortedFields, "length");

      for (i; i < length; i++) {
        self.addColumnParagraph(steps, height, Ember.get(sortedFields[i], "name"));
        height = self.addColumnParagraph(steps, height, self.valueToDisplay(Ember.get(sortedFields[i], "type"), Ember.get(sortedFields[i], "notes")), true);
        height = self.checkPageBreack(steps, height);
      }

      return height;
    },
    printFields: function printFields(steps, height, start,
    /*firstExcluded*/
    end) {
      var self = this;
      var sortedFields = this.get("sortedFields");
      var i = start ? start : 0;
      var length = end ? end - 1 : Ember.get(sortedFields, "length");

      for (i; i < length; i++) {
        var key = Ember.get(sortedFields[i], "name");
        var value = self.valueToDisplay(Ember.get(sortedFields[i], "type"), Ember.get(sortedFields[i], "notes"));
        height = self.printField(steps, height, key, value);
        height = self.checkPageBreack(steps, height);
      }

      return height;
    },
    printField: function printField(steps, height, key, value) {
      steps.push({
        setFontType: "bold"
      });
      height = this.addParagraph(steps, height + 5, key);
      steps.push({
        setFontSize: this.get("fontSizeDefault")
      });
      steps.push({
        setFontType: "normal"
      });
      height = this.addParagraph(steps, height - 1, value);
      return height;
    },
    checkPageBreack: function checkPageBreack(steps, height, threshold) {
      if (height > (threshold || this.get("docHeight"))) {
        this.footer(steps, this.get("model.organization"));
        steps.push({
          addPage: []
        });
        height = 10 + this.header(steps);
      }

      return height;
    },
    breakLines: function breakLines(words, fontSize, columns, marginLeft) {
      marginLeft = marginLeft || this.get("leftMargin");
      columns = columns || this.columns(fontSize);
      var leftMarginChars = marginLeft / (this.get("docWidth") / columns);
      return this.breakLinesAux(columns - leftMarginChars * 2, words, []);
    },
    breakLinesColumn: function breakLinesColumn(words, fontSize, columns, marginLeft) {
      fontSize = fontSize || this.get("fontSizeDefault");
      columns = columns || this.columns(fontSize) / 2;
      marginLeft = marginLeft || this.get("leftMargin");
      var leftMarginChars = marginLeft / (this.get("docWidth") / 2 / columns);
      return this.breakLinesAux(columns - leftMarginChars, words, []);
    },
    breakLinesAux: function breakLinesAux(size, words, result) {
      if (words.length === 1) {
        result.push(words[0]);
        return result;
      }

      var phrase = words[0];
      var i = 1;

      for (; i < words.length; i++) {
        if (phrase.length + words[i].length + 1 < size) {
          phrase += " " + words[i];
        } else {
          break;
        }
      }

      result.push(phrase);

      if (i === words.length) {
        return result;
      } else {
        return this.breakLinesAux(size, words.slice(i), result);
      }
    },
    columns: function columns(fontSize) {
      switch (fontSize) {
        case 7:
          return 141;

        case 8:
          return 124;

        case 9:
          return 110;

        case 10:
          return 99;

        case 11:
          return 90;

        case 12:
          return 82;

        case 13:
          return 76;

        case 14:
          return 73;

        case 15:
          return 66;

        case 16:
          return 62;

        case 17:
          return 58;

        case 18:
          return 55;

        case 19:
          return 52;

        case 20:
          return 49;

        case 21:
          return 47;

        case 22:
          return 45;

        default:
          null;
      }
    },
    lineHeight: function lineHeight(fontSize) {
      switch (fontSize) {
        case 7:
          return 2;

        case 8:
          return 3;

        case 9:
          return 3;

        case 10:
          return 3;

        case 11:
          return 3;

        case 12:
          return 3;

        case 13:
          return 3;

        case 14:
          return 4;

        case 15:
          return 4;

        case 16:
          return 4;

        case 17:
          return 5;

        case 18:
          return 5;

        case 19:
          return 5;

        case 20:
          return 6;

        case 21:
          return 6;

        case 22:
          return 6;

        default:
          null;
      }
    },
    textSize: function textSize(fontSize) {
      return 210 / this.columns(fontSize);
    },
    patientHeader: function patientHeader(steps, height, patient) {
      steps.push({
        setFontSize: this.get("fontSizeDefault") - 1
      });
      var patientText = ["Paciente"];

      if (patient.get("name")) {
        patientText.push(patient.get("name"));
      }

      if (patient.get("sex.name")) {
        if (patient.get("birthdate")) {
          patientText.push(patient.get("sex.name") + ", " + this.ageDisplay(patient.get("birthdate")));
        } else {
          patientText.push(patient.get("sex.name"));
        }
      } else if (patient.get("birthdate")) {
        patientText.push(this.ageDisplay(patient.get("birthdate")));
      }

      if (patient.get("chip")) {
        patientText.push("Chip n.º:" + patient.get("chip"));
      }

      if (patient.get("species.name")) {
        if (patient.get("breed.name")) {
          patientText.push(patient.get("species.name") + ", " + patient.get("breed.name"));
        } else {
          patientText.push(patient.get("species.name"));
        }
      } else if (patient.get("breed.name")) {
        patientText.push(patient.get("breed.name"));
      }

      var i = 0;
      var aux = "";
      var textWith = this.columns(this.get("fontSizeDefault")) / 2 - this.get("leftMargin");

      for (; i < textWith; i++) {
        aux += "_";
      }

      this.addColumnLines(steps, height, [aux]);
      this.addColumnLines(steps, height, [aux], true);
      var heightLeft = this.addColumnLines(steps, height, patientText);
      var heightRight = this.addColumnLines(steps, height, ["Tutor", patient.get("owner.name")], true);
      steps.push({
        setFontSize: this.get("fontSizeDefault")
      });
      height = heightRight > heightLeft ? heightRight : heightLeft;
      return height + 10;
    },
    puStamp: function puStamp(steps) {
      steps.push({
        setFontSize: 7
      });
      steps.push({
        text: [15, 265, "Processado por computador - Pet Universal, " + (0, _moment.default)().format("YYYY/MM/DD") + ", " + (0, _moment.default)().format("HH:mm"), 90]
      });
    },
    sign: function sign(steps, height) {
      height = this.checkPageBreack(steps, height, 230);
      height = 230;
      var signatureName = "Veterinário(a)";
      signatureName = Ember.isEmpty(this.get("model").consultation) || Ember.isEmpty(this.get("model").consultation.get("worker1")) || Ember.isEmpty(this.get("model").consultation.get("worker1.name")) ? signatureName : "Dr.(a) " + this.get("model").consultation.get("worker1.name");
      signatureName = Ember.isEmpty(this.get("model").worker) || Ember.isEmpty(this.get("model").worker.get("name")) ? signatureName : "Dr.(a) " + this.get("model").worker.get("name");
      return this.addSignature(steps, height, signatureName);
    },
    signOwner: function signOwner(steps, height) {
      height += 10;
      var text1 = "Se não for o próprio a assinar: _____________________________";
      this.addParagraph(steps, height, text1);
      height += 10;
      var text2 = "Grau de Parentesco/Tipo de Representação: ______________________";
      this.addParagraph(steps, height, text2);
      height += 5;
      var text3 = "CC nº ______________________ Validade _____/_____/_______";
      this.addParagraph(steps, height, text3);
      height += 10;
      return this.addSignature(steps, height, "Tutor(a)");
    },
    defaultReport: function defaultReport(steps, height) {
      var _this5 = this;

      var self = this;
      height = this.patientHeader(steps, height, this.get("model").consultation.get("patient"));
      steps.push({
        setFontSize: 14
      });

      if (Ember.get(this.get("model"), "nameToCall.reportSections")) {
        Ember.get(this.get("model"), "nameToCall.reportSections").forEach(function (section) {
          height = _this5.addParagraph(steps, height, section.get("name"));
          height += 5;
          steps.push({
            setFontSize: _this5.get("fontSizeDefault")
          });

          if (section.get("reportFields")) {
            section.get("reportFields").forEach(function (field) {
              if (Ember.get(field, "notes") === "yes" || Ember.get(field, "notes") === "no" || Ember.get(field, "value") === "yes" || Ember.get(field, "value") === "no") {
                height = _this5.addParagraph(steps, height, (Ember.get(field, "templateSectionField") ? Ember.get(field, "name") : Ember.get(field, "name")) + ": " + self.valueToDisplay(Ember.get(field, "type"), Ember.get(field, "notes")));
              } else {
                height = _this5.addParagraph(steps, height, (Ember.get(field, "templateSectionField") ? Ember.get(field, "name") : Ember.get(field, "name")) + ": " + self.valueToDisplay(Ember.get(field, "type"), Ember.get(field, "notes")));
              }
            });
          }
        });
      }

      var leftFlag = true;
      var legtH = 0;
      var rightH = 0;

      if (this.get("model.nameToCall.exam.filesSorted.length")) {
        height += 10;
        this.get("model.nameToCall.exam.filesSorted").reverse().forEach(function (f) {
          var imageWidth = self.get("docWidth") / 2 - self.get("leftMargin") - 10;
          var imageHeight = 1 * imageWidth / 1;
          var left = self.get("docWidth") / 2 + 10;
          var img = new Image();
          img.src = f.get("url");

          if (leftFlag) {
            leftFlag = false;
            left = self.get("leftMargin");
            legtH = imageHeight;
            height += 5;
          } else {
            leftFlag = true;
            rightH = imageHeight;
          }

          height = _this5.checkPageBreack(steps, height, _this5.get("docHeight") - imageHeight);
          steps.push({
            addImage: [img, 'jpg', left, height, imageWidth, imageHeight]
          });

          if (rightH) {
            height = height + (rightH > legtH ? rightH : legtH);
            legtH = 0;
            rightH = 0;
          }
        });

        if (legtH) {
          height += legtH > rightH ? legtH : rightH;
        }
      }

      this.sign(steps, height);
    },
    valueToDisplay: function valueToDisplay(type, value) {
      if (Ember.isEmpty(value)) {
        return "";
      }

      switch (type) {
        case 0:
          break;

        case 1:
          break;

        case 2:
          value = !Ember.isEmpty(value) && (0, _moment.default)(value).isValid() ? (0, _moment.default)(value).format("YYYY-MM-DD") : "";
          break;

        case 3:
          value = value == "true" ? "Sim" : value == "false" ? "Não" : "";
          break;

        case 4:
          break;

        case 5:
          break;

        case 6:
          break;

        case 7:
          break;

        case 8:
          break;

        default:
          break;
      }

      return value || "";
    }
  });

  _exports.default = _default;
});