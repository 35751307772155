define("crm/components/pet-models-table", ["exports", "moment", "universe-gui/components/u-models-table"], function (_exports, _moment, _uModelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uModelsTable.default.extend({
    htmlTableToExcel: Ember.inject.service(),
    intl: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      var tableID = this.$('table').attr('id');
      var entity = "";

      if (this.get("data.modelName")) {
        if ("breed" === this.get("data.modelName")) {
          entity = " - " + this.get("intl").t("configurations.breeds.title");
        } else {
          var translation = this.get("intl").t("pages." + this.get("data.modelName") + "s");

          if (!translation.includes("Missing translation")) {
            entity = " - " + this.get("intl").t("pages." + this.get("data.modelName") + "s");
          }
        }
      }

      this.$().prepend("<a class='btn btn-default btn-excel hidden-print text-primary pull-right'><i class='fa fa-file-excel-o'></i></a>");
      this.$(".btn-excel").click(function () {
        var exportOptions = {
          fileName: "Pet Universal" + entity + " - " + (0, _moment.default)().format("YYYY-MM-DD"),
          sheetName: "Pet Universal"
        };
        self.get("htmlTableToExcel").exportExcelFromTableId(tableID, exportOptions);
      });
    }
  });

  _exports.default = _default;
});