define("crm/models/sms-sent", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    organization: _emberData.default.belongsTo("organization"),
    campaign: _emberData.default.belongsTo("campaign", {
      inverse: "sms"
    }),
    patientID: _emberData.default.belongsTo("patient"),
    phoneNumber: _emberData.default.attr("string"),
    text: _emberData.default.attr("string"),
    periodic: _emberData.default.belongsTo("periodic", {
      inverse: "smsSent"
    }),
    sendDate: _emberData.default.attr('localdatetime'),
    patient: _emberData.default.belongsTo("patient"),
    customer: _emberData.default.belongsTo("customer"),
    customerName: _emberData.default.attr("string"),
    patientName: _emberData.default.attr("string")
  });

  _exports.default = _default;
});