define("crm/models/purchase-order", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    code: _emberData.default.attr("number"),
    date: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    status: _emberData.default.attr("number"),
    payableAmount: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    supplier: _emberData.default.belongsTo("supplier"),
    purchaseOrderLines: _emberData.default.hasMany("purchase-order-line"),
    isMarkedDone: Ember.computed("purchaseOrderLines.[]", function () {
      var isMarkedDone = true;
      this.get("purchaseOrderLines").forEach(function (line) {
        if (!line.get("deleted")) {
          isMarkedDone = false;
          return isMarkedDone;
        }
      });
      return isMarkedDone;
    })
  });
  /*
  Statuses
  
  null - Received all
  0 - Under construction
  1 - Ready to order
  2 - Rejected
  3 - Sent to supplier
  4 - Failed
  8 - Canceled
  9 - Hidden
  */


  _exports.default = _default;
});