define("crm/components/internment/todo-exam-new", ["exports", "crm/components/internment/todo-new"], function (_exports, _todoNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoNew.default.extend({
    modelName: "todo-exam"
  });

  _exports.default = _default;
});