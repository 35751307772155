define("crm/routes/organizations/organization/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    queryParams: {
      search: {
        refreshModel: true
      },
      filterDisable: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      var self = this;
      var organization = this.modelFor("organizations.organization");

      if (params.search) {
        return this.store.query("item", {
          company: organization.get("company.id"),
          query: params.search,
          enable: params.filterDisable ? false : true
        }).then(function (savedItems) {
          var filtered = savedItems.filterBy("code", Number(params.search));

          if (!Ember.isEmpty(filtered)) {
            self.transitionTo('organizations.organization.items.item', filtered.get("firstObject.id"));
          } else {
            filtered = savedItems.filterBy("barCode", Number(params.search));

            if (!Ember.isEmpty(filtered)) {
              self.transitionTo('organizations.organization.items.item', filtered.get("firstObject.id"));
            } else {
              return savedItems;
            }
          }
        });
      } else if (params.filterDisable) {
        return this.store.query("item", {
          organization: organization.get("id"),
          latest: 10,
          enable: false
        });
      } else {
        return this.store.query("item", {
          organization: organization.get("id"),
          latest: 10,
          enable: true
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("search", null);
      }
    },
    actions: {
      openCreateItem: function openCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      },
      openEntryItem: function openEntryItem() {
        this.send('openModalEntryItem');
      },
      openEditItem: function openEditItem(itemToEdit) {
        this.send('openEditItemModal', itemToEdit);
      },
      openMoveStock: function openMoveStock(item) {
        this.send('openMoveStockModal', item);
      }
    }
  });

  _exports.default = _default;
});