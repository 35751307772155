define("crm/components/current-account-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    allowReceipt: Ember.computed("record.amount", function () {
      return this.get("record.amount") > 0;
    }),
    allowPayment: Ember.computed("record.amount", function () {
      return this.get("record.amount") < 0;
    }),
    canDoCurrentAccounts: Ember.computed("can", function () {
      //return this.get("can").can("do sale"); TODOJD
      return true;
    }),
    actions: {
      openReceiptGenerate: function openReceiptGenerate() {
        this.sendAction("openModal", {
          entity: "receipt",
          action: "generate",
          model: {
            customer: this.get("record.customer")
          }
        });
      },
      openPaymentGenerate: function openPaymentGenerate() {
        this.sendAction("openModal", {
          entity: "payment",
          action: "generate",
          model: {
            customer: this.get("record.customer")
          }
        });
      },
      openEditDebt: function openEditDebt() {
        this.sendAction("openModal", {
          entity: "debt",
          action: "edit",
          model: {
            debt: this.get("record")
          }
        });
      }
    }
  });

  _exports.default = _default;
});