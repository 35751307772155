define("crm/controllers/organizations/organization/items/item/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    es: Ember.inject.service(),
    pv: null,
    pvp: null,
    changePv: false,
    changePvp: false,
    nameRegex: "^[^<>]+$",
    showPeriod: false,
    showMessage: false,
    itemItems: [],
    oldString: null,
    marginTypes: Ember.computed(function () {
      var result = ["Markup", "Margem"];
      return result;
    }),
    marginDimensions: Ember.computed(function () {
      var result = ["%", this.get("intl").t("icons.currency")];
      return result;
    }),
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    parentIsExam: Ember.computed("model.type.isExam", "parameters.length", function () {
      return this.get("model.type.isExam") && this.get("parameters.length");
    }),
    parentIsDrug: Ember.observer("model.type.isDrug", "organizationPet.usesDecimals", function () {
      this.set("model.clinicBillingType", this.get("organizationPet.usesDecimals") ? null : 2);
    }),
    periodTypes: Ember.computed("catalogs.periodTypes", function () {
      return this.get("catalogs.periodTypes");
    }),
    periodSelect: Ember.computed("model.period", "periodTypes", function () {
      if (this.get("model.period")) {
        var self = this;
        var time = this.get("model.period");

        if (time >= 8760) {
          return self.get("periodTypes").filterBy("id", 8760).get("firstObject");
        } else if (time >= 720) {
          return self.get("periodTypes").filterBy("id", 720).get("firstObject");
        } else if (time >= 168) {
          return self.get("periodTypes").filterBy("id", 168).get("firstObject");
        } else {
          return self.get("periodTypes").filterBy("id", 24).get("firstObject");
        }
      } else {
        return null;
      }
    }),
    sameDimensionBaseUnits: Ember.computed("model.organization.company.units.@each.unitDimension", "model.baseUnit.unitDimension.id", function () {
      var self = this;
      return this.get("model.organization.company.units").filterBy("unitDimension.id", self.get("model.baseUnit.unitDimension.id"));
    }),
    setOfPvp: Ember.observer("model.pvpMargin", "model.pvpMargin2", "model.pvpMargin3", "model.purchasePrice", function () {
      if (this.get("model.pvpMargin") || this.get("model.purchasePrice")) {
        this.send("setOfPvpWithPurchasePrice");
      }

      if (this.get("model.pvpMargin2") || this.get("model.purchasePrice")) {
        this.send("setOfPvp2WithPurchasePrice");
      }

      if (this.get("model.pvpMargin3") || this.get("model.purchasePrice")) {
        this.send("setOfPvp3WithPurchasePrice");
      }
    }),
    pvChanged: Ember.observer("model.salePrice", "model.vat.vat.amount", "changePv", "changePvp", function () {
      if (this.get("changePv") == true) {
        if ((this.get("model.vat.vat.amount") || this.get("model.vat.vat.amount") == 0) && this.get("model.salePrice")) {
          this.set("model.pvp", (Number(this.get("model.salePrice")) + this.get("model.salePrice") * (this.get("model.vat.vat.amount") / 100)).toFixed(2));
        }
      }
    }),
    compoundProductTotal: Ember.computed("itemItems.[]", "model.pvp", "itemItems.@each.displayLinePrice", function () {
      var total = this.get("model.pvp") ? Number(this.get("model.pvp")) : 0;

      if (this.get("itemItems.length")) {
        this.get("itemItems").forEach(function (itemItem) {
          total += itemItem.get("displayLinePrice") ? itemItem.get("displayLinePrice") : 0;
        });
      }

      return total;
    }),
    pvpChanged: Ember.observer("model.pvp", "model.vat.vat.amount", "changePv", "changePvp", function () {
      if (this.get("changePvp") == true) {
        if ((this.get("model.vat.vat.amount") || this.get("model.vat.vat.amount") == 0) && this.get("model.pvp")) {
          this.set("model.salePrice", (this.get("model.pvp") * 100 / (100 + this.get("model.vat.vat.amount"))).toFixed(2));
        }
      }
    }),
    invoicesByItem: Ember.computed("model.id", function () {
      if (this.get("model.id")) {
        return this.get("store").query("invoice", {
          item: this.get("model.id")
        });
      }
    }),
    invoicesByItemFiltered: Ember.computed("invoicesByItem.@each.status", function () {
      return this.get("invoicesByItem").filter(function (i) {
        return i.get("status") !== 8 && i.get("status") !== 9;
      });
    }),
    cannotEdit: Ember.computed("model.baseUnit", "invoicesByItemFiltered.@each.billingID", function () {
      return this.get("model.baseUnit.id") && !Ember.isEmpty(this.get("invoicesByItemFiltered")) && !Ember.isEmpty(this.get("invoicesByItemFiltered").filter(function (invoice) {
        return invoice.get("billingID");
      }));
    }),
    nameHasError: Ember.computed("model.name", "nameRegex", function () {
      return this.get("model.name").match(this.get("nameRegex"));
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    requiredFieldSales: Ember.computed("selectedSalesUnitQuantity", "selectedSalesUnit", function () {
      var result = false;

      if (this.get("selectedSalesUnitQuantity") > 0) {
        if (!this.get("selectedSalesUnit")) {
          result = true;
        }
      } else if (this.get("selectedSalesUnit")) {
        result = true;
      }

      return result;
    }),
    requiredFieldPurchase: Ember.computed("selectedPurchaseUnitQuantity", "selectedPurchaseUnit", function () {
      var result = false;

      if (this.get("selectedPurchaseUnitQuantity") > 0) {
        if (!this.get("selectedPurchaseUnit")) {
          result = true;
        }
      } else if (this.get("selectedPurchaseUnit")) {
        result = true;
      }

      return result;
    }),
    actions: {
      modelSimplified: function modelSimplified() {
        this.send("openModal", {
          entity: "item-simplified",
          action: "create",
          model: {
            itemItems: this.get("itemItems"),
            parentItem: this.get("model")
          }
        });
      },
      setOfPvpWithPurchasePrice: function setOfPvpWithPurchasePrice() {},
      setOfPvp2WithPurchasePrice: function setOfPvp2WithPurchasePrice() {},
      setOfPvp3WithPurchasePrice: function setOfPvp3WithPurchasePrice() {},
      setPvpMargin: function setPvpMargin(value) {
        this.set("model.pvpMargin", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginType: function setMarginType(value) {
        this.set("selectedMarginType", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginDimension: function setMarginDimension(value) {
        this.set("selectedMarginDimension", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginType2: function setMarginType2(value) {
        this.set("selectedMarginType2", value);
        this.send("setOfPvp2WithPurchasePrice");
      },
      setMarginDimension2: function setMarginDimension2(value) {
        this.set("selectedMarginDimension2", value);
        this.send("setOfPvp2WithPurchasePrice");
      },
      setMarginType3: function setMarginType3(value) {
        this.set("selectedMarginType3", value);
        this.send("setOfPvp3WithPurchasePrice");
      },
      setMarginDimension3: function setMarginDimension3(value) {
        this.set("selectedMarginDimension3", value);
        this.send("setOfPvp3WithPurchasePrice");
      },
      setTax: function setTax(taxChosen) {
        this.set("model.vat", this.get("taxes").find(function (tax) {
          return tax.id == taxChosen;
        }));
        this.set("changePvp", false);
        this.set("changePv", true);
        this.send("setOfPvpWithPurchasePrice");
        this.send("setOfPvp2WithPurchasePrice");
        this.send("setOfPvp3WithPurchasePrice");
      },
      setPurchaseUnit: function setPurchaseUnit(unit) {
        if (Ember.isEmpty(unit)) {
          this.get("model.stockItemUnit").destroyRecord();
          this.set("selectedPurchaseUnit", null);
          this.set("selectedPurchaseUnitQuantity", null);
        }

        this.set("selectedPurchaseUnit", unit);
      },
      setPurchaseUnitQuantity: function setPurchaseUnitQuantity(quantity) {
        if (isNaN(quantity) || !quantity || quantity === 1) {
          this.set("selectedPurchaseUnit", undefined);
          this.set("selectedPurchaseUnitQuantity", undefined); // explicit null for when it's 1
        } else {
          this.set("selectedPurchaseUnitQuantity", quantity);
        }
      },
      setSalesUnit: function setSalesUnit(unit) {
        if (Ember.isEmpty(unit)) {
          this.get("model.saleItemUnit").destroyRecord();
          this.set("selectedSalesUnit", null);
          this.set("selectedSalesUnitQuantity", null);
        }

        this.set("selectedSalesUnit", unit);
      },
      setSalesUnitQuantity: function setSalesUnitQuantity(quantity) {
        if (isNaN(quantity) || !quantity || quantity === 1) {
          this.set("selectedSalesUnit", undefined);
          this.set("selectedSalesUnitQuantity", undefined); // explicit null for when it's 1
        } else {
          this.set("selectedSalesUnitQuantity", quantity);
        }
      },
      setBaseUnit: function setBaseUnit(unit) {
        this.set('model.baseUnit', unit);
        this.set("selectedSalesUnit", null);
        this.set("selectedSalesUnitQuantity", null);
        this.set("selectedPurchaseUnit", null);
        this.set("selectedPurchaseUnitQuantity", null);

        if (this.get("model.saleItemUnit")) {
          this.get("model.saleItemUnit").destroyRecord();
        }

        if (this.get("model.stockItemUnit")) {
          this.get("model.stockItemUnit").destroyRecord();
        }
      },
      setSelectedPeriod: function setSelectedPeriod(value) {
        this.set("selectedPeriod", value);
        this.set("model.period", value.id);
      },
      setPv: function setPv() {
        this.set("changePv", true);
        this.set("changePvp", false);
      },
      setPvp: function setPvp() {
        this.set("changePv", false);
        this.set("changePvp", true);
      },
      deleteCompoundProductItem: function deleteCompoundProductItem(itemItem) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          var self = this;
          this.set("model.displayPrice", Number(this.get("model.displayPrice")) - Number(itemItem.get("child.children.length") ? itemItem.get("child.displayPrice") : itemItem.get("linePrice") ? itemItem.get("linePrice") : 0));
          this.get("model").save().then(function () {
            itemItem.destroyRecord();
            self.get("itemItems").removeObject(itemItem);
          });
        }
      },
      deleteCompanionProductItem: function deleteCompanionProductItem(companionItem) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          companionItem.destroyRecord();
          this.get("model.companionItems").removeObject(companionItem);
        }
      },
      selectedItem: function selectedItem(_selectedItem) {
        this.set("showLoading", true);
        var self = this;
        var canAdd = true;

        if (this.get("model.id") !== _selectedItem.get("id")) {
          if (!Ember.isEmpty(this.get("itemItems"))) {
            this.get("itemItems").filter(function (item) {
              if (item.get("child.id") == _selectedItem.get("id")) {
                canAdd = false;
                self.set("showMessage", true);
              }
            });
          }

          if (canAdd === true) {
            self.get("store").findRecord("item", _selectedItem.get("id")).then(function (foundItem) {
              self.get("itemItems").addObject(self.get("store").createRecord("itemItem", {
                child: foundItem,
                quantity: 1,
                locked: false
              }));
            });
            self.set("showMessage", false);
          }
        } else {
          this.set("showMessage", true);
        }

        this.set("showLoading", false);
      },
      selectedCompanionItem: function selectedCompanionItem(selectedItem) {
        this.set("showLoading", true);
        var self = this;
        var canAdd = true;

        if (this.get("model.id") !== selectedItem.get("id")) {
          this.get("model.companionItems").filter(function (item) {
            if (item.get("companion.id") == selectedItem.get("id")) {
              canAdd = false;
              self.set("showMessage", true);
            }
          });

          if (canAdd === true) {
            self.get("store").findRecord("item", selectedItem.get("id")).then(function (foundItem) {
              self.get("model.companionItems").addObject(self.get("store").createRecord("item-companion", {
                item: self.get("model"),
                companion: foundItem,
                quantity: 1,
                locked: false
              }));
            });
            self.set("showMessage", false);
          }
        } else {
          this.set("showMessage", true);
        }

        this.set("showLoading", false);
      },
      saveItem: function saveItem() {
        this.persistItem();
      },
      saveCompound: function saveCompound() {
        var self = this;

        if (!Ember.isEmpty(this.get("itemItems"))) {
          this.set("disabledCompoundSubmission", true);
          this.get("itemItems").forEach(function (itemItem) {
            if (!Ember.isEmpty(itemItem.get("child"))) {
              if (!Ember.isEmpty(itemItem.get("parent"))) {
                Ember.set(itemItem, "parent", self.get("model"));
              }
            } else {
              itemItem.set("salePrice", Number(itemItem.get("salePrice")));
            }

            itemItem.save();
          });
          self.set("model.displayPrice", self.get("compoundProductTotal"));
        }

        self.persistItem();
      },
      saveCompanionItem: function saveCompanionItem() {
        var self = this;

        if (!Ember.isEmpty(this.get("model.companionItems"))) {
          this.set("disabledCompanionSubmission", true);
          this.get("model.companionItems").forEach(function (companionItem) {
            companionItem.save().then(function () {
              self.set("disabledCompanionSubmission", false);
              self.get("flashMessages").success(self.get("intl").t("alerts.changesSaved"));
            }).catch(function (result) {
              self.get("es").errorReturned(result);
              self.set("disabledCompanionSubmission", false);
            });
          });
        }
      },
      saveItemItemExam: function saveItemItemExam() {
        var self = this;

        if (!Ember.isEmpty(this.get("itemItems"))) {
          this.set("disabledSaveItemItemExam", true);
          this.get("itemItems").forEach(function (itemItem) {
            itemItem.get("child.content").save().catch(function (result) {
              self.get("es").errorReturned(result);
              self.set("disabledSaveItemItemExam", false);
            });
            self.set("disabledSaveItemItemExam", false);
          });
        }

        this.transitionToRoute("organizations.organization.items.item");
      },
      selectVariationType: function selectVariationType(value) {
        this.set("selectedVariationType", value);
        this.set("model.variationType", value.id);
      }
    },
    persistItem: function persistItem() {
      var self = this;
      this.set("disabledSubmission", true);

      if (this.get("model.stockable")) {
        if (!Ember.isEmpty(this.get("model.organization.warehouses")) && this.get("model.organization.warehouses.length") > 0 && Ember.isEmpty(this.get("model.defaultWarehouse.id"))) {
          this.set("model.defaultWarehouse", this.get("model.organization.warehouses").objectAt(0));
        }
      }

      if (!this.get("model.stockable")) {
        this.set("model.minStock", null);
        this.set("model.maxStock", null);
        this.set("model.companyItemSubType", null);
        this.set("selectedCompanySubType", null);
        this.set("selectedPurchaseUnit", null);
        this.set("selectedPurchaseUnitQuantity", null);
        this.set("selectedSalesUnit", null);
        this.set("selectedSalesUnitQuantity", null);
      }

      if (this.get("model.periodInput")) {
        this.set("model.period", Number(this.get("model.periodInput")) * Number(this.get("periodSelect").id));
      }

      if (this.get("selectedCompanySubType")) {
        this.set("model.companyItemSubType", this.get("selectedCompanySubType"));
      }

      if (Ember.isEmpty(this.get("model.isExam")) || this.get("model.isExam") == false) {
        this.set("model.parameter", null);
      }

      if (this.get("model.children.length")) {
        this.set("model.displayPrice", this.get("compoundProductTotal"));
      }

      if (this.get("selectedMarginType")) {
        if (this.get("selectedMarginType") == "Markup") {
          this.set("model.marginType", 0);
        } else {
          this.set("model.marginType", 1);
        }
      } else {
        this.set("model.marginType", null);
      }

      if (this.get("selectedMarginType2")) {
        if (this.get("selectedMarginType2") == "Markup") {
          this.set("model.marginType2", 0);
        } else {
          this.set("model.marginType2", 1);
        }
      } else {
        this.set("model.marginType2", null);
      }

      if (this.get("selectedMarginType3")) {
        if (this.get("selectedMarginType3") == "Markup") {
          this.set("model.marginType3", 0);
        } else {
          this.set("model.marginType3", 1);
        }
      } else {
        this.set("model.marginType3", null);
      }

      if (this.get("selectedMarginDimension")) {
        if (this.get("selectedMarginDimension") == "%") {
          this.set("model.marginDimension", 0);
        } else {
          this.set("model.marginDimension", 1);
        }
      } else {
        this.set("model.marginDimension", null);
      }

      if (this.get("selectedMarginDimension2")) {
        if (this.get("selectedMarginDimension2") == "%") {
          this.set("model.marginDimension2", 0);
        } else {
          this.set("model.marginDimension2", 1);
        }
      } else {
        this.set("model.marginDimension2", null);
      }

      if (this.get("selectedMarginDimension3")) {
        if (this.get("selectedMarginDimension3") == "%") {
          this.set("model.marginDimension3", 0);
        } else {
          this.set("model.marginDimension3", 1);
        }
      } else {
        this.set("model.marginDimension3", null);
      }

      this.get("model").save().then(function (savedItem) {
        /*
         * Purchase Unit
         */
        if (!Ember.isEmpty(self.get("model.stockItemUnit.id"))) {
          self.set("model.stockItemUnit.unit", self.get("selectedPurchaseUnit"));
          self.set("model.stockItemUnit.quantity", self.get("selectedPurchaseUnitQuantity"));
          self.get("model.stockItemUnit").save().catch(function (result) {
            self.get("es").errorReturned(result);
            self.set("disabledAction", false);
            self.set("disabledSubmission", false);
            self.set("disabledCompoundSubmission", false);
          });
        } else {
          if (self.get("selectedPurchaseUnit") && self.get("selectedPurchaseUnitQuantity") > 0) {
            self.get("store").createRecord("item-unit", {
              item: savedItem,
              unit: self.get("selectedPurchaseUnit"),
              quantity: Number(self.get("selectedPurchaseUnitQuantity")),
              preferred: true,
              inbound: true,
              outbound: false
            }).save();
          }
        }
        /*
         * Sale unit
         */


        if (!Ember.isEmpty(self.get("model.saleItemUnit.id"))) {
          self.set("model.saleItemUnit.unit", self.get("selectedSalesUnit"));
          self.set("model.saleItemUnit.quantity", self.get("selectedSalesUnitQuantity"));
          self.get("model.saleItemUnit").save().catch(function (result) {
            self.get("es").errorReturned(result);
            self.set("disabledAction", false);
            self.set("disabledSubmission", false);
            self.set("disabledCompoundSubmission", false);
          });
        } else {
          if (self.get("selectedSalesUnit") && self.get("selectedSalesUnitQuantity") > 0) {
            self.get("store").createRecord("item-unit", {
              item: savedItem,
              unit: self.get("selectedSalesUnit"),
              quantity: Number(self.get("selectedSalesUnitQuantity")),
              preferred: true,
              inbound: false,
              outbound: true
            }).save();
          }
        }

        self.set("disabledAction", false);
        self.set("disabledSubmission", false);
        self.set("disabledCompoundSubmission", false);
        self.transitionToRoute("organizations.organization.items.item", savedItem.get("id"));
      }).catch(function (result) {
        self.get("es").errorReturned(result);
        self.set("disabledSubmission", false);
      });
    }
  });

  _exports.default = _default;
});