define("crm/components/appointments-schedule-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      callModal: function callModal() {
        this.sendAction("openModalAppointment", this.get("record"));
      }
    }
  });

  _exports.default = _default;
});