define("crm/components/financial-account-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    reset: function reset() {
      var self = this;
      this.get('model').filter(function (financialAccount) {
        if (financialAccount.get('financialAccountType') == 1) {
          self.set('selectedFinancialAccount', financialAccount);
        }
      }).objectAt(0);
      Ember.$("modal-financialAccount-choose").modal('hide');
      this.set("disabledAction", false);
    },
    financialAccountsObserver: Ember.observer("model.@each.financialAccounts", function () {
      var self = this;

      if (this.get('model.length')) {
        this.get('model').filter(function (financialAccount) {
          if (financialAccount.get('financialAccountType') == 1) {
            self.set('selectedFinancialAccount', financialAccount);
          }
        }).objectAt(0);
      }
    }),
    actions: {
      setSelectedFinancialAccount: function setSelectedFinancialAccount(financialAccount) {
        this.set('selectedFinancialAccount', financialAccount);
      },
      canceled: function canceled() {
        this.reset();
      },
      create: function create() {
        var self = this;
        this.set("disabledAction", true);
        this.set("receipt.financialAccount", this.get("selectedFinancialAccount"));
        this.get('store').createRecord('receipt', this.get("receipt")).save().then(function () {
          self.get('flashMessages').success(this.get('intl').t('configurations.configItems.generatedReceipt'));
          self.sendAction('reloadModel');
          self.reset();
        }).catch(function () {
          self.set("disabledAction", false);
        });
      }
    }
  });

  _exports.default = _default;
});