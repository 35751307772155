define("crm/models/document-sale", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    intl: Ember.inject.service(),
    customer: _emberData.default.belongsTo("customer"),
    patient: _emberData.default.belongsTo("patient"),
    customerName: Ember.computed("customer.name", function () {
      return this.get("customer.name") ? this.get("customer.name") : this.get("intl").t("actions.finalConsumer");
    })
  });

  _exports.default = _default;
});