define("crm/models/item-adjustment-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    itemAdjustment: _emberData.default.belongsTo("item-adjustment", {
      inverse: "lines"
    }),
    purchaseInvoiceLine: _emberData.default.belongsTo("purchase-invoice-line", {
      inverse: "itemAdjustmentLines"
    })
  });

  _exports.default = _default;
});