define("crm/components/task-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    disabledSubmission: false,
    selectedWorkers: [],
    workersClass: [],
    disableCreateBtn: false,
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;

      if (this.get("model.id")) {
        this.get("store").findRecord("task", this.get("model.id")).then(function (foundTask) {
          self.set("taskDate", (0, _moment.default)(foundTask.get("taskDate")).format("YYYY-MM-DD"));
          self.set("taskTime", foundTask.get("taskTime"));
          self.set("endHour", foundTask.get("endHour"));
        });
      }

      if (this.get("model.patient.id")) {
        this.set("patientWeights", this.get("store").query("weight", {
          patient: this.get("model.patient.id")
        }));
      }
    },
    close: function close() {
      Ember.$("#modal-task-edit").modal("hide");
    },
    taskDay: Ember.computed("model.taskDate", function () {
      return (0, _moment.default)(this.get("model.taskDate")).format("YYYY-MM-DD");
    }),
    taskPriorities: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get('intl').t("tasks.priorities.low"),
        color: "text-primary"
      }, {
        id: 2,
        name: this.get('intl').t("tasks.priorities.high"),
        color: "text-warning"
      }, {
        id: 3,
        name: this.get('intl').t("tasks.priorities.urgency"),
        color: "text-danger"
      }];
    }),
    selectedWorkersByTask: Ember.computed("model.workers", "workers", function () {
      var taskWorkers = [];

      for (var i = 0; i < this.get("workers.length"); i++) {
        var worker = this.get("workers").objectAt(i);
        taskWorkers.addObject({
          selected: false,
          worker: worker
        });

        if (this.get("model.workers")) {
          for (var j = 0; j < this.get("model.workers.length"); j++) {
            var selectedWorker = this.get("model.workers").objectAt(j);
            var workerObject = taskWorkers.objectAt(i);

            if (workerObject.worker == selectedWorker) {
              workerObject.selected = true;
            }
          }
        }
      }

      return taskWorkers;
    }),
    selectedPatientObserver: Ember.observer("selectedPatient.id", "model", function () {
      if (this.get("selectedPatient.id") && this.get("model")) {
        this.set("model.patient", this.get("selectedPatient"));
      }
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      togglePriority: function togglePriority(value) {
        this.set("model.priority", value);
      },
      selectedWorkers: function selectedWorkers(event) {
        var selectedWorkers = Ember.$(event.target).val();
        this.set("selectedWorkers", selectedWorkers || []);
      },
      modalUpdateTask: function modalUpdateTask() {
        this.set("disabledSubmission", true);
        var self = this;

        if (this.get("selectedWorkers")) {
          for (var i = 0; i < this.get("selectedWorkers.length"); i++) {
            var worker = this.get("selectedWorkers").objectAt(i);
            var workerClass = this.get("store").peekRecord("worker", worker);
            this.get("workersClass").addObject(workerClass);
          }

          if (this.get("workersClass.length") > 0) {
            this.set("model.workers", this.get("workersClass"));
          }
        }

        if (this.get("priorityID")) {
          this.set("model.priority", this.get("priorityID"));
        }

        if (this.get("taskDate")) {
          this.set("model.taskDate", this.get("taskDate"));
        }

        if (this.get("taskTime")) {
          this.set("model.taskTime", this.get("taskTime"));
        }

        if (this.get("endHour")) {
          this.set("model.endHour", this.get("endHour"));
        }

        var task = this.get("model");
        task.save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
      },
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$("#openAssociatePatientOnAppointment").modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction("openEditPatientModal", model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        Ember.$("#modal-task-edit").modal("hide");
        this.sendAction("openCreateAppointmentModal", model);
      }
    }
  });

  _exports.default = _default;
});