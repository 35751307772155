define("crm/controllers/organizations/organization/item-adjustments/new", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isBlocked: false,
    saving: false,
    session: Ember.inject.service("session"),
    disabledButton: Ember.computed("lines.[]", "saving", function () {
      return !this.get("lines.length") || this.get("saving");
    }),
    displayInfoMessage: Ember.computed("adjustmentReason", function () {
      return this.get("adjustmentReason.id") == 7;
    }),
    actions: {
      addLine: function addLine(item) {
        var self = this;

        if (this.get("isBlocked") === true) {
          return;
        }

        this.set("isBlocked", true);
        this.get("store").findRecord("item", item.get("id"), {
          reload: true
        }).then(function (savedItem) {
          self.get("store").query("warehouse-item", {
            item: item.get("id"),
            organization: self.get("model.organization.id")
          }, {
            reload: true
          }).then(function (warehouseItems) {
            warehouseItems.forEach(function (warehouseItem) {
              if (warehouseItem.get("warehouse.id") === self.get("warehouse.id")) {
                self.get("lines").addObject({
                  name: savedItem.get("name"),
                  item: savedItem,
                  initialQuantity: warehouseItem.get("quantity"),
                  itemAdjustment: self.get("model")
                });
                self.set("isBlocked", false);
              }
            });
          });
        });
      },
      create: function create() {
        var self = this;
        this.set("saving", true);
        this.get("model").warehouse = this.get("warehouse");
        this.get("model").adjustmentReason = this.get("adjustmentReason");
        this.get("model").date = this.get("date");
        this.get("store").createRecord("item-adjustment", this.get("model")).save().then(function (result) {
          self.get("lines").forEach(function (line) {
            line.itemAdjustment = result.get("id");
            line.warehouse = self.get("warehouse.id");
            Ember.set(line, "item", Ember.get(line, "item.id"));
          });
          Ember.$.ajax({
            method: "POST",
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(self.get("lines")),
            url: _environment.default.universe.api.wildfly + "/itemAdjustmentLines/list"
          }).then(function () {
            self.set("saving", false);
            self.transitionToRoute("organizations.organization.item-adjustments");
            self.get("flashMessages").success(self.get("intl").t("itemAdjustments.messages.createdSuccess"));
          }, function (result) {
            self.get("es").handle(result);
            self.set("saving", false);
          });
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("saving", false);
        });
      },
      setWarehouse: function setWarehouse(warehouse) {
        var self = this;
        this.set("warehouse", warehouse);

        if (this.get("lines.length") > 0) {
          this.get("lines").forEach(function (line) {
            self.store.query("warehouse-item", {
              item: line.item.get("id"),
              organization: self.get("model.organization.id")
            }, {
              reload: true
            }).then(function () {
              line.item.get("warehouseItems").forEach(function (warehouseItem) {
                if (warehouseItem.get("warehouse.id") === self.get("warehouse.id")) {
                  Ember.set(line, "initialQuantity", warehouseItem.get("quantity"));
                }
              });
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});