define("crm/components/warehouse-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    disabledAction: false,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.get("store").findRecord("warehouse", this.get("model.id"));
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      modalEditWarehouse: function modalEditWarehouse() {
        this.set('disabledAction', true);
        var self = this;
        this.get("model").save().then(function () {
          self.close();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set('disableCreateBtn', false);
        });
      },
      cancelEditWarehouseModal: function cancelEditWarehouseModal() {
        this.close();
      },
      reset: function reset() {
        this.set('disabledAction', false);
        this.get('model').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});