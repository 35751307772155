define("crm/routes/organizations/organization/sheltereds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      return Ember.RSVP.hash({
        organization: organization,
        sheltereds: this.store.query('sheltered', {
          organization: organization.get("id")
        }),
        breeds: this.store.findAll('breed'),
        customers: this.store.query('customer', {
          organization: organization.get("id")
        }),
        speciesList: this.store.findAll('species'),
        workers: this.store.query('worker', {
          organization: organization.get('id')
        }),
        diets: this.store.findAll('diet'),
        tails: this.store.findAll('tail')
      }).then(function (hashResult) {
        var sheltereds = hashResult.sheltereds;
        Ember.set(sheltereds, "organization", hashResult.organization);
        Ember.set(sheltereds, "customers", hashResult.customers);
        Ember.set(sheltereds, "speciesList", hashResult.speciesList);
        Ember.set(sheltereds, "workers", hashResult.workers);
        Ember.set(sheltereds, "diets", hashResult.diets);
        Ember.set(sheltereds, "tails", hashResult.tails);
        return sheltereds;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model) {
        controller.set('sheltereds', model);
      }

      if (model.get('customers.length') > 0) {
        controller.set('customers', model.customers);
      }
    },
    actions: {
      openCreateSheltered: function openCreateSheltered() {
        this.send('openModalCreateSheltered');
      },
      openEditSheltered: function openEditSheltered(shelteredToEdit) {
        this.send('openEditShelteredModal', shelteredToEdit);
      },
      openCreateAppointment: function openCreateAppointment(options) {
        this.send('openModalCreateAppointment', options);
      }
    }
  });

  _exports.default = _default;
});