define("crm/models/brand", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    organization: _emberData.default.belongsTo("organization"),
    items: _emberData.default.hasMany("item", {
      inverse: "brand"
    }),
    models: _emberData.default.hasMany("model", {
      inverse: "brand"
    })
  });

  _exports.default = _default;
});