define("crm/abilities/patient", ["exports", "crm/abilities/crm"], function (_exports, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _crm.default.extend({
    canMerge: Ember.computed.alias("manager"),
    canDisable: Ember.computed('worker.role', 'settings.patient_disable', function () {
      if (this.get("settings.patient_disable")) {
        return true;
      } else {
        return this.get("manager");
      }
    })
  });

  _exports.default = _default;
});