define("crm/models/chat", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    messages: _emberData.default.hasMany("chatMessage", {
      inverse: "chat"
    })
  });

  _exports.default = _default;
});