define("crm/components/laboratory-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      editExamModal: function editExamModal(record) {
        this.sendAction('openEditExamModal', record);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
      }
    }
  });

  _exports.default = _default;
});