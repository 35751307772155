define("crm/routes/organizations/organization/clinical-waiting-rooms/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      Ember.set(organization, "appointments", this.store.query("appointment", {
        organization: organization.get("id"),
        waitingRoom: true
      }));
      return organization;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var aux = 30000;
      Ember.run.later(function () {
        return self.refreshAppointments(model, aux);
      }, aux);
      /*
       * Tasks
       */

      var organizationID = this.modelFor("organizations.organization").get("id");
      var startedAfter = (0, _moment.default)().startOf("day").format();
      var startedBefore = (0, _moment.default)().endOf("day").format();
      Ember.RSVP.hash({
        old: self.store.query("task", {
          organization: organizationID,
          startedBefore: (0, _moment.default)().add(-1, "d").endOf("d").format(),
          status: 1,
          me: true
        }),
        today: self.store.query("task", {
          organization: organizationID,
          startedAfter: startedAfter,
          startedBefore: startedBefore,
          me: true
        })
      }).then(function (hashResult) {
        var dayTasks = [];
        var hourTasks = [];

        if (hashResult.old) {
          hashResult.old.forEach(function (task) {
            dayTasks.push(task);
          });
        }

        if (hashResult.today) {
          hashResult.today.forEach(function (task) {
            if (task.get("taskTime") && task.get("taskTime") !== "00:00:00") {
              hourTasks.push(task);
            } else {
              dayTasks.push(task);
            }
          });
        }

        controller.set("tasks", dayTasks);
        controller.set("filteredTasks", hourTasks);
      });
    },
    actions: {
      createdConsultation: function createdConsultation(id) {
        this.transitionTo('organizations.organization.consultations.consultation', id);
      },
      checkOutDetails: function checkOutDetails(appointment) {
        this.transitionTo('organizations.organization.consultations.consultation.checkOut', appointment.get('id'));
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send('openModalAppointmentDetails', appointment);
      },
      openCreateAppointment: function openCreateAppointment(date) {
        this.send('openModalCreateAppointment', date);
      }
    },
    refreshAppointments: function refreshAppointments(organization, lastTime) {
      var self = this;
      this.refresh();

      if (Ember.$(document)[0].URL.includes("waitingRoom")) {
        var aux = lastTime * 1.2;
        Ember.run.later(function () {
          return self.refreshAppointments(organization, aux);
        }, aux);
      }
    }
  });

  _exports.default = _default;
});