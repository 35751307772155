define("crm/components/shopping-cart-total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    shoppingCartTotal: Ember.computed("record.shoppingCart.total", function () {
      if (!Ember.isEmpty(this.get("record.shoppingCart.content"))) {
        return this.get("record.shoppingCart.total");
      }
    })
  });

  _exports.default = _default;
});