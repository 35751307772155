define("crm/models/sheltered", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    //pathologies:            DS.hasMany('pathology-animal'),
    allergies: _emberData.default.hasMany('allergy'),
    species: _emberData.default.belongsTo('species'),
    sex: _emberData.default.attr('number'),
    sterilized: _emberData.default.attr('boolean'),
    temperament: _emberData.default.attr('number'),
    pedigree: _emberData.default.attr('string'),
    deathdate: _emberData.default.attr('isodate'),
    birthdate: _emberData.default.attr('isodate'),
    breed: _emberData.default.belongsTo('breed'),
    tailType: _emberData.default.belongsTo('tail'),
    height: _emberData.default.attr('number'),
    furDescription: _emberData.default.attr('string'),
    furColor: _emberData.default.attr('string'),
    furColor2: _emberData.default.attr('string'),
    furColor3: _emberData.default.attr('string'),
    furLength: _emberData.default.attr('string'),
    furType: _emberData.default.attr('number'),
    eyes: _emberData.default.attr('string'),
    avatar: _emberData.default.hasMany('image'),
    proportion: _emberData.default.attr('number'),
    siraID: _emberData.default.attr('string'),
    tattoo: _emberData.default.attr('boolean'),
    tattooNumber: _emberData.default.attr('number'),
    passport: _emberData.default.attr('string'),
    insurance: _emberData.default.attr('boolean'),
    insuranceType: _emberData.default.attr('string'),
    census: _emberData.default.attr('string'),
    chip: _emberData.default.attr('string'),
    diet: _emberData.default.belongsTo('diet'),
    environmentType: _emberData.default.attr('number'),
    admissionType: _emberData.default.attr('number'),
    admissionDate: _emberData.default.attr('isodate'),
    releaseType: _emberData.default.attr('number'),
    releaseDate: _emberData.default.attr('isodate'),
    notes: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});