define("crm/abilities/hr", ["exports", "crm/abilities/undermanager"], function (_exports, _undermanager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _undermanager.default.extend();

  _exports.default = _default;
});