define("crm/models/clinic", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /*  name:           DS.attr('string'),
      country:        DS.attr('string'),
      clients:        DS.hasMany('customer'),
      clientID:       DS.attr('string'),
      clientSecret:   DS.attr('string'),
      enable:         DS.attr('boolean'),
      email:          DS.attr('string'),
      fax:            DS.attr('string'),
      phone:          DS.attr('string'),
      street:         DS.attr('string'),
      town:           DS.attr('string'),
      zip:            DS.attr('string'),
      website:        DS.attr('string'),
      //clinicMessage:  DS.hasMany(''),
      workers:        DS.hasMany('worker'),
      patients:       DS.hasMany('patient'),
      organization:   DS.belongsTo('organization'),
      externalID:     DS.attr('number'),
      calendarID:     DS.attr('string'),
      selectedAvatar: DS.hasMany('image'),
      avatars:        DS.hasMany('image')*/
  });

  _exports.default = _default;
});