define("crm/components/pet-date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    classNames: ["pet-date-picker"],
    attributeBindings: ['data-date-start-date', 'data-date-end-date'],
    init: function init() {
      this._super();

      if (this.get("startDate")) {
        this.$().datepicker('setStartDate', this.get("startDate"));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("startDate")) {
        this.$().datepicker('setStartDate', this.get("startDate"));
      }
    },
    datepickerOptions: Ember.computed("startDate", "endDate", "showBelow", function () {
      return {
        weekStart: 1,
        startDate: this.get("startDate"),
        endDate: this.get("endDate"),
        format: "yyyy-mm-dd",
        todayBtn: "linked",
        clearBtn: true,
        language: "pt",
        keyboardNavigation: false,
        autoclose: true,
        todayHighlight: true,
        orientation: this.get('showBelow') === true ? "bottom" : "auto"
      };
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.later(function () {
        self.$().datepicker(self.get("datepickerOptions"));
      }, 100);
    },
    sendDate: Ember.observer('value', function () {
      if (this.get('action') && !Ember.isEmpty(this.get("value"))) {
        this.sendAction("action", (0, _moment.default)(this.get("value")));
      }
    })
  });

  _exports.default = _default;
});