define("crm/controllers/organizations/organization/listings/sales/inflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    filteredModels: Ember.computed('model.receipts.[]', 'model.invoices.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts"));
      }

      if (!Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices"));
      }

      return result;
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "title": "Date",
        "propertyName": "CreatedOn",
        "component": "umt-date",
        "className": "column-date",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "title": "Cliente",
        "propertyName": "Name",
        "className": "column-name"
      }, {
        "title": "Nif",
        "propertyName": "CompanyTaxID",
        "className": "column-vat"
      }, {
        "title": "Recibo",
        "propertyName": "NaturalKey",
        "className": "column-contact"
      }, {
        "title": "Método",
        "propertyName": "PaymentMethodsKey",
        "className": "column-sex"
      }, {
        "title": "Bruto",
        "propertyName": "GrossValueAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Total",
        "propertyName": "PayableAmountAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Desconto",
        "propertyName": "AllowanceChargeAmountAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Liquidado",
        "propertyName": "SettledAmountAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Origem",
        "propertyName": "SourceDoc__2",
        "className": "column-contact"
      }, {
        "title": "Data",
        "propertyName": "IssueDate",
        "component": "umt-date",
        "className": "column-date"
      }, {
        "title": "Total",
        "propertyName": "AmountAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});