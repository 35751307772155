define("crm/controllers/organizations/organization/group/waiting-room", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    appointmentTypes: Ember.computed.alias("catalogs.appointmentType"),
    exporting: false,
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    canDoGroupWaitingRoom: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    appointmentsOverviewStatus: Ember.computed("appointmentsOverview.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("appointmentsOverview.totalByStatus") ? [] : this.get("appointmentsOverview.totalByStatus").map(function (s) {
        return {
          type: _this.get("appointmentTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          percentage: Math.round((s[1] / self.get("appointmentsOverview.total") * 100 + Number.EPSILON) * 100) / 100,
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("appointmentsOverview.total") + "%")
        };
      });
    }),
    waits: Ember.computed("appointmentsOverview", function () {
      if (!this.get("appointmentsOverview")) {
        return [];
      }

      return {
        labels: ["checkin", "visit", "checkout"],
        datasets: [{
          data: [this.get("appointmentsOverview.checkinWait"), this.get("appointmentsOverview.visitWait"), this.get("appointmentsOverview.checkoutWait")],
          backgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"],
          hoverBackgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"]
        }]
      };
    }),
    scheduledWaitByType: Ember.computed("appointmentsOverview.scheduledWaitByType.[]", function () {
      return this.waitByType(this.get("appointmentsOverview.scheduledWaitByType"));
    }),
    checkinWaitByType: Ember.computed("appointmentsOverview.checkinWaitByType.[]", function () {
      return this.waitByType(this.get("appointmentsOverview.checkinWaitByType"));
    }),
    visitWaitByType: Ember.computed("appointmentsOverview.visitWaitByType.[]", function () {
      return this.waitByType(this.get("appointmentsOverview.visitWaitByType"));
    }),
    checkoutWaitByType: Ember.computed("appointmentsOverview.checkoutWaitByType.[]", function () {
      return this.waitByType(this.get("appointmentsOverview.checkoutWaitByType"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[1],
          quantity: s[2],
          duration: s[3]
        };
      });
    },
    todayMore: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") > this.get("todayStats.yesterday");
    }),
    todaySame: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") === this.get("todayStats.yesterday");
    }),
    todayMoreIncreased: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return Math.abs(this.get("todayStats.today") - this.get("todayStats.yesterday"));
    }),
    sexChartData: Ember.computed("sexStats.females", "sexStats.males", function () {
      return {
        labels: [this.get('intl').t('stats.female'), this.get('intl').t('stats.male')],
        datasets: [{
          data: [this.get("sexStats.females"), this.get("sexStats.males")],
          backgroundColor: ["#ED5565", "#6BCABA"],
          hoverBackgroundColor: ["#ED5565", "#6BCABA"]
        }]
      };
    }),
    actions: {
      sendOpenExport: function sendOpenExport() {
        var self = this;
        this.send("openModal", {
          entity: "export",
          action: "export",
          model: {
            func: function func(before, after) {
              self.export(before, after);
            }
          }
        });
      },
      detailsScheduleWait: function detailsScheduleWait() {
        this.transitionToRoute('organizations.organization.group.schedule-wait');
      },
      detailsCheckinWait: function detailsCheckinWait() {
        this.transitionToRoute('organizations.organization.group.checkin-wait');
      },
      detailsVisitWait: function detailsVisitWait() {
        this.transitionToRoute('organizations.organization.group.visit-wait');
      },
      detailsCheckoutWait: function detailsCheckoutWait() {
        this.transitionToRoute('organizations.organization.group.checkout-wait');
      }
    },
    export: function _export(before, after) {
      this.set("exporting", true);
      var self = this;
      var startedAfter = (0, _moment.default)(after).startOf("day").add(1, "h").toISOString();
      var startedBefore = (0, _moment.default)(before).endOf("day").add(1, "h").toISOString();
      Ember.$.ajax({
        method: 'GET',
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/appointments/waitingRoom/xlsx?consultation=true&company=" + self.get("company.id") + "&startedBefore=" + startedBefore + "&startedAfter=" + startedAfter,
        xhrFields: {
          responseType: 'blob'
        },
        success: function success(blob) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var url = url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "waitingRooms-" + new Date().toISOString().slice(0, 10) + ".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          self.set("exporting", false);
        },
        error: function error() {
          self.set("exporting", false);
        }
      });
    }
  });

  _exports.default = _default;
});