define("crm/routes/organizations/organization/listings/crm/contacts", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    intl: Ember.inject.service(),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      return Ember.RSVP.hash({
        contacts: Ember.$.get({
          url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/contacts/info" + "?company=" + organization.get("company.id")),
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return r.contacts.map(function (l) {
          var i = 0;
          var x = {
            contact: l[i++],
            contactType: l[i++],
            preferential: l[i++],
            code: l[i++],
            name: l[i++]
          };
          return x;
        });
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("customers.customer.contacts.contacts")
      });
    }
  });

  _exports.default = _default;
});