define("crm/models/company-jasmin", ["exports", "ember-data", "crm/models/company"], function (_exports, _emberData, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    endpoint: _emberData.default.attr('string')
  });

  _exports.default = _default;
});