define("crm/components/open-shopping-carts-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    customerInvoices: Ember.computed("model.customer.id", function () {
      if (this.get("model.customer.id")) {
        return this.get("store").query("invoice", {
          customer: this.get("model.customer.id")
        });
      } else {
        return [];
      }
    }),
    disabledAction: Ember.computed("invoice", function () {
      return !this.get("invoice");
    }),
    close: function close() {
      Ember.$("#modal-open-shopping-carts-close").modal("hide");
    },
    actions: {
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.set("model.status", 1);
        this.get("model").save().then(function () {
          self.get("store").createRecord("invoice-shopping-cart", {
            invoice: self.get("invoice"),
            shoppingCart: self.get("model")
          }).save().then(function () {
            self.close();
            self.sendAction("reloadModel");
          }).catch(function (result) {
            self.get("es").handle(result);
            self.set("disabledAction", false);
          });
        }).catch(function (result) {
          self.get("es").handle(result);
          self.set("disabledAction", false);
        });
      },
      reset: function reset() {
        this.set("invoice", null);
      }
    }
  });

  _exports.default = _default;
});