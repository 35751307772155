define("crm/controllers/organizations/organization/items/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    queryParams: ["type", "sellable", "stocable"],
    allItemType: {
      id: -1
    },
    itemTypes: Ember.computed("store", function () {
      return this.get("store").peekAll("itemType");
    }),
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    itemTypesToShow: Ember.computed("itemTypes.@each.parent", "type", "searchedValue", function () {
      var self = this;

      if (this.get("itemTypes.length")) {
        if (this.get("type")) {
          return this.get("itemTypes").filterBy("parent.id", self.get("type")).sortBy("name");
        } else {
          return this.get("itemTypesRoot").sortBy("name");
        }
      } else {
        return [];
      }
    }),
    actions: {
      setType: function setType(typeID) {
        if (typeID) {
          this.set("type", typeID);
        } else {
          this.set("type", undefined);
        }
      },
      setItemType: function setItemType(item, type) {
        if (item && type) {
          this.get("store").findRecord("item", item.get("id"), {
            reload: true
          }) // reload is needed to wait on a promise other wise then doesn't delay
          .then(function (savedItem) {
            savedItem.set("type", type);
            savedItem.save();
          });
        }
      }
    }
  });

  _exports.default = _default;
});