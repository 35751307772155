define("crm/components/item-transfer-stock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;
      this.set("givingWarehouse", {
        quantity: 0
      });
      this.set("receivingWarehouse", {
        quantity: 0
      });
      this.set("warehouseItems", []);
      this.get("store").findRecord("organization-pet", this.get("organization.id")).then(function (organizationPet) {
        self.set("organizationPet", organizationPet);
      });

      if (this.get("model.id")) {
        this.get("store").query("warehouse-item", {
          item: this.get("model.id"),
          company: this.get("organization.company.id"),
          organization: this.get("organization.id")
        }).then(function (warehouseItems) {
          self.set("warehouseItems", warehouseItems);
        });
      }
    },
    disabledAction: Ember.computed("givingWarehouse", "receivingWarehouse", "movingStock", function () {
      if (Ember.isEmpty(this.get("givingWarehouse")) || Ember.isEmpty(this.get("receivingWarehouse")) || Ember.isEmpty(this.get("movingStock"))) {
        this.set("hideIcon", true);
        return true;
      } else {
        this.set("hideIcon", false);
        return false;
      }
    }),
    receivingWarehouses: Ember.computed("warehouseItems.[]", "givingWarehouse", function () {
      if (!Ember.isEmpty(this.get("warehouseItems")) && !Ember.isEmpty(this.get("givingWarehouse"))) {
        var self = this;
        return this.get("warehouseItems").filter(function (warehouseItem) {
          return warehouseItem.get("id") !== self.get("givingWarehouse.id");
        });
      }
    }),
    isStockValid: Ember.computed("movingStock", "givingWarehouse", function () {
      if (!Ember.isEmpty(this.get("movingStock")) && !Ember.isEmpty(this.get("givingWarehouse"))) {
        if (this.get("movingStock") <= this.get("givingWarehouse.quantity") && this.get("movingStock") > 0) {
          return true;
        }

        return false;
      }
    }),
    close: function close() {
      Ember.$("#modal-item-transferStock").modal("hide");
    },
    actions: {
      selectGivingWarehouse: function selectGivingWarehouse(warehouse) {
        this.set("givingWarehouse", warehouse);
        this.set("receivingWarehouse", null);
      },
      selectReceivingWarehouse: function selectReceivingWarehouse(warehouse) {
        var self = this;
        this.set("receivingWarehouse", warehouse);

        if (warehouse) {
          this.get("warehouseItems").forEach(function (warehouseItem) {
            if (warehouseItem.get("warehouse.id") === warehouse.get("id")) {
              self.set("receivingWarehouseStock", Number(warehouseItem.get("quantity")));
            }
          });
        }
      },
      save: function save() {
        this.set("disabledAction", true);
        var self = this;
        this.get("warehouseItems").forEach(function (warehouseItem) {
          if (warehouseItem.get("id") === self.get("givingWarehouse.id")) {
            warehouseItem.set("item", self.get("model"));
            warehouseItem.set("quantity", self.get("givingWarehouse.quantity") - Number(self.get("movingStock")));
            warehouseItem.save();
          }

          if (warehouseItem.get("id") === self.get("receivingWarehouse.id")) {
            warehouseItem.set("item", self.get("model"));
            warehouseItem.set("quantity", self.get("receivingWarehouse.quantity") + Number(self.get("movingStock")));
            warehouseItem.save();
          }
        });
        this.close();
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        this.set("movingStock", null);
        this.set("givingWarehouse", null);
        this.set("receivingWarehouse", null);
        this.set("disabledAction", false);
      }
    }
  });

  _exports.default = _default;
});