define("crm/models/action", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    status: _emberData.default.attr("number"),
    skipSchedule: _emberData.default.attr("boolean"),
    started: _emberData.default.attr("isodate"),
    ended: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    initials: _emberData.default.attr("number"),
    quantity: _emberData.default.attr('number'),
    // todo:                DS.belongsTo("todo", {inverse: "actions"}),
    drug: _emberData.default.belongsTo("todo-drug"),
    exam: _emberData.default.belongsTo("todo-exam"),
    serum: _emberData.default.belongsTo("todo-serum"),
    monitoring: _emberData.default.belongsTo("todo-monitoring"),
    chore: _emberData.default.belongsTo("todo-chore"),
    doer: _emberData.default.belongsTo("worker"),
    examination: _emberData.default.belongsTo("exam"),
    image: _emberData.default.belongsTo("image"),
    physicalExam: _emberData.default.belongsTo('physicalExam'),
    shoppingCartLine: _emberData.default.belongsTo('shopping-cart-line'),
    images: _emberData.default.hasMany("image"),
    notesObj: Ember.computed("notes", function () {
      try {
        return JSON.parse(this.get('notes'));
      } catch (err) {
        return null;
      }
    }),
    todo: Ember.computed("task.id", "drug.id", "monitoring.id", "exam.id", "event.id", "serum.id", function () {
      if (this.get("drug.id")) {
        return this.get("drug");
      } else if (this.get("exam.id")) {
        return this.get("exam");
      } else if (this.get("monitoring.id")) {
        return this.get("monitoring");
      } else if (this.get("serum.id")) {
        return this.get("serum");
      } else if (this.get("chore.id")) {
        return this.get("chore");
      }
    }),
    type: Ember.computed("todo.type.id", function () {
      return this.get("todo.type.id") ? this.get("todo.type") : null;
    })
    /*
     * Status
     * 1 - done
     * 2 - removed
     * 3 -
     * 4 - removed ??
     * 5 - scheduled
     */

  });

  _exports.default = _default;
});