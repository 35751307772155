define("crm/routes/organizations/organization/invoices/invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("invoice", params.invoice_id);
    },
    afterModel: function afterModel(invoice) {
      if (invoice.get("id")) {
        Ember.set(invoice, "shoppingCartsByInvoice", this.store.query("shopping-cart", {
          invoice: invoice.get("id")
        }));
      }
    }
  });

  _exports.default = _default;
});