define("crm/components/purchase-invoice-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.set("documentLines", this.get("store").query("purchase-invoice-line", {
          purchaseInvoice: this.get("model.id")
        }));
      }
    }
  });

  _exports.default = _default;
});