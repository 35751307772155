define("crm/components/purchase-invoice-line-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    es: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.item.itemUnit")) {
        this.set("selectedItemsUnit", this.get("model.item.itemUnit"));
      }
    },
    close: function close() {
      Ember.$("#modal-purchase-invoice-line-edit").modal("hide");
    },
    filteredUnits: Ember.computed("model.item.allItemUnits.[]", function () {
      if (this.get("model.item.allItemUnits")) {
        return this.get("model.item.allItemUnits").filterBy("inbound", true);
      }
    }),
    requiredFieldSales: Ember.computed("selectedItemsUnit", function () {
      var result = false;

      if (this.get("selectedItemsUnit")) {
        result = true;
      }

      return result;
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      save: function save() {
        var self = this;
        self.set("disabledAction", true);
        this.set("model.item.itemUnit", this.get("selectedItemsUnit"));
        this.get("model.item").save();
        this.close();
        this.sendAction("reloadModel");
      },
      setItemUnit: function setItemUnit(unit) {
        if (Ember.isEmpty(unit)) {
          this.set("selectedItemsUnit", null);
        }

        this.set("selectedItemsUnit", unit);
      },
      reset: function reset() {
        this.set("disabledAction", false);
      }
    }
  });

  _exports.default = _default;
});