define("crm/routes/organizations/organization/group/purchase-invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('create purchase-invoice')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var companyID = organization.get("company.id");
      return Ember.RSVP.hash({
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          company: companyID,
          open: true
        })
      }).then(function (hash) {
        hash.purchaseInvoices.set("organization", organization);
        return hash.purchaseInvoices;
      });
    }
  });

  _exports.default = _default;
});