define("crm/components/financial-account-is-manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.isManual", function () {
      if (this.get("record.isManual")) {
        return this.get('intl').t("icons.check");
      }
    })
  });

  _exports.default = _default;
});