define("crm/controllers/organizations/organization/appointments/group", ["exports", "moment", "crm/controllers/organizations/organization/appointments"], function (_exports, _moment, _appointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointments.default.extend({
    queryParams: ["oldAppointment", "intakeDate", "day", "patient"],
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    step: 30,
    startHour: 8,
    endingHour: 23,
    showAside: false,
    filteredWorkers: [],
    displayChartDateInitInput: false,
    init: function init() {
      this._super();

      this.set("day", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    canDoCalendar: Ember.computed("can", function () {
      return this.get("can").can("do calendar");
    }),
    tasksFiltered: Ember.computed("tasks.@each.name", function () {
      return Ember.isEmpty(this.get("tasks")) ? undefined : this.get("tasks").filter(function (t) {
        return !t.get("name").match("Contactar:.+");
      });
    }),
    isBeforeToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isAfter(this.get("chartDateInit"), "days");
    }),
    isToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isSame(this.get("chartDateInit"), "days");
    }),
    isAfterToday: Ember.computed("chartDateInit", function () {
      return (0, _moment.default)().isBefore(this.get("chartDateInit"), "days");
    }),
    hourWindow: Ember.computed("step", "startHour", "endingHour", function () {
      return (1 + this.get("endingHour") - this.get("startHour")) * 60 / this.get("step");
    }),
    chartDateInit: Ember.computed("day", function () {
      return (this.get("day") ? (0, _moment.default)(this.get("day")) : (0, _moment.default)()).startOf("day").hours(this.get("startHour"));
    }),
    hours: Ember.computed("chartDateInit", "hourWindow", "step", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        var newHour = (0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), "minutes");
        result.addObject(newHour);
      }

      return result;
    }),
    columns: Ember.computed("model.organizations.[]", "hours.[]", function () {
      var result = {};
      var self = this;
      this.get("hours").forEach(function (hour) {
        result[hour] = {};
        self.get("model.organizations").forEach(function (worker) {
          result[hour][worker.get("name")] = [];
        });
      });
      this.get("model").filter(function (appointment) {
        return appointment.get("scheduled");
      }).forEach(function (appointment) {
        if (appointment.get("patient") && Ember.isEmpty(appointment.get("patient.deathdate"))) {
          var itemRootTypeID = null;
          /* Worker */

          var orgId = appointment.get("organization.name") ? appointment.get("organization.name") : 0;
          /* Time */

          var appointmentStart = (0, _moment.default)(appointment.get("start"));
          var minutes = appointmentStart.minutes();
          var adjustedHour;

          if (minutes < self.get("step")) {
            adjustedHour = appointmentStart.startOf("hour");
          } else {
            adjustedHour = appointmentStart.startOf("hour").add(self.get("step"), "minutes");
          }
          /* Add by type */


          if (result[adjustedHour] && result[adjustedHour][itemRootTypeID]) {
            result[adjustedHour][itemRootTypeID] = result[adjustedHour][itemRootTypeID].addObject(appointment);

            if (appointment.get("calendarSteps") > 1) {
              var lastStep = adjustedHour;

              for (var x = 1; i < appointment.get("calendarSteps"); x++) {
                lastStep = lastStep.add(self.get("step"), "minutes");

                if (result[adjustedHour] && result[adjustedHour][itemRootTypeID]) {
                  result[adjustedHour][itemRootTypeID] = result[adjustedHour][itemRootTypeID].addObject({});
                }
              }
            }
          }
          /* Add by worker */


          if (result[adjustedHour] && result[adjustedHour][orgId]) {
            result[adjustedHour][orgId] = result[adjustedHour][orgId].addObject(appointment);

            if (appointment.get("calendarSteps") > 1) {
              lastStep = adjustedHour;

              for (var i = 1; i < appointment.get("calendarSteps"); i++) {
                lastStep = lastStep.add(self.get("step"), "minutes");

                if (result[adjustedHour] && result[adjustedHour][orgId]) {
                  result[adjustedHour][orgId] = result[adjustedHour][orgId].addObject({});
                }
              }
            }
          }
        }
      });
      Ember.$(".calendar td").hover(function () {
        Ember.$('table tr th').eq(Ember.$(this).index()).add(this).toggleClass('highlight');
      });
      return result;
    }),
    actions: {
      addWorker: function addWorker(worker) {
        var aux = [];
        this.get("filteredWorkers").forEach(function (workerElem) {
          if (workerElem.get("id") != worker.get("id")) {
            aux.push(workerElem);
          }
        });
        aux.push(worker);
        this.set("filteredWorkers", aux);
      },
      previous: function previous() {
        this.set("day", this.get("chartDateInit").subtract(1, "d").format("YYYY-MM-DD"));
      },
      next: function next() {
        this.set("day", this.get("chartDateInit").add(1, "d").format("YYYY-MM-DD"));
      },
      setChartDateInit: function setChartDateInit() {
        this.set("displayChartDateInitInput", false);
      },
      setDisplayChartDateInitInput: function setDisplayChartDateInitInput() {
        this.set("displayChartDateInitInput", true);
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send("openModalAppointmentDetails", appointment);
      },
      openAppointmentCreate: function openAppointmentCreate(hour, orgId) {
        var start = hour ? hour : (0, _moment.default)().format();
        var newAppointment = {
          "organization": this.get("model.organization"),
          "start": start
        };

        if (this.get("patient")) {
          newAppointment.patient = this.get("store").peekRecord("patient", this.get("patient"));
        }

        if (this.get("worker")) {
          newAppointment.worker = this.get("store").peekRecord("worker", this.get("worker"));
        }

        if (this.get("oldAppointment")) {
          newAppointment.oldAppointment = this.get("store").peekRecord("appointment", this.get("oldAppointment"));
        }

        if (this.get("customer")) {
          newAppointment.customer = this.get("store").peekRecord("customer", this.get("customer"));
        }

        if (orgId) {
          var org = this.get("model.organizations").filter(function (o) {
            return o.get("name") === orgId;
          })[0];
          newAppointment.workers = org.get("workers.length") ? org.get("workers") : this.get("store").query("worker", {
            organization: org.get("id")
          });
          newAppointment.organization = org;
        }

        if (this.get("intakeDate")) {
          newAppointment.intakeDate = this.get("intakeDate");
        }

        this.send("openModal", {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set("appointment", appointment);
          Ember.$("#openCreateAppointment").modal();
        }
      },
      checkIn: function checkIn(appointment) {
        appointment.set("arrivalTime", (0, _moment.default)().format());
        appointment.set("status", 3);
        appointment.save();
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      deleteAppointment: function deleteAppointment(appointment) {
        if (false == Ember.isEmpty(appointment)) {
          appointment.destroyRecord();
        }
      },
      createdConsultation: function createdConsultation(consultationID) {
        this.transitionToRoute("organizations.organization.consultations.consultation", consultationID);
      },
      transitionToWeek: function transitionToWeek() {
        var day = (0, _moment.default)(this.get("day")).startOf('isoWeek');
        this.transitionToRoute("organizations.organization.appointments.week", this.get("model.organization.id"), {
          queryParams: {
            day: day.format("YYYY-MM-DD")
          }
        });
      },
      today: function today() {
        this.set("day", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      },
      transitionToDay: function transitionToDay() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.day", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToDay2: function transitionToDay2() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.day2", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      transitionToCustom: function transitionToCustom() {
        var day = this.get("day");
        this.transitionToRoute("organizations.organization.appointments.custom", this.get("model.organization.id"), {
          queryParams: {
            day: day
          }
        });
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      }
    }
  });

  _exports.default = _default;
});