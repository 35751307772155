define("crm/components/internment/todo-drug-new", ["exports", "crm/components/internment/todo-new"], function (_exports, _todoNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoNew.default.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    units: Ember.computed.alias("catalogs.units"),
    viaTypes: Ember.computed.alias("catalogs.viaTypes"),
    modelName: "todo-drug",

    /*
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get("model")) {
        if (!this.get("model.via")) {
          this.set("model.via", "4");
        }
      }
    },

    /*
     * Computed
     */
    disableBtn: Ember.computed("saving", "model.type", "model.organization.settings", function () {
      if (!this.get("model.type") && this.get("model.organization.settings.internments_drug_item_mandatory")) {
        this.set("disabledReason", this.get("intl").t("items.item.title") + ": " + this.get("intl").t("validations.fieldRequired"));
        return false;
      }

      return this.get("saving");
    }),
    selectedVia: Ember.computed("model.via", function () {
      return this.get("catalogs").viaType(this.get("model.via"));
    }),

    /*
     * Actions
     */
    actions: {
      setVia: function setVia(via) {
        this.set("model.via", via.id);
      },
      showCalculator: function showCalculator() {
        this.sendAction("openModal", {
          entity: "calculator",
          action: "details",
          model: {
            patient: this.get("model.internment.patient"),
            todo: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});