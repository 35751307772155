define("crm/controllers/organizations/organization/appointments/appointment/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableBtn: false,
    isDirty: true,
    startDate: Ember.computed("model.start", function () {
      var startDate = (0, _moment.default)(this.get('model.start'));
      return startDate.format('YYYY-MM-DDTHH:mm');
    }),
    estimatedEndDate: Ember.computed("model.end", function () {
      var endDate = (0, _moment.default)(this.get('model.end')); //var endDate = startDate.add(0.5, 'hours');   //formatado para 30 min

      return endDate.format('YYYY-MM-DDTHH:mm');
    }),
    warningDate: Ember.computed("model.notice", function () {
      if (this.get('model.notice') == true) {
        return (0, _moment.default)(this.get('model.noticeDate')).format('YYYY-MM-DD');
      }
    }),
    actions: {
      setWorker: function setWorker(worker) {
        this.set('worker', worker);
      },
      update: function update() {
        var self = this;
        this.set('disableBtn', true);

        if (this.get("worker")) {
          var worker = this.store.peekRecord('worker', this.get("worker"));
          this.set("model.worker", worker);
        }

        if (this.get('typeID')) {
          var type = this.store.peekRecord('type', this.get("typeID"));
          this.set("model.type", type);
        }

        if (this.get('startDate')) {
          this.set("model.start", this.get('startDate'));
        }

        if (this.get('estimatedEndDate')) {
          this.set("model.end", this.get('estimatedEndDate'));
        }

        if (this.get('warningDate')) {
          this.set("model.notice", this.get('warningDate'));
        }

        this.get('model').save().then(function () {
          self.send("updated");
          self.set('disableBtn', false);
        }).catch(function () {
          self.set('disableBtn', false);
        });
      },
      cancel: function cancel() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.get('model').rollbackAttributes();
          this.send("canceled");
        }
      }
    }
  });

  _exports.default = _default;
});