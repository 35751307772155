define("crm/routes/organizations/organization/configurations/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      return organization.get("avatar") || {
        id: organization.get("id")
      };
    },
    actions: {
      create: function create() {
        var self = this;
        var model = this.get("controller.model");

        if (!model.data) {
          model = this.store.createRecord("organization-avatar", model);
        }

        model.save().then(function (saved) {
          return self.set("controller.model", saved);
        });
      }
    }
  });

  _exports.default = _default;
});