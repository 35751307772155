define("crm/models/debt-status", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    children: _emberData.default.hasMany("debt-status", {
      inverse: "parent"
    }),
    parent: _emberData.default.belongsTo("debt-status", {
      inverse: "children"
    }),
    company: _emberData.default.belongsTo("company"),
    debtDebtStatuses: _emberData.default.hasMany("debt-debt-status", {
      inverse: "debtStatus"
    })
  });

  _exports.default = _default;
});