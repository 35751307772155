define("crm/components/item-move-stock", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    session: Ember.inject.service("session"),
    didUpdateAttrs: function didUpdateAttrs() {
      if (!Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") === 1) {
        this.set("warehouse", this.get("warehouses").objectAt(0));
      }

      if (!Ember.isEmpty(this.get("adjustmentReasons"))) {
        this.set("adjustmentReason", this.get("adjustmentReasons").filterBy("code", "10").objectAt(0));
      }

      if (this.get("model.purchasePrice")) {
        this.set("purchasePrice", this.get("model.purchasePrice"));
      } else {
        this.set("purchasePrice", null);
      }

      if (this.get("organizationPet.defaultWarehouse")) {
        this.set("warehouse", this.get("organizationPet.defaultWarehouse"));
      } else {
        this.set("warehouse", null);
      }

      this.set("quantity", 1);
    },
    reset: function reset() {
      if (!Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") === 1) {
        this.set("warehouse", this.get("warehouses").objectAt(0));
      }

      if (!Ember.isEmpty(this.get("adjustmentReasons"))) {
        this.set("adjustmentReason", this.get("adjustmentReasons").filterBy("code", "10").objectAt(0));
      }

      if (this.get("model.purchasePrice")) {
        this.set("purchasePrice", this.get("model.purchasePrice"));
      } else {
        this.set("purchasePrice", null);
      }

      if (this.get("organizationPet.defaultWarehouse")) {
        this.set("warehouse", this.get("organizationPet.defaultWarehouse"));
      } else {
        this.set("warehouse", null);
      }

      this.set("quantity", 1);
      this.close();
    },
    close: function close() {
      Ember.$("#modal-item-moveStock").modal("hide");
    },
    displayInfoMessage: Ember.computed("adjustmentReason", function () {
      return this.get("adjustmentReason.id") == 7;
    }),
    actions: {
      setSelectedWarehouse: function setSelectedWarehouse(warehouse) {
        this.set("selectedWarehouse", warehouse);
      },
      canceled: function canceled() {
        this.reset();
      },
      save: function save() {
        var _this = this;

        var self = this;
        this.set("disabledAction", true);
        var itemAdjustment = {
          adjustmentReason: this.get("adjustmentReason"),
          adjustmentReasonCode: this.get("adjustmentReason.code"),
          date: (0, _moment.default)(),
          organization: this.get("organization"),
          warehouse: this.get("warehouse")
        };
        this.get("store").createRecord("item-adjustment", itemAdjustment).save().then(function (savedItemAdjustment) {
          var itemAdjustmentLines = [{
            item: self.get("model.id"),
            name: self.get("model.name"),
            itemAdjustment: savedItemAdjustment.get("id"),
            unitPrice: self.get("purchasePrice"),
            quantity: self.get("quantity"),
            warehouse: self.get("warehouse.id")
          }];
          Ember.$.ajax({
            method: "POST",
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(itemAdjustmentLines),
            url: _environment.default.universe.api.wildfly + "/itemAdjustmentLines/list"
          }).then(function () {
            self.get("store").findRecord("item-adjustment", savedItemAdjustment.get("id"), {
              reload: true
            });
            self.get("store").findRecord("item", self.get("model.id"), {
              reload: true
            }).then(function () {
              self.get("store").query("warehouse-item", {
                item: self.get("model.id"),
                organization: self.get("organization.id")
              }, {
                reload: true
              });
            });
            self.reset();

            _this.set("disabledAction", false);
          }, function (result) {
            self.get("es").handle(result);

            _this.set("disabledAction", false);
          });
        }).catch(function (result) {
          self.get("es").handle(result);
          this.set("disabledAction", false);
        });
      }
    }
  });

  _exports.default = _default;
});