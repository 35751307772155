define("crm/components/manufacturers-equipments-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoManufacturers: Ember.computed("can", function () {
      //return this.get("can").can("do group"); TODOJD
      return true;
    }),
    actions: {
      details: function details() {
        this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s." + this.get("record._internalModel.modelName"), this.get("record.id"));
      },
      edit: function edit() {
        this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s." + this.get("record._internalModel.modelName") + ".edit", this.get("record.id"));
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});