define("crm/models/internment-shopping-cart", ["exports", "ember-data", "crm/models/shopping-cart"], function (_exports, _emberData, _shoppingCart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shoppingCart.default.extend({
    internment: _emberData.default.belongsTo("internment", {
      inverse: "shoppingCarts"
    })
  });

  _exports.default = _default;
});