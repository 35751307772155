define("crm/controllers/organizations/organization/stats/sales/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    appointmentTypes: Ember.computed.alias("catalogs.appointmentType"),
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    billedOverviewStatus: Ember.computed("billedOverview.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("billedOverview.totalByStatus") ? [] : this.get("billedOverview.totalByStatus").map(function (s) {
        return {
          type: _this.get("appointmentTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("billedOverview.total") + "%")
        };
      });
    }),
    waits: Ember.computed("billedOverview", function () {
      if (!this.get("billedOverview")) {
        return [];
      }

      return {
        labels: ["checkin", "visit", "checkout"],
        datasets: [{
          data: [this.get("billedOverview.checkinWait"), this.get("billedOverview.visitWait"), this.get("billedOverview.checkoutWait")],
          backgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"],
          hoverBackgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"]
        }]
      };
    }),
    itemsTodayByType: Ember.computed("billedOverview.itemsToday.[]", function () {
      return this.waitByType(this.get("billedOverview.itemsToday"));
    }),
    itemsThisWeekByType: Ember.computed("billedOverview.itemsThisWeek.[]", function () {
      return this.waitByType(this.get("billedOverview.itemsThisWeek"));
    }),
    itemsThisMonthByType: Ember.computed("billedOverview.itemsThisMonth.[]", function () {
      return this.waitByType(this.get("billedOverview.itemsThisMonth"));
    }),
    itemsThisYearByType: Ember.computed("billedOverview.itemsThisYear.[]", function () {
      return this.waitByType(this.get("billedOverview.itemsThisYear"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[0],
          quantity: s[1],
          price: s[2]
        };
      });
    }
  });

  _exports.default = _default;
});