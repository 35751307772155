define("crm/components/customers-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldString: null,
    disabled: false,
    reset: function reset() {
      Ember.$("#modal-customer-select").modal("hide");
      this.set("searchText", "");
      this.set("customers", []);
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("customers.customer.customerName"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }, {
        "propertyName": "owner.patients",
        "component": "show-patients-by-customer",
        "title": this.get('intl').t("patients.title"),
        "sortedBy": "patient.name",
        "className": "column-big",
        "searchable": true
      }];
    }),
    searching: Ember.computed("searchText", "disabled", function () {
      return this.get("searchText") && this.get("disabled");
    }),
    actions: {
      searchCustomers: function searchCustomers(query) {
        var self = this;
        this.set("lockButton", true);
        this.get("store").query("customer", {
          company: self.get("organization.company.id"),
          query: query,
          enable: true
        }).then(function (savedEntities) {
          self.set("customers", savedEntities);
          self.set("lockButton", false);
        });
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable.get("selectedItems.length")) {
          this.set("selectedCustomer", dataTable.get("selectedItems")[0]);
          this.get("store").query("contact", {
            customer: this.get("selectedCustomer.id")
          });
          this.set("oldString", null);
          this.reset();
        }
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});