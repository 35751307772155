define("crm/helpers/sex-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sexDisplay = sexDisplay;
  _exports.default = void 0;

  function sexDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return this.get('intl').t('models.sex.m');

        case 2:
          return this.get('intl').t('models.sex.f');

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});