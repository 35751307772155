define("crm/controllers/organizations/organization/debts/debt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    openItems: Ember.computed("model.customer.id", function () {
      if (this.get("model.customer.id")) {
        return this.get("store").query("openItem", {
          customer: this.get("model.customer.id"),
          company: this.get("organization.company.id")
        });
      }
    })
  });

  _exports.default = _default;
});