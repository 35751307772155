define("crm/components/show-task-actions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "task",
          action: action,
          model: this.get("record")
        });
      },
      openDone: function openDone() {
        this.sendAction('openDone', this.get("record"));
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
          this.sendAction("updateModel");
        }
      },
      done: function done() {
        if (this.get("record.status") !== 2) {
          this.get("record").set("doneTaskDate", (0, _moment.default)().format());
          this.get("record").set("status", 2);
          this.get("record").save();
        } else {
          this.get("record").set("doneTaskDate", null);
          this.get("record").set("status", 1);
          this.get("record").save();
        }

        this.sendAction("updateModel");
      },
      openCommentCreate: function openCommentCreate() {
        this.sendAction('openCommentCreate', this.get("record"));
      }
    }
  });

  _exports.default = _default;
});