define("crm/components/internment/action-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    currentUser: Ember.computed.readOnly("session.currentUser"),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    close: function close() {
      this.toggleProperty("closed");
    },
    showSwitch: Ember.computed("model", "action.id", "thisDate", function () {
      if (this.get("action.id") || !this.get("model.prevStarted") || !this.get("model.period")) {
        return false;
      }

      var task = this.get("model");
      var columnTime = (0, _moment.default)(this.get("thisDate"));
      var ref = (0, _moment.default)(task.get("prevStarted")).startOf("hour");

      if (task.get("trueFirstAction") && columnTime.isAfter(task.get("trueFirstAction.started"), "h") && task.lastActionUntil(columnTime, 1, 5)) {
        ref = (0, _moment.default)(task.lastActionUntil(columnTime, 1, 5).get("started")).startOf("hour");
      }

      return columnTime.diff(ref, "h") % task.get("period") !== 0;
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this.reset();
      var self = this;

      if (this.get("action")) {
        this.set("modelNotes", this.get("action.notes"));
        this.set("reSchedule", !this.get("action.skipSchedule"));
      } else {
        this.set("reSchedule", true);
      }

      if (this.get("action") && this.get("model") && this.get("model.subTaskItemTypes.length") > 0) {
        this.get("store").findRecord("action", this.get("action.id")).then(function (action) {
          self.set("action", action);
        });
      }

      if (this.get("action") && this.get("action.doer") && (this.get("action.status") === 1 || this.get("action.status") === 2)) {
        this.set("doer", this.get("action.doer"));
      } else {
        this.set("doer", this.get("currentWorker"));
      }

      this.set("physicalExam", {});
      this.set("notes", undefined);

      if (this.get("action") && this.get("action.notes")) {
        this.set("notes", this.get("action.notes"));
      }

      if (!Ember.isEmpty(this.get("action.physicalExam.id"))) {
        this.get("store").findRecord("physical-exam", this.get("action.physicalExam.id")).then(function (savedPhysicalExam) {
          self.set("physicalExam", savedPhysicalExam);
        });
      }

      if (this.get("model.name") && this.get("model.name") === this.get("intl").t("monitorings.monitoring.physicalExame").toString()) {
        if (this.get("action.notes")) {
          try {
            this.set("parsedActionNotes", JSON.parse(this.get("action.notes")));
            this.set("physicalExam", JSON.parse(this.get("action.notes")));
          } catch (err) {
            this.set("parsedActionNotes", this.get("action.notes"));
            this.set("physicalExam", this.get("action.notes"));
          }
        }
      }
    },
    isDrug: Ember.computed("model.kind", function () {
      return this.get("model.kind") === "drug";
    }),
    isSerum: Ember.computed("model.kind", function () {
      return this.get("model.kind") === "serum";
    }),
    isExam: Ember.computed("model.kind", function () {
      return this.get("model.kind") === "exam";
    }),
    actionIsDoneOrRemoved: Ember.computed("action", "action.status", function () {
      return [1, 2].includes(this.get("action.status"));
    }),
    canRemoveAction: Ember.computed("action", "action.status", function () {
      return [1, 2].includes(this.get("action.status"));
    }),
    canUpdateAction: Ember.computed("action", "action.doer", "currentWorker.id", "currentUser.id", function () {
      return Ember.isEmpty(this.get("action")) || this.get("action") && this.get("action.status") === 5 || this.get("currentUser.id") == 0 || this.get("currentWorker.id") === this.get("action.doer.id") || this.get("currentWorker.role") === 1;
    }),
    shouldDisplayNotesButtons: Ember.computed("notes", "action", "action.doer", "modelNotes", "currentUser.id", function () {
      if (this.get("action")) {
        if (this.get("action.status") === 3 || (this.get("action.status") === 1 || this.get("action.status") === 2) && (this.get("currentUser.id") == 0 || this.get("currentWorker.id") === this.get("action.doer.id")) && this.get("notes") && this.get("modelNotes") !== this.get("notes")) {
          return true;
        }
      } else {
        if (this.get("notes")) {
          return true;
        }
      }
    }),
    currentWeightChanged: Ember.computed("model.internment.patient.currentWeight.weighted", function () {
      if (this.get("model.internment.patient.currentWeight.weighted")) {
        return (0, _moment.default)(this.get("model.internment.patient.currentWeight.weighted")).isAfter(this.get("model.created"));
      }
    }),
    shouldDisplayPhysicalExamNotesButton: Ember.computed("action.doer", "physicalExam.abdomen", "physicalExam.attitude", "physicalExam.pain", "physicalExam.heartRate", "physicalExam.respiratoryFrequency", "physicalExam.ganglia", "physicalExam.hydration", "physicalExam.mucous", "physicalExam.bloodPressure", "physicalExam.temperature", "physicalExam.capillaryRepletionTime", "physicalExam.notes", "currentWorker", function () {
      if (this.get("model.isPhysicalExam")) {
        if (this.get("action")) {
          if (this.get("parsedActionNotes.abdomen") !== this.get("physicalExam.abdomen") || this.get("parsedActionNotes.attitude") !== this.get("physicalExam.attitude") || this.get("parsedActionNotes.pain") !== this.get("physicalExam.pain") || this.get("parsedActionNotes.heartRate") !== this.get("physicalExam.heartRate") || this.get("parsedActionNotes.respiratoryFrequency") !== this.get("physicalExam.respiratoryFrequency") || this.get("parsedActionNotes.ganglia") !== this.get("physicalExam.ganglia") || this.get("parsedActionNotes.hydration") !== this.get("physicalExam.hydration") || this.get("parsedActionNotes.mucous") !== this.get("physicalExam.mucous") || this.get("parsedActionNotes.bloodPressure") !== this.get("physicalExam.bloodPressure") || this.get("parsedActionNotes.temperature") !== this.get("physicalExam.temperature") || this.get("parsedActionNotes.capillaryRepletionTime") !== this.get("physicalExam.capillaryRepletionTime") || this.get("parsedActionNotes.notes") !== this.get("physicalExam.notes") && this.get("currentWorker.id") === this.get("action.doer.id")) {
            return true;
          }
        } else {
          if (this.get("physicalExam.abdomen") || this.get("physicalExam.attitude") || this.get("physicalExam.pain") || this.get("physicalExam.heartRate") || this.get("physicalExam.respiratoryFrequency") || this.get("physicalExam.ganglia") || this.get("physicalExam.hydration") || this.get("physicalExam.mucous") || this.get("physicalExam.bloodPressure") || this.get("physicalExam.temperature") || this.get("physicalExam.capillaryRepletionTime") || this.get("physicalExam.notes")) {
            return true;
          }
        }
      }
    }),
    isFuture: Ember.computed("thisDate", function () {
      if (this.get("thisDate")) {
        return (0, _moment.default)().isBefore(this.get("thisDate"), "h");
      }
    }),
    updateCartSize: function updateCartSize() {
      this.get("model.internment.shoppingCart.id") ? this.get("store").findRecord("internment-shopping-cart", this.get("model.internment.shoppingCart.id")).then(function () {
        Ember.$("#shopping-cart-nav a").addClass("animated pulse text-sales");
        Ember.run.later(function () {
          return Ember.$("#shopping-cart-nav a").removeClass("animated pulse text-sales");
        }, 1000);
      }) : null;
    },
    saveAction: function saveAction(action) {
      var self = this;
      this.get("store").createRecord("action", action).save().then(function (action) {
        if (Ember.isEmpty(action.get("monitoring.content")) && Ember.isEmpty(action.get("chore.content"))) {
          self.updateCartSize();
        }

        self.actionDone(action);
      }).catch(function (result) {
        self.get("es").errorReturned(result);
      });
    },
    actions: {
      reset: function reset() {
        this.reset();
      },
      cancelAction: function cancelAction() {
        this.close();
      },
      setDoerSelectAnswer: function setDoerSelectAnswer(value) {
        this.set("doer", value);
      },
      setInitials: function setInitials(value) {
        this.set("initialsValue", value);
      },
      doAction: function doAction(status) {
        if (status === 4) {
          return;
        }

        if (status === 2) {
          if (!confirm(this.get("intl").t("intl.remove"))) {
            return;
          }
        }

        var self = this;
        var date = (0, _moment.default)(self.get("thisDate"));
        /*
         * Prepare data
         */

        if (Ember.isEmpty(this.get("description"))) {
          this.set("description", undefined);
        }

        if (Ember.isEmpty(this.get("initialsValue"))) {
          this.set("initialsValue", undefined);
        }

        if (status === 3 && this.get("model.responsibleVet")) {
          this.set("doer", this.get("model.responsibleVet"));
        }
        /*
         * Create action
         */


        var action = {
          status: status,
          started: date,
          doer: self.get("doer"),
          notes: self.get("notes"),
          initials: self.get("initialsValue"),
          skipSchedule: !self.get("reSchedule")
        };
        action[self.get("model.kind")] = self.get("model");

        if (self.get("model.isPhysicalExam")) {
          self.get("store").createRecord("physical-exam", self.get("physicalExam")).save().then(function (savedPhysicalExam) {
            action.physicalExam = savedPhysicalExam;
            self.saveAction(action);
          });
        } else {
          self.saveAction(action);
        }
      },
      removeAction: function removeAction() {
        if (confirm(this.get("intl").t("intl.revert"))) {
          var self = this;
          var action = this.get("action");
          action.destroyRecord().then(function () {
            if (Ember.isEmpty(action.get("monitoring.content")) && Ember.isEmpty(action.get("chore.content"))) {
              self.updateCartSize();
            }

            self.actionDone(undefined);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      updateAction: function updateAction(actionID, status) {
        var self = this;
        var date = (0, _moment.default)(self.get("thisDate"));
        this.get("store").findRecord("action", actionID).then(function (action) {
          if (status === 3) {
            action.set("status", status);
            action.set("started", date);
            action.set("doer", self.get("currentWorker"));
            action.set("notes", self.get("notes"));
          } else {
            action.set("status", status);
            action.set("notes", self.get("notes"));
            action.set("doer", self.get("doer"));

            if (Ember.isEmpty(action.get("initials"))) {
              action.set("initials", self.get("initialsValue"));
            }
          }

          if (self.get("physicalExam.id")) {
            self.get("physicalExam").save();
            action.set("physicalExam", self.get("physicalExam"));
          }

          action.save().then(function (action) {
            self.actionDone(action);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        });
      },
      imageUpdated: function imageUpdated() {
        this.get("store").findRecord("action", this.get("action.id"));
      },
      transitionToActionDetails: function transitionToActionDetails(actionID) {
        Ember.$(".modal").modal("hide");
        this.sendAction("transitionToActionDetails", this.get("model.internment.patient.organization.id"), this.get("model.internment.id"), this.get("model.id"), actionID, this.get("model.kind"));
      }
    },
    initials: Ember.computed("intl", "model.name", function () {
      if (this.get("model.name") === this.get("intl").t("internments.internment.event.urine").toString()) {
        return [{
          id: 1,
          name: this.get("intl").t("initials.urineNormal.name"),
          description: this.get("intl").t("initials.urineNormal.description")
        }, {
          id: 2,
          name: this.get("intl").t("initials.urineConcentrated.name"),
          description: this.get("intl").t("initials.urineConcentrated.description")
        }, {
          id: 3,
          name: this.get("intl").t("initials.urineNormalByExpression.name"),
          description: this.get("intl").t("initials.urineNormalByExpression.description")
        }, {
          id: 4,
          name: this.get("intl").t("initials.urineConcentratedByExpression.name"),
          description: this.get("intl").t("initials.urineConcentratedByExpression.description")
        }, {
          id: 5,
          name: this.get("intl").t("initials.urineJaundiced.name"),
          description: this.get("intl").t("initials.urineJaundiced.description")
        }, {
          id: 6,
          name: this.get("intl").t("initials.hematuria.name"),
          description: this.get("intl").t("initials.hematuria.description")
        }, {
          id: 28,
          name: this.get("intl").t("initials.noUrine.name"),
          description: this.get("intl").t("initials.noUrine.description")
        }];
      } else if (this.get("model.name") === this.get("intl").t("internments.internment.event.faeces").toString()) {
        return [{
          id: 7,
          name: this.get("intl").t("initials.faecesNormal.name"),
          description: this.get("intl").t("initials.faecesNormal.description")
        }, {
          id: 8,
          name: this.get("intl").t("initials.faecesLiquid.name"),
          description: this.get("intl").t("initials.faecesLiquid.description")
        }, {
          id: 9,
          name: this.get("intl").t("initials.faecesLiquidWithBlood.name"),
          description: this.get("intl").t("initials.faecesLiquidWithBlood.description")
        }, {
          id: 10,
          name: this.get("intl").t("initials.faecesHard.name"),
          description: this.get("intl").t("initials.faecesHard.description")
        }, {
          id: 11,
          name: this.get("intl").t("initials.faecesHardWithBlood.name"),
          description: this.get("intl").t("initials.faecesHardWithBlood.description")
        }, {
          id: 12,
          name: this.get("intl").t("initials.faecesSoft.name"),
          description: this.get("intl").t("initials.faecesSoft.description")
        }, {
          id: 22,
          name: this.get("intl").t("initials.faecesSoftMoulded.name"),
          description: this.get("intl").t("initials.faecesSoftMoulded.description")
        }, {
          id: 27,
          name: this.get("intl").t("initials.noFaeces.name"),
          description: this.get("intl").t("initials.noFaeces.description")
        }, {
          id: 29,
          name: this.get("intl").t("initials.faecesDigestedBlood.name"),
          description: this.get("intl").t("initials.faecesDigestedBlood.description")
        }, {
          id: 30,
          name: this.get("intl").t("initials.faecesLivingBlood.name"),
          description: this.get("intl").t("initials.faecesLivingBlood.description")
        }];
      } else if (this.get("model.name") === this.get("intl").t("internments.internment.event.feeding").toString()) {
        return [{
          id: 13,
          name: this.get("intl").t("initials.feedingNormal.name"),
          description: this.get("intl").t("initials.feedingNormal.description")
        }, {
          id: 14,
          name: this.get("intl").t("initials.feedingForced.name"),
          description: this.get("intl").t("initials.feedingForced.description")
        }];
      } else if (this.get("model.name") === this.get("intl").t("internments.internment.event.vomit").toString()) {
        return [{
          id: 15,
          name: this.get("intl").t("initials.vomitFood.name"),
          description: this.get("intl").t("initials.vomitFood.description")
        }, {
          id: 16,
          name: this.get("intl").t("initials.vomitFoamy.name"),
          description: this.get("intl").t("initials.vomitFoamy.description")
        }, {
          id: 17,
          name: this.get("intl").t("initials.vomitBile.name"),
          description: this.get("intl").t("initials.vomitBile.description")
        }, {
          id: 31,
          name: this.get("intl").t("initials.vomitWithBlood.name"),
          description: this.get("intl").t("initials.vomitWithBlood.description")
        }, {
          id: 32,
          name: this.get("intl").t("initials.vomitRegurgitation.name"),
          description: this.get("intl").t("initials.vomitRegurgitation.description")
        }];
      } else if (this.get("model.name") === this.get("intl").t("internments.internment.event.food").toString()) {
        return [{
          id: 18,
          name: this.get("intl").t("initials.foodNormal.name"),
          description: this.get("intl").t("initials.foodNormal.description")
        }, {
          id: 19,
          name: this.get("intl").t("initials.foodForced.name"),
          description: this.get("intl").t("initials.foodForced.description")
        }, {
          id: 20,
          name: this.get("intl").t("initials.didntEat.name"),
          description: this.get("intl").t("initials.didntEat.description")
        }, {
          id: 21,
          name: this.get("intl").t("initials.fasting.name"),
          description: this.get("intl").t("initials.fasting.description")
        }, {
          id: 33,
          name: this.get("intl").t("initials.foodTube.name"),
          description: this.get("intl").t("initials.foodTube.description")
        }];
      } else if (this.get("model.name") === this.get("intl").t("internments.internment.event.catheter").toString()) {
        return [{
          id: 23,
          name: this.get("intl").t("initials.catheterRightForelimb.name"),
          description: this.get("intl").t("initials.catheterRightForelimb.description")
        }, {
          id: 24,
          name: this.get("intl").t("initials.catheterLeftForelimb.name"),
          description: this.get("intl").t("initials.catheterLeftForelimb.description")
        }, {
          id: 25,
          name: this.get("intl").t("initials.catheterRightHindlimb.name"),
          description: this.get("intl").t("initials.catheterRightHindlimb.description")
        }, {
          id: 26,
          name: this.get("intl").t("initials.catheterLeftHindlimb.name"),
          description: this.get("intl").t("initials.catheterLeftHindlimb.description")
        }];
      } else {
        return undefined;
      }
    }),
    actionDone: function actionDone(action) {
      var self = this;

      if (this.get("model.subTaskItemTypes.length") > 0) {
        this.get("model.subTaskItemTypes").forEach(function (subTaskItemType) {
          self.createSubTaskItem(action, subTaskItemType);
        });
      }

      Ember.$("#actionModal").modal("hide");
      var newThisDate = (0, _moment.default)(this.get("thisDate")).add(1, "millisecond");
      this.set("thisDate", newThisDate);
      this.set("action", action);
      self.get("store").findRecord(self.get("model.constructor.modelName"), self.get("model.id"));
      this.close();
    },
    reset: function reset() {
      if (this.get("model.subTaskItemTypes.length") > 0) {
        this.get("model.subTaskItemTypes").forEach(function (subTaskItemType) {
          subTaskItemType.set("valAux", null);
        });
      }

      this.set("doer", this.get("currentWorker"));
      Ember.$("form").find("#doerSelect").val(this.get("currentWorker.id"));
      this.set("initialsValue", undefined);
      this.set("physicalExam", {});

      if (this.get("reSchedule")) {
        this.set("reSchedule", true);
      }
    },
    createSubTaskItem: function createSubTaskItem(action, subTaskItemType) {
      var self = this;
      var subTaskItem = this.get("store").createRecord("sub-task-item", {
        name: subTaskItemType.get("name"),
        val: subTaskItemType.get("valAux"),
        subTaskItemType: subTaskItemType,
        taskItem: action
      });
      subTaskItem.save().catch(function (result) {
        self.get("es").errorReturned(result);
        self.get("store").unloadRecord(subTaskItem);
      });
    }
  });

  _exports.default = _default;
});