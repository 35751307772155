define("crm/routes/organizations/organization/group/consultations/index", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      before: {
        refreshModel: true
      },
      after: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var companyID = this.modelFor("organizations.organization").get("company.id");
      var begin = params.before;
      var end = params.after;

      if (begin && end) {
        if ((0, _moment.default)(begin).diff(end, "days") <= this.get("dateLimit")) {
          var self = this;
          return Ember.$.get({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/consultations/overview?company=" + companyID + "&before=" + new Date(_moment.default.utc(begin).endOf("d")).toISOString() + "&after=" + new Date(_moment.default.utc(end).startOf("d")).toISOString(),
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          });
        }
      }

      return [];
    }
  });

  _exports.default = _default;
});