define("crm/helpers/calculate-price-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculatePriceDisplay = calculatePriceDisplay;
  _exports.default = void 0;

  function calculatePriceDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return this.get('intl').t('models.sales.optionCalculatePrices.manual');

        case 2:
          return this.get('intl').t('models.sales.optionCalculatePrices.automaticSellingPrice');

        case 3:
          return this.get('intl').t('models.sales.optionCalculatePrices.automaticMargin');

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});