define("crm/components/order-lines-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (!Ember.isEmpty(this.get("model"))) {
        if (this.get("model.firstObject.discount")) {
          this.set("model.discount", this.get("model.firstObject.discount"));
        } else {
          this.set("model.discount", null);
        }
      }
    },
    close: function close() {
      Ember.$("#modal-order-lines-edit").modal('hide');
    },
    childs: Ember.computed("model.[]", "model.@each.linePrice", "model.id", function () {
      if (!Ember.isEmpty(this.get("model"))) {
        return this.get("model").filterBy("linePrice");
      }
    }),
    filteredData: Ember.computed("model.discount", "childs.[]", function () {
      var self = this;
      var result = [];

      if (!Ember.isEmpty(this.get("childs"))) {
        this.get("childs").forEach(function (child) {
          var bruto = self.bruto(child.unitPrice, child.quantity);

          if (self.get("model.discount")) {
            var newDiscount = Ember.isEmpty(child.discount) ? Number(self.get("model.discount")) : self.getNewDiscount(Number(self.get("model.discount")), child.discount);
            result.push({
              name: child.item.get("name"),
              quantity: child.quantity,
              discountTotal: self.valorTotalDesc(bruto, newDiscount),
              discount: newDiscount,
              linePrice: self.brutoDesc(bruto, self.valorTotalDesc(bruto, newDiscount))
            });
          } else {
            result.push({
              name: child.item.get("name"),
              quantity: child.quantity,
              discountTotal: self.valorTotalDesc(bruto, child.discount),
              discount: child.discount,
              linePrice: self.brutoDesc(bruto, self.valorTotalDesc(bruto, child.discount))
            });
          }
        });
      }

      return result;
    }),
    getNewDiscount: function getNewDiscount(itemItemDiscount, discount) {
      var result;

      if (discount) {
        if (itemItemDiscount) {
          result = discount + itemItemDiscount * (100 - discount) / 100;
        } else {
          result = discount;
        }
      } else {
        if (itemItemDiscount) {
          result = itemItemDiscount;
        } else {
          result = 0;
        }
      }

      return result;
    },
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },
    bruto: function bruto(unitPrice, quantity) {
      return this.round(unitPrice * quantity);
    },
    valorTotalDesc: function valorTotalDesc(bruto, discount) {
      return this.round(bruto * discount / 100);
    },
    brutoDesc: function brutoDesc(bruto, valorTotalDesc) {
      return this.round(bruto - valorTotalDesc);
    },
    actions: {
      save: function save() {
        this.get('flashMessages').success(this.get('intl').t('alerts.changesSaved'), {
          timeout: 3000
        });
        var self = this;
        this.get("childs").forEach(function (child) {
          var bruto = self.bruto(child.unitPrice, child.quantity);

          if (self.get("model.discount")) {
            var newDiscount = Ember.isEmpty(child.discount) ? Number(self.get("model.discount")) : self.getNewDiscount(Number(self.get("model.discount")), child.discount);
            Ember.set(child, "discount", newDiscount);
            Ember.set(child, "discountTotal", self.valorTotalDesc(bruto, newDiscount));
            Ember.set(child, "linePrice", self.brutoDesc(bruto, self.valorTotalDesc(bruto, newDiscount)));
          } else {
            Ember.set(child, "discount", child.discount);
            Ember.set(child, "discountTotal", self.valorTotalDesc(bruto, child.discount));
            Ember.set(child, "linePrice", self.brutoDesc(bruto, self.valorTotalDesc(bruto, child.discount)));
          }
        });
        this.close();
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {}
    }
  });

  _exports.default = _default;
});