define("crm/routes/organizations/organization/consultations/consultation", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      this.set("breadCrumb", {
        finalTitle: (0, _moment.default)(model.get("date")).format("YYYY-MM-DD HH:mm")
      });
    }
  });

  _exports.default = _default;
});