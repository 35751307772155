define("crm/controllers/organizations/organization/procurement/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    queryParams: ["queryPurchaseRequests", "queryPurchaseOrders", "queryPurchaseInvoices", "queryPurchaseReceipts", "queryPurchaseMemos", "createdAfter", "createdBefore"],
    queryPurchaseRequests: true,
    queryPurchaseOrders: true,
    queryPurchaseInvoices: true,
    queryPurchaseReceipts: true,
    queryPurchaseMemos: true,
    canDoDocuments: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    filteredModels: Ember.computed('model.purchaseInvoices.[]', 'model.purchaseOrders.[]', 'model.purchaseReceipts.[]', 'model.purchaseMemos.[]', 'model.purchaseRequests.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.purchaseInvoices"))) {
        result.addObjects(this.get("model.purchaseInvoices"));
      }

      if (!Ember.isEmpty(this.get("model.purchaseOrders"))) {
        result.addObjects(this.get("model.purchaseOrders"));
      }

      if (!Ember.isEmpty(this.get("model.purchaseReceipts"))) {
        result.addObjects(this.get("model.purchaseReceipts"));
      }

      if (!Ember.isEmpty(this.get("model.purchaseMemos"))) {
        result.addObjects(this.get("model.purchaseMemos"));
      }

      if (!Ember.isEmpty(this.get("model.purchaseRequests"))) {
        result.addObjects(this.get("model.purchaseRequests"));
      }

      return result;
    }),
    actions: {
      updateQuery: function updateQuery(name, params) {
        this.set(name, params);
      },
      openPurchaseReceiptNew: function openPurchaseReceiptNew() {
        this.send("openModal", {
          entity: "purchase-receipt",
          action: "new"
        });
      },
      openPurchaseModal: function openPurchaseModal() {
        this.send("openModal", {
          entity: "purchase-invoice",
          action: "new"
        });
      },
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      },
      transition: function transition(route, id) {
        this.send("transitionToRouteID", route, id);
      }
    }
  });

  _exports.default = _default;
});