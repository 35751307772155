define("crm/components/worker-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      editWorkerModal: function editWorkerModal(record) {
        this.sendAction('openEditWorkerModal', record);
      },
      deleteWorker: function deleteWorker() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
        }
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
      }
    }
  });

  _exports.default = _default;
});