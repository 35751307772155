define("crm/components/communication-errors-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.[]", "model.@each.created", function () {
      return [{
        "propertyName": "id",
        "className": "column-id"
      }, {
        "propertyName": "sendDate",
        "component": "umt-datetime",
        "title": this.get('intl').t("communicationErrors.communicationError.appointmentDate"),
        "className": "column-small",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "campaign.name",
        "title": this.get('intl').t("communicationErrors.communicationError.campaign"),
        "className": "column-small"
      }, {
        "propertyName": "customerName",
        "title": this.get('intl').t("communicationErrors.communicationError.customer"),
        "className": "column-small"
      }, {
        "propertyName": "periodic.patient.name",
        "title": this.get('intl').t("communicationErrors.communicationError.patient"),
        "className": "column-small"
      }, {
        "propertyName": "text",
        "title": this.get('intl').t("communicationErrors.communicationError.text"),
        "className": "column-small"
      }, {
        "propertyName": "phoneNumber",
        "title": this.get('intl').t("communicationErrors.communicationError.phoneNumber"),
        "className": "column-small"
      }];
    })
  });

  _exports.default = _default;
});