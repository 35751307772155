define("crm/controllers/organizations/organization/group/purchase-orders/new", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    supplierItems: Ember.computed("model.organization.company.id", "model.supplier.id", function () {
      if (this.get("model.organization.company.id") && this.get("model.supplier.id")) {
        return this.get("store").query("item", {
          company: this.get("model.organization.company.id"),
          supplier: this.get("model.supplier.id")
        });
      } else {
        return [];
      }
    }),
    openSupplierPurchaseRequests: Ember.computed("model.supplier.id", function () {
      return this.get("model.supplier.id") ? this.get("store").query("purchase-request", {
        open: "true",
        supplier: this.get("model.supplier.id"),
        company: this.get("model.organization.company.id")
      }, {
        reload: true
      }) : [];
    }),
    items: Ember.computed("model.shortageItems.[]", "supplierItems.@each.name", "model.warehouse.name", function () {
      var result = [];
      var self = this;

      if (this.get("model.warehouse.name") && this.get("supplierItems") && this.get("model.shortageItems")) {
        this.get("supplierItems").forEach(function (i) {
          self.get("model.shortageItems").forEach(function (s) {
            if (s.Item == i.get("id") && s.Warehouse.toUpperCase() === self.get("model.warehouse.name").toUpperCase()) {
              result.push({
                name: i.get("name"),
                type: i.get("type"),
                baseUnit: {
                  name: s.Stock + " (" + s.MinStock + ")"
                },
                item: i,
                quantity: s.MinStock - s.Stock
              });
            }
          });
        });
      }

      return result;
    }),
    disabledAction: Ember.computed("creating", "model.lines.[]", "model.name", "model.supplier", function () {
      return this.get("creating") || !(this.get("model.lines.length") && this.get("model.supplier.id"));
    }),
    linesPerID: Ember.computed("model.lines.[]", function () {
      if (this.get("model.lines")) {
        return this.get("model.lines").mapBy("item.id");
      }
    }),
    actions: {
      addOpenSupplierPurchaseRequest: function addOpenSupplierPurchaseRequest(openSupplierPurchaseRequest) {
        var self = this;
        openSupplierPurchaseRequest.set("active", true);
        this.store.query("purchase-request-line", {
          purchaseRequest: openSupplierPurchaseRequest.get("id"),
          open: "true"
        }, {
          reload: true
        }).then(function (lines) {
          if (!Ember.isEmpty(lines) && lines.get("length") > 0) {
            lines.sortBy("lineGroupTree").forEach(function (purchaseRequestLine) {
              self.get("store").findRecord("item", purchaseRequestLine.get("item").get("id"), {
                reload: true
              }).then(function (item) {
                self.get("model.lines").addObject({
                  name: item.get("name"),
                  purchaseRequestLine: purchaseRequestLine,
                  item: item,
                  quantity: item.get("maxStock") ? item.get("maxStock") - item.get("minStock") + purchaseRequestLine.get("quantity") : purchaseRequestLine.get("quantity")
                });
              });
            });
          }
        });
      },
      addLine: function addLine(itemAux) {
        var self = this;
        var item = itemAux.item;

        if (!item) {
          item = itemAux;
        }

        if (!this.get("linesPerID").includes(item.get("id"))) {
          this.get("store").findRecord("item", item.get("id"), {
            reload: true
          }).then(function (item) {
            return self.get("model.lines").addObject({
              name: item.get("name"),
              item: item,
              quantity: item.get("maxStock") ? item.get("maxStock") - item.get("minStock") + itemAux.quantity : itemAux.quantity
            });
          });
        }
      },
      deleteLine: function deleteLine(line) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("model.lines").removeObject(line);
        }
      },
      create: function create() {
        var self = this;
        this.set("creating", true);
        this.set("model.date", (0, _moment.default)());
        this.get("store").createRecord("purchase-order", this.get("model")).save().then(function (purchaseOrder) {
          var purchaseOrderLines = [];
          self.get("model.lines").forEach(function (line) {
            if (line.purchaseRequestLine) {
              self.get("store").findRecord("purchase-request-line", line.purchaseRequestLine.id, {
                reload: true
              }).then(function (requestline) {
                return self.get("store").findRecord("item", requestline.get("item").get("id"), {
                  reload: true
                }).then(function (item) {
                  item.set("quantity", item.get("quantity") + line.quantity);
                });
              });
            }

            line.purchaseOrder = purchaseOrder;
            line.warehouse = self.get("model.warehouse");
            var purchaseOrderLine = {
              name: line.item.get("name"),
              purchaseOrder: purchaseOrder.get("id"),
              warehouse: self.get("model.warehouse.id"),
              item: line.item.get("id"),
              quantity: line.quantity,
              purchaseRequestLine: line.purchaseRequestLine ? line.purchaseRequestLine.get("id") : null
            };
            purchaseOrderLines.addObject(purchaseOrderLine);
          });
          Ember.$.ajax({
            method: "POST",
            beforeSend: function beforeSend(request) {
              return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            },
            accept: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(purchaseOrderLines),
            url: _environment.default.universe.api.wildfly + "/purchaseOrderLines/list"
          }).then(function () {
            self.transitionToRoute("organizations.organization.purchase-orders");
            self.get("flashMessages").success(self.get("intl").t("purchases.messages.successfullySaved") + ": " + purchaseOrder.get("name"), {
              sticky: true
            });
          }, function (result) {
            self.get("es").handle(result);
          });
          self.set("creating", false);
          self.transitionToRoute("organizations.organization.group.purchase-orders");
        }).catch(function (result) {
          self.set("creating", false);
          self.get("es").errorReturned(result);
        });
      }
    }
  });

  _exports.default = _default;
});