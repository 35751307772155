define("crm/controllers/organizations/organization/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [{
        "propertyName": "name",
        "title": this.get("intl").t("templates.name"),
        "component": "title-models-table-display"
      }, {
        "title": " ",
        "component": "templates-actions",
        "className": "column-actions-1"
      }];
      return listCollumns;
    })
  });

  _exports.default = _default;
});