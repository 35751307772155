define("crm/components/t-day-frequence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: 'span',
    str: Ember.computed('model.period', function () {
      var _this = this;

      if (this.get("model.period")) {
        return this.get("catalogs.todoPeriods").filter(function (x) {
          return x.id == _this.get("model.period");
        })[0].name;
      } else {
        return this.get('intl').t('periodics.periods.drugs.noRepeat');
      }
    })
  });

  _exports.default = _default;
});