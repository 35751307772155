define("crm/components/card-customer-slim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['card-slim'],
    color: Ember.computed("model.currentAccount", function () {
      if (this.get("model.currentAccount")) {
        if (this.get("model.currentAccount") > 0) {
          return "text-danger";
        } else {
          return "text-info";
        }
      }
    })
  });

  _exports.default = _default;
});