define("crm/helpers/eq-ish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eqIsh = eqIsh;
  _exports.default = void 0;

  function eqIsh() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return params[0] == params[1];
    }
  });

  _exports.default = _default;
});