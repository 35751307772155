define("crm/components/purchase-invoice-lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "created",
        "title": this.get('intl').t("invoices.invoice.emissionDate"),
        "component": "umt-date",
        "className": "column-date",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "purchaseInvoice.supplier.name",
        "title": this.get('intl').t("suppliers.supplier.title"),
        "className": "column-price"
      }, {
        "propertyName": "purchasePrice",
        "title": this.get('intl').t("items.item.purchasePrice"),
        "className": "text-sales text-right"
      }, {
        "propertyName": "lot",
        "title": this.get('intl').t("purchases.lot"),
        "className": "column-price"
      }, {
        "propertyName": "shelfLife",
        "title": this.get('intl').t("purchases.shelfLife"),
        "className": "column-date",
        "component": "umt-date"
      }, {
        "propertyName": "unitPrice",
        "title": this.get('intl').t("items.item.unitPrice"),
        "className": "column-price-large"
      }, {
        "propertyName": "getVat.vat.amount",
        "title": this.get('intl').t("taxes.vat.title"),
        "className": "column-vat"
      }, {
        "propertyName": "quantity",
        "title": "#",
        "className": "column-price"
      }, {
        "propertyName": "quantityPromotion",
        "title": this.get('intl').t("purchases.quantityPromotion"),
        "className": "column-price"
      }, {
        "propertyName": "discount",
        "title": this.get('intl').t("orderLines.orderLine.discount"),
        "className": "column-price"
      }, {
        "propertyName": "discount2",
        "title": this.get('intl').t("items.item.pvpDiscount2"),
        "className": "column-price"
      }, {
        "propertyName": "linePrice",
        "title": this.get('intl').t("items.item.linePrice"),
        "className": "column-price"
      }];
    }),
    actions: {
      openModal: function openModal(document) {
        this.sendAction("openModal", {
          entity: "document",
          action: "details",
          model: document
        });
      },
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    }
  });

  _exports.default = _default;
});