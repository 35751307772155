define("crm/components/absence-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.set("model.startDate", (0, _moment.default)(this.get("model.startDate")).format("YYYY-MM-DD"));
        this.set("model.endDate", (0, _moment.default)(this.get("model.endDate")).format("YYYY-MM-DD"));
        this.get("store").findRecord("absence", this.get("model.id"));
      }
    },
    reset: function reset() {
      Ember.$("#modal-absence-edit").modal("hide");
      self.set("disabledAction", false);
      this.set("model", {});
    },
    actions: {
      edit: function edit() {
        this.set("disabledAction", true);
        var self = this;
        this.set("model.startDate", (0, _moment.default)(this.get("model.startDate")).format("YYYY-MM-DD"));
        this.set("model.endDate", (0, _moment.default)(this.get("model.endDate")).format("YYYY-MM-DD"));
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function () {
          self.set("disabledAction", false);
        });
      },
      canceled: function canceled() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});