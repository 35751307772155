define("crm/controllers/organizations/organization/items/item/documents", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    exporting: false,
    queryParams: ["createdAfter", "createdBefore"],
    canDoDocuments: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    filteredModels: Ember.computed('model.invoiceLines.[]', 'model.memoLines.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoiceLines"))) {
        result.addObjects(this.get("model.invoiceLines"));
      }

      if (!Ember.isEmpty(this.get("model.memoLines"))) {
        result.addObjects(this.get("model.memoLines"));
      }

      return result;
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "propertyName": "created",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date hidden-xs"
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("patients.patient.title"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "customer.name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "component": "title-models-table-display",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "name",
        "component": "title-models-table-display",
        "className": "column-lg"
      }, {
        "propertyName": "quantity",
        "title": this.get("intl").t("items.item.quantity"),
        "className": "column-price-medium"
      }, {
        "propertyName": "unitPrice",
        "title": this.get('intl').t("items.item.unitPrice"),
        "className": "column-price",
        "component": "umt-price-display"
      }, {
        "propertyName": "linePrice",
        "title": this.get('intl').t("items.item.linePrice"),
        "className": "column-price",
        "component": "umt-price-display"
      }];
    }),
    actions: {
      openReceiptGenerate: function openReceiptGenerate() {
        this.send("openModal", {
          entity: "receipt",
          action: "generate"
        });
      },
      openPaymentGenerate: function openPaymentGenerate() {
        this.send("openModal", {
          entity: "payment",
          action: "generate"
        });
      },
      openPurchaseModal: function openPurchaseModal() {
        this.send("openModal", {
          entity: "purchase-invoice",
          action: "new"
        });
      },
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      },
      export: function _export() {
        var self = this;
        var createdAfter = (0, _moment.default)(this.get("createdAfter")).startOf("day").add(1, "h").toISOString();
        var createdBefore = (0, _moment.default)(this.get("createdBefore")).endOf("day").add(1, "h").toISOString();
        this.set("exporting", true);
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/invoices/xlsx?organization=" + self.get("model.organization.id") + "&createdBefore=" + createdBefore + "&createdAfter=" + createdAfter,
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "invoices-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});