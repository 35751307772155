define("crm/components/stays-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("date", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "box.name",
        "title": this.get('intl').t("stays.box"),
        "routeProperty": "customer.id"
      }, {
        "propertyName": "startDate",
        "component": "umt-date",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("stays.startDate")
      }, {
        "propertyName": "endDate",
        "component": "umt-date",
        "title": this.get('intl').t("stays.endDate")
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("stays.patient"),
        "routeProperty": "customer.id"
      }, {
        "component": "stays-actions"
      }];
    }),
    filtersObserver: Ember.observer("date", function () {
      if (!Ember.isEmpty(this.get("date"))) {
        this.sendAction("updateModel", this.get("date"));
      }
    }),
    actions: {
      openDetails: function openDetails(stay) {
        this.sendAction("openModal", {
          entity: "stay",
          action: "details",
          model: stay
        });
      },
      editStayModal: function editStayModal(stay) {
        this.sendAction("openModal", {
          entity: "stay",
          action: "edit",
          model: stay
        });
      },
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    }
  });

  _exports.default = _default;
});