define("crm/models/todo-exam", ["exports", "ember-data", "crm/models/todo"], function (_exports, _emberData, _todo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todo.default.extend({
    internment: _emberData.default.belongsTo("internment", {
      inverse: "exams"
    }),
    nextTodo: _emberData.default.belongsTo("todo-exam", {
      inverse: "prevTodo"
    }),
    prevTodo: _emberData.default.belongsTo("todo-exam", {
      inverse: "nextTodo"
    }),
    kind: Ember.computed(function () {
      return "exam";
    }),
    allExaminations: Ember.computed("allActions.@each.examination", function () {
      var result = [];
      this.get("allActions").forEach(function (action) {
        if (action.get("examination") && action.get("examination.id")) {
          result.addObject(action.get("examination"));
        }
      });
      return result;
    })
  });

  _exports.default = _default;
});