define("crm/models/organization", ["exports", "ember-data", "universe-gui/models/organization"], function (_exports, _emberData, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organization.default.extend({
    diagnostics: _emberData.default.hasMany('diagnostic'),
    enterprises: _emberData.default.hasMany('enterprise'),
    financialAccounts: _emberData.default.hasMany('financialAccount'),
    intermediaries: _emberData.default.hasMany('organization-intermediary'),
    organizationFeatures: _emberData.default.hasMany('organization-feature'),
    organizationSettings: _emberData.default.hasMany('organizationSettingBoolean'),
    rooms: _emberData.default.hasMany('room'),
    suppliers: _emberData.default.hasMany('supplier'),
    symptoms: _emberData.default.hasMany('symptom'),
    treatmentPlans: _emberData.default.hasMany('treatmentPlan'),
    workers: _emberData.default.hasMany('worker'),
    warehouses: _emberData.default.hasMany('warehouse'),
    units: _emberData.default.hasMany('unit'),
    features: Ember.computed("organizationFeatures.[]", function () {
      return this.get("organizationFeatures") ? this.get("organizationFeatures").map(function (o) {
        return o.get("feature");
      }) : null;
    }),
    featureNames: Ember.computed("features.[]", function () {
      return this.get("features") ? this.get("features").map(function (f) {
        return f.get("name");
      }) : [];
    }),
    noVat: Ember.computed("id", function () {
      return ["5", "24", // CV de Marte (DEV)
      "364" // EVC
      ].includes(this.get("id"));
    }),
    workersSorted: Ember.computed("workers.[]", function () {
      return this.get("workers") ? this.get("workers").sortBy("name") : [];
    }),
    workersAppointable: Ember.computed("workersSorted.[]", function () {
      return this.get("workersSorted") ? this.get("workersSorted").filterBy("appointable", true) : [];
    }),
    vets: Ember.computed("workers.[]", function () {
      return this.get("workers") ? this.get("workers").filter(function (w) {
        return w.get("isVet");
      }).sortBy("name") : [];
    }),
    vetsSorted: Ember.computed("vets.[]", function () {
      return this.get("vets");
    }),
    symptomsSorted: Ember.computed("symptoms.[]", function () {
      return !this.get("symptoms") ? [] : this.get("symptoms").sortBy("name");
    }),
    diagnosticsSorted: Ember.computed("diagnostics.[]", function () {
      return !this.get("diagnostics") ? [] : this.get("diagnostics").sortBy("name");
    }),
    treatmentPlansSorted: Ember.computed("treatmentPlans.[]", function () {
      return !this.get("treatmentPlans") ? [] : this.get("treatmentPlans").sortBy("name");
    }),
    settings: Ember.computed("organizationSettings.[]", function () {
      var result = {};
      this.get("organizationSettings").forEach(function (os) {
        return result[os.get("setting.code")] = os.get("value");
      });
      return result;
    }),
    hasFAs: Ember.computed("settings.sales_fa_finalCustomer", function () {
      return this.get("settings.sales_fa_finalCustomer");
    }),
    hasInternalConsumption: Ember.computed("company.id", function () {
      return ["59", "105", "148", "192"].includes(this.get("company.id"));
    }),
    visitDuration: Ember.computed(function () {
      return 30;
    }),
    footer: Ember.computed("id", function () {
      if (this.get("id") == "1") {
        return {
          line1: "Edif. Central, PCI, Via do Conhecimento, Ílahvo, Aveiro | NIPC 513 969 348",
          line2: "Telef: 91 639 50 20 | info@petuniversal.com | www.petuniversal.com"
        };
      } else if (this.get("id") == "185") {
        return {
          line1: "Rua Marechal Costa Gomes, nº9 - 7005-145 Évora | NIPC 503 997 218",
          line2: "Telef: 266 771 232 | Urgências 24h: 937 712 320 | geral@hvetmuralha.pt | www.hvetmuralha.pt"
        };
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});