define("crm/controllers/internal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    queryParams: ["createdAfter", "createdBefore"],
    columns: Ember.computed(function () {
      var result = [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "name",
        "component": "title-models-table-display",
        "className": "column-nif"
      }, {
        "propertyName": "count",
        "title": "Count"
      }];
      return result;
    }),
    actions: {
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      }
    }
  });

  _exports.default = _default;
});