define("crm/components/customer-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    canDoChanges: Ember.computed("can", function () {
      return this.get("can").can("update customer");
    }),
    actions: {
      updateCustomerModal: function updateCustomerModal(record) {
        this.sendAction('openUpdateCustomerModal', record);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
      }
    }
  });

  _exports.default = _default;
});