define("crm/components/transfer-stock-actions", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    hideEmitPdf: Ember.computed("record.status", function () {
      return !Ember.isEmpty(this.get("record.status")) && [4, 9, 3].includes(this.get("record.status"));
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "stock-transfer-order",
          action: action,
          model: this.get("record")
        });
      },
      showRecordPDF: function showRecordPDF() {
        var self = this;
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stockTransferOrders/" + self.get("record.id") + "/pdf",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.sendAction("showPdf", window.URL.createObjectURL(blob));
          },
          error: function error(result) {
            self.get("store").findRecord("stock-transfer-order", self.get("record.id"), {
              reload: true
            });
            self.get("es").handle(result);
          }
        });
      }
    }
  });

  _exports.default = _default;
});