define("crm/models/species", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    description: _emberData.default.attr('string'),
    breeds: _emberData.default.hasMany("breed"),
    animals: _emberData.default.hasMany("patient", {
      inverse: "species"
    })
  });

  _exports.default = _default;
});