define("crm/components/event-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    saving: false,
    close: function close() {
      this.toggleProperty("closed");
    },
    vetsList: Ember.computed("model.enterprise", function () {
      if (this.get("model.enterprise")) {
        return this.get("store").query("liaison", {
          enterprise: this.get("model.enterprise.id")
        });
      }
    }),
    disabledActionCaseNew: Ember.computed("model.enterprise.content", "model.liaison.content", "saving", "model.patient.content", function () {
      return !this.get("model.enterprise.content") || !this.get("model.liaison.content") || this.get("saving") || !this.get("model.patient.content");
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setOfEnterprise: function setOfEnterprise(value) {
        this.set("model.enterprise", value ? value : undefined);
      },
      setOfLiaison: function setOfLiaison(value) {
        this.set("model.liaison", value ? value : undefined);
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
        this.set("model.customer", null);
      },
      edit: function edit() {
        this.set("saving", true);
        var self = this;
        this.get("model").save().then(function () {
          self.close();
          self.get("flashMessages").success(self.get("intl").t("events.messages.title"));
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set("saving", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("saving", false);
      }
    }
  });

  _exports.default = _default;
});