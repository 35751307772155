define("crm/controllers/organizations/organization/listings/sales/taxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.[]", function () {
      return [{
        "title": "Date",
        "propertyName": "Date",
        "component": "umt-date",
        "className": "column-date"
      }, {
        "title": "Entity",
        "propertyName": "EntityName"
      }, {
        "title": "TaxId",
        "propertyName": "CompanyTaxID",
        "className": "column-vat"
      }, {
        "title": "Document",
        "propertyName": "Document",
        "className": "column-contact",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "title": "TaxableAmount",
        "propertyName": "TaxableAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "TaxPercentage",
        "propertyName": "TaxPercentage",
        "className": "column-sex"
      }, {
        "title": "TaxAmount",
        "propertyName": "TaxAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});