define("crm/helpers/u-num-round2", ["exports", "universe-gui/helpers/u-num-round2"], function (_exports, _uNumRound) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uNumRound.default;
    }
  });
});