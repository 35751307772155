define("crm/components/purchase-order-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      duplicatePurchaseOrder: function duplicatePurchaseOrder() {
        this.sendAction("openPurchaseOrder", this.get("record"));
      },
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "purchase-order",
          action: action,
          model: this.get("record")
        });
      },
      edit: function edit() {
        this.sendAction("transitionToRoute", "organizations.organization." + this.get("record._internalModel.modelName") + "s." + this.get("record._internalModel.modelName") + ".edit", this.get("record.id"));
      },
      send: function send() {
        if (confirm(this.get('intl').t("purchaseOrders.send.title"))) {
          this.set("record.status", 1);
          this.get("record").save();
        }
      },
      done: function done() {
        if (confirm(this.get('intl').t("purchaseOrders.done.title"))) {
          this.set("record.status", 3);
          this.get("record").save();
        }
      },
      receive: function receive() {
        this.sendAction("receivePurchaseOrder", this.get("record"));
      },
      delete: function _delete() {
        var self = this;

        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord().catch(function (e) {
            return self.get("es").handle(e);
          });
        }
      }
    }
  });

  _exports.default = _default;
});