define("crm/models/subject", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    invoices: _emberData.default.hasMany('invoice'),
    orders: _emberData.default.hasMany('order'),
    quotations: _emberData.default.hasMany('quotation'),
    memos: _emberData.default.hasMany('memo'),
    receipts: _emberData.default.hasMany('receipt'),
    payments: _emberData.default.hasMany('payment')
  });

  _exports.default = _default;
});