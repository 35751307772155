define("crm/models/organization-bmd", ["exports", "crm/models/organization"], function (_exports, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organization.default.extend();

  _exports.default = _default;
});