define("crm/routes/organizations/organization/listings/clinical/periodics", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var self = this;
      var organizationID = this.modelFor("organizations.organization").get("id");
      var begin = this.modelFor("organizations.organization.listings").createdAfter;
      var end = this.modelFor("organizations.organization.listings").createdBefore;
      return Ember.RSVP.hash({
        periodics: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/periodics/info" + "?after=" + new Date(_moment.default.utc(begin).startOf("d")).toISOString() + "&before=" + new Date(_moment.default.utc(end).endOf("d")).toISOString() + "&organization=" + organizationID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return r.periodics.map(function (l) {
          var i = 0;
          return {
            itemName: l[i++],
            itemID: l[i++],
            patientName: l[i++],
            patientID: l[i++],
            customerName: l[i++],
            customerID: l[i++],
            nextDate: l[i++],
            doneDate: l[i++]
          };
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("columns", [{
        "propertyName": "itemName",
        "title": this.get('intl').t("periodics.periodic.type"),
        "component": "title-models-table-display",
        "path": "organizations.organization.items.item",
        "routeProperty": "itemID",
        "className": "module-sales"
      }, {
        "propertyName": "patientName",
        "title": this.get('intl').t("patients.patient.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.patients.patient",
        "routeProperty": "patientID",
        "className": "module-crm"
      }, {
        "propertyName": "customerName",
        "title": this.get('intl').t("customers.customer.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer",
        "routeProperty": "customerID",
        "className": "module-crm"
      }, {
        "propertyName": "nextDate",
        "component": "umt-date",
        "title": this.get('intl').t("periodics.periodic.nextPeriodic"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date next-periodic-date"
      }, {
        "propertyName": "doneDate",
        "component": "umt-date",
        "title": this.get('intl').t("periodics.periodic.intakeDate"),
        "className": "column-date"
      }]);
    }
  });

  _exports.default = _default;
});