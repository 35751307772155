define("crm/routes/organizations/organization/purchase-invoices/purchase-invoice/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      return Ember.RSVP.hash({
        items: this.store.query("item", {
          purchaseInvoice: model.get("id")
        }),
        itemUnits: this.store.query("item-unit", {
          purchaseInvoice: model.get("id")
        }),
        purchaseInvoiceLines: this.store.query("purchase-invoice-line", {
          purchaseInvoice: model.get("id")
        }),
        purchaseOrders: this.store.query("purchase-order", {
          purchaseInvoice: model.get("id")
        }),
        purchaseOrderLines: this.store.query("purchase-order-line", {
          purchaseInvoice: model.get("id")
        })
      }).then(function (hash) {
        model.set("purchaseOrders", hash.purchaseOrders);
      });
    }
  });

  _exports.default = _default;
});