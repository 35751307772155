define("crm/controllers/organizations/organization/group/patients/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    store: Ember.inject.service(),
    patientTypes: Ember.computed.alias("catalogs.patientType"),
    exporting: false,
    options: {
      legend: {
        display: false
      },
      responsive: true
    },
    init: function init() {
      this._super();

      this.set("after", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
      this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    patientsOverviewStatus: Ember.computed("model.totalByStatus.[]", function () {
      var _this = this;

      var self = this;
      return !this.get("model.totalByStatus") ? [] : this.get("model.totalByStatus").map(function (s) {
        return {
          type: _this.get("patientTypes").filter(function (e) {
            return e.id === s[0];
          })[0],
          quantity: s[1],
          style: Ember.String.htmlSafe("width: " + s[1] * 100 / self.get("model.total") + "%")
        };
      });
    }),
    newBySpecies: Ember.computed("model.newBySpecies.[]", function () {
      return !this.get("model.newBySpecies") ? [] : this.get("model.newBySpecies").map(function (s) {
        return {
          speciesID: s[0],
          speciesName: s[1],
          amount: s[2]
        };
      });
    }),
    activeBySpecies: Ember.computed("model.activeBySpecies.[]", function () {
      return !this.get("model.activeBySpecies") ? [] : this.get("model.activeBySpecies").map(function (s) {
        return {
          speciesID: s[0],
          speciesName: s[1],
          amount: s[2]
        };
      });
    }),
    activeByAge: Ember.computed("model.activeByAge.[]", function () {
      if (!this.get("model.activeByAge.length")) {
        return [];
      }

      return {
        labels: ["checkin", "visit", "checkout"],
        datasets: [{
          data: [this.get("model.checkinWait"), this.get("model.visitWait"), this.get("model.checkoutWait")],
          backgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"],
          hoverBackgroundColor: ["#20D8F9", "#00B8D9", "#00A8C9"]
        }]
      };
    }),
    scheduledWaitByType: Ember.computed("model.scheduledWaitByType.[]", function () {
      return this.waitByType(this.get("model.scheduledWaitByType"));
    }),
    checkinWaitByType: Ember.computed("model.checkinWaitByType.[]", function () {
      return this.waitByType(this.get("model.checkinWaitByType"));
    }),
    visitWaitByType: Ember.computed("model.visitWaitByType.[]", function () {
      return this.waitByType(this.get("model.visitWaitByType"));
    }),
    checkoutWaitByType: Ember.computed("model.checkoutWaitByType.[]", function () {
      return this.waitByType(this.get("model.checkoutWaitByType"));
    }),
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[1],
          quantity: s[2],
          duration: s[3]
        };
      });
    },
    todayMore: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") > this.get("todayStats.yesterday");
    }),
    todaySame: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return this.get("todayStats.today") === this.get("todayStats.yesterday");
    }),
    todayMoreIncreased: Ember.computed("todayStats.today", "todayStats.yesterday", function () {
      return Math.abs(this.get("todayStats.today") - this.get("todayStats.yesterday"));
    }),
    sexChartData: Ember.computed("sexStats.females", "sexStats.males", function () {
      return {
        labels: [this.get('intl').t('stats.female'), this.get('intl').t('stats.male')],
        datasets: [{
          data: [this.get("sexStats.females"), this.get("sexStats.males")],
          backgroundColor: ["#ED5565", "#6BCABA"],
          hoverBackgroundColor: ["#ED5565", "#6BCABA"]
        }]
      };
    }),
    actions: {
      patientsNew: function patientsNew() {
        this.transitionToRoute('organizations.organization.group.patients.new', {
          queryParams: {
            before: this.get("before"),
            after: this.get("after")
          }
        });
      },
      patientsActive: function patientsActive() {
        this.transitionToRoute('organizations.organization.group.patients.active', {
          queryParams: {
            before: this.get("before"),
            after: this.get("after")
          }
        });
      }
    }
  });

  _exports.default = _default;
});