define("crm/routes/organizations/organization/consultations/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var after = (params.endedAfter ? (0, _moment.default)(params.endedAfter) : (0, _moment.default)()).startOf("day").format();
      var before = (params.endedAfter ? (0, _moment.default)(params.endedBefore) : (0, _moment.default)()).endOf("day").format();
      var organizationID = this.modelFor("organizations.organization").get("id");
      return Ember.RSVP.hash({
        appointments: this.store.query("appointment", {
          organization: organizationID,
          consultationStartedAfter: after,
          consultationStartedBefore: before
        }),
        customers: this.store.query("customer", {
          organization: organizationID,
          consultationStartedAfter: after,
          consultationStartedBefore: before
        }),
        patients: this.store.query("patient", {
          organization: organizationID,
          consultationStartedAfter: after,
          consultationStartedBefore: before
        }),
        consultations: this.store.query("consultation", {
          organization: organizationID,
          endedAfter: after,
          endedBefore: before
        })
      }).then(function (hashResult) {
        return hashResult.consultations;
      });
    }
  });

  _exports.default = _default;
});