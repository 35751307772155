define("crm/controllers/organizations/organization/sms/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ["createdAfter", "createdBefore"],
    actions: {
      sendSMS: function sendSMS() {
        this.send("openModal", {
          entity: "sms",
          action: "create",
          model: {
            isSms: true
          }
        });
      },
      openDetails: function openDetails(campaign) {
        this.send("openModal", {
          entity: "sms-sent",
          action: "details",
          model: campaign
        });
      },
      updateModel: function updateModel(createdAfter, createdBefore) {
        var self = this;
        self.set("createdAfter", (0, _moment.default)(createdAfter).format('YYYY-MM-DD'));
        self.set("createdBefore", (0, _moment.default)(createdBefore).format('YYYY-MM-DD'));
      }
    }
  });

  _exports.default = _default;
});