define("crm/models/purchase-memo-item-adjustment", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    purchaseMemo: _emberData.default.belongsTo("purchase-memo"),
    itemAdjustment: _emberData.default.belongsTo("item-adjustment")
  });

  _exports.default = _default;
});