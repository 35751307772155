define("crm/components/internment/new-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    internmentTypes: Ember.computed.alias("catalogs.internmentTypes"),
    saving: false,
    typeNormal: 5,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("selectedInternmentType", this.get("internmentTypes").find(function (e) {
        return e.id === _this.get("typeNormal");
      }));
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.set("model.type", this.get("typeNormal"));

        if (!this.get("model.start")) {
          this.set("model.start", new Date());
        }
      }
    },
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    filteredItems: Ember.computed("model.organization.company.id", function () {
      return this.get("model.organization.company.id") ? this.get("store").query("item", {
        company: this.get("model.organization.company.id"),
        type: 32,
        sellable: true
      }) : null;
    }),
    disableBtnXAS: Ember.computed("model.patient.id", "model.type", "model.start", "saving", function () {
      return !this.get("model.patient.id") || !this.get("model.start") || this.get("saving");
    }),
    quotationsByPatient: Ember.computed("model.patient.id", function () {
      if (this.get("model.patient.id")) {
        return this.get("store").query("quotation", {
          patient: this.get("model.patient.id")
        });
      }
    }),
    actions: {
      create: function create() {
        var self = this;
        this.set("saving", true);

        if (this.get("selectedQuotation")) {
          this.set("model.quotation", this.get("selectedQuotation"));
        }

        if (this.get("model.consultation")) {
          var appointment = this.get("store").peekRecord("appointment", this.get("model.consultation.appointment.id"));
          appointment.set("status", 5);
          appointment.set("endConsultation", (0, _moment.default)().format());
          appointment.set("open", false);
          appointment.save();
        }

        var internment = this.get("store").createRecord("internment", this.get("model"));
        internment.save().then(function (savedInternment) {
          self.sendAction("transitionToRouteID", "organizations.organization.internments.internment", savedInternment.get("id"));
          self.close();
        }).catch(function (result) {
          self.set("saving", false);
          self.get("es").errorReturned(result);
        });
      },
      selectQuotation: function selectQuotation(quotation) {
        this.set("selectedQuotation", quotation);
      },
      setSelectedCategory: function setSelectedCategory(value) {
        this.set("selectedCategory", value);
        this.set("model.category", value);
        this.set("model.item", null);
      },
      canceled: function canceled() {
        this.close();
      },
      reset: function reset() {
        var _this2 = this;

        this.set("saving", false);
        this.set("model", {});
        this.set("date", null);
        this.set("hours", null);
        this.set("minutes", null);
        this.set("dateEnd", null);
        this.set("hoursEnd", null);
        this.set("minutesEnd", null);
        this.set("selectedCategory", null);
        this.set("selectedQuotation", null);
        this.set("selectedInternmentType", this.get("internmentTypes").find(function (e) {
          return e.id === _this2.get("typeNormal");
        }));
      },
      setSelectedInternmentType: function setSelectedInternmentType(value) {
        this.set("selectedInternmentType", value);

        if (value) {
          this.set("model.type", value.id);
        } else {
          this.set("model.type", value);
        }
      }
    }
  });

  _exports.default = _default;
});