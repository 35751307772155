define("crm/components/customer-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldString: null,
    disabled: false,
    reset: function reset() {
      this.set('searchText', "");
      this.set("customers", []);
      this.set("oldString", null);
    },
    columns: Ember.computed("model.contacts", function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.customerName"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }, {
        "propertyName": "nif",
        "title": this.get('intl').t("customers.customer.nif"),
        "className": "column-small hidden-xs",
        "searchable": true
      }];
    }),
    searching: Ember.computed("searchText", "disabled", function () {
      return this.get("searchText") && this.get("disabled");
    }),
    actions: {
      searchCustomersSelect: function searchCustomersSelect(query) {
        var self = this;
        this.set("lockButton", true);
        this.get("store").query("customer", {
          company: this.get("organization.company.id"),
          query: query,
          enable: true
        }).then(function (savedEntities) {
          self.set("customers", savedEntities);
          self.set("lockButton", false);
        }, function () {
          self.set("lockButton", false);
        });
      },
      rowSelected: function rowSelected(dataTable) {
        if (dataTable) {
          if (dataTable.get('selectedItems.length')) {
            var customer = this.get("store").findRecord("customer", dataTable.get('selectedItems')[0].get("id"));
            this.set("selectedCustomer", customer);
            this.sendAction("selected", customer);
            this.reset();
          }
        } else {
          this.sendAction("selected", {
            id: 0
          });
        }
      },
      canceled: function canceled() {
        this.reset();
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});