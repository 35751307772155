define("crm/routes/organizations/organization/purchase-invoices/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      purchaseOrder: {
        refreshModel: true
      },
      purchaseInvoice: {
        refreshModel: true
      }
    },
    model: function model() {
      return {
        organization: this.modelFor("organizations.organization")
      };
    },
    actions: {
      uploaded: function uploaded(purchaseInvoice) {
        this.transitionTo("organizations.organization.purchase-invoices.purchase-invoice.edit", purchaseInvoice.id);
      }
    }
  });

  _exports.default = _default;
});