define("crm/routes/organizations/organization/equipment-types/equipment-type/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var equipmentTypeID = this.modelFor("organizations.organization.equipment-types.equipment-type").get('id');
      return this.store.findRecord('equipment-type', equipmentTypeID);
    }
  });

  _exports.default = _default;
});