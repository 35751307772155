define("crm/components/warehouse-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    totalQuantity: null,
    init: function init() {
      this._super();

      var self = this;

      if (this.get("record.id")) {
        this.get("store").query("warehouse-item", {
          item: this.get("record.id"),
          organization: this.get("organization.id")
        }).then(function (warehouseItems) {
          if (warehouseItems.get("length")) {
            var result = 0;
            warehouseItems.forEach(function (warehouseItem) {
              result = result + Number(warehouseItem.get("quantity"));
            });
            self.set("totalQuantity", result);
          }
        });
      }
    }
  });

  _exports.default = _default;
});