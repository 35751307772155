define("crm/components/chat-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    displayMessageButtons: false,
    chatIbox: [],

    /*
     * Computed properties
     */
    isDirty: Ember.computed("chatIbox.[]", function () {
      return true;
      /* TODO  button cancel*/
    }),
    actions: {
      // Message
      sendMessage: function sendMessage() {
        var self = this;
        var chatMessage = this.get("store").createRecord("chatMessage", {
          message: this.get("value"),
          doer: this.get("currentUser.person"),
          chat: this.get("model.chat")
        });
        chatMessage.save().then(function () {
          self.set("displayMessageButtons", false);
          self.set("value", undefined);
        }).catch(function (result) {
          self.get("es").handle(result);
          self.get("store").unloadRecord(chatMessage);
        });
      },
      cancelMessage: function cancelMessage() {
        if (confirm(this.get("intl").t("intl.unsavedChanges"))) {
          this.set("displayMessageButtons", false);
          this.set("value", undefined);
        }
      },
      displayMessageButtons: function displayMessageButtons() {
        this.set("displayMessageButtons", true);
      }
    }
  });

  _exports.default = _default;
});