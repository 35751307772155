define("crm/components/compound-product-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    newItem: {
      salesUnits: []
    },
    sellable: false,
    disabledAction: false,
    changePv: false,
    changePvp: false,
    quantity: 0,
    itemItems: [],
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isEmpty(this.get("itemItems"))) {
        if (this.get("taxes.length")) {
          if (this.get("taxes.length") === 1) {
            this.set("newItem.vat", this.get("taxes").objectAt(0));
          } else {
            this.set("newItem.vat", this.get("taxes").find(function (item) {
              return item ? item.get("vat.description").includes("TN") || item.get("vat.description").includes("GEN") : null;
            }));
          }
        }
      }
    },
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    compoundProductTotal: Ember.computed("itemItems.[]", "newItem.pvp", "itemItems.@each.displayLinePrice", function () {
      var self = this;
      var total = this.get("newItem.pvp") ? Number(this.get("newItem.pvp")) : 0;

      if (this.get("itemItems.length")) {
        this.get("itemItems").forEach(function (itemItem) {
          total += self.money(itemItem.get("displayLinePrice") ? itemItem.get("displayLinePrice") : 0);
        });
      }

      return total;
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    pvChanged: Ember.observer("newItem.salePrice", "newItem.vat.vat.amount", "changePvp", function () {
      if (this.get("changePv") == true) {
        if ((this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") == 0) && this.get("newItem.salePrice")) {
          this.set("newItem.pvp", (Number(this.get("newItem.salePrice")) + this.get("newItem.salePrice") * (this.get("newItem.vat.vat.amount") / 100)).toFixed(2));
        } else {
          this.set("newItem.pvp", null);
        }
      }
    }),
    pvpChanged: Ember.observer("newItem.pvp", "newItem.vat.vat.amount", "changePv", function () {
      if (this.get("changePvp") == true) {
        if ((this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") == 0) && !Ember.isEmpty(this.get("newItem.pvp"))) {
          this.set("newItem.salePrice", (this.get("newItem.pvp") * 100 / (100 + this.get("newItem.vat.vat.amount"))).toFixed(2));
        } else {
          this.set("newItem.salePrice", null);
        }
      }
    }),
    selectedItemObserver: Ember.observer("selectedItem.id", function () {
      if (this.get("selectedItem.id")) {
        var item = this.get("selectedItem");
        this.get("store").query("item-item", {
          parent: item.get("id")
        });
        this.get("store").query("item-unit", {
          item: item.get("id")
        });
        this.get("itemItems").addObject(this.get("store").createRecord("itemItem", {
          child: item,
          quantity: 1,
          locked: false
        }));
        this.get("itemItems").forEach(function (itemItem) {
          if (itemItem.get("item.id") == item.get("id")) {
            if (itemItem.get("quantity") >= 1) {
              itemItem.set("quantity", itemItem.get("quantity") + 1);
            } else {
              itemItem.set("quantity", 1);
            }
          }
        });
      }
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setPv: function setPv() {
        this.set("changePv", true);
        this.set("changePvp", false);
      },
      setPvp: function setPvp() {
        this.set("changePv", false);
        this.set("changePvp", true);
      },
      deleteCompoundProductItem: function deleteCompoundProductItem(item) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.set("selectedItem", null);
          this.get("itemItems").removeObject(item);
        }
      },
      selectItemType: function selectItemType(value) {
        this.set("newItem.type", value);
      },
      toggleUnit: function toggleUnit(unit) {
        if (unit.active) {
          unit.set("active", false);
          this.get("newItem.salesUnits").removeObject(unit);
        } else {
          unit.set("active", true);
          this.get("newItem.salesUnits").addObject(unit);
        }
      },
      reset: function reset() {
        this.set("selectedOption", null);
        this.set("sellable", false);
        Ember.$("select").prop("selectedIndex", 0);
        this.set("disabledAction", false);
        this.set("newItem", {
          salesUnits: []
        });
        this.set("changePv", false);
        this.set("changePvp", false);
        this.set("quantity", 0);
        this.set("itemItems", []);
      },
      setBaseUnit: function setBaseUnit(unit) {
        this.set("newItem.baseUnit", unit);
      },
      setBrand: function setBrand(brand) {
        this.set("newItem.brand", brand);
      },
      setModel: function setModel(model) {
        this.set("newItem.model", model);
      },
      setTax: function setTax(Chosen) {
        this.set("newItem.vat", this.get("taxes").find(function (item) {
          return item.get("id") === Chosen;
        }));
      },
      selectItem: function selectItem() {
        this.sendAction("openModal", {
          entity: "item",
          action: "select"
        });
      },
      modalCreateItem: function modalCreateItem() {
        this.set("disabledAction", true);
        var self = this;
        this.set("newItem.organization", this.get("organization"));
        this.set("newItem.enable", true);
        this.set("newItem.sellable", true);
        this.set("newItem.displayPrice", this.get("compoundProductTotal").toFixed(2));
        var newItem = this.get("store").createRecord("item", this.get("newItem"));
        newItem.save().then(function (savedItem) {
          self.get("itemItems").forEach(function (itemItem) {
            var newitemItem = self.get("store").createRecord("item-item", {
              child: itemItem.get("child"),
              salePrice: itemItem.get("salePrice"),
              salePrice2: itemItem.get("salePrice2"),
              discount: itemItem.get("discount"),
              parent: savedItem,
              quantity: itemItem.get("quantity"),
              locked: itemItem.get("locked")
            });
            newitemItem.save();
          });
          self.get("flashMessages").success(self.get("intl").t("items.messages.createdSuccess"));
          self.close();
        }).catch(function () {
          self.get("store").unloadRecord(newItem);
          self.set("disabledAction", false);
        });
      }
    }
  });

  _exports.default = _default;
});