define("crm/components/campaign-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    control: 0,
    startDate: Ember.observer('model.started', function () {
      if (this.get('model.started')) {
        var start = (0, _moment.default)(this.get('model.started'));
        this.set('startDay', start.format('YYYY-MM-DD'));
      }
    }),
    init: function init() {
      this._super();

      this.reset();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("campaign", this.get("model.id"), {
          reload: true
        });
      }
    },
    reset: function reset() {
      this.set('model', {});
      this.set('disabledAction', false);
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$(".modal").modal('hide');
    },
    sortedSpeciesList: Ember.computed("speciesList.@each.name", function () {
      if (this.get("speciesList") && this.get("speciesList.length") > 0) {
        var self = this;
        this.get("speciesList").forEach(function (specie) {
          specie.set("name", self.get("intl").t("species." + specie.get("id")).toString());
        });
        return this.get("speciesList").sortBy("name");
      }
    }),
    actions: {
      canceled: function canceled() {
        this.reset();
      },
      setSpecies: function setSpecies(species) {
        if (false === Ember.isEmpty(species)) {
          this.set("model.species", species);
        }
      },
      create: function create() {
        this.set('disabledAction', true);
        var self = this;

        if (this.get('organization')) {
          this.set('model.organization', this.get('organization'));
        }

        if (this.get("startDay")) {
          this.set('model.started', (0, _moment.default)(this.get('startDay'), "YYYY-MM-DD").format());
        }

        this.get("model").save().then(function () {
          self.get('flashMessages').success(self.get('intl').t('brands.messages.createdSuccess'));
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function () {
          self.get('store').unloadRecord(self.get("model"));
          self.set('disabledAction', false);
        });
      }
    }
  });

  _exports.default = _default;
});