define("crm/controllers/organizations/organization/items/item/procurement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      duplicatePurchaseEntity: function duplicatePurchaseEntity(purchaseRequest) {
        this.transitionToRoute("organizations.organization.purchase-requests.new", {
          queryParams: {
            purchaseRequest: purchaseRequest.get("id")
          }
        });
      },
      transitionToRoute: function transitionToRoute(route, params) {
        this.transitionToRoute(route, params);
      },
      receivePurchaseOrder: function receivePurchaseOrder(purchaseOrder) {
        var queryParams = {
          purchaseOrder: purchaseOrder.get("id")
        };
        this.transitionToRoute("organizations.organization.purchase-invoices.new", {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});