define("crm/components/table-select-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelectedObserver: Ember.observer('isSelected', function (record) {
      if (this.get('isSelected') === true) {
        var patient = record.get('record');
        this.set('patient', patient);
        this.sendAction('patientIsSelected', patient);
      }
    })
  });

  _exports.default = _default;
});