define("crm/components/quotations-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      goToPos: function goToPos(record) {
        var queryParams = {
          quotationId: record.get("id")
        };
        this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      }
    }
  });

  _exports.default = _default;
});