define("crm/models/payment", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    billingID: _emberData.default.attr("string"),
    sourceDocs: _emberData.default.attr(),
    paymentMethod: _emberData.default.attr("string"),
    deletedReason: _emberData.default.attr("string"),
    checkNumber: _emberData.default.attr("number"),
    checkDate: _emberData.default.attr("localdate"),
    slip: _emberData.default.attr("boolean"),
    invoice: _emberData.default.belongsTo("invoice"),
    financialAccount: _emberData.default.belongsTo("financial-account"),
    lines: _emberData.default.hasMany("payment-line", {
      inverse: "order"
    })
  });

  _exports.default = _default;
});