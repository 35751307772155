define("crm/models/consumable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    pmsID: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    type: _emberData.default.belongsTo('consumable-type'),
    consumptions: _emberData.default.hasMany('consumption'),
    organization: _emberData.default.belongsTo('organization')
  });

  _exports.default = _default;
});