define("crm/models/warning-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    warningID: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    contactType: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    warningDay: _emberData.default.attr('isodate'),
    description: _emberData.default.attr('string'),
    error: _emberData.default.attr('string'),
    sendDay: _emberData.default.attr('isodate'),
    patient: _emberData.default.hasMany('patient'),
    owner: _emberData.default.hasMany('customer')
  });

  _exports.default = _default;
});