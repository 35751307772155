define("crm/controllers/organizations/organization/workers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    managers: Ember.computed("model.@each.manager", function () {
      return this.get("model").filter(function (w) {
        return w.get("manager");
      });
    }),
    undermanagers: Ember.computed("model.@each.undermanager", function () {
      return this.get("model").filter(function (w) {
        return w.get("undermanager");
      });
    }),
    others: Ember.computed("model.@each.manager", function () {
      return this.get("model").filter(function (w) {
        return !w.get("manager") && !w.get("undermanager");
      });
    }),
    columns: Ember.computed(function () {
      return [{
        "title": "",
        "propertyName": "manager",
        "component": "umt/is-manager",
        "className": "column-icon"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("workers.worker.name"),
        "component": "title-models-table-display",
        "sortPrecedence": 0
      }, {
        "propertyName": "email",
        "title": this.get('intl').t("workers.worker.email")
      }, {
        "propertyName": "role",
        "component": "show-worker-role",
        "title": this.get('intl').t("workers.worker.role"),
        "className": "column-email"
      }, {
        "component": "worker-actions",
        "className": "column-actions-2"
      }];
    }),
    actions: {
      openModalCreateWorker: function openModalCreateWorker() {
        this.send('openCreateWorker');
      },
      openEditWorkerModal: function openEditWorkerModal(WorkerToEdit) {
        this.send('openEditWorker', WorkerToEdit);
      }
    }
  });

  _exports.default = _default;
});