define("crm/components/umt-datetime3", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    datetime: Ember.computed("record", function () {
      if (this.get("record.time") && this.get("record." + this.get("column.propertyName"))) {
        var aux = (0, _moment.default)(this.get("record." + this.get("column.propertyName"))).format("YYYY-MM-DD");
        return (0, _moment.default)(aux + "T" + this.get("record.time"));
      } else {
        return this.get("record." + this.get("column.propertyName")) ? (0, _moment.default)(this.get("record." + this.get("column.propertyName"))) : null;
      }
    }),
    date: Ember.computed("datetime", function () {
      return this.get("datetime") ? this.get("datetime").format("YYYY-MM-DD") : null;
    }),
    time: Ember.computed("datetime", function () {
      return this.get("datetime") ? this.get("datetime").format("HH:mm") : null;
    })
  });

  _exports.default = _default;
});