define("crm/models/bill-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    //Obrigatorios
    consultation: _emberData.default.belongsTo('consultation'),
    product: _emberData.default.attr('number'),
    lot: _emberData.default.belongsTo('lot'),
    unitMeasure: _emberData.default.attr('number'),
    vatRateType: _emberData.default.belongsTo('vatRateType'),
    //tipo de iva
    vat: _emberData.default.attr('number'),
    //iva
    //campos obrigatórios para produtos que têm o iva a 0 para depois serem exportados para o ficheiro SAFT
    vatzeroCode: _emberData.default.attr('string'),
    vatzeroDescription: _emberData.default.attr('string'),
    totalUnits: _emberData.default.attr('number'),
    unitPrice: _emberData.default.attr('number'),
    // 4 descontos que o produto/artigo poderá ter
    discount1: _emberData.default.attr('number'),
    //%
    discount2: _emberData.default.attr('number'),
    //%
    discount3: _emberData.default.attr('number'),
    //€
    discount4: _emberData.default.attr('number'),
    //Desconto % geral
    discountReason: _emberData.default.attr('string'),
    stockOnly: _emberData.default.attr('boolean'),
    lineNumber: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),
    totalWithoutVAT: _emberData.default.attr('number'),
    reason: _emberData.default.attr('string'),
    unitPriceWithoutVAT: _emberData.default.attr('number'),
    unitPriceDiscounts: _emberData.default.attr('number'),
    //preço unitário sem iva com desconto
    totalDiscount: _emberData.default.attr('number'),
    //campo que associam aquela linha a uma linha de um documento de faturação (Faturas/Notas de crédito,..)
    generatedDocID: _emberData.default.attr('number'),
    serialCodeGeneratedDoc: _emberData.default.attr('string'),
    documentTypeCodeGeneratedDoc: _emberData.default.attr('string'),
    originID: _emberData.default.attr('number'),
    serialCodeOrigin: _emberData.default.attr('string'),
    documentTypeCodeOrigin: _emberData.default.attr('string'),
    lineNumberOrigin: _emberData.default.attr('number'),
    compositeCode: _emberData.default.attr('string')
  });

  _exports.default = _default;
});