define("crm/controllers/organizations/organization/stats/hr", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set("after", (0, _moment.default)().startOf("month").format("YYYY-MM-DD"));
      this.set("before", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    overview: Ember.computed("model.overview.[]", function () {
      return !this.get("model.overview") ? [] : this.get("model.overview").map(function (s) {
        return {
          name: s[0],
          amount: s[1]
        };
      });
    }),
    columns: Ember.computed('intl.locale', function () {
      return [{
        propertyName: "name",
        title: this.get('intl').t("suppliers.name")
      }, {
        title: this.get('intl').t("stats.salesByWorker"),
        propertyName: "amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});