define("crm/components/purchase-request-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disabledAction: false,
    recordsToDestroy: [],
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("purchase-request", this.get("model.id"));
        this.get("store").query("purchase-request-line", {
          purchaseRequest: this.get("model.id")
        });
      }
    },
    close: function close() {
      Ember.$("#modal-purchase-request-edit").modal("hide");
    },
    disabledSubmissionPOEdit: Ember.computed("model.purchaseRequestLines.@each.line", function () {
      return this.get("model.purchaseRequestLines.length") ? false : true;
    }),
    hideIconPOEdit: Ember.computed("model.purchaseRequestLines.[]", function () {
      return this.get("model.purchaseRequestLines.length") ? false : true;
    }),
    actions: {
      setSelectedItem: function setSelectedItem(item) {
        var self = this;

        if (this.get("model.purchaseRequestLines.length") > 0) {
          var exists = false;
          var pol;
          this.get("model.purchaseRequestLines").forEach(function (purchaseRequestLine) {
            pol = purchaseRequestLine;

            if (item.get("id") === purchaseRequestLine.get("item.id")) {
              exists = true;
            }
          });

          if (exists === true) {
            pol.set("quantity", Number(pol.get("quantity")) + 1);
          } else {
            self.get("model.purchaseRequestLines").addObject(self.get("store").createRecord("purchase-request-line", {
              item: item,
              quantity: 1,
              warehouse: null
            }));
          }
        } else {
          this.get("model.purchaseRequestLines").addObject(this.get("store").createRecord("purchase-request-line", {
            item: item,
            quantity: 1,
            warehouse: null
          }));
        }
      },
      canceled: function canceled() {
        this.close();

        if (this.get("model.id")) {
          this.get("store").findRecord("purchase-request", this.get("model.id"));
          this.set("model.purchaseRequestLines", this.get("store").query("purchase-request-line", {
            purchaseRequest: this.get("model.id")
          }));
        }
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledAction", false);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.get("recordsToDestroy").forEach(function (record) {
          record.destroyRecord();
        });
        this.set("model.organization", this.get("organization"));
        this.get("model").save().then(function (purchaseRequest) {
          self.get("model.purchaseRequestLines").forEach(function (purchaseRequestLine) {
            purchaseRequestLine.set("purchaseRequest", purchaseRequest);
            purchaseRequestLine.save();
          });
          self.sendAction("reloadModel");
          self.close();
          self.get("flashMessages").success(self.get("intl").t("purchaseRequest.messages.createdSuccess"));
        }).catch(function () {
          self.get("store").unloadRecord(self.get("model"));
          self.set("disabledAction", false);
        });
      },
      deletePurchaseRequestLine: function deletePurchaseRequestLine(line) {
        this.get("model.purchaseRequestLines").removeObject(line);
        this.get("recordsToDestroy").addObject(line);
      }
    }
  });

  _exports.default = _default;
});