define("crm/components/create-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    contactValidator: Ember.inject.service(),
    patient: {},
    contact: {},
    customer: {},
    saving: false,
    mobilePhone: null,
    patientName: null,
    customerName: null,
    selectedValue: "search",
    validContactNumber: Ember.computed("mobilePhone", function () {
      if (Ember.isEmpty(this.get("mobilePhone"))) {
        return true;
      }

      var contact;
      var contactType;

      if (this.get("mobilePhone")) {
        contactType = 1;
        contact = this.get("mobilePhone");
      }

      var aux = this.get("contactValidator").contactNumber(this.get("organization.country"), contact, contactType);
      return aux;
    }),
    phoneMandatory: Ember.computed("organization.id", function () {
      return this.get("organization.id") == 234;
    }),
    disableBtnAction: Ember.computed("validContactNumber", "saving", function () {
      return !this.get("validContactNumber") || this.get("saving");
    }),
    reset: function reset() {
      this.set("patient", {});
      this.set("customer", {});
      this.set("contact", {});
      this.set("sex", null);
      this.set("saving", false);
      this.set("specieSelected", null);
      this.set("customerName", null);
      this.set("patientName", null);
      this.set("mobilePhone", null);
      this.set("selectedValue", "search");
    },
    actions: {
      setSpeciesID: function setSpeciesID(speciesID) {
        if (speciesID) {
          this.set("specieSelected", this.get("store").peekRecord("species", speciesID));
        } else {
          this.set("specieSelected", null);
        }
      },
      setValue: function setValue(value) {
        this.set("selectedValue", value);
      },
      canceled: function canceled() {
        this.reset();
      },
      saveCustomerPatient: function saveCustomerPatient() {
        var self = this;
        this.set("saving", true);
        this.set("customer.organization", this.get("organization"));
        this.set("customer.name", this.get("customerName"));
        this.set("customer.inboundType", this.get("selectedInboundType.id"));
        var newCustomer = this.get("store").createRecord("customer", this.get("customer"));
        newCustomer.save().then(function (response) {
          if (self.get("mobilePhone")) {
            self.set("contact.contactType", 1);
            self.set("contact.contact", self.get("mobilePhone"));
            self.set("contact.customer", response);
            self.set("contact.name", self.get("customer.name")); // TODO build the name on serverside | person name + some sufix

            self.set("contact.preferential", true);
            self.get("store").createRecord("contact", self.get("contact")).save().catch(function (result) {
              self.set("saving", false);
              self.get("es").handle(result);
            });
          }

          var patient = {
            name: self.get("patientName"),
            owner: response,
            species: self.get("specieSelected"),
            sex: self.get("sex"),
            organization: self.get("organization")
          };
          self.get("store").createRecord("patient", patient).save().then(function (patientCreated) {
            self.reset();
            self.set("model.patient", patientCreated);
          }).catch(function (result) {
            self.set("saving", false);
            self.get("es").handle(result);
          });
        }).catch(function (result) {
          self.set("saving", false);
          self.get("es").handle(result);
        });
      }
    }
  });

  _exports.default = _default;
});