define("crm/models/weight", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    weighted: _emberData.default.attr('isodate'),
    weight: _emberData.default.attr('number'),
    patient: _emberData.default.belongsTo('patient', {
      inverse: 'weights'
    })
  });

  _exports.default = _default;
});