define("crm/models/exam", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    description: _emberData.default.attr("string"),
    doneDate: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    requestDate: _emberData.default.attr("isodate"),
    status: _emberData.default.attr("number"),
    attachment: _emberData.default.belongsTo("image"),
    consultation: _emberData.default.belongsTo("consultation"),
    externalLab: _emberData.default.belongsTo("laboratory"),
    organization: _emberData.default.belongsTo("organization"),
    patient: _emberData.default.belongsTo("patient"),
    type: _emberData.default.belongsTo("item"),
    files: _emberData.default.hasMany("file-exam"),
    lines: _emberData.default.hasMany("exam-line"),
    reports: _emberData.default.hasMany("report"),
    attachmentUrl: Ember.computed("attachment.url", function () {
      return this.get("attachment.url");
    }),
    filesSorted: Ember.computed("files.[]", function () {
      return this.get("files.length") ? this.get("files").sortBy("created").reverse() : undefined;
    })
  }); //status 0 - request
  //status 1 - done


  _exports.default = _default;
});