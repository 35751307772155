define("crm/components/comparable-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    shift: Ember.computed("createdBefore", "createdBefore2", function () {
      return this.get("createdBefore") && this.get("createdBefore2") ? (0, _moment.default)(this.get("createdBefore2")).diff(this.get("createdBefore"), this.get("windowsUnit")) : 0;
    }),
    chartDateInit: Ember.computed("createdAfter", function () {
      return this.get("createdAfter");
    }),
    window: Ember.computed("createdAfter", "createdBefore", function () {
      if (this.get("createdAfter") && this.get("createdBefore")) {
        return (0, _moment.default)(this.get("createdBefore")).diff(this.get("createdAfter"), this.get("windowsUnit"));
      } else {
        return 0;
      }
    }),
    windowsUnit: Ember.computed(function () {
      return "d";
    }),
    windowStep: Ember.computed(function () {
      return 1;
    }),
    chartOptions: {
      legend: {
        display: false
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    days: Ember.computed("chartDateInit", "window", "windowStep", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i <= this.get("window"); i++) {
        result.addObject((0, _moment.default)(result.objectAt(i - 1)).add(this.get("windowStep"), this.get("windowsUnit")).format("YYYY-MM-DD"));
      }

      return result;
    }),
    chartData2: Ember.computed("model1.[]", "model2.[]", function () {
      if (!this.get("model1") && !this.get("model2")) {
        return [];
      }

      return {
        labels: Object.keys(this.get("model1")).map(function (d) {
          return d.name;
        }),
        datasets: [{
          data: this.get("model1").map(function (d) {
            return d.value;
          })
        }, {
          data: this.get("model2").map(function (d) {
            return d.value;
          })
        }]
      };
    }),
    values1: Ember.computed("days.[]", "model1.[]", function () {
      var _this = this;

      var result = [];
      this.get("days").forEach(function (d) {
        var flag = false;

        _this.get("model1").forEach(function (x) {
          if (d === x[0]) {
            flag = true;
            result.addObject({
              name: x[0],
              value: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: d,
            value: 0
          });
        }
      });
      return result;
    }),
    values2: Ember.computed("days.[]", "model2.[]", function () {
      var _this2 = this;

      var result = [];
      this.get("days").forEach(function (d) {
        var flag = false;
        var dayShift = (0, _moment.default)(d).add(_this2.get("shift"), _this2.get("windowsUnit")).format("YYYY-MM-DD");

        _this2.get("model2").forEach(function (x) {
          if (dayShift === x[0]) {
            flag = true;
            result.addObject({
              name: dayShift,
              value: x[1]
            });
          }
        });

        if (!flag) {
          result.addObject({
            name: dayShift,
            value: 0
          });
        }
      });
      return result;
    }),
    chartData: Ember.computed("values1.[]", "values2.[]", function () {
      if (!this.get("values1") && !this.get("values2")) {
        return [];
      }

      return {
        labels: this.get("days"),
        datasets: [{
          label: "Periodo actual",
          data: this.get("values1").map(function (x) {
            return x.value;
          }),
          fillColor: "##00ff00",
          backgroundColor: "#FFC107",
          //pointBackgroundColor: "rgba(220,220,220,1)",
          pointRadius: 2,
          pointHoverRadius: 2,
          pointHoverColor: "rgba(220,220,220,1)",
          pointStyle: "circle",
          lineTension: 0
        }, {
          label: "Periodo homologo",
          data: this.get("values2").map(function (x) {
            return x.value;
          }),
          //fillColor: "#FFC107",
          //backgroundColor: "rgba(220,220,220,0.2)",
          //pointBackgroundColor: "rgba(220,220,220,1)",
          pointRadius: 2,
          pointHoverRadius: 2,
          pointHoverColor: "rgba(220,220,220,1)",
          pointStyle: "circle",
          lineTension: 0
        }]
      };
    })
  });

  _exports.default = _default;
});