define("crm/components/approvals-pending-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "startDate",
        "component": "umt-date",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("shifts.startDate")
      }, {
        "propertyName": "endDate",
        "component": "umt-date",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("shifts.endDate")
      }, {
        "propertyName": "worker.name",
        "title": this.get('intl').t("shifts.worker"),
        "routeProperty": "customer.id"
      }, {
        "propertyName": "type.name",
        "title": this.get('intl').t("absences.type.title")
      }, {
        "component": "approvals-pending-actions"
      }];
    }),
    actions: {
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    }
  });

  _exports.default = _default;
});