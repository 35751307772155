define("crm/components/approvals-pending-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      approve: function approve() {
        if (confirm(this.get('intl').t("approvals.messages.approve"))) {
          this.set("record.status", 1);
          this.get("record").save();
          this.sendAction("reloadModel");
        }
      },
      reject: function reject() {
        if (confirm(this.get('intl').t("approvals.messages.reject"))) {
          this.set("record.status", 2);
          this.get("record").save();
          this.sendAction("reloadModel");
        }
      }
    }
  });

  _exports.default = _default;
});