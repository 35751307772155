define("crm/components/purchase-invoice-status-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    title: Ember.computed("model.status", function () {
      var result = "";

      switch (this.get("model.status")) {
        case 0:
          result = this.get("intl").t("purchaseInvoices.purchaseInvoice.status.saved");
          break;

        case 1:
          result = this.get("intl").t("purchaseInvoices.purchaseInvoice.status.issued");
          break;

        case 4:
          result = this.get("intl").t("invoices.invoice.error");
          break;

        case 8:
          result = this.get("intl").t("invoices.invoice.annuled");
          break;

        default:
          break;
      }

      return result;
    })
  });

  _exports.default = _default;
});