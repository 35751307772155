define("crm/components/items-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ["items-list"],
    isBlocked: false,
    selectedItemType: null,
    canDoItem: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    itemsToShowFiltered: Ember.computed("itemsToShow.[]", function () {
      return this.get("itemsToShow") ? this.get("itemsToShow").sortBy("name") : [];
    }),
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    hideTop: Ember.computed("selectedItemType.id", "searchValue", "searchedValue", function () {
      return this.get("selectedItemType.id") || this.get("searchValue") || this.get("searchedValue");
    }),
    itemTypesToShow: Ember.computed("itemTypes.@each.parent", "selectedItemType.id", "searchedValue", function () {
      var self = this;

      if (this.get("itemTypes.length")) {
        if (this.get("selectedItemType.id")) {
          return this.get("itemTypes").filterBy("parent.id", self.get("selectedItemType.id")).sortBy("name");
        } else {
          if (this.get("searchedValue")) {
            return this.get("itemTypes").filter(function (itemType) {
              return itemType.get("name").toLowerCase().indexOf(self.get("searchedValue").toLowerCase()) != -1;
            }).sortBy("name");
          } else {
            return this.get("itemTypesRoot").sortBy("name");
          }
        }
      } else {
        return [];
      }
    }),
    reset: function reset() {
      this.set("selectedItemType", null);
      this.set("searchValue", null);
      this.set("searchedValue", null);
      this.set("itemsToShow", null);
    },
    actions: {
      search: function search() {
        var _this = this;

        var self = this;

        if (this.get("searchValue.length")) {
          this.set("lockBtn", true);
          this.set("searchedValue", this.get("searchValue"));
          this.set("searchValue", null);
          this.set("selectedItemType", null);

          if (this.get("stockableItems")) {
            this.get("store").query("item", {
              company: self.get("organization.company.id"),
              query: this.get("searchedValue"),
              stockable: true
            }).then(function (r) {
              return _this.set("itemsToShow", r);
            }).catch(function (e) {
              return self.get("es").handle(e);
            }).finally(function () {
              return self.set("lockBtn", false);
            });
          } else {
            return this.get("store").query("item", {
              company: self.get("organization.company.id"),
              query: this.get("searchedValue"),
              sellable: true
            }).then(function (r) {
              return _this.set("itemsToShow", r);
            }).catch(function (e) {
              return self.get("es").handle(e);
            }).finally(function () {
              return self.set("lockBtn", false);
            });
          }
        } else {
          this.set("itemsToShow", []);
        }
      },
      selectItemType: function selectItemType(itemType) {
        var self = this;
        this.set("lockBtn", true);

        if (itemType && itemType.get("id")) {
          this.set("selectedItemType", itemType);

          if (this.get("stockableItems")) {
            return this.get("store").query("item", {
              company: this.get("organization.company.id"),
              type: itemType.get("id"),
              stockable: true
            }).then(function (r) {
              return self.set("itemsToShow", r);
            }).catch(function (e) {
              return self.get("es").handle(e);
            }).finally(function () {
              return self.set("lockBtn", false);
            });
          } else {
            return this.get("store").query("item", {
              company: this.get("organization.company.id"),
              type: itemType.get("id"),
              sellable: true
            }).then(function (r) {
              return self.set("itemsToShow", r);
            }).catch(function (e) {
              return self.get("es").handle(e);
            }).finally(function () {
              return self.set("lockBtn", false);
            });
          }
        } else {
          return [];
        }
      },
      backToStart: function backToStart() {
        this.reset();
      },
      clear: function clear() {
        this.set("searchValue", null);
      },
      openCameraReader: function openCameraReader() {
        this.sendAction("openCameraReader");
      },
      sendAddLine: function sendAddLine(item) {
        if (this.get("canDoItem")) {
          this.sendAction("addLine", item);
        }
      }
    }
  });

  _exports.default = _default;
});