define("crm/controllers/organizations/organization/templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    section: [],
    fields: [{}],
    actions: {
      createTemplate: function createTemplate() {
        this.set("disabledSubmission", true);
        var self = this;
        this.get("model").save().then(function () {
          self.get("section").save().then(function (templateSection) {
            self.get("fields").forEach(function (field) {
              if (field.id) {
                field.save().catch(function (result) {
                  self.get("es").errorReturned(result);
                  self.set("disabledSubmission", false);
                });
              } else {
                field.templateSection = templateSection;
                field.organization = self.get("model.organization");
                field.enable = true;
                field.code = field.name;
                field.orderNumber = self.get("fields").filterBy("orderNumber").get("lastObject.orderNumber") + 1;
                self.get("store").createRecord("template-section-field", field).save().catch(function (result) {
                  self.get("es").errorReturned(result);
                  self.set("disabledSubmission", false);
                });
              }
            });
            self.set("section", []);
            self.set("fields", [{}]);
            self.set("disabledSubmission", false);
            self.transitionToRoute('organizations.organization.templates');
          });
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledSubmission", false);
        });
      },
      addTemplateField: function addTemplateField() {
        this.get('fields').addObject({});
      },
      removeTemplateField: function removeTemplateField(templateField) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          templateField.destroyRecord();
          this.get('fields').removeObject(templateField);
        }
      },
      canceled: function canceled() {
        this.transitionToRoute('organizations.organization.templates');
      }
    }
  });

  _exports.default = _default;
});