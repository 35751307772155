define("crm/controllers/organizations/organization/purchase-requests/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    exporting: false,
    columns: Ember.computed("intl", "model.@each.status", function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("purchaseRequests.name"),
        "component": "title-models-table-display",
        "className": "column-name"
      }, {
        "propertyName": "date",
        "component": "umt-date",
        "title": this.get('intl').t("purchaseRequests.date")
      }, {
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("purchaseRequests.supplier"),
        "className": "column-name"
      }, {
        "component": "purchase-request-actions",
        "className": "column-price column-actions-3"
      }];
    }),
    actions: {
      transitionToRoute: function transitionToRoute(route, params) {
        this.transitionToRoute(route, params);
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      export: function _export() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseRequests/xlsx?organization=" + self.get("organization.id") + "&issued=true",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "purchaseRequests-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});