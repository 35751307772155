define("crm/routes/organizations/organization/stats/consultations/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      var begin = this.modelFor("organizations.organization.stats").createdAfter;
      var end = this.modelFor("organizations.organization.stats").createdBefore;

      if (begin && end) {
        if ((0, _moment.default)(begin).diff(end, "days") <= this.get("dateLimit")) {
          var self = this;
          return Ember.$.get({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/clinical/consultations/overview" + "?organization=" + organizationID + "&after=" + new Date(_moment.default.utc(begin).startOf("d")).toISOString() + "&before=" + new Date(_moment.default.utc(end).endOf("d")).toISOString(),
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          });
        }
      }

      return [];
    }
  });

  _exports.default = _default;
});