define("crm/routes/organizations/organization/group/sales", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var companyID = organization.get("company.id");
      return Ember.RSVP.hash({
        organization: organization,
        invoices: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/overview?company=" + companyID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        memos: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/memos/overview?company=" + companyID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        itemTypes: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/overview/itemTypes?company=" + companyID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        invoiceCreators: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/overview/invoiceCreators?company=" + companyID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        })
      });
    }
  });

  _exports.default = _default;
});