define("crm/routes/organizations/organization/group/suppliers/supplier/current-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var self = this;
      var supplier = this.modelFor("organizations.organization.group.suppliers.supplier");
      return Ember.RSVP.hash({
        supplier: this.store.findRecord("supplier", supplier.get("id"), {
          reload: true
        }),
        supplierPurchaseInvoices: supplier.get("id") ? self.store.query("purchase-invoice", {
          supplier: supplier.get("id"),
          open: true
        }) : null
      }).then(function (hashResult) {
        var supplier = hashResult.supplier;

        if (!Ember.isEmpty(hashResult.supplierPurchaseInvoices)) {
          Ember.set(supplier, "supplierPurchaseInvoices", hashResult.supplierPurchaseInvoices);
        }

        return supplier;
      });
    }
  });

  _exports.default = _default;
});