define("crm/components/room-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        this.get("store").findRecord("room", this.get("model.id"));
      }
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      editRoom: function editRoom() {
        this.set("disabledSubmission", true);
        var self = this;
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.set("disabledSubmission", false);
        this.set("model", {});
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});