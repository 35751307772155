define("crm/components/financial-account-new", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    newFinancialAccount: {
      financialAccountType: 1
    },
    disabledSubmissionFinancialNew: false,
    countries: _uConstants.default.countries,
    currencies: _uConstants.default.currencies,
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      selectCountry: function selectCountry(country) {
        this.set('country', country.code);
      },
      selectCurrency: function selectCurrency(currency) {
        this.set('currency', currency.code);
      },
      showBankAccount: function showBankAccount() {
        this.set('showBankAccount', true);
        this.set('show', true);
      },
      show: function show() {
        this.set('showBankAccount', false);
        this.set('show', true);
      },
      create: function create() {
        this.set('disabledSubmissionFinancialNew', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newFinancialAccount.organization', this.get('organization'));
        }

        var newFinancialAccount = this.get('store').createRecord('financialAccount', this.get('newFinancialAccount'));
        newFinancialAccount.save().then(function () {
          self.get('flashMessages').success(self.get('intl').t('financialAccounts.messages.createdSuccess'));
          self.close();
        }).catch(function () {
          self.get('store').unloadRecord(newFinancialAccount);
          self.set('disabledSubmissionFinancialNew', false);
        });
      },
      reset: function reset() {
        this.set('newFinancialAccount', {});
        this.set('newFinancialAccount.financialAccountType', 1);
        this.set('disabledSubmissionFinancialNew', false);
        Ember.$('select').prop('selectedIndex', 0);
      }
    }
  });

  _exports.default = _default;
});