define("crm/routes/organizations/organization/invites/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query('invite', {
        organization: this.modelFor("organizations.organization").get("id")
      }).then(function (r) {
        return r.sortBy("name");
      });
    }
  });

  _exports.default = _default;
});