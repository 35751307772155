define("crm/components/procurement/purchase-entity-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    entityLink: Ember.computed("model.id", function () {
      return "organizations.organization." + this.get("model.modelName") + "s." + this.get("model.modelName");
    })
  });

  _exports.default = _default;
});