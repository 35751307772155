define("crm/controllers/invited", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    disableBtn: false,
    isDirty: true,
    actions: {
      setAvatar: function setAvatar(url) {
        this.set("model.avatar", url);
      },
      create: function create() {
        var self = this;
        this.set("disableBtn", true);
        var newWorker = {
          organization: self.get("model.organization"),
          role: self.get("model.role"),
          name: self.get("model.name"),
          email: self.get("model.email"),
          address: self.get("model.password"),
          enable: true
        };
        var worker = self.get("store").createRecord("worker", newWorker);
        worker.save().then(function () {
          self.get("session").authenticate("authenticator:oauth2", self.get("model.email"), self.get("model.password")).then(function () {
            self.transitionToRoute("organizations");
            self.set("disableBtn", false);
          });
        }).catch(function () {
          self.get("store").unloadRecord(worker);
          self.set("disableBtn", false);
        });
      },
      cancel: function cancel() {}
    }
  });

  _exports.default = _default;
});