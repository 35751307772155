define("crm/routes/organizations", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    model: function model() {
      var self = this;
      return Ember.RSVP.hash({
        //employees: this.store.findAll("employee"), // All for my user
        companies: this.store.query("company", {
          enable: true
        }),
        orgs: this.store.query("organization", {
          enable: true
        }),

        /* Needs to be where to be queried after login */
        breeds: this.store.findAll("breed"),
        diets: this.store.findAll("diet"),
        equipments: this.store.findAll("equipment"),
        equipmentTypes: this.store.findAll("equipment-type"),
        features: this.store.findAll("feature"),
        countries: this.store.findAll("country"),
        //intermediary: this.store.findAll("intermediary"),
        itemTypes: this.store.findAll("item-type"),
        noteTypes: this.store.findAll("note-type"),
        outcomeTypes: this.store.findAll("outcome-type"),
        settings: this.store.findAll("setting"),
        species: this.store.findAll("species"),
        tails: this.store.findAll("tail"),
        taskTypes: this.store.findAll("task-type"),
        vats: this.store.findAll("vat")
      }).then(function (hash) {
        var companies = [];
        var lasthilight = true;
        var orgs = hash.orgs.map(function (x) {
          return x;
        }).sort(function (a, b) {
          return a.get("companyID") - b.get("companyID") || a.get("id") - b.get("id");
        });
        orgs.forEach(function (o) {
          if (!companies.includes(o.get("company.id"))) {
            companies.push(o.get("company.id"));
            lasthilight = !lasthilight;
          }

          o.set("companyCardinal", companies.indexOf(o.get("company.id")));
          o.set("TableHilight", lasthilight);
        });
        hash.species.forEach(function (e) {
          return e.set("name", self.get("intl").t("species." + e.get("id")));
        });
        hash.breeds.forEach(function (e) {
          return e.set("name", self.get("intl").t("breeds." + e.get("id")));
        });
        return orgs;
      });
    }
    /*
       afterModel(model) {
          if(model.map(x => x.get("id")).includes("204") && this.get("currentUser.id") != "33") {
             $.idleTimer(10 * 60 * 1000);
             $(document).bind("idle.idleTimer",  () => {
                this.get("sessionAccount").logout();
             });
          } else {
             //$.idleTimer(30 * 60 * 1000);
          }
       }
    */

  });

  _exports.default = _default;
});