define("crm/controllers/organizations/organization/appointments/week", ["exports", "moment", "crm/controllers/organizations/organization/appointments"], function (_exports, _moment, _appointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointments.default.extend({
    queryParams: ["oldAppointment", "intakeDate", "day", "patient"],
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    currentData: Ember.computed.alias("sessionAccount.currentData"),
    displayChartDateInitInput: false,
    endingHour: 23,
    startHour: 8,
    step: 30,
    showAside: false,
    init: function init() {
      this._super();

      if (Ember.$("body").width() < 768 || Ember.$("body").width() > 1600) {
        this.set("showAside", true);
      }

      this.set("currentHour", (0, _moment.default)().format("HH"));
    },
    canDoCalendar: Ember.computed("can", function () {
      return this.get("can").can("do calendar");
    }),
    nobody: Ember.computed("intl", function () {
      return {
        id: 0,
        name: this.get("intl").t("tasks.withoutWorker"),
        appointable: true,
        employee: {
          user: {
            avatarUrl: "https://avataaars.io/?avatarStyle=Transparent&topType=undefined&accessoriesType=undefined&hatColor=undefined&clotheType=undefined&clotheColor=undefined&eyeType=undefined&eyebrowType=undefined&mouthType=undefined&skinColor="
          }
        }
      };
    }),
    hourWindow: Ember.computed("step", "startHour", "endingHour", function () {
      return (1 + this.get("endingHour") - this.get("startHour")) * 60 / this.get("step");
    }),
    hours: Ember.computed("startHour", "hourWindow", "step", function () {
      var result = [];
      result.addObject((0, _moment.default)().hours(this.get("startHour")).startOf("hour"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        result.addObject((0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), "minutes"));
      }

      return result;
    }),
    innerStyle: Ember.computed(function () {
      return Ember.String.htmlSafe(this.get("resize") ? "position: absolute; top: " + this.get("appointment.top") + "px; height:" + this.get("appointment.height") + "px" : "height: 30px");
    }),
    days: Ember.computed("model.startOfWeek", function () {
      var result = [];
      var startOfWeek = this.get("model.startOfWeek");

      for (var i = 0; i < 7; i++) {
        result.addObject((0, _moment.default)(startOfWeek).startOf("h").add(i, "d").format("YYYY-MM-DD"));
      }

      return result;
    }),
    isToday: Ember.computed("model.startOfWeek", function () {
      var now = (0, _moment.default)();
      return this.get("model.startOfWeek").isBefore(now) && (0, _moment.default)(this.get("model.startOfWeek")).add(7, "days").isAfter(now);
    }),
    columns: Ember.computed("hours.[]", "days.[]", "filteredTasks.@each.taskDate", "activeAppointments.@each.start", function () {
      var result = {};
      var self = this;
      this.get("hours").forEach(function (hour) {
        result[hour.format("HH:mm")] = {};
        self.get("days").forEach(function (day) {
          result[hour.format("HH:mm")][day] = [];
        });
      });
      this.get("activeAppointments").filter(function (appointment) {
        return appointment.get("scheduled");
      }).forEach(function (appointment) {
        if (appointment.get("patient") && Ember.isEmpty(appointment.get("patient.deathdate"))) {
          var minutes = (0, _moment.default)(appointment.get("start")).minutes();
          var adjustedHour = minutes < self.get("step") ? (0, _moment.default)(appointment.get("start")).startOf("hour") : (0, _moment.default)(appointment.get("start")).startOf("hour").add(self.get("step"), "minutes");
          var hour = (0, _moment.default)(adjustedHour).format("HH:mm");
          var day = (0, _moment.default)(adjustedHour).format("YYYY-MM-DD");

          if (result[hour] && result[hour][day]) {
            result[hour][day] = result[hour][day].addObject(appointment);
          }
        }
      });

      if (this.get("filteredTasks")) {
        this.get("filteredTasks").forEach(function (task) {
          task.set("isTask", true);
          /* Time */

          var fullDate = (0, _moment.default)(task.get("taskDate"));
          fullDate.hours(task.get("taskTime").split(":")[0]);
          fullDate.minutes(task.get("taskTime").split(":")[1].split(":")[0]);
          var adjustedHour;
          var minutes = fullDate.minutes();

          if (minutes < self.get("step")) {
            adjustedHour = fullDate.startOf("hour");
          } else {
            adjustedHour = fullDate.startOf("hour").add(self.get("step"), "minutes");
          }

          var hour = (0, _moment.default)(adjustedHour).format("HH:mm");
          var day = (0, _moment.default)(adjustedHour).format("YYYY-MM-DD");

          if (result[hour] && result[hour][day]) {
            result[hour][day] = result[hour][day].addObject(task);
          }
        });
      }

      return result;
    }),
    absentWeek: Ember.computed("model.absences.[]", function () {
      return this.get("model.absences.length") ? this.get("model.absences").filter(function (a) {
        return (0, _moment.default)().endOf("week").isSameOrAfter(a.get("startDate")) && (0, _moment.default)().startOf("week").isSameOrBefore(a.get("endDate"));
      }) : [];
    }),
    shiftWeek: Ember.computed("model.shifts.[]", function () {
      return this.get("model.shifts.length") ? this.get("model.shifts").filter(function (a) {
        return (0, _moment.default)().endOf("week").isSameOrAfter(a.get("startDate")) && (0, _moment.default)().startOf("week").isSameOrBefore(a.get("endDate"));
      }) : [];
    }),
    workersInShift: Ember.computed("shiftWeek", function () {
      return this.get("shiftWeek").uniqBy('worker.id').map(function (s) {
        return s.get("worker");
      }).sortBy("name");
    }),
    otherWeek: Ember.computed("model.workers.[]", "absentWeek.[]", "shiftWeek.[]", function () {
      var _this = this;

      return this.get("model.workers").filterBy("appointable", true).filter(function (w) {
        return !_this.get("absentWeek").map(function (a) {
          return a.get("worker");
        }).find(function (w2) {
          return w2.get("id") === Ember.get(w, "id");
        });
      }).filter(function (w) {
        return !_this.get("shiftWeek").map(function (a) {
          return a.get("worker");
        }).find(function (w2) {
          return w2.get("id") === Ember.get(w, "id");
        });
      }).sortBy("name");
    }),
    activeAppointments: Ember.computed("model.@each.worker", "activeWorkers.[]", function () {
      var workerIDs = this.get("activeWorkers").map(function (w) {
        return Ember.get(w, "id");
      });
      return this.get("model").filter(function (a) {
        return workerIDs.includes(a.get("worker.id")) || workerIDs.includes(0) && !a.get("worker.id");
      });
    }),
    activeWorkers: Ember.computed("model.workers.@each.active", "model.workerFilters.@each.worker", "currentData.hiddenobody", function () {
      var activeWorkers = [];

      if (!this.get("currentData.hiddenobody")) {
        activeWorkers.push(this.get("nobody"));
      }

      if (this.get("model.workerFilters.length")) {
        this.get("model.workerFilters").forEach(function (workerFilter) {
          workerFilter.set("worker.active", true);
          activeWorkers.addObject(workerFilter.get("worker"));
        });
      }

      return activeWorkers.sortBy("id");
    }),
    rootVisitItemTypes: Ember.computed("model.itemTypes.[]", function () {
      if (this.get("model.itemTypes.length")) {
        return this.get("model.itemTypes").filter(function (a) {
          return a.get("isVisit") === true && !a.get("parent.id");
        }).sortBy("name");
      }
    }),
    activeItemTypes: Ember.computed("model.itemTypeFilters.[]", function () {
      var activeItemTypes = [];

      if (this.get("model.itemTypeFilters.length")) {
        this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
          itemTypeFilter.set("itemType.active", true);
          activeItemTypes.addObject(itemTypeFilter.get("itemType"));
        });
      }

      return activeItemTypes;
    }),
    tasksFiltered: Ember.computed("tasks.@each.name", function () {
      return Ember.isEmpty(this.get("tasks")) ? undefined : this.get("tasks").filter(function (t) {
        return !t.get("name").match("Contactar:.+");
      });
    }),
    actions: {
      hideNobody: function hideNobody() {
        this.get('session').set("data.user_" + this.get("currentUser.id"), {
          hiddenobody: !this.get("currentData.hiddenobody")
        });
      },
      setChartDateInit: function setChartDateInit() {
        this.set("displayChartDateInitInput", false);
      },
      setDisplayChartDateInitInput: function setDisplayChartDateInitInput() {
        this.set("displayChartDateInitInput", true);
      },
      previous: function previous() {
        this.set("day", (0, _moment.default)(this.get("day")).subtract(7, "days").format("YYYY-MM-DD"));
      },
      next: function next() {
        this.set("day", (0, _moment.default)(this.get("day")).add(7, "days").format("YYYY-MM-DD"));
      },
      openCreate: function openCreate() {
        this.send("openModal", {
          entity: "task",
          action: "create"
        });
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        appointment.set("comingFrom", "week");
        this.send("openModalAppointmentDetails", appointment);
      },
      openAppointmentCreate: function openAppointmentCreate(hour, workerID) {
        var start = hour ? hour : (0, _moment.default)().format();
        var newAppointment = {
          "organization": this.get('model.organization'),
          "start": start
        };

        if (this.get('patient')) {
          newAppointment.patient = this.get('store').peekRecord('patient', this.get('patient'));
        }

        if (this.get('worker')) {
          newAppointment.worker = this.get('store').peekRecord('worker', this.get('worker'));
        }

        if (this.get('oldAppointment')) {
          newAppointment.oldAppointment = this.get('store').peekRecord('appointment', this.get('oldAppointment'));
        }

        if (this.get('customer')) {
          newAppointment.customer = this.get('store').peekRecord('customer', this.get('customer'));
        }

        if (workerID) {
          newAppointment.worker = this.get('store').peekRecord('worker', workerID);
        }

        this.send('openModal', {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      openModalCreateAppointment: function openModalCreateAppointment(date) {
        var newAppointment = {
          "organization": this.get("model.organization"),
          "start": date
        };
        this.send("openModal", {
          entity: "appointment",
          action: "create",
          model: newAppointment
        });
      },
      created: function created(appointment) {
        if ((0, _moment.default)(appointment.get("start")).startOf("day").isSameOrAfter((0, _moment.default)(this.get("model.startOfWeek")), "day") && (0, _moment.default)(appointment.get("start")).startOf("day").isSameOrBefore((0, _moment.default)(this.get("model.startOfWeek").add(6, "days")), "day")) {
          this.sendAction("createdAppointment");
        }
      },
      newSchedulingAppointment: function newSchedulingAppointment(appointment) {
        if (appointment) {
          this.set("appointment", appointment);
          Ember.$("#openCreateAppointment").modal();
        }
      },
      openDoTaskModal: function openDoTaskModal(record) {
        this.set("recordToDo", record);
        Ember.$("#openToDoTask").modal();
      },
      checkIn: function checkIn(appointment) {
        appointment.set("arrivalTime", (0, _moment.default)().format());
        appointment.set("status", 3);
        appointment.save();
      },
      closeModalAppointmentDetail: function closeModalAppointmentDetail() {
        Ember.$("#openAppointmentDetails").modal("hide");
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      deleteAppointment: function deleteAppointment(appointment) {
        if (false == Ember.isEmpty(appointment)) {
          appointment.destroyRecord();
        }
      },
      createdConsultation: function createdConsultation(consultationID) {
        this.transitionToRoute("organizations.organization.consultations.consultation", consultationID);
      },
      today: function today() {
        this.set("day", (0, _moment.default)().startOf("isoWeek").format("YYYY-MM-DD"));
      },
      transitionToCustom: function transitionToCustom() {
        this.transitionToRoute('organizations.organization.appointments.custom', {
          queryParams: {
            day: this.get("day")
          }
        });
      },
      toggleWorkerFilter: function toggleWorkerFilter(worker) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.workerFilters.length")) {
          this.get("model.workerFilters").forEach(function (workerFilter) {
            if (workerFilter.get("worker.id") == worker.get("id")) {
              exists = true;
              filterToDelete = workerFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.workerFilters").removeObject(filterToDelete);
            filterToDelete.set("worker.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-worker", {
            owner: this.get("model.me"),
            worker: worker
          }).save().then(function (calendarFilterWorker) {
            self.get("model.workerFilters").addObject(calendarFilterWorker._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleWorkerFilterAll: function toggleWorkerFilterAll() {
        var self = this;

        if (this.get("model.workerFilters.length")) {
          this.get("model.workerFilters").forEach(function (workerFilter) {
            self.get("otherWeek").forEach(function (worker) {
              if (workerFilter.get("worker.id") == Ember.get(worker, "id")) {
                worker.set("itExists", true);
              }
            });
          });
        }

        this.get("otherWeek").forEach(function (worker) {
          if (!worker.get("itExists")) {
            self.get("store").createRecord("calendar-filter-worker", {
              owner: self.get("model.me"),
              worker: worker
            }).save().then(function (calendarFilterWorker) {
              self.get("model.workerFilters").addObject(calendarFilterWorker._internalModel);
            }).catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        });
      },
      untoggleWorkerFilterAll: function untoggleWorkerFilterAll() {
        var self = this;
        this.get("model.workerFilters").forEach(function (workerFilter) {
          workerFilter.set("worker.active", false);
          workerFilter.destroyRecord().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        });
        this.get("model.workerFilters").forEach(function () {
          self.get("model.workerFilters").removeObject(self.get("model.workerFilters.firstObject"));
        });
      },
      toggleItemTypeFilter: function toggleItemTypeFilter(itemType) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.itemTypeFilters.length")) {
          this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
            if (itemTypeFilter.get("itemType.id") == itemType.get("id")) {
              exists = true;
              filterToDelete = itemTypeFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.itemTypeFilters").removeObject(filterToDelete);
            filterToDelete.set("itemType.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-itemType", {
            owner: this.get("model.me"),
            itemType: itemType
          }).save().then(function (calendarFilterItemType) {
            self.get("model.itemTypeFilters").addObject(calendarFilterItemType._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      }
    }
  });

  _exports.default = _default;
});