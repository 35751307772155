define("crm/models/delivery", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    billingID: _emberData.default.attr("string"),
    paymentDate: _emberData.default.attr("isodate"),
    paymentMethod: _emberData.default.attr("string"),
    documentType: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    accountingTaxId: _emberData.default.attr("string"),
    accountingName: _emberData.default.attr("string"),
    accountingAddress: _emberData.default.attr("string"),
    deletedReason: _emberData.default.attr("string"),
    slip: _emberData.default.attr("boolean"),
    checkNumber: _emberData.default.attr("number"),
    checkDate: _emberData.default.attr("localdate"),
    aTDocCodeID: _emberData.default.attr("string"),
    deleted: _emberData.default.attr("isodate"),
    logisticsPartyName: _emberData.default.attr("string"),
    logisticsPartyAddress: _emberData.default.attr("string"),
    logisticsPartyTaxId: _emberData.default.attr("string"),
    lines: _emberData.default.hasMany("delivery-line", {
      inverse: "delivery"
    })
  });

  _exports.default = _default;
});