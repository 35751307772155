define("crm/models/setting", ["exports", "ember-data", "crm/models/abstract-field-type"], function (_exports, _emberData, _abstractFieldType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractFieldType.default.extend({
    feature: _emberData.default.belongsTo("feature")
  });

  _exports.default = _default;
});