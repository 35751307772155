define("crm/routes/organizations/organization/listings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return {
        createdAfter: params.createdAfter,
        createdBefore: params.createdBefore,
        organization: this.modelFor("organizations.organization")
      };
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("listings.title")
      });
    }
  });

  _exports.default = _default;
});