define("crm/components/item-select-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        Ember.$('#modal-item-select').modal('hide');
      },
      reset: function reset() {
        this.set("searchText", "");
        this.set("items", []);
        this.set("oldString", null);
      }
    }
  });

  _exports.default = _default;
});