define("crm/components/saft-actions", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    exporting: false,
    canDoSaft: Ember.computed("can", function () {
      //return this.get("can").can("do sale"); TODOJD
      return true;
    }),
    actions: {
      openDetails: function openDetails() {
        this.sendAction("openSaftDetails", this.get("record"));
      },
      delete: function _delete() {
        if (confirm(this.get("intl").t("intl.remove"))) {
          this.get("record").destroyRecord();
          this.sendAction("reloadModel");
        }
      },
      export: function _export() {
        var self = this;
        var modelName = this.get("record").constructor.modelName;
        var id = this.get("record.id");
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/legalDeclarations/" + self.get("record.id") + "/xml",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id).then(function (savedLegalDeclaration) {
              self.set("exporting", false);
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedLegalDeclaration.get("name") + ".xml";
              link.click();
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});