define("crm/controllers/organizations/organization/listings/crm/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("intl.locale", function () {
      return [{
        "propertyName": "contactType",
        "component": "contact-display",
        "title": "",
        "className": "column-icon"
      }, {
        "propertyName": "contact",
        "title": this.get("intl").t("customers.customer.contacts.contact")
      }, {
        "propertyName": "preferential",
        "component": "choice-display",
        "title": this.get("intl").t("customers.customer.contacts.preferential"),
        "className": "column-date text-center"
      }, {
        "propertyName": "code",
        "title": "ID " + this.get("intl").t("customers.customer.title"),
        "className": "column-date text-center",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("customers.customer.customerName"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer"
      }];
    })
  });

  _exports.default = _default;
});