define("crm/components/procurement/purchase-invoice-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    filteredModel: Ember.computed("model.@each.placement", function () {
      return this.get("model") ? this.get("model").sortBy("placement") : null;
    }),
    itemsTotalPriceNoVat: Ember.computed("model.@each.linePrice", function () {
      var self = this;
      var finalPrice = 0;

      if (this.get("model.length")) {
        this.get("model").forEach(function (item) {
          if (Ember.get(item, "linePrice")) {
            finalPrice += self.money(Ember.get(item, "linePrice"));
          }
        });
        return this.money(finalPrice);
      } else {
        return null;
      }
    }),
    itemsTotalPriceWVat: Ember.computed("model.@each.linePriceWithVat", function () {
      var self = this;
      var finalPrice = 0;

      if (this.get("model.length")) {
        this.get("model").forEach(function (item) {
          if (Ember.get(item, "linePriceWithVat")) {
            finalPrice += self.money(Ember.get(item, "linePriceWithVat"));
          }
        });
        return this.money(finalPrice);
      } else {
        return null;
      }
    }),
    actions: {
      deleteLine: function deleteLine(line) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          if (line.id) {
            line.destroyRecord();
          } else {
            this.get("model").removeObject(line);
          }
        }
      }
    }
  });

  _exports.default = _default;
});