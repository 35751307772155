define("crm/components/saft-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      var result = [{
        "propertyName": "documentDate",
        "component": "umt-date",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("saft.documentDate")
      }, {
        "propertyName": "startDate",
        "component": "umt-date",
        "title": this.get('intl').t("saft.startDate"),
        "className": "text-strong"
      }, {
        "propertyName": "endDate",
        "component": "umt-date",
        "title": this.get('intl').t("saft.endDate")
      }, {
        "component": "saft-actions"
      }];
      return result;
    }),
    actions: {
      openSaftDetails: function openSaftDetails(model) {
        this.sendAction("openSaftDetails", model);
      },
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    }
  });

  _exports.default = _default;
});