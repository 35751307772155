define("crm/routes/organizations/organization/jasmin", ["exports", "crm/pu/routes/god"], function (_exports, _god) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _god.default.extend({
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly('sessionAccount.currentUser'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var organizationID = model.get("id");
      var companyID = model.get("company.id");
      this.store.findRecord("organization-jasmin", organizationID).then(function (r) {
        return controller.set("organizationJasmin", r);
      });
      this.store.findRecord("organization-pet", organizationID).then(function (r) {
        return controller.set("organizationPet", r);
      });
      this.store.findRecord("organization-invisible", organizationID).then(function (r) {
        return controller.set("organizationInvisible", r);
      });
      this.store.findRecord("company-jasmin", companyID).then(function (r) {
        return controller.set("companyJasmin", r);
      });
      controller.set("features", this.store.peekAll("feature"));
      controller.set("equipments", this.store.peekAll("equipment"));
      controller.set("equipmentOrganizations", this.store.query("equipment-organization", {
        organization: organizationID
      }));
    }
  });

  _exports.default = _default;
});