define("crm/models/compound-product-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    quantity: _emberData.default.attr('number'),
    linePrice: _emberData.default.attr('number'),
    compoundProduct: _emberData.default.belongsTo('compound-product', {
      inverse: 'compoundProductItems'
    }),
    item: _emberData.default.belongsTo('item')
  });

  _exports.default = _default;
});