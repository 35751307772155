define("crm/routes/organizations/organization/items/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      type: {
        refreshModel: true
      },
      sellable: {
        refreshModel: true
      },
      stocable: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = {
        company: this.modelFor("organizations.organization").get("company.id")
      };

      if (params.type) {
        if (params.type > 0) {
          query["type"] = params.type;
        } else {
          query["query"] = "%";
        }

        return this.get("store").query("item", query).then(function (r) {
          return r ? r.sortBy("name") : null;
        });
      }

      return null;
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("itemTypes.title")
      });
    }
  });

  _exports.default = _default;
});