define("crm/models/stock-movement", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    product: _emberData.default.belongsTo('product'),
    movementDate: _emberData.default.attr('isodate'),
    documentType: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('number'),
    movementNumber: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    stockLocation: _emberData.default.belongsTo('stockLocation'),
    lot: _emberData.default.belongsTo('lot'),
    stockMovementType: _emberData.default.belongsTo('stockMovementType'),
    barcode: _emberData.default.attr('string'),
    unitMeasure: _emberData.default.attr('number'),
    units: _emberData.default.attr('number'),
    totalAmount: _emberData.default.attr('number'),
    //quantidade total negativa ou positiva
    value: _emberData.default.attr('number'),
    averagePrice: _emberData.default.attr('number'),
    //preço médio do produto
    costPrice: _emberData.default.attr('number') //preço de custo do produto

  });

  _exports.default = _default;
});