define("crm/components/periodic-actions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: "table-actions",
    classNameBindings: ["rowColor"],
    canDoPeriodic: Ember.computed("can", function () {
      //return this.get("can").can("do crm"); TODOJD
      return true;
    }),
    rowColor: Ember.computed("record.nextPeriodic", function () {
      if ((0, _moment.default)().isAfter(this.get("record.nextPeriodic"), "day")) {
        return "periodic-late";
      } else if ((0, _moment.default)().isSame(this.get("record.intakeDate"), "day")) {
        return "periodic-today";
      } else {
        return "";
      }
    }),
    scheduledOrDone: Ember.computed("record.scheduled", "record.done", function () {
      return this.get("record.scheduled") || this.get("record.done");
    }),
    actions: {
      details: function details() {
        this.sendAction('callModal', {
          entity: "periodic",
          action: "details",
          model: this.get("record")
        });
      },
      giveNext: function giveNext() {
        var model = {};
        var time = (0, _moment.default)(this.get("record.nextPeriodic")).diff(this.get("record.intakeDate"), 'hours');
        model.intakeDate = new Date();
        model.nextPeriodic = new Date(_moment.default.utc(this.get('model.nextPeriodic')).startOf("day").add(time, "hours").format("YYYY-MM-DD"));
        model.patient = this.get("record.patient");
        model.type = this.get("record.type");
        this.sendAction('doNextPeriodic', model);
      },
      edit: function edit() {
        this.sendAction('callModal', {
          entity: "periodic",
          action: "edit",
          model: this.get("record")
        });
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
        }
      },
      gotoAppointmentNew: function gotoAppointmentNew() {
        var queryParams = {
          day: (0, _moment.default)(this.get("record.nextPeriodic")).format("YYYY-MM-DD"),
          patient: this.get("record.patient.id"),
          customer: this.get("record.patient.owner.id"),
          type: this.get("record.type.id")
        };
        this.sendAction("gotoAppointmentNew", queryParams);
      }
    }
  });

  _exports.default = _default;
});