define("crm/components/periodic-appointment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    statusName: Ember.computed("record.scheduled", "record.type", function () {
      if (this.get("record.scheduled")) {
        return this.get('intl').t('periodics.status.scheduled');
      }
    }),
    status: Ember.computed("record.scheduled", "record.type", function () {
      if (this.get("record.scheduled")) {
        return this.get('intl').t('icons.check');
      }
    })
  });

  _exports.default = _default;
});