define("crm/controllers/organizations/organization/laboratories/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableBtn: false,
    isDirty: true,
    newLaboratory: {},
    actions: {
      create: function create() {
        var self = this;
        var laboratory = this.store.createRecord('laboratory', this.get('newLaboratory'));
        laboratory.save().then(function (result) {
          self.send("created", result.get('id'));
        }).catch(function () {
          self.set('disableBtn', false);
          self.get('store').unloadRecord(laboratory);
        });
      },
      cancel: function cancel() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.get('model').rollbackAttributes();
          this.send("canceled");
        }
      }
    }
  });

  _exports.default = _default;
});