define("crm/models/customer-worker", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    customer: _emberData.default.belongsTo('customer', {
      inverse: 'customerWorkers'
    }),
    worker: _emberData.default.belongsTo('worker')
  });

  _exports.default = _default;
});