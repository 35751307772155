define("crm/routes/organizations/organization/appointments/custom", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: {
      day: {
        refreshModel: true
      },
      oldAppointment: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var day = transition.queryParams.day ? transition.queryParams.day : (0, _moment.default)().format("YYYY-MM-DD");

      if (!(0, _moment.default)(day).isValid()) {
        return undefined;
      }

      var s = (0, _moment.default)(day).startOf("day").format();
      var e = (0, _moment.default)(day).endOf("day").format();
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      var self = this;
      var me = this.get("currentWorker");

      if (params.customer && !this.store.peekRecord("customer", params.customer)) {
        this.store.findRecord("customer", params.customer);
      }

      if (params.patient && !this.store.peekRecord("patient", params.patient)) {
        this.store.findRecord("patient", params.patient);
      }

      return Ember.RSVP.hash({
        appointments: self.store.query("appointment", {
          organization: organizationID,
          startedAfter: s,
          startedBefore: e
        }),
        calendarFiltersWorkers: self.store.query("calendar-filter-worker", {
          owner: me.get("id")
        }),
        calendarFiltersRooms: self.store.query("calendar-filter-room", {
          owner: me.get("id")
        }),
        itemTypeFilters: self.store.query("calendar-filter-item-type", {
          owner: me.get("id")
        }),
        patients: self.store.query("patient", {
          organization: organizationID,
          appointmentStartedAfter: s,
          appointmentStartedBefore: e
        }),
        customers: self.store.query("customer", {
          organization: organizationID,
          appointmentStartedAfter: s,
          appointmentStartedBefore: e
        }),
        addresses: self.store.query("address", {
          organization: organizationID,
          appointmentStartedAfter: s,
          appointmentStartedBefore: e
        }),
        shifts: !organization.get("shiftsFeature") ? undefined : self.store.query("shift", {
          organization: organizationID,
          startDate: s,
          endDate: e
        }),
        absences: !organization.get("shiftsFeature") ? undefined : self.store.query("absence", {
          organization: organizationID,
          startDate: s,
          endDate: e
        }),
        oldAppointment: transition.queryParams.oldAppointment ? self.store.peekRecord("appointment", transition.queryParams.oldAppointment) : null
      }).then(function (hashResult) {
        var model = hashResult.appointments;

        if (organization.get("shiftsFeature")) {
          Ember.set(model, "shifts", hashResult.shifts);
          Ember.set(model, "absences", hashResult.absences);
        }

        Ember.set(model, "day", day);
        Ember.set(model, "patientid", transition.queryParams.patientid);
        Ember.set(model, "organization", organization);
        Ember.set(model, "workers", organization.get("workers"));
        Ember.set(model, "calendarFiltersWorkers", hashResult.calendarFiltersWorkers);
        Ember.set(model, "calendarFiltersRooms", hashResult.calendarFiltersRooms);
        Ember.set(model, "itemTypeFilters", hashResult.itemTypeFilters);
        Ember.set(model, "oldAppointment", hashResult.oldAppointment);
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      /*
       * Tasks
       */


      var self = this;
      var day = model.get("day");
      var organizationID = model.get("organization.id");
      var startedAfter = (0, _moment.default)(day).startOf("day").format();
      var startedBefore = (0, _moment.default)(day).endOf("day").format();
      Ember.RSVP.hash({
        old: self.store.query("task", {
          organization: organizationID,
          startedBefore: (0, _moment.default)(model.get("day")).add(-1, "d").endOf("d").format(),
          status: 1,
          me: true
        }),
        today: self.store.query("task", {
          organization: organizationID,
          startedAfter: startedAfter,
          startedBefore: startedBefore,
          me: true
        })
      }).then(function (hashResult) {
        var dayTasks = [];
        var hourTasks = [];

        if (hashResult.old) {
          hashResult.old.forEach(function (task) {
            dayTasks.push(task);
          });
        }

        if (hashResult.today) {
          hashResult.today.forEach(function (task) {
            if (task.get("taskTime") && task.get("taskTime") !== "00:00:00") {
              hourTasks.push(task);
            } else {
              dayTasks.push(task);
            }
          });
        }

        controller.set("tasks", dayTasks);
        controller.set("filteredTasks", hourTasks);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("customer", null);
        controller.set("patient", null);
        controller.set("worker", null);
        controller.set("intakeDate", null);
        controller.set("day", (0, _moment.default)().format());
      }
    },
    actions: {
      createdConsultation: function createdConsultation(id) {
        this.transitionTo("organizations.organization.consultations.consultation", id);
      }
    }
  });

  _exports.default = _default;
});