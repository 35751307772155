define("crm/controllers/organizations/organization/workers/worker/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    actions: {
      delete: function _delete(workerID) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          var workerDelete = this.get('store').peekRecord('worker', workerID);
          workerDelete.destroyRecord().then(function () {
            self.transitionToRoute('organizations.organization.workers');
          });
        }
      },
      openEditWorker: function openEditWorker() {
        this.send('openEditWorkerModal', this.get('model'));
      }
    }
  });

  _exports.default = _default;
});