define("crm/controllers/organizations/organization/purchase-orders/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ["purchaseOrder"],

    /* for used request highlight */
    usedRequests: Ember.computed("model.lines.[]", "model.lines.@each.purchaseRequestLine", function () {
      return Ember.isEmpty(this.get("model.lines")) ? [] : _toConsumableArray(new Set(this.get("model.lines").filter(function (l) {
        return l.purchaseRequestLine && l.purchaseRequestLine.get;
      }).map(function (l) {
        return Ember.get(l, "purchaseRequestLine.purchaseRequest.id");
      })));
    }),
    updateLines: function updateLines(savedPurchaseOrder) {
      var self = this;
      this.get("model.lines").forEach(function (line) {
        if (Ember.get(line, "id")) {
          if (line.get("warehouse.id") !== self.get("model.warehouse.id")) {
            line.set("warehouse", self.get("model.warehouse"));
          }

          if (line.get("hasDirtyAttributes")) {
            line.save().then(function () {
              self.get("model.lines").removeObject(line);
            }, function (error) {
              self.set("creating", false);
              self.get("es").errorReturned(error);
            });
          }
        } else {
          line.purchaseOrder = savedPurchaseOrder; // Because: self.get("model") has no id yet

          line.warehouse = self.get("model.warehouse");
          self.get("store").createRecord("purchase-order-line", line).save().then(function () {
            self.get("model.lines").removeObject(line);
          }, function (error) {
            self.set("creating", false);
            self.get("es").errorReturned(error);
          });
        }
      });
    },

    /*
        USED TO LIST ALL ITEMS UNDER MIN STOCK
     supplierItems: computed("model.organization.company.id", "model.supplier.id", function() {
       if(this.get("model.organization.company.id") && this.get("model.supplier.id")) {
          return this.get("store").query("item", { company: this.get("model.organization.company.id"), supplier: this.get("model.supplier.id") });
       } else {
          return [];
       }
    }),
     items: computed("model.shortageItems.[]", "supplierItems.@each.name", "model.warehouse.name", function() {
       var result = [];
       var self = this;
        if(this.get("model.warehouse.name") && this.get("supplierItems") && this.get("model.shortageItems")) {
          this.get("supplierItems").forEach(i => {
             self.get("model.shortageItems").forEach(s => {
                if(s.Item == i.get("id") && s.Warehouse.toUpperCase() === self.get("model.warehouse.name").toUpperCase()) {
                   result.push({
                      name: i.get("name"),
                      type: i.get("type"),
                      baseUnit: {name: s.Stock + " (" + s.MinStock + ")"},
                      item: i,
                      quantity: s.MinStock - s.Stock
                   });
                }
             });
          });
       }
        return result;
    }),
     */
    openPurchaseRequests: Ember.computed("model.supplier.id", function () {
      return this.get("model.supplier.id") ? this.get("store").query("purchase-request", {
        open: "true",
        supplier: this.get("model.supplier.id")
      }, {
        reload: true
      }) : [];
    }),
    multiOrganization: Ember.computed("openPurchaseRequests.[]", function () {
      var _this = this;

      if (!this.get("openPurchaseRequests.length")) {
        return null;
      }

      return this.get("openPurchaseRequests").filter(function (o) {
        return o.get("organization.id") !== _this.get("model.organization.id");
      }).length;
    }),
    cannotSave: Ember.computed("creating", "model.lines.[]", "model.name", "model.supplier", "model.warehouse", function () {
      if (this.get("creating")) {
        return this.get("intl").t("crud.save");
      } else if (!this.get("model.warehouse")) {
        return this.get("intl").t("warehouses.warehouse.title");
      } else if (!this.get("model.supplier")) {
        return this.get("intl").t("suppliers.supplier.title");
      } else if (!this.get("model.lines.length")) {
        return this.get("intl").t("purchaseInvoices.invalid.noLines");
      } else {
        return null;
      }
    }),
    cannotIssue: Ember.computed("cannotSave", "model.lines.@each.quantity", function () {
      if (this.get("cannotSave")) {
        return this.get("cannotSave");
      } else {
        var error;
        this.get("model.lines").forEach(function (l) {
          if (!Ember.get(l, "quantity")) {
            error = Ember.get(l, "item.name");
          }
        });

        if (error) {
          return error;
        }

        return null;
      }
    }),
    linesPerID: Ember.computed("model.lines.[]", function () {
      if (this.get("model.lines")) {
        return this.get("model.lines").mapBy("item.id");
      } else {
        return [];
      }
    }),
    payableAmount: Ember.computed("model.lines.[]", "model.lines.@each.quantity", function () {
      var result = 0;

      if (this.get("model.lines")) {
        this.get("model.lines").forEach(function (line) {
          if (Ember.get(line, "lastPrice") && Ember.get(line, "quantity")) {
            result += Ember.get(line, "quantity") * Ember.get(line, "lastPrice");
          }
        });
      }

      return result;
    }),
    reset: function reset() {
      this.set("purchaseOrder", null);
    },
    actions: {
      duplicatePurchaseOrder: function duplicatePurchaseOrder(purchaseOrder) {
        var self = this;
        Ember.RSVP.hash({
          lines: this.store.query("purchase-order-line", {
            purchaseOrder: purchaseOrder.get("id")
          }),
          items: this.store.query("item", {
            purchaseOrder: purchaseOrder.get("id")
          })
        }).then(function (res) {
          var lines = res.lines;

          if (!Ember.isEmpty(lines)) {
            lines.sortBy("name").forEach(function (purchaseOrderLine) {
              self.send("addLine", purchaseOrderLine.get("item"), purchaseOrderLine.get("quantity"));
            });
          }
        }, function (reason) {
          return self.get("es").handle(reason);
        });
      },
      addPurchaseRequest: function addPurchaseRequest(openPurchaseRequest) {
        var self = this;
        openPurchaseRequest.set("active", true);
        this.get("store").query("purchase-request-line", {
          purchaseRequest: openPurchaseRequest.get("id"),
          open: "true"
        }, {
          reload: true
        }).then(function (lines) {
          if (!Ember.isEmpty(lines) && lines.get("length") > 0) {
            lines.sortBy("lineGroupTree").forEach(function (purchaseRequestLine) {
              self.get("store").findRecord("item", purchaseRequestLine.get("item").get("id"), {
                reload: true
              }).then(function (item) {
                self.send("addLine", item, purchaseRequestLine.get("quantity"), purchaseRequestLine);
              });
            });
          }
        });
      },
      addLine: function addLine(item, quantity, purchaseRequestLine, line) {
        if (this.get("linesPerID").includes(item.get("id"))) {
          return;
        }

        var self = this;
        this.get("store").query("purchase-invoice-line", {
          item: item.get("id"),
          top: 1
        }).then(function (pils) {
          var newLine;

          if (line) {
            newLine = line;
          } else {
            newLine = {
              name: item.get("name"),
              item: item,
              quantity: item.get("maxStock") ? item.get("maxStock") - item.get("minStock") + quantity : quantity
            };
          }

          if (!Ember.isEmpty(pils)) {
            Ember.set(newLine, "lastPrice", pils.get("firstObject.unitPrice"));
          }

          if (!Ember.isEmpty(purchaseRequestLine)) {
            Ember.set(newLine, "purchaseRequestLine", purchaseRequestLine);
          }

          if (!self.get("model.lines")) {
            self.set("model.lines", []);
          }

          self.get("model.lines").addObject(newLine);
        });
      },
      deleteLine: function deleteLine(line) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("model.lines").removeObject(line);

          if (Ember.get(line, "id")) {
            line.destroyRecord();
          }
        }
      },
      save: function save() {
        var self = this;
        this.set("creating", true);

        if (!this.get("model.status")) {
          this.set("model.status", 0);
        }

        this.set("model.payableAmount", self.get("payableAmount"));
        this.get("store").createRecord("purchase-order", this.get("model")).save().then(function (savedPurchaseOrder) {
          self.updateLines(savedPurchaseOrder);
          self.set("creating", false);
          self.transitionToRoute("organizations.organization.purchase-orders.purchase-order", savedPurchaseOrder.get("id"));
        }, function (result) {
          self.set("creating", false);
          self.get("es").errorReturned(result);
        });
      },
      update: function update() {
        var self = this;
        this.set("creating", true);
        this.set("model.payableAmount", this.get("payableAmount"));

        if (this.get("model.hasDirtyAttributes")) {
          this.get("model").save().then(function () {
            self.updateLines(self.get("model"));
            self.set("creating", false);
            self.transitionToRoute("organizations.organization.purchase-orders.purchase-order", self.get("model.id"));
          }, function (error) {
            self.set("creating", false);
            self.get("es").errorReturned(error);
          });
        } else {
          self.updateLines(self.get("model"));
          self.set("creating", false);
          self.transitionToRoute("organizations.organization.purchase-orders.purchase-order", self.get("model.id"));
        }
      },
      issue: function issue() {
        this.set("model.date", (0, _moment.default)());
        this.set("model.status", 3);
        this.get("model.id") ? this.send("update") : this.send("save");
      }
    }
  });

  _exports.default = _default;
});