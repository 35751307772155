define("crm/components/campaigns-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("createdAfter", (0, _moment.default)().startOf('month').format('YYYY-MM-DD'));
      this.set("createdBefore", (0, _moment.default)().endOf('month').format('YYYY-MM-DD'));
    },
    minCreatedAfter: Ember.computed("createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdBefore"))) {
        return (0, _moment.default)(this.get("createdBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxCreatedBefore: Ember.computed("createdAfter", function () {
      if (!Ember.isEmpty(this.get("createdAfter"))) {
        return (0, _moment.default)(this.get("createdAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    filtersObserver: Ember.observer("createdAfter", "createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdAfter")) && !Ember.isEmpty(this.get("createdBefore")) && (0, _moment.default)(this.get("createdAfter")) > (0, _moment.default)("1970-01-01")) {
        this.sendAction("updateModel", this.get("createdAfter"), this.get("createdBefore"));
      }
    }),
    actions: {
      previous: function previous() {
        if (this.get("createdAfter") === (0, _moment.default)(this.get("createdAfter")).startOf('month').format('YYYY-MM-DD')) {
          this.set("createdAfter", (0, _moment.default)(this.get("createdAfter")).subtract(1, 'M').startOf('month').format('YYYY-MM-DD'));
        } else {
          this.set("createdAfter", (0, _moment.default)(this.get("createdAfter")).subtract(1, 'M').format('YYYY-MM-DD'));
        }

        if (this.get("createdBefore") === (0, _moment.default)(this.get("createdBefore")).endOf('month').format('YYYY-MM-DD')) {
          this.set("createdBefore", (0, _moment.default)(this.get("createdBefore")).subtract(1, 'M').endOf('month').format('YYYY-MM-DD'));
        } else {
          this.set("createdBefore", (0, _moment.default)(this.get("createdBefore")).subtract(1, 'M').format('YYYY-MM-DD'));
        }
      },
      next: function next() {
        if (this.get("createdAfter") === (0, _moment.default)(this.get("createdAfter")).startOf('month').format('YYYY-MM-DD')) {
          this.set("createdAfter", (0, _moment.default)(this.get("createdAfter")).add(1, 'M').startOf('month').format('YYYY-MM-DD'));
        } else {
          this.set("createdAfter", (0, _moment.default)(this.get("createdAfter")).add(1, 'M').format('YYYY-MM-DD'));
        }

        if (this.get("createdBefore") === (0, _moment.default)(this.get("createdBefore")).endOf('month').format('YYYY-MM-DD')) {
          this.set("createdBefore", (0, _moment.default)(this.get("createdBefore")).add(1, 'M').endOf('month').format('YYYY-MM-DD'));
        } else {
          this.set("createdBefore", (0, _moment.default)(this.get("createdBefore")).add(1, 'M').format('YYYY-MM-DD'));
        }
      },
      openCreate: function openCreate(entity) {
        this.send("openModal", {
          entity: entity,
          action: "create"
        });
      },
      openDetails: function openDetails(campaign) {
        this.sendAction("openDetails", campaign);
      },
      openEdit: function openEdit(campaign) {
        this.sendAction("openEdit", campaign);
      }
    },
    columns: Ember.computed("model.[]", "model.@each.sendDate", function () {
      return [{
        "propertyName": "started",
        "component": "umt-datetime",
        "title": this.get('intl').t("campaigns.campaign.started"),
        "className": "column-date-time"
      }, {
        "propertyName": "ended",
        "component": "umt-datetime",
        "title": this.get('intl').t("campaigns.campaign.ended"),
        "className": "column-date-time"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("campaigns.campaign.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.campaigns.campaign"
      }, {
        "propertyName": "sendDate",
        "component": "umt-datetime2",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "title": this.get('intl').t("campaigns.campaign.sendDate"),
        "className": "column-date-time"
      }, {
        "component": "campaigns-list-actions",
        "className": "column-actions-4"
      }];
    })
  });

  _exports.default = _default;
});