define("crm/components/item-type-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    disabledAction: false,
    init: function init() {
      this._super();

      this.reset();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("item-type", this.get("model.id"), {
          reload: true
        });
      }
    },
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    reset: function reset() {
      this.set('model', {});
      this.set('disabledAction', false);
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$("#modal-itemType-edit").modal('hide');
    },
    actions: {
      setSelectedType: function setSelectedType(value) {
        this.set('model.parent', value);
      },
      edit: function edit() {
        this.set('disabledAction', true);
        var self = this;

        if (this.get('organization')) {
          this.set('model.organization', this.get('organization'));
        }

        this.get('model').save().then(function () {
          self.reset();
        }).catch(function () {
          self.set('disabledAction', false);
        });
      }
    }
  });

  _exports.default = _default;
});