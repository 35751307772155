define("crm/components/absences-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "startDate",
        "component": "umt-date",
        "title": this.get("intl").t("shifts.startDate"),
        "className": "column-date",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "endDate",
        "component": "umt-date",
        "title": this.get("intl").t("shifts.endDate"),
        "className": "column-date"
      }, {
        "propertyName": "worker.name",
        "title": this.get("intl").t("shifts.worker"),
        "routeProperty": "customer.id"
      }, {
        "propertyName": "type.name",
        "title": this.get("intl").t("absences.type.title")
      }, {
        "propertyName": "status",
        "title": this.get("intl").t("approvals.status.title"),
        "component": "approval-status",
        "className": "column-status-icon"
      }, {
        "component": "absences-actions",
        "className": "column-actions-2"
      }];
    }),
    actions: {
      openDetails: function openDetails(absence) {
        this.sendAction("openModal", {
          entity: "absence",
          action: "details",
          model: absence
        });
      },
      openEdit: function openEdit(absence) {
        this.sendAction("openModal", {
          entity: "absence",
          action: "edit",
          model: absence
        });
      }
    }
  });

  _exports.default = _default;
});