define("crm/routes/organizations/organization/financial-accounts/financial-account/financial-account-opens/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var financialAccountID = this.modelFor("organizations.organization.financial-accounts.financial-account").get("id");
      var createdAfter = (0, _moment.default)().startOf("day").add(-7, "d").format();
      var createdBefore = (0, _moment.default)().endOf("day").format();
      return this.get("store").query("financial-account-open", {
        financialAccount: financialAccountID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("columns", [{
        "propertyName": "created",
        "title": this.get("intl").t("documents.date"),
        "component": "umt-datetime2",
        "className": "column-date-time"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("customers.customer.name"),
        "routeName": "organizations.organization.financialAccounts.financialAccount.financialAccountOpens.financialAccountOpen",
        //"routeProperty": "id",
        "className": "column-name"
      }, {
        "propertyName": "creator.name",
        "title": this.get("intl").t("workers.worker.title"),
        "className": "column-name"
      }, {
        "propertyName": "internalNotes",
        "title": this.get("intl").t("documents.internalNotes"),
        "className": "column-name"
      }]);
    }
  }); // organizations.organization.financialAccounts.financialAccount.financialAccountOpens.financialAccountOpen
  // organizations/organization/financial-accounts/financial-account/financial-account-opens/financial-account-open


  _exports.default = _default;
});