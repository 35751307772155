define("crm/routes/organizations/organization/listings/sales/financial-accounts/financial-account/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var financialAccountID = this.modelFor("organizations.organization.listings.financial-accounts.financial-account").get("id");
      var createdBefore = (0, _moment.default)(params.openAfter).endOf("day").format();
      var createdAfter = (0, _moment.default)(params.openBefore).startOf("day").format();
      return Ember.RSVP.hash({
        invoices: this.get("store").query("invoice", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        receipts: this.get("store").query("receipt", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        payments: this.get("store").query("payment", {
          status: null,
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        financialAccountOpens: this.get("store").query("financial-account-open", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        financialAccountCloses: this.get("store").query("financial-account-close", {
          financialAccount: financialAccountID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        })
      }).then(function (r) {
        var documents = [];
        r.invoices && r.invoices.filter(function (i) {
          return i.get("documentType") !== "FA";
        }).forEach(function (d) {
          return documents.addObject(d);
        });
        r.purchaseInvoices && r.purchaseInvoices.forEach(function (d) {
          return documents.addObject(d);
        });
        r.receipts && r.receipts.forEach(function (d) {
          return documents.addObject(d);
        });
        r.payment && r.payment.forEach(function (d) {
          return documents.addObject(d);
        });
        r.financialAccountOpens && r.financialAccountOpens.forEach(function (d) {
          return documents.addObject(d);
        });
        r.financialAccountCloses && r.financialAccountCloses.forEach(function (d) {
          return documents.addObject(d);
        });
        return;
      });
    }
  });

  _exports.default = _default;
});