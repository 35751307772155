define("crm/components/invoices-list-actions", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    actions: {
      showInvoicePDF: function showInvoicePDF() {
        var self = this;
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
            request.setRequestHeader("Content-Type", "application/pdf");
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/invoices/" + self.get("record.id") + "/pdf",
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = self.get("record.customer.name");
            link.click();
          }
        });
      }
    }
  });

  _exports.default = _default;
});