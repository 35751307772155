define("crm/controllers/organizations/organization/transfer-stock/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ["startedAfter", "startedBefore"],
    startedAfter: (0, _moment.default)().startOf("day").format("YYYY-MM-DD"),
    startedBefore: (0, _moment.default)().endOf("day").format("YYYY-MM-DD"),
    canDoGroupTransferStock: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    reset: function reset() {
      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      return [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": " ",
        "className": "document-name"
      }, {
        "component": "transfer-stock-actions",
        "className": "column-actions-2"
      }];
    }),
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      showPdf: function showPdf(url) {
        var model = {
          url: url
        };
        this.send("openModal", {
          entity: "pdf-viewer",
          action: "view-pdf",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});