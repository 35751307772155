define("crm/abilities/reward", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canRead: Ember.computed('setting', function () {
      return this.get("setting");
    }),
    setting: Ember.computed('seattingCodes.[]', function () {
      return this.hasSetting("sales_invoices_rewards");
    })
  });

  _exports.default = _default;
});