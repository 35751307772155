define("crm/helpers/operator-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.operatorDisplay = operatorDisplay;
  _exports.default = void 0;

  function operatorDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 0:
          return this.get('intl').t('models.sales.operators.equal');

        case 1:
          return this.get('intl').t('models.sales.operators.different');

        case 2:
          return this.get('intl').t('models.sales.operators.greaterThanOrEqualTo');

        case 3:
          return this.get('intl').t('models.sales.operators.lessThanOrEqualTo');

        case 4:
          return this.get('intl').t('models.sales.operators.greaterThan');

        case 5:
          return this.get('intl').t('models.sales.operators.lessThan');

        case 6:
          return this.get('intl').t('models.sales.logicalOperators.and');

        case 7:
          return this.get('intl').t('models.sales.logicalOperators.or');

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});