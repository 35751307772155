define("crm/models/special-price", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    product: _emberData.default.attr('number'),
    operator1: _emberData.default.attr('number'),
    operator2: _emberData.default.attr('number'),
    operator3: _emberData.default.attr('number'),
    quantity1: _emberData.default.attr('number'),
    quantity2: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number')
  });

  _exports.default = _default;
});