define("crm/models/organization-setting-boolean", ["exports", "ember-data", "crm/models/organization-setting"], function (_exports, _emberData, _organizationSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organizationSetting.default.extend({
    value: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});