define("crm/routes/organizations/organization/point-of-sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: {
      customerId: {
        refreshModel: true
      },
      shoppingCartId: {
        refreshModel: true
      },
      appointmentId: {
        refreshModel: true
      },
      patientId: {
        refreshModel: true
      },
      orderId: {
        refreshModel: true
      },
      quotationId: {
        refreshModel: true
      },
      internmentId: {
        refreshModel: true
      },
      invoiceId: {
        refreshModel: true
      },
      tabQuotations: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do sale')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model(params) {
      return {
        customerID: params.customerId ? params.customerId : null,
        invoiceId: params.invoiceId ? params.invoiceId : null,
        shoppingCartID: params.shoppingCartId ? params.shoppingCartId : null,
        appointmentID: params.appointmentId ? params.appointmentId : null,
        patientId: params.patientId ? params.patientId : null,
        orderID: params.orderId ? params.orderId : null,
        quotationId: params.quotationId ? params.quotationId : null,
        internmentId: params.internmentId ? params.internmentId : null,
        tabQuotations: params.tabQuotations ? params.tabQuotations : null
      };
    },
    setupController: function setupController(controller, model) {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      var companyID = organization.get("company.id");
      var self = this;
      controller.set("organization", organization);
      controller.set("financialAccounts", this.store.query("financial-account", {
        organization: organizationID
      }));
      controller.set("organizationJasmins", this.store.query("organization-jasmin", {
        company: companyID
      }), {
        reload: true
      });
      controller.set("itemTypes", this.controllerFor("organizations.organization").get("itemTypes"));
      controller.set("companyOrganizations", this.get("store").query("organization", {
        company: companyID
      }));

      if (model.customerID) {
        this.store.findRecord("customer", model.customerID, {
          reload: true
        }).then(function (savedCustomer) {
          controller.set("customer", savedCustomer);
          self.store.query("contact", {
            customer: savedCustomer.get("id")
          });
        });
        this.store.query("contact", {
          customer: model.customerID
        });
      }

      if (model.orderID) {
        this.store.findRecord("order", model.orderID).then(function (savedOrder) {
          controller.set("order", savedOrder);
          var lines = [];
          savedOrder.get("lines").forEach(function (orderLine) {
            if (orderLine.get("id")) {
              self.store.findRecord("order-line", orderLine.get("id")).then(function (savedOrderLine) {
                lines.addObject({
                  "item": savedOrderLine.get("item"),
                  "name": savedOrderLine.get("name"),
                  "quantity": savedOrderLine.get("quantity"),
                  unitPrice: savedOrderLine.get("unitPrice"),
                  linePrice: savedOrderLine.get("linePrice"),
                  "discount": savedOrderLine.get("discount"),
                  "lineGroup": savedOrderLine.get("lineGroup"),
                  "lineGroupTree": savedOrderLine.get("lineGroupTree"),
                  "treeLevel": savedOrderLine.get("treeLevel"),
                  "vat": savedOrderLine.get("vat"),
                  "vatAmount": savedOrderLine.get("vatAmount"),
                  "realCreator": savedOrderLine.get("creator")
                });
              });
            }
          });
          controller.set("lines", lines);

          if (Ember.isEmpty(controller.get("customer")) && savedOrder.get("customer")) {
            controller.set("customer", savedOrder.get("customer"));
            controller.set("patient", savedOrder.get("patient"));
          }
        });
      }

      if (model.quotationId) {
        this.store.findRecord("quotation", model.quotationId, {
          reload: true
        }).then(function (savedQuotation) {
          self.store.query("quotation-line", {
            "quotation": model.quotationId
          }).then(function (savedQuotationLines) {
            var lines = [];
            savedQuotationLines.forEach(function (quotationLine) {
              self.store.findRecord("quotation-line", quotationLine.get("id")).then(function (savedQuotationLine) {
                lines.addObject({
                  item: savedQuotationLine.get("item"),
                  name: savedQuotationLine.get("name"),
                  quantity: savedQuotationLine.get("quantity"),
                  unitPrice: savedQuotationLine.get("unitPrice"),
                  linePrice: savedQuotationLine.get("linePrice"),
                  discount: savedQuotationLine.get("discount"),
                  lineGroup: savedQuotationLine.get("lineGroup"),
                  lineGroupTree: savedQuotationLine.get("lineGroupTree"),
                  treeLevel: savedQuotationLine.get("treeLevel"),
                  itemUnit: savedQuotationLine.get("itemUnit"),
                  groupOdd: Ember.isEmpty(savedQuotationLine.get("lineGroup")) ? null : savedQuotationLine.get("lineGroup") % 2,
                  vat: savedQuotationLine.get("vat"),
                  vatAmount: savedQuotationLine.get("vatAmount"),
                  realCreator: savedQuotationLine.get("creator")
                });
              });
              controller.set("lines", lines);
              controller.set("lastLineGroup", self.get("quotation.lastLineGroup"));
            });

            if (Ember.isEmpty(controller.get("customer.id")) && savedQuotation.get("customer.id")) {
              controller.set("customer", savedQuotation.get("customer"));
            }

            if (Ember.isEmpty(controller.get("patient.id")) && savedQuotation.get("patient.id")) {
              controller.set("patient", savedQuotation.get("patient"));
            }
          });
        });
      }

      if (model.internmentId) {
        self.store.query("internment-shopping-cart", {
          "internment": model.internmentId
        }).then(function (savedInternmentShoppingCarts) {
          var lines = [];
          savedInternmentShoppingCarts.forEach(function (savedShoppingCart) {
            if (savedShoppingCart.get("status") != 1 && savedShoppingCart.get("status") != 3) {
              self.store.query("shopping-cart-line", {
                "shoppingCart": savedShoppingCart.get("id")
              }).then(function (savedShoppingCartLines) {
                savedShoppingCartLines.forEach(function (shoppingCartLine) {
                  self.store.findRecord("shoppingCart-line", shoppingCartLine.get("id")).then(function (savedShoppingCartLine) {
                    lines.addObject({
                      item: savedShoppingCartLine.get("item"),
                      name: savedShoppingCartLine.get("name"),
                      quantity: savedShoppingCartLine.get("quantity"),
                      unitPrice: savedShoppingCartLine.get("unitPrice"),
                      linePrice: savedShoppingCartLine.get("linePrice"),
                      discount: savedShoppingCartLine.get("discount"),
                      lineGroup: savedShoppingCartLine.get("lineGroup"),
                      lineGroupTree: savedShoppingCartLine.get("lineGroupTree"),
                      treeLevel: savedShoppingCartLine.get("treeLevel"),
                      itemUnit: savedShoppingCartLine.get("itemUnit"),
                      groupOdd: Ember.isEmpty(savedShoppingCartLine.get("lineGroup")) ? null : savedShoppingCartLine.get("lineGroup") % 2,
                      vat: savedShoppingCartLine.get("vat"),
                      vatAmount: savedShoppingCartLine.get("vatAmount"),
                      realCreator: savedShoppingCartLine.get("creator")
                    });
                  });
                  controller.set("lines", lines);
                  controller.set("lastLineGroup", self.get("shoppingCart.lastLineGroup"));
                });

                if (Ember.isEmpty(controller.get("customer.id")) && savedShoppingCart.get("customer.id")) {
                  controller.set("customer", savedShoppingCart.get("customer"));
                }

                if (Ember.isEmpty(controller.get("patient.id")) && savedShoppingCart.get("patient.id")) {
                  controller.set("patient", savedShoppingCart.get("patient"));
                }
              });
              savedShoppingCart.set("active", true);
            }
          });
        });
      }

      if (model.invoiceId) {
        this.store.findRecord("invoice", model.invoiceId, {
          reload: true
        }).then(function (savedInvoice) {
          controller.set("invoice", savedInvoice);
          controller.set("selectedInvoice", savedInvoice);
          self.store.query("invoiceLine", {
            invoice: model.invoiceId
          }).then(function (savedInvoiceLines) {
            var lines = [];
            savedInvoiceLines.forEach(function (savedInvoiceLine) {
              lines.push({
                "item": savedInvoiceLine.get("item"),
                "name": savedInvoiceLine.get("name"),
                "quantity": savedInvoiceLine.get("quantity"),
                unitPrice: savedInvoiceLine.get("unitPrice"),
                linePrice: savedInvoiceLine.get("linePrice"),
                "discount": savedInvoiceLine.get("discount"),
                "lineGroup": savedInvoiceLine.get("lineGroup"),
                "lineGroupTree": savedInvoiceLine.get("lineGroupTree"),
                "treeLevel": savedInvoiceLine.get("treeLevel"),
                "vat": savedInvoiceLine.get("vat"),
                "vatAmount": savedInvoiceLine.get("vatAmount")
              });
            });
            controller.set("lines", lines);
          });

          if (!controller.get("customer.id") && savedInvoice.get("customer.id")) {
            controller.set("customer", savedInvoice.get("customer"));
            controller.set("patient", savedInvoice.get("patient"));
          }
        });
      }

      if (model.shoppingCartID) {
        self.store.findRecord("shoppingCart", model.shoppingCartID).then(function (foundShoppingCart) {
          if (foundShoppingCart.get("status") !== 1) {
            controller.set("shoppingCart", foundShoppingCart);
            controller.set("shoppingCart.active", true);
            self.store.query("shopping-cart-line", {
              "shoppingCart": model.shoppingCartID
            }).then(function (savedShoppingCartLines) {
              var lines = []; //var lastGroupFound = 0;

              savedShoppingCartLines.forEach(function (savedShoppingCartLine) {
                /*
                if(lastGroupFound !== savedShoppingCartLine.get("lineGroup")) {
                   self.store.findRecord("item", savedShoppingCartLine.get("item.id"));
                   lastGroupFound = savedShoppingCartLine.get("lineGroup");
                }
                */
                lines.addObject({
                  item: savedShoppingCartLine.get("item"),
                  name: savedShoppingCartLine.get("name"),
                  quantity: savedShoppingCartLine.get("quantity"),
                  unitPrice: savedShoppingCartLine.get("unitPrice"),
                  linePrice: savedShoppingCartLine.get("linePrice"),
                  discount: savedShoppingCartLine.get("discount"),
                  lineGroup: savedShoppingCartLine.get("lineGroup"),
                  lineGroupTree: savedShoppingCartLine.get("lineGroupTree"),
                  treeLevel: savedShoppingCartLine.get("treeLevel"),
                  itemUnit: savedShoppingCartLine.get("itemUnit"),
                  groupOdd: savedShoppingCartLine.get("lineGroup") % 2,
                  vat: savedShoppingCartLine.get("vat"),
                  vatAmount: savedShoppingCartLine.get("vatAmount"),
                  realCreator: savedShoppingCartLine.get("creator")
                });
                controller.set("lines", lines);
                controller.set("lastLineGroup", controller.get("shoppingCart.lastLineGroup"));
              });

              if (Ember.isEmpty(controller.get("customer")) && controller.get("shoppingCart.customer")) {
                controller.set("customer", controller.get("shoppingCart.customer"));
              }

              if (Ember.isEmpty(controller.get("patient")) && controller.get("shoppingCart.patient")) {
                controller.set("patient", controller.get("shoppingCart.patient"));
              }
            });
          } else {
            model.shoppingCartID = undefined;
          }
        });
      }

      if (model.appointmentID) {
        this.store.findRecord("appointment", model.appointmentID).then(function (savedAppointment) {
          controller.set("appointment", savedAppointment);
        });
      }

      if (model.patientId) {
        this.store.findRecord("patient", model.patientId).then(function (savedPatient) {
          controller.set("patient", savedPatient);
        });
      }

      this.store.findRecord("organization-pet", organization.get("id")).then(function (organizationPet) {
        controller.set("organizationPet", organizationPet);
        controller.set("slip", organizationPet.get("invoiceSlip"));
        controller.set("selectedWarehouse", organizationPet.get("defaultWarehouse"));
      });
      this.store.findRecord("organization-jasmin", organization.get("id")).then(function (organizationJasmin) {
        controller.set("selectedOrganizationJasmin", organizationJasmin);
        controller.set("organizationJasmin", organizationJasmin);
        controller.set("invoiceNotes", organizationJasmin.get("invoiceNotes"));
      });
      this.store.query("warehouse", {
        organization: organizationID
      }).then(function (warehouses) {
        if (warehouses != null && warehouses.get("length") > 0) {
          controller.set("warehouses", warehouses);
        }
      });

      if (model.tabQuotations) {
        controller.set("tabInvoices", "");
        controller.set("tabQuotations", "active");
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.get("shoppingCart") && controller.set("shoppingCart.active", undefined);

        if (controller.get("selectedCustomerShoppingCarts")) {
          controller.get("selectedCustomerShoppingCarts").forEach(function (s) {
            return s.set("active", undefined);
          });
        }

        controller.set("customerId", null);
        controller.set("shoppingCartId", null);
        controller.set("appointmentId", null);
        controller.set("patientId", null);
        controller.set("orderId", null);
        controller.set("quotationId", null);
        controller.set("internmentId", null);
        controller.set("invoiceId", null);
        controller.set("customer", null);
        controller.set("patient", null);
        controller.set("tabQuotations", null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.reset();
      }
    }
  });

  _exports.default = _default;
});