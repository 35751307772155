define("crm/abilities/shift", ["exports", "crm/abilities/hr"], function (_exports, _hr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hr.default.extend();

  _exports.default = _default;
});