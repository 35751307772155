define("crm/components/internment/todo-details", ["exports", "crm/components/internment/todo-components"], function (_exports, _todoComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoComponents.default.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.alias('sessionAccount.currentWorker'),
    orderedPrevTodos: Ember.computed("model.prevTodos", function () {
      return this.get("model.prevTodos").sortBy("created").reverse();
    }),
    actions: {
      updateItemClinicQuantity: function updateItemClinicQuantity(quantity) {
        this.set("model.type.clinicBillingType", quantity);
        this.get("model.type.content").save();
      }
    }
  });

  _exports.default = _default;
});