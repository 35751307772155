define("crm/components/title-models-table-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: Ember.computed("record.[]", "column.propertyName", "record.type.name", function () {
      return this.get("record") && this.get("column.propertyName") ? Ember.get(this.get("record"), this.get("column.propertyName")) : null;
    }),
    pathId: Ember.computed("record.[]", "column.routeProperty", "record.type.name", function () {
      return this.get("record") && this.get("column.routeProperty") ? Ember.get(this.get("record"), this.get("column.routeProperty")) : null;
    })
  });

  _exports.default = _default;
});