define("crm/routes/organizations/organization/sheltereds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      delete: function _delete(id) {
        var self = this;
        var shelteredToDelete = this.get('store').peekRecord('sheltered', id);
        shelteredToDelete.destroyRecord().then(function () {
          self.transitionToRoute('organizations.organization.sheltereds');
        });
      },
      enable: function enable(sheltered) {
        sheltered.set('enabled', true);
        sheltered.save();
      },
      disable: function disable(sheltered) {
        sheltered.set('enabled', false);
        sheltered.save();
      }
    }
  });

  _exports.default = _default;
});