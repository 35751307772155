define("crm/components/event-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    newCase: {},
    saving: false,
    close: function close() {
      Ember.$("#modal-event-create").modal("hide");
    },
    vetsList: Ember.computed("newCase.enterprise", function () {
      if (this.get("newCase.enterprise")) {
        return this.get("store").query("liaison", {
          enterprise: this.get("newCase.enterprise.id")
        });
      }
    }),
    disabledActionCaseNew: Ember.computed("newCase.enterprise", "newCase.liaison", "saving", "newCase.patient", function () {
      return !this.get("newCase.enterprise") || !this.get("newCase.liaison") || this.get("saving") || !this.get("newCase.patient");
    }),
    actions: {
      canceled: function canceled() {
        this.close();
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("newCase.patient", null);
        this.set("newCase.customer", null);
      },
      create: function create() {
        this.set("saving", true);
        var self = this;

        if (this.get("model")) {
          this.set("newCase.organization", this.get("model"));
        }

        this.get("store").createRecord("event", this.get("newCase")).save().then(function () {
          self.close();
          self.get("flashMessages").success(self.get("intl").t("events.messages.title"));
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set("saving", false);
        });
      },
      reset: function reset() {
        this.set("newCase", {});
        this.set("saving", false);
      }
    }
  });

  _exports.default = _default;
});