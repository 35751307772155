define("crm/controllers/organizations/organization/suppliers/supplier/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableBtn: false,
    isDirty: true,
    actions: {
      update: function update() {
        var self = this;
        this.set('disableBtn', true);
        this.get('model').save().then(function (result) {
          self.send("updated", result.id);
          self.set('disableBtn', false);
        }).catch(function () {
          self.set('disableBtn', false);
        });
      },
      cancel: function cancel() {
        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          this.get('model').rollbackAttributes();
          this.send("canceled");
        }
      }
    }
  });

  _exports.default = _default;
});