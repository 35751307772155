define("crm/models/customer-contact", ["exports", "ember-data", "crm/models/contact"], function (_exports, _emberData, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contact.default.extend({
    customer: _emberData.default.belongsTo('customer', {
      inverse: 'contacts'
    })
  });

  _exports.default = _default;
});