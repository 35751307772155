define("crm/controllers/organizations/organization/group/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoOverview: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    filters: {
      invoices: false,
      memos: false
    },
    filterOptions: Ember.computed("intl", function () {
      var result = [];

      for (var key in this.get("filters")) {
        result.push({
          name: this.get("intl").t("filters." + key),
          code: "filters." + key,
          active: this.get("filters")[key]
        });
      }

      return result;
    }),
    actions: {
      setFilter: function setFilter(filterCode) {
        this.toggleProperty(filterCode);
      }
    }
  });

  _exports.default = _default;
});