define("crm/components/documents-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterCash: true,
    filterCard: true,
    filterDirectDebit: true,
    filterTransfer: true,
    filterPaymentMethod: Ember.computed("filterCash", "filterCard", "filterDirectDebit", "filterTransfer", function () {
      var result = [];
      if (this.get("filterCash")) result.push("NUM");
      if (this.get("filterCard")) result.push("CARD");
      if (this.get("filterDirectDebit")) result.push("DEB");
      if (this.get("filterTransfer")) result.push("TRA");
      return result;
    }),
    filteredData: Ember.computed("model.[]", "filterPaymentMethod.[]", function () {
      var _this = this;

      return this.get("model") ? this.get("model").filter(function (d) {
        return _this.get("filterPaymentMethod").includes(d.get("paymentMethod"));
      }) : [];
    })
  });

  _exports.default = _default;
});