define("crm/components/contacts-list", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "preferential",
        "component": "choice-display",
        "title": "",
        "className": Ember.$("body").width() < 768 ? "column-icon text-center column-title-hidden-xs" : "column-icon text-center column-title-hidden-xs"
      }, {
        "propertyName": "contactType",
        "component": "contact-display",
        "title": "",
        "className": "column-icon"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customerFieldTypes.customerFieldType.label")
      }, {
        "propertyName": "contact",
        "title": this.get('intl').t("customers.customer.contacts.contact")
      }, {
        "component": "contacts-list-actions",
        "className": "column-actions-3"
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t("modelsTable.show") + " %@ - %@ " + this.get('intl').t("modelsTable.of") + " %@",
          "noDataToShow": this.get('intl').t("modelsTable.noDataToShow")
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "fa fa-times form-control-feedback",
        "clearAllFiltersIcon": ""
      });
    }),
    actions: {
      callModal: function callModal(arg) {
        this.get("callModal")(arg);
      }
    }
  });

  _exports.default = _default;
});