define("crm/models/purchase-request", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    code: _emberData.default.attr("number"),
    date: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    status: _emberData.default.attr("number"),
    payableAmount: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    supplier: _emberData.default.belongsTo("supplier"),
    purchaseRequestLines: _emberData.default.hasMany("purchase-request-lines"),
    isMarkedDone: Ember.computed("purchaseRequestLines.[]", function () {
      var isMarkedDone = true;
      this.get("purchaseRequestLines").forEach(function (line) {
        if (!line.get("deleted")) {
          isMarkedDone = false;
          return isMarkedDone;
        }
      });
      return isMarkedDone;
    })
  });
  /*
   * Statuses
   *
   * null - Received all
   * 0 - Under construction
   * 3 - Sent to supplier
   */


  _exports.default = _default;
});