define("crm/routes/organizations/organization/group/financial-accounts", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization");
      var companyID = model.get("company.id");
      model.set("createdAfter", params.createdAfter || (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      model.set("createdBefore", params.createdBefore || (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
      model.set("financialAccounts", this.store.query("financialAccount", {
        organization: model.get("id"),
        company: companyID
      }));
      var createdBefore = (0, _moment.default)(model.get("createdBefore")).endOf("day").format();
      var createdAfter = (0, _moment.default)(model.get("createdAfter")).startOf("day").format();
      this.get("store").query("invoice", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (invoices) {
        model.set("invoices", invoices);
      });
      this.get("store").query("memo", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (memos) {
        model.set("memos", memos);
      });
      this.get("store").query("receipt", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (receipts) {
        model.set("receipts", receipts);
      });
      this.get("store").query("purchase-invoice", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (purchaseInvoices) {
        model.set("purchaseInvoices", purchaseInvoices);
      });
      this.get("store").query("payment", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (payments) {
        model.set("payments", payments);
      });
      this.get("store").query("financial-account-open", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (financialAccountOpens) {
        model.set("financialAccountOpens", financialAccountOpens);
      });
      this.get("store").query("financial-account-close", {
        company: companyID,
        createdAfter: createdAfter,
        createdBefore: createdBefore
      }).then(function (financialAccountCloses) {
        model.set("financialAccountCloses", financialAccountCloses);
      });
      return model;
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
        controller.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});