define("crm/controllers/organizations/organization/stats/financial-accounts/financial-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryCash: true,
    queryCard: true,
    queryTransfer: true,
    queryCheck: true,
    filteredModels: Ember.computed("model.invoices.[]", "model.purchaseInvoices.[]", "model.receipts.[]", "model.payments.[]", "model.financialAccountOpens.[]", "model.financialAccountCloses.[]", "queryCash", "queryCard", "queryTransfer", "queryCheck", "model.financialAccount.id", function () {
      var result = [];
      var accountID = this.get("model.financialAccount.id");
      var activeMethods = [];

      if (this.get("queryCash")) {
        activeMethods.push("NUM");
      }

      if (this.get("queryCard")) {
        activeMethods.push("CARD");
      }

      if (this.get("queryTransfer")) {
        activeMethods.push("TRA");
      }

      if (this.get("queryCheck")) {
        activeMethods.push("CHQ");
      }

      if (activeMethods.length) {
        if (!Ember.isEmpty(this.get("model.invoices"))) {
          result.addObjects(this.get("model.invoices").filter(function (doc) {
            return doc.get("financialAccount.id") === accountID && activeMethods.includes(doc.get("paymentMethod"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.purchaseInvoices"))) {
          result.addObjects(this.get("model.purchaseInvoices").filter(function (doc) {
            return doc.get("financialAccount.id") === accountID && activeMethods.includes(doc.get("paymentMethod"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.receipts"))) {
          result.addObjects(this.get("model.receipts").filter(function (doc) {
            return doc.get("financialAccount.id") === accountID && activeMethods.includes(doc.get("paymentMethod"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.payments"))) {
          result.addObjects(this.get("model.payments").filter(function (doc) {
            return doc.get("financialAccount.id") === accountID && activeMethods.includes(doc.get("paymentMethod"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.financialAccountOpens"))) {
          result.addObjects(this.get("model.financialAccountOpens").filter(function (doc) {
            return doc.get("financialAccount.id");
          }));
        }

        if (!Ember.isEmpty(this.get("model.financialAccountCloses"))) {
          result.addObjects(this.get("model.financialAccountCloses").filter(function (doc) {
            return doc.get("financialAccount.id");
          }));
        }
      }

      return result.sortBy("created").reverse();
    }),
    actions: {
      openFinancialAccount: function openFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountOpen",
          action: "new",
          model: this.get("model.financialAccount")
        });
      },
      closeFinancialAccount: function closeFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountClose",
          action: "new",
          model: this.get("model.financialAccount")
        });
      }
    }
  });

  _exports.default = _default;
});