define("crm/routes/organizations/organization/items/item/stock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query("warehouse-item", {
        item: this.modelFor("organizations.organization.items.item").get("id"),
        organization: this.modelFor("organizations.organization").get("id")
      });
    },
    actions: {
      openTransferStockModal: function openTransferStockModal() {
        this.send("openModal", {
          entity: "item",
          action: "transferStock",
          model: this.modelFor("organizations.organization.items.item")
        });
      }
    }
  });

  _exports.default = _default;
});