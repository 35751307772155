define("crm/routes/organizations/organization/items/item/contacts", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var self = this;
      var item = this.modelFor("organizations.organization.items.item");
      var createdAfter = (0, _moment.default)(params.createdAfter).startOf("day").utc().toISOString();
      var createdBefore = (0, _moment.default)(params.createdBefore).endOf("day").utc().toISOString();
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/lists/items/contacts" + "?item=" + item.get("id") + "&createdAfter=" + createdAfter + "&createdBefore=" + createdBefore,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      }).then(function (lines) {
        return lines.map(function (i) {
          return {
            customer: i[0],
            nif: i[1],
            animal: i[2],
            chip: i[3],
            item: i[4],
            created: i[5],
            invoice: i[6],
            phone: i[7],
            email: i[8]
          };
        });
      });
    }
  });

  _exports.default = _default;
});