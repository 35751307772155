define("crm/components/odata-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [];
      this.get("model.columns") && this.get("model.columns").forEach(function (c) {
        listCollumns.addObject({
          "title": c,
          "propertyName": c,
          "className": "column-contact"
        });
      });
      return listCollumns;
    })
  });

  _exports.default = _default;
});