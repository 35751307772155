define("crm/components/item-new", ["exports", "crm/components/item-crud"], function (_exports, _itemCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemCrud.default.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    nameRegex: "^[^<>]+$",
    newItem: {
      sellable: false
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.reset();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var unitUn = this.get("units").find(function (u) {
        return u.get("symbol");
      });

      if (unitUn) {
        this.set("newItem.baseUnit", unitUn);
      }

      if (this.get("taxes.length")) {
        if (this.get("taxes.length") === 1) {
          this.set("newItem.vat", this.get("taxes").objectAt(0));
        } else {
          this.set("newItem.vat", this.get("taxes").find(function (item) {
            return item ? item.get("vat.description").includes("TN") || item.get("vat.description").includes("GEN") : null;
          }));
        }
      }
    },

    /*
     * Computed
     */
    marginTypes: Ember.computed(function () {
      var result = ["Markup", "Margem"];
      return result;
    }),
    marginDimensions: Ember.computed(function () {
      var result = ["%", this.get("intl").t("icons.currency")];
      return result;
    }),
    parentIsExam: Ember.computed("newItem.type.isExam", "parameters.[]", function () {
      return this.get("newItem.type.isExam") && this.get("parameters.length");
    }),
    parentIsDrug: Ember.observer("newItem.type.isDrug", "organizationPet.usesDecimals", function () {
      this.set("newItem.clinicBillingType", this.get("organizationPet.usesDecimals") ? null : 2);
    }),
    filteredUnits: Ember.computed("newItem.baseUnit.id", "units.[]", function () {
      var self = this;

      if (this.get("units.length")) {
        return this.get("units").filter(function (u) {
          return u.get("id") !== self.get("newItem.baseUnit.id");
        });
      } else {
        return [];
      }
    }),
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    periodTypes: Ember.computed("catalogs.periodTypes", function () {
      return this.get("catalogs.periodTypes");
    }),
    nameHasError: Ember.computed("newItem.name", "nameRegex", function () {
      return this.get("newItem.name").match(this.get("nameRegex"));
    }),
    defaultWarehouseDisplay: Ember.computed("warehouses.[]", function () {
      return true;
    }),
    requiredFieldSales: Ember.computed("selectedSalesUnitQuantity", "selectedSalesUnit", function () {
      var result = false;

      if (this.get("selectedSalesUnitQuantity") > 0) {
        if (!this.get("selectedSalesUnit")) {
          result = true;
        }
      } else if (this.get("selectedSalesUnit")) {
        result = true;
      }

      return result;
    }),
    sameDimensionBaseUnits: Ember.computed("units.@each.unitDimension", "newItem.baseUnit.unitDimension.id", function () {
      var self = this;
      return this.get("units").filter(function (u) {
        return u.get("id") != self.get("newItem.baseUnit.id") && u.get("unitDimension.id") === self.get("newItem.baseUnit.unitDimension.id");
      });
    }),

    /*
     * Observers
     */
    duplicateOfItem: Ember.observer("model.[]", "model.id", function () {
      if (this.get("model.id")) {
        this.set("newItem.name", this.get("model.name"));
        this.set("newItem.type", this.get("model.type"));
        this.set("newItem.barcode", this.get("model.barcode"));
        this.set("newItem.complementaryDescription", this.get("model.complementaryDescription"));
        this.set("newItem.sellable", this.get("model.sellable"));
        this.set("newItem.description", this.get("model.description"));
        this.set("newItem.vat", this.get("model.vat"));
        this.set("newItem.salePrice", this.get("model.salePrice"));
        this.set("newItem.pvp", this.get("model.pvp"));
        this.set("newItem.pvpDiscount", this.get("model.pvpDiscount"));
        this.set("newItem.pvpMargin", this.get("model.pvpMargin"));
        this.set("newItem.pvp2", this.get("model.pvp2"));
        this.set("newItem.pvpDiscount2", this.get("model.pvpDiscount2"));
        this.set("newItem.pvpMargin2", this.get("model.pvpMargin2"));
        this.set("newItem.pvp3", this.get("model.pvp3"));
        this.set("newItem.pvpDiscount3", this.get("model.pvpDiscount3"));
        this.set("newItem.pvpMargin3", this.get("model.pvpMargin3"));
        this.set("newItem.pvpMargin2", this.get("model.pvpMargin2"));
        this.set("newItem.pvpMargin2", this.get("model.pvpMargin2"));
        this.set("newItem.stockable", this.get("model.stockable"));
        this.set("newItem.minStock", this.get("model.minStock"));
        this.set("newItem.maxStock", this.get("model.maxStock"));

        if (this.get("model.defaultWarehouse.id")) {
          this.set("newItem.defaultWarehouse", this.get("model.defaultWarehouse"));
        } else if (!Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") > 0) {
          this.set("newItem.defaultWarehouse", this.get("warehouses").objectAt(0));
        }

        this.set("newItem.companyItemSubType", this.get("model.companyItemSubType"));
        this.set("newItem.baseUnit", this.get("model.baseUnit"));

        if (this.get("model.baseUnit")) {
          this.send("setBaseUnit", this.get("model.baseUnit"));
        }

        this.set("newItem.defaultDuration", this.get("model.defaultDuration"));
        this.set("newItem.clinicBillingType", this.get("model.clinicBillingType"));
        this.set("newItem.parameter", this.get("model.parameter"));
        this.set("newItem.purchasePrice", this.get("model.purchasePrice"));
        this.set("newItem.prescription", this.get("model.prescription"));
        var self = this;
        this.get("store").query("item-unit", {
          item: this.get("model.id")
        }).then(function (itemUnits) {
          itemUnits.forEach(function (itemUnit) {
            if (itemUnit.get("inbound")) {
              self.set("selectedPurchaseUnit", itemUnit.get("unit"));
              self.set("selectedPurchaseUnitQuantity", itemUnit.get("quantity"));
            } else if (itemUnit.get("outbound")) {
              self.set("selectedSalesUnit", itemUnit.get("unit"));
              self.set("selectedSalesUnitQuantity", itemUnit.get("quantity"));
            }
          });
        });

        if (this.get("model.marginType") || this.get("model.marginType") == 0) {
          if (this.get("model.marginType") == 0) {
            this.set("selectedMarginType", "Markup");
          } else {
            this.set("selectedMarginType", "Margem");
          }
        }

        if (this.get("model.marginType2") || this.get("model.marginType2") == 0) {
          if (this.get("model.marginType2") == 0) {
            this.set("selectedMarginType2", "Markup");
          } else {
            this.set("selectedMarginType2", "Margem");
          }
        }

        if (this.get("model.marginType3") || this.get("model.marginType3") == 0) {
          if (this.get("model.marginType3") == 0) {
            this.set("selectedMarginType3", "Markup");
          } else {
            this.set("selectedMarginType3", "Margem");
          }
        }

        if (this.get("model.marginDimension") || this.get("model.marginDimension") == 0) {
          if (this.get("model.marginDimension") == 0) {
            this.set("selectedMarginDimension", "%");
          } else {
            this.set("selectedMarginDimension", "€");
          }
        }

        if (this.get("model.marginDimension2") || this.get("model.marginDimension2") == 0) {
          if (this.get("model.marginDimension2") == 0) {
            this.set("selectedMarginDimension2", "%");
          } else {
            this.set("selectedMarginDimension2", "€");
          }
        }

        if (this.get("model.marginDimension3") || this.get("model.marginDimension3") == 0) {
          if (this.get("model.marginDimension3") == 0) {
            this.set("selectedMarginDimension3", "%");
          } else {
            this.set("selectedMarginDimension3", "€");
          }
        }
      }
    }),
    setOfPvp: Ember.observer("newItem.pvpMargin", "selectedMarginDimension", "selectedMarginType", "newItem.purchasePrice", function () {
      if (this.get("newItem.pvpMargin") && this.get("selectedMarginDimension") && this.get("selectedMarginType") && this.get("newItem.purchasePrice")) {
        this.send("setOfPvpWithPurchasePrice");
      }
    }),
    setOfPvp2: Ember.observer("newItem.pvpMargin2", "selectedMarginDimension2", "selectedMarginType2", "newItem.purchasePrice", function () {
      if (this.get("newItem.pvpMargin2") && this.get("selectedMarginDimension2") && this.get("selectedMarginType2") && this.get("newItem.purchasePrice")) {
        this.send("setOfPvp2WithPurchasePrice");
      }
    }),
    setOfPvp3: Ember.observer("newItem.pvpMargin3", "selectedMarginDimension3", "selectedMarginType3", "newItem.purchasePrice", function () {
      if (this.get("newItem.pvpMargin3") && this.get("selectedMarginDimension3") && this.get("selectedMarginType3") && this.get("newItem.purchasePrice")) {
        this.send("setOfPvp3WithPurchasePrice");
      }
    }),
    pvChanged: Ember.observer("newItem.salePrice", "newItem.vat.vat.amount", "changePvp", function () {
      if (this.get("changePv") == true) {
        if ((this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") == 0) && this.get("newItem.salePrice")) {
          this.set("newItem.pvp", this.round(Number(this.get("newItem.salePrice")) + this.get("newItem.salePrice") * (this.get("newItem.vat.vat.amount") / 100)));
        } else {
          this.set("newItem.pvp", null);
        }
      }
    }),
    pvpChanged: Ember.observer("newItem.pvp", "newItem.vat.vat.amount", "changePv", function () {
      if (this.get("changePvp") == true) {
        if ((this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") == 0) && !Ember.isEmpty(this.get("newItem.pvp"))) {
          this.set("newItem.salePrice", this.round(this.get("newItem.pvp") * 100 / (100 + this.get("newItem.vat.vat.amount"))));
        } else {
          this.set("newItem.salePrice", null);
        }
      }
    }),

    /*
     * Functions
     */
    close: function close() {
      Ember.$("#modal-item-create").modal("hide");
    },
    reset: function reset() {
      this.set("selectedVariationType", null);
      this.set("selectedOption", null);
      Ember.$("select").prop("selectedIndex", 0);
      this.set("minStock", null);
      this.set("maxStock", null);
      this.set("disabledAction", false);
      this.set("changePv", false);
      this.set("changePvp", false);
      this.set("quantity", 0);
      this.set("period", null);
      this.set("selectedPeriod", null);
      this.set("selectedMarginType", null);
      this.set("selectedMarginDimension", null);
      this.set("selectedMarginType2", null);
      this.set("selectedMarginDimension2", null);
      this.set("selectedMarginType3", null);
      this.set("selectedMarginDimension3", null);
      this.set("model", {});
      this.set("selectedPurchaseUnitQuantity", undefined);
      this.set("selectedPurchaseUnit", undefined);
      this.set("selectedSalesUnitQuantity", undefined);
      this.set("selectedSalesUnit", undefined);
      this.set("newItem", {});

      if (this.get("taxes.length")) {
        this.set("newItem.vat", this.get("taxes").find(function (item) {
          return item.get("vat.description").includes("TN") || item.get("vat.description").includes("GEN");
        }));
      }
    },
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },

    /*
     * Actions
     */
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setPvpAux: function setPvpAux(pvp) {
        if (this.get("newItem.pvp") != pvp) {
          this.set("changePv", false);
          this.set("changePvp", true);
          this.set("newItem.sellable", pvp > 0);
          this.set("newItem.pvp", pvp);
        }
      },
      setOfPvpWithPurchasePrice: function setOfPvpWithPurchasePrice() {
        if (this.get("newItem.purchasePrice") && this.get("selectedMarginType") && this.get("selectedMarginDimension") && (this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") === 0)) {
          this.set("changePv", true);
          this.set("changePvp", false);

          if (this.get("selectedMarginDimension") === "%") {
            if (this.get("selectedMarginType") === "Markup") {
              this.set("newItem.salePrice", this.round(Number(this.get("newItem.purchasePrice")) * (1 + this.get("newItem.pvpMargin") / 100)));
            } else {
              this.set("newItem.salePrice", this.round(Number(this.get("newItem.purchasePrice")) / (1 - this.get("newItem.pvpMargin") / 100)));
            }
          } else {
            this.set("newItem.salePrice", this.round(Number(this.get("newItem.purchasePrice")) + Number(this.get("newItem.pvpMargin"))));
          }
        }
      },
      setOfPvp2WithPurchasePrice: function setOfPvp2WithPurchasePrice() {
        if (this.get("newItem.purchasePrice") && this.get("selectedMarginType2") && this.get("selectedMarginDimension2") && (this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") === 0)) {
          var unitPrice = Number(this.get("newItem.purchasePrice"));
          var margin = Number(this.get("newItem.pvpMargin2"));
          var vat = 100 + this.get("newItem.vat.vat.amount");

          if (this.get("selectedMarginDimension2") === "%") {
            if (this.get("selectedMarginType2") === "Markup") {
              var pv = this.round(unitPrice * (1 + margin / 100));
            } else {
              pv = this.round(unitPrice / (1 - margin / 100));
            }
          } else {
            pv = this.round(unitPrice + margin);
          }

          this.set("newItem.pvp2", this.round(pv * vat / 100));
        }
      },
      setOfPvp3WithPurchasePrice: function setOfPvp3WithPurchasePrice() {
        if (this.get("newItem.purchasePrice") && this.get("selectedMarginType3") && this.get("selectedMarginDimension3") && (this.get("newItem.vat.vat.amount") || this.get("newItem.vat.vat.amount") === 0)) {
          var unitPrice = Number(this.get("newItem.purchasePrice"));
          var margin = Number(this.get("newItem.pvpMargin3"));
          var vat = 100 + this.get("newItem.vat.vat.amount");

          if (this.get("selectedMarginDimension3") === "%") {
            if (this.get("selectedMarginType3") === "Markup") {
              var pv = this.round(unitPrice * (1 + margin / 100));
            } else {
              pv = this.round(unitPrice / (1 - margin / 100));
            }
          } else {
            pv = this.round(unitPrice + margin);
          }

          this.set("newItem.pvp3", this.round(pv * vat / 100));
        }
      },
      setPvpMargin: function setPvpMargin(value) {
        this.set("newItem.pvpMargin", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginType: function setMarginType(value) {
        this.set("selectedMarginType", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginDimension: function setMarginDimension(value) {
        this.set("selectedMarginDimension", value);
        this.send("setOfPvpWithPurchasePrice");
      },
      setMarginType2: function setMarginType2(value) {
        this.set("selectedMarginType2", value);
        this.send("setOfPvp2WithPurchasePrice");
      },
      setMarginDimension2: function setMarginDimension2(value) {
        this.set("selectedMarginDimension2", value);
        this.send("setOfPvp2WithPurchasePrice");
      },
      setMarginType3: function setMarginType3(value) {
        this.set("selectedMarginType3", value);
        this.send("setOfPvp3WithPurchasePrice");
      },
      setMarginDimension3: function setMarginDimension3(value) {
        this.set("selectedMarginDimension3", value);
        this.send("setOfPvp3WithPurchasePrice");
      },
      setTax: function setTax(Chosen) {
        this.set("newItem.vat", this.get("taxes").find(function (item) {
          return item.get("id") === Chosen;
        }));

        if (!this.get("newItem.organization.noVat")) {
          this.set("changePvp", false);
          this.set("changePv", true);
          this.send("setOfPvpWithPurchasePrice");
          this.send("setOfPvp2WithPurchasePrice");
          this.send("setOfPvp3WithPurchasePrice");
        }
      },
      setPv: function setPv() {
        this.set("changePv", true);
        this.set("changePvp", false);
      },
      setPvp: function setPvp() {
        this.set("changePv", false);
        this.set("changePvp", true);
      },
      stockableSet: function stockableSet(model) {
        this.set("newItem.stockable", model);
        this.set("newItem.lastPurchase", false);
      },
      setItemType: function setItemType(value) {
        var self = this;
        this.set("newItem.type", value);

        if (value.get("period")) {
          this.set("newItem.period", true);
          this.get("periodTypes").forEach(function (periodValue) {
            if (value.get("period") % periodValue.id == 0) {
              self.set("period", Number(value.get("period") / periodValue.id));
              self.set("selectedPeriod", periodValue);
            }
          });
        } else {
          this.set("newItem.period", false);
          this.set("period", null);
        }

        if (!this.get("newItem.type.stockable")) {
          this.set("newItem.minStock", null);
          this.set("newItem.maxStock", null);
          this.set("newItem.defaultWarehouse", null);
          this.set("newItem.companyItemSubType", null);
        }
      },
      setBaseUnit: function setBaseUnit(unit) {
        this.set("newItem.baseUnit", unit);
        this.set("selectedSalesUnit", null);
        this.set("selectedSalesUnitQuantity", null);
        this.set("selectedPurchaseUnit", null);
        this.set("selectedPurchaseUnitQuantity", null);
      },
      setSelectedPeriod: function setSelectedPeriod(value) {
        this.set("selectedPeriod", value);
        this.set("newItem.period", value.id);
      },
      setBrand: function setBrand(brand) {
        this.set("newItem.brand", brand);
      },
      setModel: function setModel(model) {
        this.set("newItem.model", model);
      },
      setWarehouse: function setWarehouse(model) {
        this.set("newItem.defaultWarehouse", model);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;
        this.set("newItem.organization", this.get("organization"));
        this.set("newItem.enabled", true);

        if (this.get("newItem.pvp2") == "") {
          this.set("newItem.pvp2", null);
        }

        if (this.get("newItem.pvp3") == "") {
          this.set("newItem.pvp3", null);
        }

        if (!this.get("newItem.sellable")) {
          this.set("newItem.salePrice", null);
          this.set("newItem.pvp", null);
        }

        if (!this.get("newItem.type.isExam")) {
          this.set("newItem.isExam", null);
        }

        if (this.get("selectedMarginType")) {
          if (this.get("selectedMarginType") == "Markup") {
            this.set("newItem.marginType", 0);
          } else {
            this.set("newItem.marginType", 1);
          }
        }

        if (this.get("selectedMarginType2")) {
          if (this.get("selectedMarginType2") == "Markup") {
            this.set("newItem.marginType2", 0);
          } else {
            this.set("newItem.marginType2", 1);
          }
        }

        if (this.get("selectedMarginType3")) {
          if (this.get("selectedMarginType3") == "Markup") {
            this.set("newItem.marginType3", 0);
          } else {
            this.set("newItem.marginType3", 1);
          }
        }

        if (this.get("selectedMarginDimension")) {
          if (this.get("selectedMarginDimension") == "%") {
            this.set("newItem.marginDimension", 0);
          } else {
            this.set("newItem.marginDimension", 1);
          }
        }

        if (this.get("selectedMarginDimension2")) {
          if (this.get("selectedMarginDimension2") == "%") {
            this.set("newItem.marginDimension2", 0);
          } else {
            this.set("newItem.marginDimension2", 1);
          }
        }

        if (this.get("selectedMarginDimension3")) {
          if (this.get("selectedMarginDimension3") == "%") {
            this.set("newItem.marginDimension3", 0);
          } else {
            this.set("newItem.marginDimension3", 1);
          }
        }

        if (Ember.isEmpty(this.get("newItem.type.isExam")) || this.get("newItem.type.isExam") == false) {
          this.set("newItem.parameter", null);
        }

        this.set("newItem.enable", true);

        if (this.get("newItem.period")) {
          this.set("newItem.period", Number(this.get("selectedPeriod.id")) * Number(this.get("period")));
        } else {
          this.set("newItem.period", null);
        }

        if (this.get("newItem.stockable") && Ember.isEmpty(this.get("newItem.defaultWarehouse")) && !Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") > 0) {
          this.set("newItem.defaultWarehouse", this.get("warehouses").objectAt(0));
        }

        this.get("store").createRecord("item", this.get("newItem")).save().then(function (savedItem) {
          /* Purchase unit */
          if (self.get("selectedPurchaseUnitQuantity") && self.get("selectedPurchaseUnit")) {
            self.get("store").createRecord("item-unit", {
              item: savedItem,
              unit: self.get("selectedPurchaseUnit"),
              quantity: Number(self.get("selectedPurchaseUnitQuantity")),
              preferred: true,
              inbound: true,
              outbound: false
            }).save();
          }
          /* Sales unit */


          if (self.get("selectedSalesUnitQuantity") && self.get("selectedSalesUnit")) {
            self.get("store").createRecord("item-unit", {
              item: savedItem,
              unit: self.get("selectedSalesUnit"),
              quantity: Number(self.get("selectedSalesUnitQuantity")),
              preferred: true,
              inbound: false,
              outbound: true
            }).save();
          }

          if (savedItem.get("stockable")) {
            /* warehouse */
            if (self.get("warehouses")) {
              self.get("warehouses").forEach(function (w) {
                return self.get("store").createRecord("warehouseItem", {
                  warehouse: w,
                  item: savedItem,
                  quantity: 0
                }).save();
              });
            }
          }

          self.get("flashMessages").success(self.get("intl").t("items.messages.createdSuccess"));
          self.close();
        }).catch(function (result) {
          self.set("disabledAction", false);
          self.get("es").errorReturned(result);
        });
      },
      selectVariationType: function selectVariationType(value) {
        this.set("selectedVariationType", value);
        this.set("newItem.variationType", value.id);
      },
      reset: function reset() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});