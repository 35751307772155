define("crm/components/campaigns-list-actions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      openEdit: function openEdit() {
        this.sendAction('openEdit', this.get("record"));
      },
      sendCampaign: function sendCampaign() {
        if (confirm(this.get('intl').t('campaigns.sendConfirmation'))) {
          this.set("record.textTemplate", this.get("record.textTemplate").split(" @c").join(" #FULL_NAME#"));
          this.set("record.textTemplate", this.get("record.textTemplate").split("@c ").join("#FULL_NAME# "));
          this.set("record.textTemplate", this.get("record.textTemplate").split(" @p").join(" #OBS#"));
          this.set("record.textTemplate", this.get("record.textTemplate").split("@p ").join("#OBS# "));
          this.get("record").set("sendDate", (0, _moment.default)());
          this.get("record").save();
        }
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});