define("crm/models/company-item-type", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    company: _emberData.default.belongsTo("company"),
    itemType: _emberData.default.belongsTo("itemType"),
    defaultDuration: _emberData.default.attr("number"),
    defaultDurationCompanyItemType: Ember.computed("defaultDuration", function () {
      return this.get("defaultDuration");
    })
  });

  _exports.default = _default;
});