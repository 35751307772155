define("crm/components/inbound-type-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    inboundType: Ember.computed("model", function () {
      var self = this;

      switch (this.get("model")) {
        case 0:
          return self.get("intl").t("inbounds.unknown");

        case 1:
          return self.get("intl").t("inbounds.customer");

        case 2:
          return self.get("intl").t("inbounds.facebook");

        case 3:
          return self.get("intl").t("inbounds.reference");

        case 4:
          return self.get("intl").t("inbounds.publicity");

        case 5:
          return self.get("intl").t("inbounds.website");

        case 6:
          return self.get("intl").t("inbounds.geolocation");

        case 7:
          return self.get("intl").t("inbounds.partner");
      }
    })
  });

  _exports.default = _default;
});