define("crm/controllers/organizations/organization/stats/reception/map", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    totals: {},
    billingByDay: Ember.computed("appointments.[]", function () {
      var _this = this;

      if (Ember.isEmpty(this.get("appointments"))) {
        return {};
      }

      var result = {};
      this.get("appointments").forEach(function (appointment) {
        var date = (0, _moment.default)(appointment[0]);
        var year = date.year();
        var month = date.format("MMM");
        var day = date.date();
        /* setup */

        if (!result[year]) {
          result[year] = {};
          _this.get("totals")[year] = {
            amount: 0,
            year: year,
            months: []
          };

          for (var d = 1; d < 38; d++) {
            switch (d % 7) {
              case 1:
                result[year][d] = {
                  dayOfTheWeek: "seg"
                };
                break;

              case 2:
                result[year][d] = {
                  dayOfTheWeek: "ter"
                };
                break;

              case 3:
                result[year][d] = {
                  dayOfTheWeek: "qua"
                };
                break;

              case 4:
                result[year][d] = {
                  dayOfTheWeek: "qui"
                };
                break;

              case 5:
                result[year][d] = {
                  dayOfTheWeek: "sex"
                };
                break;

              case 6:
                result[year][d] = {
                  dayOfTheWeek: "sab"
                };
                break;

              case 0:
                result[year][d] = {
                  dayOfTheWeek: "dom"
                };
                break;
            }

            for (var mI = 1; mI < 13; mI++) {
              var monthDate = (0, _moment.default)(year + "-" + mI + "-01");
              var mDays = monthDate.daysInMonth();
              var m = monthDate.format("MMM");
              var dayShift = monthDate.isoWeekday();

              if (d < dayShift || d > mDays + dayShift - 1) {
                result[year][d][m] = {};
              } else {
                result[year][d][m] = {
                  amount: 0,
                  day: d - dayShift + 1
                };
              }

              _this.get("totals")[year]["months"][m] = {
                amount: 0
              };
            }
          }
        }
        /* /setup */

        /* Body */


        var shift = (0, _moment.default)(year + "-" + (date.month() + 1) + "-01").isoWeekday() - 1;
        result[year][day + shift][month].amount += appointment[2];
        _this.get("totals")[year].amount += appointment[2];
        _this.get("totals")[year]["months"][month].amount += appointment[2];
      });
      return result;
    })
  });

  _exports.default = _default;
});