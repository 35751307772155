define("crm/models/internment", ["exports", "ember-data", "moment", "crm/models/abstract-model"], function (_exports, _emberData, _moment, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    internmentTypes: Ember.computed.alias("catalogs.internmentTypes"),
    criticalStatus: _emberData.default.attr("number"),
    diagnosis: _emberData.default.attr("string"),
    dischargeDate: _emberData.default.attr("localdatetime"),
    end: _emberData.default.attr("isodate"),
    endReason: _emberData.default.attr("string"),
    location: _emberData.default.attr("string"),
    locked: _emberData.default.attr("boolean"),
    notes: _emberData.default.attr("string"),
    reason: _emberData.default.attr("string"),
    start: _emberData.default.attr("isodate"),
    symptoms: _emberData.default.attr("string"),
    type: _emberData.default.attr("number"),
    warningMessage: _emberData.default.attr("string"),
    dischargeText: _emberData.default.attr("string"),
    blocker: _emberData.default.belongsTo("user"),
    discharger: _emberData.default.belongsTo("user"),
    internmentStatus: _emberData.default.belongsTo("internment-status", {
      inverse: "internment"
    }),
    organization: _emberData.default.belongsTo("organization"),
    patient: _emberData.default.belongsTo("patient"),
    responsibleVet: _emberData.default.belongsTo("worker"),
    shoppingCart: _emberData.default.belongsTo("internment-shopping-cart"),
    quotation: _emberData.default.belongsTo("quotation"),
    item: _emberData.default.belongsTo("item"),
    chores: _emberData.default.hasMany("todo-chore", {
      inverse: "internment"
    }),
    drugs: _emberData.default.hasMany("todo-drug", {
      inverse: "internment"
    }),
    exams: _emberData.default.hasMany("todo-exam", {
      inverse: "internment"
    }),
    monitorings: _emberData.default.hasMany("todo-monitoring", {
      inverse: "internment"
    }),
    serums: _emberData.default.hasMany("todo-serum", {
      inverse: "internment"
    }),
    shoppingCarts: _emberData.default.hasMany("internment-shopping-cart", {
      inverse: "internment"
    }),
    typeName: Ember.computed("type", "internmentTypes.[]", function () {
      var _this = this;

      if (!this.get("internmentTypes")) {
        return null;
      }

      return this.get("type") && this.get("internmentTypes").find(function (t) {
        return t.id === _this.get("type");
      }) ? this.get("internmentTypes").find(function (t) {
        return t.id === _this.get("type");
      }).name : this.get("internmentTypes").find(function (t) {
        return t.id === 5;
      }).name;
    }),
    patientName: Ember.computed("patient.name", function () {
      return this.get("patient.name");
    }),
    dischargeScheduled: Ember.computed("dischargeDate", "end", function () {
      return this.get("dischargeDate") && (!this.get("end") || (0, _moment.default)().isBefore(this.get("end")));
    }),
    foodAlert: Ember.computed('food.lastActionFood.started', function () {
      return (0, _moment.default)().diff(this.get('food.lastActionFood.started'), 'h') > 12;
    }),
    urineAlert: Ember.computed('urine.lastActionUrine.started', function () {
      return (0, _moment.default)().diff(this.get('urine.lastActionUrine.started'), 'h') > 24;
    }),
    faecesAlert: Ember.computed('faeces.lastActionFaeces.started', function () {
      return (0, _moment.default)().diff(this.get('faeces.lastActionFaeces.started'), 'h') > 24;
    }),
    allDrugs: Ember.computed("drugs.@each.prevTodos", function () {
      var result = [];
      this.get("drugs") && this.get("drugs").forEach(function (drug) {
        drug.get("prevTodos").forEach(function (prevTodo) {
          result.addObject(prevTodo);
        });
      });
      return result.sortBy("name");
    }),
    allExams: Ember.computed("exams.@each.prevTodos", function () {
      var result = [];
      this.get("exams") && this.get("exams").forEach(function (exam) {
        exam.get("prevTodos").forEach(function (prevTodo) {
          result.addObject(prevTodo);
        });
      });
      return result.sortBy("name");
    }),
    allMonitorings: Ember.computed("monitorings.@each.prevTodos", function () {
      var result = [];
      this.get("monitorings") && this.get("monitorings").forEach(function (monitoring) {
        monitoring.get("prevTodos").forEach(function (prevTodo) {
          result.addObject(prevTodo);
        });
      });
      return result.sortBy("name");
    }),
    allSerums: Ember.computed("serums.@each.prevTodos", function () {
      var result = [];
      this.get("serums") && this.get("serums").forEach(function (serum) {
        serum.get("prevTodos").forEach(function (prevTodo) {
          result.addObject(prevTodo);
        });
      });
      return result.sortBy("name");
    }),
    openSerums: Ember.computed("serums.@each.started", "serums.@each.ended", function () {
      var result = [];
      this.get("serums") && this.get("serums").forEach(function (serum) {
        if (!serum.get("hasEnded")) {
          result.addObject(serum);
        }
      });
      return result;
    }),
    allChores: Ember.computed("chores.@each.prevTodos", function () {
      var result = [];
      this.get("chores") && this.get("chores").forEach(function (chore) {
        chore.get("prevTodos").forEach(function (prevTodo) {
          result.addObject(prevTodo);
        });
      });
      return result;
    }),
    physycalExam: Ember.computed("allMonitorings.@each.name", function () {
      var result = null;
      var self = this;
      this.get("allMonitorings").forEach(function (monitoring) {
        if (monitoring.get("name") && monitoring.get("name") === self.get("intl").t("monitorings.physicalExam")) {
          result = monitoring;
        }
      });
      return result;
    }),
    urine: Ember.computed("allMonitorings.@each.name", function () {
      var result = null;
      var self = this;
      this.get("allMonitorings").forEach(function (monitoring) {
        if (monitoring.get("name") && monitoring.get("name") === self.get("intl").t("internments.internment.event.urine")) {
          result = monitoring;
        }
      });
      return result;
    }),
    faeces: Ember.computed("allMonitorings.@each.name", function () {
      var result = null;
      var self = this;
      this.get("allMonitorings").forEach(function (monitoring) {
        if (monitoring.get("name") && monitoring.get("name") === self.get("intl").t("internments.internment.event.faeces")) {
          result = monitoring;
        }
      });
      return result;
    }),
    food: Ember.computed("allChores.@each.name", function () {
      var result = null;
      var self = this;
      this.get("allChores").forEach(function (chore) {
        if (chore.get("name") && chore.get("name") === self.get("intl").t("internments.internment.event.food")) {
          result = chore;
        }
      });
      return result;
    }),
    contactOwner: Ember.computed("allChores.@each.name", function () {
      var result = null;
      var self = this;
      this.get("allChores").forEach(function (chore) {
        if (chore.get("name") && chore.get("name") === self.get("intl").t("internments.internment.event.contactOwner").toString()) {
          result = chore;
        }
      });
      return result;
    }),
    lastOralDrug: Ember.computed("allDrugs.@each.via", "allDrugs.@each.actions", function () {
      var oralDrugs = this.get("allDrugs").filterBy("via", 1);
      return oralDrugs.sortBy("lastAction.started").reverse().get("firstObject.lastAction");
    }),
    hasTasks: Ember.computed("tasks.[]", "drugs.[]", "exams.[]", "monitorings.[]", "events.[]", "serums.[]", function () {
      return this.get("tasks.length") > 0 || this.get("drugs.length") > 0 || this.get("exams.length") > 0 || this.get("monitorings.length") > 0 || this.get("events.length") > 0 || this.get("serums.length") > 0;
    }),
    duration: Ember.computed("end", function () {
      var end = this.get("end") ? (0, _moment.default)(this.get("end")) : (0, _moment.default)();
      return 1 + end.diff(this.get("start"), "days");
    }),
    shouldBeLocked: Ember.computed("currentUser", "locked", "blocker", function () {
      if (this.get("locked") && this.get("currentUser.id") !== this.get("blocker.id") && !this.get("currentWorker.isAdmin")) {
        return true;
      }
    }),
    hasEnded: Ember.computed("end", function () {
      return this.get("end") && (0, _moment.default)().isSameOrAfter(this.get("end"));
    }),
    disabled: Ember.computed("shouldBeLocked", "hasEnded", function () {
      return this.get("shouldBeLocked") || this.get("hasEnded");
    }),
    canBeReopened: Ember.computed("end", function () {
      return this.get("end") && (0, _moment.default)().isSameOrAfter(this.get("end")) && (0, _moment.default)().diff(this.get("end"), "h") < 2;
    })
  });

  _exports.default = _default;
});