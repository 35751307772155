define("crm/models/payment-line", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    item: _emberData.default.belongsTo('item'),
    order: _emberData.default.belongsTo('payment', {
      inverse: 'lines'
    }),
    quantity: _emberData.default.attr('number'),
    linePrice: _emberData.default.attr('number'),
    discount: _emberData.default.attr()
  });

  _exports.default = _default;
});