define("crm/components/bundle-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bundleTotal: Ember.computed("bundleItems.@each.item", "bundleItems.@each.quantity", function () {
      var total = 0;

      if (this.get("bundleItems.length")) {
        this.get("bundleItems").forEach(function (bundleItem) {
          var linePrice = Number((bundleItem.get("item.pvp") * bundleItem.get("quantity")).toFixed(2));
          bundleItem.set("linePrice", linePrice);
          total += linePrice;
        });
      }

      return total.toFixed(2);
    }),
    actions: {
      deleteBundleItem: function deleteBundleItem(item) {
        this.sendAction("delete", item);
      }
    }
  });

  _exports.default = _default;
});