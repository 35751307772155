define("crm/controllers/organizations/organization/invites/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoGroupInvites: Ember.computed("can", function () {
      return this.get("can").can("do group");
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "name",
        "title": this.get('intl').t("models.user.name"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "email",
        "title": this.get('intl').t("models.user.email"),
        "component": "title-models-table-display",
        "className": "hidden-xs"
      }, {
        "propertyName": "role",
        "title": this.get('intl').t("workers.worker.role"),
        "className": "column-big",
        "component": "show-worker-role"
      }, {
        "propertyName": "created",
        "title": this.get('intl').t("crud.created"),
        "className": "column-date-time hidden-xs",
        "component": "umt-datetime"
      }, {
        "component": "invites-table-actions",
        "className": "column-actions-1"
      }];
    })
  });

  _exports.default = _default;
});