define("crm/components/recursive-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedOption: null,
    classNames: ["recursive-select"],
    init: function init() {
      this._super.apply(this, arguments);

      this.setUp();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.setUp();
    },
    setUp: function setUp() {
      if (this.get("seed.firstObject.id")) {
        this.set('selectedOption', this.get("seed.firstObject"));
      }

      var aux = [];

      for (var i = 1; i < this.get("seed.length"); i++) {
        aux = aux.concat(this.get("seed")[i]);
      }

      this.set("subSeed", aux);
    },
    contentSorted: Ember.computed("content.@each.name", function () {
      return Ember.isEmpty(this.get("content")) ? [] : this.get("content").sortBy("name");
    }),
    actions: {
      beCalled: function beCalled(result) {
        if (Ember.isEmpty(result)) {
          this.parentAction(this.get("selectedOption"));
        } else {
          this.parentAction(result);
        }
      },
      setSelectedOption: function setSelectedOption(value) {
        var result = null;

        if (value) {
          result = this.get('store').peekRecord(this.get('modelName'), value);
          this.set('selectedOption', result);
        } else {
          this.set('selectedOption', null);
        }

        if (this.get('parentAction')) {
          this.parentAction(result);
        }
      }
    }
  });

  _exports.default = _default;
});