define("crm/components/periodic-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    lastPeriodicArray: Ember.computed("lastPeriodic.id", function () {
      if (this.get("lastPeriodic")) {
        this.set("lastPeriodic.lessTableActions", true);
      }

      return this.get("lastPeriodic") ? [this.get("lastPeriodic")] : [];
      /*
      return [{
          nextPeriodic: this.get("lastPeriodic.nextPeriodic"),
          consultation: this.get("lastPeriodic.consultation"),
          smsSent: this.get("lastPeriodic.smsSent"),
          patient: this.get("lastPeriodic.patient"),
          type: this.get("lastPeriodic.type")
      }];
      */
    }),
    allPeriodics: Ember.computed("model.patient.id", "model.type.id", function () {
      if (this.get("model.patient.id") && this.get("model.type.id")) {
        return this.get("store").query("periodic", {
          patient: this.get("model.patient.id"),
          type: this.get("model.type.id")
        });
      } else {
        return [];
      }
    }),
    lastPeriodic: Ember.computed("allPeriodics.@each.nextPeriodic", function () {
      return this.get("allPeriodics.length") ? this.get("allPeriodics").sortBy("nextPeriodic").get("lastObject") : null;
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "intakeDate",
        "title": this.get("intl").t("periodics.periodic.intake"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-date",
        "className": "column-name"
      }, {
        "propertyName": "nextPeriodic",
        "title": this.get("intl").t("periodics.periodic.nextPeriodic"),
        "component": "umt-date",
        "className": "column-date text-small"
      }, {
        "component": "periodic-history-actions",
        "className": "column-actions-1"
      }];
    }),
    actions: {
      editPeriodic: function editPeriodic(periodic) {
        this.toggleProperty("closed");
        this.sendAction("openModal", {
          entity: "periodic",
          action: "edit",
          model: periodic
        });
      },
      reset: function reset() {
        this.get("allPeriodics.length") && this.get("allPeriodics").forEach(function (p) {
          return p.set("lessTableActions", false);
        });
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        Ember.set(periodic, "consultation", this.get("model.consultation"));
        this.get("store").createRecord("periodic", periodic).save();
      }
    }
  });

  _exports.default = _default;
});