define("crm/controllers/organizations/organization/financial-accounts/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    filteredModel: Ember.computed("showAll", "model.[]", function () {
      return this.get("showAll") ? this.get("model") : this.get("model").filterBy("isManual", true).sortBy("name");
    }),
    mix: Ember.computed("model.@each.isManual", function () {
      if (Ember.isEmpty(this.get("model"))) {
        return false;
      }

      var hasAuto = false;
      var hasManual = false;
      this.get("model").forEach(function (f) {
        if (f.get("isManual")) {
          hasManual = true;
        } else {
          hasAuto = true;
        }
      });

      if (hasAuto && hasManual) {
        this.set("showAll", false);
        return true;
      } else {
        this.set("showAll", true);
        return false;
      }
    }),
    isToday: Ember.computed("date", function () {
      return (0, _moment.default)(this.get("date")).format("YYYY-MM-DD") === (0, _moment.default)().format("YYYY-MM-DD");
    }),
    canDoFinancialAccounts: Ember.computed("can", function () {
      return this.get("can").can("do sale");
    }),
    actions: {
      openFinancialAccount: function openFinancialAccount(financialAccount) {
        this.send("openModal", {
          entity: "financialAccountOpen",
          action: "new",
          model: financialAccount
        });
      },
      today: function today() {
        this.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
        this.set("createdAfter", (0, _moment.default)(this.get("date")).startOf("day").format("YYYY-MM-DD"));
        this.set("createdBefore", (0, _moment.default)(this.get("date")).endOf("day").format("YYYY-MM-DD"));
      },
      setDateFilters: function setDateFilters() {
        if (!Ember.isEmpty(this.get("date"))) {
          this.set("createdAfter", (0, _moment.default)(this.get("date")).startOf("day").format("YYYY-MM-DD"));
          this.set("createdBefore", (0, _moment.default)(this.get("date")).endOf("day").format("YYYY-MM-DD"));
        }
      }
    }
  });

  _exports.default = _default;
});