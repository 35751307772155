define("crm/routes/organizations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,
    afterModel: function afterModel(model) {
      if (model.get("length") === 1) {
        this.transitionTo('organizations.organization', model.get("firstObject.id"));
      }
    }
  });

  _exports.default = _default;
});