define("crm/components/show-task-status", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "i",
    classNames: ["fa fa-fw"],
    classNameBindings: ["icon", "color"],
    attributeBindings: ["title"],
    title: Ember.computed("record.status", "record.taskDate", function () {
      if (this.get("record.status") == 1) {
        if (this.get("record.time")) {
          if ((0, _moment.default)().isBefore(this.get("record.dateTime"))) {
            /* date & time - ok */
            this.set("icon", "fa-thumbs-o-up");
            return this.get("intl").t("tasks.status.pending");
          } else {
            /* date & time - in delay */
            this.set("icon", "fa-thumbs-down");
            return this.get("intl").t("tasks.status.inDelay");
          }
        } else {
          if ((0, _moment.default)().isBefore(this.get("record.date"), "day")) {
            /* date - in delay */
            this.set("icon", "fa-thumbs-down");
            return this.get("intl").t("tasks.status.inDelay");
          } else {
            /* date - ok */
            this.set("icon", "fa-thumbs-o-up");
            return this.get("intl").t("tasks.status.pending");
          }
        }
      } else if (this.get("record.status") == 2) {
        this.set("icon", "fa-thumbs-o-up");
        return this.get("intl").t("tasks.status.done");
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});