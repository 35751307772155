define("crm/components/item-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    model: {
      salesUnits: []
    },
    disabledAction: false,
    pv: null,
    pvp: null,
    changePv: false,
    changePvp: false,
    es: Ember.inject.service(),
    nameRegex: "^[^<>]+$",
    showPeriod: false,
    showMessage: false,
    itemItemsList: [],
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;

      if (this.get("model.id")) {
        this.set("showMessage", false);
        this.get("store").findRecord("item", this.get("model.id"), {
          reload: true
        }).then(function (savedItem) {
          self.set("savedItemStockable", savedItem.get("stockable"));
          self.set("sellable", savedItem.get("sellable"));
          self.set("stockable", savedItem.get("stockable"));

          if (savedItem.get("parameter.id")) {
            self.set("model.isExam", true);
          }

          if (savedItem.get("baseUnit")) {
            self.get("store").query("unit", {
              company: self.get("organization.company.id"),
              isBaseUnit: true,
              dimension: savedItem.get("baseUnit.dimension.id")
            }).then(function (dimensionBaseUnits) {
              self.set("dimensionBaseUnits", dimensionBaseUnits);
            });
          }

          if (savedItem.get("warehouseItems") && self.get("organization")) {
            self.get("store").query("warehouse-item", {
              organization: self.get("organization.id"),
              item: savedItem.get("id")
            });
          }

          if (savedItem.get("itemUnits.length")) {
            self.get("store").query("item-unit", {
              item: savedItem.get("id")
            }).then(function () {
              if (savedItem.get("stockItemUnit")) {
                self.set("selectedPurchaseUnit", savedItem.get("stockItemUnit.unit"));
                self.set("selectedPurchaseQuantity", savedItem.get("stockItemUnit.quantity"));
              } else {
                self.set("selectedPurchaseUnit", null);
                self.set("selectedPurchaseQuantity", null);
              }

              if (savedItem.get("saleItemUnit")) {
                self.set("selectedSalesUnit", savedItem.get("saleItemUnit.unit"));
                self.set("selectedSalesQuantity", savedItem.get("saleItemUnit.quantity"));
              } else {
                self.set("selectedSalesUnit", null);
                self.set("selectedSalesQuantity", null);
              }
            });
          } else {
            self.set("selectedPurchaseUnit", null);
            self.set("selectedPurchaseQuantity", null);
            self.set("selectedSalesUnit", null);
            self.set("selectedSalesQuantity", null);
          }

          if (savedItem.get("period")) {
            var time = savedItem.get("period");

            if (time >= 8760) {
              self.set("period", time / 8760);
              self.get("periodTypes").forEach(function (periodType) {
                if (periodType.id == 8760) self.set("selectedPeriod", periodType);
              });
            } else if (time >= 720) {
              self.set("period", time / 720);
              self.get("periodTypes").forEach(function (periodType) {
                if (periodType.id == 720) self.set("selectedPeriod", periodType);
              });
            } else if (time >= 168) {
              self.set("period", time / 168);
              self.get("periodTypes").forEach(function (periodType) {
                if (periodType.id == 168) self.set("selectedPeriod", periodType);
              });
            } else {
              self.set("period", time / 24);
              self.get("periodTypes").forEach(function (periodType) {
                if (periodType.id == 24) self.set("selectedPeriod", periodType);
              });
            }
          }

          if (!Ember.isEmpty(savedItem.get("children"))) {
            savedItem.get("childrenSorted").forEach(function (itemItem) {
              self.get("store").findRecord("item-item", itemItem.get("id"), {
                reload: true
              }).then(function (foundItemItem) {
                self.get("itemItemsList").addObject(foundItemItem);
              });
            });
          } else {
            self.set("itemItemsList", []);
          }

          if (savedItem.get("companyItemSubType")) {
            self.set("selectedCompanySubType", savedItem.get("companyItemSubType"));
          } else {
            self.set("selectedCompanySubType", undefined);
          }
        });
      }
    },
    parentIsExam: Ember.computed("type", "parameters.[]", function () {
      return this.get("type.isExam") && !Ember.isEmpty(this.get("parameters"));
    }),
    periodTypes: Ember.computed("catalogs.periodTypes", function () {
      return this.get("catalogs.periodTypes");
    }),
    defaultWarehouseDisplay: Ember.computed("warehouses.[]", function () {
      return this.get("warehouses.length") > 1;
    }),
    invoicesByItem: Ember.computed("model.id", function () {
      if (this.get("model.id")) {
        return this.get("store").query("invoice", {
          item: this.get("model.id")
        });
      }
    }),
    invoicesByItemFiltered: Ember.computed("invoicesByItem.[]", function () {
      return this.get("invoicesByItem").filter(function (i) {
        return i.get("status") !== 8 && i.get("status") !== 9;
      });
    }),
    cannotEdit: Ember.computed("invoicesByItem.@each.billingID", function () {
      if (!Ember.isEmpty(this.get("invoicesByItem"))) {
        return !Ember.isEmpty(this.get("invoicesByItem").filter(function (invoice) {
          return invoice.get("billingID");
        }));
      }
    }),
    nameHasError: Ember.computed("model.name", "nameRegex", function () {
      return this.get("model.name").match(this.get("nameRegex"));
    }),
    disableOfActions: Ember.observer("model.salePrice", "model.pvp", "sellable", function () {
      if (this.get("sellable")) {
        if (!this.get("itemItemsList")) {
          if (Ember.isEmpty(this.get("model.pvp")) || this.get("model.pvp") <= 0) {
            this.set("disabledAction", true);
          } else {
            this.set("disabledAction", false);
          }
        } else {
          this.set("disabledAction", false);
        }
      } else {
        this.set("disabledAction", false);
      }
    }),
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    requiredFieldSales: Ember.computed("selectedSalesQuantity", "selectedSalesUnit", function () {
      var result = false;

      if (this.get("selectedSalesQuantity") > 0) {
        if (!this.get("selectedSalesUnit")) {
          result = true;
        }
      } else if (this.get("selectedSalesUnit")) {
        result = true;
      }

      return result;
    }),
    requiredFieldPurchase: Ember.computed("selectedPurchaseQuantity", "selectedPurchaseUnit", function () {
      var result = false;

      if (this.get("selectedPurchaseQuantity") > 0) {
        if (!this.get("selectedPurchaseUnit")) {
          result = true;
        }
      } else if (this.get("selectedPurchaseUnit")) {
        result = true;
      }

      return result;
    }),
    itemItemsTotal: Ember.computed("itemItemsList.@each.salePrice", "itemItemsList.@each.quantity", "itemItemsList.@each.discount", function () {
      var self = this;
      var total = 0;
      var linePrice;

      if (!Ember.isEmpty(this.get('itemItemsList'))) {
        this.get("itemItemsList").forEach(function (itemItem) {
          if (itemItem.get("discount")) {
            linePrice = (self.money(itemItem.get("salePrice")) - self.money(itemItem.get("salePrice")) * (itemItem.get("discount") / 100)) * itemItem.get("quantity");
          } else {
            linePrice = itemItem.get("quantity") * self.money(itemItem.get("salePrice"));
          }

          total += linePrice;
        });
      }

      return total;
    }),
    pvChanged: Ember.observer("model.salePrice", 'model.vat.vat.amount', 'changePv', 'changePvp', function () {
      if (this.get('changePv') == true) {
        if (this.get('model.vat.vat.amount') || this.get("model.vat.vat.amount") == 0 && this.get('model.salePrice')) {
          this.set('model.pvp', (Number(this.get('model.salePrice')) + this.get('model.salePrice') * (this.get('model.vat.vat.amount') / 100)).toFixed(2));
        }
      }
    }),
    pvpChanged: Ember.observer("model.pvp", 'model.vat.vat.amount', 'changePv', 'changePvp', function () {
      if (this.get('changePvp') == true) {
        if (this.get("model.vat.vat.amount") || this.get("model.vat.vat.amount") == 0 && this.get("model.pvp")) {
          this.set('model.salePrice', (this.get('model.pvp') * 100 / (100 + this.get('model.vat.vat.amount'))).toFixed(2));
        }
      }
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      setTax: function setTax(taxChosen) {
        this.set("model.vat", this.get("taxes").find(function (tax) {
          return tax.id == taxChosen;
        }));
        this.set("changePvp", false);
        this.set("changePv", true);
      },
      setPurchaseUnit: function setPurchaseUnit(unit) {
        this.set("selectedPurchaseUnit", unit);
      },
      setSalesUnit: function setSalesUnit(unit) {
        this.set("selectedSalesUnit", unit);
      },
      selectItem: function selectItem() {
        this.sendAction("openModal", {
          entity: "item",
          action: "select"
        });
      },
      selectedItem: function selectedItem(_selectedItem) {
        this.set("showLoading", true);
        var self = this;
        var canAdd = true;

        if (this.get("model.id") !== _selectedItem.get("id")) {
          this.get("itemItemsList").filter(function (item) {
            if (item.get("child.id") == _selectedItem.get("id")) {
              canAdd = false;
              self.set("showMessage", true);
            }
          });

          if (canAdd === true) {
            self.get("store").findRecord("item", _selectedItem.get("id")).then(function (foundItem) {
              self.get("itemItemsList").addObject(self.get("store").createRecord("itemItem", {
                child: foundItem,
                quantity: 1,
                salePrice: _selectedItem.get("pvp"),
                locked: false
              }));
            });
            self.set("showMessage", false);
          }
        } else {
          this.set("showMessage", true);
        }

        this.set("showLoading", false);
      },
      setPv: function setPv() {
        this.set('changePv', true);
        this.set('changePvp', false);
      },
      setPvp: function setPvp() {
        this.set('changePv', false);
        this.set('changePvp', true);
      },
      selectItemType: function selectItemType(value) {
        this.set('type', value);
      },
      toggleUnit: function toggleUnit(unit) {
        if (unit.active) {
          unit.set('active', false);
          this.get('model.salesUnits').removeObject(unit);
        } else {
          unit.set('active', true);
          this.get('model.salesUnits').addObject(unit);
        }
      },
      setSelectedPeriod: function setSelectedPeriod(value) {
        this.set("selectedPeriod", value);
        this.set("model.period", value.id);
      },
      deleteCompoundProductItem: function deleteCompoundProductItem(itemItem) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          itemItem.destroyRecord();
          this.get("itemItemsList").removeObject(itemItem);
        }
      },
      setBaseUnit: function setBaseUnit(unit) {
        var self = this;
        this.set('model.baseUnit', unit);
        this.get("store").query("unit", {
          company: this.get("organization.company.id"),
          isBaseUnit: true,
          dimension: this.get("model.baseUnit.dimension.id")
        }).then(function (dimensionBaseUnits) {
          self.set("dimensionBaseUnits", dimensionBaseUnits);
        });
      },
      setBrand: function setBrand(brand) {
        this.set('model.brand', brand);
      },
      setModel: function setModel(model) {
        this.set('model.model', model);
      },
      setWarehouse: function setWarehouse(model) {
        this.set("model.defaultWarehouse", model);
      },
      modalEditItem: function modalEditItem() {
        var self = this;
        this.set("disabledAction", true);
        this.set("model.sellable", this.get("sellable"));

        if (this.get("stockable")) {
          this.set("model.stockable", this.get("stockable"));

          if (!Ember.isEmpty(this.get("warehouses")) && this.get("warehouses.length") > 0 && Ember.isEmpty(this.get("model.defaultWarehouse"))) {
            this.set("model.defaultWarehouse", this.get("warehouses").objectAt(0));
          }
        }

        if (this.get("model.period")) {
          this.set("model.period", Number(this.get("period")) * Number(this.get("selectedPeriod").id));
        }

        if (this.get("type")) {
          this.set("model.type", this.get("type"));
        }

        if (this.get("selectedCompanySubType")) {
          this.set("model.companyItemSubType", this.get("selectedCompanySubType"));
        }

        if (!Ember.isEmpty(this.get("itemItemsList"))) {
          this.get("itemItemsList").forEach(function (itemItem) {
            if (!Ember.isEmpty(itemItem.get("child"))) {
              if (!Ember.isEmpty(itemItem.get("parent"))) {
                Ember.set(itemItem, "parent", self.get("model"));
                itemItem.save();
              } else {
                itemItem.save();
              }
            } else {
              itemItem.set("salePrice", Number(itemItem.get("salePrice")));
              itemItem.save();
            }
          });
          this.set("model.pvp", this.get("itemItemsTotal"));
        }

        this.get("model").save().then(function (savedItem) {
          if (!Ember.isEmpty(self.get("model.stockItemUnit.id"))) {
            self.set("model.stockItemUnit.unit", self.get("selectedPurchaseUnit"));
            self.set("model.stockItemUnit.quantity", self.get("selectedPurchaseQuantity"));
            self.get("model.stockItemUnit").save().catch(function (result) {
              self.get("es").errorReturned(result);
              self.set('disabledAction', false);
            });
          } else {
            if (self.get("selectedPurchaseUnit") && self.get("selectedPurchaseQuantity") > 0) {
              self.get("store").createRecord("item-unit", {
                item: savedItem,
                unit: self.get("selectedPurchaseUnit"),
                quantity: Number(self.get("selectedPurchaseQuantity")),
                preferred: true,
                inbound: true,
                outbound: false
              }).save();
            }
          }

          if (!Ember.isEmpty(self.get("model.saleItemUnit.id"))) {
            self.set("model.saleItemUnit.unit", self.get("selectedSalesUnit"));
            self.set("model.saleItemUnit.quantity", self.get("selectedSalesQuantity"));
            self.get("model.saleItemUnit").save().catch(function (result) {
              self.get("es").errorReturned(result);
              self.set('disabledAction', false);
            });
          } else {
            if (self.get("selectedSalesUnit") && self.get("selectedSalesQuantity") > 0) {
              self.get("store").createRecord("item-unit", {
                item: savedItem,
                unit: self.get("selectedSalesUnit"),
                quantity: Number(self.get("selectedSalesQuantity")),
                preferred: true,
                inbound: false,
                outbound: true
              }).save();
            }
          }

          Ember.$('#openEditItem').modal('hide');
          self.set('disabledAction', false);
          self.close();
          self.sendAction("reloadModel");
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set('disabledAction', false);
        });
      },
      cancelEditItemModal: function cancelEditItemModal() {
        this.close();
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledAction", false);
        this.set("showPeriod", false);
        this.set("showMessage", false);
        this.set("itemItemsList", []);
      }
    }
  });

  _exports.default = _default;
});