define("crm/services/restservices", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    create: function create(url) {
      var self = this;
      return Ember.$.post({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + url,
        beforeSend: function beforeSend(request) {
          return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        }
      });
    },
    retrieve: function retrieve(url) {
      var self = this;
      return Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + url,
        beforeSend: function beforeSend(request) {
          return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        }
      });
    },
    delete: function _delete(url) {
      var self = this;
      return Ember.$.ajax({
        type: "DELETE",
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + url,
        beforeSend: function beforeSend(request) {
          return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        }
      });
    }
  });

  _exports.default = _default;
});