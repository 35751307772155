define("crm/routes/organizations/organization/internments/simple-view", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    model: function model() {
      return this.get("store").query("internment", {
        organization: this.modelFor("organizations.organization").get("id"),
        open: true
      });
    },
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do internment')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    afterModel: function afterModel() {
      if (Ember.isEmpty(this.get("model"))) {
        return;
      }

      var organizationID = this.modelFor("organizations.organization").get("id");
      var openAfter = (0, _moment.default)().startOf("hour").subtract(1, "d").format();

      if (organizationID && openAfter) {
        Ember.RSVP.hash({
          chores: this.store.query("todo-chore", {
            organization: organizationID,
            openAfter: openAfter
          }),
          drugs: this.store.query("todo-drug", {
            organization: organizationID,
            openAfter: openAfter
          }),
          exames: this.store.query("todo-exam", {
            organization: organizationID,
            openAfter: openAfter
          }),
          monitorings: this.store.query("todo-monitoring", {
            organization: organizationID,
            openAfter: openAfter
          }),
          serums: this.store.query("todo-serum", {
            organization: organizationID,
            openAfter: openAfter
          }),
          actions: this.store.query("action", {
            organization: organizationID,
            startedAfter: openAfter
          })
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var aux = 30000;
      controller.set("workers", this.get("store").query("worker", {
        organization: this.modelFor("organizations.organization").get("id")
      }));
      Ember.run.later(function () {
        return self.refreshInternments(aux);
      }, aux);
    },
    refreshInternments: function refreshInternments(lastTime) {
      var self = this;
      this.refresh();

      if (Ember.$(document)[0].URL.includes("overview")) {
        var aux = lastTime * 1.2;
        Ember.run.later(function () {
          return self.refreshInternments(aux);
        }, aux);
      }
    }
  });

  _exports.default = _default;
});