define("crm/components/drug-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    beingSaved: false,
    catalogs: Ember.inject.service(),
    viaTypes: Ember.computed.alias("catalogs.viaTypes"),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("drug", this.get("model.id"));
      }
    },
    drugTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        var result = this.get("itemTypes").filter(function (type) {
          return type.get("isDrug") && Ember.isEmpty(type.get("parent.id"));
        });
      }

      if (result && result.length == 1) {
        return this.get("itemTypes").filter(function (type) {
          return type.get("parent.id") === result.get("firstObject.id");
        });
      } else {
        return result;
      }
    }),
    disabledSubmissionDrugEdit: Ember.computed("model.type", "selectedDrugType.id", "itemTypes.@each.parent", "beingSaved", function () {
      return Ember.isEmpty(this.get("model.type")) || Ember.isEmpty(this.get("selectedDrugType.id")) || this.get("beingSaved");
    }),
    hideIconDrugEdit: Ember.computed("model.type", "selectedDrugType.id", "itemTypes.@each.parent", "beingSaved", function () {
      return Ember.isEmpty(this.get("model.type")) || Ember.isEmpty(this.get("selectedDrugType.id")) || this.get("beingSaved");
    }),
    selectedDrugTypeObserver: Ember.observer("selectedDrugType.id", function () {
      if (this.get("selectedDrugType.id")) {
        var self = this;
        this.get("store").query("item", {
          company: this.get("organization.company.id"),
          type: this.get("selectedDrugType.id"),
          sellable: true
        }).then(function (items) {
          self.set("filteredDrugs", items);

          if (items != null && items.get("length") == 1) {
            self.set("model.type", items.get("firstObject"));
          }
        });
      }
    }),
    close: function close() {
      Ember.$("#modal-drug-edit").modal("hide");
    },
    actions: {
      selectDrugType: function selectDrugType(value) {
        this.set("selectedDrugType", value);
      },
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        var self = this;
        this.set("beingSaved", true);

        if (!Ember.isEmpty(this.get("model.via.id"))) {
          this.set("model.via", Number(this.get("model.via.id")));
        }

        this.get("model").save().then(function () {
          if (self.get("model.shoppingCart")) {
            self.get("store").findRecord("shopping-cart", self.get("model.shoppingCart.id"));
          }

          self.sendAction("reloadModel");
          self.get("flashMessages").success(self.get("intl").t("drugs.messages.createdSuccess"));
          self.close();
        }).catch(function (result) {
          self.set("beingSaved", false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set("drugTypeID", null);
        this.set("beingSaved", false);
        this.set("selectedDrugType", null);
      }
    }
  });

  _exports.default = _default;
});