define("crm/controllers/organizations/organization/equipment-types/equipment-type/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    actions: {
      createEquipmentType: function createEquipmentType() {
        this.set("disabledSubmission", true);
        var self = this;
        this.get("model").save().then(function () {
          self.set("disabledSubmission", false);
          self.transitionToRoute('organizations.organization.equipment-types');
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledSubmission", false);
        });
      },
      canceled: function canceled() {
        this.transitionToRoute('organizations.organization.equipment-types');
      }
    }
  });

  _exports.default = _default;
});