define("crm/routes/organizations/organization/item-adjustments/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      return {};
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var organization = this.modelFor("organizations.organization");
      model.organization = organization;
      this.store.query("warehouse", {
        organization: organization.get("id")
      }).then(function (warehouses) {
        controller.set("warehouses", warehouses);

        if (warehouses.get("length") == 1) {
          controller.set("warehouse", warehouses.objectAt(0));
        }
      });
      this.store.findAll("adjustment-reason").then(function (adjustmentReasons) {
        if (!organization.get("hasInternalConsumption")) {
          adjustmentReasons.removeObject(adjustmentReasons.filterBy("code", "05").objectAt(0));
        }

        controller.set("adjustmentReasons", adjustmentReasons);
        controller.set("adjustmentReason", adjustmentReasons.filterBy("code", "10").objectAt(0));
      });
      controller.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
      controller.set("todayDate", (0, _moment.default)().format("YYYY-MM-DD"));
      this.store.query("item-type", {
        company: organization.get("company.id")
      }).then(function (itemTypes) {
        if (!Ember.isEmpty(itemTypes)) {
          itemTypes.forEach(function (itemType) {
            Ember.set(itemType, "name", self.get('intl').t("itemTypes.translation." + itemType.get("code")));
          });
        }

        controller.set("itemTypes", itemTypes);
      });
      this.store.findRecord("organizationPet", organization.get("id")).then(function (organizationPet) {
        controller.set("organizationPet", organizationPet);

        if (organizationPet.get("defaultWarehouse")) {
          controller.set("warehouse", organizationPet.get("defaultWarehouse"));
        }
      });
      controller.set("lines", []);
    }
  });

  _exports.default = _default;
});