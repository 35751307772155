define("crm/routes/organizations/organization/group/suppliers-current-accounts", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    intl: Ember.inject.service(),
    model: function model() {
      var companyID = this.modelFor("organizations.organization").get("company.id");
      var self = this;
      return Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseInvoices/currentAccount" + "?company=" + companyID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      }).then(function (lines) {
        return lines.map(function (i) {
          return {
            id: i[0],
            name: i[1],
            vat: i[2],
            count: i[3]
          };
        });
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("pages.currentAccounts")
      });
    }
  });

  _exports.default = _default;
});