define("crm/routes/organizations/organization/internments/internment/shopping-carts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: "organizations/organization/internments/internment/shoppingCarts/index",
    model: function model() {
      var internment = this.modelFor("organizations.organization.internments.internment");
      return this.store.query("internment-shopping-cart", {
        internment: internment.get("id")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var organization = this.modelFor("organizations.organization");
      controller.set("internment", this.modelFor("organizations.organization.internments.internment"));
      controller.set("organization", organization);
    }
  });

  _exports.default = _default;
});