define("crm/components/exam-edit-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    disabledAction: false,
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    sortedLines: Ember.computed("model.lines.@each.orderNumber", function () {
      if (!Ember.isEmpty(this.get("model.lines"))) {
        return this.get("model.lines").sortBy("orderNumber");
      }
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        var self = this;
        this.set("doneSeed", (0, _moment.default)());
        this.set("doneDate", (0, _moment.default)());
        this.get("store").query("exam-line", {
          exam: this.get("model.id")
        }, {
          reload: true
        });
        this.get("store").query("item-item", {
          parent: this.get("model.type.id")
        }).then(function (children) {
          if (!Ember.isEmpty(children)) {
            children.forEach(function (itemItem) {
              self.get("store").findRecord("item", itemItem.get("child.id")).then(function (savedItem) {
                if (savedItem.get("parameter.id") && self.get("model.patient.species.id")) {
                  self.get("store").query("equipment-parameter", {
                    parameter: savedItem.get("parameter.id"),
                    species: self.get("model.patient.species.id"),
                    organization: self.get("organization.id")
                  });
                }
              });
            });
          }

          self.get("store").findRecord("item", self.get("model.type.id")).then(function (savedItem) {
            if (savedItem.get("parameter.id") && self.get("model.patient.species.id")) {
              self.get("store").query("equipment-parameter", {
                parameter: savedItem.get("parameter.id"),
                species: self.get("model.patient.species.id"),
                organization: self.get("organization.id")
              });
            }
          });
        });
      }
    },
    close: function close() {
      Ember.$("#modal-exam-edit").modal("hide");
    },
    actions: {
      imageUploaded: function imageUploaded(savedFile) {
        var fileEmberObj = this.get("store").createRecord("file-exam", savedFile);
        this.get("model.files").pushObject(fileEmberObj);
      },
      edit: function edit() {
        var self = this;
        this.set("disabledAction", true);
        var j = 0;
        var i = 0;

        if (this.get("model.subTaskItems")) {
          for (i; i < this.get("model.subTaskItems.length"); i++) {
            var subTaskItem = this.get("model.subTaskItems").objectAt(i);

            if (subTaskItem.get("val")) {
              j++;
              subTaskItem.save();
            }
          }
        }

        if (i == j) {
          this.set("model.status", 1);
          this.set("model.doneDate", self.get("doneDate"));
          this.get("model").save().then(function () {
            if (!Ember.isEmpty(self.get("model.lines"))) {
              self.get("model.lines").forEach(function (line) {
                if (!Ember.isEmpty(line.get("notes"))) {
                  line.save();
                }
              });
            }

            self.sendAction("reloadModel");
            self.close();
          }).catch(function (error) {
            self.get("flashMessages").danger(error);
          });
        } else {
          if (this.get("model.notes")) {
            this.get("model").save();
            this.sendAction("reloadModel");
            this.close();
          }
        }
      },
      fileUploaded: function fileUploaded(savedFile) {
        var fileEmberObj = this.get("store").createRecord("fileExam", savedFile);
        this.get("model.files").pushObject(fileEmberObj);
      },
      createTask: function createTask() {
        this.sendAction("openModal", {
          entity: "task",
          action: "create",
          model: {
            exam: this.get("model")
          }
        });
        this.close();
      },
      canceled: function canceled() {
        this.close();
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          this.get("model").destroyRecord().then(function () {
            return self.close();
          }).catch(function (e) {
            return self.get("es").handle(e);
          });
        }
      },
      reset: function reset() {
        this.set("disabledAction", false);
        this.set("model", {});
        this.set("model.attachment", null);
        this.set("data", null);
        this.set("fileName", null);
        this.set("doneSeed", (0, _moment.default)());
        this.set("doneDate", (0, _moment.default)());
      }
    }
  });

  _exports.default = _default;
});