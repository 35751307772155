define("crm/components/item-simplified-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    es: Ember.inject.service(),
    disabledSubmisssionBrandNew: false,
    close: function close() {
      Ember.$("#modal-item-simplified-create").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      addItem: function addItem() {
        this.get('itemsToCreate').addObject({});
      },
      removeItem: function removeItem(item) {
        this.get('itemsToCreate').removeObject(item);
      },
      setParameter: function setParameter(parameter) {
        this.set('model.parameter', parameter);
        this.set("model.name", parameter.get("description"));
      },
      create: function create() {
        var self = this;
        this.set('disabledItemSimplifiedNew', true);
        this.set("model.organization", self.get("organization"));
        this.set("model.enable", true);
        this.get("store").createRecord("item", this.get("model")).save().then(function (itemCreated) {
          self.get("store").createRecord("itemItem", {
            child: itemCreated,
            quantity: 1,
            locked: false,
            parent: self.get("model.parentItem")
          }).save().then(function () {
            self.close();
            self.sendAction("reloadModel");
            self.get("flashMessages").success(self.get("intl").t("items.messages.createdSuccess"));
          }).catch(function (result) {
            self.get("es").errorReturned(result);
            self.set('disabledItemSimplifiedNew', false);
          });
        });
      },
      reset: function reset() {
        this.set('disabledItemSimplifiedNew', false);
      }
    }
  });

  _exports.default = _default;
});