define("crm/components/umt-number-variation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: Ember.computed("record.[]", function () {
      return Ember.get(this.get("record"), "value");
    }),
    valueOld: Ember.computed("record.[]", function () {
      return Ember.get(this.get("record"), "valueOld");
    }),
    valueDiff: Ember.computed("value", "valueOld", function () {
      if (!this.get("value") || !this.get("valueOld")) {
        return null;
      }

      return this.get("value") - this.get("valueOld");
    }),
    valueDiffPercent: Ember.computed("value", "valueOld", function () {
      if (!this.get("value") || !this.get("valueOld")) {
        return null;
      }

      return this.get("value") * 100 / this.get("valueOld") - 100;
    }),
    isPositive: Ember.computed("valueDiff", function () {
      return this.get("valueDiff") && this.get("valueDiff") > 0;
    }),
    isNegative: Ember.computed("valueDiff", function () {
      return this.get("valueDiff") && this.get("valueDiff") < 0;
    }),
    arrow: Ember.computed("isPositive", "isNegative", function () {
      return this.get("isPositive") ? "level-up" : this.get("isNegative") ? "level-down" : "tweet";
    }),
    color: Ember.computed("valueDiff", function () {
      var n = this.get("valueDiff");

      if (!n) {
        return "default";
      } else if (n < 0) {
        return "danger";
      } else if (n < 10) {
        return "warning";
      } else {
        return "success";
      }
    }),
    textColor: Ember.computed("color", function () {
      return "text-" + this.get("color");
    })
  });

  _exports.default = _default;
});