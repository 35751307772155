define("crm/controllers/organizations/organization/items/item/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["createdAfter", "createdBefore"],
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "customer",
        "title": this.get('intl').t("customers.customer.title")
      }, {
        "propertyName": "nif",
        "title": this.get('intl').t("customers.customer.nif"),
        "className": "column-vat"
      }, {
        "propertyName": "animal",
        "title": this.get('intl').t("patients.patient.title")
      }, {
        "propertyName": "chip",
        "title": this.get('intl').t("patients.patient.chip"),
        "className": "column-chip"
      }, {
        "propertyName": "item",
        "title": this.get('intl').t("items.item.title")
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": this.get('intl').t("invoices.invoice.date"),
        "className": "column-date"
      }, {
        "propertyName": "invoice",
        "title": this.get('intl').t("invoices.invoice.title"),
        "className": "column-invoice"
      }, {
        "propertyName": "phone",
        "title": this.get('intl').t("customers.customer.contacts.phone"),
        "className": "column-contact"
      }, {
        "propertyName": "email",
        "title": this.get('intl').t("customers.customer.contacts.email"),
        "className": "column-contact"
      }];
    }),
    actions: {
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      }
    }
  });

  _exports.default = _default;
});