define("crm/components/notes-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      openDetails: function openDetails() {
        this.sendAction("openModal", this.get("record"));
      },
      openEdit: function openEdit() {
        this.sendAction("openEdit", this.get("record"));
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t("intl.remove"))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});