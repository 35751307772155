define("crm/components/notes-list", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    filtered: Ember.computed("model.[]", function () {
      return Ember.isEmpty(this.get("model")) ? [] : this.get("model").filter(function (note) {
        return !Ember.isEmpty(note);
      }).sortBy("started").reverse();
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "started",
        "component": "umt-date",
        "title": this.get('intl').t("notes.date"),
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("notes.name")
      }, {
        "component": "notes-list-actions",
        "className": "column-actions-3"
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t("modelsTable.show") + " %@ - %@ " + this.get('intl').t("modelsTable.of") + " %@",
          "noDataToShow": this.get('intl').t("modelsTable.noDataToShow")
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "fa fa-times form-control-feedback",
        "clearAllFiltersIcon": ""
      });
    }),
    actions: {
      openModal: function openModal(model) {
        this.sendAction("openNoteDetails", model);
      },
      openEdit: function openEdit(model) {
        this.sendAction("openNoteEdit", model);
      }
    }
  });

  _exports.default = _default;
});