define("crm/components/customer-field-type-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    value: Ember.computed("intl", "catalogs.customerFieldTypes", function () {
      return this.get("catalogs.customerFieldTypes")[this.get("record.type")].name;
    })
  });

  _exports.default = _default;
});