define("crm/controllers/organizations/organization/purchase-orders/index", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    exporting: false,
    actions: {
      receivePurchaseOrder: function receivePurchaseOrder(purchaseOrder) {
        this.transitionToRoute("organizations.organization.purchase-invoices.new", {
          queryParams: {
            purchaseOrder: purchaseOrder.get("id")
          }
        });
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      transitionToRoute: function transitionToRoute(route, params) {
        this.transitionToRoute(route, params);
      },
      export: function _export() {
        var self = this;
        this.set("exporting", true);
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseOrders/xlsx?organization=" + self.get("organization.id") + "&issued=true",
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "purchaseOrders-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    }
  });

  _exports.default = _default;
});