define("crm/components/room-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    close: function close() {
      this.toggleProperty("closed");
    },
    actions: {
      createRoom: function createRoom() {
        this.set("disabledSubmission", true);
        var self = this;
        this.set("model.organization", this.get("organization"));
        this.get("store").createRecord("room", this.get("model")).save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.set("disabledSubmission", false);
        this.set("model", {});
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});