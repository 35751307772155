define("crm/helpers/margin-dimension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marginDimension = marginDimension;
  _exports.default = void 0;

  function marginDimension(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var aux = params[0];

      switch (aux) {
        case 0:
          return "%";

        case 1:
          return this.get("intl").t("icons.currency");

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});