define("crm/models/event", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    enterprise: _emberData.default.belongsTo("enterprise"),
    liaison: _emberData.default.belongsTo("liaison"),
    patient: _emberData.default.belongsTo("patient"),
    description: _emberData.default.attr("string"),
    started: _emberData.default.attr("localdatetime")
  });

  _exports.default = _default;
});