define("crm/components/box-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("box", this.get("model.id"), {
          reload: true
        }).catch(function (error) {
          self.get('flashMessages').danger(error);
        });
      }
    },
    close: function close() {
      Ember.$("#modal-box-edit").modal("hide");
    },
    actions: {
      editBox: function editBox() {
        this.set("disabledSubmission", true);
        var self = this;
        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function () {
          self.set("disabledSubmission", false);
        });
      },
      reset: function reset() {
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("disabledSubmission", false);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});