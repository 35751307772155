define("crm/components/note-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    maxDate: Ember.computed("model.patient", "model.customer", function () {
      if (this.get("model.patient") || this.get("model.customer")) {
        this.set("model.started", (0, _moment.default)().format("YYYY-MM-DD"));
        return (0, _moment.default)().endOf("day").format("YYYY-MM-DD");
      }
    }),
    close: function close() {
      Ember.$("#modal-note-create").modal("hide");
    },
    contacts: Ember.computed("model.customer.contacts.[]", "model.patient.owner.contacts.[]", function () {
      if (this.get("model.customer.contacts")) {
        return this.get("model.customer.contacts");
      }

      if (this.get("model.patient.owner.contacts")) {
        return this.get("model.patient.owner.contacts");
      }
    }),
    actions: {
      selectPatient: function selectPatient(value) {
        this.set("selectedPatient", value);
      },
      createNote: function createNote() {
        this.set('disabledSubmission', true);
        var self = this;

        if (!this.get("model.patient")) {
          this.set("model.customer", this.get("model.customer"));
          this.set("model.patient", this.get("selectedPatient"));
        } else {
          this.set("model.customer", null);
        }

        this.get("store").createRecord("note", this.get("model")).save().then(function () {
          self.close();
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set('disabledSubmission', false);
        });
      },
      reset: function reset() {
        this.set("model", {});
        this.set("selectedPatient", null);
        this.set("disabledSubmission", false);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});