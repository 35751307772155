define("crm/routes/invited", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel(transition) {
      if (this.get("session.isAuthenticated")) {
        this.get("session").invalidate();
        this.transitionTo("invited", transition.params.invite_code);
      }
    },
    model: function model(params) {
      return this.get("store").query("invite", {
        "code": params.invite_code
      }).then(function (invites) {
        var invite = invites.objectAt(0);
        return {
          name: invite.get("name"),
          email: invite.get("email"),
          company: invite.get("company"),
          organization: invite.get("organization"),
          role: invite.get("role"),
          profile: 1,
          avatar: {}
        };
      });
    }
  });

  _exports.default = _default;
});