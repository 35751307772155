define("crm/routes/organizations/organization/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (Ember.$("body").width() < 768) {
        Ember.$("body").addClass("mini-navbar");
      } else {
        Ember.$("body").removeClass("mini-navbar");
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var organizationID = model.get("id");
      var startedBefore = (0, _moment.default)().startOf("day").add(1, "days").format();
      this.store.query("task", {
        organization: organizationID,
        startedBefore: startedBefore,
        me: true,
        status: 1
      }).then(function (tasks) {
        controller.set('myTasksToday', tasks);
      });
      this.store.query("task", {
        organization: organizationID,
        startedBefore: startedBefore,
        noWorker: true,
        status: 1
      }).then(function (tasks) {
        controller.set('otherTasksToday', tasks ? tasks.filter(function (t) {
          return !t.get("name").match("Contactar:.+");
        }) : undefined);
      });
    }
  });

  _exports.default = _default;
});