define("crm/controllers/organizations/organization/appointments/configurations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    currentData: Ember.computed.alias("sessionAccount.currentData"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: ["day"],
    absentToday: Ember.computed("model.absences.[]", function () {
      if (Ember.isEmpty(this.get("model.absences"))) {
        return [];
      }

      return this.get("model.absences").filter(function (a) {
        if (a.get("status") === 1) {
          a.set("worker.absentToday", true);
          return true;
        }

        return false;
      });
    }),
    absentTodayOther: Ember.computed("model.absences.[]", function () {
      return Ember.isEmpty(this.get("model.absences")) ? [] : this.get("model.absences").filter(function (a) {
        return a.get("status") === 0;
      });
    }),
    shiftToday: Ember.computed("model.shifts.[]", function () {
      // REMOVE?
      return Ember.isEmpty(this.get("model.shifts")) ? [] : this.get("model.shifts");
    }),
    workersInShift: Ember.computed("shiftToday", function () {
      return Ember.isEmpty(this.get("shiftToday")) ? [] : this.get("shiftToday").uniqBy('worker.id').map(function (s) {
        return s.get("worker");
      }).sortBy("name");
    }),
    availableWorkers: Ember.computed("model.organization.settings.calendar_shift_strict", "model.organization.settings.calendar_absent_display", "model.organization.workers.[]", "shiftToday.[]", "absentToday.[]", function () {
      var self = this;
      var absent = self.get("absentToday"); // defined here to text-decorate for 'calendar_absent_display'

      if (this.get("model.organization.settings.calendar_shift_strict")) {
        return this.get("model.organization.workers").filterBy("enable", true).filter(function (w) {
          return !absent.map(function (a) {
            return a.get("worker");
          }).some(function (w2) {
            return w2.get("id") === w.get("id");
          });
        }).filter(function (w) {
          return self.get("shiftToday").map(function (a) {
            return a.get("worker");
          }).some(function (w2) {
            return w2.get("id") === w.get("id");
          });
        }).sortBy("name");
      } else if (this.get("model.organization.settings.calendar_absent_display")) {
        return this.get("model.organization.workers").filterBy("enable", true);
      } else {
        return this.get("model.organization.workers").filter(function (w) {
          return w.get("enable") && !absent.map(function (a) {
            return a.get("worker");
          }).find(function (w2) {
            return w2.get("id") === w.get("id");
          });
        });
      }
    }),
    otherToday: Ember.computed("availableWorkers.@each.appointable", "availableWorkers.@each.enable", "absentToday.[]", "shiftToday.[]", function () {
      // REMOVE?
      var self = this;
      return Ember.isEmpty(this.get("availableWorkers")) ? [] : this.get("availableWorkers").filter(function (w) {
        return !self.get("shiftToday").map(function (a) {
          return a.get("worker");
        }).some(function (w2) {
          return w2.get("id") === w.get("id");
        });
      }).sortBy("name");
    }),

    /* Os que vão para o calendário */
    activeWorkers: Ember.computed("model.organization.workers.@each.active", "availableWorkers.[]", "model.calendarFiltersWorkers.[]", "currentData.hiddenobody", "otherToday.[]", function () {
      var self = this;
      var activeWorkers = [];

      if (!this.get("currentData.hiddenobody")) {
        activeWorkers.push(this.get("nobody"));
      }

      if (this.get("model.calendarFiltersWorkers.length") && self.get("availableWorkers")) {
        this.get("model.calendarFiltersWorkers").sortBy("name").forEach(function (workerFilter) {
          workerFilter.set("worker.active", true);
          self.get("availableWorkers").forEach(function (available) {
            if (available.get("id") === workerFilter.get("worker.id")) {
              activeWorkers.addObject(workerFilter.get("worker"));
              /* Absences */

              if (self.get("absentTodayOther").some(function (a) {
                return a.get("worker.id") == workerFilter.get("worker.id");
              })) {
                workerFilter.get("worker").set("absentMaybe", true);
              } else {
                workerFilter.get("worker").set("absentMaybe", undefined);
              }
            }
          });
        });
      }

      return activeWorkers;
    }),
    activeRooms: Ember.computed("model.organization.rooms.@each.active", "model.calendarFiltersRooms.[]", function () {
      var self = this;
      var result = [];

      if (this.get("model.calendarFiltersRooms.length") && this.get("model.organization.rooms")) {
        this.get("model.calendarFiltersRooms").sortBy("name").forEach(function (filter) {
          self.get("model.organization.rooms").forEach(function (available) {
            if (available.get("id") === filter.get("room.id")) {
              filter.set("room.active", true);
              result.addObject(filter.get("room"));
            }
          });
        });
      }

      return result;
    }),
    rootVisitItemTypes: Ember.computed("model.organization.company.itemTypes.@each.isVisit", function () {
      if (this.get("model.organization.company.itemTypes.length")) {
        return this.get("model.organization.company.itemTypes").filter(function (a) {
          return a.get("isVisit") === true && !a.get("parent.id");
        }).sortBy("name");
      } else {
        return null;
      }
    }),
    enabledItemTypes: Ember.computed("rootVisitItemTypes.@each.enabled", "model.itemTypeFilters.@each.itemType", function () {
      var enabledItemTypes = [];

      if (this.get("model.itemTypeFilters.length")) {
        this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
          itemTypeFilter.set("itemType.enabled", true);
          enabledItemTypes.addObject(itemTypeFilter.get("itemType"));
        });
      }

      return enabledItemTypes.sortBy("name");
    }),
    actions: {
      toggleWorkerFilter: function toggleWorkerFilter(worker) {
        var self = this;
        var exists = false;
        var filterToDelete = undefined;

        if (this.get("model.calendarFiltersWorkers.length")) {
          this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
            if (workerFilter.get("worker.id") == Ember.get(worker, "id")) {
              exists = true;
              filterToDelete = workerFilter;
            }
          });
        }

        if (exists) {
          if (filterToDelete) {
            self.get("model.calendarFiltersWorkers").removeObject(filterToDelete);
            filterToDelete.set("worker.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-worker", {
            owner: this.get("currentWorker"),
            worker: worker
          }).save().then(function (calendarFilterWorker) {
            calendarFilterWorker.set("worker.active", true);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleWorkerFilterAll: function toggleWorkerFilterAll() {
        var self = this;

        if (this.get("model.calendarFiltersWorkers.length")) {
          this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
            self.get("otherToday").forEach(function (worker) {
              if (workerFilter.get("worker.id") == Ember.get(worker, "id")) {
                worker.set("itExists", true);
              }
            });
          });
        }

        this.get("otherToday").forEach(function (worker) {
          if (!worker.get("itExists")) {
            self.get("store").createRecord("calendar-filter-worker", {
              owner: self.get("currentWorker"),
              worker: worker
            }).save().then(function (calendarFilterWorker) {
              self.get("model.calendarFiltersWorkers").addObject(calendarFilterWorker._internalModel);
            }).catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        });
      },
      untoggleWorkerFilterAll: function untoggleWorkerFilterAll() {
        var self = this;
        this.get("model.calendarFiltersWorkers").forEach(function (workerFilter) {
          workerFilter.set("worker.active", false);
          workerFilter.destroyRecord().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        });
        this.get("model.calendarFiltersWorkers").forEach(function () {
          self.get("model.calendarFiltersWorkers").removeObject(self.get("model.calendarFiltersWorkers.firstObject"));
        });
      },
      toggleRoomFilter: function toggleRoomFilter(room) {
        var self = this;
        var exists = false;
        var filterToDelete = undefined;

        if (this.get("model.calendarFiltersRooms.length")) {
          this.get("model.calendarFiltersRooms").forEach(function (roomFilter) {
            if (roomFilter.get("room.id") == Ember.get(room, "id")) {
              exists = true;
              filterToDelete = roomFilter;
            }
          });
        }

        if (exists) {
          if (filterToDelete) {
            self.get("model.calendarFiltersRooms").removeObject(filterToDelete);
            filterToDelete.set("room.active", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-room", {
            owner: this.get("currentWorker"),
            room: room
          }).save().then(function (calendarFilterroom) {
            self.get("model.calendarFiltersRooms").addObject(calendarFilterroom._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      },
      toggleItemTypeFilter: function toggleItemTypeFilter(itemType) {
        var self = this;
        var exists = false;
        var filterToDelete = null;

        if (this.get("model.itemTypeFilters.length")) {
          this.get("model.itemTypeFilters").forEach(function (itemTypeFilter) {
            if (itemTypeFilter.get("itemType.id") == itemType.get("id")) {
              exists = true;
              filterToDelete = itemTypeFilter;
            }
          });
        }

        if (exists) {
          if (!Ember.isEmpty(filterToDelete)) {
            self.get("model.itemTypeFilters").removeObject(filterToDelete);
            filterToDelete.set("itemType.enabled", false);
            filterToDelete.destroyRecord().catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        } else {
          this.get("store").createRecord("calendar-filter-itemType", {
            owner: this.get("currentWorker"),
            itemType: itemType
          }).save().then(function (calendarFilterItemType) {
            self.get("model.itemTypeFilters").addObject(calendarFilterItemType._internalModel);
          }).catch(function (result) {
            self.get("es").errorReturned(result);
          });
        }
      }
    }
  });

  _exports.default = _default;
});