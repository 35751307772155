define("crm/controllers/organizations/organization/customers/customer/edit", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    disableBtn: false,
    isDirty: true,
    countries: Ember.computed(function () {
      return _uConstants.default.countries;
    }),
    adherenceReasons: Ember.computed("model.id", function () {
      return this.store.findAll('adherenceReason');
    }),
    origins: Ember.computed("model.id", function () {
      return this.store.findAll('origin');
    }),
    paymentTerms: Ember.computed("model.id", function () {
      return this.store.findAll('paymentTerm');
    }),
    actions: {
      selectSex: function selectSex(sex) {
        if (this.get("model.sex")) {
          if (this.get("model.sex") === sex) {
            this.set("model.sex", null);
          } else {
            this.set("model.sex", sex);
          }
        } else {
          this.set("model.sex", sex);
        }
      },
      update: function update() {
        var self = this;
        this.set('disableBtn', true);
        this.get('model').save().then(function (result) {
          self.send("updated", result.id);
          self.set('disableBtn', false);
        }).catch(function () {
          self.set('disableBtn', false);
        });
        this.get('address').save().then(function (result) {
          self.send("updated", result.id);
          self.set('disableBtn', false);
        }).catch(function () {
          self.set('disableBtn', false);
        });
      },
      canceled: function canceled() {
        this.get('model').rollbackAttributes();
        this.send("canceled", this.get('model.id'));
      }
    }
  });

  _exports.default = _default;
});