define("crm/components/open-shopping-carts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    showPay: false,
    debt: Ember.computed("model.@each.status", "model.@each.payableAmount", function () {
      var total = 0;
      this.get("model").forEach(function (shoppingCart) {
        if (shoppingCart.get("status") !== 1) {
          total += shoppingCart.get("payableAmount");
        }
      });
      return total.toFixed(2);
    }),
    totalAccumulated: Ember.computed("model.@each.status", "model.@each.payableAmount", function () {
      var total = 0;
      this.get("model").forEach(function (shoppingCart) {
        if (shoppingCart.get("status") == 1) {
          total += shoppingCart.get("payableAmount");
        }
      });
      return total.toFixed(2);
    }),
    filteredShopping: Ember.computed("model.@each.status", "showPay", function () {
      if (this.get("model")) {
        return this.get("showPay") ? this.get("model") : this.get("model").filter(function (s) {
          return s.get("status") !== 1;
        });
      }
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "propertyName": "created",
        "component": "umt-date",
        "title": this.get('intl').t("enums.chronos.dateUpper"),
        "className": "column-date",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "component": "open-shopping-carts-status",
        "title": this.get('intl').t("shoppingCarts.status")
      }, {
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "className": "column-price-large",
        "title": this.get('intl').t("documents.payableAmount")
      }, {
        "component": "open-shopping-carts-actions",
        "className": "column-actions-3",
        "pu-organization": this.get("organization")
      }];
    }),
    actions: {
      openDetails: function openDetails(shoppingCart) {
        this.sendAction("openModal", {
          entity: "open-shopping-carts",
          action: "details",
          model: shoppingCart
        });
      },
      openCloseShoppingCart: function openCloseShoppingCart(shoppingCart) {
        this.sendAction("openModal", {
          entity: "open-shopping-carts",
          action: "close",
          model: shoppingCart
        });
      }
    }
  });

  _exports.default = _default;
});