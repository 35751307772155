define("crm/models/todo-drug", ["exports", "ember-data", "crm/models/todo"], function (_exports, _emberData, _todo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todo.default.extend({
    kind: "drug",
    catalogs: Ember.inject.service(),
    viaTypes: Ember.computed.readOnly("catalogs.viaTypes"),
    concentration: _emberData.default.attr("number"),
    dosage: _emberData.default.attr("number"),
    unit: _emberData.default.attr("number"),
    via: _emberData.default.attr("number"),
    volumeKg: _emberData.default.attr("number"),
    volumeTotal: _emberData.default.attr("number"),
    // LP: prevent polymofrfic errors
    internment: _emberData.default.belongsTo("internment", {
      inverse: "drugs"
    }),
    nextTodo: _emberData.default.belongsTo("todo-drug", {
      inverse: "prevTodo"
    }),
    prevTodo: _emberData.default.belongsTo("todo-drug", {
      inverse: "nextTodo"
    }),
    fullName: Ember.computed(function () {
      return this.get("name") + " (" + this.get("volumeTotal") + " " + this.get("unitName") + " | " + this.get("viaName") + ")" + (Ember.isEmpty(this.get("description")) ? "" : "\n" + this.get("description"));
    }),
    viaName: Ember.computed("via", function () {
      return this.get("catalogs").viaType(this.get("via")) ? this.get("catalogs").viaType(this.get("via")).name : null;
    }),
    unitName: Ember.computed("unit", function () {
      return this.get("catalogs").unit(this.get("unit")) ? this.get("catalogs").unit(this.get("unit")).name : null;
    }),

    /*
     * Changed properties
     */
    volumeTotalHasChanged: Ember.computed("volumeTotal", "prevTodo.volumeTotal", function () {
      return this.get("prevTodo.id") && this.get("volumeTotal") !== this.get("prevTodo.volumeTotal");
    }),
    unitHasChanged: Ember.computed("unit", "prevTodo.unit", function () {
      return this.get("prevTodo.id") && this.get("unit") !== this.get("prevTodo.unit");
    }),
    viaHasChanged: Ember.computed("via", "prevTodo.via", function () {
      return this.get("prevTodo.id") && this.get("via") !== this.get("prevTodo.via");
    }),
    creatorHasChanged: Ember.computed("creator", "prevTodo.creator", function () {
      return this.get("prevTodo.id") && this.get("creator.id") !== this.get("prevTodo.creator.id");
    })
  });

  _exports.default = _default;
});