define("crm/routes/organizations/organization/customers/customer/open-items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var customer = this.modelFor("organizations.organization.customers.customer");
      customer.set("openItems", this.get("store").query("openItem", {
        customer: customer.get("id"),
        company: organization.get("company.id")
      }));
      self.get("store").findRecord("customer", customer.get("id"), {
        reload: true
      });
      return customer;
    }
  });

  _exports.default = _default;
});