define("crm/components/documents-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      var result = [{
        "className": "column-date-time",
        "propertyName": "created",
        "component": "umt-datetime2",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("invoices.invoice.emissionDate")
      }, {
        "className": "column-document",
        "propertyName": "name",
        "title": this.get('intl').t("documents.title")
      }, {
        "className": "column-method text-right",
        "propertyName": "paymentMethod"
      }, {
        "className": "column-price",
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "title": this.get('intl').t("invoices.invoice.total")
      }];

      if (!this.get("hideActions")) {
        result.addObject({
          "className": "column-actions-5",
          "component": "documents-list-actions"
        });
      }

      if (!this.get("hideStatus")) {
        result.splice(0, 0, {
          className: "column-icon",
          "component": "show-document-status"
        });
      }

      if (this.get("showCreator")) {
        result.splice(3, 0, {
          "propertyName": "creator.name",
          "title": this.get('intl').t("workers.worker.title")
        });
      }

      if (!this.get("hideName")) {
        result.splice(2, 0, {
          "propertyName": "customer.name",
          "title": this.get('intl').t("customers.customer.title"),
          "routeName": "organizations.organization.customers.customer",
          "routeProperty": "customer.id"
        });
      }

      return result;
    }),
    actions: {
      openModal: function openModal(arg) {
        this.get("openDetails")(arg);
      },
      annulDocument: function annulDocument(arg) {
        this.get("annulDocument")(arg);
      },
      showPdf: function showPdf(arg) {
        this.get("showPdf")(arg);
      },
      transitionToRoute: function transitionToRoute(route, params) {
        this.get("transitionToRoute")(route, params);
      },
      editDocumentModal: function editDocumentModal(arg) {
        this.get("editDocumentModal")(arg);
      }
    }
  });

  _exports.default = _default;
});