define("crm/routes/organizations/organization/equipments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return {};
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("manufacturers", this.store.query("manufacturer", {
        organization: controller.get("organization.id")
      }));
      controller.set("equipmentTypes", this.store.peekAll("equipment-type"));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("model", {});
      }
    }
  });

  _exports.default = _default;
});