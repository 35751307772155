define("crm/controllers/organizations/organization/purchase-memos/purchase-memo/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    displayAnnulButton: Ember.computed("model.status", "currentWorker.isAdmin", function () {
      return this.get("model.status") ? this.get("model.status") == 8 && this.get("currentWorker.isAdmin") : false;
    }),
    displayEditButton: Ember.computed("model.status", function () {
      return ![8].includes(this.get("model.status"));
    }),
    documentAnnul: Ember.computed("model.status", function () {
      var result = "";

      switch (this.get("model.status")) {
        case 3:
          result = "";
          break;

        case 4:
          result = this.get("intl").t("memos.memo.error");
          break;

        case 8:
          result = this.get("intl").t("memos.memo.annuled");
          break;

        default:
          break;
      }

      return result;
    }),
    filteredModels: Ember.computed("model.purchaseReceipts.[]", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.purchaseReceipts"))) {
        result.addObjects(this.get("model.purchaseReceipts"));
      }

      return result;
    }),
    actions: {
      annulDocument: function annulDocument(document) {
        this.send("openModal", {
          entity: "document",
          action: "annul",
          model: document
        });
      }
    }
  });

  _exports.default = _default;
});