define("crm/helpers/chronos-from-now-color", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sexDisplay = sexDisplay;
  _exports.default = void 0;

  function sexDisplay() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var diff = (0, _moment.default)().diff((0, _moment.default)(params[0])); // var jumkData = params[1]; // refresh trigger

      var step1 = params[2] ? params[2] : 1 * 60 * 1000;
      var step2 = params[3] ? params[3] : 15 * 60 * 1000;
      var step3 = params[4] ? params[4] : 30 * 60 * 1000;
      var result = "danger " + params[1];

      if (diff < step1) {
        result = "primary";
      } else if (diff < step2) {
        result = "default";
      } else if (diff < step3) {
        result = "warning";
      }

      return result;
    }
  });

  _exports.default = _default;
});