define("crm/components/status-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.status", function () {
      switch (this.get("record.status")) {
        case 0:
          return this.get('intl').t("icons.calendarMenu");

        case 1:
          return this.get('intl').t("icons.check");

        default:
          return "";
      }
    }),
    statusName: Ember.computed("record.status", function () {
      switch (this.get("record.status")) {
        case 0:
          return this.get('intl').t("tasks.status.pending");

        case 1:
          return this.get('intl').t("tasks.status.done");

        default:
          return "";
      }
    })
  });

  _exports.default = _default;
});