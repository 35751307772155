define("crm/components/purchase-request-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deletePurchaseRequestLine: function deletePurchaseRequestLine(line) {
        this.sendAction("deletePurchaseRequestLine", line);
      }
    }
  });

  _exports.default = _default;
});