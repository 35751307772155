define("crm/routes/organizations/organization/stats/financial-accounts", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor("organizations.organization");
      var organizationID = model.get("id");
      var begin = this.modelFor("organizations.organization.stats").createdAfter;
      var end = this.modelFor("organizations.organization.stats").createdBefore;
      model.set("createdAfter", begin);
      model.set("createdBefore", end);
      var createdBefore = (0, _moment.default)(model.get("createdBefore")).endOf("day").format();
      var createdAfter = (0, _moment.default)(model.get("createdAfter")).startOf("day").format();
      return Ember.RSVP.hash({
        financialAccounts: this.get("store").query("financialAccount", {
          organization: organizationID
        }),
        invoices: this.get("store").query("invoice", {
          status: null,
          organization: organizationID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        memos: this.get("store").query("memo", {
          status: null,
          organization: organizationID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        receipts: this.get("store").query("receipt", {
          status: null,
          organization: organizationID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        purchaseInvoices: this.get("store").query("purchase-invoice", {
          status: null,
          organization: organizationID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }),
        payments: this.get("store").query("payment", {
          status: null,
          organization: organizationID,
          createdAfter: createdAfter,
          createdBefore: createdBefore
        }) //financialAccountOpens: this.get("store").query("financial-account-open", { status: null, organization: organizationID, createdAfter: createdAfter, createdBefore: createdBefore }),
        //financialAccountCloses: this.get("store").query("financial-account-close", { status: null, organization: organizationID, createdAfter: createdAfter, createdBefore: createdBefore })

      }).then(function (r) {
        Ember.set(model, "financialAccounts", r.financialAccounts);
        Ember.set(model, "invoices", r.invoices);
        Ember.set(model, "memos", r.memos);
        Ember.set(model, "receipts", r.receipts);
        Ember.set(model, "purchaseInvoices", r.purchaseInvoices.forEach(function (doc) {
          doc.set("payableAmount", doc.get("payableAmount") * -1);
          return doc;
        }));
        Ember.set(model, "payments", r.payments.forEach(function (doc) {
          doc.set("payableAmount", doc.get("payableAmount") * -1);
          return doc;
        })); //set(model, "financialAccountOpens", r.financialAccountOpens);
        //set(model, "financialAccountCloses", r.financialAccountCloses);

        return model;
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
        controller.set("date", (0, _moment.default)().format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});