define("crm/controllers/organizations/organization/stats/visit-wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    waitByType: function waitByType(array) {
      return !array ? [] : array.map(function (s) {
        return {
          // 0: item id; 1: name; 2: count; 3: time;
          name: s[1],
          quantity: s[2],
          duration: s[3]
        };
      });
    },
    visitWaitByType: Ember.computed("appointmentsOverview.visitWaitByType.[]", function () {
      return this.waitByType(this.get("appointmentsOverview.visitWaitByType"));
    })
  });

  _exports.default = _default;
});