define("crm/components/appointment-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    calendarPeriodTypes: Ember.computed.alias("catalogs.calendarPeriodTypes"),
    control: 0,
    saving: false,
    selectedItemType: null,
    errorRoomOverlap: false,
    selectedVisitType: null,
    errorWorkerOverlap: false,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.oldAppointment.id")) {
        this.set("model.patient", this.get("model.oldAppointment.patient"));
        this.set("model.customer", this.get("model.oldAppointment.patient.owner"));
        this.set("model.type", this.get("model.oldAppointment.type"));
        this.set("model.observation", this.get("model.oldAppointment.observation"));
        this.set("model.category", this.get("model.oldAppointment.category"));
        this.set("model.room", this.get("model.oldAppointment.room"));
        this.set("model.shoppingCart", this.get("model.oldAppointment.shoppingCart"));

        if (!this.get("model.worker")) {
          this.set("model.worker", this.get("model.oldAppointment.worker"));
        }
      }

      if (this.get("model.patient.id")) {
        this.set("patientWeights", this.get("store").query("weight", {
          patient: this.get("model.patient.id")
        }));
      }

      if (this.get("model.itemType")) {
        this.set("model.category", this.get("model.itemType"));
      }

      if (this.get("model.type.id") && !this.get("model.category.id")) {
        var self = this;
        this.get("store").findRecord("item", this.get("model.type.id")).then(function (retrievedItem) {
          self.set("model.category", retrievedItem.get("type"));
          self.set("model.type", retrievedItem);
        });
      }

      if (this.get("model.organization.id")) {
        if (this.get("organization.campaignsFeature")) {
          // if hasSMS
          this.set("model.sendNotification", !["244", "245", "93", "94", "95", "272"].includes(this.get("model.organization.id")));
        }
      }

      if (this.get("model") && !this.get("model.start")) {
        this.set("model.start", (0, _moment.default)());
      }

      this.updateEndTime();
    },

    /*
     * Computed
     */
    dateRepetitionMax: Ember.computed("model.start", "selectedPeriodType", function () {
      if (this.get("model.start") && this.get("selectedPeriodType")) {
        switch (this.get("selectedPeriodType").id) {
          case "d":
            return (0, _moment.default)(this.get('model.start')).add(1, "week").format('YYYY-MM-DD');

          case "w":
            return (0, _moment.default)(this.get('model.start')).add(1, "month").format('YYYY-MM-DD');

          case "m":
            return (0, _moment.default)(this.get('model.start')).add(1, "year").format('YYYY-MM-DD');

          default:
            return "2090-01-01";
        }
      } else {
        return "2090-01-01";
      }
    }),
    appointableWorkers: Ember.computed("organization.workers.@each.appointable", "model.workers.[]", function () {
      if (this.get("model.workers")) {
        return this.get('model.workers').filterBy('appointable', true);
      } else if (this.get('organization.workers.length')) {
        return this.get('organization.workers').filterBy('appointable', true);
      }
    }),
    duration: Ember.computed('model.start', 'model.end', function () {
      return this.get("model.end") && this.get("model.start") ? this.get("model.end").diff(this.get("model.start"), "minutes") : null;
    }),
    visitTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      return this.get("itemTypes") ? this.get('itemTypes').filter(function (type) {
        return type.get('isVisit') && !type.get('parent.id');
      }) : null;
    }),
    filteredItems: Ember.computed("organization.company.id", "model.category.id", function () {
      return this.get("organization.company.id") && this.get('model.category.id') ? this.get("store").query("item", {
        company: this.get("organization.company.id"),
        type: this.get("model.category.id"),
        sellable: true
      }) : null;
    }),
    displayWorkerOverlap: Ember.computed("errorWorkerOverlap", function () {
      return this.get("errorWorkerOverlap");
    }),
    displayRoomOverlap: Ember.computed("errorRoomOverlap", function () {
      return this.get("errorRoomOverlap");
    }),
    hideIcon: Ember.computed("model.category.id", "model.patient.id", function () {
      return !(this.get("model.category.id") && this.get("model.patient.id"));
    }),
    disabledAction: Ember.computed("model.category.id", "model.customer.id", "model.observation", "duration", "saving", function () {
      return !this.get("model.customer") && !this.get("model.observation") || !this.get("model.category.id") || this.get("duration") < 1 || this.get("saving");
    }),

    /*
     * Functions
     */
    reset: function reset() {
      this.set('saving', false);
      this.set('errorRoomOverlap', null);
      this.set('errorWorkerOverlap', null);
      this.set('selectedItems', []);
      Ember.$('select').prop('selectedIndex', 0);
      this.set('queryParams', null);
      this.set('control', 0);
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    updateEndTime: function updateEndTime() {
      if (this.get("model.start")) {
        var start = (0, _moment.default)(this.get("model.start"));

        if (this.get("model.fixedDuration")) {
          // fixedDuration
          this.set("model.end", start.add(this.get("model.fixedDuration"), "minutes"));
        } else if (this.get("model.oldAppointment.start") && this.get("model.oldAppointment.end")) {
          // oldAppointment exists
          this.set('model.end', start.add((0, _moment.default)(this.get("model.oldAppointment.end")).diff(this.get("model.oldAppointment.start"), "minutes"), "minutes"));
        } else {
          // go for defaults
          var cits = this.get("model.organization.company.citMap");

          if (cits && cits[this.get("model.category.id")] && cits[this.get("model.category.id")].get("defaultDurationCompanyItemType")) {
            if (this.get("model.type.defaultDurationItem")) {
              this.set('model.end', start.add(cits[this.get("model.type.id")].get("defaultDurationCompanyItemType"), 'minutes'));
            } else {
              this.set('model.end', start.add(cits[this.get("model.category.id")].get("defaultDurationCompanyItemType"), 'minutes'));
            }
          } else {
            this.set('model.end', start.add(this.get("model.organization.visitDuration"), 'minutes'));
          }
        }
      } else if (this.get("model")) {
        // Resert - no start
        this.set('model.end', null);
      }
    },

    /*
     * Actions
     */
    actions: {
      setStart: function setStart(start) {
        if (start && this.get("model.start") && this.get("model.end")) {
          this.set("model.end", (0, _moment.default)(start).add((0, _moment.default)(this.get("model.end")).diff(this.get("model.start"), "minutes"), "minutes"));
          this.set("model.start", start); // can only set after update
        } else {
          this.set("model.start", start); // set before update

          this.updateEndTime();
        }
      },
      setEnd: function setEnd(end) {
        if (end) {
          if (this.get("model.start")) {
            var start = (0, _moment.default)(this.get("model.start"));

            if (end.isBefore(start, "days")) {
              end.set("day", start.days() + 1);
            }

            this.set("model.fixedDuration", end.diff(start, "minutes"));
          }

          this.set("model.end", end);
        } else {
          this.set("model.end", null);
        }
      },
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
        this.set("model.customer", null);
      },
      canceled: function canceled() {
        this.close();
      },
      setCategory: function setCategory(value) {
        this.set("model.category", value);
        this.set("model.type", null);
        this.updateEndTime();
      },
      setType: function setType(type) {
        this.set("model.type", type);
        this.updateEndTime();
      },
      setCustomer: function setCustomer(customer) {
        this.set("model.customer", customer);
      },
      create: function create() {
        this.set("saving", true);
        var self = this; // period

        if (this.get("selectedPeriodType") && this.get("selectedPeriodType.id")) {
          this.set("model.periodType", this.get("selectedPeriodType.id"));
        }

        if (this.get("model.periodUntil")) {
          this.set("model.periodUntil", (0, _moment.default)(this.get("model.periodUntil")).endOf("day").format());
        } // Skip Notifications


        if (this.get("model.organization.campaignsFeature")) {
          this.set("model.skipNotification", !this.get("model.sendNotification"));
        }

        if (this.get("model.shouldCheckin")) {
          this.set("model.status", 2);
          this.set("model.arrivalTime", (0, _moment.default)().format());
        } else {
          this.set("model.status", 1);
        } // Cleanup


        this.set("model.manualEnd", undefined);
        var newAppointment = this.get('store').createRecord('appointment', this.get('model'));
        newAppointment.save().then(function (savedAppointment) {
          if (self.get('model.oldAppointment')) {
            self.set('model.oldAppointment.status', 11);
            self.get('model.oldAppointment').save();
            self.sendAction('resetQueryParams');
          }

          if (savedAppointment.get('consultation.id')) {
            self.sendAction('transitionToRouteID', 'organizations.organization.consultations.consultation', savedAppointment.get('consultation.id'));
          } else if (self.get("model.goTo")) {
            self.sendAction('transitionToRouteID', self.get("model.goTo"), self.get("model.goToId"));
          } else {
            self.sendAction("created", self.get("model.shouldCheckin"));
          }

          self.close();
        }).catch(function (result) {
          if (result.errors[0].worker && result.errors[0].worker[0].includes("overlap")) {
            self.set("errorWorkerOverlap", true);
          } else if (result.errors[0].room && result.errors[0].room[0].includes("overlap")) {
            self.set("errorRoomOverlap", true);
          } else {
            self.get("es").handle(result);
          }

          self.get("store").unloadRecord(newAppointment);
          self.set("saving", false);
        });
      },
      reset: function reset() {
        this.reset();
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$('#openAssociatePatientOnAppointment').modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction("openEditPatientModal", model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setWorker: function setWorker(worker) {
        this.set("model.worker", worker);
        this.set("errorWorkerOverlap", false);
      },
      setRoom: function setRoom(room) {
        this.set("model.room", room);
        this.set("errorRoomOverlap", false);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        Ember.$("#modal-appointment-create").modal("hide");
        this.sendAction('openCreateAppointmentModal', model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});