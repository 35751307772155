define("crm/components/campaign-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      delete: function _delete(id) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.sendAction("delete", id);
        }
      }
    }
  });

  _exports.default = _default;
});