define("crm/controllers/organizations/organization/consultations/consultation/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    drugs: Ember.computed("model.id", function () {
      return this.get("model.id") ? this.get("store").query("drug", {
        consultation: this.get("model.id")
      }) : [];
    }),
    periodics: Ember.computed("model.patient.id", function () {
      return this.get("model.patient.id") ? this.get("store").query("periodic", {
        patient: this.get("model.patient.id")
      }) : [];
    }),
    actions: {
      openMDCDetails: function openMDCDetails(exam) {
        this.send('openModal', {
          entity: "exam",
          action: "details",
          model: exam
        });
      },
      openDrugDetails: function openDrugDetails(drug) {
        this.send("openModal", {
          entity: "drug",
          action: "details",
          model: drug
        });
      },
      openPeriodicDetails: function openPeriodicDetails(periodic) {
        this.send("openModal", {
          entity: "periodic",
          action: "details",
          model: periodic
        });
      }
    }
  });

  _exports.default = _default;
});