define("crm/routes/organizations/organization/group/purchase-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('read purchase')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    }
  });

  _exports.default = _default;
});