define("crm/routes/organizations/organization/internments/overview-shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query("internment", {
        organization: this.modelFor('organizations.organization').get("id"),
        open: true
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      model.forEach(function (internment) {
        self.store.findRecord("internment", internment.get("id"));
      });
    }
  });

  _exports.default = _default;
});