define("crm/controllers/organizations/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly("sessionAccount.currentUser"),
    isGod: Ember.computed.readOnly("sessionAccount.currentUser.isGod"),
    dayTime: Ember.computed(function () {
      var h = (0, _moment.default)().hours();

      if (h > 20 || h < 6) {
        return "night";
      } else if (h > 12) {
        return "afterNoon";
      } else {
        return "morning";
      }
    }),
    filteredModel: Ember.computed("model.@each.companyID", function () {
      return this.get("model");
    }),
    actions: {
      toggleEnable: function toggleEnable(organization) {
        organization.set("enable", !organization.get("enable"));
        organization.save();
      }
    }
  });

  _exports.default = _default;
});