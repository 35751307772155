define("crm/models/debt", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    amount: _emberData.default.attr(),
    customer: _emberData.default.belongsTo("customer"),
    dueDate: _emberData.default.attr("localdate"),
    currentDebtStatus: _emberData.default.attr("string"),
    notes: _emberData.default.attr(),
    debtDebtStatuses: _emberData.default.hasMany("debt-debt-status", {
      inverse: "debt"
    }),
    currentDebtDebtStatus: Ember.computed("debtDebtStatuses.[]", "debtDebtStatuses.@each.created", "debtDebtStatuses.@each.debtStatus", function () {
      if (!Ember.isEmpty(this.get("debtDebtStatuses"))) {
        return this.get("debtDebtStatuses").sortBy("created").reverse().objectAt(0);
      }
    })
  });

  _exports.default = _default;
});