define("crm/routes/organizations/organization/exams/exam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      this.set("breadCrumb", {
        finalTitle: model.get("name")
      });
    }
  });

  _exports.default = _default;
});