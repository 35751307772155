define("crm/controllers/organizations/organization/edit", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    countries: _uConstants.default.countries,
    countryName: Ember.computed('model.country', function () {
      var _this = this;

      return this.get('countries').find(function (item) {
        return item.code === _this.get('model.country');
      }).name;
    }),
    clinicUpdate: [],

    /*
     * Computed properties
     */
    isDirty: Ember.computed("clinicUpdate.[]", function () {
      return true;
      /* TODO  button cancel*/
    }),
    actions: {
      update: function update() {
        var self = this;

        if (this.get('model.hasDirtyAttributes')) {
          this.get('model').save().then(function () {
            self.sendAction("cancel");
          }).catch(function () {});
        } else {
          this.sendAction("cancel");
        }
      },
      imageUpdated: function imageUpdated() {
        this.sendAction("imageUpdated");
      },
      cancel: function cancel() {
        var self = this;
        var clinic = this.get('store').peekRecord('clinic', self.get('model.id'));
        clinic.set('model', self.get('model'));

        if (confirm(this.get('intl').t('intl.unsavedChanges'))) {
          if (this.get("model.hasDirtyAttributes")) {
            clinic.rollbackAttributes();
          }

          this.sendAction("cancel");
        }
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          var self = this;
          var clinic = this.get('model');
          var result = [];

          for (var i = 0; i < clinic.get('workers.length'); i++) {
            result.push(clinic.get('workers').objectAt(i));
          }

          clinic.deleteRecord();
          clinic.save().then(function () {
            for (var j = 0; j < result.get('length'); j++) {
              self.get('store').unloadRecord(result.objectAt(j));
            }

            self.sendAction("routeDeleted");
          }).catch(function () {});
        }
      },
      setCountrySelectAnswer: function setCountrySelectAnswer(value) {
        if (value === null || value === undefined) {
          this.set('model.country', "PT");
        } else {
          this.set('model.country', value);
        }
      }
    }
  });

  _exports.default = _default;
});