define("crm/models/allergy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    patient: _emberData.default.belongsTo('patient')
  });

  _exports.default = _default;
});