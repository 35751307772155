define("crm/models/document", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    intl: Ember.inject.service(),
    payableAmount: _emberData.default.attr("number"),
    internalNotes: _emberData.default.attr("string"),
    status: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    lastLineGroup: Ember.computed("lines.@each.lineGroup", function () {
      var lineGroup = 0;
      this.get("lines").forEach(function (line) {
        if (!Ember.isEmpty(line.get("lineGroup")) && line.get("lineGroup") > lineGroup) {
          lineGroup = line.get("lineGroup");
        }
      });
      return lineGroup;
    }),
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },
    total4: Ember.computed("lines.each.totalVat", "getVat.vat.amount", function () {
      return Ember.isEmpty(this.get("lines")) ? null : this.round(this.get("lines").filter(function (line) {
        return line.get("getVat.vat.amount") == 4;
      }).reduce(function (acc, obj) {
        return acc + obj.get("totalVat");
      }, 0));
    }),
    total5: Ember.computed("lines.each.totalVat", "getVat.vat.amount", function () {
      return Ember.isEmpty(this.get("lines")) ? null : this.round(this.get("lines").filter(function (line) {
        return line.get("getVat.vat.amount") == 5;
      }).reduce(function (acc, obj) {
        return acc + obj.get("totalVat");
      }, 0));
    }),
    total6: Ember.computed("lines.each.totalVat", "getVat.vat.amount", function () {
      return Ember.isEmpty(this.get("lines")) ? null : this.round(this.get("lines").filter(function (line) {
        return line.get("getVat.vat.amount") == 6;
      }).reduce(function (acc, obj) {
        return acc + obj.get("totalVat");
      }, 0));
    }),
    total23: Ember.computed("lines.each.totalVat", "getVat.vat.amount", function () {
      return Ember.isEmpty(this.get("lines")) ? null : this.round(this.get("lines").filter(function (line) {
        return line.get("getVat.vat.amount") == 23;
      }).reduce(function (acc, obj) {
        return acc + obj.get("totalVat");
      }, 0));
    }),
    totalTax: Ember.computed("total4", "total5", "total6", "total23", function () {
      return this.get("total4") + this.get("total5") + this.get("total6") + this.get("total23");
    }),
    totalNoVat: Ember.computed("payableAmount", "totalTax", function () {
      return this.round(this.get("payableAmount") - this.get("totalTax"));
    })
  });

  _exports.default = _default;
});