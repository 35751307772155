define("crm/components/drug-new-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    es: Ember.inject.service(),
    beingSaved: false,
    catalogs: Ember.inject.service(),
    viaTypes: Ember.computed.alias("catalogs.viaTypes"),
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isEmpty(this.get("model.patient")) && !Ember.isEmpty(this.get("model.consultation"))) {
        this.set("model.patient", this.get("model.consultation.patient"));
      }
    },
    drugTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        var result = this.get("itemTypes").filter(function (type) {
          return type.get("isDrug") && Ember.isEmpty(type.get("parent.id"));
        });
      }

      if (!Ember.isEmpty(result)) {
        this.set("selectedDrugType", result.get(0));
      }

      if (result && result.length == 1) {
        return this.get("itemTypes").filter(function (type) {
          return type.get("parent.id") === result.get("firstObject.id");
        });
      } else {
        return result;
      }
    }),
    disabledSubmissionDrug: Ember.computed("model.type", "model.quantity", "beingSaved", function () {
      return Ember.isEmpty(this.get("model.type")) || Ember.isEmpty(this.get("model.quantity")) || this.get("model.quantity") <= 0 || this.get("beingSaved");
    }),
    drugQuantity: Ember.observer("model.[]", function () {
      if (this.get("model")) {
        this.set("model.quantity", 1);
      }
    }),
    selectedDrugTypeObserver: Ember.observer("selectedDrugType.id", "model.organization.company.id", function () {
      var self = this;

      if (this.get("selectedDrugType.id") && this.get("model.organization.company.id")) {
        this.get("store").query("item", {
          company: this.get("model.organization.company.id"),
          type: this.get("selectedDrugType.id"),
          sellable: true
        }).then(function (items) {
          self.set("filteredDrugs", items);

          if (items != null && items.get("length") == 1) {
            self.set("model.type", items.get("firstObject"));
          }
        });
      }
    }),
    close: function close() {
      Ember.$("#modal-drug-create").modal("hide");
    },
    actions: {
      setDrugType: function setDrugType(value) {
        this.set("model.type", value);
        this.get("store").query("item-unit", {
          item: value.get("id")
        });
      },
      selectDrugType: function selectDrugType(value) {
        if (value) {
          this.set("selectedDrugType", value);
          this.set("model.type", null);
        } else {
          if (!Ember.isEmpty(this.get("itemTypes"))) {
            var result = this.get("itemTypes").filter(function (type) {
              return type.get("isDrug") && Ember.isEmpty(type.get("parent.id"));
            });
          }

          this.set("selectedDrugType", result.get(0));
        }
      },
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        var self = this;
        this.set("beingSaved", true);
        this.set("model.name", this.get("model.type.name"));
        this.set("model.doneDate", (0, _moment.default)());

        if (!Ember.isEmpty(this.get("via"))) {
          this.set("model.via", this.get("via").id);
        }

        var newDrug = this.get("store").createRecord("drug", this.get("model"));
        newDrug.save().then(function () {
          self.close();
        }).catch(function (result) {
          self.set("beingSaved", false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set("model", {});
        this.set("drugTypeID", null);
        this.set("beingSaved", false);
        this.set("selectedDrugType", null);
      }
    }
  });

  _exports.default = _default;
});