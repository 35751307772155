define("crm/components/show-task-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    taskTime: Ember.computed('record.taskDate', function () {
      if ((0, _moment.default)(this.get('record.taskDate')).format('HH:mm') == "00:00") {
        return true;
      }
    }),
    lateTask: Ember.computed('record.taskDate', 'record.status', function () {
      if (this.get('record.status') == 1) {
        if ((0, _moment.default)(this.get('record.taskDate')).format('HH:mm') == "00:00") {
          return (0, _moment.default)(this.get('record.taskDate')).isBefore((0, _moment.default)().startOf('day'));
        } else {
          return (0, _moment.default)(this.get('record.taskDate')).isBefore((0, _moment.default)());
        }
      }
    })
  });

  _exports.default = _default;
});