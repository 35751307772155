define("crm/initializers/html-table-to-excel", ["exports", "ember-html-table-to-excel/initializers/html-table-to-excel"], function (_exports, _htmlTableToExcel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _htmlTableToExcel.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _htmlTableToExcel.initialize;
    }
  });
});