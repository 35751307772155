define("crm/components/order-line-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    actions: {
      openOrderLineDetails: function openOrderLineDetails(model) {
        if (Ember.isEmpty(this.get("hideLinkDetails"))) {
          this.sendAction('openDetails', model);
        }
      }
    }
  });

  _exports.default = _default;
});