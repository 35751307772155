define("crm/components/task-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //tagName: "appointment-card",
    intl: Ember.inject.service(),
    classNames: ["appointment-card-component"],
    eventIO: null,
    attributeBindings: ['idx'],
    idx: Ember.computed(function () {
      return 'task-card-' + this.get("model.id");
    }),
    done: Ember.computed("model.doneTaskDate", function () {
      return this.get("model.doneTaskDate");
    }),
    innerStyle: Ember.computed("resize", "model.top", "model.height", function () {
      return Ember.String.htmlSafe(this.get("resize") ? "position: absolute; top: " + this.get("model.top") + "px; height:" + this.get("model.height") + "px" : "");
    }),
    canDoTask: Ember.computed('model.doneTaskDate', 'model.worker.id', 'model.worker.isCurrentWorker', function () {
      return Ember.isEmpty(this.get("model.doneTaskDate")) && (this.get("model.worker.isCurrentWorker") || Ember.isEmpty(this.get("model.worker.id")));
    }),
    actions: {
      openDetails: function openDetails() {
        this.sendAction("openModal", {
          entity: "task",
          action: "details",
          model: this.get("model")
        });
      }
    }
  });

  _exports.default = _default;
});