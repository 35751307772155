define("crm/controllers/organizations/organization/customers/customer/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    filteredModels: Ember.computed('model.invoices.[]', 'model.orders.[]', 'model.quotations.[]', 'model.memos.[]', 'model.receipts.[]', 'model.payments.[]', function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices"));
      }

      if (!Ember.isEmpty(this.get("model.orders"))) {
        result.addObjects(this.get("model.orders"));
      }

      if (!Ember.isEmpty(this.get("model.quotations"))) {
        result.addObjects(this.get("model.quotations"));
      }

      if (!Ember.isEmpty(this.get("model.memos"))) {
        result.addObjects(this.get("model.memos"));
      }

      if (!Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts"));
      }

      if (!Ember.isEmpty(this.get("model.payments"))) {
        result.addObjects(this.get("model.payments"));
      }

      return result;
    }),
    columns: Ember.computed(function () {
      var result = [{
        "component": "show-document-status",
        "className": "column-icon"
      }, {
        "propertyName": "created",
        "component": "umt-date",
        "title": " ",
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("documents.document.title")
      }, {
        "title": this.get("intl").t("documents.paymentMethod"),
        "propertyName": "paymentMethod",
        "className": "column-status"
      }, {
        "title": this.get("intl").t("documents.payableAmount"),
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "className": "column-price-medium"
      }];
      return result;
    })
  });

  _exports.default = _default;
});