define("crm/routes/organizations/organization/group/patients/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      before: {
        refreshModel: true
      },
      after: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var companyID = this.modelFor("organizations.organization").get("company.id");
      var begin = params.before;
      var end = params.after;

      if (begin && end) {
        if ((0, _moment.default)(begin).diff(end, "days") <= this.get("dateLimit")) {
          return this.get("store").query("patient", {
            company: companyID,
            enable: true,
            createdBefore: begin,
            createdAfter: end
          });
        }
      }

      return [];
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get('intl').t("patients.patient.patientSince")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("company", this.modelFor("organizations.organization").get("company"));
    }
  });

  _exports.default = _default;
});