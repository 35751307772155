define("crm/components/god-well", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        var self = this;

        if (confirm("Tens a certeza??")) {
          if (confirm("Isto é super perigoso!!")) {
            if (this.get("model.modelName")) {
              this.get("store").peekRecord(this.get("model.modelName"), this.get("model.id")).destroyRecord().then(function () {
                return self.get("flashMessages").info("Deleted!");
              }, function (error) {
                return self.get("es").handle(error);
              });
            } else if (this.get("model._internalModel.modelName")) {
              this.get("store").peekRecord(this.get("model._internalModel.modelName"), this.get("model.id")).destroyRecord().then(function () {
                return self.get("flashMessages").info("Deleted!");
              }, function (error) {
                return self.get("es").handle(error);
              });
            } else {
              alert("Erro!!");
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});