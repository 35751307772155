define("crm/components/umt-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNameBindings: ['negative:text-danger'],
    number: Ember.computed("record.[]", "column.propertyName", function () {
      if (this.get("record") && this.get("column.propertyName")) {
        return this.get("record." + this.get("column.propertyName"));
      } else {
        return null;
      }
    }),
    negative: Ember.computed("number", function () {
      return this.get("number") && this.get("number") < 0;
    }),
    unit: Ember.computed("number", function () {
      return this.get("number") ? Math.trunc(this.get("number")) : 0;
    }),
    decimal: Ember.computed("unit", function () {
      return (this.get("number") - this.get("unit")).toFixed(2).split('.')[1];
    })
  });

  _exports.default = _default;
});