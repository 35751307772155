define("crm/components/internment/status-faces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),
    actions: {
      setStatus: function setStatus(value) {
        this.set('model.criticalStatus', this.get('model.criticalStatus') === value ? undefined : value);
        this.get("model").save();
      }
    }
  });

  _exports.default = _default;
});