define("crm/components/financial-account-open-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    openFinancialAccount: {},
    showContent: true,
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model")) {
        var self = this;
        this.get("store").findRecord("financial-account", this.get("model.id"), {
          reload: true
        }).then(function (financialAccount) {
          if (financialAccount.get("isOpen")) {
            self.set("showContent", false);
          }
        });
      }
    },
    close: function close() {
      Ember.$("#modal-financialAccountOpen-new").modal("hide");
    },
    actions: {
      create: function create() {
        var _this = this;

        var self = this;
        this.get("store").findRecord("financial-account", this.get("model.id"), {
          reload: true
        }).then(function (financialAccount) {
          if (financialAccount.get("isOpen")) {
            self.get('flashMessages').info(_this.get('intl').t('financialAccounts.messages.allreadyOpen'));
          } else {
            self.set("openFinancialAccount.organization", self.get("organization"));
            self.set("openFinancialAccount.financialAccount", self.get("model"));
            self.get("store").createRecord("financialAccountOpen", self.get("openFinancialAccount")).save().then(function () {
              self.sendAction("reloadModel");
              self.close();
            }).catch(function (result) {
              self.get("es").errorReturned(result);
            });
          }
        });
      },
      reset: function reset() {
        this.set("openFinancialAccount", {});
        this.set("disabledAction", false);
        this.set("showContent", true);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});