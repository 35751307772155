define("crm/components/umt-price-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNameBindings: ["negative:text-danger"],
    unit: Ember.computed("record", "column.originalDefinition.propertyName", function () {
      return this.get("column.originalDefinition.propertyName") && Ember.get(this.get("record"), this.get("column.originalDefinition.propertyName")) ? Math.trunc(Ember.get(this.get("record"), this.get("column.originalDefinition.propertyName"))) : null;
    }),
    formated: Ember.computed("unit", "record", "column.originalDefinition.propertyName", function () {
      return this.get("unit") || this.get("unit") == 0 ? this.get("unit").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace("-", "") : null;
    }),
    decimal: Ember.computed("unit", "record", "column.originalDefinition.propertyName", function () {
      return (Ember.get(this.get("record"), this.get("column.originalDefinition.propertyName")) - this.get("unit")).toFixed(2).split(".")[1];
    }),
    negative: Ember.computed("record", "column.originalDefinition.propertyName", function () {
      return Ember.get(this.get("record"), this.get("column.originalDefinition.propertyName")) && Ember.get(this.get("record"), this.get("column.originalDefinition.propertyName")) < 0;
    })
  });

  _exports.default = _default;
});