define("crm/abilities/consultation", ["exports", "crm/abilities/clinical"], function (_exports, _clinical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _clinical.default.extend({
    canCreate: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canUpdate: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    }),
    canDelete: Ember.computed('worker.role', function () {
      return this.is(this.get("veterinary"));
    })
  });

  _exports.default = _default;
});