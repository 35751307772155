define("crm/components/role-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'span',
    code: null,
    faClass: Ember.computed('code', function () {
      if (this.get("code") == 1) {
        return "fa-unlock text-primary";
      } else {
        return "fa-lock text-danger";
      }
    }),
    code2str: Ember.computed('code', function () {
      if (this.get("code")) {
        var code = this.get("code");
        var results = this.get("roles").filter(function (role) {
          return role.code === code;
        });
        return this.get('intl').t("models.role." + results[0].code);
      }
    }),
    roles: [{
      code: 0,
      name: "Unknown",
      value: "unknown"
    }, {
      code: 1,
      name: "Admin",
      value: "administrator"
    }, {
      code: 2,
      name: "Doctor",
      value: "veterinary"
    }, {
      code: 3,
      name: "Nurse",
      value: "nurse"
    }, {
      code: 4,
      name: "Auxiliar",
      value: "auxiliar"
    }, {
      code: 5,
      name: "Rececionista",
      value: "rececionista"
    }]
  });

  _exports.default = _default;
});