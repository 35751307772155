define("crm/models/drug", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    consultation: _emberData.default.belongsTo("consultation"),
    description: _emberData.default.attr("string"),
    doneDate: _emberData.default.attr("isodate"),
    notes: _emberData.default.attr("string"),
    patient: _emberData.default.belongsTo("patient"),
    quantity: _emberData.default.attr('number'),
    shoppingCart: _emberData.default.belongsTo("shopping-cart"),
    type: _emberData.default.belongsTo("item"),
    via: _emberData.default.attr("number")
  });

  _exports.default = _default;
});