define("crm/controllers/organizations/organization/group/patients/active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ["before", "after"],
    columns: Ember.computed('intl', function () {
      return [{
        "propertyName": "created",
        "title": this.get('intl').t("organizations.organization.created"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-datetime"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.name"),
        "routeName": "organizations.organization.patients.patient",
        "routeProperty": "id"
      }];
    }),
    filteredModel: Ember.computed("model.[]", function () {
      return this.get("model").map(function (x) {
        return {
          id: x[0],
          name: x[1],
          invoicesNumber: x[2],
          recentDate: x[3],
          invoicesTotal: x[4]
        };
      }).sortBy("name");
    })
  });

  _exports.default = _default;
});