define("crm/models/consumable-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    consumables: _emberData.default.hasMany('consumable'),
    organization: _emberData.default.belongsTo('organization'),
    hasActiveConsumables: Ember.computed("consumables.@each.active", function () {
      return this.get('consumables').filterBy('active');
    })
  });

  _exports.default = _default;
});