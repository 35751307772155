define("crm/models/purchase-memo", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    date: _emberData.default.attr("localdate"),
    motive: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    movesStock: _emberData.default.attr("boolean"),
    deletedReason: _emberData.default.attr("string"),
    paymentMethod: _emberData.default.attr("string"),
    stockDate: _emberData.default.attr("localdate"),
    purchaseInvoice: _emberData.default.belongsTo("purchase-invoice"),
    supplier: _emberData.default.belongsTo("supplier"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    lines: _emberData.default.hasMany("purchase-memo-line", {
      inverse: "purchaseMemo"
    })
  });

  _exports.default = _default;
});