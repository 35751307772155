define("crm/components/appointments-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "appointment-history",
          action: action,
          model: this.get("record")
        });
      }
    }
  });

  _exports.default = _default;
});