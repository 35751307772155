define("crm/components/supplier-edit", ["exports", "universe-gui/u-constants"], function (_exports, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    countries: _uConstants.default.countries,
    newSupplier: {},
    contact: {},
    address: {},
    rpContacts: [{}],
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (this.get("model.id")) {
        this.get("store").findRecord("supplier", this.get("model.id")).then(function (supplier) {
          _this.set("address.country", _this.get("countries").filterBy('code', supplier.get("country"))[0]);
        });
      }
    },
    close: function close() {
      this.send("reset");
      Ember.$("#modal-supplier-edit").modal("hide");
    },
    countryIsPortugal: Ember.computed("address.country", function () {
      return !Ember.isEmpty(this.get("address.country")) && this.get("address.country.code") === "PT";
    }),
    isRequired: Ember.computed("address.country", "address.street", "address.building", "address.zip", "address.town", function () {
      if (Ember.isEmpty(this.get("address.country")) && Ember.isEmpty(this.get("address.street")) && Ember.isEmpty(this.get("address.building")) && Ember.isEmpty(this.get("address.zip")) && Ember.isEmpty(this.get("address.town"))) {
        return false;
      } else {
        return true;
      }
    }),
    hasMoreThanOne: Ember.computed("rpContacts.[]", function () {
      if (this.get("rpContacts.length") > 1) {
        return true;
      } else {
        return false;
      }
    }),
    nifAlreadyExists: Ember.computed("countryIsPortugal", "model.vat", function () {
      if (!this.get("countryIsPortugal") || this.get("model.vat.length") >= 9) {
        return this.get("store").query("supplier", {
          company: this.get("organization.company.id"),
          vat: this.get("model.vat")
        });
      }
    }),
    usageNifMessage: Ember.computed("nifAlreadyExists.[]", "model.vat", function () {
      if (this.get("model.vat") && !Ember.isEmpty(this.get("nifAlreadyExists"))) {
        return this.get("nifAlreadyExists").get("firstObject.id") !== this.get("model.id");
      }
    }),
    disabledAction: Ember.computed("model.name", "model.vat", "usageNifMessage", function () {
      return Ember.isEmpty(this.get("model.name")) || Ember.isEmpty(this.get("model.country")) || Ember.isEmpty(this.get("model.vat")) || this.get("usageNifMessage");
    }),
    actions: {
      selectCountry: function selectCountry(country) {
        if (country) {
          this.set("address.country", country);
        }
      },
      addRpContact: function addRpContact() {
        this.get("rpContacts").addObject({});
      },
      removeRpContact: function removeRpContact(contact) {
        this.get("rpContacts").removeObject(contact);
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;

        if (this.get("organization")) {
          this.set("model.organization", this.get("organization"));
        }

        if (this.get("address.country") && this.get("address.country.code") !== this.get("model.country")) {
          this.set("model.country", this.get("address.country.code"));
        }

        this.get("model").save().then(function () {
          /*if(self.get("mobilePhone")) {
          self.set("contact.contactType", 1);
          self.set("contact.contact", self.get("mobilePhone"));
          self.set("contact.name", self.get("customer.name")); // TODO build the name on serverside | person name + some sufix
          self.set("contact.preferential", true);
          self.get("store").createRecord("contact", self.get("contact")).save();
          }
           if(self.get("email")) {
          self.set("contact.contactType", 3);
          self.set("contact.contact", self.get("email"));
          self.set("contact.name", self.get("customer.name"));
          self.set("contact.preferential", true);
          self.get("store").createRecord("contact", self.get("contact")).save();
          }
           if(self.get("address")) {
          self.get("store").createRecord("address", self.get("address")).save();
          }*/

          /*if(self.get("rpContacts")) {
          self.get("rpContacts").forEach(function(contact) {
          var newRpContact = self.get("store").createRecord("contact", {
          name: contact.name,
          contact: contact.contact,
          });
          newRpContact.save();
          })
          }*/
          self.get("flashMessages").success(self.get("intl").t("suppliers.messages.createdSuccess"));
          self.sendAction("reloadModel");
          self.close();
        }).catch(function () {
          self.get("store").unloadRecord(this.get("model"));
          self.set("disabledAction", false);
        });
      },
      reset: function reset() {
        this.set("model", {});
        this.set("contact", {});
        this.set("address", {});
        this.set("disabledAction", false);
        this.set("mobilePhone", null);
        this.set("email", null); //this.set("rpMobilePhone", null);
        //this.set("rpEmail", null);
        //this.set("rpName", null);
        //this.set("rpContacts", [{}]);
      },
      canceled: function canceled() {
        this.set("model", {});
        this.close();
      }
    }
  });

  _exports.default = _default;
});