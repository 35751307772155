define("crm/controllers/organizations/organization/workers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableBtn: false,
    employeesWithWorker: Ember.computed("model.employees.[]", "model.workers.[]", function () {
      return this.get("model.employees.length") && this.get("model.workers.length") ? this.get("model.workers").map(function (worker) {
        return worker.get("employee.id");
      }) : null;
    }),
    employeesToList: Ember.computed("model.employees.@each.name", "employeesWithWorker.[]", function () {
      var self = this;
      return this.get("model.employees.length") && this.get("employeesWithWorker.length") ? this.get("model.employees").filter(function (e) {
        return !self.get("employeesWithWorker").includes(e.get("id"));
      }).sortBy("user.name") : null;
    }),
    actions: {
      selectEmployee: function selectEmployee(employeeID) {
        employeeID ? this.set("selectedEmployee", this.store.findRecord("employee", employeeID, {
          reload: true
        })) : this.set("selectedEmployee", null);
      },
      create: function create() {
        if (!Ember.isEmpty(this.get("selectedEmployee"))) {
          var self = this;
          this.set("disableBtn", true);
          this.get("store").findRecord("user", this.get("selectedEmployee.user.id")).then(function (savedUser) {
            self.set("model.employee", self.get("selectedEmployee"));
            self.set("model.email", savedUser.get("email"));
            self.set("model.name", savedUser.get("name"));
            self.set("model.role", self.get("selectedEmployee.profile"));
            self.set("model.enable", true);
            var worker = self.get("store").createRecord("worker", self.get("model"));
            worker.save().then(function (result) {
              self.send("created", result.id);
              self.set("disableBtn", false);
            }).catch(function () {
              self.set("disableBtn", false);
              self.get("store").unloadRecord(worker);
            });
          });
        }
      },
      cancel: function cancel() {
        this.transitionToRoute("organizations.organization.workers");
      },
      setRoleSelectAnswer: function setRoleSelectAnswer(value) {
        this.set("model.role", value);
      }
    }
  });

  _exports.default = _default;
});