define("crm/models/purchase-invoice", ["exports", "ember-data", "crm/models/document"], function (_exports, _emberData, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Status
   *
   * 0 - Draft
   * 1 -
   */
  var _default = _document.default.extend({
    accountingAddress: _emberData.default.attr("string"),
    accountingName: _emberData.default.attr("string"),
    accountingTaxId: _emberData.default.attr("string"),
    billingID: _emberData.default.attr("string"),
    checkDate: _emberData.default.attr("localdate"),
    checkNumber: _emberData.default.attr("number"),
    date: _emberData.default.attr("localdate"),
    dueDate: _emberData.default.attr("localdate"),
    paidAmount: _emberData.default.attr("number"),
    discount: _emberData.default.attr("number"),
    deleted: _emberData.default.attr("isodate"),
    deletedReason: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    paymentMethod: _emberData.default.attr("string"),
    stockDate: _emberData.default.attr("localdate"),
    truePayableAmount: _emberData.default.attr("number"),
    financialAccount: _emberData.default.belongsTo("financial-account"),
    order: _emberData.default.belongsTo("order"),
    organization: _emberData.default.belongsTo("organization"),
    purchaseOrder: _emberData.default.belongsTo("purchase-order"),
    supplier: _emberData.default.belongsTo("supplier"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    lines: _emberData.default.hasMany("purchase-invoice-line", {
      inverse: "purchaseInvoice"
    }),
    open: Ember.computed("paidAmount", "truePayableAmount", function () {
      if (this.get("truePayableAmount")) {
        if (this.get("paidAmount")) {
          if (Number(this.get("paidAmount")) < Number(this.get("truePayableAmount"))) {
            return "Y";
          } else {
            return "";
          }
        } else {
          return "Y";
        }
      } else {
        return "";
      }
    }),
    openAmount: Ember.computed("paidAmount", "truePayableAmount", function () {
      var total = 0;

      if (this.get("truePayableAmount")) {
        if (this.get("paidAmount")) {
          total = Number(this.get("truePayableAmount")) - Number(this.get("paidAmount"));
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        } else {
          total = Number(this.get("truePayableAmount"));
        }
      }

      return total;
    })
  });

  _exports.default = _default;
});