define("crm/components/temperament-faces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    temperamentToShow: null,
    catalogs: Ember.inject.service(),
    temperaments: Ember.computed.alias("catalogs.temperaments"),
    selectedTemperament: Ember.computed("temperament", function () {
      if (this.get("temperament") === 1 || this.get("temperament") === 2 || this.get("temperament") === 3 || this.get("temperament") === 4) {
        this.set("temperamentToShow", 0);
      }

      if (this.get("temperament") === 5 || this.get("temperament") === 6 || this.get("temperament") === 7 || this.get("temperament") === 8) {
        this.set("temperamentToShow", 1);
      }

      if (this.get("temperament") === 9 || this.get("temperament") === 10 || this.get("temperament") === 11) {
        this.set("temperamentToShow", 2);
      }

      return this.get("temperaments")[this.get("temperamentToShow")];
    }),
    actions: {
      setTemperament: function setTemperament(value) {
        if (this.get("temperament") === value) {
          this.set("temperament", undefined);
        } else {
          this.set("temperament", value);
        }
      }
    }
  });

  _exports.default = _default;
});