define("crm/components/compound-product-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["u-models-table"],
    actions: {
      deleteCompoundProductItem: function deleteCompoundProductItem(item) {
        this.sendAction("deleteCompoundProductItem", item);
      },
      togglePriceLocked: function togglePriceLocked(itemItem) {
        if (Ember.isEmpty(itemItem.get("priceLocked")) || itemItem.get("priceLocked") == false) {
          itemItem.set("priceLocked", true);
        } else {
          itemItem.set("priceLocked", false);
          itemItem.set("salePrice", null);
        }
      },
      togglePriceLocked2: function togglePriceLocked2(itemItem) {
        if (Ember.isEmpty(itemItem.get("priceLocked2")) || itemItem.get("priceLocked2") == false) {
          itemItem.set("priceLocked2", true);
        } else {
          itemItem.set("priceLocked2", false);
          itemItem.set("salePrice2", null);
        }
      }
    }
  });

  _exports.default = _default;
});