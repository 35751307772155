define("crm/controllers/organizations/organization/listings/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["dateFrom", "dateTo"],
    actions: {
      search: function search(dates) {
        if (dates) {
          this.set("dateFrom", dates.nextDateStart);
          this.set("dateTo", dates.nextDateEnd);
        }
      }
    }
  });

  _exports.default = _default;
});