define("crm/models/file", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    location: _emberData.default.attr('string'),
    directory: _emberData.default.attr('string'),
    file: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization'),
    isImage: Ember.computed("name", function () {
      var str = this.get("name");
      var patt1 = /.+\.(jpg)|(gif)|(tif)|(png)|(bmp)/i;
      return str.match(patt1);
    }),
    isPdf: Ember.computed("name", function () {
      var str = this.get("name");
      var patt1 = /.+\.(pdf)/i;
      return str.match(patt1);
    }),
    url: Ember.computed("location", "directory", "file", function () {
      if (this.get("location") && this.get("directory") && this.get("file")) {
        return this.get("location") + this.get("directory") + this.get("file");
      } else if (this.get("location") && this.get("file")) {
        return this.get("location") + this.get("file");
      } else {
        return this.get("file");
      }
    })
  });

  _exports.default = _default;
});