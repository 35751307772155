define("crm/abilities/payment", ["exports", "crm/abilities/manager"], function (_exports, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manager.default.extend({
    canCreate: Ember.computed('featureNames.[]', function () {
      return this.hasFeature("sales");
    }),
    canUpdate: Ember.computed('featureNames.[]', function () {
      return this.hasFeature("sales");
    })
  });

  _exports.default = _default;
});