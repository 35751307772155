define("crm/routes/organizations/organization/purchase-orders/purchase-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.store.findRecord("purchase-order", params.purchase_order_id),
        item: this.store.query("item", {
          purchaseOrder: params.purchase_order_id
        }),
        units: this.store.query("item-unit", {
          purchaseOrder: params.purchase_order_id
        }),
        lines: this.store.query("purchase-order-line", {
          purchaseOrder: params.purchase_order_id
        })
      }).then(function (hash) {
        hash.lines.forEach(function (line) {
          line.set("enabled", !line.get("deleted"));
        });
        return hash.model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      return Ember.RSVP.hash({
        purchaseInvoices: this.store.query("purchase-invoice", {
          purchaseOrder: model.get("id")
        }),
        purchaseInvoiceLines: this.store.query("purchase-invoice-line", {
          purchaseOrder: model.get("id")
        }),
        purchaseRequests: this.store.query("purchase-request", {
          purchaseOrder: model.get("id")
        }),
        purchaseRequestLines: this.store.query("purchase-request-line", {
          purchaseOrder: model.get("id")
        })
      }).then(function (hash) {
        model.set("purchaseInvoices", hash.purchaseInvoices);
        model.set("purchaseRequests", hash.purchaseRequests);
      });
    }
  });

  _exports.default = _default;
});