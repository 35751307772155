define("crm/routes/organizations/organization/configurations/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var featuresOrg = organization.get("features");
      var featuresOrgIds = featuresOrg.map(function (f) {
        return f.get("id");
      });
      return Ember.RSVP.hash({
        settings: this.store.peekAll("setting"),
        features: this.store.peekAll("feature"),
        orgSetBool: organization.get("organizationSettings")
      }).then(function (r) {
        var result = [];
        var settingsBool = {};

        if (r.orgSetBool && r.orgSetBool.get("length")) {
          r.orgSetBool.forEach(function (b) {
            settingsBool[b.get("setting.id")] = b;
          });
        }

        var settings = r.settings.map(function (s) {
          return settingsBool[s.get("id")] ? settingsBool[s.get("id")] : {
            type: s,
            setting: s,
            organization: organization
          };
        });
        r.features.sortBy("office").forEach(function (f) {
          result.push({
            feature: f,
            fields: settings.filterBy("type.feature.id", f.get("id")),
            available: featuresOrgIds.includes(f.get("id"))
          });
        });
        return result;
      });
    }
  });

  _exports.default = _default;
});