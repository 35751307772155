define("crm/controllers/organizations/organization/hotel/stays/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ["date"],
    canDoStays: Ember.computed("can", function () {
      return this.get("can").can("do hotel");
    }),
    actions: {
      openStayNew: function openStayNew() {
        this.send("openModal", {
          entity: "stay",
          action: "new"
        });
      },
      updateModel: function updateModel(date) {
        this.set("date", (0, _moment.default)(date).startOf("day").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});