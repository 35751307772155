define("crm/components/show-appointment-actions", ["exports", "crm/templates/components/show-appointment-actions"], function (_exports, _showAppointmentActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _showAppointmentActions.default,
    actions: {
      sendAction: function sendAction(actionName, record) {
        Ember.get(this, 'sendAction')(actionName, record);
      }
    }
  });

  _exports.default = _default;
});