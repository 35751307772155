define("crm/models/physical-exam", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    intl: Ember.inject.service(),
    abdomen: _emberData.default.attr('string'),
    attitude: _emberData.default.attr('string'),
    pain: _emberData.default.attr('string'),
    heartRate: _emberData.default.attr('string'),
    respiratoryFrequency: _emberData.default.attr('string'),
    ganglia: _emberData.default.attr('string'),
    hydration: _emberData.default.attr('string'),
    mucous: _emberData.default.attr('string'),
    bloodPressure: _emberData.default.attr('string'),
    temperature: _emberData.default.attr('string'),
    capillaryRepletionTime: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    consultation: _emberData.default.belongsTo('consultation', {
      inverse: 'physicalExam'
    }),
    actions: _emberData.default.hasMany("action", {
      inverse: 'physicalExam'
    }),
    notEmpty: Ember.computed("abdomen", "attitude", "pain", "heartRate", "respiratoryFrequency", "ganglia", "hydration", "mucous", "bloodPressure", "temperature", "notes", function () {
      return this.get("abdomen") || this.get("attitude") || this.get("pain") || this.get("heartRate") || this.get("respiratoryFrequency") || this.get("ganglia") || this.get("hydration") || this.get("mucous") || this.get("bloodPressure") || this.get("temperature") || this.get("notes");
    }),
    isEmpty: Ember.computed("notEmpty", function () {
      return !this.get("notEmpty");
    }),
    htmlSafe: Ember.computed("abdomen", "attitude", "pain", "heartRate", "respiratoryFrequency", "ganglia", "hydration", "mucous", "bloodPressure", "temperature", "notes", function () {
      var result = "";
      var keys = ["abdomen", "ganglia", "attitude", "hydration", "pain", "mucous", "heartRate", "bloodPressure", "respiratoryFrequency", "temperature"];

      for (var i = 0; i < keys.length; i++) {
        var aux = keys[i];
        result += "<p><b>" + this.get("intl").t("monitorings.monitoring." + aux) + "</b><br>" + (this.get(aux) ? this.get(aux) : "&nbsp;") + "</p>";
      }

      return Ember.String.htmlSafe(result);
    }),
    displayHtml: Ember.computed("abdomen", "attitude", "pain", "heartRate", "respiratoryFrequency", "ganglia", "hydration", "mucous", "bloodPressure", "temperature", "notes", function () {
      var result;

      if (this.get("isEmpty")) {
        result = null;
      }

      var aa = "";
      var bb = "";
      var keys = ["abdomen", "ganglia", "attitude", "hydration", "pain", "mucous", "heartRate", "bloodPressure", "respiratoryFrequency", "temperature"];

      for (var i = 0; i < keys.length; i++) {
        var aux = keys[i];

        if (this.get(aux)) {
          if (i % 2) {
            bb += "<p><b>" + this.get("intl").t("monitorings.monitoring." + aux) + "</b><br>" + this.get(aux) + "</p>";
          } else {
            aa += "<p><b>" + this.get("intl").t("monitorings.monitoring." + aux) + "</b><br>" + this.get(aux) + "</p>";
          }
        }
      }

      if (aa === "" && bb === "") {
        result = null;
      } else if (aa === "") {
        result = bb;
      } else if (bb === "") {
        result = aa;
      } else {
        result = "<div class='row'>" + "<div class='col-xs-6 p-xs'>" + aa + "</div>" + "<div class='col-xs-6 p-xs'>" + bb + "</div>" + "</div>" + (this.get("notes") ? "<div class='no-margin p-xs well'>" + this.get("intl").t("monitorings.monitoring.notes") + ": " + this.get("notes") + "</div>" : "");
      }

      return result;
    })
  });

  _exports.default = _default;
});