define("crm/controllers/companies/company/edit", ["exports", "universe-gui/controllers/companies/company/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _edit.default;
    }
  });
});