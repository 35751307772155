define("crm/components/internment/discharge-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    saving: false,
    close: function close() {
      this.toggleProperty("closed");
    },
    disableButtons: Ember.computed("saving", function () {
      return this.get("saving");
    }),
    actions: {
      reset: function reset() {
        this.set("selectedWorker", undefined);
        this.toggleProperty("resetDate");
        this.set("saving", false);
      },
      cancel: function cancel() {
        this.close();
      },
      discharge: function discharge() {
        var self = this;
        this.set("saving", true);
        this.set("model.dischargeDate", this.get("selectedDate"));
        this.set("model.discharger", this.get("selectedWorker.employee.user"));
        this.get("model").save().then(function () {
          self.createAppointment();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
        });
      },
      setWorker: function setWorker(worker) {
        this.set("model.discharger", worker.get("employee.user"));
      }
    },
    createAppointment: function createAppointment() {
      var self = this;
      var itemType = this.get("store").peekRecord("item-type", 5); // Hospitalização TODO o internamento devia ter item e/ou itemType

      var appointment = {
        start: this.get("model.dischargeDate"),
        end: (0, _moment.default)(this.get("model.dischargeDate")).add("30", "m"),
        patient: this.get("model.patient"),
        worker: this.get("selectedWorker"),
        organization: this.get("model.organization"),
        customer: this.get("model.customer"),
        internment: this.get("model"),
        category: itemType,
        status: 1
      };
      this.get("store").createRecord("appointment", appointment).save().then(function () {
        self.close();
      }).catch(function (result) {
        self.get("es").errorReturned(result);
      });
    }
  });

  _exports.default = _default;
});