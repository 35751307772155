define("crm/components/stats-sales-item-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: "li",
    classNames: ["item-type-border", "list-group-item", "sales-item-line", "ellipsis"],
    classNameBindings: ['parent'],
    parent: Ember.computed("model.name", function () {
      var types = {};
      this.get("store").peekAll('item-type').map(function (t) {
        return types[t.get("name")] = t;
      });
      return this.get("model.name") ? types[this.get("model.name")] ? types[this.get("model.name")].get("codesStr") : "" : "";
    })
  });

  _exports.default = _default;
});