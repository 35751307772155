define("crm/controllers/organizations/organization/open-purchase-invoices/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ["dueDate"],
    init: function init() {
      this._super();

      this.set("dueDate", (0, _moment.default)().format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      return [{
        "propertyName": "dueDate",
        "component": "umt-date",
        "title": this.get("intl").t("purchaseInvoices.new.dueDate"),
        "sortDirection": "asc",
        "sortPrecedence": 1,
        "className": "column-date"
      }, {
        "title": this.get("intl").t("suppliers.supplier.title"),
        "propertyName": "supplier.name",
        "component": "title-models-table-display",
        "className": "column-small"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("purchaseOrders.name"),
        "component": "title-models-table-display"
      }, {
        "title": this.get("intl").t("documents.paymentMethod"),
        "propertyName": "paymentMethod",
        "className": "column-price"
      }, {
        "propertyName": "payableAmount",
        "title": this.get("intl").t("documents.payableAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "propertyName": "paidAmount",
        "title": this.get("intl").t("purchaseInvoices.new.paidAmount"),
        "component": "umt-price-display",
        "className": "column-price-medium"
      }, {
        "component": "open-purchase-invoices-actions",
        "className": "column-actions-1"
      }];
    })
  });

  _exports.default = _default;
});