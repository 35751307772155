define("crm/components/purchase-request-ibox", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    saving: false,
    linesColumns: Ember.computed("intl", function () {
      var result = [{
        "propertyName": "item.name",
        "title": this.get('intl').t("purchaseRequests.item"),
        "path": "organizations.organization.items.item",
        "routeProperty": "item.id"
      }, {
        "propertyName": "warehouse.internalDescription",
        "title": this.get("intl").t("warehouses.warehouse.title"),
        "path": "organizations.organization.warehouses.warehouse",
        "routeProperty": "warehouse.id"
      }, {
        "propertyName": "quantity",
        "title": this.get("intl").t("purchaseRequests.quantity")
      }];

      if (this.get("model.status") !== 0) {
        // porque tanto pode estar emitido ou em encomenda
        result.addObject({
          "className": "column-actions-1",
          "component": "procurement/purchase-entity-line-mark-done"
        });
      }

      return result;
    }),
    hasStatus: Ember.computed("model.status", function () {
      return [0].includes(this.get("model.status"));
    }),
    actions: {
      enableLine: function enableLine(line) {
        var self = this;
        Ember.$.ajax({
          method: "PUT",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseRequestLines/" + line.get("id") + "/undone",
          success: function success() {
            line.set("deleted", null);
            line.set("deletor", null);
            line.set("enabled", true);
          },
          error: function error(result) {
            self.get("es").handle(result);
          }
        });
      },
      disableLine: function disableLine(line) {
        line.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});