define("crm/components/customer-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      completeCustomerFile: function completeCustomerFile() {
        Ember.$('#openUpdateCustomer').modal();
      },
      closeModalAppointmentDetailByCustomer: function closeModalAppointmentDetailByCustomer(customerID) {
        Ember.$("#openAppointmentDetails").modal('hide');
        this.sendAction('closeAppointmentDetailByCustomer', customerID);
      }
    }
  });

  _exports.default = _default;
});