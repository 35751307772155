define("crm/models/field-boolean", ["exports", "ember-data", "crm/models/field-model"], function (_exports, _emberData, _fieldModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fieldModel.default.extend({
    value: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});