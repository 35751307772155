define("crm/controllers/organizations/organization/internments/internment/diagnosis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    displayDiagnosisButtons: false,
    diagnosisAnimal: [],
    sortedSymptoms: Ember.computed("model.internmentStatus.chat.messages.@each.created", function () {
      if (this.get("model.internmentStatus.chat.messages")) {
        return this.get("model.internmentStatus.chat.messages").sortBy("created").reverse();
      }
    }),

    /*
     * Computed properties
     */
    isDirty: Ember.computed("diagnosisAnimal.[]", function () {
      return true;
      /* TODO  button cancel*/
    }),
    actions: {
      saveDiagnosis: function saveDiagnosis() {
        var self = this;
        var internmentStatus = this.get("store").peekRecord("internmentStatus", this.get("model.internmentStatus.id"));
        internmentStatus.save().then(function () {
          self.set("displayDiagnosisButtons", false);
        }).catch(function (result) {
          self.get("es").handle(result);
        });
      },
      cancelDiagnosis: function cancelDiagnosis() {
        if (confirm(this.get("intl").t("intl.unsavedChanges"))) {
          this.set("displayDiagnosisButtons", false);
          var internmentStatus = this.get("store").peekRecord("internmentStatus", this.get("model.internmentStatus.id"));
          internmentStatus.rollbackAttributes();
        }
      },
      displayDiagnosisButtons: function displayDiagnosisButtons() {
        this.set("displayDiagnosisButtons", true);
      }
    }
  });

  _exports.default = _default;
});