define("crm/components/future-visits", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "start",
        "component": "umt-datetime2",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("enums.chronos.dateUpper"),
        "className": "column-date-time"
      }, {
        "propertyName": "display",
        "title": this.get('intl').t("appointments.appointment.appointmentType")
      }, {
        "propertyName": "observation",
        "title": this.get('intl').t("appointments.appointment.observation")
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t("modelsTable.show") + " %@ - %@ " + this.get('intl').t("modelsTable.of") + " %@",
          "noDataToShow": this.get('intl').t("modelsTable.noDataToShow")
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "fa fa-times form-control-feedback",
        "clearAllFiltersIcon": ""
      });
    })
  });

  _exports.default = _default;
});