define("crm/models/purchase-request-line", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    quantity: _emberData.default.attr("number"),
    item: _emberData.default.belongsTo("item"),
    purchaseRequest: _emberData.default.belongsTo("purchase-request"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    deletor: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});