define("crm/models/abstract-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    creator: _emberData.default.belongsTo('user'),
    created: _emberData.default.attr('isodate'),
    deleted: _emberData.default.attr('isodate'),
    updater: _emberData.default.belongsTo('user'),
    updated: _emberData.default.attr('isodate'),
    code: _emberData.default.attr(),
    isFull: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});