define("crm/components/characters-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    charsNumber: Ember.computed('text', function () {
      return this.get('text.length');
    })
  });

  _exports.default = _default;
});