define("crm/components/stay-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    es: Ember.inject.service(),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    close: function close() {
      Ember.$("#modal-stay-new").modal("hide");
    },
    boxesList: Ember.computed("model.startDate", "model.endDate", "organization", function () {
      if (this.get("model.startDate") && this.get("model.endDate") && this.get("organization")) {
        var self = this;
        return this.get("store").query("box", {
          organization: this.get("organization.id"),
          startDate: (0, _moment.default)(this.get("model.startDate")).startOf("day").format(),
          endDate: (0, _moment.default)(this.get("model.endDate")).endOf("day").format(),
          available: true
        }).catch(function (result) {
          self.get("es").errorReturned(result);
        });
      }
    }),
    actions: {
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
      },
      createStay: function createStay() {
        this.set("disabledSubmission", true);
        var self = this;
        this.set("model.startDate", new Date(this.get("model.startDate")));
        this.set("model.endDate", new Date(this.get("model.endDate")));
        this.get("store").createRecord("stay", this.get("model")).save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.set("disabledSubmission", false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set("model", {});
        this.set("disabledSubmission", false);
        this.set("selectedPatient", null);
      },
      canceled: function canceled() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});