define("crm/components/sms-sent-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("createdAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("createdBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    minCreatedAfter: Ember.computed("createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdBefore"))) {
        return (0, _moment.default)(this.get("createdBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxCreatedBefore: Ember.computed("createdAfter", function () {
      if (!Ember.isEmpty(this.get("createdAfter"))) {
        return (0, _moment.default)(this.get("createdAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    filtersObserver: Ember.observer("createdAfter", "createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdAfter")) && !Ember.isEmpty(this.get("createdBefore")) && (0, _moment.default)(this.get("createdAfter")) > (0, _moment.default)("1970-01-01")) {
        this.sendAction("updateModel", this.get("createdAfter"), this.get("createdBefore"));
      }
    }),
    columns: Ember.computed("model.[]", "model.@each.created", function () {
      return [{
        "propertyName": "id",
        "className": "column-id"
      }, {
        "propertyName": "sendDate",
        "component": "umt-datetime2",
        "title": this.get("intl").t("sms.sms.appointmentDate"),
        "sortDirection": "desc",
        "sortPrecedence": 1,
        "className": "column-date-time"
      }, {
        "propertyName": "campaign.name",
        "title": this.get("intl").t("sms.sms.campaign"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "customerName",
        "title": this.get("intl").t("sms.sms.customer"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "phoneNumber",
        "title": this.get("intl").t("sms.sms.phoneNumber"),
        "className": "column-contact"
      }, {
        "propertyName": "patientName",
        "title": this.get("intl").t("sms.sms.patient"),
        "component": "title-models-table-display"
      }, {
        "propertyName": "text",
        "title": this.get("intl").t("sms.sms.text"),
        "component": "title-models-table-display"
      }];
    }),
    actions: {
      openDetails: function openDetails(campaign) {
        this.sendAction("openDetails", campaign);
      }
    }
  });

  _exports.default = _default;
});