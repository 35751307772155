define("crm/models/questionnaire-invite", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    baseUrl: _emberData.default.attr("string"),
    customerName: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    questionnaire: _emberData.default.belongsTo("questionnaire"),
    invoice: _emberData.default.belongsTo("invoice"),
    customer: _emberData.default.belongsTo("customer")
  });

  _exports.default = _default;
});