define("crm/components/liaison-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    newLiaison: {},
    disabledActionLiaisonNew: false,
    close: function close() {
      Ember.$("#modal-liaison-create").modal("hide");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        this.set("disabledActionLiaisonNew", true);
        var self = this;

        if (this.get("model")) {
          this.set("newLiaison.organization", this.get("model"));
        }

        this.get("store").createRecord("liaison", this.get("newLiaison")).save().then(function () {
          self.close();
          self.get("flashMessages").success(self.get("intl").t("liaisons.messages.title"));
          self.sendAction("reloadModel");
        }).catch(function () {
          self.set("disabledActionLiaisonNew", false);
        });
      },
      reset: function reset() {
        this.set("newLiaison", {});
        this.set("disabledActionLiaisonNew", false);
      }
    }
  });

  _exports.default = _default;
});