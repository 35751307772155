define("crm/components/item-unit-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.item.id")) {
        this.get("store").findRecord("item", this.get("model.item.id"), {
          reload: true
        });
      }
    },
    reset: function reset() {
      this.set("disabledAction", false);
      Ember.$("#modal-item-unit-edit").modal("hide");
    },
    actions: {
      setBaseUnit: function setBaseUnit(unit) {
        this.set("model.item.baseUnit", unit);
      },
      modalEditItemUnit: function modalEditItemUnit() {
        var self = this;
        this.set("disabledAction", true);
        this.get("model.item").save().then(function () {
          self.reset();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledAction", false);
        });
      },
      cancelEditItemUnitModal: function cancelEditItemUnitModal() {
        if (this.get("model.item.id")) {
          this.get("store").findRecord("item", this.get("model.item.id"), {
            reload: true
          });
        }

        this.reset();
      }
    }
  });

  _exports.default = _default;
});