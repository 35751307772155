define("crm/controllers/organizations/organization/stats/customers/active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    columns: Ember.computed('intl', function () {
      return [{
        "propertyName": "id",
        "title": this.get('intl').t("patients.patient.id"),
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("customers.customer.name"),
        "routeName": "organizations.organization.customers.customer",
        "routeProperty": "id"
      }, {
        "propertyName": "invoicesNumber",
        "title": this.get('intl').t("stats.invoicesNumber"),
        "className": "column-price-large text-right"
      }, {
        "propertyName": "recentDate",
        "title": this.get('intl').t("stats.mostRecentDate"),
        "component": "umt-datetime",
        "className": "column-date-time text-right"
      }, {
        "propertyName": "invoicesTotal",
        "title": this.get('intl').t("stats.totalInInvoices"),
        "component": "umt-price-display",
        "className": "column-price-large text-right"
      }];
    }),
    filteredModel: Ember.computed("model.[]", function () {
      return this.get("model") ? this.get("model").map(function (x) {
        return {
          id: x[0],
          name: x[1],
          invoicesNumber: x[2],
          recentDate: x[3],
          invoicesTotal: x[4]
        };
      }).sortBy("name") : null;
    })
  });

  _exports.default = _default;
});