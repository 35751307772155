define("crm/helpers/margin-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marginType = marginType;
  _exports.default = void 0;

  function marginType(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var aux = params[0];

      switch (aux) {
        case 0:
          return "Markup";

        case 1:
          return "Margem";

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});