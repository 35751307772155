define("crm/components/supplier-item-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.@each.supplier", "model.@each.item", function () {
      return [{
        "propertyName": "supplier.name",
        "title": this.get("intl").t("suppliers.supplier.title"),
        "className": "column-big"
      }, {
        "propertyName": "item.name",
        "title": this.get("intl").t("items.item.title"),
        "className": "column-big"
      }, {
        "propertyName": "supplierId",
        "title": this.get("intl").t("supplierItems.supplierItem.supplierId"),
        "className": "column-small"
      }];
    })
  });

  _exports.default = _default;
});