define("crm/components/chat-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.readOnly('sessionAccount.currentUser'),
    chatMessages: [],

    /*
     * Computed properties
     */
    isDirty: Ember.computed("chatIbox.[]", function () {
      return true;
      /* TODO  button cancel*/
    }),
    actions: {
      deleteMessage: function deleteMessage(chatMessageID) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          var self = this;
          var chatMessage = this.get("store").peekRecord("chatMessage", chatMessageID);
          var chatID = chatMessage.get("chat.id");
          chatMessage.destroyRecord().then(function () {
            self.get("store").findRecord("chat", chatID);
          }).catch(function (result) {
            self.get("es").handle(result);
          });
        }
      },
      updateMessage: function updateMessage(chatMessageID) {
        var self = this;
        var chatMessage = this.get("store").peekRecord("chatMessage", chatMessageID);

        if (chatMessage.get("hasDirtyAttributes")) {
          chatMessage.save().then(function () {
            self.set("displayUpdateButtons", false);
            self.set("displayUpdateIndex", undefined);
          }).catch(function (result) {
            self.get("es").handle(result);
          });
        } else {
          this.set("displayUpdateButtons", false);
          this.set("displayUpdateIndex", undefined);
        }
      },
      cancelMessage: function cancelMessage(chatMessageID) {
        var chatMessage = this.get("store").peekRecord("chatMessage", chatMessageID);

        if (!chatMessage.get("hasDirtyAttributes") || confirm(this.get("intl").t("intl.unsavedChanges"))) {
          this.set("displayUpdateButtons", false);
          this.set("displayUpdateIndex", undefined);
          chatMessage.rollbackAttributes();
        }
      },
      displayUpdateButtons: function displayUpdateButtons(index) {
        this.set("displayUpdateButtons", true);
        this.set("displayUpdateIndex", index);
      }
    }
  });

  _exports.default = _default;
});