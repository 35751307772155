define("crm/components/saft-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("model.id")) {
        this.get("store").findRecord("legal-declaration", this.get("model.id"));
      }
    },
    actions: {
      reset: function reset() {
        this.set("model", {});
      }
    }
  });

  _exports.default = _default;
});