define("crm/routes/organizations/organization/equipment-parameters/equipment-parameter/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var equipmentParameterID = this.modelFor("organizations.organization.equipment-parameters.equipment-parameter").get('id');
      return this.store.findRecord('equipment-parameter', equipmentParameterID);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("equipments", this.store.peekAll("equipment"));
      controller.set("species", this.store.peekAll("species"));
      controller.set("equipmentParameterTypes", this.store.peekAll("equipment-parameter-type"));
    }
  });

  _exports.default = _default;
});