define("crm/models/warehouse", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    billingID: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    internalDescription: _emberData.default.attr("string"),
    organization: _emberData.default.belongsTo("organization"),
    alias: Ember.computed("name", "internalDescription", function () {
      return this.get("internalDescription") ? this.get("internalDescription") : this.get("name");
    }),
    loadingStreetName: Ember.computed("id", function () {
      switch (this.get("id")) {
        case "406":
          return "Caminho Santo António";

        case "408":
          return "Caminho Santo António";

        case "413":
          return "Estrada João Gonçalves Zarco";

        case "414":
          return "Rua da Árvore nº 2";

        case "415":
          return "Rua Vale da Ajuda";

        case "416":
          return "Av das madalenas";

        case "417":
          return "Estrada da Boa Nova";

        case "418":
          return "Estrada João Gonçalves Zarco";

        case "419":
          return "Rua São Sebastião";

        case "436":
          return "rua 5 outubro n 87";

        case "498":
          return "Estrada Regional 104 Edf Ribeira Country III";

        case "521":
          return "Estrada João Gonçalves Zarco nº 145 Cancela Park loja 22 e 23";

        case "582":
          return "Caminho de São Martinho nº 14 Continente Modelo loja 6";

        case "587":
          return "Estrada da Calheta nº 644";

        case "601":
          return "Edifício Vila Sol Centro Comercial da Ponta do Sol Shopping";

        case "609":
          return "Rua Brito Câmara";

        case "629":
          return "Rua Do Visconde De Anadia 27, Centro Comercial Anadia";

        case "671":
          return "Estrada Eng. Abel Vieira, CC Camacha Shopping, Loja 8";

        case "717":
          return "Estrada Dr. João Abel de Freitas";

        default:
          return null;
      }
    }),
    loadingBuildingNumber: Ember.computed("id", function () {
      switch (this.get("id")) {
        case "406":
          return "202";

        case "408":
          return "202";

        case "413":
          return "181";

        case "414":
          return "2";

        case "415":
          return "número 20 Bloco B";

        case "416":
          return "91";

        case "417":
          return "15";

        case "418":
          return "55";

        case "419":
          return "71";

        case "436":
          return "87";

        case "498":
          return "Loja 1 A";

        case "521":
          return "22";

        case "582":
          return "loja 6";

        case "587":
          return "Edf Conchimar loja B";

        case "601":
          return "Piso 0 Loja 1";

        case "609":
          return "9";

        case "629":
          return "Loja 4";

        case "671":
          return "8";

        default:
          return null;
      }
    }),
    loadingPostalZone: Ember.computed("id", function () {
      switch (this.get("id")) {
        case "406":
          return "9020-002";

        case "408":
          return "9020-002";

        case "413":
          return "9300-161";

        case "414":
          return "9200-087";

        case "415":
          return "9000-116";

        case "416":
          return "9020-330";

        case "417":
          return "9060-001";

        case "418":
          return "9125-018";

        case "419":
          return "9200-049";

        case "436":
          return "9000-079";

        case "498":
          return "9350-146";

        case "521":
          return "9125-169";

        case "582":
          return "9000-070";

        case "587":
          return "9370-175";

        case "601":
          return "9360-500";

        case "609":
          return "9000-039";

        case "629":
          return "9050-070";

        case "671":
          return "9135-413";

        case "717":
          return "9000-310";

        default:
          return null;
      }
    }),
    loadingCityName: Ember.computed("id", function () {
      switch (this.get("id")) {
        case "406":
          return "Funchal";

        case "408":
          return "Funchal";

        case "413":
          return "Câmara de Lobos";

        case "414":
          return "Machico";

        case "415":
          return "Funchal";

        case "416":
          return "funchal";

        case "417":
          return "Funchal";

        case "418":
          return "Caniço";

        case "419":
          return "Machico";

        case "436":
          return "funchal";

        case "498":
          return "Ribeira Brava";

        case "521":
          return "Caniço";

        case "582":
          return "funchal";

        case "587":
          return "Calheta";

        case "601":
          return "Ponta do Sol";

        case "609":
          return "funchal";

        case "629":
          return "Funchal";

        case "671":
          return "Camacha";

        case "717":
          return "Funchal";

        default:
          return null;
      }
    }),
    loadingCountry: Ember.computed("id", function () {
      switch (this.get("id")) {
        case "406":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "408":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "413":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "414":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "415":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "416":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "417":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "418":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "419":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "436":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "498":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "521":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "582":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "587":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "601":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "609":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "629":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "671":
          return {
            code: "PT",
            name: "Portugal"
          };

        case "717":
          return {
            code: "PT",
            name: "Portugal"
          };

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});