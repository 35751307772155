define("crm/components/custom-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    controlActions: null,
    actions: {
      updateCheckboxList: function updateCheckboxList(field, option) {
        if (field.value) {
          var aux = field.value.split(", ");
          var index = aux.indexOf(option);

          if (index > -1) {
            aux.splice(index, 1);
          } else {
            aux.push(option);
          }

          field.value = aux.join(', ');
        } else {
          field.value = option;
        }
      },
      sendAction: function sendAction() {
        if (this.get("controlActions") == true) {
          this.sendAction("save", this.get("model"));
        } else if (this.get("controlActions") == false) {
          this.sendAction("extraAction");
        }
      },
      canceled: function canceled() {
        this.set("controlActions", null);
        this.sendAction("canceled");
      },
      sendCancelAction: function sendCancelAction() {
        this.set("controlActions", null);
      },
      sendExtraAction: function sendExtraAction() {
        this.set("controlActions", false);
      },
      sendSaveAction: function sendSaveAction() {
        this.set("controlActions", true);
      }
    }
  });

  _exports.default = _default;
});