define("crm/controllers/organizations/organization/patients/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    queryParams: ["search", "filterDisable"],
    oldString: null,
    disabled: false,
    columns: Ember.computed(function () {
      return [{
        "propertyName": "code",
        "title": "ID",
        "routeName": "organizations.organization.patients.patient",
        "className": "column-id"
      }, {
        "component": "show-sex-symbol",
        "title": "",
        "filterWithSelect": true,
        "className": "column-icon"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.name"),
        "component": "title-models-table-display",
        "path": "organizations.organization.patients.patient",
        "className": "text-strong",
        "sortPrecedence": 0
      }, {
        "propertyName": "owner.name",
        "title": this.get('intl').t("customers.customer.title"),
        "component": "title-models-table-display",
        "path": "organizations.organization.customers.customer",
        "routeProperty": "owner.id"
      }, {
        "propertyName": "alert",
        "title": this.get('intl').t("patients.patient.alert"),
        "className": "hidden-xs hidden-sm"
      }, {
        "propertyName": "observations",
        "title": this.get('intl').t("patients.patient.notes"),
        "className": "hidden-xs hidden-sm hidden-md"
      }, {
        "component": "patient-actions",
        "className": this.get("hideExtraActions") || Ember.$("body").width() < 768 ? "column-actions-2 hide-extra-actions" : "column-actions-5"
      }];
    }),
    actions: {
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create"
        });
      },
      update: function update(patient) {
        this.send("updatePatient", patient);
      },
      newCustomer: function newCustomer() {
        this.send("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      makeSale: function makeSale(parameters) {
        this.send("transitionToRoute", "organizations.organization.pointOfSales", parameters);
      },
      openEditPatientModal: function openEditPatientModal(patientToEdit) {
        this.send('openEditPatient', patientToEdit);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(patient) {
        this.transitionToRoute('organizations.organization.appointments.custom', {
          queryParams: {
            patient: patient.get('id')
          }
        });
      },
      search: function search(query) {
        this.set("search", query);
      },
      searchInactivePatients: function searchInactivePatients(query) {
        this.set("search", query);
      },
      setPatientsTab: function setPatientsTab() {
        this.set('filterDisable', undefined);
      },
      setInactivePatientsTab: function setInactivePatientsTab() {
        this.set('filterDisable', true);
      }
    }
  });

  _exports.default = _default;
});