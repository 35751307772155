define("crm/components/shifts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "worker.name",
        "title": this.get("intl").t("shifts.worker")
      }, {
        "propertyName": "notes",
        "title": this.get("intl").t("shifts.notes")
      }, {
        "propertyName": "startDate",
        "component": "umt-datetime2",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": this.get("intl").t("shifts.startDate"),
        "className": "column-date-time"
      }, {
        "propertyName": "endDate",
        "component": "umt-datetime2",
        "title": this.get("intl").t("shifts.endDate"),
        "className": "column-date-time"
      }, {
        "propertyName": "category.name",
        "title": this.get("intl").t("itemTypes.itemType.title"),
        "className": "column-type"
      }, {
        "propertyName": "room.name",
        "title": this.get("intl").t("rooms.room.title"),
        "className": "column-type"
      }, {
        "component": "shift-actions",
        "className": "column-actions-3"
      }];
    }),
    actions: {
      openDetails: function openDetails(shift) {
        this.sendAction("openModal", {
          entity: "shift",
          action: "details",
          model: shift
        });
      },
      openEdit: function openEdit(shift) {
        this.sendAction("openModal", {
          entity: "shift",
          action: "edit",
          model: shift
        });
      }
    }
  });

  _exports.default = _default;
});