define("crm/controllers/organizations/organization/customers/customer/index", ["exports", "crm/config/environment", "ember-inflector", "moment"], function (_exports, _environment, _emberInflector, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    pdf: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ["queryQuotations", "queryOrders", "queryInvoices", "queryMemos", "queryReceipts", "queryPayments"],
    disableBtnContact: false,
    filteredModels: Ember.computed("model.invoices.[]", "model.orders.[]", "model.quotations.[]", "model.memos.[]", "model.receipts.[]", "model.payments.[]", function () {
      var result = [];

      if (!Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices"));
      }

      if (!Ember.isEmpty(this.get("model.orders"))) {
        result.addObjects(this.get("model.orders"));
      }

      if (!Ember.isEmpty(this.get("model.quotations"))) {
        result.addObjects(this.get("model.quotations"));
      }

      if (!Ember.isEmpty(this.get("model.memos"))) {
        result.addObjects(this.get("model.memos"));
      }

      if (!Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts"));
      }

      if (!Ember.isEmpty(this.get("model.payments"))) {
        result.addObjects(this.get("model.payments"));
      }

      return result;
    }),
    filteredPeriodics: Ember.computed("model.periodics.@each.type", "model.periodics.@each.nextPeriodic", function () {
      if (!Ember.isEmpty(this.get("model.periodics"))) {
        return this.get("model.periodics").sortBy("nextPeriodic").reverse().uniqBy("type.name");
      }
    }),
    customerHasPhone: Ember.computed("model.contacts.[]", "model.contacts.@each.contactType", function () {
      if (!Ember.isEmpty(this.get("model.contacts"))) {
        return this.get("model.contacts").filterBy("contactType", 1);
      }
    }),
    openShoppingCarts: Ember.computed("model.shoppingCartsByCustomer.@each.created", "model.shoppingCartsByCustomer.@each.status", "model.shoppingCartsByCustomer.@each.payableAmount", function () {
      if (!Ember.isEmpty(this.get("model.shoppingCartsByCustomer"))) {
        return this.get("model.shoppingCartsByCustomer").sortBy("created").reverse();
      }
    }),
    filteredAnimals: Ember.computed("model.animals.@each.enabled", function () {
      return this.get("model.animals") ? this.get("model.animals").filterBy("enabled").sortBy("name").concat(this.get("model.animals").filter(function (p) {
        return !p.get("enabled");
      }).sortBy("name")) : null;
    }),
    isReferal: Ember.computed("model.id", function () {
      return this.get("store").query("enterprise", {
        customer: this.get("model.id")
      });
    }),
    currentAccountColor: Ember.computed("model.currentAccount", function () {
      return this.get("model.currentAccount") && this.get("model.currentAccount") > 0 ? "text-danger" : "";
    }),
    columns: Ember.computed(function () {
      var result = [{
        "propertyName": "name",
        "title": this.get("intl").t("patients.patient.name"),
        "routeName": "organizations.organization.patients.patient",
        "routeProperty": "id"
      }, {
        "title": "",
        "component": "patient-status",
        "className": "column-icon"
      }];
      return result;
    }),
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      enable: function enable(patient) {
        patient.set('enabled', true);
        patient.save();
      },
      disable: function disable(patient) {
        patient.set('enabled', false);
        patient.save();
      },
      sendSMS: function sendSMS() {
        this.send("openModal", {
          entity: "sms",
          action: "create",
          model: {
            customer: this.get('model')
          }
        });
      },
      openCommentCreate: function openCommentCreate(task) {
        this.send("openModal", {
          entity: "task",
          action: "commentCreate",
          model: {
            task: task
          }
        });
      },
      openReceiptGenerate: function openReceiptGenerate() {
        this.send("openModal", {
          entity: "receipt",
          action: "generate",
          model: {
            customer: this.get("model")
          }
        });
      },
      openPaymentGenerate: function openPaymentGenerate() {
        this.send("openModal", {
          entity: "payment",
          action: "generate",
          model: {
            customer: this.get("model")
          }
        });
      },
      newPatient: function newPatient() {
        this.send("openModal", {
          entity: "patient",
          action: "create",
          model: {
            owner: this.get("model")
          }
        });
      },
      newAddress: function newAddress() {
        this.send("openModal", {
          entity: "address",
          action: "new",
          model: this.get("model")
        });
      },
      newTask: function newTask() {
        this.send("openModal", {
          entity: "task",
          action: "create",
          model: {
            customer: this.get("model")
          }
        });
      },
      newNote: function newNote() {
        this.send("openModal", {
          entity: "note",
          action: "create",
          model: {
            customer: this.get("model")
          }
        });
      },
      openNoteDetails: function openNoteDetails(model) {
        this.send("openModal", {
          entity: "note",
          action: "details",
          model: model
        });
      },
      openNoteEdit: function openNoteEdit(model) {
        this.send("openModal", {
          entity: "note",
          action: "edit",
          model: model
        });
      },
      openExamDetails: function openExamDetails(model) {
        this.send("openModal", {
          entity: "exam",
          action: "details",
          model: model
        });
      },
      openExamEdit: function openExamEdit(model) {
        this.send("openModal", {
          entity: "exam",
          action: "edit",
          model: model
        });
      },
      openPeriodicDetails: function openPeriodicDetails(model) {
        this.send("openModal", {
          entity: "periodic",
          action: "details",
          model: model
        });
      },
      selectCountry: function selectCountry(value) {
        this.set("country", value);
      },
      selectContactType: function selectContactType(value) {
        this.set("contactType", value);
      },
      createContact: function createContact() {
        this.send("openModal", {
          entity: "contact",
          action: "new",
          model: this.get("model")
        });
      },
      deleteContact: function deleteContact(contactID) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          var self = this;

          if (false == Ember.isEmpty(contactID)) {
            var contactDelete = this.get("store").peekRecord("contact", contactID);
            contactDelete.destroyRecord().then(function () {
              self.send("getContacts");
            });
          }
        }
      },
      openUpdateCustomerModal: function openUpdateCustomerModal() {
        this.send("openUpdateCustomer", this.get("model"));
      },
      openAppointment: function openAppointment() {
        var patient;

        if (this.get("model.animals.length") == 1) {
          patient = this.get("model.animals").objectAt(0).get("id");
        }

        var queryParams = {
          patient: patient,
          customer: this.get("model.id")
        };
        this.send("transitionToRoute", "organizations.organization.appointments.custom", queryParams);
      },
      generateMemos: function generateMemos() {
        var queryParams = {
          customerId: this.get("model.id"),
          document: "Memo"
        };
        this.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      },
      updateQuery: function updateQuery(name, params) {
        this.set(name, params);
      },
      toggleAuthorizationType: function toggleAuthorizationType(authorizationType) {
        if (authorizationType.active) {
          authorizationType.set("active", false);
        } else {
          authorizationType.set("active", true);
        }
      },
      transitToTasks: function transitToTasks() {
        this.transitionToRoute("organizations.organization.tasks");
      },
      gotoAppointmentNew: function gotoAppointmentNew(queryParams) {
        if (queryParams) {
          this.send("transitionToRoute", "organizations.organization.appointments.custom", queryParams);
        } else {
          this.send("transitionToRoute", "organizations.organization.appointments.custom", {
            customer: this.get("model.id")
          });
        }
      },
      createShoppingCart: function createShoppingCart() {
        var self = this;
        self.get("store").createRecord("shopping-cart", {
          organization: this.get("model.currentOrganization"),
          customer: this.get("model")
        }).save().then(function (savedShoppingCart) {
          self.send("reloadModel");
          self.send("openModal", {
            entity: "shopping-cart",
            action: "edit",
            model: {
              shoppingCart: savedShoppingCart,
              hidePayButton: true
            }
          });
        });
      },
      delete: function _delete(model, isCustomer) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          model.destroyRecord();

          if (isCustomer) {
            this.send("transitionToRoute", "organizations.organization.customers");
          }
        }
      },
      updateModel: function updateModel() {
        this.send("reloadModel");
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        Ember.set(periodic, "patient", periodic.patient);
        this.get("store").createRecord("periodic", periodic).save();
        this.send("reloadModel");
      },
      makeSale: function makeSale() {
        this.send("transitionToRoute", "organizations.organization.pointOfSales", {
          customerId: this.get("model.id")
        });
      },
      callLabAnalysisExamReport: function callLabAnalysisExamReport(exam) {
        var self = this;
        var modelName = exam.constructor.modelName;
        var id = exam.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("name") + "_" + (0, _moment.default)(savedDocument.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale "));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = exam.get("patient.code") + "_" + exam.get("patient.name") + "_" + exam.get("name") + "_" + (0, _moment.default)(exam.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      }
    }
  });

  _exports.default = _default;
});