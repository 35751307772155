define("crm/models/item", ["exports", "ember-data", "moment", "crm/models/abstract-model"], function (_exports, _emberData, _moment, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    intl: Ember.inject.service(),
    pmsID: _emberData.default.attr("string"),
    barcode: _emberData.default.attr("string"),
    complementaryDescription: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    enable: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    minStock: _emberData.default.attr("number"),
    maxStock: _emberData.default.attr("number"),
    defaultDuration: _emberData.default.attr("number"),
    period: _emberData.default.attr("number"),
    pvp: _emberData.default.attr("number"),
    pvp2: _emberData.default.attr("number"),
    pvp3: _emberData.default.attr("number"),
    pvpDiscount: _emberData.default.attr("number"),
    pvpDiscount2: _emberData.default.attr("number"),
    pvpDiscount3: _emberData.default.attr("number"),
    pvpMargin: _emberData.default.attr("number"),
    marginType: _emberData.default.attr("number"),
    marginDimension: _emberData.default.attr("number"),
    pvpMargin2: _emberData.default.attr("number"),
    marginType2: _emberData.default.attr("number"),
    marginDimension2: _emberData.default.attr("number"),
    pvpMargin3: _emberData.default.attr("number"),
    marginType3: _emberData.default.attr("number"),
    marginDimension3: _emberData.default.attr("number"),
    variationType: _emberData.default.attr("number"),
    pvp2Variation: _emberData.default.attr("number"),
    pvp3Variation: _emberData.default.attr("number"),
    salePrice: _emberData.default.attr(),
    sellable: _emberData.default.attr("boolean"),
    stockable: _emberData.default.attr("boolean"),
    externalID: _emberData.default.attr("string"),
    displayPrice: _emberData.default.attr("number"),
    prescription: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    purchasePrice: _emberData.default.attr("number"),
    clinicBillingType: _emberData.default.attr("number"),
    baseUnit: _emberData.default.belongsTo("unit"),
    brand: _emberData.default.belongsTo("brand", {
      inverse: "items"
    }),
    bundleItem: _emberData.default.belongsTo("bundle-item"),
    compoundProductItem: _emberData.default.belongsTo("compound-product-item"),
    defaultWarehouse: _emberData.default.belongsTo("warehouse"),
    model: _emberData.default.belongsTo("model"),
    organization: _emberData.default.belongsTo("organization"),
    subfamily: _emberData.default.belongsTo("subfamily"),
    type: _emberData.default.belongsTo("item-type"),
    vat: _emberData.default.belongsTo("tax"),
    companyItemSubType: _emberData.default.belongsTo("company-item-sub-type"),
    parameter: _emberData.default.belongsTo("equipment-parameter-type", {
      inverse: "items"
    }),
    equipment: _emberData.default.belongsTo("equipment"),
    children: _emberData.default.hasMany("item-item", {
      inverse: "parent"
    }),
    warehouseItems: _emberData.default.hasMany("warehouse-item", {
      inverse: "item"
    }),
    companions: _emberData.default.hasMany("item-companion", {
      inverse: "item"
    }),
    supplierItems: _emberData.default.hasMany("supplier-item", {
      inverse: "item"
    }),
    periodics: _emberData.default.hasMany("periodic", {
      inverse: "type"
    }),
    purchaseInvoiceLines: _emberData.default.hasMany("purchase-invoice-line", {
      inverse: "item"
    }),
    itemUnits: _emberData.default.hasMany("item-unit", {
      inverse: "item"
    }),
    synced: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    hasComplementary: Ember.computed("name", "complementaryDescription", function () {
      return this.get("complementaryDescription") && this.get("complementaryDescription").trim() !== this.get("name").trim();
    }),
    itemUnitsInbound: Ember.computed("itemUnits.@each.inbound", function () {
      return this.get("itemUnits").filterBy("inbound", true);
    }),
    stockItemUnit: Ember.computed("itemUnitsInbound.[]", "itemUnitsInbound.@each.id", function () {
      if (!Ember.isEmpty(this.get("itemUnitsInbound")) && this.get("itemUnitsInbound").objectAt(0).get("id")) {
        this.get("store").findRecord("item-unit", this.get("itemUnitsInbound").objectAt(0).get("id"), {
          reload: true
        });
        return this.get("itemUnitsInbound").objectAt(0);
      }

      return null;
    }),
    purchaseUnit: Ember.computed("itemUnitsInbound.[]", "itemUnitsInbound.@each.id", function () {
      if (!Ember.isEmpty(this.get("itemUnitsInbound")) && this.get("itemUnitsInbound").objectAt(0).get("id")) {
        this.get("store").findRecord("item-unit", this.get("itemUnitsInbound").objectAt(0).get("id"), {
          reload: true
        });
        return this.get("itemUnitsInbound").objectAt(0);
      }

      return null;
    }),
    purchaseItemUnit: Ember.computed("purchaseUnit", function () {
      return this.get("purchaseUnit");
    }),
    stockUnit: Ember.computed("stockItemUnit.unit", "stockItemUnit.id", "baseUnit.id", function () {
      return Ember.isEmpty(this.get("stockItemUnit.unit")) ? Ember.isEmpty(this.get("baseUnit")) ? null : this.get("baseUnit") : this.get("stockItemUnit.unit");
    }),
    itemUnitsOutbound: Ember.computed("itemUnits.@each.outbound", function () {
      return Ember.isEmpty(this.get("itemUnits")) ? null : this.get("itemUnits").filterBy("outbound", true);
    }),
    saleItemUnit: Ember.computed("itemUnitsOutbound.[]", function () {
      return Ember.isEmpty(this.get("itemUnitsOutbound")) ? null : this.get("itemUnitsOutbound").objectAt(0);
    }),
    saleUnit: Ember.computed("saleItemUnit.unit", "baseUnit", function () {
      return Ember.isEmpty(this.get("saleItemUnit.unit")) ? Ember.isEmpty(this.get("baseUnit")) ? null : this.get("baseUnit") : this.get("saleItemUnit.unit");
    }),
    saleUnitSymbol: Ember.computed("saleUnit.symbol", function () {
      return this.get("saleUnit.symbol") || "UN";
    }),
    basePrice: Ember.computed("displayPrice", "pvp", function () {
      return this.get("displayPrice") ? this.get("displayPrice") : this.get("pvp") ? this.get("pvp") : 0;
    }),
    saleUnitPrice: Ember.computed("saleItemUnit.quantity", "basePrice", function () {
      return this.get("saleItemUnit") && this.get("saleItemUnit.quantity") ? this.get("saleItemUnit.quantity") * this.get("basePrice") : this.get("basePrice");
    }),
    saleUnitPrice2: Ember.computed("saleItemUnit.quantity", "pvp2", function () {
      return this.get("saleItemUnit") && this.get("saleItemUnit.quantity") ? this.get("saleItemUnit.quantity") * this.get("pvp2") : this.get("pvp2");
    }),
    saleUnitPrice3: Ember.computed("saleItemUnit.quantity", "pvp3", function () {
      return this.get("saleItemUnit") && this.get("saleItemUnit.quantity") ? this.get("saleItemUnit.quantity") * this.get("pvp3") : this.get("pvp3");
    }),
    unit: Ember.computed("baseUnit", function () {
      return this.get("baseUnit");
    }),
    unitSymbol: Ember.computed("baseUnit.symbol", "activeChildren.@each.unitSymbol", function () {
      var result = null;

      if (this.get("baseUnit.symbol")) {
        result = this.get("baseUnit.symbol");
      } else {
        if (!Ember.isEmpty(this.get("activeChildren"))) {
          if (this.get("activeChildren.length") === 1) {
            result = this.get("activeChildren.firstObject.unitSymbol");
          } else {// varios
          }
        } else {// none
          }
      }

      return result;
    }),
    isCompound: Ember.computed("children.[]", function () {
      return this.get("children.length");
    }),
    isExam: Ember.computed("type.root", function () {
      return this.get("type.root.isExam");
    }),
    activeChildren: Ember.computed("children.@each.locked", function () {
      return this.get("children").filter(function (ii) {
        return !ii.get("locked");
      });
    }),
    typeRoot: Ember.computed("type.root", function () {
      return this.get("type.root");
    }),
    codes: Ember.computed("type.codes", function () {
      var result = "";
      var names = this.get("type.codes");

      if (Ember.isEmpty(names)) {
        return "";
      }

      for (var i = 0; i < names.length; i++) {
        result += " " + names[i];
      }

      return result;
    }),
    names: Ember.computed("type.names", function () {
      return this.get("type.names") + " - " + this.get("name");
    }),
    showStockableCheck: Ember.computed("type.stockable", "stockable", function () {
      return this.get("type.stockable") && !this.get("stockable");
    }),
    periodBuild: Ember.computed("period", "type.period", function () {
      return this.get("period") ? this.get("period") : this.get("type.period");
    }),
    pvp2ToUse: Ember.computed("pvp", "pvp2", function () {
      return Ember.isEmpty(this.get("pvp2")) ? this.get("pvp") : this.get("pvp2");
    }),
    pvp3ToUse: Ember.computed("pvp", "pvp3", function () {
      return Ember.isEmpty(this.get("pvp3")) ? this.get("pvp") : this.get("pvp3");
    }),
    periodStr: Ember.computed("periodBuild", function () {
      if (this.get("periodBuild")) {
        var time = this.get("periodBuild");

        if (time >= 8760) {
          return this.get("intl").t("periodics.periods.year") + ": " + time / 8760;
        } else if (time >= 720) {
          return this.get("intl").t("periodics.periods.month") + ": " + time / 720;
        } else if (time >= 168) {
          return this.get("intl").t("periodics.periods.week") + ": " + time / 168;
        } else {
          return this.get("intl").t("periodics.periods.day") + ": " + time / 24;
        }
      } else {
        return "";
      }
    }),
    childrenItems: Ember.computed("children.[]", "children.@each.child", function () {
      return this.get("children.length") ? this.get("children").map(function (c) {
        return c.get("child");
      }) : [];
    }),
    sellableChildrenItems: Ember.computed("childrenItems.[]", "childrenItems.@each.sellable", function () {
      return this.get("childrenItems.length") ? this.get("childrenItems").map(function (c) {
        return c.get("sellable");
      }) : [];
    }),
    isFixedPriceBundle: Ember.computed("children.@each.salePrice", "organization.id", function () {
      // HV Fozcanis (hemograma) || HVME
      return this.get("id") == 233329 || this.get("organization.id") == 185 && this.get("pvp") && this.get("children") && this.get("children").every(function (ii) {
        return !ii.get("salePrice");
      });
    }),
    childrenSorted: Ember.computed("children.@each.orderNumber", function () {
      return this.get("children").sortBy("orderNumber");
    }),
    calculatedDisplayPrice: Ember.computed("pvp", "children.@each.linePrice", function () {
      var result = Ember.isEmpty(this.get("pvp")) ? 0 : this.get("pvp");
      this.get("children").forEach(function (child) {
        result += child.get("linePrice");
      });
      return result;
    }),
    pmsCode: Ember.computed("pmsID", "code", function () {
      return this.get("pmsID") ? this.get("pmsID") : this.get("code");
    }),
    equipmentParameters: Ember.computed("parameter.equipmentParameters.[]", function () {
      return this.get("parameter.equipmentParameters");
    }),
    minVal: Ember.computed("isFuji", "equipmentParameters.[]", "equipmentParameters.@each.minVal", function () {
      if (!Ember.isEmpty(this.get("equipmentParameters"))) {
        if (this.get("isFuji") && this.get("equipmentParameters").objectAt(1)) {
          return this.get("equipmentParameters").objectAt(1).get("minVal");
        }

        return this.get("equipmentParameters.firstObject.minVal");
      }
    }),
    maxVal: Ember.computed("isFuji", "equipmentParameters.[]", "equipmentParameters.@each.maxVal", function () {
      if (!Ember.isEmpty(this.get("equipmentParameters"))) {
        if (this.get("isFuji") && this.get("equipmentParameters").objectAt(1)) {
          return this.get("equipmentParameters").objectAt(1).get("maxVal");
        }

        return this.get("equipmentParameters.firstObject.maxVal");
      }
    }),
    hasMinOrMax: Ember.computed("minVal", "maxVal", function () {
      return this.get("minVal") || this.get("maxVal");
    }),
    isFuji: Ember.computed("name", "organization.id", function () {
      // TODO
      return this.get("organization.id") == 185 && this.get("name").match(/fuji/i);
    }),
    configurations: Ember.computed("period", "defaultDuration", function () {
      return this.get("period") || this.get("defaultDuration");
    }),
    defaultDurationItem: Ember.computed("defaultDuration", "type.defaultDurationItemType", function () {
      return this.get("defaultDuration") ? this.get("defaultDuration") : this.get("type.defaultDurationItemType");
    }),
    purchases: Ember.computed("purchaseInvoiceLines.@each.purchaseInvoice", function () {
      return Ember.isEmpty(this.get("purchaseInvoiceLines")) ? null : this.get("purchaseInvoiceLines").map(function (l) {
        return l.get("purchaseInvoice");
      });
    }),
    lastPurchase: Ember.computed("purchases.@each.date", function () {
      if (Ember.isEmpty(this.get("purchaseInvoiceLines"))) {
        return null;
      }

      var last = this.get("purchaseInvoiceLines.firstObject");
      var lastDate = (0, _moment.default)(last.get("purchaseInvoice.date"));
      this.get("purchaseInvoiceLines").forEach(function (l) {
        if (lastDate.isBefore(l.get("purchaseInvoice.date"))) {
          last = l;
          lastDate = (0, _moment.default)(l.get("purchaseInvoice.date"));
        }
      });
      return last;
    }),
    profit: Ember.computed("pvpWithoutVat", "purchasePrice", function () {
      return this.get("pvpWithoutVat") && this.get("purchasePrice") ? (this.get("pvpWithoutVat") - this.get("purchasePrice")) * 100 / this.get("pvpWithoutVat") : null;
    }),
    profit2: Ember.computed("pvp2WithoutVat", "purchasePrice", function () {
      return this.get("pvp2WithoutVat") && this.get("purchasePrice") ? (this.get("pvp2WithoutVat") - this.get("purchasePrice")) * 100 / this.get("pvp2WithoutVat") : null;
    }),
    profit3: Ember.computed("pvp3WithoutVat", "purchasePrice", function () {
      return this.get("pvp3WithoutVat") && this.get("purchasePrice") ? (this.get("pvp3WithoutVat") - this.get("purchasePrice")) * 100 / this.get("pvp3WithoutVat") : null;
    }),
    pvpWithoutVat: Ember.computed("pvp", "salePrice", "organization.noVat", function () {
      //return this.get("organization.noVat") ? this.get("pvp") : this.get("salePrice");
      return this.get("organization.noVat") ? this.get("pvp") : this.get("pvp") / (1 + this.get("vat.amount") / 100);
    }),
    pvp2WithoutVat: Ember.computed("pvp2", "organization.noVat", function () {
      return this.get("organization.noVat") ? this.get("pvp2") : this.get("pvp2") / (1 + this.get("vat.amount") / 100);
    }),
    pvp3WithoutVat: Ember.computed("pvp3", "organization.noVat", function () {
      return this.get("organization.noVat") ? this.get("pvp3") : this.get("pvp3") / (1 + this.get("vat.amount") / 100);
    }),
    pvpWithVat: Ember.computed("pvp", "vat", "organization.noVat", function () {
      return this.get("organization.noVat") ? this.get("pvp") * (1 + this.get("vat.amount") / 100) : this.get("pvp");
    }),
    pvp2WithVat: Ember.computed("pvp2", "vat", "organization.noVat", function () {
      return this.get("organization.noVat") ? this.get("pvp2") * (1 + this.get("vat.amount") / 100) : this.get("pvp2");
    }),
    pvp3WithVat: Ember.computed("pvp3", "vat", "organization.noVat", function () {
      return this.get("organization.noVat") ? this.get("pvp3") * (1 + this.get("vat.amount") / 100) : this.get("pvp3");
    }),
    usesDecimals: Ember.computed("baseUnit", function () {
      return this.get("baseUnit.precisionDigits");
    })
  });

  _exports.default = _default;
});