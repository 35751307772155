define("crm/components/number-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    unit: Ember.computed("number", function () {
      return this.get("number") ? Math.trunc(this.get("number")) : 0;
    }),
    decimal: Ember.computed("unit", function () {
      return this.get("number") - this.get("unit") ? (this.get("number") - this.get("unit")).toFixed(2).split('.')[1] : null;
    })
  });

  _exports.default = _default;
});