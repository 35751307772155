define("crm/components/visit-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoCalendar: Ember.computed("can", function () {
      return this.get("can").can("do calendar");
    }),
    actions: {
      openDetails: function openDetails(record) {
        this.sendAction('openDetails', record);
      },
      editVisitModal: function editVisitModal(record) {
        this.sendAction('openEditVisitModal', record);
      },
      document: function document(record) {
        this.sendAction('showPdf', record);
      },
      delete: function _delete() {
        var self = this;

        if (this.get("record.appointment.status") === 4) {
          this.set("record.appointment.status", 3);
          this.get("record").save().then(function () {
            self.sendAction("delete", self.get("record"));
          });
        } else {
          this.sendAction("delete", this.get("record"));
        }
      },
      enable: function enable(record) {
        this.sendAction("enable", record);
      },
      disable: function disable(record) {
        this.sendAction("disable", record);
      }
    }
  });

  _exports.default = _default;
});