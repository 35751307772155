define("crm/components/item-type-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    newItemType: {},
    disableCreateBtn: false,
    init: function init() {
      this._super();

      this.reset();
    },
    itemTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get('itemTypes'))) {
        return this.get('itemTypes').filter(function (type) {
          return Ember.isEmpty(type.get('parent.id'));
        });
      }
    }),
    reset: function reset() {
      this.set('newItemType', {});
      this.set('disableCreateBtn', false);
      Ember.$('select').prop('selectedIndex', 0);
      this.close();
    },
    close: function close() {
      Ember.$(".modal").modal('hide');
    },
    actions: {
      setSelectedType: function setSelectedType(value) {
        this.set('newItemType.parent', value);
      },
      create: function create() {
        this.set('disableCreateBtn', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newItemType.organization', this.get('organization'));
        }

        var itemTypeNew = this.get('store').createRecord('item-type', this.get('newItemType'));
        itemTypeNew.save().then(function (savedItemType) {
          self.get('itemTypes').addObject(savedItemType._internalModel);
          self.reset();
        }).catch(function () {
          self.set('disableCreateBtn', false);
        });
      }
    }
  });

  _exports.default = _default;
});