define("crm/controllers/organizations/organization/group/financial-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryCash: true,
    queryCard: true,
    queryTransfer: true,
    queryCheck: true,
    filteredModels: Ember.computed("model.invoices.[]", "model.purchaseInvoices.[]", "model.receipts.[]", "model.payments.[]", "model.financialAccountOpens.[]", "model.financialAccountCloses.[]", "queryCash", "queryCard", "queryTransfer", "queryCheck", function () {
      var result = [];
      var self = this;

      if (!Ember.isEmpty(this.get("queryCash")) || !Ember.isEmpty(this.get("queryCard")) || !Ember.isEmpty(this.get("queryTransfer"))) {
        if (!Ember.isEmpty(this.get("model.invoices"))) {
          result.addObjects(this.get("model.invoices").filter(function (doc) {
            return self.get("queryCash") && doc.get("paymentMethod") == "NUM" || self.get("queryCard") && doc.get("paymentMethod") == "CARD" || self.get("queryTransfer") && doc.get("paymentMethod") == "TRA" || self.get("queryCheck") && doc.get("paymentMethod") == "CHQ" && Ember.isEmpty(doc.get("status"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.purchaseInvoices"))) {
          result.addObjects(this.get("model.purchaseInvoices").filter(function (doc) {
            return self.get("queryCash") && doc.get("paymentMethod") == "NUM" || self.get("queryCard") && doc.get("paymentMethod") == "CARD" || self.get("queryTransfer") && doc.get("paymentMethod") == "TRA" || self.get("queryCheck") && doc.get("paymentMethod") == "CHQ" && Ember.isEmpty(doc.get("status"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.receipts"))) {
          result.addObjects(this.get("model.receipts").filter(function (doc) {
            return self.get("queryCash") && doc.get("paymentMethod") == "NUM" || self.get("queryCard") && doc.get("paymentMethod") == "CARD" || self.get("queryTransfer") && doc.get("paymentMethod") == "TRA" || self.get("queryCheck") && doc.get("paymentMethod") == "CHQ" && Ember.isEmpty(doc.get("status"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.payments"))) {
          result.addObjects(this.get("model.payments").filter(function (doc) {
            return self.get("queryCash") && doc.get("paymentMethod") == "NUM" || self.get("queryCard") && doc.get("paymentMethod") == "CARD" || self.get("queryTransfer") && doc.get("paymentMethod") == "TRA" || self.get("queryCheck") && doc.get("paymentMethod") == "CHQ" && Ember.isEmpty(doc.get("status"));
          }));
        }

        if (!Ember.isEmpty(this.get("model.financialAccountOpens"))) {
          result.addObjects(this.get("model.financialAccountOpens"));
        }

        if (!Ember.isEmpty(this.get("model.financialAccountCloses"))) {
          result.addObjects(this.get("model.financialAccountCloses"));
        }
      } else {
        result = [];
      }

      return result.sortBy("created").reverse();
    }),
    actions: {
      openFinancialAccount: function openFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountOpen",
          action: "new",
          model: this.get("model")
        });
      },
      closeFinancialAccount: function closeFinancialAccount() {
        this.send("openModal", {
          entity: "financialAccountClose",
          action: "new",
          model: this.get("model")
        });
      }
    }
  });

  _exports.default = _default;
});