define("crm/components/calendar-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["appointment-card-component"],
    didInsertElement: function didInsertElement() {
      this.set("height", this.$(".appointment-card").height());
    },
    done: Ember.computed("endConsultation", function () {
      return Ember.isEmpty(this.get("appointment.exitTime")) ? "" : "disabled";
    }),
    disabled: Ember.computed("done", "appointment.status", function () {
      return !this.get("forceEnable") && (this.get("done") || this.get("appointment.status") == 11 || this.get("appointment.status") == 7 || this.get("appointment.status") == 8);
    }),
    innerStyle: Ember.computed("appointment.top", "appointment.height", function () {
      return Ember.String.htmlSafe("position: absolute; top: " + this.get("appointment.top") + "px; height:" + this.get("appointment.height") + "px;");
    })
  });

  _exports.default = _default;
});