define("crm/models/unit", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    externalID: _emberData.default.attr("string"),
    precisionDigits: _emberData.default.attr("number"),
    conversionFactor: _emberData.default.attr("number"),
    symbol: _emberData.default.attr("string"),
    baseUnit: _emberData.default.belongsTo("unit", {
      inverse: "children"
    }),
    organization: _emberData.default.belongsTo("organization"),
    unitDimension: _emberData.default.belongsTo("unitDimension", {
      inverse: "units"
    }),
    children: _emberData.default.hasMany("unit", {
      inverse: "baseUnit"
    }),
    items: _emberData.default.hasMany("item", {
      inverse: "baseUnit"
    }),
    //salesItems
    // stockItems
    // itemUnits
    isBaseUnit: Ember.computed("baseUnit.id", function () {
      return Ember.isEmpty(this.get("baseUnit.id"));
    })
  });

  _exports.default = _default;
});