define("crm/routes/organizations/organization/purchase-requests/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      return Ember.RSVP.hash({
        savedRequests: this.get("store").query("purchase-request", {
          organization: organizationID,
          status: 0
        }, {
          reload: true
        }),
        emittedRequests: this.get("store").query("purchase-request", {
          organization: organizationID,
          issued: true
        }, {
          reload: true
        }),
        requestsInSavedOrders: this.get("store").query("purchase-request", {
          organization: organizationID,
          inOrder: true
        }, {
          reload: true
        }),
        requestsInEmittedOrders: this.get("store").query("purchase-request", {
          organization: organizationID,
          ordered: true
        }, {
          reload: true
        }),
        requestsInSavedInvoices: this.get("store").query("purchase-request", {
          organization: organizationID,
          billed: true
        }, {
          reload: true
        })
      }).then(function (hashResult) {
        return hashResult;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});