define("crm/components/contact-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    intl: Ember.inject.service(),
    contactType: Ember.computed("record.contactType", function () {
      switch (this.get("record.contactType")) {
        case 1:
          return this.get('intl').t("icons.mobilePhone");

        case 2:
          return this.get('intl').t("icons.phone");

        case 3:
          return this.get('intl').t("icons.email");

        default:
          return "";
      }
    })
  });

  _exports.default = _default;
});