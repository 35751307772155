define("crm/controllers/organizations/organization/listings/sales/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    filters: {
      quotations: true,
      orders: true,
      invoices: true,
      memos: true,
      receipts: true,
      payments: true,
      purchaseInvoices: true
    },
    filteredModel: Ember.computed('model.invoices.[]', 'model.memos.[]', 'model.receipts.[]', 'model.payments.[]', 'model.orders.[]', 'model.quotations.[]', 'model.purchaseInvoices.[]', 'filters.invoices', 'filters.memos', 'filters.receipts', 'filters.payments', 'filters.orders', 'filters.quotations', 'filters.purchaseInvoices', function () {
      var result = [];

      if (this.get("filters.invoices") && !Ember.isEmpty(this.get("model.invoices"))) {
        result.addObjects(this.get("model.invoices").filter(function (x) {
          return !x.get("status");
        }));
      }

      if (this.get("filters.memos") && !Ember.isEmpty(this.get("model.memos"))) {
        result.addObjects(this.get("model.memos").filter(function (x) {
          return !x.get("status");
        }));
      }

      if (this.get("filters.receipts") && !Ember.isEmpty(this.get("model.receipts"))) {
        result.addObjects(this.get("model.receipts").filter(function (x) {
          return !x.get("status");
        }));
      }

      if (this.get("filters.payments") && !Ember.isEmpty(this.get("model.payments"))) {
        result.addObjects(this.get("model.payments").filter(function (x) {
          return !x.get("status");
        }));
      }

      if (this.get("filters.orders") && !Ember.isEmpty(this.get("model.orders"))) {
        result.addObjects(this.get("model.orders"));
      }

      if (this.get("filters.quotations") && !Ember.isEmpty(this.get("model.quotations"))) {
        result.addObjects(this.get("model.quotations"));
      }

      if (this.get("filters.purchaseInvoices") && !Ember.isEmpty(this.get("model.purchaseInvoices"))) {
        result.addObjects(this.get("model.purchaseInvoices").filter(function (x) {
          return !Ember.isEmpty(x.get("financialAccount.id"));
        }));
      }

      return result;
    }),
    columns: Ember.computed('intl', function () {
      return [{
        "propertyName": "created",
        "title": this.get('intl').t("organizations.organization.created"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "component": "umt-date",
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("patients.patient.name"),
        "component": "title-models-table-display"
      }, {
        "title": " ",
        "propertyName": "paymentMethod",
        "className": "column-method"
      }, {
        "propertyName": "payableAmount",
        "component": "umt-price-display",
        "title": this.get('intl').t("documents.payableAmount"),
        "className": "column-price-medium text-right"
      }];
    })
  });

  _exports.default = _default;
});