define("crm/components/purchase-invoice-line-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disabledAction: false,
    es: Ember.inject.service(),
    close: function close() {
      Ember.$("#modal-purchase-invoice-line-create").modal("hide");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      modalCreatePurchaseInvoiceLine: function modalCreatePurchaseInvoiceLine() {
        this.set("disabledAction", true);
        var self = this;
        this.get("store").createRecord("purchase-invoice-line", this.get("model")).save().then(function () {
          self.get("flashMessages").success(self.get("intl").t("purchaseInvoiceLines.messages.createdSuccess"));
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.set("disabledAction", false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set("disabledAction", false);
      }
    }
  });

  _exports.default = _default;
});