define("crm/components/date-filters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    catalogs: Ember.inject.service(),
    nextDateStart: (0, _moment.default)().format('YYYY-MM-DD'),
    nextDateEnd: (0, _moment.default)().format('YYYY-MM-DD'),
    hiddenBtn: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.send("search");
    },
    moduleName: Ember.computed("module", function () {
      if (this.get("module")) {
        return this.get("module").split("-")[1];
      } else {
        return "system";
      }
    }),
    isToday: Ember.computed("nextDateStart", "nextDateEnd", function () {
      return this.get("nextDateStart") && this.get("nextDateEnd") ? (0, _moment.default)().isBetween(this.get("nextDateStart"), this.get("nextDateEnd"), "day", "[]") : true;
    }),
    fromMin: Ember.computed("startMin", "nextDateEnd", function () {
      if (this.get("startMin")) {
        return this.get("startMin");
      } else if (this.get("nextDateEnd")) {
        return (0, _moment.default)(this.get("nextDateEnd")).subtract(this.get("catalogs.dateLimit"), "days").format("YYYY-MM-DD");
      } else {
        return (0, _moment.default)().subtract(this.get("catalogs.dateLimit"), "days").format("YYYY-MM-DD");
      }
    }),
    untilMax: Ember.computed("endMax", "nextDateStart", function () {
      if (this.get("endMax")) {
        return this.get("endMax");
      } else if (this.get("nextDateStart")) {
        return (0, _moment.default)(this.get("nextDateStart")).add(this.get("catalogs.dateLimit"), "days").format("YYYY-MM-DD");
      } else {
        return (0, _moment.default)().add(this.get("catalogs.dateLimit"), "days").format("YYYY-MM-DD");
      }
    }),
    actions: {
      previous: function previous() {
        if ((0, _moment.default)(this.get("nextDateStart")).startOf("year").isSame(this.get("nextDateStart"), "day") && (0, _moment.default)(this.get("nextDateEnd")).endOf("year").isSame(this.get("nextDateEnd"), "day")) {
          /* -1 year */
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).subtract(1, 'years').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateEnd")).subtract(1, 'years').format('YYYY-MM-DD'));
        } else if ((0, _moment.default)(this.get("nextDateStart")).startOf("month").isSame(this.get("nextDateStart"), "day") && (0, _moment.default)(this.get("nextDateEnd")).endOf("month").isSame(this.get("nextDateEnd"), "day")) {
          /* -1 month */
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).subtract(1, 'months').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateStart")).endOf("month").format('YYYY-MM-DD'));
        } else {
          var diff = (0, _moment.default)(this.get("nextDateEnd")).diff(this.get("nextDateStart"), "days");
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).subtract(diff + 1, 'days').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateEnd")).subtract(diff + 1, 'days').format('YYYY-MM-DD'));
        }

        this.send("search");
      },
      next: function next() {
        if ((0, _moment.default)(this.get("nextDateStart")).startOf("year").isSame(this.get("nextDateStart"), "day") && (0, _moment.default)(this.get("nextDateEnd")).endOf("year").isSame(this.get("nextDateEnd"), "day")) {
          /* +1 year */
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).add(1, 'years').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateEnd")).add(1, 'years').format('YYYY-MM-DD'));
        } else if ((0, _moment.default)(this.get("nextDateStart")).startOf("month").isSame(this.get("nextDateStart", "day")) && (0, _moment.default)(this.get("nextDateEnd")).endOf("month").startOf("day").isSame(this.get("nextDateEnd"), "day")) {
          /* +1 month */
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).add(1, 'months').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateStart")).endOf("month").format('YYYY-MM-DD'));
        } else {
          /* +x days */
          var diff = (0, _moment.default)(this.get("nextDateEnd")).diff(this.get("nextDateStart"), "days");
          this.set("nextDateStart", (0, _moment.default)(this.get("nextDateStart")).add(diff + 1, 'days').format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)(this.get("nextDateEnd")).add(diff + 1, 'days').format('YYYY-MM-DD'));
        }

        this.send("search");
      },
      today: function today() {
        if ((0, _moment.default)(this.get("nextDateStart")).startOf("year").isSame(this.get("nextDateStart"), "day") && (0, _moment.default)(this.get("nextDateEnd")).endOf("year").startOf("day").isSame(this.get("nextDateEnd"), "day")) {
          this.set("nextDateStart", (0, _moment.default)().startOf("year").format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)().endOf("year").format('YYYY-MM-DD'));
          this.sendAction("today");
        } else if ((0, _moment.default)(this.get("nextDateStart")).startOf("month").isSame(this.get("nextDateStart"), "day") && (0, _moment.default)(this.get("nextDateEnd")).endOf("month").startOf("day").isSame(this.get("nextDateEnd"), "day")) {
          this.set("nextDateStart", (0, _moment.default)().startOf("month").format('YYYY-MM-DD'));
          this.set("nextDateEnd", (0, _moment.default)().endOf("month").format('YYYY-MM-DD'));
          this.sendAction("today");
        } else {
          switch ((0, _moment.default)(this.get("nextDateEnd")).diff(this.get("nextDateStart"), "days")) {
            case 0:
              {
                this.set("nextDateStart", (0, _moment.default)().format('YYYY-MM-DD'));
                this.set("nextDateEnd", (0, _moment.default)().format('YYYY-MM-DD'));
                this.sendAction("today");
                break;
              }

            case 7:
              {
                var weekDay = this.get("nextDateStart").isoWeekday();
                this.set("nextDateStart", (0, _moment.default)().subtract(weekDay - 1, "days").format('YYYY-MM-DD'));
                this.set("nextDateEnd", (0, _moment.default)().subtract(weekDay - 1, "days").format('YYYY-MM-DD'));
                this.sendAction("today");
                break;
              }

            default:
              {
                this.set("nextDateStart", (0, _moment.default)().startOf('month').format('YYYY-MM-DD'));
                this.set("nextDateEnd", (0, _moment.default)().endOf('month').format('YYYY-MM-DD'));
                this.sendAction("today");
              }
          }
        }

        this.send("search");
      },
      updateNextDateStart: function updateNextDateStart(nextDateStart) {
        this.set("nextDateStart", nextDateStart);
        this.set("hiddenBtn", false);
      },
      updateNextDateEnd: function updateNextDateEnd(nextDateEnd) {
        this.set("nextDateEnd", nextDateEnd);
        this.set("hiddenBtn", false);
      },
      search: function search() {
        this.set("hiddenBtn", true);
        var model = {
          nextDateStart: this.get("nextDateStart"),
          nextDateEnd: this.get("nextDateEnd")
        };
        this.sendAction("search", model);
      }
    }
  });

  _exports.default = _default;
});