define("crm/components/card-patient-slim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'span',
    classNames: ['card-slim'],
    actions: {
      callModalWeightDetails: function callModalWeightDetails() {
        var _this = this;

        this.get("store").query("weight", {
          patient: this.get("model.id")
        }).then(function (weights) {
          return _this.get("callModal")({
            entity: "weight",
            action: "details",
            model: weights
          });
        });
      }
    }
  });

  _exports.default = _default;
});