define("crm/controllers/organizations/organization/shopping/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed("model.contacts", function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id hidden-xs"
      }, {
        "propertyName": "name",
        "title": this.get('intl').t("suppliers.supplier.title"),
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "className": "column-big"
      }, {
        "component": "suppliers-actions",
        "className": "column-actions"
      }];
    }),
    actions: {
      openSupplierDetails: function openSupplierDetails() {
        this.send("openModal", {
          entity: "supplier",
          action: "details"
        });
      },
      newSupplier: function newSupplier() {
        this.send("openModal", {
          entity: "supplier",
          action: "new"
        });
      }
    }
  });

  _exports.default = _default;
});