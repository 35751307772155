define("crm/components/patient-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    model: {},
    disabledAction: false,
    catalogs: Ember.inject.service(),
    environmentTypes: Ember.computed.alias("catalogs.environmentTypes"),
    today: (0, _moment.default)().format("YYYY-MM-DD"),
    createdCustomerObserver: Ember.observer("createdCustomer.id", "model", function () {
      if (this.get("createdCustomer.id") && this.get("model")) {
        this.set("model.owner", this.get("createdCustomer"));
      }
    }),
    diets: Ember.computed(function () {
      return this.get("store").peekAll("diet");
    }),
    speciesList: Ember.computed(function () {
      return this.get("store").peekAll("species");
    }),
    reset: function reset() {
      Ember.$("#modal-patient-create").modal("hide");
      this.set("showCreateAndAssociate", false);
      this.set("model", {});
      this.set("showMoreFields", false);
      this.set("disabledAction", false);
      this.set("createdCustomer", null);
    },
    actions: {
      newCustomerOnPatient: function newCustomerOnPatient() {
        this.set("showCreateAndAssociate", true);
        this.sendAction("openModal", {
          entity: "customer",
          action: "create",
          model: {
            hasAnimal: true,
            shouldntOpen: true
          }
        });
      },
      selectCustomer: function selectCustomer() {
        this.sendAction("openModal", {
          entity: "customer",
          action: "select"
        });
      },
      toggleEnvironmentType: function toggleEnvironmentType(environmentTypeID) {
        if (this.get("model.environmentType") === environmentTypeID) {
          this.set("model.environmentType", null);
        } else {
          this.set("model.environmentType", environmentTypeID);
        }
      },
      canceled: function canceled() {
        this.reset();
      },
      selectDiet: function selectDiet(diet) {
        this.set("model.diet", diet);
      },
      unselectCustomer: function unselectCustomer() {
        this.set("model.owner", null);
      },
      setEnvironmentSelectAnswer: function setEnvironmentSelectAnswer(value) {
        this.set("model.environmentType", value);
      },
      setSpeciesID: function setSpeciesID(speciesID) {
        if (speciesID) {
          this.send("setSpecies", this.get("store").peekRecord("species", speciesID));
        } else {
          this.send("setSpecies", null);
        }
      },
      setSpecies: function setSpecies(species) {
        if (false === Ember.isEmpty(species)) {
          this.set("model.species", species);
          this.set("model.breed", null);
        }
      },
      modalCreatePatient: function modalCreatePatient() {
        var self = this;
        this.set("disabledAction", true);
        var newPatient = this.get("store").createRecord("patient", this.get("model"));
        newPatient.save().then(function (result) {
          if (self.get("appointment")) {
            var appointment = self.get("appointment");
            appointment.set("patient", result);
            appointment.save();
            Ember.$("#openAssociatePatientOnAppointment").modal("hide");
          }

          self.get("flashMessages").success(self.get("intl").t("patients.messages.createdSuccess"));
          self.sendAction("reloadModel");
          self.reset();
        }).catch(function (result) {
          self.get("store").unloadRecord(newPatient);
          self.set("disabledAction", false);
          self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
        });
      },
      reset: function reset() {
        this.set("showCreateAndAssociate", false);
        this.set("showMoreFields", false);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});