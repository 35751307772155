define("crm/models/delivery-line", ["exports", "ember-data", "crm/models/document-line"], function (_exports, _emberData, _documentLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentLine.default.extend({
    delivery: _emberData.default.belongsTo("delivery", {
      inverse: "lines"
    })
  });

  _exports.default = _default;
});