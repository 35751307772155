define("crm/models/template-section-field", ["exports", "ember-data", "crm/models/abstract-type-model"], function (_exports, _emberData, _abstractTypeModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractTypeModel.default.extend({
    defaultValue: _emberData.default.attr("string"),
    highlight: _emberData.default.attr("boolean"),
    options: _emberData.default.attr("string"),
    orderNumber: _emberData.default.attr("number"),
    type: _emberData.default.attr("number"),
    mandatory: _emberData.default.attr("boolean"),
    templateSection: _emberData.default.belongsTo("template-section", {
      inverse: "fields"
    }),
    parent: _emberData.default.belongsTo("template-section-field", {
      inverse: "children"
    }),
    children: _emberData.default.hasMany("template-section-field", {
      inverse: "parent"
    }),
    consultationTemplateSectionFields: _emberData.default.hasMany("consultation-template-section-field", {
      inverse: "templateSectionField"
    }),
    optionsArray: Ember.computed("options", function () {
      var result = this.get("options") ? this.get("options").split(",") : [];
      return result.length > 1 ? result : undefined;
    })
  });

  _exports.default = _default;
});