define("crm/models/reward", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    amount: _emberData.default.attr(),
    customer: _emberData.default.belongsTo("customer"),
    invoice: _emberData.default.belongsTo("invoice"),
    receipt: _emberData.default.belongsTo("receipt"),
    payment: _emberData.default.belongsTo("payment")
  });

  _exports.default = _default;
});