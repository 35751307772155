define("crm/components/document-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      var self = this;

      if (this.get("model.id")) {
        this.get("store").findRecord(this.get("model.constructor.modelName"), this.get("model.id"), {
          reload: true
        }).then(function (foundDocument) {
          if (foundDocument.get("checkDate") || foundDocument.get("checkNumber")) {
            self.set("checkDate", (0, _moment.default)(foundDocument.get("checkDate")).format("YYYY-MM-DD"));
            self.set("checkNumber", foundDocument.get("checkNumber"));
          } else {
            self.set("checkDate", null);
            self.set("checkNumber", null);
          }
        });
      }

      if (this.get("model.date")) {
        this.set("date", (0, _moment.default)(this.get("model.date")).format("YYYY-MM-DD"));
      }
    },
    financialAccountsUsable: Ember.computed("financialAccounts.[]", "financialAccounts.@each.isManual", "financialAccounts.@each.isOpen", function () {
      var financialAccounts = [];

      if (this.get("financialAccounts.length")) {
        this.get("financialAccounts").filter(function (financialAccount) {
          if (financialAccount.get("isManual")) {
            if (financialAccount.get("isOpen")) {
              financialAccounts.addObject(financialAccount);
            }
          } else {
            financialAccounts.addObject(financialAccount);
          }
        }).objectAt(0);
      }

      return financialAccounts;
    }),
    financialAccountsExistent: Ember.computed("financialAccounts.@each.financialAccountType", function () {
      var financialAccounts = [];

      if (this.get("financialAccounts.length")) {
        this.get("financialAccounts").filter(function (financialAccount) {
          if (financialAccount.get("financialAccountType") == 1) {
            financialAccounts.addObject(financialAccount);
          }
        }).objectAt(0);
      }

      return financialAccounts;
    }),
    disableActionObserver: Ember.observer("checkNumber", "checkDate", "model.paymentMethod", function () {
      if (this.get("model.paymentMethod") === "CHQ" && (Ember.isEmpty(this.get("checkNumber")) || Ember.isEmpty(this.get("checkDate")))) {
        this.set("disabledActionReceiptEdit", true);
      } else {
        this.set("disabledActionReceiptEdit", false);
      }
    }),
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    canEditName: Ember.computed("model.constructor.modelName", function () {
      return this.get("model.constructor.modelName") == "purchase-receipt";
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsExistent", function () {
      return this.get("financialAccountsExistent").filterBy("financialAccountType", 2).get("length") > 0 ? true : false;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsExistent.@each.financialAccountType", "model.paymentMethod", function () {
      return this.get("financialAccountsExistent").filterBy("financialAccountType", 1).get("length") > 0 || this.get("model.paymentMethod") === "DEB" ? true : false;
    }),
    close: function close() {
      Ember.$("#modal-document-edit").modal("hide");
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setPaymentMethod: function setPaymentMethod(paymentMethod) {
        if (paymentMethod) {
          this.set("model.paymentMethod", paymentMethod);
        } else {
          this.set("model.paymentMethod", null);
        }
      },
      setFinancialAccount: function setFinancialAccount(financialAccount) {
        this.set("model.financialAccount", financialAccount);
      },
      edit: function edit() {
        this.set("disabledActionReceiptEdit", true);

        if (this.get("checkNumber")) {
          this.set("model.checkNumber", this.get("checkNumber"));
        }

        if (this.get("checkDate")) {
          this.set("model.checkDate", (0, _moment.default)(this.get("checkDate")).format("YYYY-MM-DD"));
        }

        if (this.get("date")) {
          this.set("model.date", this.get("date"));
        }

        this.get("model").save();
        this.close();
        this.sendAction("reloadModel");
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      reset: function reset() {
        this.set("model", {});
        this.set("disabledActionReceiptEdit", false);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});