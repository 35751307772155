define("crm/models/consultation", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    date: _emberData.default.attr("isodate"),
    open: _emberData.default.attr("boolean"),
    internment: _emberData.default.attr("number"),
    workerObservation: _emberData.default.attr("string"),
    prescription: _emberData.default.attr("string"),
    treatmentPlanDescription: _emberData.default.attr("string"),
    symptomsDescription: _emberData.default.attr("string"),
    diagnosticsDescription: _emberData.default.attr("string"),
    observations: _emberData.default.attr("string"),
    patient: _emberData.default.belongsTo("patient"),
    organization: _emberData.default.belongsTo("organization"),
    owner: _emberData.default.belongsTo("customer"),
    worker1: _emberData.default.belongsTo("worker"),
    worker2: _emberData.default.belongsTo("worker"),
    weight: _emberData.default.belongsTo("weight"),
    physicalExam: _emberData.default.belongsTo("physicalExam", {
      inverse: "consultation"
    }),
    appointment: _emberData.default.belongsTo("appointment", {
      inverse: "consultation"
    }),
    shoppingCart: _emberData.default.belongsTo("shopping-cart"),
    consultationTemplateSectionFields: _emberData.default.hasMany("consultation-template-section-field", {
      inverse: "consultation"
    }),
    files: _emberData.default.hasMany("file-consultation", {
      inverse: "consultation"
    }),
    drugs: _emberData.default.hasMany("drug", {
      inverse: "consultation"
    }),
    exams: _emberData.default.hasMany("exam", {
      inverse: "consultation"
    }),
    orders: _emberData.default.hasMany("order", {
      inverse: "consultation"
    }),
    reports: _emberData.default.hasMany("report", {
      inverse: "consultation"
    }),
    diagnostics: _emberData.default.hasMany("diagnostic", {
      inverse: "consultations"
    }),
    symptoms: _emberData.default.hasMany("symptom", {
      inverse: "consultations"
    }),
    treatmentPlans: _emberData.default.hasMany("treatmentPlan", {
      inverse: "consultations"
    }),
    customer: Ember.computed("owner", function () {
      return this.get("owner");
    }),
    filesSorted: Ember.computed("files.[]", function () {
      return this.get("files.length") ? this.get("files").sortBy("created").reverse() : undefined;
    }),
    order: Ember.computed("id", "orders.@each.name", function () {
      if (this.get("orders.length")) {
        return this.get("orders").objectAt(0);
      }
    }),
    halfName: Ember.computed("name", function () {
      return this.get("name") ? this.get("name").split("-")[1] : "";
    }),
    templates: Ember.computed("consultationTemplateSectionFields.[]", "consultationTemplateSectionFields.@each.template", function () {
      if (!Ember.isEmpty(this.get("consultationTemplateSectionFields"))) {
        var templates = Ember.A();
        this.get("consultationTemplateSectionFields").forEach(function (consultationTemplateSectionField) {
          if (!Ember.isEmpty(consultationTemplateSectionField.get("template"))) {
            templates.addObject(consultationTemplateSectionField.get("template"));
          }
        });
        return templates;
      }
    })
  });

  _exports.default = _default;
});