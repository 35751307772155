define("crm/controllers/organizations/organization/absences/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["startDate", "endDate"],
    startDate: (0, _moment.default)().startOf("month").format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().endOf("month").format("YYYY-MM-DD"),
    isToday: Ember.computed("startDate", "endDate", function () {
      return (0, _moment.default)().isSame(this.get("startDate"), "day") || (0, _moment.default)().isBetween(this.get("startDate"), this.get("endDate"));
    }),
    allWorkers: Ember.computed("model.organization.id", "currentUser.id", function () {
      return this.get("store").query("worker", {
        organization: this.get("model.organization.id"),
        user: this.get("currentUser.id")
      });
    }),
    actions: {
      previous: function previous() {
        this.set("startDate", (0, _moment.default)(this.get("startDate")).add(-1, "days").format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)(this.get("endDate")).add(-1, "days").format("YYYY-MM-DD"));
      },
      next: function next() {
        this.set("startDate", (0, _moment.default)(this.get("startDate")).add(1, "days").format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)(this.get("endDate")).add(1, "days").format("YYYY-MM-DD"));
      },
      today: function today() {
        this.set("startDate", (0, _moment.default)().format("YYYY-MM-DD"));
        this.set("endDate", (0, _moment.default)().format("YYYY-MM-DD"));
      },
      openNew: function openNew() {
        this.send("openModal", {
          entity: "absence",
          action: "new"
        });
      },
      approveAbsentRequests: function approveAbsentRequests() {
        this.send("transitionToRoute", "organizations.organization.absences.approval");
      }
    }
  });

  _exports.default = _default;
});