define("crm/components/file-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        var self = this;

        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("model").destroyRecord().catch(function (e) {
            return self.get("es").handle(e);
          });
        }
      }
    }
  });

  _exports.default = _default;
});