define("crm/components/pu-date-picker-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "form",
    classNames: ["form-inline", "form-nav", "m-b-md", "m-t-sm"],
    // startDate
    // selectedDate
    // endDate
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("seed")) {
        this.set("selectedDate", (0, _moment.default)(this.get("seed")).format("YYYY-MM-DD"));
      }
    },
    previousDayHide: Ember.computed("selectedDate", "startDate", function () {
      if (!this.get("startDate")) return false;
      var startDate = (0, _moment.default)(this.get("startDate"));
      return startDate.isValid() && startDate.isSameOrAfter(this.get("selectedDate"), "d");
    }),
    isCurrentDay: Ember.computed("selectedDate", function () {
      return (0, _moment.default)().isSame(this.get("selectedDate"), "d");
    }),
    nextDayHide: Ember.computed("selectedDate", "endDate", function () {
      if (!this.get("endDate")) return false;
      var endDate = (0, _moment.default)(this.get("endDate"));
      return endDate.isValid() && endDate.isSameOrBefore(this.get("selectedDate"), "d");
    }),
    actions: {
      currentDay: function currentDay() {
        this.set("selectedDate", (0, _moment.default)().format("YYYY-MM-DD"));
      },
      previousDay: function previousDay() {
        this.set("selectedDate", (0, _moment.default)(this.get("selectedDate")).subtract(1, "d").format("YYYY-MM-DD"));
      },
      nextDay: function nextDay() {
        this.set("selectedDate", (0, _moment.default)(this.get("selectedDate")).add(1, "d").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});