define("crm/routes/organizations/organization/items/item/documents", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var itemID = this.modelFor('organizations.organization.items.item').get('id');

      if (params.createdAfter && (0, _moment.default)(params.createdAfter).isValid() && params.createdBefore && (0, _moment.default)(params.createdBefore).isValid()) {
        var createdAfter = (0, _moment.default)(params.createdAfter).startOf("day").format();
        var createdBefore = (0, _moment.default)(params.createdBefore).endOf("day").format();
        return Ember.RSVP.hash({
          invoices: this.store.query('invoice', {
            item: itemID
          }),
          memos: this.store.query('memo', {
            item: itemID
          }),
          customers: this.store.query('customer', {
            item: itemID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          patients: this.store.query('patient', {
            item: itemID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          invoiceLines: this.store.query('invoice-line', {
            item: itemID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          memoLines: this.store.query('memo-line', {
            item: itemID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          })
        }).then(function (hash) {
          hash.memoLines.forEach(function (memoLine) {
            if (memoLine.get('linePrice')) {
              memoLine.set('linePrice', -memoLine.get('linePrice'));
            }

            if (memoLine.get('memo')) {
              memoLine.set('name', memoLine.get('memo.name'));
              memoLine.set('customer', memoLine.get('memo.customer'));
              memoLine.set('patient', memoLine.get('memo.patient'));
            }
          });
          hash.invoiceLines.forEach(function (invoiceLine) {
            if (invoiceLine.get('invoice')) {
              invoiceLine.set('name', invoiceLine.get('invoice.name'));
              invoiceLine.set('customer', invoiceLine.get('invoice.customer'));
              invoiceLine.set('patient', invoiceLine.get('invoice.patient'));
            }
          });
          return {
            invoiceLines: hash.invoiceLines,
            memoLines: hash.memoLines
          };
        });
      } else {
        return {};
      }
    }
  });

  _exports.default = _default;
});