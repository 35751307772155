define("crm/routes/organizations/organization/listings/procurement/pending", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    store: Ember.inject.service("session"),
    breadCrumb: {
      finalTitle: "Pagamentos"
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      return Ember.RSVP.hash({
        pending: Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/procurement/pending" + "?organization=" + organization.get("id") + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return {
          pending: r.pending.map(function (l) {
            var i = 0;
            var x = {
              invoiceDate: l[i++],
              supplierName: l[i++],
              vat: l[i++],
              purchaseInvoiceName: l[i++],
              payableAmount: l[i++],
              openAmount: l[i++],
              dueDate: l[i++],
              supplierID: l[i++],
              purchaseInvoiceID: l[i++]
            };
            return x;
          })
        };
      });
    }
  });

  _exports.default = _default;
});