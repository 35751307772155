define("crm/routes/organizations/organization/stats/reception/waiting-room", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model() {
      return this.modelFor("organizations.organization");
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var organizationID = model.get("id");
      var begin = this.modelFor("organizations.organization.stats").createdAfter;
      var end = this.modelFor("organizations.organization.stats").createdBefore;
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/appointments/overview" + "?organization=" + organizationID + "&startedAfter=" + begin + "&startedBefore=" + end,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("appointmentsOverview", result);
        }
      });
      Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/appointments/today" + "?organization=" + organizationID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
        },
        success: function success(result) {
          controller.set("todayStats", result);
        }
      });
      controller.set("organization", model);
    }
  });

  _exports.default = _default;
});