define("crm/routes/organizations/organization/patients/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      search: {
        refreshModel: true
      },
      filterDisable: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var self = this;
      var organization = this.modelFor("organizations.organization");

      if (params.search) {
        return Ember.RSVP.hash({
          customers: this.get("store").query("customer", {
            patient: true,
            company: organization.get("company.id"),
            query: params.search
          }),
          patients: this.get("store").query("patient", {
            company: organization.get("company.id"),
            query: params.search,
            enable: params.filterDisable ? false : true
          })
        }).then(function (hashResult) {
          var filtered = hashResult.patients.filterBy("code", Number(params.search));

          if (!Ember.isEmpty(filtered)) {
            self.transitionTo('organizations.organization.patients.patient', filtered.get("firstObject.id"));
          } else {
            return hashResult.patients;
          }
        });
      } else if (params.filterDisable) {
        return Ember.RSVP.hash({
          customers: this.store.query("customer", {
            organization: organization.get("id"),
            latest: 10,
            enable: false,
            patient: true
          }),
          patients: this.store.query("patient", {
            organization: organization.get("id"),
            latest: 10,
            enable: false
          })
        }).then(function (r) {
          return r.patients;
        });
      } else {
        return Ember.RSVP.hash({
          customers: this.store.query("customer", {
            organization: organization.get("id"),
            latest: 10,
            enable: true,
            patient: true
          }),
          patients: this.store.query("patient", {
            organization: organization.get("id"),
            latest: 10,
            enable: true
          })
        }).then(function (r) {
          return r.patients;
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("search", null);
      }
    },
    actions: {
      openEditPatient: function openEditPatient(patientToEdit) {
        this.send('openEditPatientModal', patientToEdit);
      },
      openCreateAppointment: function openCreateAppointment(options) {
        this.send('openModalCreateAppointment', options);
      }
    }
  });

  _exports.default = _default;
});