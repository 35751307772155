define("crm/components/task-detail", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    saving: false,
    newComment: {},
    modelFull: Ember.computed("model.id", function () {
      return Ember.isEmpty(this.get("model.id")) ? {} : this.get("store").findRecord("task", this.get("model.id"));
    }),
    priority: Ember.computed("taskPriorities", "model.priority", function () {
      var self = this;
      var result;
      this.get("taskPriorities").forEach(function (priority) {
        if (self.get("model.priority") && self.get("model.priority") == priority.id) {
          result = priority;
        }
      });
      return result;
    }),
    taskPriorities: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('tasks.priorities.low'),
        color: "primary"
      }, {
        id: 2,
        name: this.get('intl').t('tasks.priorities.high'),
        color: "warning"
      }, {
        id: 3,
        name: this.get('intl').t('tasks.priorities.urgency'),
        color: "danger"
      }];
    }),
    taskDay: Ember.computed('model.taskDate', function () {
      return (0, _moment.default)(this.get('model.taskDate')).format('YYYY-MM-DD');
    }),
    taskTime: Ember.computed('model.taskDate', function () {
      if ((0, _moment.default)(this.get('model.taskDate')).format('hh:mm') != "00:00") {
        return (0, _moment.default)(this.get('model.taskDate')).format('hh:mm');
      }
    }),
    canDoTask: Ember.computed('model.doneTaskDate', 'model.worker.id', 'model.worker.isCurrentWorker', function () {
      return Ember.isEmpty(this.get("model.doneTaskDate")) && (this.get("model.worker.isCurrentWorker") || Ember.isEmpty(this.get("model.worker.id")) || this.get("can").can("do task"));
    }),
    canDoTasks: Ember.computed("can", function () {
      return this.get("can").can("do task");
    }),
    ownerContacts: Ember.computed('model.patient.owner.id', function () {
      return this.get("model.patient.owner.id") ? this.get("store").query("contact", {
        customer: this.get("model.patient.owner.id")
      }) : [];
    }),
    ownerContactsList: Ember.computed('ownerContacts.[]', function () {
      return this.get("ownerContacts");
    }),
    disableBtnComment: Ember.computed("newComment.name", "saving", function () {
      return this.get("newComment.name") ? !this.get("newComment.name").replace(/\s/g, '') : true || this.get("saving");
    }),
    actions: {
      done: function done() {
        var task = this.get('model');
        task.set('status', 2);
        task.set("doneTaskDate", (0, _moment.default)().format());
        task.save().then(function () {
          Ember.$('#modal-task-details').modal('hide');
        });
      },
      createComment: function createComment() {
        if (!Ember.isEmpty(this.get("newComment.name"))) {
          this.set('saving', true);
          var self = this;
          this.set("newComment.task", this.get("modelFull"));
          var newComment = this.get('store').createRecord('taskComment', this.get("newComment"));
          newComment.save().then(function (createdComment) {
            self.set("newComment", {});
            self.sendAction('createdComment', createdComment);
            self.set('saving', false);
          }).catch(function () {
            self.set('saving', false);
            self.get('store').unloadRecord(newComment);
          });
        }
      },
      reset: function reset() {
        this.set("newComment", {});
      },
      deleteTask: function deleteTask() {
        var task = this.get('model');
        var self = this;

        if (confirm(this.get('intl').t("intl.remove"))) {
          task.destroyRecord().then(function () {
            Ember.$('#modal-task-details').modal('hide');
            self.sendAction("reloadModel");
          });
        }
      }
    }
  });

  _exports.default = _default;
});