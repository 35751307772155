define("crm/components/show-document-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: Ember.computed("record.status", function () {
      var result = "";

      switch (this.get("record.status")) {
        case 0:
          result = "floppy-o";
          break;

        case 1:
          if (this.get("record._internalModel.modelName") === "purchase-invoice" && this.get("record.paidAmount") && this.get("record.paidAmount") === this.get("record.payableAmount")) {
            result = this.get("intl").t("icons.check");
          } else {
            result = "credit-card";
          }

          break;

        case 2:
          result = this.get("intl").t("icons.reject");
          break;

        case 3:
          result = this.get("intl").t("icons.processing");
          break;

        case 4:
          result = this.get("intl").t("icons.error");
          break;

        case 8:
          result = this.get("intl").t("icons.annul");
          break;

        default:
          break;
      }

      return result;
    }),
    title: Ember.computed("record.status", function () {
      var result = "";

      switch (this.get("record.status")) {
        case 3:
          result = "";
          break;

        case 4:
          result = this.get("intl").t("invoices.invoice.error");
          break;

        case 8:
          result = this.get("intl").t("invoices.invoice.annuled");
          break;

        default:
          break;
      }

      return result;
    })
  });

  _exports.default = _default;
});