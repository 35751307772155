define("crm/components/brand-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    newBrand: {},
    es: Ember.inject.service(),
    disabledSubmisssionBrandNew: false,
    nameRegex: "^[a-zA-Z0-9_-]+$",
    nameHasError: Ember.computed("newBrand.name", "nameRegex", function () {
      if (this.get("newBrand.name")) {
        return !this.get("newBrand.name").match(this.get("nameRegex"));
      }
    }),
    close: function close() {
      Ember.$("#modal-brand-create").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        this.set('disabledSubmisssionBrandNew', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newBrand.organization', this.get('organization'));
        }

        if (this.get('newBrand.name')) {
          this.set('newBrand.name', this.get('newBrand.name').trim());
        }

        var newBrand = this.get('store').createRecord('brand', this.get('newBrand'));
        newBrand.save().then(function (savedBrand) {
          if (self.get('brands.content')) {
            self.get('brands.content').addObject(savedBrand._internalModel);
          }

          self.close();
          self.get('flashMessages').success(self.get('intl').t('brands.messages.createdSuccess'));
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.get('store').unloadRecord(newBrand);
          self.set('disabledSubmisssionBrandNew', false);
        });
      },
      reset: function reset() {
        this.set('newBrand', {});
        this.set('disabledSubmisssionBrandNew', false);
      }
    }
  });

  _exports.default = _default;
});