define("crm/routes/organizations/organization/customers/customer/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor("organizations.organization.customers.customer");
      return Ember.RSVP.hash({
        invoiceLines: this.get("store").query("invoice-line", {
          customer: customer.get("id")
        }),
        invoices: this.get("store").query("invoice", {
          customer: customer.get("id")
        }),
        items: this.get("store").query("item", {
          customer: customer.get("id")
        })
      }).then(function (hashResult) {
        return hashResult.invoiceLines;
      });
    }
  });

  _exports.default = _default;
});