define("crm/models/patient", ["exports", "ember-data", "crm/models/subject"], function (_exports, _emberData, _subject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _subject.default.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    environmentTypes: Ember.computed.alias("catalogs.environmentTypes"),
    age: _emberData.default.attr("isodate"),
    alert: _emberData.default.attr("string"),
    cardID: _emberData.default.attr("string"),
    admission: _emberData.default.attr("isodate"),
    birthdate: _emberData.default.attr("localdate"),
    mixedbreed: _emberData.default.attr("boolean"),
    census: _emberData.default.attr("string"),
    chip: _emberData.default.attr("string"),
    comments: _emberData.default.attr("string"),
    commentsExtra: _emberData.default.attr("string"),
    deathdate: _emberData.default.attr("localdate"),
    deleted: _emberData.default.attr("isodate"),
    enabled: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    environmentType: _emberData.default.attr("number"),
    eyes: _emberData.default.attr("string"),
    fur: _emberData.default.attr("string"),
    height: _emberData.default.attr("number"),
    insurance: _emberData.default.attr("boolean"),
    insuranceType: _emberData.default.attr("string"),
    location: _emberData.default.attr("string"),
    ownerPrefered: _emberData.default.attr("number"),
    passport: _emberData.default.attr("string"),
    pedigree: _emberData.default.attr("boolean"),
    pmsID: _emberData.default.attr("string"),
    observations: _emberData.default.attr("string"),
    sex: _emberData.default.attr("number"),
    status: _emberData.default.attr("number"),
    sterilized: _emberData.default.attr("boolean"),
    tattoo: _emberData.default.attr("boolean"),
    temperament: _emberData.default.attr("number"),
    completed: _emberData.default.attr("boolean"),
    tattooNumber: _emberData.default.attr("number"),
    furColor: _emberData.default.attr("string"),
    avatar: _emberData.default.belongsTo("image"),
    organization: _emberData.default.belongsTo("organization"),
    breed: _emberData.default.belongsTo("breed"),
    diet: _emberData.default.belongsTo("diet"),
    owner: _emberData.default.belongsTo("customer", {
      inverse: "patients"
    }),
    species: _emberData.default.belongsTo("species", {
      inverse: "animals"
    }),
    tailType: _emberData.default.belongsTo("tail"),
    veterinaryPrefered: _emberData.default.belongsTo("worker"),
    currentWeight: _emberData.default.belongsTo("weight"),
    enterprise: _emberData.default.belongsTo("enterprise"),
    liaison: _emberData.default.belongsTo("liaison"),
    deathType: _emberData.default.belongsTo("death-type"),
    weights: _emberData.default.hasMany("weight", {
      inverse: "patient"
    }),
    periodics: _emberData.default.hasMany("periodic", {
      inverse: "patient"
    }),
    tasks: _emberData.default.hasMany("task", {
      inverse: "patient"
    }),
    consultations: _emberData.default.hasMany("consultation", {
      inverse: "patient"
    }),
    appointments: _emberData.default.hasMany("appointment", {
      inverse: "patient"
    }),
    notes: _emberData.default.hasMany("note", {
      inverse: "patient"
    }),
    exams: _emberData.default.hasMany("exam", {
      inverse: "patient"
    }),
    internments: _emberData.default.hasMany("internment", {
      inverse: "patient"
    }),
    files: _emberData.default.hasMany("file-patient", {
      inverse: "patient"
    }),
    shoppingCarts: _emberData.default.hasMany("shopping-cart", {
      inverse: "patient"
    }),
    enable: Ember.computed("enabled", function () {
      return this.get("enabled");
    }),
    filteredPeriodics: Ember.computed("periodics.@each.type", function () {
      if (!Ember.isEmpty(this.get("periodics"))) {
        return this.get("periodics").sortBy("nextPeriodic").reverse().uniqBy("type.id");
      }
    }),
    currentOrder: Ember.computed("orders.@each.name", function () {
      if (this.get("orders.length")) {
        return this.get("orders").objectAt(this.get("orders.length") - 1);
      }
    }),
    avatarUrl: Ember.computed("avatar.url", "species.id", function () {
      if (!Ember.isEmpty(this.get("avatar.url"))) {
        return this.get("avatar.url");
      } else if (this.get("species.id")) {
        if (this.get("species.id") == 33) {
          // dog
          return "assets/img/dog.png";
        } else if (this.get("species.id") == 36) {
          // cat
          return "assets/img/cat.png";
        }
      }

      return "assets/img/animal.png"; // default
    }),
    environmentTypeName: Ember.computed("environmentTypes.@each.name", function () {
      var _this = this;

      if (this.get("environmentType")) {
        return this.get("environmentTypes").find(function (item) {
          return item.id === _this.get("environmentType");
        }).name;
      }
    }),
    sterilizedName: Ember.computed("sterilized", function () {
      if (this.get("sterilized")) {
        switch (this.get("sterilized")) {
          case 0:
            return "Não";

          case 1:
            return "Sim";

          default:
            return "";
        }
      }
    }),
    maintenanceFluidRate: Ember.computed("currentWeight.weight", function () {
      if (this.get("currentWeight.weight")) {
        var weight = this.get("currentWeight.weight");
        return ((30 * weight + 70) / 24).toFixed(3);
      }
    }),
    maintenanceFluidRateDay: Ember.computed("currentWeight.weight", function () {
      if (this.get("currentWeight.weight")) {
        var weight = this.get("currentWeight.weight");
        return (30 * weight + 70).toFixed(1);
      }
    }),
    isReferral: Ember.computed("enterprise.id", "liaison.id", function () {
      return !Ember.isEmpty(this.get("enterprise.id")) || !Ember.isEmpty(this.get("liaison.id"));
    }),
    referrerName: Ember.computed("isReferral", "enterprise.name", "liaison.name", function () {
      if (this.get("isReferral")) {
        return !Ember.isEmpty(this.get("liaison.id")) ? this.get("liaison.name") : this.get("enterprise.name");
      }
    }),
    filesSorted: Ember.computed("files.[]", function () {
      return this.get("files.length") ? this.get("files").sortBy("created").reverse() : undefined;
    }),
    codeString: Ember.computed("organization.company.organizations.[]", function () {
      return this.get("organization.company.organizations.length") > 1 ? undefined : this.get("code");
    })
  });
  /*
  Sex
  
  0 - Unknown
  1 - Male
  2 - Female
  3 - Genderless
  4 - Hermaphrodite
  5 - Neuter
  
  */


  _exports.default = _default;
});