define("crm/models/vat-rate", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    rate: _emberData.default.attr('number'),
    start: _emberData.default.attr('isodate'),
    end: _emberData.default.attr('isodate'),
    rateType: _emberData.default.belongsTo('vatRateType')
  });

  _exports.default = _default;
});