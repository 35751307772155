define("crm/helpers/consultationType-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appointmentTypeColor = appointmentTypeColor;
  _exports.default = void 0;

  function appointmentTypeColor() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      switch (Number(params[0])) {
        case 1:
          return "consultaColor";

        case 2:
          return "urgenciaColor";

        case 3:
          return "banhosTosquiasColor";

        case 4:
          return "cirurgiaColor";

        case 5:
          return "imagiologiaColor";

        case 6:
          return "laboratorioColor";

        case 7:
          return "vacinaColor";

        case 8:
          return "bemEstarColor";

        case 9:
          return "hospitalizacaoColor";

        case 10:
          return "hotelColor";

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});