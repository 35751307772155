define("crm/components/internment/overview-tr", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: "tr",
    classNameBindings: ['classes'],
    attributeBindings: ['title'],
    columnsIdFormat: "YYYY-MM-DD HH:00",
    columns: Ember.computed("hours", "internment.drugs.@each.isLoaded", "internment.drugs.@each.allActions", "internment.drugs.@each.prevStarted", "internment.monitorings.@each.isLoaded", "internment.monitorings.@each.allActions", "internment.monitorings.@each.prevStarted", "internment.exames.@each.isLoaded", "internment.exames.@each.allActions", "internment.exames.@each.prevStarted", "internment.tasks.@each.isLoaded", "internment.tasks.@each.allActions", "internment.tasks.@each.prevStarted", "internment.serums.@each.isLoaded", "internment.serums.@each.allActions", "internment.serums.@each.prevStarted", function () {
      var self = this;
      var todos = {};
      self.get("hours").forEach(function (hour) {
        todos[hour.format(self.get("columnsIdFormat"))] = undefined;
      });
      self.get("internment.drugs").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.monitorings").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.exams").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.chores").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.serums").forEach(function (serum) {
        self.fillTodos(todos, serum);
      });
      return todos;
    }),
    fillTodos: function fillTodos(todos, task) {
      var chartDateInit = this.get("hours.firstObject");
      var chartDateEnd = this.get("hours.lastObject");
      var taskEndedDate = task.get("ended");
      var taskPrevStarted = task.get("prevStarted");

      if (taskEndedDate && chartDateInit.isAfter(taskEndedDate, 'hour') || // task has ended
      Ember.isEmpty(taskPrevStarted) || // task has no start date
      chartDateEnd.isBefore(taskPrevStarted) // task hasn't started yet
      ) {
          return;
        }

      var self = this;
      var period = task.get("period");
      taskPrevStarted = (0, _moment.default)(taskPrevStarted);
      var actions = {};
      task.get("allActions").forEach(function (action) {
        if (action.get('started')) {
          var started = (0, _moment.default)(action.get("started"));
          var index = (0, _moment.default)(action.get("started")).format(self.get("columnsIdFormat"));

          if (started.isSameOrAfter(chartDateInit) && started.isSameOrBefore(chartDateEnd)) {
            if (false === (action.get('status') == 5 && false == Ember.isEmpty(taskEndedDate) && started.isAfter(taskEndedDate))) {
              actions[index] = action;

              if (todos[index]) {
                todos[index].push({
                  task: task,
                  action: actions[index]
                });
              } else {
                todos[index] = [{
                  task: task,
                  action: actions[index]
                }];
              }
            }
          }
        }
      });

      if (!period && !task.get('prevTodo.id') && taskPrevStarted.isSameOrAfter(chartDateInit) && taskPrevStarted.isSameOrBefore(chartDateEnd)) {
        // single action
        var index = taskPrevStarted.format(this.get("columnsIdFormat"));

        if (!actions[index]) {
          if (todos[index]) {
            todos[index].push({
              task: task,
              action: {
                status: 0
              }
            });
          } else {
            todos[index] = [{
              task: task,
              action: {
                status: 0
              }
            }];
          }
        }
      }

      this.get("hours").forEach(function (hour) {
        // by period
        if (!task.get('period') || !taskPrevStarted || taskPrevStarted && hour.isBefore(taskPrevStarted, 'hour') || taskEndedDate && hour.isAfter(taskEndedDate, 'hour') || task.get('prevTodo.id') && task.get('prevTodo.ended') && hour.isSameOrBefore(task.get('prevTodo.ended')) || task.get('prevTodo.id') && task.get('started') && hour.isSame((0, _moment.default)(task.get('started')).startOf('hour'))) {
          return;
        }

        var ref = taskPrevStarted.startOf('hour');

        if (period && task.get("trueFirstAction") && hour.isAfter(task.get("trueFirstAction.started")) && task.lastActionUntil(hour, 1, 5)) {
          ref = (0, _moment.default)(task.lastActionUntil(hour, 1, 5).get('started')).startOf('hour');
        }

        if (period && hour.diff(ref, "h") % period === 0) {
          //Has period
          var index = hour.format(self.get("columnsIdFormat"));

          if (!actions[index]) {
            if (todos[index]) {
              todos[index].push({
                task: task,
                action: {
                  status: 0
                }
              });
            } else {
              todos[index] = [{
                task: task,
                action: {
                  status: 0
                }
              }];
            }
          }
        }
      });
    },
    actions: {
      openAction: function openAction(task, thisDate, action, statusColor, prevAction) {
        if (action) {
          if (action.get('chore.id')) {
            task = this.get('store').peekRecord('todo-chore', action.get('chore.id'));
          } else if (action.get('drug.id')) {
            task = this.get('store').peekRecord('todo-drug', action.get('drug.id'));
          } else if (action.get('monitoring.id')) {
            task = this.get('store').peekRecord('todo-monitoring', action.get('monitoring.id'));
          } else if (action.get('serum.id')) {
            task = this.get('store').peekRecord('todo-serum', action.get('serum.id'));
          } else if (action.get('exam.id')) {
            task = this.get('store').peekRecord('todo-exam', action.get('exam.id'));
          }
        }

        this.sendAction('openAction', task, thisDate, action, statusColor, prevAction);
      },
      cancelAction: function cancelAction() {
        this.sendAction('cancelAction');
      },
      openTaskCreate: function openTaskCreate(internment) {
        this.sendAction("openTaskCreate", internment);
      },
      openModal: function openModal(args) {
        this.sendAction("callModal", args);
      }
    }
  });

  _exports.default = _default;
});