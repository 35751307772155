define("crm/controllers/organizations/organization/liaisons/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoLiaisons: Ember.computed("can", function () {
      return this.get("can").can("do referral");
    }),
    columns: Ember.computed('intl.locale', function () {
      return [{
        propertyName: "name",
        title: this.get('intl').t("suppliers.name"),
        "component": "title-models-table-display"
      }, {
        className: "column-vat",
        title: this.get('intl').t("customers.customer.nif"),
        propertyName: "vat"
      }, {
        "component": "liaisons-actions",
        "className": "column-actions-1"
      }];
    }),
    useFilteringByColumns: false,
    actions: {
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      newEnterprise: function newEnterprise() {
        this.send("openModal", {
          entity: "enterprise",
          action: "create",
          model: this.get("model")
        });
      },
      newLiaison: function newLiaison() {
        this.send("openModal", {
          entity: "liaison",
          action: "create",
          model: this.get("model")
        });
      }
    }
  });

  _exports.default = _default;
});