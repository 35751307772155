define("crm/models/order", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    lines: _emberData.default.hasMany("order-line", {
      inverse: "order"
    }),
    invoice: _emberData.default.belongsTo("invoice"),
    consultation: _emberData.default.belongsTo("consultation"),
    quotation: _emberData.default.belongsTo("quotation", {
      inverse: "order"
    })
  });
  /*
  Statuses:
      0 - Default
      1 - Was transformed into Invoice
  */


  _exports.default = _default;
});