define("crm/components/sheltered-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    shelteredModel: {},
    workers: Ember.computed("shelteredModel.id", function () {
      return this.get('store').query('worker', {
        organization: this.get('organization.id')
      });
    }),
    diets: Ember.computed("shelteredModel.id", function () {
      return this.get('store').findAll('diet');
    }),
    createCustomFieldObject: function createCustomFieldObject(fieldType, subject, subjectResult) {
      var dataType = fieldType.get('dataType');
      var model;
      var newFieldType = {};

      switch (dataType) {
        default:
        case 1:
          model = "fieldString";
          break;

        case 2:
          model = "fieldInt";
          break;

        case 3:
          model = "fieldDate";
          break;

        case 4:
          model = "fieldBoolean";
          break;

        case 5:
          model = "fieldDouble";
          break;
      }

      newFieldType = this.get('store').createRecord(model, {
        name: fieldType.get('name'),
        value: fieldType.value,
        fieldType: dataType
      });

      if (subject == 0) {
        //sheltered
        newFieldType.set('sheltereds', [subjectResult]);
      } else if (subject === 1) {
        newFieldType.set('customers', [subjectResult]);
      }

      return newFieldType;
    },
    tails: Ember.computed("shelteredModel.id", function () {
      return this.get('store').findAll('tail');
    }),
    speciesList: Ember.computed("shelteredModel.id", function () {
      return this.get('store').findAll('species');
    }),
    speciesBreeds: Ember.computed("species", function () {
      return [];
    }),
    init: function init() {
      this._super();

      this.resetSheltered();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (Ember.isEmpty(this.get('createdCustomer'))) {
        this.resetSheltered();
      } else {
        this.set('shelteredModel.owner', this.get('createdCustomer'));
      }
    },
    resetSheltered: function resetSheltered() {
      Ember.$('#openCreateSheltered').modal('hide');
      Ember.$('select').prop('selectedIndex', 0);
      this.set('shelteredModel', {});
      this.set("showMoreFields", false);

      if (this.get('fieldTypes')) {
        this.get('fieldTypes').forEach(function (ft) {
          ft.set('value', null);
        });
      }
    },
    actions: {
      newCustomerOnSheltered: function newCustomerOnSheltered() {
        this.sendAction("openModal", {
          entity: "customer",
          action: "create"
        });
      },
      canceled: function canceled() {
        this.resetSheltered();
      },
      selectOwner: function selectOwner(ownerID) {
        this.set('selectedCustomer', ownerID);
      },
      selectWorker: function selectWorker(worker) {
        this.set('worker', worker);
      },
      selectSterialized: function selectSterialized(value) {
        this.set('shelteredModel.sterilized', value);
      },
      selectTattoo: function selectTattoo(value) {
        this.set('shelteredModel.tattoo', value);
      },
      selectInsurance: function selectInsurance(value) {
        this.set('shelteredModel.insurance', value);
      },
      selectDiet: function selectDiet(diet) {
        this.set("diet", diet);
      },
      setSpeciesSelectAnswer: function setSpeciesSelectAnswer(value) {
        this.set('species', value);
      },
      setBreedSelectAnswer: function setBreedSelectAnswer(breed) {
        this.set('breed', breed);
      },
      selectTail: function selectTail(tailType) {
        this.set('shelteredModel.tailType', tailType);
      },
      selectFavoriteOwner: function selectFavoriteOwner(favoriteOwner) {
        this.set('shelteredModel.ownerPrefered', favoriteOwner);
      },
      setEnvironmentSelectAnswer: function setEnvironmentSelectAnswer(value) {
        this.set('shelteredModel.environmentType', value);
      },
      modalCreateSheltered: function modalCreateSheltered() {
        var self = this;

        if (this.get('selectedCustomer')) {
          var selectedCustomer = this.get('store').peekRecord('customer', this.get('selectedCustomer'));
          this.set("shelteredModel.owner", selectedCustomer);
        }

        if (this.get('owner.id')) {
          var owner = this.get('store').peekRecord('customer', this.get('owner.id'));
          this.set("shelteredModel.owner", owner);
        }

        if (this.get('worker')) {
          var worker = this.get('store').peekRecord('worker', this.get("worker"));
          this.set("shelteredModel.veterinaryPrefered", worker);
        }

        if (this.get('diet')) {
          var diet = this.get('store').peekRecord('diet', this.get("diet"));
          this.set("shelteredModel.diet", diet);
        }

        if (this.get('tail')) {
          var tail = this.get('store').peekRecord('tail', this.get("tail"));
          this.set("shelteredModel.tailType", tail);
        }

        if (this.get('species')) {
          var specie = this.get('store').peekRecord('species', this.get("species"));
          this.set("shelteredModel.species", specie);
        }

        if (this.get('breed')) {
          var breed = this.get('store').peekRecord('breed', this.get("breed"));
          this.set("shelteredModel.breed", breed);
        }

        this.set('shelteredModel.organization', this.get('organization'));
        this.set('shelteredModel.enabled', true);

        if (Ember.isEmpty(self.get('appointment'))) {
          this.set('shelteredModel.completed', true);
        } else {
          this.set('shelteredModel.completed', false);
        }

        var newSheltered = this.get('store').createRecord('sheltered', this.get('shelteredModel'));
        newSheltered.save().then(function (result) {
          if (self.get('appointment')) {
            var appointment = self.get('appointment');
            appointment.set('sheltered', result);
            appointment.save();
            Ember.$('#openAssociateShelteredOnAppointment').modal('hide');
          }

          if (self.get('fieldTypes')) {
            self.get('fieldTypes').forEach(function (ft) {
              if (ft.value) {
                var fieldTypeObj = self.createCustomFieldObject(ft, 0, result);
                fieldTypeObj.save();
              }
            });
          }

          self.sendAction('reloadModel');
          self.resetSheltered();
          self.get('sheltereds').addObject(result._internalModel);
        }).catch(function () {
          self.get('store').unloadRecord(newSheltered);
        });
      }
    }
  });

  _exports.default = _default;
});