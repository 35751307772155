define("crm/controllers/organizations/organization/configurations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    itemTypes: [],
    customersCards: Ember.computed(function () {
      return [{
        entity: "customerFieldType",
        title: "customerFieldTypes.title"
      }];
    }),
    organizationCards: Ember.computed("currentUser.isAdmin", function () {
      return [{
        entity: "organization",
        title: "configurations.organization.title"
      }, {
        entity: "organization-pet",
        title: "configurations.organization.advanced"
      }];
    }),
    itemsCards: Ember.computed("model.salesFeature", "currentUser.isGod", function () {
      var itemCards = [{
        entity: "brand",
        title: "configurations.item.brands.title"
      }, {
        entity: "model",
        title: "configurations.item.models.title"
      }];

      if (this.get("model.salesFeature")) {
        itemCards.push.apply(itemCards, [{
          entity: "financialAccount",
          title: "financialAccounts.title"
        }]);
      }

      if (this.get("currentUser.isGod")) {
        itemCards.push.apply(itemCards, [{
          entity: "itemType",
          title: "configurations.item.itemTypes.title"
        }, {
          entity: "unit",
          title: "configurations.item.units.title"
        }, {
          entity: "unitDimension",
          title: "configurations.item.unitDimensions.title"
        }]);
      }

      return itemCards;
    }),
    organizationCommunicationCards: Ember.computed("model.campaignsFeature", function () {
      if (this.get("model.campaignsFeature")) {
        return [{
          entity: "organization-communication",
          title: "configurations.organizationCommunication.title"
        }];
      }
    }),
    roomsCards: Ember.computed(function () {
      return [{
        entity: "room",
        title: "rooms.title"
      }];
    }),

    /*patientsCards: Ember.computed(function() {
        return [
            {
                entity: "specie",
                title: "configurations.species.title"
            },
            {
                entity: "breed",
                title: "configurations.breeds.title"
            }
        ];
    }),*/
    roots: Ember.computed("itemTypes.[]", function () {
      return this.get("itemTypes") ? this.get("itemTypes").filter(function (t) {
        return !t.get("parent.id");
      }).sortBy("name") : [];
    }),
    actions: {
      openModalCreatePeriodicType: function openModalCreatePeriodicType() {
        this.send("openCreatePeriodicTypeModal");
      },
      openCreate: function openCreate(entity) {
        this.send("openModal", {
          entity: entity,
          action: "create"
        });
      },
      openList: function openList(entity) {
        this.send("openModal", {
          entity: entity,
          action: "list"
        });
      },
      openEdit: function openEdit(entity) {
        var self = this;
        this.get("store").findRecord(entity, this.get("model.id"), {
          reload: true
        }).then(function (model) {
          self.send("openModal", {
            entity: entity,
            action: "edit",
            model: model
          });
        });
      },
      create: function create() {
        this.set("disabledAction", true);
        var self = this;

        if (this.get("organizationCommunication.name")) {
          this.set("organizationCommunication.name", this.get("organizationCommunication.name").trim());
        }

        if (this.get("organizationCommunication.hourToSend")) {
          this.set("organizationCommunication.sendHour", Number(this.get("organizationCommunication.hourToSend").split(":", 2)[0]));
          this.set("organizationCommunication.sendMinute", Number(this.get("organizationCommunication.hourToSend").split(":", 4)[1]));
        }

        var organizationCommunicationAux;

        if (this.get("organizationCommunication.id") == null) {
          this.set("organizationCommunication.id", this.get("model.id"));
          organizationCommunicationAux = this.get("store").createRecord("organization-communication", this.get("organizationCommunication"));
        } else {
          organizationCommunicationAux = this.get("organizationCommunication");
        }

        organizationCommunicationAux.save().then(function () {
          self.get("flashMessages").success(self.get("intl").t("alerts.changesSaved"));
          self.set("disabledAction", false);
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("disabledAction", false);
        });
      }
    }
  });

  _exports.default = _default;
});