define("crm/models/organization-jasmin", ["exports", "ember-data", "crm/models/organization"], function (_exports, _emberData, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _organization.default.extend({
    series: _emberData.default.attr("string"),
    enterprise: _emberData.default.attr("string"),
    invoiceNotes: _emberData.default.attr("string"),
    pvp: _emberData.default.attr("string"),
    pvp2: _emberData.default.attr("string"),
    pvp3: _emberData.default.attr("string")
  });

  _exports.default = _default;
});