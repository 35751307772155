define("crm/components/model-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    es: Ember.inject.service(),
    newModel: {},
    disabledSubmissionModelNew: false,
    nameRegex: "^[a-zA-Z0-9_-]+$",
    nameHasError: Ember.computed("newModel.name", "nameRegex", function () {
      if (this.get("newModel.name")) {
        return !this.get("newModel.name").match(this.get("nameRegex"));
      }
    }),
    close: function close() {
      Ember.$("#modal-model-create").modal('hide');
    },
    actions: {
      canceled: function canceled() {
        this.close();
      },
      setBrand: function setBrand(brand) {
        this.set('newModel.brand', this.get('store').peekRecord('brand', brand.get("id")));
      },
      create: function create() {
        this.set('disabledSubmissionModelNew', true);
        var self = this;

        if (this.get('organization')) {
          this.set('newModel.organization', this.get('organization'));
        }

        if (this.get('newModel.name')) {
          this.set('newModel.name', this.get('newModel.name').trim());
        }

        var newModel = this.get('store').createRecord('model', this.get('newModel'));
        newModel.save().then(function (savedModel) {
          if (self.get("models.content")) {
            self.get('models.content').addObject(savedModel._internalModel);
          }

          self.close();
          self.get('flashMessages').success(self.get('intl').t('models.messages.createdSuccess'));
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.get('store').unloadRecord(newModel);
          self.set('disabledSubmissionModelNew', false);
        });
      },
      reset: function reset() {
        this.set('newModel', {});
        this.set('disabledSubmissionModelNew', false);
      }
    }
  });

  _exports.default = _default;
});