define("crm/helpers/age-display", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageDisplay = ageDisplay;
  _exports.default = void 0;

  function ageDisplay() {} //export default Ember.Helper.helper(ageDisplay);


  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var years = (0, _moment.default)().diff(params[0], 'years');
      var months = (0, _moment.default)().subtract(years, 'year').diff(params[0], 'months');
      var result = "";

      if (years === 1) {
        result += years + " " + this.get('intl').t('enums.chronos.year') + " ";
      } else if (years > 1) {
        result += years + " " + this.get('intl').t('enums.chronos.years') + " ";
      }

      if (months === 1) {
        result += months + " " + this.get('intl').t('enums.chronos.month') + " ";
      }

      if (months > 1) {
        result += months + " " + this.get('intl').t('enums.chronos.months') + " ";
      }

      if (years < 1 && months < 1) {
        var days = (0, _moment.default)().subtract(months, 'month').diff(params[0], 'days');

        if (days === 1) {
          result += days + " " + this.get('intl').t('enums.chronos.day') + " ";
        } else {
          result += days + " " + this.get('intl').t('enums.chronos.days') + " ";
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});