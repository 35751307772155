define("crm/models/supplier", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    name: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    //
    phone: _emberData.default.attr("string"),
    //
    country: _emberData.default.attr("string"),
    //
    taxID: _emberData.default.attr("number"),
    vat: _emberData.default.attr("string"),
    paymentTerm: _emberData.default.attr("number"),
    paymentType: _emberData.default.attr("string"),
    iban: _emberData.default.attr("string"),
    currentAccount: _emberData.default.attr(),
    organization: _emberData.default.belongsTo("organization"),
    company: _emberData.default.belongsTo("company"),
    address: _emberData.default.belongsTo("address", {
      inverse: "supplier"
    }),
    //
    contacts: _emberData.default.hasMany("contact", {
      inverse: "supplier"
    }),
    supplierItems: _emberData.default.hasMany("supplier-item", {
      inverse: "supplier"
    })
  });

  _exports.default = _default;
});