define("crm/routes/organizations/organization/stats/sales/overview/item-types/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      this.set("breadCrumb", null);
    }
  });

  _exports.default = _default;
});