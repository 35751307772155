define("crm/components/internment/discharge-text-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    saving: false,
    close: function close() {
      this.toggleProperty("closed");
    },
    disableButtons: Ember.computed("saving", function () {
      return this.get("saving");
    }),
    actions: {
      reset: function reset() {
        this.set("saving", false);
      },
      save: function save() {
        this.set("saving", true);
        var self = this;
        this.get("model").save().then(function () {
          self.close();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
          self.set("saving", false);
        });
      },
      cancel: function cancel() {
        this.get("model").rollbackAttributes(); //this.get("store").findRecord("internment", this.get("model.id"), {reload: true});

        this.close();
      },
      showPdf: function showPdf() {
        this.sendAction("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "dischargeLetter",
            consultation: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});