define("crm/components/internment/simple-view-tr", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    classNameBindings: ['classes'],
    attributeBindings: ['title'],
    hourWindow: 24,
    step: 1,
    start: 8,
    columnsIdFormat: "YYYY-MM-DD HH:00",
    columns: Ember.computed("hours", "internment.drugs.@each.isLoaded", "internment.drugs.@each.allActions", "internment.drugs.@each.prevStarted", "internment.monitorings.@each.isLoaded", "internment.monitorings.@each.allActions", "internment.monitorings.@each.prevStarted", "internment.exames.@each.isLoaded", "internment.exames.@each.allActions", "internment.exames.@each.prevStarted", "internment.tasks.@each.isLoaded", "internment.tasks.@each.allActions", "internment.tasks.@each.prevStarted", "internment.serums.@each.isLoaded", "internment.serums.@each.allActions", "internment.serums.@each.prevStarted", function () {
      var self = this;
      var todos = {};
      self.get("hours").forEach(function (hour) {
        todos[hour.format(self.get("columnsIdFormat"))] = undefined;
      });
      self.get("internment.drugs").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.monitorings").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.exams").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.chores").forEach(function (task) {
        self.fillTodos(todos, task);
      });
      self.get("internment.serums").forEach(function (serum) {
        self.fillTodos(todos, serum);
      });
      return todos;
    }),
    fillTodos: function fillTodos(todos, task) {
      var chartDateInit = this.get("hours.firstObject");
      var chartDateEnd = this.get("hours.lastObject");
      var taskEndedDate = task.get("ended");
      var taskPrevStarted = task.get("prevStarted");

      if (taskEndedDate && chartDateInit.isAfter(taskEndedDate, 'hour') || // task has ended
      Ember.isEmpty(taskPrevStarted) || // task has no start date
      chartDateEnd.isBefore(taskPrevStarted) // task hasn't started yet
      ) {
          return;
        }

      var self = this;
      var period = task.get("period");
      taskPrevStarted = (0, _moment.default)(taskPrevStarted);
      var actions = {}; // Reset actions
      // Actions

      task.get("actions").forEach(function (action) {
        if (action.get('started')) {
          var started = (0, _moment.default)(action.get("started"));
          var index = (0, _moment.default)(action.get("started")).format(self.get("columnsIdFormat"));
          var status = action.get("status");

          if (started.isSameOrAfter(chartDateInit) && started.isSameOrBefore(chartDateEnd) && status) {
            if (false === (action.get('status') == 5 && false == Ember.isEmpty(taskEndedDate) && started.isAfter(taskEndedDate))) {
              actions[index] = action;

              if (todos[index] > -1) {
                if (todos[index] > status) {
                  if (status === 5) {
                    todos[index] = 0;
                  } else {
                    todos[index] = status;
                  }
                }
              } else {
                if (status === 5) {
                  todos[index] = 0;
                } else {
                  todos[index] = status;
                }
              }
            }
          }
        }
      }); // No period

      if (Ember.isEmpty(period) && taskPrevStarted.isSameOrAfter(chartDateInit) && taskPrevStarted.isSameOrBefore(chartDateEnd)) {
        var index = taskPrevStarted.format(this.get("columnsIdFormat"));

        if (!actions[index]) {
          todos[index] = 0;
        }
      } // Has period


      this.get("hours").forEach(function (hour) {
        if (Ember.isEmpty(taskPrevStarted) || taskPrevStarted && hour.isBefore(taskPrevStarted, 'hour') || taskEndedDate && hour.isAfter(taskEndedDate, 'hour') || task.get('prevTodo.id') && task.get('prevTodo.ended') && hour.isSameOrBefore(task.get('prevTodo.ended'))) {
          return;
        }

        var ref = taskPrevStarted.startOf('hour');

        if (period && task.get("trueFirstAction") && hour.isAfter(task.get("trueFirstAction.started")) && task.lastActionUntil(hour, 1, 5)) {
          ref = (0, _moment.default)(task.lastActionUntil(hour, 1, 5).get('started')).startOf('hour');
        }

        if (period && hour.diff(ref, "h") % period === 0) {
          var index = hour.format(self.get("columnsIdFormat"));

          if (!actions[index]) {
            todos[index] = 0;
          }
        }
      });
    }
  });

  _exports.default = _default;
});