define("crm/helpers/contact-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contactDisplay = contactDisplay;
  _exports.default = void 0;

  function contactDisplay() {}

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      switch (params[0]) {
        case 1:
          return this.get('intl').t("icons.mobilePhone");

        case 2:
          return this.get('intl').t("icons.phone");

        case 3:
          return this.get('intl').t("icons.email");

        default:
          return "";
      }
    }
  });

  _exports.default = _default;
});