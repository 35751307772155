define("crm/routes/organizations/organization/stats/sales/overview/item-types", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    afterModel: function afterModel() {
      this.set("breadCrumb", null);
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      return Ember.RSVP.hash({
        organization: organization,
        invoices: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/invoices/overview?organization=" + organizationID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        memos: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/memos/overview?organization=" + organizationID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        itemTypes: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/overview/itemTypes?organization=" + organizationID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        }),
        invoiceCreators: Ember.$.get({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/sales/overview/invoiceCreators?organization=" + organizationID,
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          }
        })
      });
    }
  });

  _exports.default = _default;
});