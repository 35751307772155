define("crm/controllers/organizations/organization/internments/simple-view", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    momentHourStr: "h",
    dogSpeciesId: 9615,
    catSpeciesId: 9685,
    hourWindow: 24,
    step: 1,
    start: 8,
    chartDateInit: Ember.computed('start', "model.clock", function () {
      var startOfDay = (0, _moment.default)(this.get("start"), "HH");

      if ((0, _moment.default)().isBefore(startOfDay)) {
        return startOfDay.subtract(24, 'hours');
      } else {
        return startOfDay;
      }
    }),
    chartDateEnd: Ember.computed('chartDateInit', "hourWindow", function () {
      return this.get("chartDateInit").add(this.get("hourWindow"), "hours");
    }),
    chartHours: Ember.computed('chartDateInit', "hourWindow", "step", function () {
      var result = [];
      result.addObject(this.get("chartDateInit"));

      for (var i = 1; i < this.get("hourWindow"); i++) {
        var newHour = (0, _moment.default)(result.objectAt(i - 1)).add(this.get("step"), 'hours');
        result.addObject(newHour);
      }

      return result;
    }),
    discharges: Ember.computed("model.@each.dischargeDate", function () {
      return this.get('model').filter(function (internment) {
        if (internment.get('dischargeDate')) {
          return internment;
        }
      }).sortBy('dischargeDate').reverse();
    }),
    filteredModel: Ember.computed("model.@each.typeName", function () {
      var result = {};
      var filter = "typeName";
      this.get("model").forEach(function (i) {
        if (!result[i.get(filter)]) {
          result[i.get(filter)] = Ember.A();
        }

        result[i.get(filter)].addObject(i);
      });
      return result;
    })
  });

  _exports.default = _default;
});