define("crm/components/via-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    catalogs: Ember.inject.service(),
    via: Ember.computed("model.via", function () {
      var self = this;
      var result;

      if (this.get("model.via")) {
        this.get("catalogs.viaTypes").forEach(function (via) {
          if (via.id === self.get("model.via")) result = via.name;
        });
      }

      return result;
    })
  });

  _exports.default = _default;
});