define("crm/components/events-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canDoEvents: Ember.computed("can", function () {
      //return this.get("can").can("do referral"); TODOJD
      return true;
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "event",
          action: action,
          model: this.get("record")
        });
      },
      delete: function _delete() {
        if (confirm(this.get('intl').t('intl.remove'))) {
          this.get("record").destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});