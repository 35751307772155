define("crm/routes/organizations/organization/listings/sales/financial-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.get("store").query("financialAccount", {
        organization: this.modelFor("organizations.organization").get("id")
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("columns", [{
        "propertyName": "internalDescription",
        "title": this.get("intl").t("financialAccounts.financialAccount.name"),
        "component": "title-models-table-display",
        "className": "column-name",
        "routeName": "organizations.organization.listings.sales.financial-accounts.financial-account.financial-account-opens"
      }]);
    }
  });

  _exports.default = _default;
});