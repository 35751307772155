define("crm/models/customer", ["exports", "ember-data", "crm/models/subject"], function (_exports, _emberData, _subject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _subject.default.extend({
    catalogs: Ember.inject.service(),
    alert: _emberData.default.attr("string"),
    code: _emberData.default.attr("number"),
    enabled: _emberData.default.attr("boolean", {
      allowNull: true
    }),
    nif: _emberData.default.attr("string"),
    profession: _emberData.default.attr("string"),
    accumulatedPoints: _emberData.default.attr("number"),
    balcon: _emberData.default.attr("string"),
    birthdate: _emberData.default.attr("localdate"),
    cc: _emberData.default.attr("string"),
    generalDiscounts: _emberData.default.attr("number"),
    irsRetention: _emberData.default.attr("number"),
    iban: _emberData.default.attr("string"),
    personalCharacteristics: _emberData.default.attr("string"),
    stickyNote: _emberData.default.attr("string"),
    sex: _emberData.default.attr("number"),
    usedPoints: _emberData.default.attr("number"),
    validCC: _emberData.default.attr("string"),
    completed: _emberData.default.attr("boolean"),
    shoppingCartsTotalAmount: _emberData.default.attr("string"),
    currentAccount: _emberData.default.attr(),
    inboundType: _emberData.default.attr("number"),
    tarifa: _emberData.default.attr("number"),
    paymentMethod: _emberData.default.attr("string"),
    barCode: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    avatar: _emberData.default.belongsTo("image"),
    language: _emberData.default.belongsTo("country"),
    title: _emberData.default.belongsTo("customerTitle"),
    origin: _emberData.default.belongsTo("origin"),
    paymentTerm: _emberData.default.belongsTo("paymentTerm"),
    reason: _emberData.default.belongsTo("adherenceReason"),
    referencedBy: _emberData.default.belongsTo("customer", {
      inverse: "customers"
    }),
    addresses: _emberData.default.hasMany("address"),
    appointments: _emberData.default.hasMany("appointment"),
    consultations: _emberData.default.hasMany("consultation"),
    contacts: _emberData.default.hasMany("contact"),
    customerWorkers: _emberData.default.hasMany("customer-worker"),
    customers: _emberData.default.hasMany("customer", {
      inverse: "referencedBy"
    }),
    debts: _emberData.default.hasMany("debt"),
    headerDocuments: _emberData.default.hasMany("header-document"),
    orders: _emberData.default.hasMany("order"),
    patients: _emberData.default.hasMany("patient", {
      inverse: "owner"
    }),
    rewards: _emberData.default.hasMany("reward"),
    warningList: _emberData.default.hasMany("warning-list"),
    rewardsTotal: Ember.computed("rewards.@amount", function () {
      return this.get("rewards") ? this.get("rewards").reduce(function (acc, elem) {
        return acc + elem.get("amount");
      }, 0) : null;
    }),
    currentOrder: Ember.computed("id", "orders.@each.name", function () {
      if (this.get("orders.length")) {
        return this.get("orders").objectAt(this.get("orders.length") - 1);
      }
    }),
    avatarUrl: Ember.computed("avatar.url", "catalogs", function () {
      return this.get("avatar.url") ? this.get("avatar.url") : this.get("catalogs.images.customer");
    }),
    lastContact: Ember.computed("contacts.[]", function () {
      return this.get("contacts").get("lastObject");
    }),
    emailContact: Ember.computed("contacts.@each.contactType", function () {
      var emailContact = null;

      if (!Ember.isEmpty(this.get("contacts"))) {
        this.get("contacts").forEach(function (contact) {
          if (contact.get("contactType") == 3) {
            emailContact = contact;
          }
        });
      }

      return emailContact;
    }),
    whatsAppNumber: Ember.computed("phoneContact", function () {
      if (!this.get("phoneContact")) {
        return null;
      }

      var contact = this.get("phoneContact.contact");

      if (contact.startsWith("+") || contact.startsWith("00")) {
        return encodeURIComponent(contact);
      }

      if (contact.startsWith("9")) {
        return encodeURIComponent("+351" + contact);
      }

      return contact;
    }),
    phoneContact: Ember.computed("contacts.@each.contactType", "contacts.@each.preferential", function () {
      if (!Ember.isEmpty(this.get("contacts"))) {
        return this.get("contacts").filter(function (c) {
          return c.get("contactType") !== 3;
        }).sortBy("preferential, contactType").reverse().get("firstObject");
      } else {
        return null;
      }
    }),
    mobilephoneContact: Ember.computed("contacts.@each.contactType", "contacts.@each.preferential", function () {
      if (!Ember.isEmpty(this.get("contacts"))) {
        return this.get("contacts").filter(function (c) {
          return c.get("contactType") === 1;
        }).sortBy("preferential").reverse().get("firstObject");
      } else {
        return null;
      }
    }),
    email: Ember.computed("emailContact.contact", function () {
      return Ember.isEmpty(this.get("emailContact.contact")) ? undefined : this.get("emailContact.contact");
    }),
    mobilephone: Ember.computed("mobilephoneContact.contact", function () {
      return Ember.isEmpty(this.get("mobilephoneContact.contact")) ? undefined : this.get("mobilephoneContact.contact");
    }),
    address: Ember.computed("firstAddress", function () {
      return this.get("firstAddress");
    }),
    firstAddress: Ember.computed("addresses.@each.created", function () {
      return Ember.isEmpty(this.get("addresses")) ? undefined : this.get("addresses").sortBy("created").get("firstObject");
    }),
    lastAddress: Ember.computed("addresses.@each.created", function () {
      return Ember.isEmpty(this.get("addresses")) ? undefined : this.get("addresses").sortBy("created").get("lastObject");
    }),
    firstAndLastName: Ember.computed("name", function () {
      return Ember.isEmpty(this.get("name")) ? undefined : this.get("name").split(/(\s).+\s/).join("");
    }),
    codeString: Ember.computed("organization.company.organizations.[]", function () {
      return this.get("organization.company.organizations.length") > 1 ? undefined : this.get("code");
    })
  });

  _exports.default = _default;
});