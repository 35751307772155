define("crm/routes/organizations/organization/customers/customer/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor("organizations.organization.customers.customer");
      customer.set("quotations", this.get("store").query("quotation", {
        customer: customer.get("id")
      }));
      customer.set("orders", this.get("store").query("order", {
        customer: customer.get("id")
      }));
      customer.set("invoices", this.get("store").query("invoice", {
        customer: customer.get("id")
      }));
      customer.set("memos", this.get("store").query("memo", {
        customer: customer.get("id")
      }));
      customer.set("receipts", this.get("store").query("receipt", {
        customer: customer.get("id")
      }));
      customer.set("payments", this.get("store").query("payment", {
        customer: customer.get("id")
      }));
      return customer;
    }
  });

  _exports.default = _default;
});