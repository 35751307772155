define("crm/models/item-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    salePrice: _emberData.default.attr("number"),
    quantity: _emberData.default.attr("number"),
    locked: _emberData.default.attr("boolean"),
    discount: _emberData.default.attr("number"),
    orderNumber: _emberData.default.attr("number"),
    salePrice2: _emberData.default.attr("number"),
    discount2: _emberData.default.attr("number"),
    salePrice3: _emberData.default.attr("number"),
    discount3: _emberData.default.attr("number"),
    parent: _emberData.default.belongsTo("item", {
      inverse: "children"
    }),
    child: _emberData.default.belongsTo("item"),
    unitSymbol: Ember.computed("child.unitSymbol", function () {
      return this.get("child.unitSymbol");
    }),
    linePrice: Ember.computed("saleUnitPrice", "discount", "quantity", "child.saleUnitPrice", function () {
      var result = null;

      if (this.get("discount") && this.get("saleUnitPrice")) {
        result = (this.get("saleUnitPrice") - this.get("saleUnitPrice") * (this.get("discount") / 100)) * this.get("quantity");
      } else if (this.get("saleUnitPrice")) {
        result = this.get("quantity") * this.get("saleUnitPrice");
      } else if (this.get("discount") && this.get("child.saleUnitPrice")) {
        result = (this.get("child.saleUnitPrice") - this.get("child.saleUnitPrice") * (this.get("discount") / 100)) * this.get("quantity");
      } else {
        result = this.get("child.saleUnitPrice") * this.get("quantity");
      }

      return result;
    }),
    displayPrice: Ember.computed("salePrice", "saleUnitPrice", "quantity", function () {
      var result = 0;

      if (this.get("salePrice")) {
        result += Number(this.get("salePrice")) * Number(this.get("quantity"));
      } else {
        result += Number(this.get("saleUnitPrice")) * Number(this.get("quantity"));
      }

      return result;
    }),
    displayPrice2: Ember.computed("salePrice2", "quantity", function () {
      var result = 0;

      if (this.get("salePrice2")) {
        result += Number(this.get("salePrice2")) * Number(this.get("quantity"));
      }

      return result;
    }),
    displayPrice3: Ember.computed("salePrice3", "quantity", function () {
      var result = 0;

      if (this.get("salePrice3")) {
        result += Number(this.get("salePrice3")) * Number(this.get("quantity"));
      }

      return result;
    }),
    saleUnitPrice: Ember.computed("salePrice", "child.pvp", "child.saleItemUnit", "child.saleItemUnit.quantity", function () {
      var result = 0;

      if (!Ember.isEmpty(this.get("salePrice"))) {
        result = this.get("salePrice");
      } else {
        result = this.get("child.saleItemUnit") && this.get("child.saleItemUnit.quantity") ? this.get("child.saleItemUnit.quantity") * this.get("basePrice") : this.get("basePrice");
      }

      return result;
    }),
    saleUnitPrice2: Ember.computed("salePrice2", "basePrice2", "child.saleItemUnit", "child.saleItemUnit.quantity", function () {
      var result = 0;

      if (!Ember.isEmpty(this.get("salePrice2"))) {
        result = this.get("salePrice2");
      } else {
        result = this.get("child.saleItemUnit") && this.get("child.saleItemUnit.quantity") ? this.get("child.saleItemUnit.quantity") * this.get("basePrice2") : this.get("basePrice2");
      }

      return result;
    }),
    saleUnitPrice3: Ember.computed("salePrice3", "basePrice3", "child.saleItemUnit", "child.saleItemUnit.quantity", function () {
      var result = 0;

      if (!Ember.isEmpty(this.get("salePrice3"))) {
        result = this.get("salePrice3");
      } else {
        result = this.get("child.saleItemUnit") && this.get("child.saleItemUnit.quantity") ? this.get("child.saleItemUnit.quantity") * this.get("basePrice3") : this.get("basePrice3");
      }

      return result;
    }),
    basePrice: Ember.computed("child.displayPrice", "child.pvp", function () {
      return this.get("child.displayPrice") ? this.get("child.displayPrice") : this.get("child.pvp");
    }),
    basePrice2: Ember.computed("child.displayPrice", "child.pvp2ToUse", function () {
      return this.get("child.displayPrice") ? this.get("child.displayPrice") : this.get("child.pvp2ToUse");
    }),
    basePrice3: Ember.computed("child.displayPrice", "child.pvp3ToUse", function () {
      return this.get("child.displayPrice") ? this.get("child.displayPrice") : this.get("child.pvp3ToUse");
    }),
    displayLinePrice: Ember.computed("discount", "saleUnitPrice", "quantity", function () {
      if (this.get("discount")) {
        return this.money((this.get("saleUnitPrice") - this.get("saleUnitPrice") * (this.get("discount") / 100)) * this.get("quantity"));
      } else {
        return this.money(this.get("quantity") * this.get("saleUnitPrice"));
      }
    }),
    displayLinePrice2: Ember.computed("discount", "saleUnitPrice2", "quantity", function () {
      if (this.get("discount")) {
        return this.money((this.get("saleUnitPrice2") - this.get("saleUnitPrice2") * (this.get("discount") / 100)) * this.get("quantity"));
      } else {
        return this.money(this.get("quantity") * this.get("saleUnitPrice2"));
      }
    }),
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});