define("crm/models/organization-communication-email", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    replyToEmail: _emberData.default.attr("string"),
    replyToName: _emberData.default.attr("string"),
    senderName: _emberData.default.attr("string"),
    smtpUser: _emberData.default.attr("string"),
    smtpPassword: _emberData.default.attr("string"),
    smtpAddr: _emberData.default.attr("string"),
    smtpPort: _emberData.default.attr("string")
  });

  _exports.default = _default;
});