define("crm/components/sms-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    contactValidator: Ember.inject.service(),
    tab: "new",
    saving: false,
    disabledAction: Ember.computed("model.customer", "saving", "displaySend", "tab", "validContactNumber", "model.text", function () {
      return this.get("saving") || !this.get("displaySend") || this.get("tab") === "search" && !this.get("model.customer") || !this.get("validContactNumber") || !this.get("model.text");
    }),
    contactsRequest: Ember.computed("model.customer.id", function () {
      if (this.get("model.customer.id")) {
        return this.get("store").query("contact", {
          customer: this.get('model.customer.id')
        });
      }
    }),
    displaySend: Ember.computed("contactsRequest.[]", "model.phoneNumber", function () {
      var result = false;

      if (this.get("contactsRequest")) {
        if (!Ember.isEmpty(this.get("contactsRequest"))) {
          this.get("contactsRequest").forEach(function (contact) {
            if (contact.get("contactType") == 1) {
              result = true;
            }
          });
        }
      }

      if (this.get("model.phoneNumber")) {
        result = true;
      }

      return result;
    }),
    close: function close() {
      this.toggleProperty("closed");
    },
    validContactNumber: Ember.computed("model.phoneNumber", "organization.country", "model.customer.lastAddress", function () {
      if (Ember.isEmpty(this.get("model.phoneNumber"))) {
        return true;
      }

      var result;
      var contact;
      var contactType;

      if (this.get("model.phoneNumber")) {
        contactType = 1;
        contact = this.get("model.phoneNumber");
      }

      if (this.get("model.customer.id") && this.get("model.customer.lastAddress.id") && this.get("model.customer.lastAddress.country")) {
        result = this.get("contactValidator").contactNumber(this.get("model.customer.lastAddress.country"), contact, contactType);
      } else {
        result = this.get("contactValidator").contactNumber(this.get("organization.country"), contact, contactType);
      }

      return result;
    }),
    actions: {
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
        this.set("model.customer", null);
      },
      canceled: function canceled() {
        this.close();
      },
      setTab: function setTab(value) {
        this.set("tab", value);
      },
      create: function create() {
        this.set('saving', true);
        var self = this;

        if (this.get("tab") === "search") {
          this.set("model.phoneNumber", null);
        }

        if (this.get("tab") === "new") {
          this.set("model.customer", null);
          this.set("model.patient", null);
        }

        var createsms = this.get('store').createRecord('sms-sent', this.get('model'));
        createsms.save().then(function () {
          self.get('flashMessages').success(self.get('intl').t('sms.messages.createdSuccess'));
          self.sendAction('reloadModel');
          self.close();
        }).catch(function (result) {
          self.set('saving', false);
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set('model', {});
        this.set('tab', false);
        this.set('saving', false);
      },
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      }
    }
  });

  _exports.default = _default;
});