define("crm/components/pu-page-footer", ["exports", "universe-gui/components/pu-page-footer", "crm/config/environment"], function (_exports, _puPageFooter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _puPageFooter.default.extend({
    version: Ember.computed("ENV", function () {
      return _environment.default.version;
    })
  });

  _exports.default = _default;
});