define("crm/components/invoice-memos-total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    invoiceTotal: Ember.computed('model.[]', function () {
      var invoiceTotal = 0;

      if (!Ember.isEmpty(this.get("model"))) {
        this.get("model").forEach(function (filteredModelInvoice) {
          if (filteredModelInvoice.get("constructor.modelName") === "invoice") {
            if (filteredModelInvoice.get("payableAmount") && Ember.isEmpty(filteredModelInvoice.get("status")) && filteredModelInvoice.get("name") !== "Em processamento...") {
              invoiceTotal = invoiceTotal + filteredModelInvoice.get("payableAmount");
            }
          }
        });
      }

      return invoiceTotal.toFixed(2);
    }),
    memoTotal: Ember.computed('model.[]', function () {
      var memoTotal = 0;

      if (!Ember.isEmpty(this.get("model"))) {
        this.get("model").forEach(function (filteredModelMemo) {
          if (filteredModelMemo.get("constructor.modelName") === "memo") {
            if (filteredModelMemo.get("payableAmount") && Ember.isEmpty(filteredModelMemo.get("status"))) {
              memoTotal = memoTotal + filteredModelMemo.get("payableAmount");
            }
          }
        });
      }

      return memoTotal.toFixed(2);
    }),
    total: Ember.computed('invoiceTotal', 'memoTotal', function () {
      var total = 0;
      total = this.get("invoiceTotal") - this.get('memoTotal');
      return total.toFixed(2);
    })
  });

  _exports.default = _default;
});