define("crm/controllers/organizations/organization/suppliers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoSuppliers: Ember.computed("can", function () {
      return this.get("can").can("do procurement");
    }),
    columns: Ember.computed('intl.locale', function () {
      return [{
        propertyName: "country",
        title: this.get('intl').t("suppliers.country"),
        className: "column-id"
      }, {
        propertyName: "vat",
        title: this.get('intl').t("customers.customer.nif"),
        className: "column-vat"
      }, {
        propertyName: "name",
        title: this.get('intl').t("suppliers.name"),
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "component": "title-models-table-display",
        "path": "organizations.organization.suppliers.supplier",
        className: "text-strong"
      }, {
        component: "suppliers-actions",
        className: "column-actions-2"
      }];
    }),
    useFilteringByColumns: false,
    actions: {
      newSupplier: function newSupplier() {
        this.send("openModal", {
          entity: "supplier",
          action: "new"
        });
      },
      openSupplierDetails: function openSupplierDetails(model) {
        this.send("openModal", {
          entity: "supplier",
          action: "details",
          model: model
        });
      },
      openSupplierEdit: function openSupplierEdit(model) {
        this.send("openModal", {
          entity: "supplier",
          action: "edit",
          model: model
        });
      }
    }
  });

  _exports.default = _default;
});