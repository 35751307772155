define("crm/controllers/organizations/organization/listings/sales/receivables", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    createdAfter: (0, _moment.default)().startOf("month").format("YYYY-MM-DD"),
    createdBefore: (0, _moment.default)().format("YYYY-MM-DD"),
    columns: Ember.computed("model.[]", function () {
      return [{
        "title": this.get('intl').t("customers.customer.title"),
        "propertyName": "Name",
        "className": "column-name"
      }, {
        "title": this.get('intl').t("sales.currentAccount.notDue"),
        "propertyName": "NonDueAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.less3"),
        "propertyName": "Range1Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.3to6"),
        "propertyName": "Range2Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.6to12"),
        "propertyName": "Range3Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.12to18"),
        "propertyName": "Range4Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.18to24"),
        "propertyName": "Range5Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.more24"),
        "propertyName": "Range6Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Total",
        "propertyName": "TotalAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});