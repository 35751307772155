define("crm/components/customer-workers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sortedData: Ember.computed('data.@each.created', function () {
      return this.get("data").sortBy("created").reverse();
    }),
    columns: Ember.computed('model.[]', function () {
      return [{
        "propertyName": "worker.name",
        "title": this.get('intl').t("workers.worker.title")
      }, {
        "propertyName": "created",
        "component": "umt-datetime",
        "title": this.get('intl').t("consultations.consultation.searchOptions.date")
      }];
    })
  });

  _exports.default = _default;
});