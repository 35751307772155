define("crm/components/order-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    itemsToShow: Ember.computed("items.@each.type", "selectedItemType.id", "searchValue", function () {
      if (this.get('items.length')) {
        var self = this;

        if (this.get('searchValue')) {
          return this.get('items').filter(function (item) {
            if (item.get('pvp') && item.get('name').toLowerCase().indexOf(self.get('searchValue').toLowerCase()) != -1) {
              return item;
            }
          });
        } else {
          if (this.get('selectedItemType.id')) {
            return this.get('items').filter(function (item) {
              return item.get('pvp') && item.get('type.id') == self.get('selectedItemType.id');
            });
          }
        }
      }
    })
  });

  _exports.default = _default;
});