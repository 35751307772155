define("crm/models/sub-task-item", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    val: _emberData.default.attr('string'),
    subTaskItemType: _emberData.default.belongsTo('subTaskItemType', {
      inverse: 'subTaskItems'
    }),
    exam: _emberData.default.belongsTo('exam', {
      inverse: 'subTaskItems'
    })
  });

  _exports.default = _default;
});