define("crm/controllers/organizations/organization/internments/overview-shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "start",
        "component": "umt-date",
        "title": this.get('intl').t("internments.internment.started"),
        "className": "column-date"
      }, {
        "propertyName": "patient.name",
        "title": this.get('intl').t("configurations.breeds.name")
      }, {
        "title": this.get('intl').t("financialAccounts.financialAccount.total"),
        "component": "shopping-cart-total",
        "className": "column-price"
      }, {
        "component": "internment-shopping-cart-actions",
        "className": "column-actions-1"
      }];
    }),
    actions: {
      openDetails: function openDetails(shoppingCart) {
        this.send("openModal", {
          entity: "open-shopping-carts",
          action: "details",
          model: shoppingCart
        });
      }
    }
  });

  _exports.default = _default;
});