define("crm/adapters/warehouse", ["exports", "crm/config/environment", "crm/adapters/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.universe.api.wildfly,
    namespace: ""
  });

  _exports.default = _default;
});