define("crm/models/campaign", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    started: _emberData.default.attr("localdatetime"),
    ended: _emberData.default.attr("localdatetime"),
    sendDate: _emberData.default.attr("localdatetime"),
    title: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    textTemplate: _emberData.default.attr("string"),
    species: _emberData.default.belongsTo("species"),
    item: _emberData.default.belongsTo("item"),
    itemType: _emberData.default.belongsTo("itemType"),
    organization: _emberData.default.belongsTo("organization"),
    audience: _emberData.default.attr("string"),
    sms: _emberData.default.hasMany("sms-sent", {
      inverse: "campaign"
    }),
    communicationErrors: _emberData.default.hasMany("communication-error", {
      inverse: "campaign"
    })
  });

  _exports.default = _default;
});