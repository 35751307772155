define("crm/controllers/organizations/organization/listings/sales/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    exporting: false,
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "userName",
        "title": this.get("intl").t("items.item.name"),
        "routeName": "organizations.organization.listings.sales.users.user",
        "routeProperty": "userId"
      }, {
        "propertyName": "total",
        "component": "umt-price-display",
        "className": "column-big text-right",
        "title": this.get("intl").t("invoices.invoice.total")
      }];
    }),
    filteredModel: Ember.computed("model.[]", function () {
      return this.get("model") ? this.get("model").map(function (x) {
        return {
          userId: x[0],
          userName: x[1],
          total: x[2]
        };
      }).sortBy("total").reverse() : null;
    })
  });

  _exports.default = _default;
});