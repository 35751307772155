define("crm/models/receipt", ["exports", "ember-data", "crm/models/document-sale"], function (_exports, _emberData, _documentSale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentSale.default.extend({
    billingID: _emberData.default.attr("string"),
    checkDate: _emberData.default.attr("localdate"),
    checkNumber: _emberData.default.attr("number"),
    deletedReason: _emberData.default.attr("string"),
    paymentMethod: _emberData.default.attr("string"),
    settled: _emberData.default.attr("number"),
    slip: _emberData.default.attr("boolean"),
    sourceDocs: _emberData.default.attr(),
    financialAccount: _emberData.default.belongsTo("financial-account"),
    lines: _emberData.default.hasMany("receipt-line", {
      inverse: "order"
    }),
    receiptInvoices: _emberData.default.hasMany("receipt-invoice"),
    invoices: Ember.computed("receiptInvoices.@each.invoice", function () {
      return this.get("receiptInvoices").map(function (x) {
        return x.get("invoice");
      });
    })
  });

  _exports.default = _default;
});