define("crm/routes/organizations/organization/equipment-parameter-types/equipment-parameter-type/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var equipmentParameterTypesID = this.modelFor("organizations.organization.equipment-parameter-types.equipment-parameter-type").get('id');
      return this.store.findRecord('equipment-parameter-type', equipmentParameterTypesID);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("units", this.store.query("unit", {
        company: this.modelFor("organizations.organization").get("company.id")
      }));
      controller.set("equipmentTypes", this.store.peekAll("equipment-type"));
    }
  });

  _exports.default = _default;
});