define("crm/components/internment/todo-drug-edit", ["exports", "crm/components/internment/todo-edit"], function (_exports, _todoEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todoEdit.default.extend({
    catalogs: Ember.inject.service(),
    units: Ember.computed.alias("catalogs.units"),
    viaTypes: Ember.computed.alias("catalogs.viaTypes"),
    modelName: "todo-drug",

    /*
     * Computed
     */
    selectedVia: Ember.computed("model.via", function () {
      return this.get("catalogs").viaType(this.get("model.via"));
    }),

    /*
     * Functions
     */
    clone: function clone() {
      return {
        concentration: this.get("model.concentration"),
        dosage: this.get("model.dosage"),
        unit: this.get("model.unit"),
        via: this.get("model.via"),
        volumeKg: this.get("model.volumeKg"),
        volumeTotal: this.get("model.volumeTotal")
      };
    },

    /*
     * Actions
     */
    actions: {
      setVia: function setVia(via) {
        this.set("model.via", via.id);
      },
      showCalculator: function showCalculator() {
        this.sendAction("openModal", {
          entity: "calculator",
          action: "details",
          model: {
            patient: this.get("model.internment.patient"),
            todo: this.get("model")
          }
        });
      }
    }
  });

  _exports.default = _default;
});