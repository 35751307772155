define("crm/controllers/organizations/organization/invites/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    roles: Ember.computed.alias("catalogs.workerRoles"),
    actions: {
      create: function create() {
        var self = this;
        this.set('disableBtn', true);
        var newModel = this.get('store').createRecord('invite', this.get('model'));
        newModel.save().then(function () {
          return self.transitionToRoute("organizations.organization.invites");
        }).catch(function (e) {
          self.get("es").handle(e);
          self.get('store').unloadRecord(newModel);
        }).finally(function () {
          return self.set("disableBtn", false);
        });
      },
      cancel: function cancel() {
        this.send("canceled");
      }
    }
  });

  _exports.default = _default;
});