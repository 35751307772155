define("crm/routes/organizations/organization/items/item/procurement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var item = this.modelFor('organizations.organization.items.item');
      var organizationID = this.modelFor("organizations.organization").id;
      return Ember.RSVP.hash({
        purchaseRequests: this.store.query("purchase-request", {
          organization: organizationID,
          status: 3,
          item: item.id
        }),
        purchaseOrders: this.store.query("purchase-order", {
          organization: organizationID,
          status: 3,
          item: item.id
        })
      });
    }
  });

  _exports.default = _default;
});