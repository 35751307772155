define("crm/abilities/financial-account", ["exports", "crm/abilities/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    canRead: Ember.computed("worker.organization.id", function () {
      if (["77", "196"].includes(this.get("worker.organization.id"))) {
        return this.get("undermanager");
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});