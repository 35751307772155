define("crm/components/internments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      var listCollumns = [{
        "propertyName": "id",
        "routeName": "organizations.organization.internments.internment",
        "title": "#",
        "className": "column-id module-internments"
      }, {
        "propertyName": "start",
        "component": "umt-datetime2",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get("intl").t("internments.internment.started"),
        "className": "column-datetime"
      }, {
        "propertyName": "end",
        "component": "umt-datetime2",
        "title": this.get("intl").t("internments.internment.ended"),
        "className": "column-datetime"
      }, {
        "propertyName": "reason",
        "title": this.get("intl").t("internments.internment.reason")
      }, {
        "propertyName": "endReason",
        "title": this.get("intl").t("internments.internment.endReason")
      }, {
        "component": "internment/list-actions",
        "className": "column-actions-3"
      }];

      if (!this.get("hidePatient")) {
        listCollumns.splice(1, 0, {
          "propertyName": "patient.name",
          "title": this.get("intl").t("patients.patient.title"),
          "routeProperty": "patient.id",
          "routeName": "organizations.organization.patients.patient",
          "className": "module-crm"
        });
      }

      return listCollumns;
    }),
    actions: {
      callModal: function callModal(arg) {
        this.sendAction("callModal", arg);
      }
    }
  });

  _exports.default = _default;
});