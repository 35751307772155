define("crm/models/resource", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    selectedAvatar: _emberData.default.belongsTo('image'),
    avatars: _emberData.default.hasMany('image'),
    worker: _emberData.default.belongsTo('worker'),
    color: _emberData.default.attr('number'),
    organization: _emberData.default.belongsTo('organization'),
    showOrder: _emberData.default.attr('number'),
    token: _emberData.default.attr('string'),
    calendarID: _emberData.default.attr('string')
  });

  _exports.default = _default;
});