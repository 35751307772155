define("crm/components/internment/details-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    types: Ember.computed.alias("catalogs.internmentTypes"),
    typeName: Ember.computed("model.type", "types.[]", function () {
      var _this = this;

      return this.get("types").find(function (e) {
        return e.id === _this.get("model.type");
      }).name;
    }),
    actions: {
      editInternment: function editInternment() {
        this.sendAction("callModal", {
          entity: "internment",
          action: "edit",
          model: this.get("model")
        });
      }
    }
  });

  _exports.default = _default;
});