define("crm/models/item-companion", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    quantity: _emberData.default.attr("number"),
    item: _emberData.default.belongsTo("item", {
      inverse: "companions"
    }),
    companion: _emberData.default.belongsTo("item"),
    unitSymbol: Ember.computed("companion.unitSymbol", function () {
      return this.get("companion.unitSymbol");
    }),
    saleUnitPrice: Ember.computed("companion.id", "companion.pvp", "companion.saleItemUnit", function () {
      return this.get("companion.saleItemUnit") && this.get("companion.saleItemUnit.quantity") ? this.get("companion.saleItemUnit.quantity") * this.get("companion.pvp") : this.get("companion.pvp");
    })
  });

  _exports.default = _default;
});